import { useEffect, useState } from 'react';

import type { RadioChangeEvent } from 'antd';
import { Button, Col, Radio, Row, Typography } from 'antd';

import { Mark, Popover, RadioCard } from 'src/ui';

import { SynchronizeType } from 'src/libs/finbits/Bank/Accounts/types';

import BTGConnectionRedirectButton from 'src/features/open-banking/DEPRECATED_BTGConnectionRedirectButton';

import ModalActions from '../ModalActions';

import styles from './ChooseSynchronizeType.module.less';

type Props = {
  onClickNext: (synchronizeType: SynchronizeType) => void;
  allowAutomatic: boolean;
  loading: boolean;
  isBTG?: boolean;
};

export default function ChooseSynchronizeType({
  onClickNext,
  allowAutomatic,
  loading,
  isBTG = false,
}: Props) {
  const showOnlyManual = !allowAutomatic && !isBTG;

  const introText = allowAutomatic
    ? 'Para este banco, existem dois tipos de atualização disponíveis:'
    : 'Para este banco, a opção é:';

  const [synchronizeType, setSynchronizeType] = useState<SynchronizeType>(
    allowAutomatic ? SynchronizeType.AUTOMATIC : SynchronizeType.MANUAL
  );

  useEffect(() => {
    if (allowAutomatic) {
      setSynchronizeType(SynchronizeType.AUTOMATIC);
    }
  }, [allowAutomatic]);

  function onChange(event: RadioChangeEvent) {
    setSynchronizeType(event.target.value);
  }

  return (
    <>
      <Typography.Paragraph>{introText}</Typography.Paragraph>
      <div>
        <Radio.Group onChange={onChange} value={synchronizeType}>
          <Row gutter={[24, 24]}>
            {allowAutomatic && (
              <Col span={24}>
                <RadioCard
                  value="automatic"
                  label="Atualização automática"
                  isActive={synchronizeType === 'automatic'}
                >
                  <Typography.Text>
                    Você informa suas credenciais de acesso ao banco e o Finbits
                    verifica automaticamente as novas transações algumas vezes
                    ao dia.
                  </Typography.Text>
                </RadioCard>
              </Col>
            )}
            <Col span={24}>
              <RadioCard
                value="manual"
                label="Atualização manual"
                isActive={synchronizeType === 'manual'}
              >
                <Typography.Text>
                  Você acessa o site/app do banco e baixa o extrato com novas
                  transações em{' '}
                  <Popover
                    placement="bottomLeft"
                    title={
                      <Typography.Text strong>Arquivos OFX 📄</Typography.Text>
                    }
                    content={
                      'Ao solicitar o download do extrato no ' +
                      'site/app do seu banco, você encontrará ' +
                      'opções de formatos de arquivos e o OFX é o ' +
                      'tipo que deve ser escolhido para importação ' +
                      'no Finbits.'
                    }
                  >
                    <Mark>formato OFX</Mark>
                  </Popover>
                  , em seguida importa no Finbits.
                </Typography.Text>
              </RadioCard>
            </Col>
          </Row>
        </Radio.Group>
      </div>
      {showOnlyManual && (
        <div className={styles.messageContainer}>
          <Typography.Paragraph>
            🔖 Já estamos trabalhando para que em breve as contas deste banco
            possam ser atualizadas automaticamente.
          </Typography.Paragraph>
        </div>
      )}
      {isBTG && (
        <div className={styles.messageContainer}>
          <Typography.Paragraph>
            Ou você pode conectar suas contas BTG automaticamente clicando
            <BTGConnectionRedirectButton
              type="link"
              className={styles.btgButton}
            >
              aqui
            </BTGConnectionRedirectButton>
          </Typography.Paragraph>
        </div>
      )}
      <Row>
        <ModalActions>
          <Button
            size="large"
            type="primary"
            loading={loading}
            onClick={() => onClickNext(synchronizeType)}
          >
            Continuar
          </Button>
        </ModalActions>
      </Row>
    </>
  );
}
