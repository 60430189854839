import { forwardRef } from 'react';

import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';

import { isMaskFilled, masker, unMask } from 'src/libs/finbits/Masker';

export type TextFieldMaskedProps = TextFieldProps & {
  mask?: string[];
  shouldUnMask?: boolean;
};

const TextFieldMasked = forwardRef<HTMLElement, TextFieldMaskedProps>(
  function TextFieldMasked(
    { onChange, mask, value, shouldUnMask = true, ...props },
    ref
  ) {
    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        if (mask && isMaskFilled(mask, event.target.value.trim())) return;

        onChange({
          ...event,
          target: {
            ...event.target,
            value:
              mask && shouldUnMask
                ? unMask(event.target.value)
                : event.target.value,
          },
        });
      }
    }

    const valueMasked = mask && value ? masker(value as string, mask) : '';

    return (
      <TextField
        {...props}
        inputRef={ref}
        onChange={handleOnChange}
        value={mask ? valueMasked : value}
      />
    );
  }
);

export default TextFieldMasked;
