import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import type { ErrorsObject } from 'src/libs/finbits/Form';
import { invalidateClassificationsQueries } from 'src/libs/finbits/Classifications';

import type { ActiveParams, Label, PatchParams, PostParams } from './types';
import { LabelDecoder } from './types';

async function postLabel({
  organizationId,
  companyId,
  classificationId,
  ...params
}: PostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/classifications/${classificationId}/labels`,
    params
  );

  return decodeResponse<Label>(response, LabelDecoder);
}

export function useCreateLabels() {
  const queryClient = useQueryClient();

  const { mutate: createLabel, ...rest } = useMutation<
    Label,
    ApiError<ErrorsObject>,
    PostParams
  >(postLabel, {
    onSuccess: () => {
      invalidateClassificationsQueries(queryClient);
    },
  });

  return { createLabel, ...rest };
}

async function patchLabel({
  organizationId,
  companyId,
  classificationId,
  labelId,
  ...params
}: PatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/classifications/${classificationId}/labels/${labelId}`,
    params
  );

  return decodeResponse<Label>(response, LabelDecoder);
}

export function useUpdateLabel() {
  const queryClient = useQueryClient();

  const { mutate: updateLabel, ...rest } = useMutation<
    Label,
    ApiError<ErrorsObject>,
    PatchParams
  >(patchLabel, {
    onSuccess: () => {
      invalidateClassificationsQueries(queryClient);
    },
  });

  return { updateLabel, ...rest };
}

async function patchActiveLabel({
  organizationId,
  companyId,
  classificationId,
  labelId,
}: ActiveParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/classifications/${classificationId}/labels/${labelId}/activate`
  );

  return response;
}

export function useActiveLabel() {
  const queryClient = useQueryClient();

  const { mutate: activeLabel, ...rest } = useMutation<
    unknown,
    ApiError<ErrorsObject>,
    ActiveParams
  >(patchActiveLabel, {
    onSuccess: () => {
      invalidateClassificationsQueries(queryClient);
    },
  });

  return { activeLabel, ...rest };
}

async function patchDeactiveLabel({
  organizationId,
  companyId,
  classificationId,
  labelId,
}: ActiveParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/classifications/${classificationId}/labels/${labelId}/deactivate`
  );

  return response;
}

export function useDeactiveLabel() {
  const queryClient = useQueryClient();

  const { mutate: deactiveLabel, ...rest } = useMutation<
    unknown,
    ApiError<ErrorsObject>,
    ActiveParams
  >(patchDeactiveLabel, {
    onSuccess: () => {
      invalidateClassificationsQueries(queryClient);
    },
  });

  return { deactiveLabel, ...rest };
}
