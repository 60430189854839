import { Empty } from 'antd';

import type { SelectedTab } from './types';

type Props = {
  selectedTab: SelectedTab;
};

export default function EmptyTable({ selectedTab }: Props) {
  const emptyText =
    selectedTab === 'actives'
      ? 'Você ainda não tem contatos ativos'
      : 'Você ainda não tem contatos inativos';

  return <Empty description={emptyText} image={Empty.PRESENTED_IMAGE_SIMPLE} />;
}
