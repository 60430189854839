import Grid from '@mui/material/Unstable_Grid2';

import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import { parseQrCode } from 'src/libs/finbits/Bank/Pix';
import { toCurrency } from 'src/libs/finbits/Money';

import DetailsInfo from 'src/features/inbox-items/InboxItemDetails/DetailsInfo';

type Props = {
  paymentDetails: PaymentDetails;
};

export default function PixQrCodeDetailsInfo({ paymentDetails }: Props) {
  const { error, parsedQrCode } = parseQrCode(paymentDetails.pixKey);

  if (error) {
    return (
      <Grid container columnGap={16} rowGap={6}>
        <DetailsInfo
          label="Código copia e cola (invalido)"
          value={paymentDetails.pixKey}
          noContentMessage="Sem código"
        />
      </Grid>
    );
  }

  return (
    <Grid container columnGap={16} rowGap={6}>
      <DetailsInfo
        label="Recebedor"
        value={parsedQrCode?.merchantName}
        noContentMessage="Sem nome do recebedor"
      />
      <DetailsInfo
        label="Cidade"
        value={parsedQrCode?.merchantCity}
        noContentMessage="Sem cidade"
      />
      <DetailsInfo
        label="Chave pix"
        value={parsedQrCode?.pixKey}
        noContentMessage="Sem chave pix"
      />
      <DetailsInfo
        label="Valor"
        value={parsedQrCode?.transactionAmount}
        noContentMessage="Sem valor"
        formatter={toCurrency}
      />
      <DetailsInfo
        label="Código copia e cola"
        value={paymentDetails.pixKey}
        noContentMessage="Sem código"
      />
    </Grid>
  );
}
