import { useCallback } from 'react';

import { generatePath, useNavigate } from 'react-router';

import { useCurrentRoute } from 'src/router/useCurrentRoute';

import {
  setCompanyParamsOnStorage,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';

export function useSwitchCompany() {
  const navigate = useNavigate();
  const { organizationId } = useCompanyParams();
  const currentRoute = useCurrentRoute();

  const switchCompany = useCallback(
    (companyId: string) => {
      if (!currentRoute?.path) throw new Error('Missing current route');

      setCompanyParamsOnStorage({ companyId, organizationId });

      return navigate(
        generatePath(currentRoute.path, {
          companyId,
          organizationId,
        })
      );
    },
    [currentRoute, navigate, organizationId]
  );

  return switchCompany;
}
