import { IMaskMixin } from 'react-imask';
import type { InputBaseProps } from '@mui/material';
import { InputBase } from '@mui/material';
import type { AnyMaskedOptions } from 'imask';

type AdditionalMaskedInputProps = {
  componentsProps?: {
    inputBase?: Partial<InputBaseProps>;
  };
};

const InputBaseMasked = IMaskMixin<
  AnyMaskedOptions,
  false,
  string,
  HTMLInputElement,
  AdditionalMaskedInputProps
>(({ inputRef, ...props }) => {
  const { componentsProps = {} } = props;
  const { inputBase: inputProps = {} } = componentsProps;

  return <InputBase {...inputProps} inputRef={inputRef} />;
});

export default InputBaseMasked;
