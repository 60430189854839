import type { SxProps, Theme } from '@mui/material';
import { textFieldClasses } from '@mui/material';

export const companyText: SxProps<Theme> = {
  maxWidth: '14ch',
  fontWeight: '600',
  mr: 1,
};

export const menu: SxProps<Theme> = {
  '.MuiPopper-root': {
    position: 'unset !important',
    transform: 'unset !important',
  },
};

export const menuPaper: SxProps<Theme> = {
  padding: 0,
  background: (theme) => theme.palette.background.paper,
};

export const textField: SxProps<Theme> = {
  [`.${textFieldClasses.root}`]: { borderBottom: '0 !important' },
};
