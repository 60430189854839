import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function NotEqualIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="not-equal-icon"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M5 9H19M5 15H19M19 5L5 19"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
