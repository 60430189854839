import uniqBy from 'lodash/unionBy';
import type { ColumnType } from 'antd/es/table';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { EntrySituation } from 'src/libs/finbits/Management/Entries/types';
import type { Receivable } from 'src/libs/finbits/Receivables/types';

import CurrentSituationTag from 'src/features/entries/CurrentSituationTag';

const PaymentStatus = [
  EntrySituation.WAITING_PAYMENT,
  EntrySituation.UNAUTHORIZED,
  EntrySituation.FAILED,
  EntrySituation.PAID,
  EntrySituation.REVERTED,
  EntrySituation.CANCELED,
  EntrySituation.PROCESSING,
  EntrySituation.PENDING_AUTHORIZATION,
  EntrySituation.PAYMENT_SCHEDULED,
];

const filters = uniqBy(
  Object.values(EntrySituation)
    .filter((currentSituation) => PaymentStatus.includes(currentSituation))
    .map((currentSituation) => ({
      text: <CurrentSituationTag currentSituation={currentSituation} />,
      value: currentSituation,
    })),
  'value'
);

function CurrentSituationFilter<
  T extends BillPayable | Receivable
>(): ColumnType<T> {
  return {
    filterMode: 'tree',
    filters: filters,
    onFilter: (filteredValue, record) => {
      const status = record.currentSituation;

      return status === filteredValue;
    },
  };
}

export default CurrentSituationFilter;
