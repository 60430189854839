import { UndoWaitingPaymentIcon } from 'src/mui/_icons';

import { BillStatus } from 'src/libs/finbits/Bills/types';

import UndoActionButton from 'src/features/bills-to-pay/UndoActionButton';

import type { Action } from '../types';

export default function WaitingPaymentActions({
  selecteds,
  onSuccess,
}: Action) {
  return (
    <UndoActionButton
      Icon={UndoWaitingPaymentIcon}
      status={BillStatus.WAITING_PAYMENT}
      bills={selecteds}
      onSuccess={onSuccess}
    />
  );
}
