import raw from 'raw.macro';

import type { PortalProps } from 'src/ui';

import MarkdownModal from 'src/features/markdown-modal';

type Props = {
  label?: string;
  defaultVisible?: boolean;
} & Partial<PortalProps>;

const markdown = raw('./saas-contract.md');

export default function SaasContractModal({
  label,
  defaultVisible = false,
  ...portalProps
}: Props) {
  return (
    <MarkdownModal
      label={label}
      open={defaultVisible}
      markdown={markdown}
      {...portalProps}
    />
  );
}
