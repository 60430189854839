import { useState } from 'react';

import cn from 'classnames';
import { Button, Col, Divider, Form, Row, Typography } from 'antd';
import { Button as MUIButton } from '@mui/material';
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { closeSnackbar } from 'notistack';

import { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';

import { snackbar, snackbarAction } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { FormItem, Modal, Title, UploadDragger } from 'src/ui';

import { useImportScheduledEntriesSpreadsheet } from 'src/libs/finbits/Management/ScheduledEntries/ScheduledEntries';
import { hasValidationErrors } from 'src/libs/finbits/client';
import { validateBeforeUpload } from 'src/libs/finbits/Spreadsheet/validations';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import type { SubmitParams } from 'src/libs/finbits/Spreadsheet/types';

import type { ScheduledEntryDrawerParams } from 'src/features/entries/Modals/ImportScheduledEntryModal/types';
import AccountSelect from 'src/features/DEPRECATED_accounts/AccountSelect/AccountSelect';

import ImportScheduledEntryDrawer from './ImportScheduledEntryDrawer';
import styles from './ImportScheduledEntryModal.module.less';

type Props = {
  isLoadingAccounts: boolean;
  accounts?: Account[];
} & PortalProps;

const SNACKBAR_KEY = 'sending-failed';

function ImportScheduledEntryModal({
  onClose,
  accounts,
  isLoadingAccounts,
  open = true,
  onExit,
}: Props) {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [isFilledFile, setIsFilledFile] = useState<boolean>(true);
  const { companyId, organizationId } = useCompanyParams();
  const [form] = Form.useForm();

  const [scheduledEntryDrawer, setScheduledEntryDrawer] =
    useState<ScheduledEntryDrawerParams>({ isOpen: false });

  const { importScheduledEntry, isLoading } =
    useImportScheduledEntriesSpreadsheet();

  function redirectToAccountSettings() {
    onClose();

    navigate({
      pathname: generateCompanyPath(RouteKey.ACCOUNTS, {
        companyId,
        organizationId,
      }),
      search: 'action=createAccount',
    });
  }

  function openInvalidSpreadsheetSnackbar() {
    snackbar({
      variant: 'error',
      key: SNACKBAR_KEY,
      title: 'Falha no envio',
      message: (
        <span>
          Não foi possível fazer upload do arquivo. Por favor, verifique se o
          modelo de planilha disponibilizado sofreu alterações.
        </span>
      ),
      alignItems: 'start',
      customActions: (
        <MUIButton
          aria-label="Entendi"
          size="small"
          color="secondary"
          sx={snackbarAction}
          onClick={() => closeSnackbar(SNACKBAR_KEY)}
        >
          Entendi
        </MUIButton>
      ),
    });
  }

  function onSubmit({ accountId, file }: SubmitParams) {
    importScheduledEntry(
      {
        organizationId,
        companyId,
        accountId,
        file,
      },
      {
        onSuccess: () => {
          onClose();

          snackbar({ variant: 'success', message: 'Lançamentos importados' });
        },
        onError: ({ response }) => {
          if (response?.data.errors?.file) {
            openInvalidSpreadsheetSnackbar();

            return;
          }

          if (hasValidationErrors(response)) {
            setScheduledEntryDrawer({
              isOpen: true,
              errors: response?.data.errors,
            });

            return;
          }

          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar os lançamentos!',
          });
        },
      }
    );
  }

  function handleChange({ file, fileList }: UploadChangeParam) {
    if (file.status === 'removed') {
      form.setFieldsValue({ file: undefined });
      setFileList([]);

      return;
    }

    setIsFilledFile(true);

    setFileList(
      fileList.map((file) => ({
        ...file,
        status: 'done',
      }))
    );

    form.setFieldsValue({ file: fileList[0].originFileObj });
  }

  return (
    <>
      <Modal
        width={576}
        onCancel={onClose}
        title={
          <Title icon={<UploadOutlined />}>
            Upload lançamentos programados
          </Title>
        }
        footer={
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            onClick={() => {
              setIsFilledFile(form.getFieldValue('file'));

              form.submit();
            }}
          >
            Fazer upload
          </Button>
        }
        visible={open}
        afterClose={onExit}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          requiredMark={false}
        >
          <Row>
            <Col span={24}>
              <Typography.Paragraph>
                Primeiro, faça o{' '}
                <Typography.Link
                  href="https://finbits-public-assets.s3.amazonaws.com/spreadsheets/planilha-lancamentos-finbits.xlsx"
                  target="_blank"
                  download
                >
                  download da planilha modelo{' '}
                </Typography.Link>
                que disponibilizamos.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Em seguida, preencha as informações desejadas seguindo as
                instruções na planilha - observe que os campos Descrição, Valor
                e Data são de preenchimento obrigatório.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Para concluir, faça o upload da sua planilha preenchida
                selecionando ou arrastando o arquivo para a área abaixo. Os
                lançamentos serão importados para uma conta de cada vez.
              </Typography.Paragraph>
              <Divider />
            </Col>

            <Col span={24}>
              <FormItem
                required
                className={styles.accountSelect}
                name="accountId"
                label="Para qual conta deseja importar os lançamentos?"
              >
                <AccountSelect
                  accounts={accounts ?? []}
                  loading={isLoadingAccounts}
                />
              </FormItem>

              <Typography.Link onClick={redirectToAccountSettings}>
                Cadastrar nova conta
              </Typography.Link>
            </Col>

            <Col span={24}>
              <FormItem required name="file" hasMax={false} shouldUpdate>
                <UploadDragger
                  showUploadList
                  accept=".xlsx"
                  multiple={false}
                  maxCount={1}
                  fileList={fileList}
                  customRequest={({ onSuccess }) => {
                    onSuccess && onSuccess('done');
                  }}
                  data-testid="file"
                  beforeUpload={validateBeforeUpload}
                  onChange={handleChange}
                  className={cn([
                    styles.dragger,
                    {
                      [styles.draggerError]: !isFilledFile,
                    },
                  ])}
                >
                  <span className={styles.highlight}>Selecione</span> ou arraste
                  a planilha <br /> (arquivo .xlsx) para esta área
                </UploadDragger>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>

      {scheduledEntryDrawer.errors && scheduledEntryDrawer.isOpen && (
        <ImportScheduledEntryDrawer
          errors={scheduledEntryDrawer.errors}
          onClose={() => setScheduledEntryDrawer({ isOpen: false })}
        />
      )}
    </>
  );
}

export default ImportScheduledEntryModal;
