import type { GridApi, GridRowModel } from '@mui/x-data-grid-premium';
import compact from 'lodash/compact';

export function getSelectedRows<T extends GridRowModel>(
  api: GridApi,
  row: T
): { hasSelected: boolean; activeRows: GridRowModel<T>[] } {
  const selectedMap = api.getSelectedRows();
  const selected = compact(Array.from(selectedMap.values()));

  const isSelectedRowEqualRow =
    selected.length === 1 && selected[0].id === row.id;

  const hasSelected = !isSelectedRowEqualRow && selected.length > 0;

  const activeRows = selectedMap.has(row.id) ? selected : [...selected, row];

  return { hasSelected, activeRows: activeRows as GridRowModel<T>[] };
}
