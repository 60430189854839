import { Button, Checkbox, Radio, Space, Tag, Typography } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { Card, TextHighlight } from 'src/ui';

import type {
  CompanyService,
  Service,
} from 'src/libs/finbits/NotaFiscal/Services/types';
import {
  getServiceId,
  isCompanyService,
} from 'src/libs/finbits/NotaFiscal/Services';

import styles from './ServiceCard.module.less';

type Props = {
  type?: 'select' | 'card';
  actionType?: 'checkbox' | 'radio';
  selected?: boolean;
  highlightText?: string;
  service: Service | CompanyService;
  showSuggestedRate?: boolean;
  onDelete?: (id: string) => void;
  onChangeMainActivity?: (id: string) => void;
  onChangeService?: () => void;
};

export default function ServiceCard({
  type = 'card',
  actionType = 'checkbox',
  service,
  selected = false,
  showSuggestedRate = false,
  highlightText,
  onDelete,
  onChangeMainActivity,
  onChangeService,
}: Props) {
  const fullCode = service.subitemCode
    ? `${service.code} ${service.subitemCode}`
    : service.code;
  const codeWithdescription = `${fullCode} - ${service.description}`;

  const isActive = service.active;

  const serviceId = getServiceId(service);
  const isMain = isCompanyService(service) ? service.mainActivity : false;

  const isCardType = type === 'card';
  const isSelectType = type === 'select';

  const canShowActions = !!onDelete || !!onChangeMainActivity;
  const canShowMainTag = isMain && isCardType && !canShowActions;
  const canShowSuggestedRate = !!service.suggestedRate && showSuggestedRate;

  if (!serviceId) {
    return null;
  }

  return (
    <Card
      data-testid="card service"
      aria-label={`card service ${service.description}`}
      bordered={false}
      boxShadow={1}
      className={cn(styles.card, {
        [styles.cardActive]: isMain && isCardType && isActive,
      })}
    >
      <div className={styles.cardBody}>
        {isSelectType && (
          <>
            {actionType === 'checkbox' && (
              <Checkbox
                checked={selected}
                disabled={!isActive && !selected}
                aria-label={codeWithdescription}
                onChange={onChangeService}
              />
            )}
            {actionType === 'radio' && (
              <>
                {selected ? (
                  <CheckOutlined />
                ) : (
                  <Radio
                    disabled={!isActive}
                    aria-label={codeWithdescription}
                    onChange={onChangeService}
                  />
                )}
              </>
            )}
          </>
        )}

        <Space
          direction="vertical"
          className={cn({
            [styles.cardDisabled]: !isActive,
          })}
        >
          {highlightText ? (
            <TextHighlight
              searchWords={[highlightText]}
              textToHighlight={codeWithdescription}
            />
          ) : (
            <Typography.Paragraph>
              <Typography.Text strong>{fullCode} - </Typography.Text>
              {service.description}
            </Typography.Paragraph>
          )}
          {canShowSuggestedRate && (
            <span className={styles.suggestedRateText}>
              {`Alíquota ISS sugerida: ${service.suggestedRate}%`}
            </span>
          )}
        </Space>
        <Space
          className={styles.cardOptions}
          direction="horizontal"
          align="start"
          size="small"
        >
          {!isActive && <Tag color="yellow">Serviço desativado</Tag>}
          {canShowMainTag && <Tag color="blue">principal</Tag>}
          {canShowActions && (
            <>
              {isActive && onChangeMainActivity && (
                <Button
                  onClick={() => onChangeMainActivity(serviceId)}
                  className={cn(styles.changeButton, {
                    [styles.changeButtonActive]: isMain,
                  })}
                >
                  {isMain ? 'Principal' : 'Tornar Principal'}
                </Button>
              )}
              {onDelete && (
                <Button
                  aria-label="Excluir"
                  size="middle"
                  icon={<DeleteOutlined />}
                  onClick={() => onDelete(serviceId)}
                  type="text"
                />
              )}
            </>
          )}
        </Space>
      </div>
    </Card>
  );
}
