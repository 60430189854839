import t from 'zod';

import { ScheduledEntryDecoder } from 'src/libs/finbits/Management/ScheduledEntries/types';
import { FinancialEntryDecoder } from 'src/libs/finbits/Management/FinancialEntries/types';

export const ConciliationSuggestionDecoder = t.object({
  id: t.string(),
  status: t.union([
    t.literal('pending'),
    t.literal('accepted'),
    t.literal('refused'),
    t.literal('ignored'),
  ]),
  financialEntry: FinancialEntryDecoder,
  scheduledEntry: ScheduledEntryDecoder,
});

export const ConciliationSuggestionsDecoder = t.array(
  ConciliationSuggestionDecoder
);

export type ConciliationSuggestion = t.TypeOf<
  typeof ConciliationSuggestionDecoder
>;

export type PatchParams = {
  organizationId: string;
  companyId: string;
  suggestionId: string;
  status?: 'accepted' | 'refused';
};

export type GetParams = {
  organizationId?: string;
  companyId?: string;
};
