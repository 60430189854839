import { useMemo, useState } from 'react';

import { Button, Col, Row, Tooltip } from 'antd';
import { CloseOutlined, SyncOutlined } from '@ant-design/icons';

import { Drawer, DrawerFooter, DrawerHeader, Loader, Title } from 'src/ui';

import { useAttachments } from 'src/libs/finbits/Management/Attachments';
import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import { pluralize } from 'src/libs/finbits/Pluralize';

import EmptyState from './EmptyState';
import AttachmentCheckbox from './AttachmentCheckbox';
import styles from './InboxDrawer.module.less';

export type CheckedList = Record<string, boolean>;

type Props = {
  organizationId?: string;
  companyId?: string;
  onClose: () => void;
  onSubmit: (attachments: Attachment[]) => void;
  unavailableAttachments: Attachment[];
};

function InboxDrawer({
  onClose,
  onSubmit,
  companyId,
  organizationId,
  unavailableAttachments,
}: Props) {
  const [checkedList, setCheckedList] = useState<CheckedList>({});

  const {
    attachments: fetchedAttachments,
    isLoading,
    isFetching,
    refetch,
  } = useAttachments({
    companyId,
    organizationId,
  });

  const attachments = useMemo(() => {
    const unavailableAttachmentsIds = unavailableAttachments.map((a) => a.id);

    return fetchedAttachments.filter(
      (a) => !unavailableAttachmentsIds.includes(a.id)
    );
  }, [fetchedAttachments, unavailableAttachments]);

  const selectedCount = Object.keys(checkedList).length;

  function onChangeHandle(attachmentId: string, checked: boolean) {
    const newList = { ...checkedList };

    if (checked) {
      newList[attachmentId] = true;
    } else {
      delete newList[attachmentId];
    }
    setCheckedList(newList);
  }

  function handleAddAttachments() {
    const selecteds = Object.keys(checkedList);

    const selectedAttachments = attachments.filter((attachment: Attachment) =>
      selecteds.includes(attachment.id)
    );

    onSubmit(selectedAttachments ?? []);
    handleOnClose();
  }

  function handleOnClose() {
    setCheckedList({});
    onClose();
  }

  return (
    <Drawer
      closable={false}
      className={styles.drawer}
      title={
        <DrawerHeader
          onBack={handleOnClose}
          title={<Title>Anexos na Caixa de Entrada</Title>}
          extra={
            <Tooltip title="Atualizar" placement="bottom">
              <Button
                icon={<SyncOutlined spin={isLoading || isFetching} />}
                onClick={() => refetch()}
                type="text"
              />
            </Tooltip>
          }
        />
      }
      visible
      footer={null}
      onClose={handleOnClose}
    >
      {!isLoading && attachments.length === 0 && <EmptyState />}

      {isLoading && <Loader />}

      {!isLoading && (
        <Row gutter={[20, 20]}>
          {attachments.map((attachment: Attachment) => (
            <Col key={attachment.id} span={8}>
              <AttachmentCheckbox
                attachment={attachment}
                checked={!!checkedList[attachment.id]}
                onChange={(checked) => onChangeHandle(attachment.id, checked)}
              />
            </Col>
          ))}
        </Row>
      )}

      {selectedCount > 0 && (
        <DrawerFooter>
          <div className={styles.selectedCount}>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setCheckedList({})}
            />
            {pluralize(
              selectedCount,
              `${selectedCount} anexo selecionado`,
              `${selectedCount} anexos selecionados`
            )}
          </div>

          <DrawerFooter.SubmitButton onClick={handleAddAttachments}>
            Adicionar
          </DrawerFooter.SubmitButton>
        </DrawerFooter>
      )}
    </Drawer>
  );
}

export default InboxDrawer;
