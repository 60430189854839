import { Col, Input, Select, Space, Tag } from 'antd';

import { FormItem } from 'src/ui';

import {
  ACCOUNT_TYPE_DICTIONARY,
  AccountTypeLabel,
} from 'src/libs/finbits/Bank/Accounts';
import type { PaymentDetails } from 'src/libs/finbits/Payments/types';

import BankSelect from 'src/features/DEPRECATED_accounts/BankSelect/BankSelect';

import styles from './BankFields.module.less';

type Props = {
  international?: boolean;
  disabled?: boolean;
  baseFieldName?: 'bankDetails' | 'paymentDetails';
  requiredFields?: Array<keyof PaymentDetails> | string[];
  suggestions?: PaymentDetails;
};

export default function BankFields({
  international,
  disabled = false,
  baseFieldName = 'bankDetails',
  requiredFields = [],
  suggestions,
}: Props) {
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12}>
        <FormItem
          name={[baseFieldName, 'routingNumber']}
          label={
            <Space>
              Banco
              {suggestions?.routingNumber && (
                <Tag color="blue">Preenchimento sugerido</Tag>
              )}
            </Space>
          }
          required={requiredFields.includes('routingNumber')}
        >
          {international ? (
            <Input size="large" disabled={disabled} />
          ) : (
            <BankSelect allowClear disabled={disabled} />
          )}
        </FormItem>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12}>
        <FormItem
          label={
            <Space>
              Tipo de Conta
              {suggestions?.accountType && (
                <Tag color="blue">Preenchimento sugerido</Tag>
              )}
            </Space>
          }
          name={[baseFieldName, 'accountType']}
          required={requiredFields.includes('accountType')}
        >
          <Select
            placeholder="Selecione"
            size="large"
            allowClear
            disabled={disabled}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <Select.Option value="checking_account" key="checking_account">
              {ACCOUNT_TYPE_DICTIONARY[AccountTypeLabel.CHECKING_ACCOUNT]}
            </Select.Option>
            <Select.Option value="savings_account" key="saving_account">
              {ACCOUNT_TYPE_DICTIONARY[AccountTypeLabel.SAVINGS_ACCOUNT]}
            </Select.Option>
          </Select>
        </FormItem>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12}>
        <FormItem
          label={
            <Space>
              Agência
              {suggestions?.branchNumber && (
                <Tag color="blue">Preenchimento sugerido</Tag>
              )}
            </Space>
          }
          name={[baseFieldName, 'branchNumber']}
          required={requiredFields.includes('branchNumber')}
        >
          <Input
            size="large"
            placeholder={!international ? 'Exemplo: 0000' : undefined}
            disabled={disabled}
          />
        </FormItem>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12}>
        <FormItem
          label={
            <Space size={1}>
              Conta (com o dígito)
              {suggestions?.accountNumber && (
                <Tag color="blue" className={styles.tag}>
                  Preenchimento sugerido
                </Tag>
              )}
            </Space>
          }
          name={[baseFieldName, 'accountNumber']}
          required={requiredFields.includes('accountNumber')}
        >
          <Input
            size="large"
            placeholder={!international ? 'Exemplo: 000000-0' : undefined}
            disabled={disabled}
          />
        </FormItem>
      </Col>
    </>
  );
}
