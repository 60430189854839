import type {
  MigrationFinishedMessage,
  StatementImportFinishedMessage,
} from 'src/libs/finbits/Channels/types';

import { useCompanyListener } from '../Channels';

export function useStatementImportFinishedListener(
  handler: (message: StatementImportFinishedMessage) => void
) {
  useCompanyListener('statement_import_finished', handler);
}

export function useMigrationCompletedListener(
  handler: (message: MigrationFinishedMessage) => void
) {
  useCompanyListener('finished_migration', handler);
}
