import type { GridRowModel, GridValidRowModel } from '@mui/x-data-grid-premium';
import type { ProcessRowUpdateProps } from 'src/mui/_scss';

import { useUpdateEntriesInBatch } from 'src/libs/finbits/Management/EntriesBatchUpdate';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import isCellEditable from './isCellEditable';

function isAllAllowedFields(row: FinancialStatementEntry, fields: string[]) {
  return fields.every((field) => isCellEditable({ row, field }));
}

function buildEntriesIds<T extends GridValidRowModel & FinancialStatementEntry>(
  { amountType }: GridRowModel<T>,
  rows: GridRowModel<T>[],
  fields: string[]
) {
  const hasCategory = fields.includes('categoryId');

  const entriesToUpdate = rows.filter((row) => {
    if (hasCategory && amountType !== row.amountType) {
      return false;
    }

    return isAllAllowedFields(row, fields);
  });

  const receivablesIds = entriesToUpdate
    .filter((entry) => entry.entryType === EntryType.RECEIVABLE)
    .map((entry) => entry.id);

  const financialEntriesIds = entriesToUpdate
    .filter((entry) => entry.entryType === EntryType.FINANCIAL_ENTRY)
    .map((entry) => entry.id);

  const billsIds = entriesToUpdate
    .filter((entry) => entry.entryType === EntryType.BILL)
    .map((entry) => entry.id);

  return { billsIds, receivablesIds, financialEntriesIds };
}

export default function useHandleProcessRowUpdate() {
  const { organizationId, companyId } = useCompanyParams();
  const { mutateAsync: updateEntries, isLoading } = useUpdateEntriesInBatch();

  function handleProcessRowUpdate({
    fields,
    newRow,
    activeRows,
  }: ProcessRowUpdateProps<FinancialStatementEntry>) {
    const updatedRow = { ...newRow, ...fields };

    const entries = buildEntriesIds<any>(
      updatedRow,
      activeRows,
      Object.keys(fields)
    );

    return updateEntries({
      ...entries,
      organizationId,
      companyId,
      changes: fields,
    });
  }

  return { isLoading, handleProcessRowUpdate };
}
