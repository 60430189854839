import { Box } from '@mui/material';
import { LoadingIcon } from 'src/mui/_icons';

import { centered, fullPage, loading } from './Loader.sx';

type Props = {
  size?: 'small' | 'medium' | 'large';
  full?: boolean;
  forceCentered?: boolean;
};

const sizes = {
  small: {
    fontSize: '80px',
  },
  medium: {
    fontSize: '100px',
  },
  large: {
    fontSize: '120px',
  },
};

export default function Loader({
  size = 'medium',
  full = false,
  forceCentered = false,
}: Props) {
  return (
    <Box
      aria-label="loading"
      sx={{
        ...loading,
        ...(full ? fullPage : {}),
        ...(forceCentered ? centered : {}),
      }}
    >
      <LoadingIcon sx={{ fontSize: sizes[size].fontSize }} />
    </Box>
  );
}
