import * as t from 'zod';

import { ReceivableDecoder } from 'src/libs/finbits/Receivables/types';
import { FinancialEntryDecoder } from 'src/libs/finbits/Management/FinancialEntries/types';

export const AvailableToLinkNotaFiscalDecoder = t.object({
  financialEntries: FinancialEntryDecoder.array(),
  receivables: ReceivableDecoder.array(),
});

export type AvailableToLinkNotaFiscal = t.TypeOf<
  typeof AvailableToLinkNotaFiscalDecoder
>;

export type GetParams = {
  companyId: string;
  organizationId: string;
  startDate?: string;
  endDate?: string;
};
