import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';
import { parseISO } from 'src/libs/finbits/Date';
import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import type { Receivable } from 'src/libs/finbits/Receivables/types';
import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { Label } from 'src/libs/finbits/Labels/types';
import type { NewEntrySuggestion } from 'src/libs/finbits/Management/Entries/types';
import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { filterAvailable } from 'src/libs/finbits/Classifications';

import type { FormValues } from './types';

export function financialEntryToFormValues(
  financialEntry: FinancialEntry
): FormValues {
  return {
    id: financialEntry.id,
    description: financialEntry.description,
    date: parseISO(financialEntry.date),
    amount: financialEntry.amount,
    accountId: financialEntry.accountId,
    contactId: financialEntry.contactId,
    comments: financialEntry.comments,
    categoryId: financialEntry.categoryId,
    type: financialEntry.type,
    relevantDate: financialEntry.relevantDate
      ? parseISO(financialEntry.relevantDate)
      : undefined,
    attachments: financialEntry.attachments,
    originDescription: financialEntry.originDescription,
    scheduledEntryId: financialEntry.scheduledEntry?.id ?? '',
    labels: financialEntry.labels ?? [],
    notaFiscalId: financialEntry.notaFiscalId ?? '',
  };
}

export function billToFormValues(bill: Partial<BillPayable>): FormValues {
  return {
    type: BalanceType.DEBIT,
    id: bill.id ?? 'new_bill',
    description: bill.description,
    date: bill.date ? parseISO(bill.date) : null,
    amount: bill.amount,
    accountId: bill.accountId,
    comments: bill.comments,
    contactId: bill.contactId,
    categoryId: bill.categoryId,
    relevantDate: bill.relevantDate ? parseISO(bill.relevantDate) : null,
    attachments: bill.attachments,
    paymentDetails: bill.paymentDetails,
    assignees: bill.assignees,
    assigneesIds: bill.assignees?.map((assignee) => assignee.id),
    approvalType: bill.approvalType ?? BillPayableApprovalType.ALL,
    labels: bill.labels ?? [],
    isRecurrent: Boolean(bill.scheduledRecurrenceId),
  };
}

export function newEntrySuggestionToFormValues(
  suggestion: NewEntrySuggestion | undefined,
  attachments: Attachment[]
): FormValues {
  return {
    type: BalanceType.DEBIT,
    amount: suggestion?.fields.amount,
    description: suggestion?.fields.description || undefined,
    contactId: suggestion?.fields.contact?.id,
    categoryId: suggestion?.fields.category?.id,
    attachments: attachments,
    date: suggestion?.fields.date
      ? parseISO(suggestion.fields.date)
      : undefined,
    relevantDate: suggestion?.fields.relevantDate
      ? parseISO(suggestion.fields.relevantDate)
      : undefined,
    paymentDetails: suggestion?.fields.paymentDetails as any,
    comments: suggestion?.fields.comments,
  };
}

export function receivableToFormValues(
  receivable: Partial<Receivable>
): FormValues {
  return {
    id: receivable.id ?? 'new_receivable',
    description: receivable.description,
    date: receivable.date ? parseISO(receivable.date) : undefined,
    amount: receivable.amount,
    accountId: receivable.accountId,
    comments: receivable.comments,
    contactId: receivable.contactId,
    categoryId: receivable.categoryId,
    type: BalanceType.CREDIT,
    relevantDate: receivable.relevantDate
      ? parseISO(receivable.relevantDate)
      : null,
    attachments: receivable.attachments,
    isRecurrent: Boolean(receivable.scheduledRecurrence),
    notaFiscalId: receivable.notaFiscalId ?? '',
    labels: receivable.labels ?? [],
  };
}

export function scheduledEntryToFormValues(
  entry: ScheduledStatementEntry,
  type: FormValues['type']
) {
  if (type === BalanceType.CREDIT) {
    return receivableToFormValues(entry);
  }

  return billToFormValues(entry);
}

export function classificationToFormValues(
  classifications?: Classification[],
  labels?: Label[]
) {
  if (!classifications || classifications?.length === 0) return [];

  return filterAvailable(classifications, labels).map((classification) => {
    const labelsIds =
      labels
        ?.filter((label) => label.classificationId === classification.id)
        .map((label) => label.id) ?? [];

    return {
      classificationId: classification.id,
      labelsIds,
    };
  });
}
