import { useMemo, useState } from 'react';

import { CalendarOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Drawer, DrawerHeader, Title } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  buildSubmitParams,
  useCreateBill,
  DEPRECATED_useCreateRecurringBill as useCreateRecurringBill,
} from 'src/libs/finbits/Bills';
import { useIsEnabledBillsApprovalsRulesInCompany } from 'src/libs/finbits/Organization/Companies/Options';
import {
  useCreateReceivable,
  useCreateRecurringReceivable,
} from 'src/libs/finbits/Receivables';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { isStructuredFormError } from 'src/libs/finbits/Form';
import type { Receivable } from 'src/libs/finbits/Receivables/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import EntryForm from 'src/features/EntryForm';
import CreateNotaFiscalDrawer from 'src/features/nota-fiscal/CreateNotaFiscalDrawer';
import type {
  EntryFormHiddenActions,
  EntryFormHiddenFields,
  FormValues,
  SubmitParams,
} from 'src/features/EntryForm/types';

type Props = {
  title?: string;
  initialValues?: Partial<FormValues>;
  hiddenFields?: EntryFormHiddenFields;
  hiddenActions?: EntryFormHiddenActions;
  onCreateReceivable?: (receivable: Receivable) => void;
} & PortalProps;

const HIDDEN_ACTIONS: EntryFormHiddenActions = [
  'createNotaFiscalFromReceivable',
];

export default function CreateScheduledDrawer({
  title = 'Lançamento programado',
  initialValues,
  hiddenFields,
  hiddenActions = HIDDEN_ACTIONS,
  onClose,
  open = true,
  onExit,
}: Props) {
  const [errors, setErros] = useState<ApiErrorForm>();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [isCreateScheduledDrawerVisible, setIsCreateScheduledDrawerVisible] =
    useState(true);
  const [isCreateNotaFiscalDrawerVisible, setIsCreateNotaFiscalDrawerVisible] =
    useState(false);

  const { createBill, isLoading: isLoadingBill } = useCreateBill();
  const {
    createReceivable,
    isLoading: isLoadingReceivable,
    data: receivable,
  } = useCreateReceivable();

  const { createRecurringBill, isLoading: isLoadingRecurringBill } =
    useCreateRecurringBill();

  const {
    createRecurringReceivable,
    isLoading: isLoadingRecurringReceivable,
    data: recurringReceivable,
  } = useCreateRecurringReceivable();

  const { companyId, organizationId } = useCompanyParams();
  const { isEnabledBillsApprovalsRules } =
    useIsEnabledBillsApprovalsRulesInCompany();
  const isLoading =
    isLoadingBill ||
    isLoadingReceivable ||
    isLoadingRecurringReceivable ||
    isLoadingRecurringBill;

  function handleCreateScheduledEntry(
    { attachments, isRecurrent, type, ...params }: SubmitParams,
    shouldCreateNotaFiscal?: boolean
  ) {
    const callbacks = {
      onSuccess: () => {
        if (shouldCreateNotaFiscal) {
          setIsCreateScheduledDrawerVisible(false);
          setIsCreateNotaFiscalDrawerVisible(true);

          snackbar({
            variant: 'success',
            message:
              'Lançamento programado foi adicionado com sucesso! Agora você pode preencher as informações da nota fiscal',
          });

          return;
        }

        onClose();

        snackbar({
          variant: 'success',
          message: 'O novo lançamento programado foi adicionado com sucesso!',
        });
      },
      onError: ({ response }: ApiError<ApiErrorForm>) => {
        snackbar({
          variant: 'error',
          message: 'Ocorreu um erro ao criar o lançamento programado!',
        });

        if (isStructuredFormError(response?.data)) {
          setErros(response?.data.errors);
        }
      },
    };

    if (type === BalanceType.DEBIT) {
      const payload = {
        ...buildSubmitParams({
          ...params,
          attachments,
          type,
        }),
        organizationId,
        companyId,
      };

      if (isRecurrent) {
        return createRecurringBill(payload, callbacks);
      }

      return createBill(payload, callbacks);
    }

    if (type === BalanceType.CREDIT) {
      const payload = {
        ...params,
        organizationId,
        companyId,
        attachmentsIds: attachments?.map((a) => a.id),
      };

      if (isRecurrent) {
        return createRecurringReceivable(payload, callbacks);
      }

      return createReceivable(payload, callbacks);
    }
  }

  function getHiddenFields(): EntryFormHiddenFields | undefined {
    if (isEnabledBillsApprovalsRules) {
      return [...(hiddenFields || []), 'assigneesIds', 'approvalType'];
    }

    return hiddenFields;
  }

  const originValues = useMemo(
    () => ({
      id: 'new_scheduled_entry',
      frequencyUnit: 'months',
      ...initialValues,
    }),
    [initialValues]
  );

  const receivableToLink = useMemo(() => {
    return receivable || recurringReceivable?.receivables[0];
  }, [receivable, recurringReceivable]);

  return (
    <>
      {isCreateScheduledDrawerVisible && (
        <Drawer
          title={
            <DrawerHeader
              title={<Title icon={<CalendarOutlined />}>{title}</Title>}
            />
          }
          footer={null}
          onClose={onClose}
          visible={open}
          afterVisibleChange={onExit}
        >
          <EntryForm
            isFormDirty={isFormDirty}
            onFieldsChange={() => setIsFormDirty(true)}
            organizationId={organizationId}
            companyId={companyId}
            initialValues={originValues}
            onSubmit={handleCreateScheduledEntry}
            loading={isLoading}
            errors={errors}
            hiddenFields={getHiddenFields()}
            hiddenActions={hiddenActions}
          />
        </Drawer>
      )}

      {isCreateNotaFiscalDrawerVisible && (
        <CreateNotaFiscalDrawer
          initialValues={{
            amount: receivableToLink?.amount,
            contactId: receivableToLink?.contactId!,
            receivableId: receivableToLink?.id,
          }}
          onClose={onClose}
        />
      )}
    </>
  );
}
