import { formLabelClasses, paperClasses } from '@mui/material';
import type { DataGridPremiumComponents } from '@mui/x-data-grid-premium/themeAugmentation';
import type { ThemeConfig } from 'src/theme';

export function createMuiDataGrid({
  palette,
  typography,
  shadows,
}: ThemeConfig): DataGridPremiumComponents['MuiDataGrid'] {
  return {
    styleOverrides: {
      columnHeader: {
        transition: 'all 0.3s ease-in-out',
      },
      root: {
        borderColor: 'transparent',
      },
      row: {
        [`&:nth-of-type(odd):not(:hover):not(.Mui-selected)`]: {
          backgroundColor: palette.grey?.[50],
        },
      },
      cell: {
        ...typography['text-sm'],
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.3s ease-in-out',
      },
      panelWrapper: {
        padding: '16px',
        boxShadow: 'none',
      },
      filterForm: { alignItems: 'flex-end', gap: '8px', padding: '8px 4px' },
      filterFormDeleteIcon: { marginBottom: '6px' },
      panelHeader: {
        [`& .${formLabelClasses.root}`]: {
          display: 'none !important',
        },
      },
      menu: {
        [`.${paperClasses.root}`]: {
          paddingInline: '8px',
          border: `1px solid ${palette.divider}`,
          boxShadow: shadows[4],

          '.MuiList-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          },
        },
      },
      menuList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      },
    },
  };
}
