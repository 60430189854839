import { Menu } from 'antd';
import {
  DisconnectOutlined,
  LinkOutlined,
  MailOutlined,
  StarOutlined,
  StopOutlined,
} from '@ant-design/icons';
import compact from 'lodash/compact';

import { snackbar } from 'src/mui';

import { DropdownActions } from 'src/ui';

import type { UserWithPermissions } from 'src/libs/finbits/Organization/Users/types';
import { UserStatus } from 'src/libs/finbits/Organization/Users/types';
import { useProfile } from 'src/libs/finbits/Organization/Users';
import { OrganizationRole } from 'src/libs/finbits/Permissions';
import { useResendInvitation } from 'src/libs/finbits/Organization/Invitation';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useMenuItem } from 'src/libs/finbits/MenuItem/useMenuItem';

import showsInvitationSuccessNotification from 'src/features/users/CreateInvitationForm/InvitationSuccessNotification';

import useDeactivateModal from './useDeactivateModal';
import useActivateModal from './useActivateModal';
import useMakeAdminModal from './useMakeAdminModal';
import useRemoveAdminModal from './useRemoveAdminModal';

type Props = {
  user: UserWithPermissions;
};

export default function Actions({ user }: Props) {
  const { organizationId } = useCompanyParams();
  const { user: currentUser } = useProfile();

  const { openActivateConfirmation } = useActivateModal({
    user,
  });

  const { openDeactivateConfirmation } = useDeactivateModal({
    user,
  });

  const { openMakeAdminConfirmation } = useMakeAdminModal({
    user,
  });

  const { openRemoveAdminConfirmation } = useRemoveAdminModal({
    user,
  });

  const { resendInvitation } = useResendInvitation();

  function handleResendInvitation() {
    resendInvitation(
      {
        userId: user.id,
        organizationId,
      },
      {
        onSuccess: () => {
          showsInvitationSuccessNotification(user);
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Ocorreu um erro ao reenviar o convite. Tente novamente em instantes.',
          });
        },
      }
    );
  }

  const isCurrentUser = currentUser?.id === user.id;

  const organizationRole = user.organizationPermission.role;

  const itemsAdmin = {
    [OrganizationRole.ADMIN]: [
      useMenuItem({
        menuItem: {
          'aria-label': 'Remover administrador',
          key: 'removeAdmin',
          label: 'Remover administrador',
          icon: <StopOutlined />,
          disabled: isCurrentUser,
          onClick: openRemoveAdminConfirmation,
        },
        permissions: {
          action: 'update',
          resource: 'userOrganizationPermissions',
          scope: 'organization',
        },
      }),
    ],
    [OrganizationRole.MEMBER]: [
      useMenuItem({
        menuItem: {
          'aria-label': 'Tornar administrador',
          key: 'makeAdmin',
          label: 'Tornar administrador',
          icon: <StarOutlined />,
          disabled: isCurrentUser,
          onClick: openMakeAdminConfirmation,
        },
        permissions: {
          action: 'update',
          resource: 'userOrganizationPermissions',
          scope: 'organization',
        },
      }),
    ],
  };

  const items = {
    [UserStatus.ACTIVE]: [
      ...itemsAdmin[organizationRole],
      useMenuItem({
        menuItem: {
          'aria-label': 'Desativar',
          key: 'deactivate',
          label: 'Desativar',
          icon: <DisconnectOutlined />,
          disabled: isCurrentUser,
          onClick: openDeactivateConfirmation,
        },
        permissions: {
          action: 'deactivate',
          resource: 'users',
          scope: 'organization',
        },
      }),
    ],
    [UserStatus.INACTIVE]: [
      useMenuItem({
        menuItem: {
          'aria-label': 'Reativar',
          key: 'activate',
          label: 'Reativar',
          icon: <LinkOutlined />,
          onClick: openActivateConfirmation,
        },
        permissions: {
          action: 'activate',
          resource: 'users',
          scope: 'organization',
        },
      }),
    ],
    [UserStatus.PENDING]: [
      useMenuItem({
        menuItem: {
          'aria-label': 'Reenviar convite',
          key: 'resendInvitation',
          label: 'Reenviar convite',
          icon: <MailOutlined />,
          onClick: handleResendInvitation,
        },
        permissions: {
          action: 'update',
          resource: 'invitations',
          scope: 'organization',
        },
      }),
    ],
  };

  const itemsFromStatus = compact(items[user.status]);

  if (itemsFromStatus.length === 0) {
    return null;
  }

  return <DropdownActions overlay={<Menu items={itemsFromStatus} />} />;
}
