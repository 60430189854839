import type { ReactNode } from 'react';

import cn from 'classnames';
import type { ModalProps } from 'antd';
import { Modal as AntModal } from 'antd';

import styles from './Modal.module.less';

export type Props = ModalProps & {
  children: ReactNode;
  width?: number;
};

export default function Modal({
  children,
  className,
  width = 448,
  ...props
}: Props) {
  return (
    <AntModal
      {...props}
      className={cn(styles.modal, className)}
      centered
      width={width}
    >
      {children}
    </AntModal>
  );
}
