import type { DefaultOptions } from 'react-query';
import { QueryClient } from 'react-query';

import type { ApiError } from '../finbits/client';

const MAX_FAILED_FETCH_RETRIES = 3;

const isProduction = import.meta.env.MODE === 'production';

export const queryConfigDefault: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      const apiError = error as ApiError;
      const statusCode = apiError?.response?.status ?? 0;
      const isInternalServerError = statusCode >= 500;
      const isLessThanMaxFailureCount = failureCount < MAX_FAILED_FETCH_RETRIES;

      const canRetry =
        isProduction && isInternalServerError && isLessThanMaxFailureCount;

      return canRetry;
    },
  },
  mutations: {
    retry: false,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfigDefault,
});
