export const sourceDictionary: Record<string, string> = {
  email: 'E-mail',
  nota_fiscal: 'Nota Fiscal',
  product_nota_fiscal: 'Nota Fiscal de Produto',
  upload: 'Upload',
  whatsapp: 'WhatsApp',
  purchase_order: 'Formulário',
  dda: 'DDA',
  contact: 'Contato',
};
