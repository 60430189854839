import { useState } from 'react';

import type { AutocompleteProps, TextFieldProps } from '@mui/material';
import { Box, Autocomplete as MUIAutocomplete, TextField } from '@mui/material';
import merge from 'lodash/merge';

import { paper, popper, textField } from './AutocompleteSearch.sx';

type Props<
  T = unknown,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = {
  TextFieldProps?: TextFieldProps;
} & Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
>;

const SLOT_PROPS: Props['slotProps'] = {
  popper: {
    sx: popper,
  },
  paper: {
    sx: paper,
  },
};

export default function Search<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  PaperComponent = Box,
  renderOption,
  slotProps = SLOT_PROPS,
  TextFieldProps,
  ...rest
}: Props<T, Multiple, DisableClearable, FreeSolo>) {
  const [inputValue, setInputValue] = useState('');

  return (
    <MUIAutocomplete
      {...rest}
      PaperComponent={PaperComponent}
      slotProps={slotProps}
      inputValue={inputValue}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          {...TextFieldProps}
          sx={merge(textField, TextFieldProps?.sx)}
          InputProps={{
            ...InputProps,
            ...TextFieldProps?.InputProps,
          }}
          onChange={(event) => setInputValue(event.currentTarget.value)}
        />
      )}
      ListboxProps={{
        sx: { pt: 0 },
      }}
      noOptionsText="Sem opções"
      renderOption={(props, value, state, ownerState) => (
        <Box component="li" {...props}>
          {renderOption && renderOption(props, value, state, ownerState)}
        </Box>
      )}
    />
  );
}
