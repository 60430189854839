import type { Components } from '@mui/material';
import type { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import type { ThemeConfig } from 'src/theme';

export function createMuiInput({
  typography,
}: ThemeConfig): Components['MuiInput'] {
  return {
    defaultProps: { disableUnderline: true },
    styleOverrides: {
      input: typography['text-sm'] as TypographyStyleOptions,
    },
  };
}
