import { Button, Divider, Stack } from '@mui/material';
import cn from 'classnames';
import { SparklesIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import {
  NavigateAction,
  useNavigateAction,
} from 'src/router/useNavigateAction/useNavigateAction';
import { RouteKey } from 'src/router/types';

import illustration from 'src/assets/finbot-banner.svg';

import analytics from 'src/libs/analytics';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import styles from './FinbotBanner.module.scss';

type Props = {
  big?: boolean;
};

export default function FinbotBanner({ big = false }: Props) {
  const { companyId } = useCompanyParams();
  const navigateTo = useNavigateAction(
    RouteKey.ENTRIES,
    NavigateAction.OPEN_FINBOT
  );

  function handleClick() {
    analytics.track('FinBot Banner Button Clicked', { company_id: companyId });
    navigateTo();
  }

  const { isEnabled: isFinbotEnabled } = useExternalFeatureFlag(Feature.FINBOT);

  if (!isFinbotEnabled) return null;

  return (
    <>
      <Stack className={styles.container} direction="row" gap={8}>
        <Stack
          className={cn(styles.leftContainer, { [styles.big]: big })}
          direction="row"
          gap={6}
        >
          <SparklesIcon
            className={cn(styles.icon, { [styles.big]: big })}
            strokeWidth="1"
          />
          <Typography
            fontWeight="600"
            className={cn(styles.header, { [styles.big]: big })}
          >
            Nossa{' '}
            <span className={styles.brandText}>inteligência artificial</span>
            <br /> a serviço das suas finanças
          </Typography>
        </Stack>
        <Stack
          direction={big ? 'column' : 'row'}
          gap={6}
          className={cn(styles.rightContainer, { [styles.big]: big })}
        >
          <Typography
            className={styles.paragraph}
            textAlign={{ xs: 'left', xl: 'center' }}
          >
            Acesse informações rápidas e obtenha insights em tempo real com a{' '}
            <b>FinBot</b>.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={handleClick}
          >
            Experimente já!
          </Button>
        </Stack>
        {big && (
          <img
            src={illustration}
            alt="Ilustração com uma pessoa lendo dados de uma tela com ajuda de AI"
            className={styles.image}
          />
        )}
      </Stack>
      <Divider />
    </>
  );
}
