import cn from 'classnames';
import type { DialogActionsProps } from '@mui/material';
import { DialogActions } from '@mui/material';

import styles from './Actions.module.scss';

export type DialogActionsSize = 'small' | 'medium';

type Props = {
  size?: DialogActionsSize;
  className?: string;
} & DialogActionsProps;

export default function Actions({
  size = 'medium',
  children,
  className,
  ...rest
}: Props) {
  return (
    <DialogActions
      className={cn(className, styles.actions, {
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
      })}
      {...rest}
    >
      {children}
    </DialogActions>
  );
}
