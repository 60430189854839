import type { ReactNode } from 'react';

import { Box } from '@mui/material';
import { Typography } from 'src/design-system/components';

import emptyStateIllustration from 'src/assets/empty-state-table.svg';

type Props = {
  children?: ReactNode;
  hideIllustration?: boolean;
  illustration?: string;
  subTitle?: ReactNode | JSX.Element;
  title?: string;
};

export default function EmptyState({
  children,
  hideIllustration = false,
  illustration = emptyStateIllustration,
  subTitle,
  title,
}: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      height="100%"
    >
      {!hideIllustration && (
        <img
          srcSet={illustration}
          src={illustration}
          alt={title || 'Empty State'}
          loading="lazy"
        />
      )}

      {title && (
        <Typography variant="text-md" fontWeight="600" component="h2">
          {title}
        </Typography>
      )}

      {subTitle && <Typography align="center">{subTitle}</Typography>}

      {children}
    </Box>
  );
}
