import type { ReactNode } from 'react';

import type { ButtonProps } from 'antd';
import { Button, Tooltip } from 'antd';
import type { TooltipPlacement } from 'antd/lib/tooltip';

import styles from './DrawerHeaderButton.module.less';

type Props = {
  title?: string;
  onClick?: () => void;
  icon?: ReactNode;
  text?: string;
  tooltipPlacement?: TooltipPlacement;
  disabled?: boolean;
} & ButtonProps;

export default function DrawerHeaderButton({
  title = '',
  onClick,
  text,
  icon,
  tooltipPlacement = 'bottom',
  disabled = false,
  ...rest
}: Props) {
  return (
    <Tooltip
      title={title}
      placement={tooltipPlacement}
      className={styles.button}
    >
      <Button icon={icon} onClick={onClick} disabled={disabled} {...rest}>
        {text}
      </Button>
    </Tooltip>
  );
}
