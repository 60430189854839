import t from 'zod';
import type { StaticPixEmvElements } from 'pix-utils';

import { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

export enum PixType {
  CPF = 'cpf',
  CNPJ = 'cnpj',
  CELLPHONE_NUMBER = 'cellphone_number',
  EMAIL = 'email',
  RANDOM = 'random',
}

export const PixKeyDecoder = t.object({
  pixKey: t.object({
    id: t.string(),
    key: t.string(),
    type: t.nativeEnum(PixType),
    branchNumber: t.string(),
    accountNumber: t.string(),
    ownerTaxIdText: t.string(),
    ownerType: t.nativeEnum(ContactType),
    ownerName: t.string(),
    updatedAt: t.string(),
    bankName: t.string(),
  }),
  matchesContact: t.boolean(),
});

export type PixKey = t.TypeOf<typeof PixKeyDecoder>;

export type PixKeyPostParams = {
  organizationId: string;
  companyId: string;
  key: string;
  type: PixType;
  contactType?: ContactType | null;
  contactDocument?: string | null;
};

export type ParsedQrCode = {
  error: boolean;
  parsedQrCode?: StaticPixEmvElements;
};
