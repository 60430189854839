import type { ReactNode } from 'react';

import { Box, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import { FocusIcon } from 'src/mui';

import styles from './Message.module.scss';

type Props = {
  title: string;
  subtitle: string;
  icon?: ReactNode;
  image?: string;
  children?: ReactNode;
  alt?: string;
  maxWidth?: number;
};

export default function Message({
  title,
  subtitle,
  icon,
  image,
  children,
  alt,
  maxWidth = 390,
}: Props) {
  return (
    <Stack className={styles.stack}>
      {icon && (
        <FocusIcon size="xl" className={styles.icon}>
          {icon}
        </FocusIcon>
      )}
      {image && (
        <Box
          component="img"
          src={image}
          alt={alt}
          className={styles.icon}
          draggable={false}
        />
      )}

      <Stack maxWidth={maxWidth} className={styles.stackText}>
        <Typography
          component="h2"
          variant="text-lg"
          fontWeight="600"
          color="grey.900"
        >
          {title}
        </Typography>

        <Typography color="text.secondary">{subtitle}</Typography>
      </Stack>

      {children}
    </Stack>
  );
}
