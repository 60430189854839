import type { RcFile } from 'rc-upload/lib/interface';

import { snackbar } from 'src/mui';

import { isValidSize } from 'src/features/attachments/UploadAttachments/Validators';

function isOfxFile(file: RcFile) {
  return file.name.match(/\.ofx$/i);
}

export function validateBeforeUpload(file: RcFile) {
  if (!isOfxFile(file)) {
    snackbar({
      variant: 'error',
      message: 'Arquivo inválido! 👉 Revise o formato: OFX',
    });
    return false;
  }

  if (!isValidSize(file)) {
    snackbar({
      variant: 'error',
      message: 'O tamanho do arquivo ultrapassa o limite de 20MB.',
    });

    return false;
  }

  return true;
}
