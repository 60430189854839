import type { ReactNode } from 'react';

import type { ChipProps } from 'src/design-system/components/types';
import { Chip } from 'src/design-system/components';

type Props = {
  windows: ReactNode;
  macOS: ReactNode;
} & ChipProps;

export default function KeyboardShortcut({
  windows,
  macOS,
  size = 'medium',

  ...rest
}: Props) {
  const label = navigator.userAgent.includes('Mac') ? macOS : windows;

  return <Chip label={label} size={size} square {...rest} />;
}
