import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { Loader } from 'src/ui';

import { renewAuth, useIsInitialized } from 'src/libs/finbits/Auth';

type Props = {
  children: ReactNode;
};

export default function AuthRenewer({ children }: Props) {
  const isInitialized = useIsInitialized();

  useEffect(() => {
    if (isInitialized === false) {
      renewAuth();
    }
  }, [isInitialized]);

  if (!isInitialized) {
    return <Loader size="large" full />;
  }

  return <>{children}</>;
}
