import t from 'zod';

export const NotaFiscalConnectionDecoder = t.object({
  certificateExpiration: t.string().nullable(),
  status: t.union([
    t.literal('unset'),
    t.literal('activating'),
    t.literal('active'),
  ]),
  monitoringEnabled: t.boolean(),
  monitoringProductEnabled: t.boolean(),
});

export type NotaFiscalConnection = t.TypeOf<typeof NotaFiscalConnectionDecoder>;
