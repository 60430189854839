import { useQuery } from 'react-query';

import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import { SpecificationsDecoder } from './types';
import type { Specifications } from './types';

type Options = {
  cityCode?: string | null;
};

async function getSpecifications({ cityCode }: Options) {
  const url = `/nota_fiscal/cities/${cityCode}/specifications`;

  const response = await authenticatedAPI.get(url);

  return decodeResponse<Specifications>(response, SpecificationsDecoder);
}

export function useSpecifications({ cityCode }: Options) {
  const { data, ...rest } = useQuery({
    enabled: !!cityCode,
    queryKey: ['specifications'],
    queryFn: () =>
      getSpecifications({
        cityCode,
      }),
  });

  return {
    specifications: data,
    ...rest,
  };
}
