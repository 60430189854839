import { createContext, useContext } from 'react';

import type { EditableFields } from 'src/features/EntryForm/types';

import type {
  EditableFieldsContextProps,
  EditableFieldsProviderProps,
} from './types';

const EditableFieldsContext = createContext<
  EditableFieldsContextProps | undefined
>(undefined);

export default function EditableFieldsProvider({
  children,
  editableFields,
  isEditMode,
}: EditableFieldsProviderProps) {
  function isDisabledField(fieldName: EditableFields) {
    if (!isEditMode) {
      return false;
    }

    return !editableFields?.includes(fieldName);
  }

  return (
    <EditableFieldsContext.Provider
      value={{
        editableFields,
        isEditMode,
        isDisabledField,
      }}
    >
      {children}
    </EditableFieldsContext.Provider>
  );
}

export function useEditableFieldsContext() {
  const context = useContext(EditableFieldsContext);

  if (!context) {
    throw new Error(
      'To use context, you must provide a EditableFieldsProvider'
    );
  }

  return context;
}
