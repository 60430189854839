import { useMemo, useState } from 'react';

import { Button, DialogActions, DialogContent, Stack } from '@mui/material';

import type { PortalProps } from 'src/ui';

import {
  canPerformAction,
  isOverDue,
} from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { type BillPayable, BillStatus } from 'src/libs/finbits/Bills/types';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { WaitingPaymentParams } from 'src/libs/finbits/PaymentsFlow/types';
import {
  BillsApprovalsType,
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { useGetBills } from 'src/libs/finbits/Bills';

import BtgAlert from 'src/features/open-banking/BtgAlert';
import OverdueAlert from 'src/features/bills-to-pay/PaymentReviewModal/PaymentReviewModalContent/OverdueAlert';

import AutomaticPayment from './AutomaticPayment';
import ManualPayment from './ManualPayment';
import styles from './PaymentReviewModalContent.module.scss';

type Props = {
  selecteds: BillPayable[];
  onConfirm: (params: WaitingPaymentParams) => void;
} & Pick<PortalProps, 'onClose'>;

function splitIntoManualOrAutomatic(bills: BillPayable[]) {
  return bills.reduce<{
    manualPayments: BillPayable[];
    automaticPayments: BillPayable[];
  }>(
    (acc, bill) => {
      if (canPerformAction(bill, AvailableActions.SEND_TO_AUTOMATIC_PAYMENT)) {
        acc.automaticPayments.push(bill);
      } else {
        acc.manualPayments.push(bill);
      }

      return acc;
    },
    {
      manualPayments: [],
      automaticPayments: [],
    }
  );
}

export default function PaymentReviewModalContent({
  selecteds,
  onClose,
  onConfirm,
}: Props) {
  const [{ manualPayments, automaticPayments }, setPayments] = useState(
    splitIntoManualOrAutomatic(selecteds)
  );
  const [selectedIds, setSelectedIds] = useState(
    automaticPayments.map((bill) => bill.id)
  );

  const { companyId, organizationId } = useCompanyParams();
  const { company } = useCompany({ organizationId, companyId });
  const billsCurrentStatus =
    company?.billsApprovals === BillsApprovalsType.DISABLED
      ? BillStatus.PENDING
      : BillStatus.APPROVED;

  useGetBills(
    { companyId, organizationId, status: billsCurrentStatus },
    {
      onSuccess: (data) => {
        const initialSelectedIds = selecteds.map((bill) => bill.id);
        const { automaticPayments, manualPayments } =
          splitIntoManualOrAutomatic(
            data.filter((bill) => initialSelectedIds.includes(bill.id))
          );

        setPayments({ automaticPayments, manualPayments });
        setSelectedIds(
          selectedIds.filter((id) =>
            automaticPayments.find((bill) => bill.id === id)
          )
        );
      },
    }
  );

  function handleConfirm() {
    const automaticDeselectedIds = automaticPayments
      .filter((automatic) => !selectedIds.includes(automatic.id))
      .map((bill) => bill.id);

    const toManualIds = manualPayments.map((manual) => manual.id);

    onConfirm({
      toAutomaticIds: selectedIds,
      toManualIds: [...toManualIds, ...automaticDeselectedIds],
    });
    onClose();
  }

  const hasAutomatics = Boolean(automaticPayments.length);
  const hasManuals = Boolean(manualPayments.length);

  const hasOverdueBills = useMemo(() => {
    return manualPayments.some((bill) => isOverDue(bill.date));
  }, [manualPayments]);

  return (
    <>
      <DialogContent dividers>
        {hasAutomatics && (
          <AutomaticPayment
            automaticPayments={automaticPayments}
            renderAsList={hasManuals}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        )}

        {!hasAutomatics && <BtgAlert />}

        {hasManuals && (
          <Stack gap={2}>
            <ManualPayment
              manualPayments={manualPayments}
              renderAsList={hasAutomatics}
            />

            {hasOverdueBills && <OverdueAlert hasAutomatics={hasAutomatics} />}
          </Stack>
        )}
      </DialogContent>

      <DialogActions className={styles.dialogActions}>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          Enviar para pagamento
        </Button>
      </DialogActions>
    </>
  );
}
