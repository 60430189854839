import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function SlashCircleIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="slash-circle"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        id="Icon"
        d="M5.43 5.43L19.57 19.57M22.5 12.5C22.5 18.0228 18.0228 22.5 12.5 22.5C6.97715 22.5 2.5 18.0228 2.5 12.5C2.5 6.97715 6.97715 2.5 12.5 2.5C18.0228 2.5 22.5 6.97715 22.5 12.5Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
