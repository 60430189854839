import { useMemo } from 'react';

import isArray from 'lodash/isArray';
import intersection from 'lodash/intersection';

import { useOptionalCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useProfile } from 'src/libs/finbits/Organization/Users';
import type {
  User,
  UserWithPermissions,
} from 'src/libs/finbits/Organization/Users/types';

import type { Permissions, Resource } from './types';
import { CompanyRole, PermissionScope } from './types';
import { companyRoleDictionary } from './translation';

type Params = {
  allowedActions: Record<string, string[]>;
  action: string | string[];
  resource: Resource;
};

function isAllowed({ allowedActions, action, resource }: Params) {
  const actions = typeof action === 'string' ? [action] : action;
  const resourceActions = allowedActions[resource];

  return (
    Boolean(resourceActions) &&
    Boolean(intersection(resourceActions, actions).length)
  );
}

function getAllowedActionsByScope(
  user: UserWithPermissions,
  scope: keyof typeof PermissionScope = 'company',
  companyId?: string
) {
  if (scope === PermissionScope.organization) {
    if (!user?.organizationPermission) {
      return {};
    }

    return user?.organizationPermission.actions ?? {};
  }

  const companyPermission = getCompanyPermissionFromUser(user, companyId);

  return companyPermission?.actions ?? {};
}

export function useAllowedPermission(
  permissions?: Permissions | Permissions[]
): boolean {
  const permissionsArray = useMemo(
    () => (isArray(permissions) ? permissions : [permissions]),
    [permissions]
  );

  const { user } = useProfile();
  const { companyId: currentCompanyId } = useOptionalCompanyParams();

  return useMemo(() => {
    if (!user || permissionsArray.length === 0) {
      return false;
    }

    return permissionsArray.some((permission) => {
      const allowedActions = getAllowedActionsByScope(
        user,
        permission?.scope,
        permission?.companyId ?? currentCompanyId
      );

      return isAllowed({
        allowedActions,
        action: permission?.action!,
        resource: permission?.resource!,
      });
    });
  }, [user, currentCompanyId, permissionsArray]);
}

export function getCompanyPermissionFromUser(user?: User, companyId?: string) {
  if (!user || !companyId) return null;

  const permission = user.companiesPermissions?.find(
    (companyPermission) => companyPermission.companyId === companyId
  );

  return permission;
}

export const roleOptions = Object.values(CompanyRole).map((role) => ({
  value: role,
  label: companyRoleDictionary[role],
}));
