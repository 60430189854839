import type { QueryKey, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import { ONE_HOUR_IN_MS } from 'src/libs/finbits/Time';

import { CitiesDecoder } from './types';
import type { Cities } from './types';

type Params = {
  city?: string | null;
  state?: string | null;
};

async function getAllowedCities() {
  const url = '/nota_fiscal/cities';

  const response = await authenticatedAPI.get(url);

  return decodeResponse<Cities>(response, CitiesDecoder);
}

export function useAllowCityNotaFiscal(
  { city, state }: Params,
  options?: UseQueryOptions<Cities, unknown, boolean, QueryKey>
) {
  const hasParams = !!city && !!state;

  const { data = false, ...rest } = useQuery({
    queryKey: ['nota_fiscal_cities'],
    enabled: hasParams,
    queryFn: getAllowedCities,
    select: (data) =>
      hasParams ? data[state!]?.cities.includes(city!) : false,
    staleTime: ONE_HOUR_IN_MS,
    ...options,
  });

  return { isCityAllowed: data, ...rest };
}
