import { useEffect, useState } from 'react';

import cn from 'classnames';
import { Input } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import ptBR from 'antd/lib/date-picker/locale/pt_BR';
import type {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from 'antd/lib/date-picker/generatePicker';

import { masker } from 'src/libs/finbits/Masker';
import { format as formatDate, isValid, parse } from 'src/libs/finbits/Date';

import DatePicker from '../DatePicker';

import styles from './MaskedDatePicker.module.less';

type Props = { dataTestId?: string } & (
  | PickerDateProps<Date>
  | PickerBaseProps<Date>
  | PickerTimeProps<Date>
);

export default function MaskedDatePicker({
  placeholder,
  suffixIcon,
  className,
  size,
  disabled,
  onChange,
  onBlur,
  format,
  value,
  dataTestId = 'datepicker-masked-input',
  ...props
}: Props) {
  const inputMaskFromFormat =
    typeof format === 'string' ? format.replace(/[A-Z]/gi, '9') : '99/99/9999';
  const inputFormat = typeof format === 'string' ? format : 'dd/MM/yyyy';

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>(
    value ? formatDate(value, inputFormat) : undefined
  );

  useEffect(() => {
    setInputValue(value ? formatDate(value, inputFormat) : undefined);
  }, [value, inputFormat]);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value) {
      if (
        onChange &&
        event.target.value.length === inputMaskFromFormat.length
      ) {
        const date = parse(event.target.value, inputFormat, new Date());

        if (!isValid(date)) return;

        const dateString = formatDate(date, inputFormat);

        onChange(date, dateString);
      }

      return setInputValue(
        masker(event.target.value as string, inputMaskFromFormat)
      );
    } else {
      setInputValue(undefined);
      onChange && onChange(null, '');
    }
  }

  function handlePickerChange(date: Date | null, dateString: string) {
    setInputValue(date ? formatDate(date, inputFormat) : undefined);
    onChange && onChange(date, dateString);
  }

  function handleOnBlur(event: React.FocusEvent<HTMLInputElement, Element>) {
    setOpen(false);
    onBlur && onBlur(event);
  }

  const suffix = (
    <span className={styles.maskedInputIcon}>
      {suffixIcon ? suffixIcon : <CalendarOutlined />}
    </span>
  );

  return (
    <div onClick={() => setOpen(true)} onBlur={handleOnBlur}>
      <Input
        size={size}
        suffix={suffix}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        disabled={disabled}
        data-testid={dataTestId}
        aria-hidden
      />
      <DatePicker
        {...props}
        className={cn([className, styles.maskedDatePicker])}
        onChange={handlePickerChange}
        disabled={disabled}
        value={value}
        open={open}
        onOpenChange={(isOpen) => setOpen(isOpen)}
        locale={ptBR}
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      />
    </div>
  );
}
