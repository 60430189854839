import type { Ref } from 'react';
import { forwardRef } from 'react';

import type { InputUnstyledProps } from '@mui/base/InputUnstyled';
import InputUnstyled from '@mui/base/InputUnstyled';
import cn from 'classnames';

import styles from './Input.module.scss';

function Input(
  {
    onChange,
    slotProps,
    endAdornment,
    ownerState,
    bordered = true,
    disabled = false,
    className,
    ...props
  }: { ownerState?: null; bordered?: boolean } & InputUnstyledProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <InputUnstyled
      {...props}
      endAdornment={
        endAdornment && (
          <div className={styles.endAdornment}>{endAdornment}</div>
        )
      }
      slotProps={{
        root: ({ focused, error }) => {
          const inputStyle = cn(styles.root, {
            [styles.focused]: focused,
            [styles.error]: error,
            [styles.bordered]: bordered,
            [styles.disabled]: disabled,
          });

          return {
            ...slotProps?.root,
            className: inputStyle,
          };
        },
        input: {
          ...slotProps?.input,
          ref,
          onChange,
          disabled,
          className: cn(styles.input, className),
        },
      }}
    />
  );
}

export default forwardRef(Input) as typeof Input;
