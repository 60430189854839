import t from 'zod';

export enum BalanceType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export const BalanceInconsistencyDecoder = t.object({
  account: t.object({
    id: t.string().nullable(),
    routingNumber: t.string().nullable(),
    branchNumber: t.string().nullable(),
    accountNumber: t.string().nullable(),
    name: t.string().nullable(),
    synchronizedAt: t.string().nullable(),
  }),
  inconsistency: t.object({
    inconsistencyValue: t.object({
      type: t.nativeEnum(BalanceType),
      amount: t.number(),
    }),
    lastAccountBalance: t.object({
      type: t.nativeEnum(BalanceType),
      amount: t.number(),
    }),
    lastReportedBalance: t.object({
      type: t.nativeEnum(BalanceType),
      amount: t.number(),
    }),
  }),
});

export type BalanceInconsistency = t.TypeOf<typeof BalanceInconsistencyDecoder>;
