import type { ReactNode } from 'react';

import { Navigate, useSearchParams } from 'react-router-dom';

import { Loader } from 'src/ui';

import { useIsAuthenticated } from 'src/libs/finbits/Auth';
import { useProfileInitialCompanyParams } from 'src/libs/finbits/Organization/Users';

type Props = {
  children: ReactNode;
};

export default function PublicAuthenticationRoute({ children }: Props) {
  const isAuthenticated = useIsAuthenticated();

  const [searchParams] = useSearchParams();

  const { companyId, organizationId, isLoading } =
    useProfileInitialCompanyParams();

  const hasCompany = !!companyId;
  const afterLoginRedirectTo = searchParams.get('afterLoginRedirectTo');

  if (isAuthenticated && isLoading) {
    return <Loader size="large" full />;
  }

  if (isAuthenticated && !hasCompany) {
    return <Navigate replace to={`/organizations/${organizationId}/profile`} />;
  }

  if (isAuthenticated) {
    const url =
      afterLoginRedirectTo ??
      `/organizations/${organizationId}/companies/${companyId}`;

    return <Navigate replace to={url} />;
  }

  return <>{children}</>;
}
