import { Col, Row, Space, Table, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { InfoCircleOutlined } from '@ant-design/icons/lib/icons';

import { Tag } from 'src/ui';

import type { UserWithPermissions } from 'src/libs/finbits/Organization/Users/types';
import { UserStatus } from 'src/libs/finbits/Organization/Users/types';
import {
  useCompanies,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { formatCNPJ } from 'src/libs/finbits/Documents';
import {
  CompanyRole,
  OrganizationRole,
  companyRoleDictionary,
  getCompanyPermissionFromUser,
  organizationRoleDictionary,
} from 'src/libs/finbits/Permissions';
import { useOrganizationUsers } from 'src/libs/finbits/Organization/Users';
import { userStatusDictionary } from 'src/libs/finbits/Organization/Users/translation';

import RoleSelect from 'src/features/users/RoleSelect/RoleSelect';
import InfoTooltip from 'src/features/users/UsersTable/InfoTooltip/InfoTooltip';

import Actions from './Actions';
import UserName from './UserName';
import styles from './UsersTable.module.less';

const userStatusFilter = Object.values(UserStatus).map((userStatus) => ({
  text: `${userStatusDictionary[userStatus]}s`,
  value: userStatus,
}));

const roleFilter = Object.values(CompanyRole).map((companyRole) => ({
  text: companyRoleDictionary[companyRole],
  value: companyRole,
}));

export default function UsersTable() {
  const { organizationId } = useCompanyParams();

  const { users, isLoading: isLoadingUsers } = useOrganizationUsers({
    organizationId,
  });

  const { companies, isLoading: isLoadingCompanies } = useCompanies({
    organizationId,
  });

  const isLoading = isLoadingUsers || isLoadingCompanies;

  const companiesColumns: ColumnsType<UserWithPermissions> = companies.map(
    (company) => ({
      key: company.name,
      title: (
        <Space align="center">
          <Typography.Text>{company.tradeName}</Typography.Text>
          <Tooltip title={formatCNPJ(company.document)} placement="bottom">
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      ),
      filters: [...roleFilter, { text: 'Sem permissão', value: '' }],
      filterMode: 'tree',
      onFilter: (value, record) => {
        const role = getCompanyPermissionFromUser(record, company.id)?.role;

        if (value === '') {
          return !role;
        }

        return role === value;
      },
      render: (user: UserWithPermissions) => (
        <RoleSelect user={user} companyId={company.id} />
      ),
    })
  );

  const columns: ColumnsType<UserWithPermissions> = [
    {
      key: 'user',
      title: (
        <Space align="center">
          <Typography.Text>Usuário</Typography.Text>
          <InfoTooltip />
        </Space>
      ),
      width: 284,
      filterMode: 'tree',
      filters: [
        ...userStatusFilter,
        { text: 'Administradores', value: OrganizationRole.ADMIN },
      ],
      onFilter: (value, record) => {
        if (value === OrganizationRole.ADMIN) {
          return record.organizationPermission?.role === value;
        }

        return record.status === value;
      },
      sorter: (a, b) => {
        const aNameOrEmail = a.name || a.email;
        const bNameOrEmail = b.name || b.email;

        return aNameOrEmail.localeCompare(bNameOrEmail);
      },
      render: (user: UserWithPermissions) => {
        const isPending = user.status === UserStatus.PENDING;

        return (
          <Row align="middle" gutter={32} justify="space-between">
            <Col>
              <Space direction="vertical" size={4} className={styles.userInfo}>
                {isPending ? (
                  <Tag color="orange">{userStatusDictionary.pending}</Tag>
                ) : (
                  <UserName user={user} />
                )}

                <Typography.Text
                  type="secondary"
                  ellipsis={{ tooltip: user.email }}
                >
                  {user.email}
                </Typography.Text>
              </Space>
            </Col>
            <Col>
              {user.organizationPermission?.role === OrganizationRole.ADMIN && (
                <Tag color="gold">{organizationRoleDictionary.admin}</Tag>
              )}
            </Col>
          </Row>
        );
      },
      fixed: 'left',
    },
    ...companiesColumns,
    {
      title: '',
      width: 32,
      shouldCellUpdate: () => false,
      render: (user: UserWithPermissions) => <Actions user={user} />,
      fixed: 'right',
    },
  ];

  return (
    <Table
      className={styles.table}
      columns={columns}
      dataSource={users}
      loading={isLoading}
      scroll={{ x: 'max-content' }}
      pagination={false}
      rowKey="id"
      size="small"
    />
  );
}
