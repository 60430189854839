import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

import styles from './AccountInfoIcon.module.less';

type Props = {
  account: Account;
};

function AccountInfoIcon({ account }: Props) {
  const isBtgAccount = Boolean(account.btgConnection);

  const titles = {
    mfa_required:
      'Sua conta é atualizada diariamente, mas pode ser que o banco solicite verificação em duas etapas, então você precisará autenticar para que a atualização seja feita',
    invalid_credentials:
      'Não foi possível realizar o acesso com as credenciais informadas, reconecte para reativar a atualização automática',
    active: isBtgAccount
      ? 'A atualização automática sincroniza suas transações bancárias com a plataforma em tempo real'
      : 'A atualização automática sincroniza suas transações bancárias com a plataforma algumas vezes ao dia',
    activating: null,
    expired:
      'A atualização automática sincroniza suas transações bancárias com a plataforma algumas vezes ao dia',
  };

  const status =
    account.openBankingConnection?.status || account.btgConnection?.status;

  if (status === 'activating') return null;

  return (
    <Tooltip title={status && titles[status]}>
      <InfoCircleOutlined className={styles.automaticSyncWarningIcon} />
    </Tooltip>
  );
}

export default AccountInfoIcon;
