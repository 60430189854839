export enum Taxes {
  IRRF = 'irrf',
  INSS = 'inss',
  CSLL = 'csll',
  COFINS = 'cofins',
  PIS = 'pis',
  ISS = 'iss',
}

export const TaxesRate: {
  [key in Taxes]: number;
} = {
  [Taxes.IRRF]: 1.5,
  [Taxes.INSS]: 11,
  [Taxes.CSLL]: 1,
  [Taxes.COFINS]: 3,
  [Taxes.PIS]: 0.65,
  [Taxes.ISS]: 0,
};
