import type { ReactNode } from 'react';

import { Navigate } from 'react-router-dom';

import { useInitialOrganizationRoute } from 'src/router/useInitialOrganizationRoute';

import { Loader } from 'src/ui';

import { useIsAuthenticated } from 'src/libs/finbits/Auth';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Permissions } from 'src/libs/finbits/Permissions/types';

type Props = {
  children: ReactNode;
  permissions?: Permissions;
};

export default function AuthenticatedPermissionRoute({
  children,
  permissions,
}: Props) {
  const isAuthenticated = useIsAuthenticated();

  const hasPermission = useAllowedPermission(permissions);

  const { path, isLoading, forceNavigate } = useInitialOrganizationRoute();

  if (isLoading) {
    return <Loader size="large" full />;
  }

  if (forceNavigate) {
    return <Navigate to={path} />;
  }

  if (isAuthenticated && permissions && !hasPermission) {
    return <Navigate to={path} />;
  }

  return <>{children}</>;
}
