import type { Components, PaletteColor } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiAlert({
  palette,
  typography,
}: ThemeConfig): Components['MuiAlert'] {
  const {
    25: successBackGround,
    300: successBorderColor,
    700: successColor,
  } = palette.success! as Partial<PaletteColor>;

  const {
    25: infoBackGround,
    300: infoBorderColor,
    700: infoColor,
  } = palette.info! as Partial<PaletteColor>;

  const {
    25: warningBackGround,
    300: warningBorderColor,
    700: warningColor,
  } = palette.warning! as Partial<PaletteColor>;

  const {
    25: errorBackGround,
    300: errorBorderColor,
    700: errorColor,
  } = palette.error! as Partial<PaletteColor>;

  const {
    50: defaultBackGround,
    300: defaultBorderColor,
    600: defaultColor,
  } = palette.grey! as Partial<PaletteColor>;

  return {
    styleOverrides: {
      standardSuccess: {
        backgroundColor: successBackGround,
        borderColor: successBorderColor,
        color: successColor,
      },
      standardInfo: {
        backgroundColor: infoBackGround,
        borderColor: infoBorderColor,
        color: infoColor,
      },
      standardWarning: {
        backgroundColor: warningBackGround,
        borderColor: warningBorderColor,
        color: warningColor,
      },
      standardError: {
        backgroundColor: errorBackGround,
        borderColor: errorBorderColor,
        color: errorColor,
      },
      outlinedSuccess: {
        borderColor: successBorderColor,
        color: successColor,
      },
      outlinedInfo: {
        borderColor: infoBorderColor,
        color: infoColor,
      },
      outlinedWarning: {
        borderColor: warningBorderColor,
        color: warningColor,
      },
      outlinedError: {
        borderColor: errorBorderColor,
        color: errorColor,
      },
      icon: {
        color: 'inherit !important',
      },
      root: {
        borderRadius: '12px',
        fontSize: typography['text-sm']?.fontSize,
        lineHeight: typography['text-sm']?.lineHeight,
        variants: [
          {
            props: { variant: 'standard' },
            style: { border: '1px solid' },
          },
          {
            props: { variant: 'filled' },
            style: { color: palette.common?.white },
          },
          {
            props: { severity: 'default', variant: 'standard' },
            style: {
              backgroundColor: defaultBackGround,
              borderColor: defaultBorderColor,
              color: defaultColor,
            },
          },
          {
            props: { severity: 'default', variant: 'outlined' },
            style: {
              border: '1px solid',
              borderColor: defaultBorderColor,
              color: defaultColor,
            },
          },
          {
            props: { severity: 'default', variant: 'filled' },
            style: {
              backgroundColor: defaultBackGround,
              borderColor: defaultBorderColor,
              color: defaultColor,
            },
          },
        ],
      },
    },
  };
}
