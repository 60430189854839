import { TrashIcon } from 'src/mui/_icons';
import { LoadingButton } from '@mui/lab';

import { snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { useDeleteManyBills } from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';

import { useOpenConfirmSoftDeleteDialog } from 'src/features/entries/Dialogs';
import ReviewModal from 'src/features/bills-to-pay/TabActions/DEPRECATED_ReviewModal';

type Props = {
  bills: BillPayable[];
  onSuccess: () => void;
};

export default function DeleteBillsButton({ bills, onSuccess }: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { deleteManyBills, isLoading } = useDeleteManyBills();

  const openPortal = useOpenPortal();

  const openConfirmDeletionDialog = useOpenConfirmSoftDeleteDialog();

  const { billsAvailable, billsNotAvailable } = bills.reduce<{
    billsAvailable: BillPayable[];
    billsNotAvailable: BillPayable[];
  }>(
    (acc, bill) => {
      if (canPerformAction(bill, AvailableActions.DELETE)) {
        acc.billsAvailable.push(bill);
      } else {
        acc.billsNotAvailable.push(bill);
      }

      return acc;
    },
    { billsAvailable: [], billsNotAvailable: [] }
  );

  function handleDeleteSuccess(billIds: string[]) {
    snackbar({
      variant: 'success',
      message: pluralize(
        billIds.length,
        'Lançamento excluído',
        'Lançamentos excluídos'
      ),
    });

    onSuccess();
  }

  function handleDelete() {
    const billIds = billsAvailable.map((bill) => bill.id);

    if (billIds.length === 0) {
      handleDeleteSuccess(billIds);
      return;
    }

    deleteManyBills(
      {
        companyId,
        organizationId,
        billIds,
      },
      {
        onSuccess: () => {
          handleDeleteSuccess(billIds);
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: `Ocorreu um erro ao excluir ${pluralize(
              billIds.length,
              'lançamento',
              'lançamentos'
            )}`,
          });
        },
      }
    );
  }

  function handleDeleteClick() {
    if (billsNotAvailable.length !== 0) {
      openPortal(ReviewModal, {
        title: 'Excluir lançamentos',
        description:
          'Ao clicar em continuar os lançamentos abaixo não serão excluidos pois se encontram em uma situação que não permite esta ação.',
        bills: billsNotAvailable,
        onSuccess: handleDelete,
      });

      return;
    }

    openConfirmDialog();
  }

  function openConfirmDialog() {
    return openConfirmDeletionDialog({
      onConfirm: handleDelete,
    });
  }

  return (
    <WithAllowedPermission
      permissions={{ action: 'delete_many', resource: 'bills' }}
    >
      <LoadingButton
        variant="text"
        disabled={bills.length === 0}
        startIcon={<TrashIcon />}
        onClick={handleDeleteClick}
        aria-label="Excluir"
        loading={isLoading}
      >
        Excluir
      </LoadingButton>
    </WithAllowedPermission>
  );
}
