import { useState } from 'react';

import type { FormInstance } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Drawer, DrawerHeader, Title } from 'src/ui';

import { useCreateContact } from 'src/libs/finbits/Organization/Companies/Contacts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { errorsToFormField } from 'src/libs/finbits/Form';
import { e164Format } from 'src/libs/finbits/Organization/PhoneNumber';
import type { PostParams } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import ContactForm from 'src/features/contacts/ContactForm';
import type {
  FormParams,
  RequiredFields,
} from 'src/features/contacts/ContactForm/ContactForm';

import type { Document } from './DocumentForm';
import DocumentForm from './DocumentForm';

type Props = {
  hideBackButton?: boolean;
  defaultContactDocument?: Document;
  requiredFields?: Array<keyof RequiredFields>;
  onCreate?: (contactId: string) => void;
} & PortalProps;

function contactDrawerTitle(contactDocument?: Document): string {
  if (contactDocument?.isInternational) return 'Contato - Internacional';

  switch (contactDocument?.type) {
    case 'legal':
      return 'Contato - Pessoa Jurídica';
    case 'natural':
      return 'Contato - Pessoa Física';
    default:
      return 'Adicionar Contato';
  }
}

export default function CreateContactDrawer({
  hideBackButton = false,
  defaultContactDocument,
  requiredFields,
  onClose,
  onCreate,
  open = true,
  onExit,
}: Props) {
  const [contactDocument, setContactDocument] = useState<Document | undefined>(
    defaultContactDocument
  );
  const { createContact, isLoading } = useCreateContact();

  const { companyId, organizationId } = useCompanyParams();

  function handleOnSubmit(contactParams: FormParams, form: FormInstance) {
    const params = {
      ...contactParams,
      organizationId,
      companyId,
      phone: contactParams.phone ? e164Format(contactParams.phone) : null,
    };
    createContact(params as PostParams, {
      onSuccess: (contact) => {
        onCreate && onCreate(contact.id);
        onClose();
        snackbar({
          variant: 'success',
          message: 'Contato adicionado com sucesso.',
        });
      },
      onError: (error) => {
        if (error.response?.status === 422) {
          return form.setFields(
            errorsToFormField(error.response?.data.errors as any)
          );
        }

        snackbar({
          variant: 'error',
          message: 'Não foi possível adicionar o contato.',
        });
      },
    });
  }

  return (
    <Drawer
      title={
        <DrawerHeader
          title={
            <Title icon={<UsergroupAddOutlined />}>
              {contactDrawerTitle(contactDocument)}
            </Title>
          }
        />
      }
      footer={null}
      onClose={onClose}
      visible={open}
      afterVisibleChange={onExit}
    >
      {!contactDocument && (
        <DocumentForm
          onFinish={(value) => {
            setContactDocument(value);
          }}
        />
      )}

      {contactDocument && (
        <ContactForm
          onFinish={handleOnSubmit}
          contact={{
            document: contactDocument?.number,
            ...contactDocument,
          }}
          requiredFields={requiredFields}
          onGoBack={
            hideBackButton ? false : () => setContactDocument(undefined)
          }
          isLoading={isLoading}
          initialSearch
        />
      )}
    </Drawer>
  );
}
