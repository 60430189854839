import { Button, Card, CardActions, CardContent } from '@mui/material';
import { Typography } from 'src/design-system/components';

import { formatCNPJ } from 'src/libs/finbits/Documents';
import type { ErrorsObject } from 'src/libs/finbits/Form';

import CompanyErrors from 'src/features/sign-up/CompanyErrors';

type Props = {
  document: string;
  name: string;
  tradeName: string;
  onClickRemove: (document: string) => void;
  submitErrors?: ErrorsObject;
};

export default function CompanyCard({
  document,
  submitErrors,
  name,
  tradeName,
  onClickRemove,
}: Props) {
  const hasErrors = submitErrors && Object.keys(submitErrors)?.length > 0;

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6" component="h3" marginBottom={4}>
            {tradeName}
          </Typography>
          <Typography marginBottom={2}>{formatCNPJ(document)}</Typography>
          <Typography variant="text-xs">{name}</Typography>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="text"
            onClick={() => onClickRemove(document)}
          >
            Remover
          </Button>
        </CardActions>
      </Card>

      {hasErrors && <CompanyErrors submitErrors={submitErrors} />}
    </>
  );
}
