import { Button, Result, Typography } from 'antd';
import { ClockCircleFilled } from '@ant-design/icons';

import { Title, useOpenPortal } from 'src/ui';

import LinkEntriesDrawer from 'src/features/nota-fiscal/LinkEntriesDrawer';

import styles from './Confirmation.module.less';

type Props = {
  hasLinked?: boolean;
  onClose: () => void;
};

export default function Confirmation({ hasLinked = false, onClose }: Props) {
  const openPortal = useOpenPortal();

  function handleOpenLinkEntriesDrawer() {
    onClose();

    openPortal(LinkEntriesDrawer);
  }

  return (
    <Result
      status="warning"
      className={styles.resultWrapper}
      icon={<ClockCircleFilled />}
      title={
        <Title level={4}>
          Tudo certo! A solicitação da emissão está sendo enviada à prefeitura.
        </Title>
      }
      subTitle={
        <>
          <Typography.Paragraph type="secondary">
            Esta solicitação pode demorar alguns minutos. Quando a emissão for
            processada, você poderá visualizar a nota fiscal diretamente na
            Finbits.
          </Typography.Paragraph>
          {!hasLinked && (
            <Typography.Paragraph type="secondary">
              Recomendamos que adicione ou vincule um lançamento à esta nota
              fiscal. Gostaria de fazer isto agora?
            </Typography.Paragraph>
          )}
        </>
      }
      extra={
        hasLinked ? (
          <Button size="large" onClick={onClose}>
            Fechar
          </Button>
        ) : (
          <>
            <Button size="large" onClick={onClose}>
              Mais tarde
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={handleOpenLinkEntriesDrawer}
            >
              Vincular recebimento
            </Button>
          </>
        )
      }
    />
  );
}
