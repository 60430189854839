import type { SxProps, Theme } from '@mui/material';

export const dropdown: SxProps<Theme> = {
  height: 'fit-content',
  p: 0,

  ':hover': {
    background: 'transparent',
  },
};
