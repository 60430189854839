type Props = {
  color?: string;
  className?: string;
};

function TermsIcon({ color, className }: Props) {
  return (
    <span className="anticon" role="img" aria-label="Termos de Uso">
      <svg
        color={color}
        width="1em"
        height="1em"
        viewBox="0 0 16 18"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M10.6652 12.1544C10.6504 12.1337 10.6307 12.1168 10.608 12.1053C10.5853 12.0938 10.5601 12.0879 10.5346 12.0881H9.64669C9.51611 12.0881 9.43977 12.2367 9.51611 12.3432L10.9987 14.3943C11.063 14.4827 11.1936 14.4827 11.2579 14.3943L13.5521 11.2202C13.6284 11.1138 13.5521 10.9651 13.4215 10.9651H12.5315C12.4793 10.9651 12.4311 10.9892 12.4009 11.0314L11.1253 12.7952L10.6652 12.1544ZM11.5351 4.85795V3.89366C11.5351 3.80527 11.4628 3.73295 11.3744 3.73295H3.66009C3.57169 3.73295 3.49937 3.80527 3.49937 3.89366V4.85795C3.49937 4.94634 3.57169 5.01866 3.66009 5.01866H11.3744C11.4628 5.01866 11.5351 4.94634 11.5351 4.85795ZM3.66009 6.6258C3.57169 6.6258 3.49937 6.69813 3.49937 6.78652V7.7508C3.49937 7.8392 3.57169 7.91152 3.66009 7.91152H7.35652C7.44491 7.91152 7.51723 7.8392 7.51723 7.7508V6.78652C7.51723 6.69813 7.44491 6.6258 7.35652 6.6258H3.66009ZM7.35652 15.8267H1.89223V1.68384H13.1422V7.63027C13.1422 7.71866 13.2146 7.79098 13.3029 7.79098H14.4279C14.5163 7.79098 14.5887 7.71866 14.5887 7.63027V0.880269C14.5887 0.524688 14.3014 0.237411 13.9458 0.237411H1.08866C0.733078 0.237411 0.445801 0.524688 0.445801 0.880269V16.6303C0.445801 16.9859 0.733078 17.2731 1.08866 17.2731H7.35652C7.44491 17.2731 7.51723 17.2008 7.51723 17.1124V15.9874C7.51723 15.899 7.44491 15.8267 7.35652 15.8267ZM15.4445 9.38206L11.5873 8.0421C11.5692 8.03607 11.5532 8.03406 11.5351 8.03406C11.517 8.03406 11.4989 8.03607 11.4829 8.0421L7.62571 9.38206C7.5942 9.39276 7.56681 9.41303 7.54737 9.44003C7.52792 9.46704 7.51739 9.49944 7.51723 9.53273V14.5771C7.51723 14.6274 7.53933 14.6736 7.57951 14.7037L11.4366 17.7211C11.4648 17.7432 11.5009 17.7553 11.5351 17.7553C11.5692 17.7553 11.6054 17.7432 11.6335 17.7211L15.4907 14.7037C15.5288 14.6736 15.5529 14.6274 15.5529 14.5771V9.53273C15.5529 9.46442 15.5087 9.40415 15.4445 9.38206ZM14.3074 14.0528L11.5351 16.2204L8.76277 14.0528V10.3021L11.5351 9.33786L14.3074 10.3021V14.0528Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

export default TermsIcon;
