import { AlertTriangleIcon, ClockIcon, InfoCircleIcon } from 'src/mui/_icons';

import { useOpenPortal } from 'src/ui';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { SummaryAccount } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import AmountBox from 'src/features/entries/EntriesSummary/MoneyBox/AmountBox';
import BalanceInconsistencyDialog from 'src/features/balance-inconsistency/BalanceInconsistencyDialog';

import styles from './BalanceAmount.module.scss';

type Props = {
  hasInconsistency: boolean;
  someBalancePending: boolean;
  accounts: SummaryAccount[];
  amount: number;
  amountType: BalanceType;
  defaultTipText?: string;
};

function getBalanceColor(balanceType: string) {
  return balanceType === BalanceType.DEBIT ? 'error.500' : undefined;
}

export default function BalanceAmount({
  hasInconsistency,
  someBalancePending,
  accounts,
  defaultTipText,
  amount,
  amountType,
}: Props) {
  const openPortal = useOpenPortal();

  function handleOpenBalanceInconsistencyDrawer() {
    if (Boolean(accounts.length)) {
      openPortal(BalanceInconsistencyDialog, { accounts: accounts });
    }
  }

  if (!hasInconsistency && someBalancePending && accounts.length === 1) {
    return (
      <AmountBox
        alternativeText="Em atualização"
        Icon={
          <InfoCircleIcon color="disabled" className={styles.balanceIcon} />
        }
        tipText="Após a inclusão de uma nova conta bancária e seu saldo inicial, os saldos são recalculados e exibidos em até 1 dia útil."
      />
    );
  }

  if (!hasInconsistency && someBalancePending) {
    return (
      <AmountBox
        amount={amount}
        Icon={<ClockIcon color={'warning'} />}
        textColor={getBalanceColor(amountType)}
        amountType={amountType}
        tipText="A análise do saldo inicial de uma das contas selecionadas está pendente e, por isso, este valor pode apresentar divergência."
      />
    );
  }

  if (hasInconsistency) {
    return (
      <AmountBox
        amount={amount}
        Icon={<AlertTriangleIcon className={styles.alertIcon} />}
        onClick={handleOpenBalanceInconsistencyDrawer}
        textColor="warning.600"
        amountType={amountType}
        tipText="Há divergências entre o saldo da sua conta bancária e o saldo apresentado na FinBits. Clique para saber mais."
      />
    );
  }

  return (
    <AmountBox
      amount={amount}
      Icon={<InfoCircleIcon color="disabled" className={styles.balanceIcon} />}
      textColor={getBalanceColor(amountType)}
      amountType={amountType}
      tipText={defaultTipText}
    />
  );
}
