import { CompanyRole, OrganizationRole } from './types';

export const companyRoleDictionary = {
  [CompanyRole.MANAGER]: 'Gestor',
  [CompanyRole.OPERATOR]: 'Operador',
  [CompanyRole.VIEWER]: 'Visualizador',
  [CompanyRole.APPROVER]: 'Aprovador',
};

export const organizationRoleDictionary = {
  [OrganizationRole.ADMIN]: 'Admin',
  [OrganizationRole.MEMBER]: 'Membro',
};
