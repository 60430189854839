import { useState } from 'react';

import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import type { FormInstance } from 'antd';
import { Col, Form, Input, Row } from 'antd';
import type { FieldData } from 'rc-field-form/lib/interface';

import {
  DrawerFooter,
  FormItem,
  MaskedInput,
  MaskedSearch,
  Title,
} from 'src/ui';

import {
  addressPostalCodeValidator,
  cepMask,
  useAddress,
} from 'src/libs/finbits/BrazilianData/Addresses';
import { useLegalEntity } from 'src/libs/finbits/BrazilianData/LegalEntities';
import {
  cellphoneMask,
  internationalPhoneValidator,
  phoneFormat,
  phoneMask,
  phoneValidator,
} from 'src/libs/finbits/Organization/PhoneNumber';
import { emailRegex } from 'src/libs/finbits/Email';
import {
  formCnpjValidator,
  formCpfValidator,
} from 'src/libs/finbits/Documents';
import { unMask } from 'src/libs/finbits/Masker';
import type {
  BankDetails,
  Contact,
} from 'src/libs/finbits/Organization/Companies/Contacts/types';
import type { Address } from 'src/libs/finbits/BrazilianData/Addresses/types';
import type { PaymentDetails } from 'src/libs/finbits/Payments/types';

import StatesSelect from 'src/features/location/StatesSelect';
import CitiesSelect from 'src/features/location/CitiesSelect';
import PixFields from 'src/features/payments/PixFields';
import BankFields from 'src/features/payments/BankFields';
import CountriesSelect from 'src/features/location/CountriesSelect/CountriesSelect';

import DocumentInput from './DocumentInput';
import styles from './ContactForm.module.less';

type GoBackFn = () => void;

export type RequiredFields = Omit<FormParams, 'address' | 'bankDetails'> &
  Address &
  PaymentDetails;

type Props = {
  contact: Partial<Contact>;
  onFinish: (contact: FormParams, form: FormInstance) => void;
  onGoBack: false | GoBackFn;
  isLoading: boolean;
  disabled?: boolean;
  initialSearch?: boolean;
  disabledDocument?: boolean;
  requiredFields?: Array<keyof RequiredFields>;
};

export type FormParams = {
  id?: string;
  name: string;
  nickname: string;
  type: 'legal' | 'natural';
  document: string;
  email?: string;
  phone?: string;
  address?: Partial<Address>;
  comments?: string;
  bankDetails?: Partial<BankDetails>;
  isInternational: boolean;
};

export default function ContactForm({
  contact,
  onFinish,
  onGoBack,
  isLoading,
  disabled,
  initialSearch,
  disabledDocument,
  requiredFields = contact.isInternational
    ? ['name', 'nickname', 'countryCode']
    : ['name', 'nickname', 'document'],
}: Props) {
  const [form] = Form.useForm();

  const formDocumentValue = Form.useWatch('document', form);
  const formTypeValue = Form.useWatch('type', form);

  const isLegalType = contact.type === 'legal';
  const isInternational = contact.isInternational;

  const [stateCode, setStateCode] = useState<string | undefined>(
    contact.address?.state ?? undefined
  );
  const [searchPostalCode, setSearchPostalCode] = useState<string>();
  const [searchDocument, setSearchDocument] = useState(
    initialSearch && isLegalType ? contact.document : null
  );

  const { isFetching: searchingDocument, refetch: refetchDocument } =
    useLegalEntity(
      { document: searchDocument },
      {
        retry: false,
        onSuccess: (contactInfo) => {
          const { phone, address, ...restContact } = contactInfo;
          const postalCode = address.postalCode
            ? unMask(address.postalCode)
            : null;

          setStateCode(address.state ?? undefined);

          form.setFieldsValue({
            phone: phone ? phoneFormat(phone) : null,
            address: {
              ...address,
              postalCode,
            },
            ...omitBy(restContact, (value) => value === null),
          });
        },
      }
    );

  const { isFetching: searchingAddress, refetch: refetchAddress } = useAddress(
    { postalCode: searchPostalCode },
    {
      retry: false,
      onSuccess: (addressInfo) => {
        const { state, ...restAddress } = addressInfo;

        setStateCode(state ?? undefined);
        form.setFieldsValue({
          address: {
            state,
            ...restAddress,
            postalCode: searchPostalCode,
          },
        });
      },
    }
  );

  function onSearchDocument() {
    const currentDocument = form.getFieldValue('document');
    if (currentDocument === searchDocument) {
      return refetchDocument();
    }

    setSearchDocument(currentDocument);
  }

  function onSearchAddress() {
    const currentPostalCode = form.getFieldValue(['address', 'postalCode']);

    if (currentPostalCode?.length !== 8) return;

    if (currentPostalCode === searchPostalCode) {
      return refetchAddress();
    }

    setSearchPostalCode(currentPostalCode);
  }

  const formDisabled =
    disabled || searchingDocument || searchingAddress || isLoading;

  return (
    <Form
      form={form}
      className={styles.form}
      layout="vertical"
      initialValues={contact}
      onFinish={(contactParams) => onFinish(contactParams, form)}
      onFieldsChange={(changedFields: FieldData[]) => {
        const [{ name }] = changedFields;
        if (isEqual(name, ['address', 'state'])) {
          setStateCode(changedFields[0].value);
          form.setFieldsValue({ address: { city: undefined } });
        }

        if (isEqual(name, ['bankDetails', 'pixType'])) {
          form.setFieldsValue({ bankDetails: { pixKey: undefined } });
        }

        if (isEqual(name, ['address', 'countryCode'])) {
          form.validateFields(['phone']);
        }
      }}
    >
      <Form.Item name="id" hidden>
        <input />
      </Form.Item>
      <Form.Item name="type" hidden>
        <input />
      </Form.Item>
      <Form.Item name="isInternational" hidden>
        <input />
      </Form.Item>

      <Row gutter={32}>
        <Col span={12}>
          {isInternational ? (
            <FormItem
              required={requiredFields.includes('name')}
              label="Nome / Razão Social"
              name="name"
            >
              <Input size="large" disabled={formDisabled} />
            </FormItem>
          ) : (
            <FormItem
              label={contact.type === 'natural' ? 'CPF' : 'CNPJ'}
              name="document"
              required={requiredFields.includes('document')}
              rules={[
                {
                  validator:
                    contact.type === 'natural'
                      ? formCpfValidator
                      : formCnpjValidator,
                },
              ]}
            >
              <DocumentInput
                type={contact.type!}
                disabled={disabledDocument || formDisabled}
                onSearch={onSearchDocument}
                loading={searchingDocument}
              />
            </FormItem>
          )}
        </Col>
        <Col span={12}>
          {isInternational && (
            <FormItem
              required={requiredFields.includes('nickname')}
              label="Apelido / Nome da Empresa"
              name="nickname"
            >
              <Input size="large" disabled={formDisabled} />
            </FormItem>
          )}
        </Col>

        <Col span={12}>
          {isInternational ? (
            <FormItem
              label="País"
              name={['address', 'countryCode']}
              required={requiredFields.includes('countryCode')}
            >
              <CountriesSelect disabled={formDisabled} />
            </FormItem>
          ) : (
            <FormItem
              label={isLegalType ? 'Razão Social' : 'Nome Completo'}
              name="name"
              required={requiredFields.includes('name')}
            >
              <Input size="large" disabled={formDisabled} />
            </FormItem>
          )}
        </Col>

        <Col span={12}>
          {isInternational ? (
            <FormItem
              label="Documento"
              name="document"
              required={requiredFields.includes('document')}
            >
              <Input size="large" disabled={formDisabled} />
            </FormItem>
          ) : (
            <FormItem
              label={isLegalType ? 'Nome da Empresa' : 'Apelido'}
              name="nickname"
              required={requiredFields.includes('nickname')}
            >
              <Input size="large" disabled={formDisabled} />
            </FormItem>
          )}
        </Col>

        <Col span={12}>
          <FormItem
            label="E-mail"
            name="email"
            required={requiredFields.includes('email')}
            rules={[
              { message: 'Formato de e-mail inválido', pattern: emailRegex },
            ]}
          >
            <Input size="large" disabled={formDisabled} />
          </FormItem>
        </Col>

        <Col span={12}>
          {isInternational ? (
            <FormItem
              label="Telefone"
              name="phone"
              required={requiredFields.includes('phone')}
              rules={[
                {
                  validator: (_rule, value) =>
                    internationalPhoneValidator(
                      _rule,
                      value,
                      form.getFieldValue(['address', 'countryCode'])
                    ),
                },
              ]}
            >
              <Input
                size="large"
                disabled={formDisabled}
                placeholder="+00 (000) 000-0000"
              />
            </FormItem>
          ) : (
            <FormItem
              label="Telefone"
              name="phone"
              required={requiredFields.includes('phone')}
              rules={[{ validator: phoneValidator }]}
            >
              <MaskedInput
                mask={[phoneMask, cellphoneMask]}
                size="large"
                placeholder="(00) 00000-0000"
                disabled={formDisabled}
              />
            </FormItem>
          )}
        </Col>

        <Col span={12}>
          {isInternational ? (
            <FormItem
              label="Código Postal"
              name={['address', 'postalCode']}
              required={requiredFields.includes('postalCode')}
            >
              <Input size="large" disabled={formDisabled} />
            </FormItem>
          ) : (
            <FormItem
              label="CEP"
              name={['address', 'postalCode']}
              required={requiredFields.includes('postalCode')}
              rules={[{ validator: addressPostalCodeValidator }]}
            >
              <MaskedSearch
                mask={[cepMask]}
                size="large"
                placeholder="00000-000"
                onSearch={onSearchAddress}
                loading={searchingAddress}
                disabled={formDisabled}
              />
            </FormItem>
          )}
        </Col>

        <Col span={12}>
          <FormItem
            label="Estado"
            name={['address', 'state']}
            required={requiredFields.includes('state')}
          >
            {isInternational ? (
              <Input size="large" disabled={formDisabled} />
            ) : (
              <StatesSelect disabled={formDisabled} />
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label="Cidade"
            name={['address', 'city']}
            required={requiredFields.includes('city')}
          >
            {isInternational ? (
              <Input size="large" disabled={formDisabled} />
            ) : (
              <CitiesSelect stateCode={stateCode} disabled={formDisabled} />
            )}
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label="Endereço"
            name={['address', 'street']}
            required={requiredFields.includes('street')}
          >
            <Input size="large" disabled={formDisabled} />
          </FormItem>
        </Col>

        {!isInternational && (
          <>
            <Col span={12}>
              <FormItem
                label="Bairro"
                name={['address', 'district']}
                required={requiredFields.includes('district')}
              >
                <Input size="large" disabled={formDisabled} />
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Número"
                name={['address', 'number']}
                required={requiredFields.includes('number')}
              >
                <Input size="large" disabled={formDisabled} />
              </FormItem>
            </Col>
          </>
        )}

        <Col span={24}>
          <FormItem
            label="Complemento"
            name={['address', 'details']}
            required={requiredFields.includes('details')}
          >
            <Input size="large" disabled={formDisabled} />
          </FormItem>
        </Col>

        <Col span={24}>
          <Title level={4} className={styles.separator}>
            Dados Bancários
          </Title>
        </Col>

        <BankFields
          international={isInternational}
          disabled={formDisabled}
          requiredFields={requiredFields}
        />

        {!isInternational && (
          <PixFields
            disabled={formDisabled}
            requiredFields={requiredFields}
            contactParams={{
              contactType: formTypeValue,
              contactDocument: formDocumentValue,
            }}
          />
        )}

        <Col span={24}>
          <Title level={4} className={styles.separator}>
            Informações extras
          </Title>
          <FormItem max={240} label="Observação" name="comments">
            <Input.TextArea
              size="large"
              showCount
              maxLength={240}
              autoSize={{ minRows: 4 }}
              disabled={formDisabled}
            />
          </FormItem>
        </Col>
      </Row>

      <DrawerFooter>
        {onGoBack && (
          <DrawerFooter.BackButton onClick={onGoBack} disabled={formDisabled}>
            Voltar
          </DrawerFooter.BackButton>
        )}

        <DrawerFooter.SubmitButton loading={isLoading} disabled={formDisabled}>
          Salvar
        </DrawerFooter.SubmitButton>
      </DrawerFooter>
    </Form>
  );
}
