import type { FormFieldProps } from 'src/mui/_scss';
import { FormField } from 'src/mui/_scss';

import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';

import ApprovalTypeSelect from 'src/features/approval/ApprovalTypeSelect';

export default function ApprovalTypeField({
  defaultValue = BillPayableApprovalType.ALL,
  ...rest
}: FormFieldProps) {
  return (
    <FormField
      defaultValue={defaultValue}
      label="Aprovações necessárias"
      {...rest}
    >
      {(field) => (
        <ApprovalTypeSelect
          {...field}
          value={field.value}
          onChange={(_e, value) => field.onChange(value)}
        />
      )}
    </FormField>
  );
}
