import type { ReactNode } from 'react';

import { Dialog, DialogTitle, IconButton, Slide } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { AlertCircleIcon, CloseIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import type { PortalProps } from 'src/ui';
import { useOpenConfirmDialog } from 'src/ui';

import type { BillTabsType } from 'src/features/bills-to-pay/ReviewModal/types';

import styles from './ReviewModal.module.scss';
import ReviewModalContent from './ReviewModalContent';

type Props = {
  title: string;
  modalDescription?: ReactNode | string;
  onConfirm: {
    confirmText: string;
    action: (newSelectedIds?: string[]) => void;
  };
  tabs: Record<'availables' | 'notAvailables', BillTabsType>;
  modalClose: {
    text: string;
    subtext: string;
  };
} & PortalProps;

export default function ReviewModal({
  title,
  modalDescription,
  tabs,
  modalClose,
  open = true,
  onClose,
  onConfirm,
  onExit,
}: Props) {
  const openConfirmDialog = useOpenConfirmDialog();

  function handleClose(confirmed: boolean = false) {
    if (confirmed) {
      onClose();
      return;
    }

    openCloseConfirmationModal();
  }

  function openCloseConfirmationModal() {
    openConfirmDialog({
      variant: 'error',
      title: modalClose.text,
      icon: <AlertCircleIcon />,
      content: (
        <Typography marginTop={4} marginBottom={4}>
          {modalClose.subtext}
        </Typography>
      ),
      confirmText: 'Confirmar',
      cancelText: 'Voltar',
      onConfirm: onClose,
    });
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={() => handleClose()}
      maxWidth="md"
      fullWidth
      aria-labelledby="review-modal"
      disableEnforceFocus
    >
      <DialogTitle id="review-modal" variant="text-lg" fontWeight="600">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="Fechar modal"
        onClick={() => handleClose()}
        className={styles.closeIcon}
      >
        <CloseIcon />
      </IconButton>

      <ReviewModalContent
        tabs={tabs}
        modalDescription={modalDescription}
        onConfirm={onConfirm}
        onClose={handleClose}
      />
    </Dialog>
  );
}
