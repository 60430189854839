import { Box } from '@mui/material';
import { Typography } from 'src/design-system/components';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import AmountBox from './AmountBox';

type Props = {
  text: string;
  amount?: number;
  amountType?: BalanceType;
  Icon?: JSX.Element;
  children?: JSX.Element;
};

export default function MoneyBox({
  text,
  amount = 0,
  amountType = BalanceType.CREDIT,
  Icon,
  children,
}: Props) {
  return (
    <Box>
      <Typography variant="text-xs" color="grey.500">
        {text}
      </Typography>
      {Boolean(children) ? (
        children
      ) : (
        <AmountBox amount={amount} amountType={amountType} Icon={Icon} />
      )}
    </Box>
  );
}
