import { Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import type { BaseType } from 'antd/lib/typography/Base';

import type { BTGConsentment } from 'src/libs/finbits/Bank/Accounts/types';
import { formatCPF } from 'src/libs/finbits/Documents';

import style from './ConsentmentInfo.module.less';

type Props = {
  consentment?: BTGConsentment | null;
  type?: BaseType;
};

export default function ConsentmentInfo({ consentment, type }: Props) {
  if (!consentment) return null;

  return (
    <Typography.Text type={type} className={style.wrapper}>
      <Typography.Text>Conectado por</Typography.Text>
      <Typography.Text
        type={type}
        className={style.ellipse}
        strong
        ellipsis={{ tooltip: consentment.consenterName }}
      >
        {consentment.consenterName}
      </Typography.Text>
      <Tooltip
        placement="bottom"
        title={
          <>
            <div>
              <b>CPF:</b> {formatCPF(consentment.consenterDocument!)}
            </div>
            <div>
              <b>E-mail:</b> {consentment.consenterEmail}
            </div>
          </>
        }
      >
        <InfoCircleOutlined data-testid="btgConsenterInfo" />
      </Tooltip>
    </Typography.Text>
  );
}
