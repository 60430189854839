import { IconButton } from '@mui/material';
import { FileQuestion } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import useOpenEntryEditDrawer from 'src/features/entries/hooks/useOpenEntryEditDrawer';

import styles from './DescriptionWithContextIcon.module.scss';

type Props = {
  row: FinancialStatementEntry;
};

export default function UnfilledContextIcon({ row }: Props) {
  const openEntryEditDrawer = useOpenEntryEditDrawer();

  function handleOpenDreawer() {
    openEntryEditDrawer(row);
  }

  return (
    <Tooltip
      title="Verifique as informações pendentes deste lançamento"
      enterDelay={100}
    >
      <IconButton
        aria-label="unfilled-context-button"
        onClick={handleOpenDreawer}
      >
        <FileQuestion className={styles.icon} />
      </IconButton>
    </Tooltip>
  );
}
