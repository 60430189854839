import type { ReactNode } from 'react';

import { Button, Divider, Typography } from 'antd';

import styles from './SelectAddButton.module.less';

type Props = {
  children: ReactNode;
  label: string;
  showButton?: boolean;
  onClick?: () => void;
};

export default function SelectAddButton({
  children,
  label,
  showButton = false,
  onClick,
}: Props) {
  return (
    <div className={styles.select}>
      {children}
      {showButton && (
        <>
          <Divider />
          <div className={styles.actions}>
            <Button type="text" onClick={onClick}>
              <Typography.Text strong>{label}</Typography.Text>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
