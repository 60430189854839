import type { ReactNode } from 'react';
import { useEffect } from 'react';

import * as Sentry from '@sentry/react';

import { useProfile } from 'src/libs/finbits/Organization/Users';

type Props = {
  children: ReactNode;
};

export default function SentryUserSetter({ children }: Props) {
  const { user } = useProfile();

  useEffect(() => {
    user && Sentry.setUser({ id: user.id, email: user.email });

    return () => {
      Sentry.setUser(null);
    };
  }, [user]);

  return <>{children}</>;
}
