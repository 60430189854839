import { useEffect, useState } from 'react';

import useWindowSize from 'src/libs/finbits/hooks/useWindowSize';

type UseCalculatedScrollParams = {
  classNames: string[];
  parentClassName?: string;
  recalculateScroll?: boolean;
  items?: unknown[];
};

function getClientHeight(selector?: string) {
  if (!selector) return 0;
  return document.querySelector(`.${selector}`)?.clientHeight ?? 0;
}

export function useCalculatedScroll({
  classNames,
  parentClassName,
  recalculateScroll,
  items,
}: UseCalculatedScrollParams) {
  const [width, height] = useWindowSize();

  const [calculatedScroll, setCalculatedScroll] = useState<number>();

  useEffect(() => {
    const total = classNames.reduce((acc, className) => {
      const height = getClientHeight(className);

      return acc + height;
    }, 0);

    setCalculatedScroll(getClientHeight(parentClassName) - total);
  }, [items, classNames, parentClassName, recalculateScroll, width, height]);

  return {
    calculatedScroll,
  };
}
