import type { JSXElementConstructor, SyntheticEvent } from 'react';

import type { GridFilterInputValueProps } from '@mui/x-data-grid';
import { InputLabel } from '@mui/material';

export default function FilterWrapper({
  Component,
  item,
  applyValue,
  apiRef,
  focusElementRef,
  ...props
}: GridFilterInputValueProps & { Component: JSXElementConstructor<any> }) {
  function handleFilterChange(_e: SyntheticEvent, newValue: any | null) {
    applyValue({ ...item, value: newValue });
  }

  return (
    <>
      <InputLabel>Valor</InputLabel>
      <Component
        value={item.value ?? null}
        onChange={handleFilterChange}
        autoFocus={false}
        {...props}
      />
    </>
  );
}
