import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { useSearchParams } from 'react-router-dom';
import { CurrencyDollarIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { useCurrentRoute } from 'src/router/useCurrentRoute';

import { Badge } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { useGetAccountsPendencies } from 'src/libs/finbits/Bank/Accounts';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Account } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import AccountsBalances from './AccountsBalances';
import styles from './InitialBalanceMenu.module.scss';
import RegisterBalance from './RegisterBalance';

export default function InitialBalanceMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { companyId, organizationId } = useCompanyParams();
  const currentRoute = useCurrentRoute();
  const openPortal = useOpenPortal();
  const [searchParams] = useSearchParams();

  const { isEnabled: isBalanceModuleEnabled } = useExternalFeatureFlag(
    Feature.INITIAL_BALANCE_MODULE
  );

  const { data } = useGetAccountsPendencies({
    organizationId,
    companyId,
  });

  const isPending = data?.accounts?.initialBalancePending ?? false;

  const isVisibility = Boolean(anchorEl);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl((state) => (state ? null : event.currentTarget));
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function showPopoverRouter() {
    const action = searchParams.get('action');

    const routers = [
      '/organizations/:organizationId/companies/:companyId/entries',
      '/organizations/:organizationId/companies/:companyId/accounts',
    ];

    if (
      !action &&
      currentRoute &&
      routers.includes(currentRoute.path || '') &&
      isPending
    ) {
      const element = document.querySelector(
        '[aria-label="Saldos"]'
      ) as HTMLElement;

      setAnchorEl(element);
    }
  }

  function handleRegisterBalance(account: Account) {
    handleClose();
    openPortal(RegisterBalance, { account });
  }

  useEffect(() => {
    showPopoverRouter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute]);

  return (
    <WithAllowedPermission
      permissions={{ resource: 'companiesPendencies', action: 'index' }}
    >
      {isBalanceModuleEnabled && (
        <>
          <IconButton
            onClick={handleClick}
            size="large"
            aria-label="Saldos"
            aria-describedby="detail-popover"
          >
            <Badge variant="dot" color="error" invisible={!isPending}>
              <CurrencyDollarIcon />
            </Badge>
          </IconButton>

          <Popover
            id="detail-popover"
            open={isVisibility}
            anchorEl={anchorEl}
            onClose={handleClose}
            classes={{ paper: styles.paper }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Typography
              variant="text-lg"
              fontWeight="600"
              className={styles.title}
            >
              Saldos realizados
            </Typography>

            {anchorEl && (
              <AccountsBalances
                onClose={handleClose}
                onRegister={handleRegisterBalance}
              />
            )}
          </Popover>
        </>
      )}
    </WithAllowedPermission>
  );
}
