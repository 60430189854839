import { Alert, Button, Empty, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';

import { Loader, PaymentIcon } from 'src/ui';

import {
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { useAllowCityNotaFiscal } from 'src/libs/finbits/NotaFiscal/Cities';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import styles from './NotaFiscalEmptyState.module.less';

type Props = {
  connectionStatus: string;
  isConnectionLoading: boolean;
  onOpenEnableDrawer: () => void;
  onOpenEditSetupDrawer: () => void;
  onOpenCreateDrawer: () => void;
};

export default function NotaFiscalEmptyState({
  connectionStatus,
  isConnectionLoading,
  onOpenEnableDrawer,
  onOpenEditSetupDrawer,
  onOpenCreateDrawer,
}: Props) {
  const { organizationId, companyId } = useCompanyParams();
  const { company } = useCompany({
    organizationId,
    companyId,
  });

  const city = company?.address?.city;
  const state = company?.address?.state;

  const { isCityAllowed, isFetched } = useAllowCityNotaFiscal({
    city,
    state,
  });

  const isAllowed = isCityAllowed && isFetched;
  const isActive = connectionStatus === 'active';
  const isPendingUploadDigitalCertificate = connectionStatus === 'activating';
  const isPendingCityOrState = !city || !state;

  if (isConnectionLoading) {
    return <Loader />;
  }

  if (isPendingCityOrState) {
    return (
      <div className={styles.cardContent}>
        <Typography.Text type="secondary" className={styles.cardText}>
          Você não possui sua cidade cadastrada.
          <br />
          <Link
            to={generateCompanyPath(RouteKey.COMPANY, {
              companyId,
              organizationId,
            })}
          >
            Preencha aqui
          </Link>{' '}
          os campos de cidade e estado para poder acessar notas fiscais.
        </Typography.Text>
      </div>
    );
  }

  if (isActive) {
    return (
      <div className={styles.activeEmptyState}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Typography.Text>
              Você ainda não emitiu notas fiscais. <br /> Comece clicando
              abaixo.
            </Typography.Text>
          }
        />

        <WithAllowedPermission
          permissions={{
            resource: 'notasFiscais',
            action: 'create',
          }}
        >
          <Button
            size="large"
            icon={<PaymentIcon />}
            onClick={onOpenCreateDrawer}
          >
            Emitir nota fiscal
          </Button>
        </WithAllowedPermission>
      </div>
    );
  }

  if (isPendingUploadDigitalCertificate) {
    return (
      <div className={styles.alert}>
        <Alert
          banner
          type="warning"
          icon={<ClockCircleOutlined size={24} />}
          message={
            <>
              <Typography.Text className={styles.alertText}>
                Aguardando o envio do <b>Certificado Digital.</b>
              </Typography.Text>
              <WithAllowedPermission
                permissions={{
                  resource: 'notaFiscalCertificate',
                  action: 'update',
                }}
              >
                <Button type="link" onClick={onOpenEditSetupDrawer}>
                  <Typography.Text underline>ENVIAR AGORA</Typography.Text>
                </Button>
              </WithAllowedPermission>
            </>
          }
        />
        <div className={styles.pendingEmptyState}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Typography.Text>
                Você ainda não emitiu notas fiscais. Envie o{' '}
                <b>Certificado Digital</b> para começar.
              </Typography.Text>
            }
          />
        </div>
      </div>
    );
  }

  if (isAllowed) {
    return (
      <div className={styles.cardContent}>
        <Typography.Text className={styles.cardText}>
          Emitir suas notas fiscais pelo Finbits é simples e rápido.
          <br />
          Cada vez mais seu controle financeiro em um só lugar.
        </Typography.Text>
        <WithAllowedPermission
          permissions={{
            resource: 'notaFiscalSetup',
            action: 'create',
          }}
        >
          <Button size="large" type="primary" onClick={onOpenEnableDrawer}>
            Habilitar Nota Fiscal
          </Button>
        </WithAllowedPermission>
      </div>
    );
  }

  return (
    <div className={styles.cardContent}>
      <Typography.Text className={styles.cardText}>
        Atualmente não possuímos este serviço para empresas na sua cidade.
        <br />
        Caso deseje emitir notas por aqui, entre em contato conosco para
        integrarmos com a sua prefeitura.
        <br />
        <a href="mailto:suporte@finbits.com.br">suporte@finbits.com.br</a>
      </Typography.Text>
    </div>
  );
}
