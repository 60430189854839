import type { Components } from '@mui/material';
import { tabClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiTab({ palette }: ThemeConfig): Components['MuiTab'] {
  return {
    styleOverrides: {
      root: {
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:disabled': {
          opacity: '0.4',
        },
      },
      textColorSecondary: {
        color: palette.text?.secondary,

        [`&.${tabClasses.selected}`]: {
          color: palette.text?.primary,
        },
      },
    },
  };
}
