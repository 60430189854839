import { translateCurrentSituation } from 'src/libs/finbits/Management/Entries/currentSituationTranslations';
import type { EntrySituation } from 'src/libs/finbits/Management/Entries/types';

type NonEditableFieldProps = {
  headerName?: string;
  currentSituation: EntrySituation;
};

export function NonEditableField({
  headerName,
  currentSituation,
}: NonEditableFieldProps) {
  return (
    <>
      {headerName} não pode ser editado para lançamentos na situação{' '}
      <b>{translateCurrentSituation(currentSituation)}</b>. Pressione <b>Esc</b>{' '}
      para continuar.
    </>
  );
}
