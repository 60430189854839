import { Alert, Button } from 'antd';

import { snackbar } from 'src/mui';

import { TermsIcon } from 'src/ui';

import {
  useProfile,
  useUpdateProfile,
  userNeedsToAcceptTerms,
} from 'src/libs/finbits/Organization/Users';

import TermsOfUseModal from 'src/features/terms-of-use/TermsOfUseModal';
import PrivacyPolicyModal from 'src/features/terms-of-use/PrivacyPolicyModal';
import SaasContractModal from 'src/features/terms-of-use/SaasContractModal';

import styles from './TermsOfUseAlert.module.less';

export default function TermsOfUseAlert() {
  const { user } = useProfile();
  const { updateProfile } = useUpdateProfile();

  function handleClick() {
    const params = {
      acceptedTermsAt: new Date().toISOString(),
    };

    updateProfile(params, {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          message: 'Termos de uso aceitos com sucesso.',
        });
      },
      onError: (error) => {
        if (error.response?.status === 422) {
          snackbar({
            variant: 'error',
            message: 'Ops! Falha ao salvar as informações.',
          });
        }
      },
    });
  }

  if (userNeedsToAcceptTerms(user)) {
    return (
      <Alert
        type="warning"
        message={
          <div className={styles.alert}>
            <div className={styles.text}>
              <TermsIcon className={styles.icon} />
              <div>
                Estamos constantemente aprimorando a experiência da Finbits e
                por isso atualizamos nossos{' '}
                <TermsOfUseModal label="Termos de Uso" />,{' '}
                <PrivacyPolicyModal label="Política de Privacidade" /> e{' '}
                <SaasContractModal label="Contrato SaaS" />.
              </div>
            </div>
            <div className={styles.button}>
              <Button type="link" onClick={handleClick}>
                Concordar e fechar
              </Button>
            </div>
          </div>
        }
      />
    );
  }

  return null;
}
