import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import type { Channel } from 'phoenix';
import { Socket } from 'phoenix';

import {
  getWebsocketAccessToken,
  useIsAuthenticated,
} from 'src/libs/finbits/Auth';

import { reconnectAfterMs, rejoinAfterMs } from './backoff';

type State = {
  socket: Socket | null;
  isConnected: boolean;
};

type Props = { children: ReactNode };

const initialState = { socket: null, isConnected: false };
const SocketContext = createContext<State>(initialState);

export function SocketProvider({ children }: Props) {
  const [state, setState] = useState<State>(initialState);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const newSocket = new Socket(import.meta.env.VITE_WEB_SOCKET_URL!, {
      params: () => ({ access_token: getWebsocketAccessToken() }),
      reconnectAfterMs,
      rejoinAfterMs,
      logger: function (kind, msg, data) {
        if (import.meta.env.VITE_WEB_SOCKET_DEBUG === 'true') {
          console.debug(`[websocket] ${kind}: ${msg}`, data);
        }
      },
    });

    function setConnectionState() {
      setState((state) => ({
        ...state,
        isConnected: newSocket.isConnected() || false,
      }));
    }

    const onErrorRef = newSocket.onError(setConnectionState);
    const onOpenRef = newSocket.onOpen(setConnectionState);
    const onCloseRef = newSocket.onClose(setConnectionState);

    setState((state) => ({ ...state, socket: newSocket }));

    return () => {
      newSocket.off([onErrorRef, onOpenRef, onCloseRef]);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      state.socket?.connect();
    }

    return () => {
      state.socket?.disconnect();
    };
  }, [isAuthenticated, state.socket]);

  return (
    <SocketContext.Provider value={state}>{children}</SocketContext.Provider>
  );
}

export function useSocket() {
  return useContext(SocketContext);
}

export function useChannel(topic: null | string): {
  channel: Channel | null;
  isConnected: boolean;
} {
  const [currentChannel, setChannel] = useState<Channel | null>(null);
  const { socket, isConnected } = useSocket();

  useEffect(() => {
    if (!socket || !isConnected || !topic) return;

    const channel = socket.channel(topic);

    channel
      .join()
      .receive('ok', () => {
        setChannel(channel);
      })
      .receive('error', (resp) => {
        console.error(resp);
      });

    return () => {
      channel.leave();
    };
  }, [socket, isConnected, topic]);

  return { channel: currentChannel, isConnected };
}
