import { Space, Typography } from 'antd';

import BankName from './BankName';

type Props = {
  routingNumber: string;
  branchNumber: string;
  accountNumber: string;
};

function DialogContent({ routingNumber, branchNumber, accountNumber }: Props) {
  return (
    <Space direction="vertical" size="large">
      <Typography.Text>
        Identificamos que este arquivo é de uma conta que ainda não está
        cadastrada na Finbits.
      </Typography.Text>
      <Typography.Text strong>
        <BankName routingNumber={routingNumber} /> ( ag {branchNumber} conta{' '}
        {accountNumber})
      </Typography.Text>
    </Space>
  );
}

export default DialogContent;
