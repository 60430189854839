import { Collapse, Space } from 'antd';

import { Title } from 'src/ui';

import type { Classification } from 'src/libs/finbits/Classifications/types';

import LabelList from 'src/features/labels/LabelList';
import EditClassificationButton from 'src/features/classifications/EditClassificationButton';
import CreateLabelButton from 'src/features/labels/CreateLabelButton';
import ActiveClassificationSwitch from 'src/features/classifications/ActiveClassificationSwitch';

import styles from './ClassificationCollapse.module.less';

type Props = {
  classification: Classification;
};

function ClassificationCollapse({ classification }: Props) {
  return (
    <Collapse bordered={false} collapsible="header">
      <Collapse.Panel
        className={styles.collapse}
        header={
          <Title level={5} ellipsis={{ tooltip: classification.name }}>
            {classification.name}
          </Title>
        }
        key={classification.id}
        extra={
          <Space>
            <CreateLabelButton classification={classification} />

            <ActiveClassificationSwitch classification={classification} />

            <EditClassificationButton classification={classification} />
          </Space>
        }
      >
        <LabelList classification={classification} />
      </Collapse.Panel>
    </Collapse>
  );
}

export default ClassificationCollapse;
