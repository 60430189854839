import type { DropdownProps } from 'antd';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import styles from './DropdownActions.module.less';

export default function DropdownActions(props: DropdownProps) {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        className={styles.dropdown}
        overlayClassName={styles.menu}
        placement="bottomRight"
        trigger={['click']}
        destroyPopupOnHide
        {...props}
      >
        <EllipsisOutlined aria-label="action-button" />
      </Dropdown>
    </div>
  );
}
