import type {
  GridColDef,
  GridValidRowModel,
  GridValueOptionsParams,
} from '@mui/x-data-grid-premium';
import { singleSelectColumn } from 'src/mui/_scss';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

import AccountsAutocomplete from 'src/features/entries/EntriesDataGrid/columns/accountColumn/AccountsAutocomplete';
import AccountInfo from 'src/features/entries/EntriesDataGrid/columns/accountColumn/AccountInfo';
import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

function formatAccount(account?: Account) {
  if (!account) return '';

  const suffix = account?.accountNumber ? ` - ${account?.accountNumber}` : '';
  return `${account?.name}${suffix}`;
}

export function accountColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return singleSelectColumn({
    columnProps: {
      minWidth: 100,
      flex: 2,
      editable: true,
      aggregable: false,
      headerName: 'Conta',
      renderCell: (props) => <AccountInfo {...props} />,
      valueFormatter: (value) => {
        return formatAccount(value);
      },
      groupingValueGetter: (value) => {
        return formatAccount(value) ?? 'sem conta';
      },
      getOptionValue: (value: any) => value?.id,
      getOptionLabel: (value: any) => formatAccount(value),
      valueOptions: (params: GridValueOptionsParams<T>) => {
        return params?.row?.account;
      },
      sortComparator: (accA, accB) => {
        const nameA = formatAccount(accA);
        const nameB = formatAccount(accB);

        return nameA.localeCompare(nameB);
      },
      ...columnProps,
      field: 'account',
    },
    SelectComponent: AccountsAutocomplete,
  });
}
