import cn from 'classnames';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { AlertHexagonIcon, BuildingIcon } from 'src/mui/_icons';

import { parseQrCode } from 'src/libs/finbits/Bank/Pix';
import { toCurrency } from 'src/libs/finbits/Money';

import styles from './PixQrCodeAlert.module.scss';

type Props = {
  qrCode?: string | null;
  amountToMatch?: number;
};

export default function PixQrCodeAlert({ qrCode, amountToMatch }: Props) {
  if (!qrCode) return null;

  const { error, parsedQrCode } = parseQrCode(qrCode);

  const matchesAmount =
    Boolean(amountToMatch) && parsedQrCode?.transactionAmount === amountToMatch;

  const alertClass = cn({
    [styles.warningColor]: !matchesAmount,
  });

  if (error) {
    return (
      <Alert
        className={styles.warningColor}
        severity="default"
        variant="filled"
        icon={<AlertHexagonIcon fontSize="small" />}
      >
        <AlertTitle>Codigo invalido</AlertTitle>
      </Alert>
    );
  }

  return (
    <Alert
      severity="default"
      variant="filled"
      className={alertClass}
      icon={
        matchesAmount ? (
          <BuildingIcon fontSize="inherit" />
        ) : (
          <AlertHexagonIcon fontSize="inherit" />
        )
      }
      classes={{ message: styles.message }}
    >
      <AlertTitle>Para {parsedQrCode?.merchantName}</AlertTitle>

      <Typography>
        Cidade: <b>{parsedQrCode?.merchantCity}</b>
      </Typography>

      <Typography>
        Chave Pix: <b>{parsedQrCode?.pixKey}</b>
      </Typography>

      <Typography>
        Valor: <b>{toCurrency(parsedQrCode?.transactionAmount ?? 0)}</b>
      </Typography>

      {!matchesAmount && (
        <Alert icon={false} severity="warning" className={styles.noMatchAlert}>
          <Typography variant="text-xs">
            O valor do código não corresponde ao valor preenchido. Se
            prosseguir, a inclusão do pagamento será feito com valor do
            formulário.
          </Typography>
        </Alert>
      )}
    </Alert>
  );
}
