import type { ReactNode } from 'react';

import cn from 'classnames';
import type { RadioProps } from '@mui/material';
import { Radio } from '@mui/material';
import { CheckCircleIcon } from 'src/mui/_icons';
import FocusIcon from 'src/mui/FocusIcon';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';

import styles from './RadioCard.module.scss';

type Props = RadioProps & {
  icon?: ReactNode;
  label?: ReactNode;
  description?: string;
  value: string;
};

export default function RadioCard({
  label,
  description,
  icon,
  ...muiRadioProps
}: Props) {
  const checked = Boolean(muiRadioProps.checked);

  const alertClassName = cn(styles.alert, {
    [styles.alertCheck]: checked,
  });

  const alertTileClassName = cn(styles.alertTitle, {
    [styles.alertTitleChecked]: checked,
  });

  return (
    <Alert
      variant="outlined"
      icon={
        <FocusIcon className={styles.icon} size="sm">
          {icon}
        </FocusIcon>
      }
      className={alertClassName}
      action={
        <Radio
          className={styles.radio}
          size="small"
          {...muiRadioProps}
          checkedIcon={
            <CheckCircleIcon fontSize="small" className={styles.checkedIcon} />
          }
          inputProps={{
            'aria-label': muiRadioProps.value,
          }}
        />
      }
    >
      <AlertTitle className={alertTileClassName}>{label}</AlertTitle>
      <Typography>{description}</Typography>
    </Alert>
  );
}
