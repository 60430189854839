import type { SxProps, Theme } from '@mui/material';

export const navigation: SxProps<Theme> = {
  alignItems: 'center',
  ml: 3,
  gap: 2,

  display: { md: 'flex', lg: 'none' },
};

export const divider: SxProps<Theme> = {
  my: '4px !important',
};
