import type { ReactNode } from 'react';

import cn from 'classnames';
import type { CardProps as AntdCardProps } from 'antd';
import { Card as AntdCard } from 'antd';

import styles from './Card.module.less';

export type Props = AntdCardProps & {
  boxShadow?: 0 | 1 | 2;
  fullHeight?: boolean;
};

function Card({
  boxShadow = 0,
  fullHeight = false,
  className,
  ...rest
}: Props) {
  return (
    <AntdCard
      data-testid="card"
      className={cn([
        boxShadow === 1 && styles.boxShadowOne,
        boxShadow === 2 && styles.boxShadowTwo,
        fullHeight && styles.fullHeight,
        className,
      ])}
      {...rest}
    />
  );
}

type ContentProps = {
  children: ReactNode;
};

function Content({ children }: ContentProps) {
  return <div className={styles.content}>{children}</div>;
}

Card.Content = Content;

export default Card;
