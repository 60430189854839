import type { ElementType, ReactNode } from 'react';

import type { LinkProps } from '@mui/material';
import { Link as MLink } from '@mui/material';

import styles from './Link.module.scss';

type Props = {
  startIcon?: ReactNode;
  children: ReactNode;
};

export default function Link<T extends ElementType<any>>({
  startIcon,
  children,
  ...rest
}: Props & LinkProps<T>) {
  return (
    <MLink className={styles.link} role="link" {...rest}>
      {startIcon && startIcon}
      {children}
    </MLink>
  );
}
