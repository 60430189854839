import { useState } from 'react';

const rotateRange = [0, 90, 180, 270];
const LAST_ROTATE_INDEX = rotateRange.length - 1;

export function useControls() {
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);

  function handleZoomIn() {
    setZoom((prevZoom) => prevZoom + 0.2);
  }

  function handleZoomOut() {
    setZoom((prevZoom) => prevZoom - 0.2);
  }

  function handleRotateLeft() {
    setRotate((prevRotate) => {
      if (prevRotate === 0) return LAST_ROTATE_INDEX;

      return prevRotate - 1;
    });
  }

  function handleRotateRight() {
    setRotate((prevRotate) => {
      if (prevRotate === LAST_ROTATE_INDEX) return 0;

      return prevRotate + 1;
    });
  }

  function resetControls() {
    setZoom(1);
    setRotate(0);
  }

  return {
    zoom,
    rotate: rotateRange[rotate],
    handleRotateLeft,
    handleRotateRight,
    handleZoomIn,
    handleZoomOut,
    resetControls,
  };
}
