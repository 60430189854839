import { unMask } from 'src/libs/finbits/Masker';
import { removeAccentuation } from 'src/libs/finbits/Accentuation';

export function contactSelectFilter(input: string, option: any) {
  const inputTransformed = input.toLowerCase().trim();

  const name = option['data-name'];
  const nickname = option['data-nickname'];
  const document = option['data-document'];

  const nameMatched =
    name &&
    removeAccentuation(name)
      .toLowerCase()
      .indexOf(removeAccentuation(inputTransformed)) >= 0;
  const nicknameMatched =
    nickname &&
    removeAccentuation(nickname)
      .toLowerCase()
      .indexOf(removeAccentuation(inputTransformed)) >= 0;
  const documentMatched =
    document && unMask(document).indexOf(unMask(inputTransformed)) >= 0;

  return nameMatched || nicknameMatched || documentMatched;
}
