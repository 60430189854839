import type { GridCellParams } from '@mui/x-data-grid-premium';

import type { RowProps } from './useEditCellValidation';

type CheckCellParams = Pick<GridCellParams<RowProps>, 'row' | 'field'>;

function formatFieldKey(field: string) {
  if (['category', 'account', 'contact'].includes(field)) {
    return `${field}Id`;
  }

  if (field.startsWith('classifications:')) {
    return 'classifications';
  }

  return field;
}

export default function isCellEditable({ row, field }: CheckCellParams) {
  return row.editableFields?.includes(formatFieldKey(field) as any);
}
