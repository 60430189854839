import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogContent, Slide, Stack } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from 'src/design-system/components';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { useCreateClassifications } from 'src/libs/finbits/Classifications';
import type { FormParams } from 'src/libs/finbits/Classifications/types';
import { FormParamsDecoder } from 'src/libs/finbits/Classifications/types';
import { errorsToFormField } from 'src/libs/finbits/Form';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';

import ClassificationField from 'src/features/classifications/ClassificationField';

import styles from './CreateClassificationModal.module.scss';

type Props = PortalProps;

export default function CreateClassificationModal({
  onClose,
  onExit,
  open = true,
}: Props) {
  const form = useForm<FormParams>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(FormParamsDecoder),
  });

  const { handleSubmit, setError } = form;

  const { companyId, organizationId } = useCompanyParams();

  const { createClassification, isLoading } = useCreateClassifications();

  function onSubmit(params: FormParams) {
    createClassification(
      { companyId, organizationId, ...params },
      {
        onSuccess: () => {
          onClose();

          snackbar({ variant: 'success', message: 'Classificação criada' });
        },
        onError: ({ response }: ApiError<ApiErrorForm>) => {
          if (response?.status === 422) {
            const formErrors = errorsToFormField(response.data.errors);

            formErrors.forEach(({ errors }) => {
              errors.forEach((error) => {
                setError('name', { message: error });
              });
            });

            return;
          }

          snackbar({
            variant: 'error',
            message: 'Erro ao criar classificação',
          });
        },
      }
    );
  }

  return (
    <Dialog
      title="Nova classificação"
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
    >
      <Dialog.Header
        size="small"
        title={
          <Stack direction="row" gap={2} alignItems="center">
            Nova classificação
          </Stack>
        }
        onClose={onClose}
      />
      <FormProvider {...form}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={styles.dialogContent}>
            <Typography className={styles.subtitle}>
              Digite o nome da classificação que deseja criar.
            </Typography>

            <ClassificationField />
          </DialogContent>

          <Dialog.Actions>
            <Button fullWidth size="large" variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              Criar
            </LoadingButton>
          </Dialog.Actions>
        </Box>
      </FormProvider>
    </Dialog>
  );
}
