import { Steps } from 'antd';

import type { PortalProps } from 'src/ui';
import { Drawer, DrawerHeader, PaymentIcon, Title } from 'src/ui';

import CompanyStep from 'src/features/nota-fiscal/Steps/CompanyStep';
import ServicesStep from 'src/features/nota-fiscal/Steps/ServicesStep';
import ComplementaryDataStep from 'src/features/nota-fiscal/Steps/ComplementaryDataStep';
import DigitalCertificateStep from 'src/features/nota-fiscal/Steps/DigitalCertificateStep';

import ResultStep from './ResultStep';
import styles from './EnableNotaFiscalDrawer.module.less';
import { useEnableNotaFiscalDrawer } from './useEnableNotaFiscalDrawer';

type Props = {
  onClose: () => void;
  openCreateNotaFiscalDrawer: () => void;
} & PortalProps;

export default function EnableNotaFiscalDrawer({
  onClose,
  openCreateNotaFiscalDrawer,
  open = true,
  onExit,
}: Props) {
  const {
    form,
    formErrors,
    resultType,
    stepsProps,
    isButtonFinishedLoading,
    isButtonSendLaterLoading,
    indexStepsWithError,
    totalStepsTaken,
    currentStep,
    handleContinue,
    handlePrevious,
    handleOnSubmit,
    handleSendLater,
    handleOnResolveError,
  } = useEnableNotaFiscalDrawer();

  function onClickOpenCreateNotaFiscalDrawer() {
    onClose();
    openCreateNotaFiscalDrawer();
  }

  const steps = [
    <CompanyStep
      form={form}
      errors={formErrors}
      onSubmit={handleContinue}
      onResolveError={handleOnResolveError(0)}
    />,
    <ComplementaryDataStep
      form={form}
      errors={formErrors}
      onSubmit={handleContinue}
      onPrevious={handlePrevious}
      onResolveError={handleOnResolveError(1)}
      initialValues={{
        address: { city: form.getFieldValue(['address', 'city']) },
      }}
    />,
    <ServicesStep
      form={form}
      initialValues={{
        address: { city: form.getFieldValue(['address', 'city']) },
        services: form.getFieldValue('services'),
      }}
      onSubmit={handleContinue}
      onPrevious={handlePrevious}
      onResolveError={handleOnResolveError(2)}
    />,
    <DigitalCertificateStep
      form={form}
      errors={formErrors}
      onSubmit={handleOnSubmit}
      onPrevious={handlePrevious}
      initialValues={{
        file: form.getFieldValue('file'),
      }}
      isButtonLoading={isButtonFinishedLoading}
      isButtonSendLaterLoading={isButtonSendLaterLoading}
      onClickSendLater={handleSendLater}
      onResolveError={handleOnResolveError(3)}
    />,
  ];

  return (
    <Drawer
      title={
        <>
          <DrawerHeader
            title={
              <Title icon={<PaymentIcon />}>
                Habilitar nota fiscal de serviço
              </Title>
            }
          />
          {!resultType && (
            <Steps {...stepsProps}>
              {steps.map((_, index) => (
                <Steps.Step
                  key={index}
                  status={
                    indexStepsWithError.includes(index) ? 'error' : undefined
                  }
                  disabled={totalStepsTaken < index}
                />
              ))}
            </Steps>
          )}
        </>
      }
      className={styles.drawer}
      footer={null}
      onClose={onClose}
      visible={open}
      afterVisibleChange={onExit}
    >
      {resultType ? (
        <ResultStep
          resultType={resultType}
          onClickWhenTypeIsCompleted={onClickOpenCreateNotaFiscalDrawer}
        />
      ) : (
        steps[currentStep]
      )}
    </Drawer>
  );
}
