import { useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';
import { Table, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table/interface';

import { useOpenPortal } from 'src/ui';

import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { differenceInMinutes, parseISO } from 'src/libs/finbits/Date';
import { PAYMENT_METHOD_DICTIONARY } from 'src/libs/finbits/PaymentMethods/translations';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import { AccountInfo, Amount, Date } from 'src/features/entries/Columns';
import {
  AccountFilter,
  AmountFilter,
  ContactFilter,
  DateFilter,
  DescriptionFilter,
  PaymentMethodFilter,
} from 'src/features/filters';
import EditBillDrawer from 'src/features/bills-to-pay/EditBillDrawer';

import styles from './ReviewTable.module.less';

type Props = {
  bills: BillPayable[];
  selecteds?: BillPayable[];
  onChangeSelecteds?: (bills: BillPayable[]) => void;
};

export default function ReviewTable({
  bills,
  selecteds = [],
  onChangeSelecteds,
}: Props) {
  const openPortal = useOpenPortal();

  const contacts = useMemo(() => {
    const billsWithContact = bills.filter((bill) => Boolean(bill.contact));

    return uniqBy(
      billsWithContact.map((bill) => bill.contact),
      'id'
    ) as Contact[];
  }, [bills]);

  const accounts = useMemo(() => {
    const billsWithAccounts = bills.filter((bill) => Boolean(bill.account));

    return uniqBy(
      billsWithAccounts.map((bill) => bill.account),
      'id'
    ) as Account[];
  }, [bills]);

  const columns: ColumnsType<BillPayable> = [
    {
      key: 'date',
      className: 'date',
      title: 'Data de pagamento',
      ...DateFilter<BillPayable>('date'),
      render: ({ date }: BillPayable) => <Date date={date} showOverDueIcon />,
      sorter: (a: BillPayable, b: BillPayable) =>
        differenceInMinutes(parseISO(a.date), parseISO(b.date)),
    },
    {
      key: 'description',
      title: 'Descrição',
      ...DescriptionFilter<BillPayable>(),
      render: ({ description }: BillPayable) => (
        <Tooltip
          placement="topLeft"
          title={description}
          overlayInnerStyle={{ textAlign: 'center' }}
        >
          <Typography.Text>{description}</Typography.Text>
        </Tooltip>
      ),
      sorter: (a: BillPayable, b: BillPayable) =>
        a.description.localeCompare(b.description),
    },
    {
      key: 'account',
      align: 'center',
      title: 'Conta',
      ...AccountFilter<BillPayable>({ accounts }),
      render: (record: BillPayable) => {
        return <AccountInfo account={record.account} />;
      },
      sorter: (recordA: BillPayable, recordB: BillPayable) => {
        const a = recordA.account?.name ?? '';
        const b = recordB.account?.name ?? '';

        return a.localeCompare(b);
      },
    },
    {
      key: 'contact',
      title: 'Contato',
      ...ContactFilter<BillPayable>({ contacts }),
      render: ({ contact }: BillPayable) => contact?.nickname ?? '',
      sorter: (a: BillPayable, b: BillPayable) => {
        const entryA = a.contact?.nickname ?? '';
        const entryB = b.contact?.nickname ?? '';

        return entryA.localeCompare(entryB);
      },
    },
    {
      key: 'paymentMethod',
      title: 'Método',
      ...PaymentMethodFilter<BillPayable>(),
      render: ({ paymentDetails }: BillPayable) =>
        paymentDetails?.paymentMethod &&
        PAYMENT_METHOD_DICTIONARY[paymentDetails?.paymentMethod],
      sorter: (a: BillPayable, b: BillPayable) => {
        const entryA = a.paymentDetails?.paymentMethod ?? '';
        const entryB = b.paymentDetails?.paymentMethod ?? '';

        return entryA.localeCompare(entryB);
      },
    },
    {
      key: 'amount',
      title: 'Valor',
      align: 'right',
      render: ({ amount }: BillPayable) => (
        <Amount amount={amount} type={BalanceType.DEBIT} />
      ),
      sorter: (a: BillPayable, b: BillPayable) => a.amount - b.amount,
      ...AmountFilter<BillPayable>(),
    },
  ];

  const selectedIds = selecteds.map(({ id }) => id);

  function handleRowClick(row: BillPayable) {
    openPortal(EditBillDrawer, {
      billId: row.id,
    });
  }

  return (
    <Table
      className={styles.table}
      bordered
      rowKey="id"
      size="small"
      columns={columns}
      pagination={{
        position: ['bottomRight'],
        defaultPageSize: 5,
        showTotal: (total) => (
          <div>{`${total} ${pluralize(
            total,
            'Lançamento',
            'Lançamentos'
          )}`}</div>
        ),
      }}
      dataSource={bills}
      rowSelection={
        onChangeSelecteds && {
          type: 'checkbox',
          selectedRowKeys: selectedIds,
          onChange: (_, value) => onChangeSelecteds(value),
          preserveSelectedRowKeys: true,
        }
      }
      onRow={(row) => ({
        onClick: () => handleRowClick(row),
      })}
    />
  );
}
