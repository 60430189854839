import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function ReorderIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="reorder"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M20 10H4M20 14H4M9 18L12 21L15 18M15 6L12 3L9 6"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
