import { useEffect, useRef } from 'react';

import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

import Loading from './Loading';
import EmptyState from './EmptyState';
import InboxItemCard from './InboxItemCard';

const INBOX_ITEM_CARD_HEIGHT = 159;

type Props = {
  isFiltering?: boolean;
  isLoading?: boolean;
  inboxItems: NewInboxItem[];
  activeIndex: number;
  setActiveInboxItemId: (activeInboxItemId: string) => void;
  heightOffset: number;
};

export default function InboxItemList({
  isFiltering = false,
  isLoading,
  inboxItems,
  activeIndex,
  setActiveInboxItemId,
  heightOffset,
}: Props) {
  const ref = useRef<FixedSizeList>(null);

  useEffect(() => {
    if (activeIndex !== -1) {
      ref.current?.scrollToItem(activeIndex, 'auto');
    }
  }, [activeIndex]);

  if (isLoading) {
    return <Loading />;
  }

  if (inboxItems.length === 0) {
    return <EmptyState isFiltering={isFiltering} />;
  }

  return (
    <AutoSizer>
      {({ height = 0, width = 0 }) => (
        <FixedSizeList
          ref={ref}
          itemData={inboxItems}
          height={height - heightOffset}
          width={width}
          itemCount={inboxItems.length}
          itemSize={INBOX_ITEM_CARD_HEIGHT}
          overscanCount={5}
        >
          {({ style, index, data }) => {
            const inboxItem = data.at(index);

            if (!inboxItem) return null;

            return (
              <InboxItemCard
                key={index}
                style={style}
                inboxItem={inboxItem}
                activeIndex={activeIndex}
                isActive={index === activeIndex}
                onClick={() => setActiveInboxItemId(inboxItem.id)}
              />
            );
          }}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}
