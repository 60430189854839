import { Form } from 'antd';

import { snackbar } from 'src/mui';

import { Loader } from 'src/ui';

import { useCompany } from 'src/libs/finbits/Organization/Companies/Companies';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  useCompanyServices,
  useUpdateCompanyServices,
} from 'src/libs/finbits/NotaFiscal/Services';
import {
  errorsToFormField,
  isStructuredFormError,
} from 'src/libs/finbits/Form';
import type { UpdateCompanyServicesFormParams } from 'src/libs/finbits/NotaFiscal/Services/types';

import ServicesStep from 'src/features/nota-fiscal/Steps/ServicesStep';

export default function Services() {
  const [form] = Form.useForm();

  const { companyId, organizationId } = useCompanyParams();
  const { company } = useCompany({ companyId, organizationId });

  const { services, isLoading } = useCompanyServices({
    companyId,
    organizationId,
  });

  const { updateCompanyServices, isLoading: isSaving } =
    useUpdateCompanyServices();

  function handleSubmit({ services }: UpdateCompanyServicesFormParams) {
    if (!organizationId || !companyId) return;

    updateCompanyServices(
      {
        organizationId,
        companyId,
        services: services.map(({ municipalServiceId, mainActivity }) => ({
          municipalServiceId,
          mainActivity,
        })),
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Alterações salvas com sucesso.',
          });

          form.setFields([{ name: 'services', touched: false }]);
        },
        onError: ({ response }) => {
          const data = response?.data;

          if (isStructuredFormError(data)) {
            return form.setFields(errorsToFormField(data.errors));
          }

          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar as alterações.',
          });
        },
      }
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ServicesStep
      form={form}
      showTitle={false}
      buttonText="Salvar"
      onSubmit={handleSubmit}
      isButtonLoading={isSaving}
      initialValues={{
        services,
        address: {
          city: company?.address?.city,
        },
      }}
    />
  );
}
