import { Divider, Stack } from '@mui/material';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import {
  format,
  isFuture,
  isToday,
  startOfYesterday,
} from 'src/libs/finbits/Date';
import type { FinancialStatementEntriesSummary } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import BalanceAmount from './BalanceAmount';
import MoneyBox from './MoneyBox';
import styles from './EntriesSummary.module.scss';

export type Props = {
  data: FinancialStatementEntriesSummary;
  selectedStartDate: Date;
  selectedEndDate: Date;
};

function getCurrentBalanceDate(endDate: Date) {
  if (isToday(endDate) || isFuture(endDate)) {
    return startOfYesterday();
  }
  return endDate;
}

export default function EntriesSummary({
  data,
  selectedStartDate,
  selectedEndDate,
}: Props) {
  const projectedBalance = data.summary.projectedBalance;
  const currentBalance = data.summary.finalBalance;
  const cashIn = data.summary.receivableAmount + data.summary.creditAmount;
  const cashOut = data.summary.billAmount + data.summary.debitAmount;

  const hasInconsistency = data.accounts.some(
    ({ inconsistency }) => inconsistency.amount > 0
  );

  const someBalancePending = data.accounts.some(
    ({ balancePending }) => balancePending
  );

  const currentBalanceDate = getCurrentBalanceDate(selectedEndDate);

  return (
    <Stack direction="row">
      <Stack>
        <Stack direction="row" alignItems="center" mb={2}>
          <Typography variant="text-xs" fontWeight={600}>
            Projetado de {format(selectedStartDate, 'dd/MM/yyyy')} a{' '}
            {format(selectedEndDate, 'dd/MM/yyyy')}
          </Typography>
        </Stack>
        <Divider classes={{ root: styles.lineDivider }} />
        <Stack
          mt={2}
          direction="row"
          justifyContent="space-around"
          alignItems="flex-end"
          spacing={6}
        >
          <MoneyBox
            text={'Entradas'}
            amount={cashIn}
            Icon={<ArrowCircleUpIcon color="success" />}
          />
          <MoneyBox
            text={'Saídas'}
            amount={cashOut}
            Icon={<ArrowCircleDownIcon color="error" />}
          />

          <MoneyBox text="Saldo previsto">
            <BalanceAmount
              amount={projectedBalance.amount}
              amountType={projectedBalance.type}
              hasInconsistency={hasInconsistency}
              someBalancePending={someBalancePending}
              accounts={data.accounts}
              defaultTipText="Saldo projetado das contas selecionadas, calculado com base nas transações lançadas na FinBits dentro do período filtrado."
            />
          </MoneyBox>
        </Stack>
      </Stack>
      <Stack ml={10}>
        <Stack direction="row" alignItems="center" mb={2}>
          <Typography variant="text-xs" fontWeight={600}>
            Realizado em {format(currentBalanceDate, 'dd/MM/yyyy')}
          </Typography>
        </Stack>
        <Divider classes={{ root: styles.lineDivider }} />
        <Stack mt={2} direction="row" alignItems="flex-end">
          <MoneyBox text="Saldo realizado">
            <BalanceAmount
              amount={currentBalance.amount}
              amountType={currentBalance.type}
              hasInconsistency={hasInconsistency}
              someBalancePending={someBalancePending}
              accounts={data.accounts}
              defaultTipText="Saldo realizado das contas selecionadas, calculado com base nas transações realizadas que foram lançadas na FinBits até o final do período filtrado."
            />
          </MoneyBox>
        </Stack>
      </Stack>
    </Stack>
  );
}
