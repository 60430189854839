import type { ImporterFailure } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import styles from './ErrorList.module.less';
import RowErrors from './RowErrors';

type props = {
  failures?: ImporterFailure[];
};

export default function ErrorList({ failures = [] }: props) {
  return (
    <ul className={styles.list}>
      {failures.map((failure, index) => {
        return (
          <li key={index} className={styles.items}>
            <h2 className={styles.title}>Linha {failure.rowNumber}</h2>
            <ul className={styles.errors}>
              <RowErrors errors={failure.errors} />
            </ul>
          </li>
        );
      })}
    </ul>
  );
}
