import ptBR from 'antd/lib/locale/pt_BR';

export const locale = {
  ...ptBR,
  Table: {
    ...ptBR.Table,
    cancelSort: 'Limpar',
    filterCheckall: 'Selecionar Todos',
    filterConfirm: 'Aplicar',
    filterReset: 'Limpar',
    triggerAsc: 'Ordenar: crescente',
    triggerDesc: 'Ordenar: decrescente',
  },
};
