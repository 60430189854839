import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import { CitiesDecoder } from './types';
import type { City } from './types';

type GetParams = {
  stateCode: string | null;
};

async function getCities({ stateCode }: GetParams) {
  const response = await authenticatedAPI.get(
    `/ibge/states/${stateCode}/cities`
  );
  return decodeResponse<City[]>(response, CitiesDecoder);
}

export function useCities<TData = City[]>({ stateCode }: Partial<GetParams>) {
  return useQuery<City[], ApiError, TData>({
    queryKey: ['cities', { stateCode }],
    queryFn: () => getCities({ stateCode: stateCode! }),
    enabled: !!stateCode,
  });
}
