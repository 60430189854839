import { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { generateCompanyPath } from 'src/router/routes';
import type { RouteKey } from 'src/router/types';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

export enum NavigateAction {
  OPEN_FINBOT = 'openFinbot',
}

export function useExecuteNavigateAction(
  callback: () => void,
  action: NavigateAction
) {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const currentAction = searchParams.get('action');

    if (currentAction === action) {
      callback();
    }
  }, [searchParams, callback, action]);
}

export function useNavigateAction(routeKey: RouteKey, action: NavigateAction) {
  const navigate = useNavigate();
  const { companyId, organizationId } = useCompanyParams();

  const navigateTo = useCallback(() => {
    navigate({
      pathname: generateCompanyPath(routeKey, {
        companyId,
        organizationId,
      }),
      search: `action=${action}`,
    });
  }, [companyId, organizationId, routeKey, action, navigate]);

  return navigateTo;
}
