import type { KeyboardEvent } from 'react';

import { Button } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/lib/table/interface';

import { AmountInput } from 'src/ui';

import styles from './filters.module.less';

export default function AmountFilter<
  T extends {
    amount?: number;
  }
>(): ColumnType<T> {
  function handleEnter(
    e: KeyboardEvent<HTMLDivElement>,
    confirm: (param?: FilterConfirmProps) => void
  ) {
    if (e.code === 'Enter') {
      e.stopPropagation();
      confirm();
    }
  }
  return {
    filterDropdown: ({
      setSelectedKeys,
      confirm,
      selectedKeys,
      clearFilters,
    }) => (
      <div className={styles.wrapper}>
        <AmountInput
          autoFocus
          isIconVisible={false}
          placeholder="0,00"
          onChange={(value) => {
            setSelectedKeys([value ?? '']);
          }}
          onKeyDown={(e) => handleEnter(e, confirm)}
          size="middle"
          value={selectedKeys[0]}
        />

        <div className={styles.actions}>
          <Button
            type="link"
            size="small"
            disabled={!Boolean(selectedKeys.length)}
            onClick={() => {
              clearFilters && clearFilters();
            }}
          >
            Limpar
          </Button>
          <Button type="primary" size="small" onClick={() => confirm()}>
            Aplicar
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => record.amount === value,
  };
}
