import { Box, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import noPreviewImg from 'src/assets/no-preview.svg';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

import styles from './PreviewError.module.scss';

type Props = {
  attachment: Attachment;
};

export default function PreviewError({ attachment }: Props) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      className={styles.container}
    >
      <Box
        component="img"
        src={noPreviewImg}
        alt="Preview não suportado"
        draggable={false}
      />

      <Stack
        direction="row"
        justifyContent="center"
        className={styles.attachment}
        gap={1}
      >
        <Typography
          ellipsis
          tooltip={{ placement: 'bottom-start' }}
          variant="text-lg"
          fontWeight="600"
          className={styles.file}
        >
          {attachment.name}
        </Typography>

        <Typography variant="text-lg" fontWeight="600">
          sem visualização
        </Typography>
      </Stack>

      <Typography align="center" className={styles.description}>
        Infelizmente, o recurso de visualização não está disponível para este
        anexo.
      </Typography>
    </Stack>
  );
}
