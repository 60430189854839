import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons/lib/icons';

export default function InfoTooltip() {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Tooltip
        title={
          <>
            <p>
              Administrador: pode cadastrar empresas, inserir e excluir
              documentos e informações, criar e revogar acesso aos demais
              usuários
            </p>
            <p>
              Aprovador: acesso limitado a aprovações que lhe forem designadas
            </p>
            <p>
              Gestor Financeiro: pode inserir e excluir documentos e
              informações, criar e revogar acesso aos demais usuários
              relacionados à empresa pela qual é gestor
            </p>
            <p>
              Operador Financeiro: pode inserir e excluir documentos e
              informações
            </p>
            <p>Visualizador: acesso limitado à leitura das informações</p>
            <p>Sem permissão: não tem acesso às informações da empresa</p>
          </>
        }
      >
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
}
