import cn from 'classnames';
import { Button } from '@mui/material';
import { PlusIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import type { ApprovalRule } from 'src/libs/finbits/ApprovalRules/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import CreateApprovalRuleModal from 'src/features/approval/approval-rules/CreateApprovalRuleModal';

import styles from './CreatePositionedRuleButton.module.scss';

type Props = {
  approvalRule: ApprovalRule;
  isDragging?: boolean;
  isFirst?: boolean;
};

export default function CreatePositionedRuleButton({
  approvalRule,
  isDragging,
  isFirst = false,
}: Props) {
  const openPortal = useOpenPortal();

  function handleNewClick() {
    if (isFirst) return;

    openPortal(CreateApprovalRuleModal, { referenceRuleId: approvalRule.id });
  }

  return (
    <WithAllowedPermission
      permissions={{ resource: 'approvalsRules', action: 'create' }}
    >
      <Button
        className={cn(styles.button, {
          [styles.hide]: isFirst || isDragging,
        })}
        variant="outlined"
        color="secondary"
        onClick={handleNewClick}
        size="small"
      >
        <Tooltip
          disableHoverListener={isFirst}
          disableInteractive
          title={`Criar nova regra com prioridade maior que a de "${approvalRule.description}"`}
        >
          <PlusIcon fontSize="xsmall" />
        </Tooltip>
      </Button>
    </WithAllowedPermission>
  );
}
