import cn from 'classnames';
import { Box, IconButton, ListItem, Stack, Tooltip } from '@mui/material';
import {
  AlertTriangleIcon,
  AttachmentIcon,
  DownloadCloudIcon,
} from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { snackbar } from 'src/mui';

import type {
  Attachment,
  ExternalFiles,
} from 'src/libs/finbits/Management/Attachments/types';
import { useDownloadExternalFile } from 'src/libs/finbits/Management/Attachments';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { useOpenConfirmDownloadDialog } from 'src/features/inbox-items/InboxItemDetails/ConfirmationModal';

import Preview from './Preview';
import styles from './AttachmentItem.module.scss';

type Props = {
  attachment: Attachment | ExternalFiles;
  isInvalid: boolean;
  index: number;
  onClick: (index: number) => void;
  sender?: string | null;
};

export default function AttachmentItem({
  attachment,
  isInvalid,
  index,
  onClick,
  sender,
}: Props) {
  const openConfirmDownload = useOpenConfirmDownloadDialog();
  const { companyId, organizationId } = useCompanyParams();
  const { downloadExternalFile } = useDownloadExternalFile();

  function handleDownload() {
    downloadExternalFile(
      {
        companyId,
        organizationId,
        attachmentId: attachment.id,
      },
      {
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao fazer o download, tente novamente.',
          });
        },
      }
    );
  }

  function handleDownloadModal() {
    openConfirmDownload({
      attachmentName: attachment.name,
      sender,
      onConfirm: handleDownload,
    });
    return;
  }

  return (
    <ListItem key={attachment.id} className={styles.listItem}>
      <Box className={styles.preview} onClick={() => onClick(index)}>
        {isInvalid ? (
          <Box className={styles.invalidAttachment}>
            <AttachmentIcon className={styles.invalidAttachmentIcon} />
          </Box>
        ) : (
          <Preview attachment={attachment as Attachment} />
        )}
      </Box>
      <Stack className={styles.actions}>
        <Box className={styles.fileBox}>
          {isInvalid && <AlertTriangleIcon className={styles.alertIcon} />}
          <Typography
            ellipsis
            className={cn(styles.attachmentFile, {
              [styles.attachmentFileInvalid]: isInvalid,
            })}
          >
            {attachment.name}
          </Typography>
        </Box>

        <Tooltip title="Download">
          {isInvalid ? (
            <IconButton aria-label="Download" onClick={handleDownloadModal}>
              <DownloadCloudIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="Download"
              href={attachment.url}
              download={attachment.name}
              target="_blank"
            >
              <DownloadCloudIcon />
            </IconButton>
          )}
        </Tooltip>
      </Stack>
    </ListItem>
  );
}
