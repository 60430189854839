import type { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './EmptyState.module.scss';

type Props = {
  status?: BillStatus;
};

export default function EmptyState({ status }: Props) {
  const { organizationId, companyId } = useCompanyParams();

  const description: Record<BillStatus, ReactNode> = {
    [BillStatus.PENDING]: (
      <>
        {'Nenhum lançamento a pagar, verifique sua '}
        <Link
          to={`/organizations/${organizationId}/companies/${companyId}/inbox_items`}
        >
          Caixa de Entrada
        </Link>
      </>
    ),
    [BillStatus.WAITING_APPROVAL]: 'Nenhum lançamento em aprovação',
    [BillStatus.APPROVED]: 'Nenhum lançamento aprovado',
    [BillStatus.WAITING_PAYMENT]: 'Nenhum lançamento enviado para pagamento',
    [BillStatus.REPROVED]: 'Nenhum lançamento reprovado',
  };

  if (!status) return null;

  return <div className={styles.emptyState}>{description[status]}</div>;
}
