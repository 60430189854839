import { Col, Form, Input, Select, Typography } from 'antd';

import { FormItem } from 'src/ui';

import { greaterThanZeroValidator } from 'src/libs/finbits/Amount';
import { addMonths, addWeeks, format } from 'src/libs/finbits/Date';
import { FrequencyUnit } from 'src/libs/finbits/Management/ScheduledRecurrences/types';

import type {
  EntryFormDisabledFields,
  EntryFormHiddenFields,
} from 'src/features/EntryForm/types';

import styles from './RecurrenceFields.module.less';

type Props = {
  disabled?: boolean;
  disabledFields?: EntryFormDisabledFields;
  hiddenFields?: EntryFormHiddenFields;
};

function calculateEndDate(
  dateString: string,
  frequencyInterval: string,
  frequencyUnit: string,
  quantity: string
) {
  const initialDate = new Date(dateString);
  const intervalAmount = Number(frequencyInterval) * (Number(quantity) - 1);

  if (frequencyUnit === FrequencyUnit.MONTHS) {
    return format(addMonths(initialDate, intervalAmount), 'dd/MM/yyyy');
  }

  return format(addWeeks(initialDate, intervalAmount), 'dd/MM/yyyy');
}

function RecurrenceFields({
  disabled = false,
  disabledFields = [],
  hiddenFields = [],
}: Props) {
  function frequencyIntervalValidator(_rule: any, value?: string) {
    if (Number(value) < 25) {
      return Promise.resolve(null);
    }

    return Promise.reject('Deve ser menor que 25');
  }

  function quantityValidator(_rule: any, value?: string) {
    if (Number(value) < 54) {
      return Promise.resolve(null);
    }

    return Promise.reject('Deve ser menor que 54');
  }

  const frequencyUnitSelect = (
    <FormItem required name="frequencyUnit" initialValue="months" noStyle>
      <Select
        aria-label="Repete a Cada Unidade"
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        <Select.Option value="months">mês(es)</Select.Option>
        <Select.Option value="weeks">semana(s)</Select.Option>
      </Select>
    </FormItem>
  );

  return (
    <>
      {!hiddenFields.includes('frequencyInterval') && (
        <Col span={12}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <FormItem
                required
                name="frequencyInterval"
                label="Repete a cada"
                rules={[
                  {
                    validator: frequencyIntervalValidator,
                  },
                  { validator: greaterThanZeroValidator },
                ]}
              >
                <Input
                  type="number"
                  placeholder="0"
                  addonAfter={frequencyUnitSelect}
                  size="large"
                  disabled={
                    disabled || disabledFields.includes('frequencyInterval')
                  }
                />
              </FormItem>
            )}
          </Form.Item>
        </Col>
      )}

      {!hiddenFields.includes('quantity') && (
        <Col span={12}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <FormItem
                required
                name="quantity"
                label="Termina após"
                rules={[
                  {
                    validator: quantityValidator,
                  },
                  { validator: greaterThanZeroValidator },
                ]}
              >
                <Input
                  className={styles.quantity}
                  type="number"
                  placeholder="0"
                  addonAfter="ocorrências"
                  size="large"
                  disabled={disabled || disabledFields.includes('quantity')}
                />
              </FormItem>
            )}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsError, getFieldsValue }) => {
              const fields = [
                'date',
                'frequencyInterval',
                'frequencyUnit',
                'quantity',
              ];

              const haveFieldsError = getFieldsError(fields).some(
                ({ errors }) => errors.length
              );

              const values = getFieldsValue(fields);

              const haveUndefinedFields =
                values.date === undefined ||
                values.frequencyInterval === undefined ||
                values.quantity === undefined;

              if (!haveFieldsError && !haveUndefinedFields) {
                const endDate = calculateEndDate(
                  values.date,
                  values.frequencyInterval,
                  values.frequencyUnit,
                  values.quantity
                );

                return (
                  <Typography.Text className={styles.endDate} type="secondary">
                    Termina em {endDate}
                  </Typography.Text>
                );
              }

              return null;
            }}
          </Form.Item>
        </Col>
      )}
    </>
  );
}

export default RecurrenceFields;
