import { useEffect } from 'react';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from 'src/layouts/AppLayout';
import SideContentLayout from 'src/layouts/SideContentLayout';
import Providers from 'src/Providers';

import UserProfile from 'src/pages/UserProfile';
import NotFoundPage from 'src/pages/NotFoundPage';
import MuiStyleGuide from 'src/pages/MuiStyleGuide';

import analytics from 'src/libs/analytics/Analytics';

import {
  ORGANIZATION_ROUTE_PATH,
  organizationRoutes,
  publicAuthenticationRoutes,
  publicRoutes,
} from './routes';
import AuthenticatedRoute from './AuthenticatedRoute';
import PublicAuthenticationRoute from './PublicAuthenticationRoute';
import AuthenticatedPermissionRoute from './AuthenticatedPermissionRoute';
import { useInitialOrganizationRoute } from './useInitialOrganizationRoute';
import { useCurrentRoute } from './useCurrentRoute';

const environment = import.meta.env.VITE_ENVIRONMENT ?? 'prod';

function AppRoutes() {
  const { path: initialOrganizationPath, hasCompany } =
    useInitialOrganizationRoute();

  const currentRoute = useCurrentRoute();

  useEffect(() => {
    document.title = currentRoute?.title
      ? `FinBits - ${currentRoute.title}`
      : 'FinBits';

    analytics.page({ data: currentRoute?.path });
  }, [currentRoute]);

  return (
    <Routes>
      {publicRoutes.map(({ id, path, element }) => (
        <Route key={id} id={id} path={path} element={element} />
      ))}

      {environment !== 'prod' && (
        <Route path="/example" element={<MuiStyleGuide />} />
      )}

      <Route
        path="/"
        element={
          <PublicAuthenticationRoute>
            <SideContentLayout />
          </PublicAuthenticationRoute>
        }
      >
        <Route index element={<Navigate to="/login" />} />

        {publicAuthenticationRoutes.map(({ id, path, element }) => (
          <Route key={id} id={id} path={path} element={element} />
        ))}
      </Route>

      <Route
        path="/organizations/:organizationId/profile"
        element={
          hasCompany ? (
            <Navigate to="/" />
          ) : (
            <AuthenticatedRoute>
              <AppLayout />
            </AuthenticatedRoute>
          )
        }
      >
        <Route index element={<UserProfile />} />
      </Route>

      <Route
        path={ORGANIZATION_ROUTE_PATH}
        element={<Navigate replace to={initialOrganizationPath} />}
      />

      {organizationRoutes.map(({ id, path, element, permission }) => (
        <Route
          key={id}
          path={path}
          element={
            <AuthenticatedRoute>
              <AuthenticatedPermissionRoute permissions={permission}>
                <AppLayout />
              </AuthenticatedPermissionRoute>
            </AuthenticatedRoute>
          }
        >
          <Route index element={element} />
        </Route>
      ))}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default function Router() {
  return (
    <BrowserRouter>
      <Providers>
        <AppRoutes />
      </Providers>
    </BrowserRouter>
  );
}
