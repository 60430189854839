import { useSearchParams } from 'react-router-dom';

import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';

const DISABLED_APPROVAL_BILL_STATUS = [
  BillStatus.PENDING,
  BillStatus.WAITING_PAYMENT,
];

export default function useBillStatusFromUrl() {
  const [searchParams] = useSearchParams();
  const { isEnabledBillsApprovals, isLoading: isLoadingCompany } =
    useIsEnabledBillsApprovalsInCompany();

  const urlActiveTab = searchParams.get('status');

  const billStatusArray = isEnabledBillsApprovals
    ? BillStatus
    : DISABLED_APPROVAL_BILL_STATUS;

  const isValidStatus = Object.values(billStatusArray).some(
    (status) => status === urlActiveTab
  );

  if (isValidStatus)
    return { status: urlActiveTab as BillStatus, isLoadingCompany };

  return { status: BillStatus.PENDING, isLoadingCompany };
}
