import {
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import Slide from '@mui/material/Slide';
import compact from 'lodash/compact';
import type { GridToolBarSlots } from 'src/mui/_scss/Table';

import { ToolbarQuickActions } from './ToolbarQuickActions';
import { ToolbarSelectedActions } from './ToolbarSelectedActions';
import styles from './GridToolBar.module.scss';

type Props = {
  setCurrentButtonRef: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  GridToolBarSlots?: GridToolBarSlots<any>;
};

export default function GridToolBar({
  setCurrentButtonRef,
  GridToolBarSlots,
}: Props) {
  const apiRef = useGridApiContext();

  const selected = compact(
    Array.from(apiRef.current.getSelectedRows().values())
  );

  const hasSelected = Boolean(selected.length);

  return (
    <GridToolbarContainer className={styles.gridToolbar}>
      <Slide in={hasSelected} direction="down" key="selected">
        <ToolbarSelectedActions
          selecteds={selected}
          apiRef={apiRef.current}
          SelectedActions={GridToolBarSlots?.SelectedActions}
          selectedText={GridToolBarSlots?.selectedText}
        />
      </Slide>

      <Slide in={!hasSelected} direction="up" key="quick">
        <ToolbarQuickActions
          apiRef={apiRef.current}
          setCurrentButtonRef={setCurrentButtonRef}
          QuickActions={GridToolBarSlots?.QuickActions}
          TableRootActions={GridToolBarSlots?.TableRootActions}
        />
      </Slide>
    </GridToolbarContainer>
  );
}
