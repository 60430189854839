import type { Location } from 'history';

import type { PortalProps } from 'src/ui';
import { Modal } from 'src/ui';

import type { ImportRedirectParams } from 'src/libs/finbits/Bank/Statements/types';

import ImportOfx from './ImportOfx';

type Props = {
  companyId?: string;
  organizationId?: string;
  redirectPathAfterImport: (params: ImportRedirectParams) => Partial<Location>;
} & PortalProps;

function ImportOfxModal({
  organizationId,
  companyId,
  open = true,
  onExit,
  onClose,
  redirectPathAfterImport,
}: Props) {
  return (
    <Modal footer={null} visible={open} onCancel={onClose} afterClose={onExit}>
      <ImportOfx
        organizationId={organizationId}
        companyId={companyId}
        onClose={onClose}
        redirectPathAfterImport={redirectPathAfterImport}
      />
    </Modal>
  );
}

export default ImportOfxModal;
