import type { ForwardRefExoticComponent } from 'react';

import type { InputBaseProps } from '@mui/material';
import { InputBase as MUIInputBase } from '@mui/material';

import InputBaseAmount from './InputBaseAmount';
import InputBaseMasked from './InputBaseMasked';
import InputBaseTableAmount from './InputBaseTableAmount';

type InputCompounded = {
  Amount: typeof InputBaseAmount;
  Masked: typeof InputBaseMasked;
} & ForwardRefExoticComponent<InputBaseProps>;

export const InputBase = {
  ...MUIInputBase,
  Amount: InputBaseAmount,
  Masked: InputBaseMasked,
} as InputCompounded;

export const InputBaseTable = {
  ...MUIInputBase,
  Amount: InputBaseTableAmount,
} as InputCompounded;
