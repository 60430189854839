import { useMemo } from 'react';

import type { GridColDef } from '@mui/x-data-grid-premium';
import { actionColumn, checkboxColumn } from 'src/mui/_scss/Table';

import type { Classification } from 'src/libs/finbits/Classifications/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import { RowActions } from './ContextMenuRowActions/RowActions/RowActions';
import { accountColumn } from './columns/accountColumn/accountColumn';
import { categoryColumn } from './columns/categoryColumn/categoryColumn';
import { contactColumn } from './columns/contactColumn/contactColumn';
import { currentSituationColumn } from './columns/currentSituationColumn/currentSituationColumn';
import { attachmentColumn } from './columns/attachmentColumn/attachmentColumn';
import { amountTypeColumn } from './columns/amountTypeColumn/amountTypeColumn';
import { relevantdateColumn } from './columns/relevantDateColumn/relevantDateColumn';
import { amountColumn } from './columns/amountColumn/amountColumn';
import { dateColumn } from './columns/dateColumn/dateColumn';
import { descriptionColumn } from './columns/descriptionColumn/descriptionColumn';
import { classificationsColumn } from './columns/classificationColumn/classificationColumn';
import { commentsColumn } from './columns/commentsColumn/commentsColumn';

export function useBuildColumns(
  classifications: Classification[],
  opts: { editable: boolean } = { editable: true }
): GridColDef[] {
  const canEdit =
    useAllowedPermission({
      action: 'batch_update',
      resource: 'entries',
    }) && opts.editable;

  const columns = useMemo(() => {
    return [
      checkboxColumn(),
      dateColumn({ editable: canEdit }),
      descriptionColumn({ editable: canEdit }),
      accountColumn({ editable: canEdit }),
      currentSituationColumn(),
      categoryColumn({ editable: canEdit }),
      contactColumn({ editable: canEdit }),
      relevantdateColumn({ editable: canEdit }),
      ...classificationsColumn({
        classifications,
        columnProps: { editable: canEdit },
      }),
      amountColumn({ editable: canEdit }),
      amountTypeColumn(),
      attachmentColumn(),
      commentsColumn({ editable: canEdit }),
      actionColumn({
        getActions: RowActions,
      }),
    ];
  }, [classifications, canEdit]);

  return columns;
}
