import t from 'zod';

import { CompanyDecoder } from 'src/libs/finbits/Organization/Companies/types';

export enum BalanceType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum SynchronizeType {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
  SYNC = 'sync',
}

export const BalanceDecoder = t.object({
  balance: t.number().nullable(),
  balanceType: t.nativeEnum(BalanceType),
  date: t.string().nullable(),
});

export const AccountDecoder = t.object({
  id: t.string().nullable(),
  routingNumber: t.string().nullable(),
  branchNumber: t.string().nullable(),
  accountNumber: t.string().nullable(),
  name: t.string().nullable(),
});

export type Account = t.TypeOf<typeof AccountDecoder>;

export const SummaryAccountsBalancesDecoder = t.object({
  account: t.object({
    id: t.string().nullable(),
    routingNumber: t.string().nullable(),
    branchNumber: t.string().nullable(),
    accountNumber: t.string().nullable(),
    name: t.string().nullable(),
    synchronizeType: t.nativeEnum(SynchronizeType).nullable(),
    synchronizedAt: t.string().nullable(),
  }),
  pendingInitialBalance: t.boolean().nullable(),
  accountBalance: t.object({
    balance: t.number().nullable(),
    balanceType: t.nativeEnum(BalanceType).nullable(),
    refDate: t.string().nullable(),
  }),
  inconsistency: t
    .object({
      inconsistencyValue: t.object({
        type: t.nativeEnum(BalanceType),
        amount: t.number(),
      }),
      lastAccountBalance: t.object({
        type: t.nativeEnum(BalanceType),
        amount: t.number(),
      }),
      lastReportedBalance: t.object({
        type: t.nativeEnum(BalanceType),
        amount: t.number(),
      }),
    })
    .nullable(),
});

export type SummaryAccountsBalances = t.TypeOf<
  typeof SummaryAccountsBalancesDecoder
>;

export type Balance = t.TypeOf<typeof BalanceDecoder>;

export const CompanyBalanceDecoder = BalanceDecoder.merge(
  t.object({
    company: CompanyDecoder,
  })
);

export type CompanyBalance = t.TypeOf<typeof CompanyBalanceDecoder>;

export const AccountBalanceDecoder = BalanceDecoder.merge(
  t.object({
    accountId: t.string(),
  })
);

export type AccountBalance = t.TypeOf<typeof AccountBalanceDecoder>;

export type SummaryAccountBalance = t.TypeOf<typeof AccountBalanceDecoder>;

export type GetCompaniesBalancesParams = {
  organizationId: string;
  date?: string;
};

export type GetAccountsBalancesParams = {
  organizationId: string;
  companyId: string;
  cut_date?: string;
};

export type PostInitialBalanceParams = {
  organizationId: string;
  companyId: string;
  accountId: string;
  cut_date: string;
  initial_balance: string;
  balance_type: BalanceType;
};
