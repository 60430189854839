import type { AxiosError } from 'axios';
import axios from 'axios';

import {
  getRefreshToken,
  logOut,
  refreshTokens,
  setTokens,
} from 'src/libs/finbits/Auth';
import type { AuthenticationTokens } from 'src/libs/finbits/Auth/types';

import type { ErrorResponse } from '.';

async function unauthorizedInterceptor(error: AxiosError) {
  if (
    error?.response?.status !== 401 ||
    error.config?.url === '/authentications'
  ) {
    return Promise.reject(error);
  }

  const token = getRefreshToken();

  if (!token) {
    logOut();
    return Promise.reject(error);
  }

  const response = await refreshTokens(token);

  if ((response as ErrorResponse).isError) {
    logOut();
    return Promise.reject(error);
  }

  setTokens(response as AuthenticationTokens);

  const config = {
    ...error.config,
    headers: {
      ...error.config?.headers,
      Authorization: `Bearer ${(response as AuthenticationTokens).access}`,
    },
  };

  return axios(config)
    .then((retrySuccess) => retrySuccess)
    .catch((retryError) => {
      if (retryError.response?.status === 401) {
        logOut();
      }

      return Promise.reject(retryError);
    });
}

export default unauthorizedInterceptor;
