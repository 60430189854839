import { Form } from 'antd';

import { snackbar } from 'src/mui';

import {
  errorsToFormField,
  isStructuredFormError,
} from 'src/libs/finbits/Form';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCompany } from 'src/libs/finbits/Organization/Companies/Companies';
import { useUpdateComplementaryData } from 'src/libs/finbits/NotaFiscal/Setup';
import type { UpdateComplementaryDataFormParams } from 'src/libs/finbits/NotaFiscal/Setup/types';

import ComplementaryDataStep from 'src/features/nota-fiscal/Steps/ComplementaryDataStep';

export default function ComplementaryData() {
  const [form] = Form.useForm();

  const { companyId, organizationId } = useCompanyParams();
  const { company } = useCompany({ companyId, organizationId });

  const { updateComplementaryData, isLoading } = useUpdateComplementaryData();

  function handleOnSubmit(values: UpdateComplementaryDataFormParams) {
    if (!companyId || !organizationId) return;

    updateComplementaryData(
      {
        companyId,
        organizationId,
        ...values,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Alterações salvas com sucesso.',
          });

          form.setFields([
            { name: 'municipalRegistration', touched: false },
            { name: 'specialTaxRuleId', touched: false },
            { name: 'simpleNational', touched: false },
          ]);
        },
        onError: ({ response }) => {
          const data = response?.data;

          if (isStructuredFormError(data)) {
            return form.setFields(errorsToFormField(data.errors));
          }

          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar as alterações.',
          });
        },
      }
    );
  }

  return (
    <ComplementaryDataStep
      form={form}
      showTitle={false}
      buttonText="Salvar"
      isButtonLoading={isLoading}
      initialValues={{
        simpleNational: company?.simpleNational,
        municipalRegistration: company?.municipalRegistration,
        specialTaxRuleId: company?.specialTaxRuleId,
        address: {
          city: company?.address?.city,
        },
      }}
      onSubmit={handleOnSubmit}
    />
  );
}
