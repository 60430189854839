import type { ReactNode } from 'react';

import { IconButton } from '@mui/material';
import type { ButtonProps } from '@mui/material';

import { Tooltip } from 'src/mui';

import type { Permissions } from 'src/libs/finbits/Permissions';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import styles from './ActionButton.module.scss';

type Props = {
  tooltip?: string;
  label: string;
  disabled?: boolean;
  icon: ReactNode;
  permissions: Permissions;
} & ButtonProps;

export default function ActionButton({
  tooltip,
  onClick,
  label,
  permissions,
  disabled = false,
  icon,
}: Props) {
  return (
    <WithAllowedPermission permissions={permissions}>
      <Tooltip title={tooltip || label} disableInteractive>
        <IconButton
          classes={{ root: styles.iconButton }}
          disabled={disabled}
          size="small"
          aria-label={label}
          onClick={onClick}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </WithAllowedPermission>
  );
}
