import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import type { DialogProps } from '@mui/material';
import {
  Box,
  Button,
  Card,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { CloseIcon, LinkExternalIcon, LockKey } from 'src/mui/_icons';
import { FormField, Input } from 'src/mui/_scss';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useConnectSantanderConsentments } from 'src/libs/finbits/Bank/SantanderConsentments/SantanderConsentments';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './SantanderAutoPayment.module.scss';
import { SantanderAutoPaymentResolver } from './validations';

type Props = DialogProps &
  PortalProps & {
    account: Account;
  };

export default function SantanderAutoPayment({
  onClose,
  onExit,
  open,
  account,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { connectSantanderConsentments, isLoading } =
    useConnectSantanderConsentments();
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);

  const form = useForm({
    resolver: zodResolver(SantanderAutoPaymentResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      clientId: '',
      clientSecret: '',
    },
  });

  function handleClose() {
    return onClose();
  }

  async function handleRegister() {
    const isValid = await form.trigger();

    if (isValid) {
      const { clientId, clientSecret } = form.getValues();

      connectSantanderConsentments(
        {
          organizationId,
          companyId,
          accountId: account.id,
          clientId,
          clientSecret,
          scope: 'payments',
        },
        {
          onSuccess: () => {
            onClose();
            snackbar({
              variant: 'success',
              message: `Sua conta da instituição ${account.name} foi conectada para atualização automática das transações.`,
              title: 'Conta bancária conectada com sucesso',
            });
          },

          onError: (error) => {
            if (error.response?.status === 422) {
              setIsInvalidCredentials(true);
              return;
            }

            onClose();
            snackbar({
              variant: 'error',
              message:
                'Infelizmente, a conexão com o Banco Santander falhou. Por favor, tente novamente ou fale com nosso suporte.',
              title: 'Não foi possível realizar a conexão',
            });
          },
        }
      );
    }
  }

  useEffect(() => {
    if (isLoading) {
      setIsInvalidCredentials(false);
    }
  }, [isLoading]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        variant="text-md"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fontWeight="600"
      >
        Banco Santander - Conexão para pagamentos automáticos
        <IconButton
          aria-label="Fechar modal de conexão para pagamentos automáticos"
          onClick={handleClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.content} dividers>
        {isInvalidCredentials && (
          <Alert severity="error" variant="standard" className={styles.alert}>
            <AlertTitle>A credencial informada está incorreta.</AlertTitle>
            Por favor, verifique o Client ID e o Client Secret obtidos no Portal
            do Santander e tente novamente.
          </Alert>
        )}

        <Box className={styles.container}>
          <Card variant="outlined" className={styles.box}>
            <Typography
              component="h3"
              variant="text-md"
              color="black"
              fontWeight="600"
            >
              <span className={styles.number}>1.</span>
              Acesse o Portal do Desenvolvedor Santander.
            </Typography>

            <Typography color="grey.600">
              Para fazer login, use os dados de acesso da sua conta PJ (usuário
              master ou secundário).
            </Typography>

            <Button
              variant="outlined"
              endIcon={<LinkExternalIcon />}
              href="https://developer.santander.com.br/login"
              className={styles.btn}
              target="_blank"
            >
              Acessar Santander
            </Button>
          </Card>

          <Card variant="outlined" className={styles.box}>
            <Typography
              component="h3"
              variant="text-md"
              fontWeight="500"
              color="black"
            >
              <span className={styles.number}>2.</span>
              Siga o passo a passo abaixo no portal do Santander:
            </Typography>

            <Typography color="grey.600">
              Você irá autorizar a conexão do banco com a FinBits.
            </Typography>

            <Typography color="grey.600">
              Ao final deste processo, copie o <strong>Client ID</strong> e o
              <strong>Client Secret</strong> que serão exibidos na tela.
            </Typography>

            <Button
              variant="outlined"
              endIcon={<LinkExternalIcon />}
              href="https://drive.google.com/file/d/1ZZJfYHC4Si6Kh8uk2NxKI9F2LseyMltv/view"
              className={styles.btn}
              target="_blank"
            >
              Ver passo a passo
            </Button>
          </Card>

          <Card variant="outlined" className={styles.box}>
            <Typography
              component="h3"
              variant="text-md"
              fontWeight="500"
              color="black"
            >
              <span className={styles.number}>3.</span>
              Informe o Client ID e o Client Secret obtidos e confirme:
            </Typography>

            <Box
              component="form"
              gap={8}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <FormProvider {...form}>
                <FormField name="clientId" label="Client ID">
                  {(field) => <Input {...field} />}
                </FormField>

                <FormField name="clientSecret" label="Client Secret">
                  {(field) => <Input {...field} />}
                </FormField>

                <Button
                  onClick={handleRegister}
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  className={styles.button}
                >
                  Confirmar conexão
                </Button>
              </FormProvider>
            </Box>
          </Card>
        </Box>

        <Box
          display="flex"
          gap={3}
          alignItems="center"
          marginTop={8}
          marginBottom={2}
        >
          <LockKey color="primary" />

          <Typography color="grey.600">
            <strong>Conexão segura,</strong> desenvolvida em parceria com o
            Banco Santander.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
