import { Collapse } from '@mui/material';
import { Form, Input } from 'antd';

import { FormItem } from 'src/ui';

import type { PaymentDetails } from 'src/libs/finbits/Payments/types';

import CopyFieldButton from 'src/features/payments/CopyFieldButton';

import PixQrCodeAlert from '../PixQrCodeAlert';

type Props = {
  disabled?: boolean;
  fieldName?: string | string[];
  requiredFields?: Array<keyof PaymentDetails> | string[];
};

export default function PixQrCodeField({
  disabled = false,
  fieldName = 'pixKey',
  requiredFields = [],
}: Props) {
  const pixQrCode = Form.useWatch(fieldName);
  const amount = Form.useWatch('amount');

  return (
    <CopyFieldButton valueToCopy={pixQrCode ?? ''}>
      <FormItem
        hasMax={false}
        label="Código Pix"
        name={fieldName}
        required={requiredFields.includes('pixKey')}
      >
        <Input.TextArea
          size="large"
          placeholder="Cole o código Pix aqui..."
          autoSize={{ minRows: 2, maxRows: 12 }}
          disabled={disabled}
        />
      </FormItem>
      <Collapse in={Boolean(pixQrCode)} orientation="vertical">
        <PixQrCodeAlert qrCode={pixQrCode} amountToMatch={amount} />
      </Collapse>
    </CopyFieldButton>
  );
}
