import { useState } from 'react';

import { Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { Popover } from 'src/ui';

import { toCurrency } from 'src/libs/finbits/Money';
import { format } from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useFinancialStatementEntries } from 'src/libs/finbits/Management/FinancialStatements/Entries/Entries';

import useEntriesParams from 'src/features/entries/useEntriesParams';

import styles from './FinalBalance.module.less';

type Props = {
  filtersActive: boolean;
};

export default function FinalBalance({ filtersActive }: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { selectedAccountIds, selectedDateRange } = useEntriesParams();

  const { data } = useFinancialStatementEntries({
    organizationId,
    companyId,
    accountsIds: selectedAccountIds,
    startDate: format(selectedDateRange[0], 'yyyy-MM-dd'),
    endDate: format(selectedDateRange[1], 'yyyy-MM-dd'),
  });

  const formattedStartDate = format(selectedDateRange[0], 'dd/MM/yyyy');
  const formattedDate = format(selectedDateRange[1], 'dd/MM/yyyy');

  const initialBalance = data?.summary.initialBalance;
  const initialBalanceAmount = toCurrency(
    initialBalance?.amount ?? 0,
    initialBalance?.type
  );

  const projectedBalance = data?.summary.projectedBalance;
  const projectedBalanceAmount = toCurrency(
    projectedBalance?.amount ?? 0,
    projectedBalance?.type
  );

  const creditAmount = data?.summary.creditAmount ?? 0;
  const debitAmount = data?.summary.debitAmount ?? 0;
  const billAmount = data?.summary.billAmount ?? 0;
  const receivableAmount = data?.summary.receivableAmount ?? 0;

  const totalText = `Projeção para ${formattedDate}:`;

  const totalAmount = projectedBalanceAmount;

  const incomingOutcomingSum =
    creditAmount - debitAmount - billAmount + receivableAmount;

  const [
    isBalanceInconsistencyPopoverVisible,
    setIsBalanceInconsistencyPopoverVisible,
  ] = useState(false);

  return (
    <>
      <Popover
        overlayClassName={styles.popoverOverlay}
        placement="top"
        visible={isBalanceInconsistencyPopoverVisible}
        onVisibleChange={setIsBalanceInconsistencyPopoverVisible}
        content={
          <div className={styles.textWrapper}>
            <Typography.Paragraph className={styles.popoverText}>
              Saldo inicial {formattedStartDate}:
              <Typography.Text strong>{initialBalanceAmount}</Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph className={styles.popoverText}>
              {totalText}
              <Typography.Text strong>{totalAmount}</Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph className={styles.popoverText}>
              Soma de entradas e saídas:
              <Typography.Text strong>
                {toCurrency(incomingOutcomingSum)}
              </Typography.Text>
            </Typography.Paragraph>
          </div>
        }
      >
        <InfoCircleOutlined
          className={classNames({
            [styles.activeFilterIcon]: filtersActive,
          })}
        />
      </Popover>

      <Typography.Text type={filtersActive ? 'secondary' : undefined}>
        {totalText}
      </Typography.Text>
      <Typography.Text strong type={filtersActive ? 'secondary' : undefined}>
        {totalAmount}
      </Typography.Text>
    </>
  );
}
