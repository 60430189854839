import { useCallback } from 'react';

import { AlertTriangleIcon, DownloadCloudIcon } from 'src/mui/_icons';
import { Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import { useOpenConfirmDialog } from 'src/ui';

type Props = {
  onConfirm: () => void;
  attachmentName: string;
  sender?: string | null;
};

export function useOpenConfirmDownloadDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'warning',
        icon: <AlertTriangleIcon color="warning" />,
        title: props.attachmentName,
        content: (
          <Stack gap={2}>
            <Typography>
              Recomendamos que <b>só faça download caso conheça o remetente.</b>
            </Typography>
            <Typography>
              Enviado por <b>{props.sender}</b>.
            </Typography>
          </Stack>
        ),
        confirmText: 'Baixar',
        cancelText: 'Cancelar',
        confirmButtonProps: {
          startIcon: <DownloadCloudIcon />,
        },
        ...props,
      });
    },
    [openConfirmDialog]
  );
}
