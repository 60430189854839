import { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Label } from 'src/libs/finbits/Labels/types';
import type { Classification } from 'src/libs/finbits/Classifications/types';

import LabelModal from 'src/features/labels/LabelModal';

type Props = {
  label: Label;
  classification: Classification;
};

export default function EditLabelButton({ label, classification }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  function handleClose() {
    setIsModalVisible(false);
  }

  return (
    <>
      <WithAllowedPermission
        permissions={{
          resource: 'labels',
          action: 'update',
        }}
      >
        <Button
          icon={<EditOutlined />}
          onClick={() => setIsModalVisible(true)}
          aria-label="Editar etiqueta"
        />
      </WithAllowedPermission>

      {isModalVisible && (
        <LabelModal
          label={label}
          classification={classification}
          onClose={handleClose}
          title={`Editar ${label.name}`}
          okText={'Salvar'}
        />
      )}
    </>
  );
}
