import type { SxProps, Theme } from '@mui/material';

export const card: SxProps<Theme> = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '12px',
  boxShadow: (theme) => theme.shadows[2],
};
