import t from 'zod';

import { required } from 'src/libs/finbits/resolverValidations';
import { LabelDecoder } from 'src/libs/finbits/Labels/types';

export const FormParamsDecoder = t.object({
  name: required.max(50, 'Deve ter no máximo 50 caracteres'),
});

export type FormParams = t.TypeOf<typeof FormParamsDecoder>;

export const ClassificationDecoder = t.object({
  id: t.string(),
  name: t.string(),
  labels: LabelDecoder.array().optional(),
  active: t.boolean().optional(),
});

export type Classification = t.TypeOf<typeof ClassificationDecoder>;

export const EntryClassificationsDecoder = t.object({
  id: t.string(),
  labels: LabelDecoder.array(),
});

export type GetParams = {
  organizationId?: string;
  companyId?: string;
};

export type PostParams = {
  organizationId?: string;
  companyId?: string;
  name: string;
};

export type PatchParams = {
  organizationId?: string;
  companyId?: string;
  classificationId: string;
  name?: string;
};

export type ActiveParams = {
  organizationId?: string;
  companyId?: string;
  classificationId: string;
};
