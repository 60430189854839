import { useCallback } from 'react';

import cn from 'classnames';
import type { TextFieldProps } from '@mui/material';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  List,
  Stack,
} from '@mui/material';
import type { ControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { UploadCloudIcon } from 'src/mui/_icons';
import type { FileRejection } from 'react-dropzone';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { Typography } from 'src/design-system/components';

import { FocusIcon, snackbar } from 'src/mui';

import { useUploadFile } from 'src/libs/finbits/Management/Attachments';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import { AttachmentType } from 'src/libs/finbits/Management/Attachments/types';

import { PreviewTab } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider/types';
import { useBillPreviewContext } from 'src/features/bills-to-pay/BillContent/BillPreviewProvider';
import {
  MAX_SIZE,
  isDuplicatedFile,
} from 'src/features/attachments/UploadAttachments/Validators';
import { useTabPanelsContext } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider';

import UploadedAttachment from './UploadedAttachment';
import styles from './UploadField.module.scss';

type Props = {
  TextFieldProps?: TextFieldProps;
} & Omit<ControllerProps, 'render'>;

const ERROR_MESSAGE: Record<string, string> = {
  [ErrorCode.FileInvalidType]:
    'Arquivo inválido! 👉 Revise o formato: JPG, PNG, GIF, PDF, XLS, XML, OFX ou TXT.',
  [ErrorCode.FileTooLarge]: 'O tamanho do arquivo ultrapassa o limite de 20MB.',
};

export default function UploadField({ defaultValue = [], ...rest }: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { selectedPreviewTab, setSelectedPreviewTab } = useTabPanelsContext();

  const { currentAttachmentIndex, setCurrentAttachmentIndex } =
    useBillPreviewContext();

  const {
    field: { value: attachments, onChange },
  } = useController({
    defaultValue,
    ...rest,
  });

  const { uploadFile, isLoading } = useUploadFile();

  const onDrop = useCallback(
    ([acceptedFile]: File[]) => {
      if (!acceptedFile) return;

      uploadFile(
        {
          file: acceptedFile,
          companyId,
          organizationId,
        },
        {
          onSuccess: (attachment) => {
            onChange([...attachments, attachment]);
          },
        }
      );
    },
    [companyId, organizationId, attachments, onChange, uploadFile]
  );

  const onDropRejected = useCallback(([rejectedFile]: FileRejection[]) => {
    const errorCode = rejectedFile.errors[0].code;
    const message = rejectedFile.errors[0].message;

    snackbar({
      variant: 'error',
      message: ERROR_MESSAGE[errorCode] || message,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    maxSize: MAX_SIZE,
    disabled: isLoading,
    accept: {
      [AttachmentType.PNG]: ['.png'],
      [AttachmentType.JPEG]: ['.jpeg', '.jpg'],
      [AttachmentType.GIF]: ['.gif'],
      [AttachmentType.PDF]: [],
      [AttachmentType.XLSX]: ['.xml'],
      [AttachmentType.XLS]: ['.xls'],
      [AttachmentType.DOC]: ['.ofx', '.txt'],
      [AttachmentType.WEBP]: [],
    },
    onDrop,
    onDropRejected,
    validator: (file) => {
      if (isDuplicatedFile(file, attachments)) {
        return {
          code: 'custom',
          message:
            'Este arquivo já existe nos documentos anexos ao lançamento.',
        };
      }

      return null;
    },
  });

  function updateCurrentAttachmentIndex(attachments: Attachment[]) {
    if (attachments.length === 0 || attachments[currentAttachmentIndex - 1]) {
      return;
    }

    setCurrentAttachmentIndex(currentAttachmentIndex - 1);
  }

  function handleDeleteAttachment(attachment: Attachment) {
    if (!attachments) return;

    const updatedAttachments = attachments.filter(
      (a: Attachment) => a.id !== attachment.id
    );

    onChange(updatedAttachments);
    updateCurrentAttachmentIndex(updatedAttachments);
  }

  function handleClickItem(attachment: Attachment) {
    const index = attachments.indexOf(attachment) + 1;

    if (selectedPreviewTab === PreviewTab.SUGGESTIONS) {
      setSelectedPreviewTab(PreviewTab.ATTACHMENTS);
    }

    setCurrentAttachmentIndex(index);
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Anexos</InputLabel>
      <Stack
        alignItems="center"
        className={cn(styles.upload, {
          [styles.dragger]: isDragActive,
        })}
        color="text.secondary"
        {...getRootProps()}
      >
        <FocusIcon size="md">
          {isLoading ? (
            <CircularProgress size={14} thickness={3} />
          ) : (
            <UploadCloudIcon fontSize="inherit" viewBox="0 0 22 22" />
          )}
        </FocusIcon>
        <Typography className={styles.typography} align="center">
          <Link
            className={cn({
              [styles.draggerLink]: isDragActive,
            })}
          >
            Selecione
          </Link>{' '}
          ou arraste um arquivo
        </Typography>

        <Typography align="center">
          JPG, PNG, GIF, PDF, XLS, XML, OFX ou TXT. (max. 20mb)
        </Typography>

        <input data-testid="file-upload" {...getInputProps()} />
      </Stack>

      {attachments.length ? (
        <List>
          {attachments.map((attachment: Attachment) => (
            <UploadedAttachment
              key={attachment.id}
              attachment={attachment}
              onDeleteAttachment={handleDeleteAttachment}
              onClickItem={handleClickItem}
            />
          ))}
        </List>
      ) : null}
    </FormControl>
  );
}
