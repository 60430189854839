import { MenuItem, Skeleton, Stack } from '@mui/material';
import { ChevronDownIcon } from 'src/mui/_icons';
import { Rotate } from 'src/mui/_transitions';
import { Typography } from 'src/design-system/components';

import { Badge, Dropdown } from 'src/mui';

import { inboxItemStatusDictionary } from 'src/libs/finbits/Management/NewInboxItems/translations';
import type {
  InboxItemStatus,
  NewInboxItem,
} from 'src/libs/finbits/Management/NewInboxItems/types';

import { useInboxItemsParams } from 'src/features/inbox-items/useInboxItemsParams';

import { dropdown } from './InboxItemSelect.sx';

type Props = {
  inboxItems: NewInboxItem[];
  isLoading?: boolean;
};

export default function InboxItemSelect({
  inboxItems,
  isLoading = false,
}: Props) {
  const { status, setStatus } = useInboxItemsParams();

  const selectedStatus = inboxItemStatusDictionary[status];

  if (isLoading) {
    return <Skeleton variant="rectangular" width="140px" height="40px" />;
  }

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Dropdown
        sx={dropdown}
        aria-label={selectedStatus}
        PopoverProps={{
          sx: {
            mx: 2,
            mt: 1,
          },
        }}
        endIcon={({ open }) => (
          <Stack direction="row" gap={2}>
            <Rotate in={open}>
              <ChevronDownIcon />
            </Rotate>
            <Badge
              max={9999}
              color="secondary"
              badgeContent={inboxItems.length}
              showZero
            />
          </Stack>
        )}
        options={Object.entries(inboxItemStatusDictionary).map(
          ([status, label]) => (
            <MenuItem
              key={status}
              onClick={() => setStatus(status as InboxItemStatus)}
              aria-label={label}
            >
              {label}
            </MenuItem>
          )
        )}
      >
        <Typography fontWeight="700">{selectedStatus}</Typography>
      </Dropdown>
    </Stack>
  );
}
