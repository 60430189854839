import { Message } from 'src/mui/_scss';

import noApiToken from 'src/assets/no-api-token.svg';

export default function ApiTokenEmptyState() {
  return (
    <Message
      image={noApiToken}
      alt="Entrada não encontrada"
      title="Sem tokens da API pública"
      subtitle="Crie seu primeiro token para conceder acesso a API."
    />
  );
}
