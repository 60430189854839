import t from 'zod';
import type { RcFile } from 'rc-upload/lib/interface';

export const UploadDecoder = t.object({
  id: t.string(),
  name: t.string(),
});

export type Upload = t.TypeOf<typeof UploadDecoder>;

export type PostParams = {
  organizationId?: string;
  companyId?: string;
  file: RcFile;
};
