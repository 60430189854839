import { Menu } from 'antd';
import { FileTextOutlined, ScissorOutlined } from '@ant-design/icons';
import compact from 'lodash/compact';

import { DropdownActions } from 'src/ui';

import type { NotaFiscal } from 'src/libs/finbits/NotaFiscal/types';
import { useMenuItem } from 'src/libs/finbits/MenuItem/useMenuItem';

type Props = {
  notaFiscal: NotaFiscal;
  onUnlinkClick: () => void;
  isEntryLinked: boolean;
};

export default function Actions({
  notaFiscal,
  onUnlinkClick,
  isEntryLinked,
}: Props) {
  const items = [
    useMenuItem({
      menuItem: {
        key: 'pdfUrl',
        label: (
          <a
            href={notaFiscal?.pdfUrl ?? ''}
            target="_blank"
            rel="noreferrer"
            download
          >
            Baixar arquivo PDF
          </a>
        ),
        icon: <FileTextOutlined />,
        disabled: Boolean(!notaFiscal?.pdfUrl),
      },
    }),
    useMenuItem({
      menuItem: {
        key: 'xmlUrl',
        label: (
          <a
            href={notaFiscal?.xmlUrl ?? ''}
            target="_blank"
            rel="noreferrer"
            download
          >
            Baixar arquivo XML
          </a>
        ),
        icon: <FileTextOutlined />,
        disabled: Boolean(!notaFiscal?.xmlUrl),
      },
    }),
    useMenuItem({
      menuItem: {
        'aria-label': 'Desvincular lançamento',
        key: 'unlinkEntry',
        label: 'Desvincular',
        icon: <ScissorOutlined />,
        onClick: onUnlinkClick,
      },
      permissions: {
        action: 'unlink_entries',
        resource: 'notasFiscais',
        scope: 'company',
      },
      enabled: isEntryLinked,
    }),
  ];

  return <DropdownActions overlay={<Menu items={compact(items)} />} />;
}
