import { Button, Row, Tooltip, Typography, Upload } from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import type { DraggerProps } from 'antd/lib/upload';
import type { RcFile } from 'rc-upload/lib/interface';
import UploadDragger from 'src/ui/UploadDragger';

import { format } from 'src/libs/finbits/Date';

import styles from './UploadDigitalCertificate.module.less';

type Props = {
  errorMessage?: string;
  isAttached: boolean;
  certificateExpiration?: string | null;
  onDetachFile: () => void;
  onAttachFile: (file: string | Blob | RcFile) => void;
  isDisabled?: boolean;
};

export default function UploadDigitalCertificate({
  isAttached,
  errorMessage,
  certificateExpiration,
  onDetachFile,
  onAttachFile,
  isDisabled = false,
}: Props) {
  const draggerProps: DraggerProps = {
    multiple: false,
    accept: '.p12,.pfx',
    showUploadList: false,
    customRequest: ({ file }) => {
      onAttachFile(file);
    },
  };

  if (isAttached || certificateExpiration) {
    return (
      <div className={styles.fileUploadedWrapper}>
        <div className={styles.fileUploaded}>
          <Row align="middle">
            <div
              className={cn(styles.fileUploadedIcon, {
                [styles.fileUploadedIconError]: !!errorMessage,
              })}
            >
              {errorMessage ? <ExclamationCircleOutlined /> : <CheckOutlined />}
            </div>
            {errorMessage ? (
              <Typography.Text>{capitalize(errorMessage)}</Typography.Text>
            ) : (
              <Typography.Text>
                {isAttached && 'Arquivo pronto para envio'}
                {certificateExpiration &&
                  !isAttached &&
                  `Certificado ativo até ${format(
                    certificateExpiration,
                    'dd/MM/yyyy'
                  )}`}
              </Typography.Text>
            )}
          </Row>
          {certificateExpiration ? (
            <Upload
              data-testid="file-upload"
              {...draggerProps}
              disabled={isDisabled}
            >
              <Tooltip
                title={
                  isDisabled
                    ? 'Indisponível no momento.'
                    : 'Enviar novo arquivo'
                }
              >
                <Button
                  type="text"
                  aria-label="reenviar arquivo"
                  htmlType="button"
                  icon={<UploadOutlined />}
                  disabled={isDisabled}
                />
              </Tooltip>
            </Upload>
          ) : (
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={onDetachFile}
              aria-label="detach file"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <UploadDragger {...draggerProps} disabled={isDisabled}>
      <p>
        Clique ou arraste o arquivo do certificado
        <br /> digital para esta área (extensão .PFX ou .P12)
      </p>
      <Tooltip
        title={isDisabled ? 'Indisponível no momento.' : 'Enviar arquivo'}
      >
        <Button
          htmlType="button"
          size="large"
          type="primary"
          disabled={isDisabled}
        >
          Selecionar arquivo
        </Button>
      </Tooltip>
    </UploadDragger>
  );
}
