import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function LoadingIcon({
  viewBox = '-20 0 100 100',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="loading"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <circle fill="currentColor" stroke="none" cx="6" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 15 ; 0 -15; 0 15"
          repeatCount="indefinite"
          begin="0"
        />
      </circle>
      <circle fill="currentColor" stroke="none" cx="30" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 10 ; 0 -10; 0 10"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="currentColor" stroke="none" cx="54" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 5 ; 0 -5; 0 5"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
    </SvgIcon>
  );
}
