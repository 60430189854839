import type { KeyboardEvent, ReactNode, SyntheticEvent } from 'react';
import { useEffect, useRef } from 'react';

import {
  CloseIcon,
  DownloadCloudIcon,
  RotateLeftIcon,
  RotateRightIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from 'src/mui/_icons';
import cn from 'classnames';
import { Fade, IconButton } from '@mui/material';

import type { PortalProps } from 'src/ui';

import { useDragging } from 'src/features/preview/PreviewBase/useDragging';

import styles from './PreviewBase.module.scss';

type Props = {
  children?: ReactNode;
  footer?: ReactNode;
  handleRotateLeft: () => void;
  handleRotateRight: () => void;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  onDownload?: (event: SyntheticEvent) => void;
  renderCloseButton?: boolean;
  renderWithDrawer?: boolean;
} & PortalProps;

export default function PreviewBase({
  children,
  footer,
  onClose,
  onDownload,
  renderCloseButton = true,
  renderWithDrawer = false,
  handleRotateLeft,
  handleRotateRight,
  handleZoomIn,
  handleZoomOut,
  onExit,
  open = true,
}: Props) {
  const { coordinates, handleMove, isDragging, setIsDragging } = useDragging();

  function handleEscape(e: KeyboardEvent<HTMLDivElement>) {
    if (e.code === 'Escape') {
      e.stopPropagation();
      onClose();
    }
  }

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  useEffect(() => {
    document.body.style.setProperty('overflow', 'hidden');

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Fade in={open} onTransitionEnd={onExit}>
      <div
        ref={ref}
        onKeyDown={handleEscape}
        tabIndex={0}
        className={cn(styles.preview, {
          [styles.renderWithDrawer]: renderWithDrawer,
        })}
      >
        <div
          className={cn(styles.toolbar, {
            [styles.toolbarCentered]: renderWithDrawer,
          })}
        >
          <IconButton
            onClick={handleRotateLeft}
            aria-label="Rotacionar para esquerda"
          >
            <RotateLeftIcon className={styles.icon} />
          </IconButton>

          <IconButton
            onClick={handleRotateRight}
            aria-label="Rotacionar para direita"
          >
            <RotateRightIcon className={styles.icon} />
          </IconButton>

          <IconButton onClick={handleZoomOut} aria-label="Remover zoom">
            <ZoomOutIcon className={styles.icon} />
          </IconButton>

          <IconButton onClick={handleZoomIn} aria-label="Aplicar zoom">
            <ZoomInIcon className={styles.icon} />
          </IconButton>

          {onDownload && (
            <IconButton onClick={onDownload} aria-label="Download">
              <DownloadCloudIcon className={styles.icon} />
            </IconButton>
          )}

          {renderCloseButton && (
            <IconButton
              onClick={onClose}
              aria-label="Fechar"
              className={styles.closeIcon}
            >
              <CloseIcon className={styles.icon} />
            </IconButton>
          )}
        </div>

        <div onClick={onClose} className={styles.content}>
          <div
            style={{
              cursor: isDragging ? 'grabbing' : 'grab',
              transform: `translate3d(${coordinates?.x}px, ${coordinates?.y}px, 0px)`,
            }}
            onMouseMove={handleMove}
            onMouseDown={() => setIsDragging(true)}
            onMouseUp={() => setIsDragging(false)}
            onClick={(e) => e.stopPropagation()}
            draggable="false"
          >
            {children}
          </div>

          <div className={styles.footer} onClick={(e) => e.stopPropagation()}>
            {footer}
          </div>
        </div>
      </div>
    </Fade>
  );
}
