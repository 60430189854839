import type { ReactNode } from 'react';

import type { SnackbarOrigin } from 'notistack';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { Box, IconButton, Stack } from '@mui/material';
import { CloseIcon } from 'src/mui/_icons';

import { FocusIcon } from 'src/mui';

import { globalIconVariant } from './snackbarGlobalStyles';
import { actions, description, iconButton, snackbarTitle } from './snackbar.sx';

const snackbarAnchorOrigin: SnackbarOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
};

type BaseVariant = 'success' | 'info' | 'warning' | 'error';

type SnackbarType = {
  title?: string | ReactNode;
  message?: string | ReactNode;
  key?: string;
  variant: BaseVariant;
  persist?: boolean;
  preventDuplicate?: boolean;
  customActions?: ReactNode;
  anchorOrigin?: SnackbarOrigin;
  autoHideDuration?: number;
  alignItems?: string;
};

export default function snackbar({
  title,
  message,
  key,
  variant,
  persist = false,
  preventDuplicate = true,
  customActions,
  anchorOrigin = snackbarAnchorOrigin,
  autoHideDuration,
  alignItems = 'center',
}: SnackbarType) {
  const color = variant === 'info' ? 'primary' : variant;

  enqueueSnackbar({
    key,
    variant,
    hideIconVariant: true,
    persist,
    preventDuplicate,
    anchorOrigin,
    autoHideDuration,
    message: (
      <Stack direction="row" alignItems={alignItems}>
        <FocusIcon size="md" color={color} sx={{ mr: 4 }}>
          {globalIconVariant[variant]}
        </FocusIcon>

        <Box sx={{ width: '300px', pt: alignItems === 'start' ? '7px' : 0 }}>
          <Box component="strong" sx={snackbarTitle}>
            {title}
          </Box>
          <Box
            component="p"
            sx={{ ...description, mt: alignItems === 'start' ? 1 : 0 }}
          >
            {message}
          </Box>

          <Box sx={actions}>{customActions}</Box>
        </Box>

        <IconButton
          aria-label="Fechar notificação"
          onClick={() => closeSnackbar(key)}
          sx={{
            ...iconButton,
            ...(alignItems === 'start'
              ? {
                  top: '4px',
                }
              : {
                  top: '50%',
                  transform: 'translateY(-50%)',
                }),
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
    ),
  });
}
