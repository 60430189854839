import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function ChevronRightIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="chevron-right"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M9 18L15 12L9 6"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
