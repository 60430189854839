import { Button } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';

import analytics from 'src/libs/analytics';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './ActionButtons.module.less';

type Props = {
  show?: boolean;
  loading: boolean;
  disabled: boolean;
  onInboxClick: () => void;
};

function ActionButtons({
  show = true,
  disabled,
  loading,
  onInboxClick,
}: Props) {
  const { companyId } = useCompanyParams();

  if (!show) {
    return null;
  }

  function handleInboxClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();

    analytics.track('Drawer Inbox Attachments Button Clicked', {
      company_id: companyId,
    });

    onInboxClick();
  }

  return (
    <div className={styles.buttons}>
      <Button
        size="large"
        icon={<UploadOutlined />}
        loading={loading}
        disabled={disabled}
      >
        Novo anexo
      </Button>
      <Button
        size="large"
        icon={<InboxOutlined />}
        onClick={handleInboxClick}
        disabled={disabled}
      >
        Caixa de Entrada
      </Button>
    </div>
  );
}

export default ActionButtons;
