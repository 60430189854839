import { useMemo } from 'react';

import { Loader } from 'src/ui';

import { useClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';

import EntriesEmptyState from 'src/features/entries/EntriesEmptyState';
import EntriesStatement from 'src/features/entries/EntriesStatement';

export default function Entries() {
  const { organizationId, companyId } = useCompanyParams();
  const { accounts, isLoading: isLoadingAccounts } = useAccounts({
    organizationId,
    companyId,
  });

  const { classifications, isLoading: isLoadingClassifications } =
    useClassifications({ companyId, organizationId });

  const isLoadingPage = useMemo(
    () => isLoadingAccounts || isLoadingClassifications,
    [isLoadingAccounts, isLoadingClassifications]
  );

  if (isLoadingPage) {
    return <Loader />;
  }

  if (accounts.length === 0) {
    return <EntriesEmptyState />;
  }

  return <EntriesStatement classifications={classifications} />;
}
