import { useNavigate } from 'react-router';
import type { UseFormSetError } from 'react-hook-form';
import isArray from 'lodash/isArray';
import { Button } from '@mui/material';

import { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';

import { snackbar, snackbarAction } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Modal } from 'src/ui';

import type {
  Company,
  CompanyCreateParams,
} from 'src/libs/finbits/Organization/Companies';
import { useCreateCompany } from 'src/libs/finbits/Organization/Companies';
import {
  errorsToFormField,
  isStructuredFormError,
} from 'src/libs/finbits/Form';
import { useProfileInitialCompanyParams } from 'src/libs/finbits/Organization/Users';
import type { CompanyInfo } from 'src/libs/finbits/Organization/types';

import CompanyForm from 'src/features/sign-up/CompanyForm/CompanyForm';

type Props = {
  onSuccess?: (company: Company) => void;
} & PortalProps;

export default function CreateCompanyModal({
  onClose,
  onSuccess,
  open = true,
  onExit,
}: Props) {
  const { organizationId, isLoading: isProfileLoading } =
    useProfileInitialCompanyParams();
  const { createCompany, isLoading } = useCreateCompany();
  const navigate = useNavigate();

  function onSubmit(
    params: CompanyCreateParams,
    setError: UseFormSetError<CompanyInfo>
  ) {
    if (!organizationId) return;

    createCompany(
      { organizationId, params },
      {
        onSuccess: (data) => {
          snackbar({
            variant: 'success',
            title: 'Empresa criada',
            message:
              'Configure o permissionamento dos usuários da empresa em “Usuários”',
            alignItems: 'start',
            customActions: (
              <Button
                size="small"
                color="secondary"
                sx={snackbarAction}
                onClick={() =>
                  navigate(
                    generateCompanyPath(RouteKey.USERS, {
                      companyId: data.id,
                      organizationId,
                    })
                  )
                }
              >
                Ir para Usuários
              </Button>
            ),
          });

          onSuccess && onSuccess(data);

          onClose();
        },
        onError: ({ response }) => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao adicionar a empresa!',
          });

          if (response?.data && isStructuredFormError(response?.data)) {
            const errors = errorsToFormField(response.data.errors);

            errors.map(({ name, errors }) => {
              const field = isArray(name) ? name.join('.') : name;
              const message = errors.join(', ');
              return setError(field as any, { message });
            });
          }
        },
      }
    );
  }

  return (
    <Modal
      title="Adicionar nova empresa"
      visible={open}
      afterClose={onExit}
      footer={null}
      onCancel={onClose}
    >
      <CompanyForm
        onSubmit={onSubmit}
        onCancel={onClose}
        isLoading={isLoading || isProfileLoading}
        submitLabel="Adicionar"
      />
    </Modal>
  );
}
