import { Tooltip } from 'antd';
import compact from 'lodash/compact';

import { Tag } from 'src/ui';

import type { NotaFiscal } from 'src/libs/finbits/NotaFiscal/types';
import { pluralize } from 'src/libs/finbits/Pluralize';

type Props = {
  notaFiscal: NotaFiscal;
};

export default function LinkedEntriesTooltip({ notaFiscal }: Props) {
  const receivablesLength = notaFiscal.receivables?.length ?? 0;
  const financialEntriesLength = notaFiscal.financialEntries?.length ?? 0;

  const tooltip = compact([
    receivablesLength > 0
      ? pluralize(
          receivablesLength,
          `${receivablesLength} programado`,
          `${receivablesLength} programados`
        )
      : null,
    financialEntriesLength > 0
      ? pluralize(
          financialEntriesLength,
          `${financialEntriesLength} realizado`,
          `${financialEntriesLength} realizados`
        )
      : null,
  ]);

  if (receivablesLength === 0 && financialEntriesLength === 0) {
    return null;
  }

  return (
    <Tooltip placement="top" title={`NF vinculada a ${tooltip.join(' e ')}`}>
      <Tag>Vinculado</Tag>
    </Tooltip>
  );
}
