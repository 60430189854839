import { useMemo, useState } from 'react';

import { Typography } from 'antd';
import { WalletOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import { Modal, Title } from 'src/ui';

import { useAccount, useUpdateAccount } from 'src/libs/finbits/Bank/Accounts';
import type { FieldError } from 'src/libs/finbits/Form';
import { errorsToFormField } from 'src/libs/finbits/Form';
import type { AccountFormParams } from 'src/libs/finbits/Bank/Accounts/types';
import { SynchronizeType } from 'src/libs/finbits/Bank/Accounts/types';
import { BTG_ROUTING_NUMBER } from 'src/libs/finbits/Bank';

import AccountForm from 'src/features/DEPRECATED_accounts/AccountForm';

import styles from './EditAccountModal.module.less';

type Props = {
  organizationId: string;
  companyId: string;
  visible: boolean;
  onClose: () => void;
  accountId: string;
};

function EditAccountModal({
  organizationId,
  companyId,
  visible,
  onClose,
  accountId,
}: Props) {
  const [errors, setErrors] = useState<FieldError[]>();

  const { account, isLoading } = useAccount({
    companyId,
    organizationId,
    accountId,
  });

  const { updateAccount } = useUpdateAccount();

  async function updateOrganizationAccount(params: AccountFormParams) {
    if (!account) {
      return;
    }

    updateAccount(
      {
        ...params,
        organizationId,
        companyId,
        accountId,
      },
      {
        onSuccess: () => {
          onClose();
          snackbar({
            variant: 'success',
            message: 'A conta foi editada com sucesso!',
          });
        },
        onError: ({ response }) => {
          if (response?.status === 422) {
            const formErrors = errorsToFormField(response.data.errors);

            return setErrors(formErrors);
          }

          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao editar a conta!',
          });
        },
      }
    );
  }

  const disabledFields = useMemo(() => {
    if (!account) return [];

    if (
      account.synchronizeType === SynchronizeType.AUTOMATIC &&
      account.routingNumber === BTG_ROUTING_NUMBER
    ) {
      return [
        'branchNumber' as 'branchNumber',
        'accountNumber' as 'accountNumber',
      ];
    }

    return [];
  }, [account]);

  return (
    <Modal footer={null} visible={visible} onCancel={onClose} destroyOnClose>
      <div className={styles.modalHeader}>
        <Title level={4} icon={<WalletOutlined />}>
          Editar conta
        </Title>
      </div>

      <Typography.Paragraph>
        Informe os números de agência e conta, e um nome para ajudar a
        descrevê-la no Finbits:
      </Typography.Paragraph>

      <AccountForm
        submitButtonTitle="Salvar"
        onSubmit={updateOrganizationAccount}
        initialValues={account}
        loading={isLoading}
        showBankSelect
        errors={errors}
        disabledFields={disabledFields}
      />
    </Modal>
  );
}

export default EditAccountModal;
