import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';

import { translateCurrentSituation } from 'src/libs/finbits/Management/Entries/currentSituationTranslations';
import { EntrySituation } from 'src/libs/finbits/Management/Entries/types';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';
import CurrentSituationChip from 'src/features/entries/CurrentSituationChip/CurrentSituationChip';

function buildSelectOptions() {
  return Object.values(EntrySituation).map((situation) => ({
    value: situation,
    label: translateCurrentSituation(situation),
  }));
}

export function currentSituationColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  const options = buildSelectOptions();

  return {
    headerName: 'Situação',
    field: 'currentSituation',
    type: 'singleSelect',
    renderCell: (props) => {
      return <CurrentSituationChip currentSituation={props.value} />;
    },
    sortComparator: (situationA, situationB) => {
      const translatedSituationA = translateCurrentSituation(situationA) ?? '';
      const translatedSituationB = translateCurrentSituation(situationB) ?? '';

      return translatedSituationA.localeCompare(translatedSituationB);
    },
    minWidth: 110,
    valueOptions: options,
    ...columnProps,
    editable: false,
  };
}
