import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogContent, Slide, TextField } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { FormProvider, useForm } from 'react-hook-form';
import { FormField } from 'src/mui/_scss';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { ApiError } from 'src/libs/finbits/client';
import type { FormParams } from 'src/libs/finbits/Support/types';
import { FormParamsDecoder } from 'src/libs/finbits/Support/types';
import { useImpersonate } from 'src/libs/finbits/Support';
import { impersonateAuth } from 'src/libs/finbits/Auth';

type Props = PortalProps;

export default function ImpersonateModal({
  onClose,
  onExit,
  open = true,
}: Props) {
  const form = useForm<FormParams>({
    defaultValues: { key: '' },
    resolver: zodResolver(FormParamsDecoder),
    reValidateMode: 'onChange',
  });

  const { handleSubmit } = form;

  const { impersonate, isLoading } = useImpersonate();
  const navigate = useNavigate();

  function onSubmit(params: FormParams) {
    impersonate(params, {
      onSuccess: ({ refresh, access, companyId, organizationId }) => {
        onClose();

        impersonateAuth({ access, refresh });

        const homePath = generateCompanyPath(RouteKey.INBOX_ITEMS, {
          companyId,
          organizationId,
        });

        navigate(homePath);

        snackbar({ variant: 'success', message: 'Organização impersonada' });
      },

      onError: ({ response }: ApiError) => {
        snackbar({
          variant: 'error',
          message: `Erro ao impersonar ${JSON.stringify(response?.data)}`,
        });
      },
    });
  }

  return (
    <Dialog
      title="Impersonar"
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
    >
      <Dialog.Header
        size="small"
        title={'Impersonar em organização'}
        onClose={onClose}
      />
      <FormProvider {...form}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <FormField
              control={form.control as any}
              name="key"
              label="Digite o UUID/CNPJ da empresa ou UUID da organização."
            >
              {(field) => <TextField {...field} />}
            </FormField>
          </DialogContent>

          <Dialog.Actions>
            <Button fullWidth size="large" variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              Impersonar
            </LoadingButton>
          </Dialog.Actions>
        </Box>
      </FormProvider>
    </Dialog>
  );
}
