import { useState } from 'react';

import { Button, Form, Input, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import compact from 'lodash/compact';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import {
  FormItem,
  Modal,
  Stepper,
  Title,
  TreeSelect,
  useStepper,
} from 'src/ui';

import type { Label } from 'src/libs/finbits/Labels/types';
import { errorsToFormField } from 'src/libs/finbits/Form';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCreateLabels, useUpdateLabel } from 'src/libs/finbits/Labels';
import type { Classification } from 'src/libs/finbits/Classifications/types';
import { useClassifications } from 'src/libs/finbits/Classifications';

import { selectFilter } from 'src/features/EntryForm/selectFilter';

import styles from './LabelModa.module.less';

export type FormParams = {
  name: string;
  parentLabelId?: string;
};

type Props = {
  classification?: Classification;
  label?: Label;
  okText?: string;
  onClose: () => void;
  onCreate?: (label: Label) => void;
  title?: string;
  open?: boolean;
} & PortalProps;

export default function LabelModal({
  classification: initialClassification,
  label,
  okText = 'Criar',
  onClose,
  onCreate,
  title = 'Criar etiqueta',
  open = true,
  onExit,
}: Props) {
  const hasInitialClassification = Boolean(initialClassification);
  const initialStep = hasInitialClassification ? 2 : 1;

  const store = useStepper(initialStep);
  const { goNextStep, goBackStep, currentStep } = store;

  const [form] = Form.useForm();

  const [classification, setClassification] = useState(initialClassification);

  const parentLabels = classification?.labels;

  const treeData = buildLabelOption(parentLabels, label);
  const { companyId, organizationId } = useCompanyParams();

  const { classifications, isLoading: isLoadingClassifications } =
    useClassifications(
      { organizationId, companyId },
      { enabled: !hasInitialClassification }
    );

  const { createLabel, isLoading: isCreatingLabel } = useCreateLabels();
  const { updateLabel, isLoading: isEditingLabel } = useUpdateLabel();

  const isLoading = isCreatingLabel || isEditingLabel;

  function handleSubmit(params: FormParams) {
    if (label) {
      return updateLabel(
        {
          companyId,
          organizationId,
          classificationId: label.classificationId,
          labelId: label.id,
          ...params,
          parentLabelId: params.parentLabelId ?? null,
        },
        {
          onSuccess: () => {
            onClose();
            snackbar({ variant: 'success', message: 'Etiqueta atualizada' });
          },
          onError: ({ response }) => {
            if (response?.status === 422) {
              const formErrors = errorsToFormField(response.data.errors);

              return form.setFields(formErrors);
            }

            snackbar({ variant: 'error', message: 'Erro ao editar etiqueta' });
          },
        }
      );
    }

    return createLabel(
      {
        companyId,
        organizationId,
        classificationId: classification?.id!,
        ...params,
      },
      {
        onSuccess: (label) => {
          snackbar({ variant: 'success', message: 'Etiqueta criada' });
          onCreate && onCreate(label);

          onClose();
        },
        onError: ({ response }) => {
          if (response?.status === 422) {
            const formErrors = errorsToFormField(response.data.errors);

            return form.setFields(formErrors);
          }

          snackbar({ variant: 'error', message: 'Erro ao criar etiqueta' });
        },
      }
    );
  }

  function handleClassificationFormSubmit(params: {
    classificationId: string;
  }) {
    const selectedClassification = classifications.find(
      (classification) => classification.id === params.classificationId
    );

    setClassification(selectedClassification);
    goNextStep();
  }

  return (
    <Modal
      visible={open}
      title={<Title ellipsis={{ tooltip: title }}>{title}</Title>}
      afterClose={onExit}
      onCancel={onClose}
      footer={
        currentStep === 1 ? (
          <>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="primary" onClick={form.submit}>
              Continuar
            </Button>
          </>
        ) : (
          <div className={styles.footer}>
            <div>
              <Button onClick={onClose}>Cancelar</Button>
              <Button type="primary" onClick={form.submit} loading={isLoading}>
                {okText}
              </Button>
            </div>

            {!hasInitialClassification && (
              <Button
                onClick={goBackStep}
                type="text"
                icon={<ArrowLeftOutlined />}
              >
                Voltar
              </Button>
            )}
          </div>
        )
      }
    >
      <Stepper store={store} showGoBack={false} showContinue={false}>
        <Stepper.Step>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleClassificationFormSubmit}
            requiredMark={false}
          >
            <FormItem name="classificationId" label="Classificação" required>
              <Select
                showSearch
                placeholder="Digite o nome da classificação"
                size="large"
                filterOption={selectFilter}
                loading={isLoadingClassifications}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                {classifications.map((classification: Classification) => (
                  <Select.Option
                    value={classification.id}
                    key={classification.id}
                  >
                    {classification.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Form>
        </Stepper.Step>
        <Stepper.Step>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark={false}
            initialValues={label}
          >
            <FormItem name="name" label="Nome da etiqueta" required max={50}>
              <Input size="large" placeholder="Digite aqui" />
            </FormItem>

            <FormItem name="parentLabelId" label="Etiqueta agrupada dentro de">
              <TreeSelect
                treeDefaultExpandAll
                placeholder="Selecione uma etiqueta"
                treeNodeFilterProp="name"
                treeData={treeData}
                fieldNames={{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }}
              />
            </FormItem>
          </Form>
        </Stepper.Step>
      </Stepper>
    </Modal>
  );
}

function isRelated(labelId: string, label?: Label): boolean {
  return (
    label?.children?.some(
      (child) => child.id === labelId || isRelated(labelId, child)
    ) || false
  );
}

function buildLabelOption(
  labels: Label[] = [],
  selectedLabel?: Label
): Label[] {
  const labelId = selectedLabel?.id ?? '';

  return compact(
    labels?.map((label) => {
      const deactivedLabel = !label.active;
      const selected = label.id === labelId;
      const related = isRelated(label.id, selectedLabel);

      return {
        ...label,
        children: buildLabelOption(label.children, selectedLabel),
        disabled: selected || deactivedLabel || related,
      };
    })
  );
}
