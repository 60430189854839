import { useMutation } from 'react-query';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { API, decodeResponse } from 'src/libs/finbits/client';
import { OrganizationDecoder } from 'src/libs/finbits/Organization/types';
import type {
  Organization,
  PostParams,
} from 'src/libs/finbits/Organization/types';
import { e164Format } from 'src/libs/finbits/Organization/PhoneNumber';

async function postOrganization(organizationInfo: PostParams) {
  const payload = {
    ...organizationInfo,
    user: {
      ...organizationInfo.user,
      phone: { number: e164Format(organizationInfo.user.phone.number) },
    },
  };

  const response = await API.post('/organizations', payload);

  return decodeResponse<Organization>(response, OrganizationDecoder);
}

export function useCreateOrganization() {
  const { mutate: createOrganization, ...rest } = useMutation<
    Organization,
    ApiError<ApiErrorForm>,
    PostParams
  >(postOrganization);
  return { createOrganization, ...rest };
}
