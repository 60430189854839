import { Col, Row, Typography } from 'antd';
import { TagIcon } from 'src/mui/_icons';

import { PageWrapper, Title } from 'src/ui';

import ClassificationsList from 'src/features/classifications/ClassificationList';
import CreateClassificationButton from 'src/features/classifications/CreateClassificationButton';

import styles from './Classifications.module.less';

export default function Classifications() {
  return (
    <PageWrapper>
      <Row gutter={[10, 32]} align="top" justify="space-between">
        <Col span={24}>
          <Title icon={<TagIcon />}>Etiquetas</Title>
        </Col>

        <Col>
          <Typography.Text className={styles.paragraph}>
            Aqui você cria e edita as etiquetas, dentro de suas classificações.
          </Typography.Text>
        </Col>
        <Col>
          <CreateClassificationButton />
        </Col>

        <Col span={24}>
          <ClassificationsList />
        </Col>
      </Row>
    </PageWrapper>
  );
}
