import type { QueryClient, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import throttle from 'lodash/throttle';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import type { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import {
  FinancialStatementEntriesDecoder,
  FinancialStatementEntriesSummaryDecoder,
} from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type {
  FinancialStatementEntries,
  FinancialStatementEntriesSummary,
  FinancialStatementEntry,
  GetDeletedParams,
  GetOverdueEntriesParams,
  GetParams,
  ScheduledStatementEntry,
} from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { differenceInDays, parseISO, startOfDay } from 'src/libs/finbits/Date';
import { invalidateConciliationSuggestionsQueries } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';
import { invalidateIgnoredRecordsQueries } from 'src/libs/finbits/Management/IgnoredRecords/IgnoredRecords';
import { invalidateCompaniesBalancesQueries } from 'src/libs/finbits/Organization/Companies/Balances';
import { invalidateAccountsQueries } from 'src/libs/finbits/Bank/Accounts';
import { invalidateFinancialMovementsQueries } from 'src/libs/finbits/Management/FinancialStatements/FinancialMovements';
import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

const CACHE_KEY = 'financialStatementEntries';

async function getFinancialStatementEntries({
  organizationId,
  companyId,
  ...params
}: GetParams) {
  //TODO: Remove the groupSplits param after the backend PR is done
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/financial_statements/entries`,
    { params: { ...params, groupSplits: false } }
  );

  return decodeResponse<FinancialStatementEntriesSummary>(
    response,
    FinancialStatementEntriesSummaryDecoder
  );
}

function setFinancialStatementQueryKey({
  companyId,
  organizationId,
  accountsIds,
  startDate,
  endDate,
}: GetParams) {
  return [
    CACHE_KEY,
    { companyId, organizationId, accountsIds, startDate, endDate },
  ];
}

export function useFinancialStatementEntries(params: GetParams) {
  const { data, ...rest } = useQuery<
    FinancialStatementEntriesSummary,
    ApiError
  >({
    queryKey: setFinancialStatementQueryKey(params),
    queryFn: () => getFinancialStatementEntries(params),
    enabled: params.accountsIds.length > 0,
  });

  return { data, ...rest };
}

export function isOverDue(date: string) {
  const today = startOfDay(Date.now());

  return differenceInDays(today, startOfDay(parseISO(date))) > 0;
}

export function canPerformAction(
  entry: FinancialStatementEntry | ScheduledStatementEntry | undefined,
  action: AvailableActions
) {
  if (!entry || !entry.availableActions) return false;

  return entry.availableActions?.includes(action);
}

type EntryTextParams = {
  type?: BalanceType;
  capitalize?: boolean;
  objectType: 'financial_entry' | 'scheduled_entry';
};

export function entryText({
  type,
  objectType,
  capitalize = true,
}: EntryTextParams) {
  const prefix: Record<BalanceType, string> = {
    [BalanceType.CREDIT]: 'Recebimento',
    [BalanceType.DEBIT]: 'Pagamento',
  };

  const text = type
    ? `${prefix[type]} ${
        objectType === 'scheduled_entry' ? 'programado' : 'realizado'
      }`
    : '';

  if (capitalize) {
    return text;
  } else {
    return text.toLowerCase();
  }
}

export function invalidateFinancialStatementEntriesQueries(
  queryClient: QueryClient
) {
  queryClient.invalidateQueries([CACHE_KEY]);
}

export function invalidateFinancialEntry(queryClient: QueryClient) {
  queryClient.invalidateQueries(['financial_entry']);
}

// TODO: Rola um efeito em cascata aqui, que todas vez que temos o update em lote
// as accounts são relodadas. Acho que isso é desncessário no cenário atual
function invalidateEntriesQueriesCall(queryClient: QueryClient) {
  invalidateAccountsQueries(queryClient);
  invalidateCompaniesBalancesQueries(queryClient);
  invalidateFinancialStatementEntriesQueries(queryClient);
  invalidateConciliationSuggestionsQueries(queryClient);
  invalidateIgnoredRecordsQueries(queryClient);
  invalidateFinancialMovementsQueries(queryClient);
  invalidateDeletedEntriesQueries(queryClient);
  invalidateOverdueEntriesQueries(queryClient);
}

export const invalidateEntriesQueries = throttle(
  invalidateEntriesQueriesCall,
  FIVE_SECONDS_IN_MS
);

async function indexDeletedEntries({
  organizationId,
  companyId,
  ...params
}: GetDeletedParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/financial_statements/entries/deleted`,
    { params }
  );

  return decodeResponse<FinancialStatementEntries>(
    response,
    FinancialStatementEntriesDecoder
  );
}

async function indexOverdueEntries({
  organizationId,
  companyId,
}: GetOverdueEntriesParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/financial_statements/entries/overdue`
  );

  return decodeResponse<FinancialStatementEntries>(
    response,
    FinancialStatementEntriesDecoder
  );
}

type QueryOptions = UseQueryOptions<FinancialStatementEntries, ApiError>;

export function useGetDeletedEntries(
  params: GetDeletedParams,
  { enabled, ...queryConfig }: QueryOptions = { enabled: true }
) {
  const { data, ...rest } = useQuery<FinancialStatementEntries, ApiError>({
    enabled: !!params.organizationId && !!params.companyId && enabled,
    queryKey: ['deletedFinancialStatementEntries', params],
    queryFn: () => indexDeletedEntries(params),
    ...queryConfig,
  });

  return { data, ...rest };
}

export function useGetOverdueEntries(
  params: GetOverdueEntriesParams,
  { enabled, ...queryConfig }: QueryOptions = { enabled: true }
) {
  const { data, ...rest } = useQuery<FinancialStatementEntries, ApiError>({
    enabled: !!params.companyId && enabled,
    queryKey: ['overdueFinancialStatementEntries', params],
    queryFn: () => indexOverdueEntries(params),
    ...queryConfig,
  });

  return { data, ...rest };
}

function invalidateDeletedEntriesQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['deletedFinancialStatementEntries']);
}

function invalidateOverdueEntriesQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['overdueFinancialStatementEntries']);
}
