import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useOpenPortal } from 'src/ui';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Classification } from 'src/libs/finbits/Classifications/types';

import EditClassificationModal from 'src/features/classifications/EditClassificationModal';

type Props = {
  classification: Classification;
};

export default function EditClassificationButton({ classification }: Props) {
  const openPortal = useOpenPortal();

  function openEditClassificationModal() {
    openPortal(EditClassificationModal, {
      initialValues: classification,
    });
  }

  return (
    <WithAllowedPermission
      permissions={{ resource: 'classifications', action: 'update' }}
    >
      <Button
        onClick={openEditClassificationModal}
        icon={<EditOutlined />}
        aria-label="Editar classificação"
      />
    </WithAllowedPermission>
  );
}
