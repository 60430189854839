import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { textColumn } from 'src/mui/_scss/Table';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

export function commentsColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return textColumn({
    columnProps: {
      headerName: 'Observação',
      field: 'comments',
      minWidth: 120,
      flex: 1,
      aggregable: false,
      editable: true,
      ...columnProps,
    },
  });
}
