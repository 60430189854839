import omit from 'lodash/omit';
import pick from 'lodash/pick';

import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

import type { FormValues } from 'src/features/EntryForm/types';
import { billToFormValues } from 'src/features/EntryForm/formValues';

export function buildOriginalValuesForConciliation(
  originValues: FormValues,
  linkedEntry: ScheduledStatementEntry,
  fieldsToOmitOnConciliation: Array<keyof FormValues>
) {
  const omittedScheduledEntry: Partial<BillPayable> = omit(
    linkedEntry,
    fieldsToOmitOnConciliation
  );

  const preserveOnConciliationValues = pick(
    originValues,
    fieldsToOmitOnConciliation
  );

  return {
    ...billToFormValues({
      ...omittedScheduledEntry,
      attachments: [
        ...(linkedEntry?.attachments ?? []),
        ...(originValues.attachments ?? []),
      ],
    }),
    ...preserveOnConciliationValues,
  };
}
