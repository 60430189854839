import type { MouseEvent } from 'react';
import { useMemo, useState } from 'react';

import md5 from 'md5';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuList,
} from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  ClipboardCheckIcon,
  FileCheckIcon,
  FileLockIcon,
  LogoutIcon,
  UserIcon,
} from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Dropdown } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { useProfile } from 'src/libs/finbits/Organization/Users';

import TermsOfUseModal from 'src/features/terms-of-use/TermsOfUseModal';
import SaaSContractModal from 'src/features/terms-of-use/SaasContractModal';
import PrivacyPolicyModal from 'src/features/terms-of-use/PrivacyPolicyModal';

export default function UserMenu() {
  const openPortal = useOpenPortal();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { user } = useProfile();

  const { companyId, organizationId } = useParams();

  const isVisibility = !!anchorEl;

  const hasCompany = Boolean(user?.companies?.length);

  const profileUrl = useMemo(() => {
    if (hasCompany) {
      return generateCompanyPath(RouteKey.PROFILE, {
        companyId,
        organizationId,
      });
    }

    return generatePath(`/organizations/:organizationId/${RouteKey.PROFILE}`, {
      organizationId: organizationId ?? '',
    });
  }, [organizationId, companyId, hasCompany]);

  function toggleVisibility(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl((state) => (state ? null : event.currentTarget));
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpenSaaSContactModal() {
    openPortal(SaaSContractModal, {
      defaultVisible: true,
    });
  }

  function handleOpenTermsOfUseModal() {
    openPortal(TermsOfUseModal, {
      defaultVisible: true,
    });
  }

  function handleOpenPrivacyPolicyModal() {
    openPortal(PrivacyPolicyModal, {
      defaultVisible: true,
    });
  }

  const avatarUrl = useMemo(() => {
    if (!user?.email) return 'wrong-url';

    const hash = md5(user.email);

    return `https://www.gravatar.com/avatar/${hash}?default=404`;
  }, [user]);

  return (
    <>
      <IconButton sx={{ p: 0 }} onClick={toggleVisibility} aria-label="Perfil">
        <Avatar alt={user?.name ?? ''} src={avatarUrl} />
      </IconButton>

      <Menu
        open={isVisibility}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        sx={{ marginTop: 2 }}
        PaperProps={{
          sx: { px: 0 },
        }}
      >
        <Box sx={{ px: 4, py: 3 }}>
          <Typography fontWeight={600}>{user?.name}</Typography>
          <Typography variant="text-xs">{user?.email}</Typography>
        </Box>
        <Divider />
        <MenuList
          sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 1 }}
        >
          <Dropdown.Option
            icon={<UserIcon />}
            onClick={() => navigate(profileUrl)}
          >
            Meu perfil
          </Dropdown.Option>

          <Divider sx={{ mx: -2 }} />

          <Dropdown.Option
            icon={<FileCheckIcon />}
            onClick={handleOpenSaaSContactModal}
          >
            Contrato SaaS
          </Dropdown.Option>
          <Dropdown.Option
            icon={<ClipboardCheckIcon />}
            onClick={handleOpenTermsOfUseModal}
          >
            Termos de uso
          </Dropdown.Option>
          <Dropdown.Option
            icon={<FileLockIcon />}
            onClick={handleOpenPrivacyPolicyModal}
          >
            Política de privacidade
          </Dropdown.Option>

          <Divider sx={{ mx: -2 }} />

          <Dropdown.Option
            icon={<LogoutIcon />}
            onClick={() => navigate('/logout')}
          >
            Sair
          </Dropdown.Option>
        </MenuList>
      </Menu>
    </>
  );
}
