import type { SnackbarOrigin } from 'notistack';
import { Button } from '@mui/material';
import type {
  GridApi,
  GridRowModel,
  GridUpdateAction,
} from '@mui/x-data-grid-premium';
import { TrashIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useDeleteManyEntries } from 'src/libs/finbits/Management/EntriesDeleteMany';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import { buildEntriesIdsToDelete, nonDeletableMessage } from './deleteRows';
import AmountTotal from './AmountTotal/AmountTotal';

type Props = {
  selecteds: GridRowModel<FinancialStatementEntry>[];
  apiRef: GridApi;
};

const snackbarAnchorOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'bottom',
};

export default function ToolbarSelectionActions({ selecteds, apiRef }: Props) {
  const openConfirmDialog = useOpenConfirmDialog();
  const { organizationId, companyId } = useCompanyParams();
  const { deleteMany } = useDeleteManyEntries();

  function deleteSelection() {
    deleteMany(
      { organizationId, companyId, ...buildEntriesIdsToDelete(selecteds) },
      {
        onSuccess: () => {
          const rowsToRemove: Array<{ id: string; _action: GridUpdateAction }> =
            selecteds.map((s) => ({
              id: s.id,
              _action: 'delete',
            }));

          apiRef.updateRows(rowsToRemove);

          snackbar({
            variant: 'success',
            anchorOrigin: snackbarAnchorOrigin,
            message: 'Os lançamentos foram deletados com sucesso',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            anchorOrigin: snackbarAnchorOrigin,
            message: 'Falha ao excluir lançamentos',
          });
        },
      }
    );
  }

  function handleDeleteSelection() {
    openConfirmDialog({
      variant: 'error',
      icon: <TrashIcon />,
      title: 'Tem certeza que deseja excluir?',
      confirmText: 'Sim, quero excluir',
      cancelText: 'Cancelar',
      onConfirm: deleteSelection,
      content: (
        <>
          As informações serão excluídas permanentemente. <br /> <br />
          {nonDeletableMessage(selecteds)}
        </>
      ),
    });
  }

  return (
    <>
      <WithAllowedPermission
        permissions={{
          resource: 'entries',
          action: 'delete_many',
        }}
      >
        <Button
          variant="text"
          startIcon={<TrashIcon />}
          onClick={handleDeleteSelection}
        >
          Excluir
        </Button>
      </WithAllowedPermission>

      <AmountTotal selecteds={selecteds} />
    </>
  );
}
