import type { ButtonProps } from 'antd';
import { Button } from 'antd';
import cn from 'classnames';

import styles from './BackButton.module.less';

export default function BackButton({
  children = 'Voltar',
  className,
  ...rest
}: ButtonProps) {
  return (
    <Button className={cn(styles.backButton, className)} size="large" {...rest}>
      {children}
    </Button>
  );
}
