import type { ReactNode } from 'react';

import { Box } from '@mui/system';
import { Typography } from 'src/design-system/components';
import { CheckSquareIcon, SquareIcon } from 'src/mui/_icons';

import styles from './AccountFeatureBadge.module.scss';

type Props = {
  children?: ReactNode;
  isAccountFeatureEnabled: boolean;
  title: string;
};

export default function AccountFeatureBadge({
  children,
  isAccountFeatureEnabled,
  title,
}: Props) {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      {isAccountFeatureEnabled ? (
        <CheckSquareIcon className={styles.icon} />
      ) : (
        <SquareIcon className={styles.icon} />
      )}

      <Typography variant="text-sm" color="black">
        {title}
      </Typography>
      {children}
    </Box>
  );
}
