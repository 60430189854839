import t from 'zod';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

const IgnoredRecordDecoder = t.object({
  accountId: t.string(),
  amount: t.number(),
  date: t.string(),
  description: t.string(),
  id: t.string(),
  type: t.nativeEnum(BalanceType),
});

export const IgnoredRecordsDecoder = t.array(IgnoredRecordDecoder);

export type IgnoredRecord = t.TypeOf<typeof IgnoredRecordDecoder>;

export type GetParams = {
  organizationId?: string;
  companyId?: string;
  accountsIds: string[];
  date?: string;
  startDate?: string;
  endDate?: string;
};

export type IgnoredRecordDeleteParams = {
  organizationId: string;
  companyId: string;
  ignoredRecordId: string;
};
