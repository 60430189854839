import { Typography } from 'antd';
import cn from 'classnames';

import { Title } from 'src/ui';

import styles from './StepInformation.module.less';

type Props = {
  title?: string;
  description: string;
  className?: string;
};

export default function StepInformation({
  title,
  description,
  className,
}: Props) {
  return (
    <div className={cn([styles.container, className])}>
      {title && <Title level={5}>{title}</Title>}
      <Typography.Paragraph>{description}</Typography.Paragraph>
    </div>
  );
}
