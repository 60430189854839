import Grid from '@mui/material/Unstable_Grid2';

import Components from './Components';

export default function MuiStyleGuide() {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid>
        <Components />
      </Grid>
    </Grid>
  );
}
