import type { ReactNode } from 'react';

import BackButton from './BackButton';
import SubmitButton from './SubmitButton';
import styles from './DrawerFooter.module.less';

type Props = {
  children: ReactNode;
};

export default function DrawerFooter({ children }: Props) {
  return <div className={styles.footer}>{children}</div>;
}

DrawerFooter.SubmitButton = SubmitButton;
DrawerFooter.BackButton = BackButton;
