import { Divider, MenuList } from '@mui/material';
import { ContextItem } from 'src/mui/_scss/Table/GridContextMenu';
import type { ContextMenuInfo } from 'src/mui/_scss/Table/types';

import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import { useRowActions } from './useRowActions';

type RowActionsProps = {
  info: ContextMenuInfo<FinancialStatementEntry>;
  onClose: () => void;
};

export default function ContextMenuRowActions({
  info,
  onClose,
}: RowActionsProps) {
  const { actionsForRow } = useRowActions(info.row);

  const actions = actionsForRow();

  return (
    <MenuList>
      {actions.map((action, index) => (
        <div key={action.name}>
          <ContextItem {...action} onClose={onClose} key={action.name} />
          {action.dividerAfter && actions[index + 1] !== undefined && (
            <Divider variant="fullWidth" />
          )}
        </div>
      ))}
    </MenuList>
  );
}
