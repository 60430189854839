import type { SyntheticEvent } from 'react';
import { useMemo, useState } from 'react';

import { Alert, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons/lib/icons';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Loader } from 'src/ui';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  useAttachment,
  useDownloadAttachment,
} from 'src/libs/finbits/Management/Attachments';
import { isImageType, isPDFType } from 'src/libs/finbits/File/File';

import PreviewBase, { useControls } from 'src/features/preview/PreviewBase';
import PreviewIMG from 'src/features/preview/PreviewIMG';
import PreviewPDF from 'src/features/preview/PreviewPDF';
import Pagination, { usePageControls } from 'src/features/preview/Pagination';

type Props = {
  attachments: Attachment[];
  renderWithDrawer?: boolean;
  renderCloseButton?: boolean;
  defaultActiveIndex?: number;
} & PortalProps;

export default function PreviewAttachment({
  attachments,
  renderWithDrawer = false,
  renderCloseButton = true,
  defaultActiveIndex = 0,
  onClose,
  onExit,
  open = true,
}: Props) {
  const {
    zoom,
    rotate,
    handleRotateLeft,
    handleRotateRight,
    handleZoomIn,
    handleZoomOut,
  } = useControls();

  const { companyId, organizationId } = useCompanyParams();

  const [attachmentIndex, setAttachmentIndex] = useState(defaultActiveIndex);

  const currentAttachment = useMemo(() => {
    return attachments[attachmentIndex];
  }, [attachmentIndex, attachments]);

  const attachmentNumber = attachmentIndex + 1;
  const attachmentsCount = attachments.length;

  const {
    attachment: fetchedAttachment,
    isLoading,
    isError,
    isFetchedAfterMount,
  } = useAttachment({
    companyId,
    organizationId,
    attachmentId: currentAttachment.id,
  });
  const { downloadAttachment, isDownloading } = useDownloadAttachment();

  const {
    handleNextPage,
    handlePreviousPage,
    numPages,
    onPDFLoadSuccess,
    pageNumber,
    resetPageNumber,
  } = usePageControls();

  const isPdf = isPDFType(fetchedAttachment);
  const isImage = isImageType(fetchedAttachment);

  function handlePreviousAttachment(event: SyntheticEvent) {
    event.stopPropagation();

    setAttachmentIndex((prevAttachmentIndex) => prevAttachmentIndex - 1);
    resetPageNumber();
  }

  function handleNextAttachment(event: SyntheticEvent) {
    event.stopPropagation();

    setAttachmentIndex((prevAttachmentIndex) => prevAttachmentIndex + 1);
    resetPageNumber();
  }

  function onDownload(event: SyntheticEvent) {
    if (!organizationId || !companyId || !currentAttachment) return;

    event.stopPropagation();

    downloadAttachment(
      {
        organizationId,
        companyId,
        attachmentId: currentAttachment.id,
      },
      {
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao fazer o download, tente novamente.',
          });
        },
      }
    );
  }

  return (
    <PreviewBase
      onClose={onClose}
      handleRotateLeft={handleRotateLeft}
      handleRotateRight={handleRotateRight}
      handleZoomIn={handleZoomIn}
      handleZoomOut={handleZoomOut}
      renderWithDrawer={renderWithDrawer}
      renderCloseButton={renderCloseButton}
      onDownload={onDownload}
      onExit={onExit}
      open={open}
      footer={
        fetchedAttachment && (
          <>
            {isPdf && (
              <Pagination
                canPreviousPage={pageNumber > 1}
                canNextPage={pageNumber !== numPages}
                onPrevious={handlePreviousPage}
                onNext={handleNextPage}
              >
                Página {pageNumber}/{numPages}
              </Pagination>
            )}

            {attachmentsCount && attachmentsCount > 1 && (
              <Pagination
                canPreviousPage={attachmentIndex > 0}
                canNextPage={attachmentNumber < attachmentsCount}
                onPrevious={handlePreviousAttachment}
                onNext={handleNextAttachment}
              >
                Documento {attachmentNumber}/{attachmentsCount}
              </Pagination>
            )}
          </>
        )
      }
    >
      {isLoading && <Loader />}
      {isError && (
        <Alert
          showIcon
          type="error"
          message="Falha ao carregar arquivo, tente novamente."
        />
      )}

      {fetchedAttachment && isFetchedAfterMount && (
        <>
          {isImage && (
            <PreviewIMG
              attachment={fetchedAttachment}
              zoom={zoom}
              rotate={rotate}
            />
          )}
          {isPdf && (
            <PreviewPDF
              attachment={fetchedAttachment}
              zoom={zoom}
              rotate={rotate}
              pageNumber={pageNumber}
              onDocumentLoadSuccess={onPDFLoadSuccess}
              onClose={onClose}
            />
          )}
          {!isImage && !isPdf && (
            <Alert
              showIcon
              type="warning"
              message="Preview não suportado"
              action={
                <Button
                  icon={<DownloadOutlined />}
                  loading={isDownloading}
                  onClick={onDownload}
                >
                  Baixar arquivo
                </Button>
              }
            />
          )}
        </>
      )}
    </PreviewBase>
  );
}
