import type { Color, Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiChip({ palette }: ThemeConfig): Components['MuiChip'] {
  const {
    50: infoBgColor,
    100: infoHoverColor,
    600: infoBorderColor,
    700: infoColor,
  } = palette.info as Color;
  const {
    50: errorBGColor,
    100: erroHoverCOlor,
    600: errorBorderColor,
    700: errorColor,
  } = palette.error as Color;
  const {
    50: warningBGColor,
    100: warningHoverColor,
    600: warningBorderColor,
    700: warningColor,
  } = palette.warning as Color;
  const {
    50: successBGColor,
    100: successHoverColor,
    600: successBorderColor,
    700: successColor,
  } = palette.success as Color;
  const {
    200: defaultBGColor,
    300: defaultHoverColor,
    400: defaultBorderColor,
    700: defaultColor,
  } = palette.grey as Color;
  const {
    200: primaryBGColor,
    500: primaryHoverColor,
    600: primaryBorderColor,
    700: primaryColor,
  } = palette.primary as Color;
  const { 700: filledPrimaryBgColor } = palette.primary as Color;
  const {
    500: secondaryBGColor,
    600: secondaryHoverColor,
    600: secondaryBorderColor,
    900: filledSecondaryBgColor,
  } = palette.grey as Color;

  return {
    styleOverrides: {
      colorPrimary: {
        color: primaryColor,
        backgroundColor: primaryBGColor,
        borderColor: primaryBorderColor,
      },
      filledPrimary: {
        color: palette.common?.white,
        backgroundColor: filledPrimaryBgColor,
      },
      colorSuccess: {
        color: successColor,
        backgroundColor: successBGColor,
        borderColor: successBorderColor,
      },
      colorError: {
        color: errorColor,
        backgroundColor: errorBGColor,
        borderColor: errorBorderColor,
      },
      colorWarning: {
        color: warningColor,
        backgroundColor: warningBGColor,
        borderColor: warningBorderColor,
      },
      colorInfo: {
        color: infoColor,
        backgroundColor: infoBgColor,
        borderColor: infoBorderColor,
      },
      colorSecondary: {
        color: palette.common?.white,
        backgroundColor: secondaryBGColor,
        borderColor: secondaryBorderColor,
      },
      outlinedSecondary: {
        color: palette.common?.black,
      },
      filledSecondary: {
        color: palette.common?.white,
        backgroundColor: filledSecondaryBgColor,
      },
      outlined: {
        backgroundColor: 'transparent',
      },
      root: {
        variants: [
          {
            props: { square: true },
            style: { borderRadius: '8px' },
          },
          {
            props: { square: true, size: 'xsmall' },
            style: { borderRadius: '4px' },
          },
          {
            props: { variant: 'standard' },
            style: {
              border: '1px solid',
            },
          },
          {
            props: { color: 'default' },
            style: {
              color: defaultColor,
              backgroundColor: defaultBGColor,
              borderColor: defaultBorderColor,
            },
          },
          {
            props: { size: 'large' },
            style: {
              height: 28,
            },
          },
          {
            props: { size: 'medium' },
            style: {
              height: 24,
            },
          },
          {
            props: { size: 'small' },
            style: {
              height: 22,
            },
          },
          {
            props: { size: 'xsmall' },
            style: {
              height: 18,
            },
          },
        ],
      },
      avatar: {
        variants: [
          {
            props: { size: 'large' },
            style: { height: 18, width: 18, fontSize: 10, marginLeft: '4px' },
          },
          {
            props: { size: 'medium' },
            style: {
              height: 16,
              width: 16,
              marginRight: '-4px',
              marginLeft: '4px',
              fontSize: 10,
            },
          },
          {
            props: { size: 'small' },
            style: {
              height: 14,
              width: 14,
              marginRight: '-2px',
              marginLeft: '4px',
              fontSize: 10,
            },
          },
          {
            props: { size: 'xsmall' },
            style: {
              height: 12,
              width: 12,
              marginRight: '0px',
              marginLeft: '4px',
              fontSize: '8px',
            },
          },
          {
            props: (props) =>
              props.label === undefined && props.size === 'large',
            style: {
              marginRight: 6,
            },
          },
          {
            props: (props) => props.label === undefined,
            style: {
              marginRight: 4,
            },
          },
        ],
      },
      deleteIcon: {
        color: 'inherit',
        ':hover': {
          color: 'inherit',
          filter: 'brightness(0.6)',
        },
        variants: [
          {
            props: { size: 'large' },
            style: { fontSize: '18px' },
          },
          {
            props: { size: 'medium' },
            style: { fontSize: '18px', marginLeft: '-4px', marginRight: '2px' },
          },
          {
            props: { size: 'xsmall' },
            style: { fontSize: '16px', marginLeft: 0, marginRight: '2px' },
          },
          {
            props: (props) => props.label === undefined,
            style: {
              marginLeft: 0,
            },
          },
        ],
      },
      icon: {
        variants: [
          {
            props: { size: 'large' },
            style: { marginRight: '-6px', marginLeft: '6px', fontSize: '16px' },
          },
          {
            props: { size: 'medium' },
            style: { fontSize: '16px', marginLeft: '4px', marginRight: '-4px' },
          },
          {
            props: { size: 'small' },
            style: { marginRight: '-4px', fontSize: '14px', marginLeft: '4px' },
          },
          {
            props: { size: 'xsmall' },
            style: { marginRight: '0px', fontSize: '1em' },
          },
          {
            props: (props) =>
              props.label === undefined && props.size === 'large',
            style: {
              marginRight: '6px',
            },
          },
          {
            props: (props) =>
              props.label === undefined && props.size !== 'large',
            style: {
              marginRight: '4px',
            },
          },
        ],
      },
      label: {
        minWidth: 'unset',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
        variants: [
          {
            props: { size: 'medium' },
            style: {
              paddingInline: 8,
            },
          },
          {
            props: { size: 'xsmall' },
            style: {
              fontSize: 11,
              fontWeight: 600,
              paddingInline: 4,
            },
          },
          {
            props: (props) => props.label === undefined,
            style: {
              padding: 0,
              paddingInline: 0,
              minWidth: 'unset',
            },
          },
        ],
      },
      clickable: {
        variants: [
          {
            props: { color: 'primary' },
            style: {
              ':hover': {
                backgroundColor: primaryHoverColor,
              },
            },
          },
          {
            props: { color: 'secondary' },
            style: {
              ':hover': {
                backgroundColor: secondaryHoverColor,
              },
            },
          },
          {
            props: { color: 'default' },
            style: {
              ':hover': {
                backgroundColor: defaultHoverColor,
              },
            },
          },
          {
            props: { color: 'success' },
            style: {
              ':hover': {
                backgroundColor: successHoverColor,
              },
            },
          },
          {
            props: { color: 'error' },
            style: {
              ':hover': {
                backgroundColor: erroHoverCOlor,
              },
            },
          },
          {
            props: { color: 'warning' },
            style: {
              ':hover': {
                backgroundColor: warningHoverColor,
              },
            },
          },
          {
            props: { color: 'info' },
            style: {
              ':hover': {
                backgroundColor: infoHoverColor,
              },
            },
          },
        ],
      },
    },
  };
}
