import Grid from '@mui/material/Unstable_Grid2';
import compact from 'lodash/compact';

import {
  boletoBancarioMask,
  boletoConsumoMask,
} from 'src/libs/finbits/Boletos/masks';
import {
  payeeDocumentTypeDictionary,
  payeeNameDictionary,
} from 'src/libs/finbits/Boletos/translations';
import type { Boleto } from 'src/libs/finbits/Boletos/types';
import { BoletoType } from 'src/libs/finbits/Boletos/types';
import { format } from 'src/libs/finbits/Date';
import { masker } from 'src/libs/finbits/Masker';
import { toCurrency } from 'src/libs/finbits/Money';
import { formatDocument, typeFromDocument } from 'src/libs/finbits/Documents';

import DetailsInfo from 'src/features/inbox-items/InboxItemDetails/DetailsInfo';

type Props = {
  boleto?: Boleto | null;
};

export default function BoletoDetails({ boleto }: Props) {
  if (!boleto) return null;

  const isConsumptionBoleto = boleto.type === BoletoType.CONSUMPTION;
  const mask = isConsumptionBoleto ? boletoConsumoMask : boletoBancarioMask;

  const payeeDocumentType = typeFromDocument(boleto.payeeDocument) ?? 'legal';

  return (
    <Grid sx={{ color: 'text.secondary' }} container columnGap={16} rowGap={6}>
      <DetailsInfo
        label="Valor"
        value={boleto.amount}
        noContentMessage="Sem valor"
        formatter={toCurrency}
      />

      <DetailsInfo
        label="Juros"
        value={boleto.interest}
        noContentMessage="Sem juros"
        formatter={toCurrency}
      />

      <DetailsInfo
        label="Multa"
        value={boleto.fine}
        noContentMessage="Sem multa"
        formatter={toCurrency}
      />

      <DetailsInfo
        label="Desconto"
        value={boleto.discount}
        noContentMessage="Sem desconto"
        formatter={toCurrency}
      />

      <DetailsInfo
        label="Vencimento"
        value={boleto.dueDate}
        noContentMessage="Sem data"
        formatter={(value) => format(value, 'dd/MM/yy')}
      />

      <DetailsInfo
        label={payeeDocumentTypeDictionary[payeeDocumentType]}
        value={boleto.payeeDocument}
        noContentMessage="Sem documento"
        formatter={(value) => formatDocument(payeeDocumentType, value)}
      />

      <DetailsInfo
        label={payeeNameDictionary[payeeDocumentType]}
        value={boleto.payeeName}
        noContentMessage="Sem razão social"
      />

      <DetailsInfo
        label="Nome Fantasia"
        value={boleto.payeeTradeName}
        noContentMessage="Sem nome fantasia"
      />

      <DetailsInfo
        label="Banco"
        value={compact([boleto.routingNumber, boleto.bankName]).join(' - ')}
        noContentMessage="Sem banco"
      />

      {isConsumptionBoleto && (
        <DetailsInfo
          label="Segmento"
          value={boleto.segment}
          noContentMessage="Sem segmento"
        />
      )}

      <DetailsInfo
        label="Linha digitável"
        value={boleto.digitableLine}
        formatter={(value) => masker(value, mask)}
      />
    </Grid>
  );
}
