import type { TextFieldProps } from '@mui/material';
import type { ControllerProps } from 'react-hook-form';
import { Controller, useWatch } from 'react-hook-form';

import { Autocomplete } from 'src/mui';

import type { SetupNotaFiscalParams } from 'src/libs/finbits/NotaFiscal/Setup/types';
import { useSpecifications } from 'src/libs/finbits/NotaFiscal/Specifications';

type Props = {
  TextFieldProps?: TextFieldProps;
} & Omit<ControllerProps, 'render'>;

export default function SpecialTaxRuleField({ TextFieldProps }: Props) {
  const { address } = useWatch<SetupNotaFiscalParams>();

  const cityCode = address?.city;

  const { specifications, isLoading } = useSpecifications({
    cityCode,
  });

  return (
    <Controller
      name="specialTaxRuleId"
      render={({ field, fieldState: { error } }) => {
        const specialTaxRule =
          specifications?.specialTaxRules.find(
            (rule) => rule.id === field.value
          ) ?? null;

        return (
          <Autocomplete.Select
            {...field}
            value={specialTaxRule}
            loading={isLoading}
            TextFieldProps={{
              label: 'Regime Especial de Tributação',
              placeholder: 'Selecione',
              InputLabelProps: { required: true },
              helperText: error?.message,
              error: !!error,
              ...TextFieldProps,
            }}
            options={specifications?.specialTaxRules ?? []}
            onChange={(_e, value) => field.onChange(value?.id)}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        );
      }}
    />
  );
}
