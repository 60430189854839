import t from 'zod';

import { REQUIRED_MESSAGE } from './required';

const DATE_ERROR_MAP: Record<string, string> = {
  invalid_date: 'Data inválida',
  required_error: REQUIRED_MESSAGE,
  invalid_type: REQUIRED_MESSAGE,
};

export const date = t.date({
  errorMap: ({ code, message = '' }) => ({
    message: DATE_ERROR_MAP[code] || message,
  }),
});
