import { List, Typography } from 'antd';

import { formatCNPJ } from 'src/libs/finbits/Documents';
import { toCurrency } from 'src/libs/finbits/Money';
import type { CompanyBalance } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './CompanyBalanceBox.module.less';

type Props = {
  companyBalance: CompanyBalance;
};

export default function CompanyBalanceBox({ companyBalance }: Props) {
  const document = formatCNPJ(companyBalance.company.document);
  const balanceAmount = toCurrency(
    companyBalance.balance ?? 0,
    companyBalance.balanceType
  );

  return (
    <List.Item className={styles.company}>
      <Typography.Text ellipsis={{ tooltip: companyBalance.company.tradeName }}>
        {companyBalance.company.tradeName}
      </Typography.Text>
      <Typography.Text className={styles.document}>{document}</Typography.Text>
      <Typography.Text className={styles.balanceAmount}>
        {balanceAmount}
      </Typography.Text>
    </List.Item>
  );
}
