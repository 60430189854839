import { Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Typography } from 'src/design-system/components';

export default function LoginLink() {
  return (
    <Box sx={{ mt: 8 }}>
      <Typography>
        Já tem uma conta?{' '}
        <Link component={RouterLink} to="/login">
          Entre aqui
        </Link>
      </Typography>
    </Box>
  );
}
