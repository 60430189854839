import { useState } from 'react';

import { Typography } from 'antd';

import { useAddAccount } from 'src/libs/finbits/Bank/Accounts';
import type { FieldError } from 'src/libs/finbits/Form';
import { errorsToFormField } from 'src/libs/finbits/Form';
import type {
  Account,
  AccountFormParams,
} from 'src/libs/finbits/Bank/Accounts/types';

import AccountForm from 'src/features/DEPRECATED_accounts/AccountForm';
import type { CustomValidators } from 'src/features/DEPRECATED_accounts/AccountForm/types';

import BankSelected from '../BankSelected';

type Props = {
  companyId?: string;
  organizationId?: string;
  routingNumber: string | null;
  initialValues?: Partial<Account>;
  submitButtonTitle: string;
  onSuccess: (newAccount: Account) => void;
  onError: () => void;
  onClickEditBank?: () => void;
  isLoading?: boolean;
  customValidators?: Partial<CustomValidators>;
};

function CreateAccountForm({
  companyId,
  organizationId,
  routingNumber,
  initialValues,
  customValidators,
  submitButtonTitle,
  onSuccess,
  onError,
  onClickEditBank,
  isLoading = false,
}: Props) {
  const [errors, setErrors] = useState<FieldError[]>();

  const { addAccount, isLoading: isCreating } = useAddAccount();

  async function createAccount(params: AccountFormParams) {
    addAccount(
      {
        ...params,
        companyId,
        organizationId,
        routingNumber,
      },
      {
        onSuccess: (newAccount: Account) => {
          onSuccess(newAccount);
        },
        onError: ({ response }) => {
          if (response?.status === 422) {
            const formErrors = errorsToFormField(response.data.errors);

            return setErrors(formErrors);
          }

          onError();
        },
      }
    );
  }

  return (
    <>
      <Typography.Paragraph>
        Informe os números de agência e conta, e um nome para ajudar a
        descrevê-la no Finbits:
      </Typography.Paragraph>
      <BankSelected
        routingNumber={routingNumber}
        onClickEdit={onClickEditBank}
      />
      <AccountForm
        initialValues={initialValues}
        customValidators={customValidators}
        submitButtonTitle={submitButtonTitle}
        onSubmit={createAccount}
        loading={isLoading || isCreating}
        errors={errors}
      />
    </>
  );
}

export default CreateAccountForm;
