import cn from 'classnames';
import type { InputNumberProps } from 'antd';
import { InputNumber } from 'antd';
import { DollarCircleOutlined } from '@ant-design/icons';

import { toDecimal } from 'src/libs/finbits/Money';

import styles from './AmountInput.module.less';

type Props = InputNumberProps & {
  allowNegative?: boolean;
  ariaLabel?: string;
  isIconVisible?: boolean;
  type?: 'default' | 'success' | 'warning' | 'danger';
};

function inputPositiveNumberParser(value?: string): string {
  return value ? value.replace(/\D/g, '') : '';
}

function inputNegativeNumberParser(value?: string): string {
  return value ? value.replace(/[^-\d]/g, '') : '';
}

function inputNumberFormatter(value?: string | number): string {
  return value ? toDecimal(parseInt(value.toString())) : '';
}

function AmountInput({
  allowNegative = false,
  ariaLabel = 'valor',
  disabled = false,
  isIconVisible = true,
  placeholder,
  type = 'default',
  ...rest
}: Props) {
  return (
    <>
      <InputNumber
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        aria-label={ariaLabel}
        size="large"
        parser={
          allowNegative ? inputNegativeNumberParser : inputPositiveNumberParser
        }
        min={allowNegative ? `${Number.MIN_SAFE_INTEGER}` : '0'}
        formatter={inputNumberFormatter}
        className={cn(styles.amountInput, {
          [styles.success]: type === 'success',
          [styles.warning]: type === 'warning',
          [styles.danger]: type === 'danger',
        })}
        {...rest}
      />
      {isIconVisible && (
        <DollarCircleOutlined
          className={cn([styles.grayIcon, styles.amountIcon])}
        />
      )}
    </>
  );
}

export default AmountInput;
