import { Col, Input, Row, Space, Typography } from 'antd';

import { FormItem } from 'src/ui';

export default function DescriptionField() {
  return (
    <Row gutter={[0, 32]}>
      <Space direction="vertical">
        <Typography.Text strong>Descrição do serviço</Typography.Text>
        <Typography.Text>
          Nesta área você pode adicionar uma descrição ou alguma informação
          importante para o contato (tomador). Este texto será incluído na nota
          fiscal.
        </Typography.Text>
      </Space>

      <Col span={24}>
        <FormItem required name="additionalInformation" max={1500}>
          <Input.TextArea
            size="large"
            aria-label="Descrição do serviço"
            placeholder="Preencha este campo com os dados de pagamento e outras informações inportantes ao contato."
          />
        </FormItem>
      </Col>
    </Row>
  );
}
