import { useState } from 'react';

import { snackbar } from 'src/mui';

import { OptedInSwitch } from 'src/ui';

import {
  useProfile,
  useUpdateOptedInDailyReports,
} from 'src/libs/finbits/Organization/Users';
import { companyRoleDictionary } from 'src/libs/finbits/Permissions';

export default function UserOptedInDailyReports() {
  const { user } = useProfile();

  const [checked, setChecked] = useState(user?.optedInDailyReports);

  const { updateOptedInDailyReports, isLoading } =
    useUpdateOptedInDailyReports();

  function onChange() {
    setChecked((prevChecked) => !prevChecked);

    updateOptedInDailyReports(
      { optedInDailyReports: !checked },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Alteração salva com sucesso.',
          });
        },
        onError: () => {
          setChecked(user?.optedInDailyReports);

          snackbar({
            variant: 'error',
            message: 'Ops! Falha ao salvar alteração.',
          });
        },
      }
    );
  }

  return (
    <OptedInSwitch
      checked={checked}
      loading={isLoading}
      onChange={onChange}
      aria-label="E-mail diário"
      title="Receber e-mail com as transações do dia"
      description={`Apenas para empresas em que você seja ${companyRoleDictionary.manager} ou ${companyRoleDictionary.operator}`}
    />
  );
}
