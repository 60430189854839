import type { ReactNode } from 'react';

import { Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import { Badge } from 'src/mui';

type Props = {
  title: ReactNode;
  length: number;
  description: ReactNode;
};

export default function BalanceInconsistencyHeader({
  title,
  length,
  description,
}: Props) {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" sx={{ gap: 2 }}>
        <Typography variant="text-lg" fontWeight="600">
          {title}
        </Typography>
        <Badge color="secondary" badgeContent={length} showZero />
      </Stack>
      <Typography color="text.secondary">{description}</Typography>
    </Stack>
  );
}
