import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from 'src/design-system/components';

import { CollapseText } from 'src/mui';

import { formatDocument } from 'src/libs/finbits/Documents';
import { toCurrency } from 'src/libs/finbits/Money';
import { format } from 'src/libs/finbits/Date';
import type {
  ReceivedNotaFiscal,
  ReceivedProductNotaFiscal,
} from 'src/libs/finbits/Management/NewInboxItems/types';
import { inboxItemIssuerTradeName } from 'src/libs/finbits/Management/InboxItems/translations';
import { contactTypeDictionary } from 'src/libs/finbits/Organization/Companies/Contacts/translations';

import DetailsInfo from 'src/features/inbox-items/InboxItemDetails/DetailsInfo';

type Props = {
  receivedNotaFiscal?: ReceivedNotaFiscal | ReceivedProductNotaFiscal | null;
};

export default function NotaFiscalDetails({ receivedNotaFiscal }: Props) {
  if (!receivedNotaFiscal) return null;

  const documentType = receivedNotaFiscal.issuerLegalDocumentType;

  return (
    <>
      <Grid
        sx={{ color: 'text.secondary' }}
        container
        columnGap={16}
        rowGap={6}
      >
        <DetailsInfo
          label="Valor"
          value={receivedNotaFiscal.amount}
          formatter={toCurrency}
        />

        {'relevantDate' in receivedNotaFiscal && (
          <DetailsInfo
            label="Competência"
            value={receivedNotaFiscal.relevantDate}
            noContentMessage="Sem data"
            formatter={(value) => format(value, 'MM/yy')}
          />
        )}

        <DetailsInfo
          label="Data de Emissão"
          value={receivedNotaFiscal.authorizationDate}
          noContentMessage="Sem data"
          formatter={(value) => format(value, 'dd/MM/yy')}
        />

        <DetailsInfo label="Número da Nota" value={receivedNotaFiscal.number} />

        <DetailsInfo
          label={
            documentType ? contactTypeDictionary[documentType] : 'Documento'
          }
          value={receivedNotaFiscal.issuerLegalDocument}
          formatter={(value) =>
            documentType ? formatDocument(documentType, value) : value
          }
        />

        <DetailsInfo
          label={
            documentType ? inboxItemIssuerTradeName[documentType] : 'Emissor'
          }
          value={receivedNotaFiscal.issuerTradeName}
        />
      </Grid>
      {'serviceDescription' in receivedNotaFiscal && (
        <>
          <Divider sx={{ my: 6 }} />

          <Typography fontWeight={600} color="text.primary" marginBottom={2}>
            Descrição do Serviço
          </Typography>

          <CollapseText
            text={
              receivedNotaFiscal.serviceDescription ??
              'Sem descrição do serviço'
            }
          />
        </>
      )}
    </>
  );
}
