import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import {
  invalidateProfileQueries,
  invalidateUsersQueries,
} from 'src/libs/finbits/Organization/Users';
import type { ErrorsObject } from 'src/libs/finbits/Form';

import type { Company, CreateParams, GetParams, PatchParams } from './types';
import { CompanyDecoder } from './types';
import { invalidateCompaniesBalancesQueries } from './Balances';

async function getCompany({ organizationId, companyId }: GetParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}`;

  const response = await authenticatedAPI.get(url);

  return decodeResponse<Company>(response, CompanyDecoder);
}

async function getCompanies({
  organizationId,
}: Pick<GetParams, 'organizationId'>) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies`
  );

  return decodeResponse<Company[]>(response, CompanyDecoder.array());
}

async function patchCompany({
  organizationId,
  companyId,
  params,
}: PatchParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}`;

  const response = await authenticatedAPI.patch(url, params);

  return decodeResponse<Company>(response, CompanyDecoder);
}

async function postCompany({ organizationId, params }: CreateParams) {
  const url = `/organizations/${organizationId}/companies`;

  const response = await authenticatedAPI.post(url, params);

  return decodeResponse<Company>(response, CompanyDecoder);
}

export function useCompany(
  { organizationId, companyId }: Partial<GetParams>,
  queryConfigCustom: Partial<UseQueryOptions<Company, ApiError>> = {}
) {
  const { data, ...rest } = useQuery<Company, ApiError>({
    queryKey: ['company', { organizationId, companyId }],
    queryFn: () =>
      getCompany({ organizationId: organizationId!, companyId: companyId! }),
    enabled: !!organizationId && !!companyId,
    ...queryConfigCustom,
  });

  return { company: data, ...rest };
}

export function useCompanies(
  { organizationId }: Partial<Pick<GetParams, 'organizationId'>>,
  queryConfigCustom: Partial<UseQueryOptions<Company[], ApiError>> = {}
) {
  const { data, ...rest } = useQuery<Company[], ApiError>({
    queryKey: ['companies', { organizationId }],
    queryFn: () => getCompanies({ organizationId: organizationId! }),
    enabled: !!organizationId,
    ...queryConfigCustom,
  });

  return { companies: withEmptyArrayDefault(data), ...rest };
}

export function useUpdateCompany() {
  const queryClient = useQueryClient();
  const { mutate: updateCompany, ...rest } = useMutation<
    Company,
    ApiError<ErrorsObject>,
    PatchParams
  >(patchCompany, {
    onSuccess: () => {
      invalidateCompanyQueries(queryClient);
      invalidateCompaniesBalancesQueries(queryClient);
    },
  });
  return { updateCompany, ...rest };
}

export function useCreateCompany() {
  const queryClient = useQueryClient();

  const { mutate: createCompany, ...rest } = useMutation<
    Company,
    ApiError<ErrorsObject>,
    CreateParams
  >(postCompany, {
    onSuccess: () => {
      invalidateCompanyQueries(queryClient);
      invalidateUsersQueries(queryClient);
      invalidateCompaniesBalancesQueries(queryClient);
    },
  });

  return { createCompany, ...rest };
}

export function invalidateCompanyQueries(queryClient: QueryClient) {
  invalidateProfileQueries(queryClient);
  queryClient.invalidateQueries(['company']);
  queryClient.invalidateQueries(['companies']);
}
