import type { SnackbarOrigin } from 'notistack';

import { snackbar } from 'src/mui';

const snackbarAnchorOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'bottom',
};

export function onProcessRowUpdateError() {
  snackbar({
    variant: 'error',
    anchorOrigin: snackbarAnchorOrigin,
    message: (
      <>
        Falha ao salvar alterações. Use <b>ESC</b> para cancelar a edição
      </>
    ),
  });
}

export function onProcessRowUpdateSuccess() {
  snackbar({
    variant: 'success',
    anchorOrigin: snackbarAnchorOrigin,
    message: 'Alterações salvas com sucesso',
  });
}
