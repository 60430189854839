import { useMemo } from 'react';

import { Typography } from 'antd';

import { getAmountType, toCurrency } from 'src/libs/finbits/Money';
import type { Balance } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './TotalAmount.module.less';

type Props = {
  balances: Omit<Balance, 'date'>[];
};

export default function TotalAmount({ balances }: Props) {
  const totalAmount = useMemo(() => {
    return balances.reduce((total, companyBalance) => {
      const balance = companyBalance.balance ?? 0;

      if (companyBalance.balanceType === BalanceType.DEBIT) {
        return total - balance;
      }

      return total + balance;
    }, 0);
  }, [balances]);

  const { amount, type } = getAmountType(totalAmount);
  const formattedTotalAmount = toCurrency(amount, type);

  return (
    <div className={styles.totalAmount}>
      <Typography.Text strong>Total</Typography.Text>
      <Typography.Text strong>{formattedTotalAmount}</Typography.Text>
    </div>
  );
}
