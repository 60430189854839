import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function DotIcon({ fontSize = 'small', ...rest }: SvgIconProps) {
  return (
    <SvgIcon aria-label="dot" fontSize={fontSize} viewBox="0 0 9 9" {...rest}>
      <circle cx="4.5" cy="4.5" r="4" fill="currentColor" />
    </SvgIcon>
  );
}
