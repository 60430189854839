import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { Button, Space } from 'antd';

import { snackbar } from 'src/mui';

import { Loader, Stepper, useStepper } from 'src/ui';

import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';

import { usePublicCompany } from 'src/libs/finbits/PublicCompanies/PublicCompanies';
import { useCreatePurchaseOrder } from 'src/libs/finbits/PurchaseOrder';
import { isStructuredFormError } from 'src/libs/finbits/Form';
import type { ApiErrorForm } from 'src/libs/finbits/client';
import type { PurchaseOrder as PurchaseOrderType } from 'src/libs/finbits/PurchaseOrder/types';

import PurchaseOrderContent from 'src/features/purchase-order/PurchaseOrderContent/PurchaseOrderContent';
import PurchaseOrderForm from 'src/features/purchase-order/PurchaseOrderForm';

import styles from './PurchaseOrder.module.less';

export default function PurchaseOrder() {
  const [errors, setErrors] = useState<ApiErrorForm>();
  const [senderEmail, setSenderEmail] = useState<string>();

  const { publicId } = useParams<{ publicId: string }>();
  const { publicCompany, isLoading, isError } = usePublicCompany({ publicId });

  const { createPurchaseOrder, isLoading: loadingCreatePurchaseOrder } =
    useCreatePurchaseOrder();
  const store = useStepper();

  function handleOnSubmit(values: PurchaseOrderType) {
    if (!publicCompany?.id) return;

    createPurchaseOrder(
      {
        ...values,
        companyId: publicCompany.id,
        files: values?.files?.fileList,
      },
      {
        onSuccess: () => {
          setSenderEmail(values.requesterEmail);
          store.goNextStep();
        },
        onError: ({ response }) => {
          snackbar({
            variant: 'error',
            message:
              'Não foi possível enviar a solicitação de pagamento. Por favor, tente novamente.',
          });

          if (isStructuredFormError(response?.data)) {
            setErrors(response?.data.errors);
          }
        },
      }
    );
  }

  if (isLoading) {
    return <Loader size="large" full />;
  }

  if (isError) {
    return <NotFoundPage />;
  }

  return (
    <div className={styles.pageContainer}>
      <Stepper store={store} showContinue={false} showGoBack={false}>
        <Stepper.Step>
          <div className={styles.background}>
            <div className={styles.container}>
              <PurchaseOrderContent
                title={publicCompany?.tradeName!}
                text="Use este formulário para uma solicitação de pagamento ou reembolso"
              />
            </div>
          </div>
          <div className={styles.content}>
            <PurchaseOrderForm
              categories={publicCompany?.categories}
              onSubmit={handleOnSubmit}
              loading={loadingCreatePurchaseOrder}
              errors={errors}
            />
          </div>
        </Stepper.Step>
        <Stepper.Step showContinue={false}>
          <div className={styles.background}>
            <div className={styles.container}>
              <Space direction="vertical" size={32}>
                <PurchaseOrderContent
                  title="Sua solicitação foi enviada com sucesso 🎉"
                  text={`Você receberá uma confirmação no email ${senderEmail}`}
                />
                <Button
                  type="primary"
                  size="large"
                  onClick={() => store.goBackStep()}
                >
                  Enviar outro
                </Button>
              </Space>
            </div>
          </div>
        </Stepper.Step>
      </Stepper>
    </div>
  );
}
