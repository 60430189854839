import type { ReactElement } from 'react';

import type { SnackbarOrigin } from 'notistack';
import {
  CheckDoneIcon,
  DividerIcon,
  FileLinesIcon,
  RefreshIcon,
  ShareIcon,
  TrashIcon,
} from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useDeleteBill } from 'src/libs/finbits/Bills';
import { useDeleteFinancialEntry } from 'src/libs/finbits/Management/FinancialEntries';
import { useDeleteReceivable } from 'src/libs/finbits/Receivables';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import {
  useOpenConfirmDeletionDialog,
  useOpenConfirmRestore,
} from 'src/features/entries/Dialogs/Dialogs';
import CreateNotaFiscalDrawer from 'src/features/nota-fiscal/CreateNotaFiscalDrawer';
import EditSplitsDrawer from 'src/features/entries/Drawers/FinancialEntry/EditSplitsDrawer';
import EditFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/EditFinancialEntryDrawer';
import { useRestoreEntry } from 'src/features/bills-to-pay/DeletedBillModal/useRestoreEntry';
import useOpenEntryEditDrawer from 'src/features/entries/hooks/useOpenEntryEditDrawer';

export type RowAction = {
  name: string;
  icon: ReactElement;
  label: string;
  onClick: () => void;
  dividerAfter?: boolean;
};

const snackbarAnchorOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'bottom',
};

function addIf(mustAdd: boolean, action: RowAction) {
  return mustAdd ? [action] : [];
}

export function useRowActions(row: FinancialStatementEntry) {
  const openPortal = useOpenPortal();
  const openEntryEditDrawer = useOpenEntryEditDrawer();

  const { organizationId, companyId } = useCompanyParams();

  const { deleteBill } = useDeleteBill();
  const { deleteFinancialEntry } = useDeleteFinancialEntry();
  const { deleteReceivable } = useDeleteReceivable();

  const { status: connectionStatus } = useNotaFiscalConnection();
  const openConfirmDeletion = useOpenConfirmDeletionDialog();

  const userCanDelete = useAllowedPermission({
    action: 'delete',
    resource: rowResource(),
  });
  const userCanSplit = useAllowedPermission({
    action: 'create',
    resource: 'financialEntrySplit',
  });
  const userCanCreateNF = useAllowedPermission({
    action: 'create',
    resource: 'notasFiscais',
  });
  const userCanConciliate = useAllowedPermission({
    action: 'conciliate',
    resource: rowResource(),
  });
  const userCanRestore = useAllowedPermission({
    action: 'restore_many',
    resource: 'entries',
  });

  function openDeleteEntryModal() {
    const callbacks = {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          anchorOrigin: snackbarAnchorOrigin,
          message: 'Lançamento excluído',
        });
      },
      OnError: () => {
        snackbar({
          variant: 'error',
          anchorOrigin: snackbarAnchorOrigin,
          message: 'Ocorreu um erro ao excluir lançamento',
        });
      },
    };

    openConfirmDeletion({
      onConfirm: () => {
        if (row.entryType === EntryType.FINANCIAL_ENTRY) {
          deleteFinancialEntry(
            {
              organizationId,
              companyId,
              financialEntryId: row.id,
            },
            callbacks
          );
        }

        if (row.entryType === EntryType.BILL) {
          deleteBill({ organizationId, companyId, billId: row.id }, callbacks);
        }

        if (row.entryType === EntryType.RECEIVABLE) {
          deleteReceivable(
            {
              organizationId,
              companyId,
              receivableId: row.id,
            },
            callbacks
          );
        }
      },
    });
  }

  function openCreateNFDrawer() {
    if (row.entryType === EntryType.FINANCIAL_ENTRY) {
      openPortal(CreateNotaFiscalDrawer, {
        initialValues: {
          financialEntryId: row.id,
          amount: row.amount,
          contactId: row.contactId || undefined,
        },
      });
    }

    if (row.entryType === EntryType.RECEIVABLE) {
      openPortal(CreateNotaFiscalDrawer, {
        initialValues: {
          receivableId: row.id,
          amount: row.amount,
          contactId: row.contactId || undefined,
        },
      });
    }
  }

  function openConciliationDrawer() {
    openPortal(EditFinancialEntryDrawer, {
      financialEntryId: row.id,
      isSplit: !!row.isSplit,
      isConciliation: true,
    });
  }

  function openCreateSplitDrawer() {
    openPortal(EditFinancialEntryDrawer, {
      financialEntryId: row.id,
      isSplit: !!row.isSplit,
      isSplitCreation: true,
    });
  }

  function openEditSplitDrawer() {
    if (!row.transactionId) return;

    openPortal(EditSplitsDrawer, {
      transactionId: row.transactionId,
      type: row.amountType,
    });
  }

  const { handleRestoreSelection } = useRestoreEntry({ selecteds: [row] });
  const confirmRestoreEntry = useOpenConfirmRestore();

  function openRestoreConfirmation() {
    confirmRestoreEntry({
      onConfirm: handleRestoreSelection,
    });
  }

  function rowResource() {
    if (row.entryType === EntryType.BILL) {
      return 'bills';
    }

    if (row.entryType === EntryType.RECEIVABLE) {
      return 'receivables';
    }

    return 'financialEntries';
  }

  function canDelete() {
    return userCanDelete && canPerformAction(row, AvailableActions.DELETE);
  }

  function canSplit() {
    return userCanSplit && canPerformAction(row, AvailableActions.SPLIT);
  }

  function canConciliate() {
    return (
      userCanConciliate && canPerformAction(row, AvailableActions.CONCILIATE)
    );
  }

  function canRestore() {
    return userCanRestore && canPerformAction(row, AvailableActions.RESTORE);
  }

  function canCreateNF() {
    return (
      connectionStatus === 'active' &&
      userCanCreateNF &&
      canPerformAction(row, AvailableActions.ISSUE_NF)
    );
  }

  function actionsForRow(): RowAction[] {
    return [
      {
        name: 'openEntry',
        icon: <ShareIcon />,
        label: 'Abrir lançamento',
        onClick: () => openEntryEditDrawer(row),
        dividerAfter: true,
      },
      ...addIf(canRestore(), {
        name: 'restoreEntry',
        icon: <RefreshIcon />,
        label: 'Restaurar',
        onClick: openRestoreConfirmation,
      }),

      ...addIf(canConciliate(), {
        name: 'conciliateEntry',
        icon: <CheckDoneIcon />,
        label: 'Conciliar lançamento',
        onClick: openConciliationDrawer,
      }),

      ...addIf(!!row.isSplit, {
        name: 'editSplitEntry',
        icon: <DividerIcon />,
        label: 'Editar quebra do lançamento',
        onClick: openEditSplitDrawer,
      }),

      ...addIf(canSplit(), {
        name: 'createSplitEntry',
        icon: <DividerIcon />,
        label: 'Quebrar lançamento',
        onClick: openCreateSplitDrawer,
      }),

      ...addIf(canCreateNF(), {
        name: 'createNF',
        icon: <FileLinesIcon />,
        label: 'Emitir Nota Fiscal',
        onClick: openCreateNFDrawer,
      }),

      ...addIf(canDelete(), {
        name: 'deleteEntry',
        icon: <TrashIcon />,
        label: 'Excluir',
        onClick: openDeleteEntryModal,
      }),
    ];
  }

  return { actionsForRow };
}
