import type { ErrorResponse } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  handleError,
  handleSuccess,
} from 'src/libs/finbits/client';

import type {
  ImportRedirectParams,
  ImportedStatement,
  PatchParams,
  UploadFile,
} from './types';

export async function importStatement(
  organizationId: string,
  companyId: string,
  file: UploadFile
): Promise<ImportedStatement | ErrorResponse> {
  const formData = new FormData();

  formData.append('file', file);

  const response = await authenticatedAPI
    .post(
      `/organizations/${organizationId}/companies/${companyId}/banks/statements`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then(handleSuccess)
    .catch(handleError);

  if (response.type === 'success') {
    return response.data as ImportedStatement;
  }

  return { ...response.data, isError: true } as ErrorResponse;
}

export async function patchImportedStatement({
  organizationId,
  companyId,
  payload,
}: PatchParams) {
  const response = await authenticatedAPI
    .patch(
      `/organizations/${organizationId}/companies/${companyId}/banks/statements`,
      payload
    )
    .then(handleSuccess)
    .catch(handleError);

  if (response.type === 'success') {
    return response.data;
  }

  return { ...response.data, isError: true } as ErrorResponse;
}

export function redirectPathAfterEntriesImport({
  organizationId,
  companyId,
  accountId,
  date,
}: ImportRedirectParams) {
  const params = new URLSearchParams();

  params.append('accountId', accountId);
  params.append('date', date);

  return {
    pathname: `/organizations/${organizationId}/companies/${companyId}/entries`,
    search: params.toString(),
  };
}

export function redirectPathAfterStatementsImport({
  organizationId,
  companyId,
  accountId,
  date,
}: ImportRedirectParams) {
  const params = new URLSearchParams();

  params.append('accountId', accountId);
  params.append('date', date);

  return {
    pathname: `/organizations/${organizationId}/companies/${companyId}/entries`,
    search: params.toString(),
  };
}
