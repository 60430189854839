import { ListItem, ListItemButton } from '@mui/material';
import { FileIcon, TrashIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { FocusIcon, IconButton } from 'src/mui';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

import styles from './UploadedAttachment.module.scss';

type Props = {
  attachment: Attachment;
  onDeleteAttachment: (attachment: Attachment) => void;
  onClickItem: (attachment: Attachment) => void;
};

export default function UploadedAttachment({
  attachment,
  onDeleteAttachment,
  onClickItem,
}: Props) {
  return (
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton
          onClick={() => onDeleteAttachment(attachment)}
          aria-label="Remover arquivo"
          title="Remover arquivo"
        >
          <TrashIcon />
        </IconButton>
      }
    >
      <ListItemButton
        className={styles.item}
        onClick={() => onClickItem(attachment)}
        aria-label="Abrir anexo no preview"
      >
        <FocusIcon size="md">
          <FileIcon fontSize="small" viewBox="-7 6 30 10" />
        </FocusIcon>

        <Typography ellipsis fontWeight="500" className={styles.typography}>
          {attachment?.name}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
}
