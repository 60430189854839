import type { ReactNode } from 'react';

import cn from 'classnames';

import styles from './PageWrapper.module.less';

type Props = {
  children: ReactNode;
  className?: string;
};

export default function PageWrapper({ children, className }: Props) {
  return <div className={cn(styles.wrapper, className)}>{children}</div>;
}
