import type { Ref } from 'react';
import { forwardRef } from 'react';

import type { RadioGroupProps } from '@mui/material';
import { RadioGroup as MUIRadioGroup } from '@mui/material';

import styles from './RadioGroup.module.scss';

function RadioGroup(
  { children, ...props }: RadioGroupProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <MUIRadioGroup {...props} className={styles.radioGroup} ref={ref}>
      {children}
    </MUIRadioGroup>
  );
}

export default forwardRef(RadioGroup);
