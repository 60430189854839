import type { Components } from '@mui/material';

export function createMuiAppBar(): Components['MuiAppBar'] {
  return {
    defaultProps: {
      elevation: 1,
      sx: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'divider',
        boxShadow: 'none',
        background: (theme) => `${theme.palette.background.paper} !important`,
        zIndex: (theme) => theme.zIndex.appBar,
      },
    },
  };
}
