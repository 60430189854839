import { Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { pluralize } from 'src/libs/finbits/Pluralize';

import TotalSum from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar/TotalSum';

type Props = {
  selectedIds: string[];
  bills: BillPayable[];
  description: string;
};

export default function GridToolBar({
  selectedIds,
  bills,
  description,
}: Props) {
  const filteredSelecteds = bills.filter(({ id }) => selectedIds.includes(id));
  return (
    <Stack
      marginX={4}
      marginY={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography>
        {`${selectedIds?.length} 
            ${pluralize(
              selectedIds.length,
              'lançamento selecionado',
              'lançamentos selecionados'
            )}`}
      </Typography>

      <TotalSum bills={filteredSelecteds} description={description} />
    </Stack>
  );
}
