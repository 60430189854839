import { useMemo } from 'react';

import {
  Checkbox,
  Fade,
  FormControlLabel,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
} from '@mui/material';
import { TrashIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { useInboxItemsContext } from 'src/features/inbox-items/InboxItemsProvider/InboxItemsProvider';
import useInboxItemsActions from 'src/features/inbox-items/useInboxItemsActions';

const MAX_ITENS = 9999;

type Props = {
  isLoading: boolean;
};

export default function InboxItemsCheckbox({ isLoading }: Props) {
  const {
    filteredInboxItems,
    selectedInboxItemsIds,
    setSelectedInboxItemsIds,
  } = useInboxItemsContext();

  const { onRejectionMany } = useInboxItemsActions({
    inboxItemsIds: selectedInboxItemsIds,
  });

  const inboxItems = filteredInboxItems;

  const isSelected = useMemo(() => {
    if (inboxItems.length === 0) return false;

    return selectedInboxItemsIds.length === inboxItems.length;
  }, [selectedInboxItemsIds, inboxItems]);

  const isIndeterminate = useMemo(() => {
    return selectedInboxItemsIds.length > 0 && !isSelected;
  }, [selectedInboxItemsIds, isSelected]);

  const inboxItemsIds = useMemo(() => {
    return inboxItems.map(({ id }) => id);
  }, [inboxItems]);

  if (isLoading) {
    return (
      <Skeleton
        data-testid="loading"
        variant="rectangular"
        width="50%"
        height="24px"
      />
    );
  }

  function handleChange(checked: boolean) {
    if (checked) {
      return setSelectedInboxItemsIds(inboxItemsIds);
    }

    setSelectedInboxItemsIds([]);
  }

  function buildMessage() {
    const selectedCount = selectedInboxItemsIds.length;

    if (selectedCount === 0) {
      return 'Selecionar todos';
    }

    if (selectedCount === 1) {
      return '1 item selecionado';
    }

    if (selectedCount < MAX_ITENS) {
      return `${selectedCount} itens selecionados`;
    }

    return `${MAX_ITENS}+ selecionados`;
  }

  const message = buildMessage();

  return (
    <Stack
      direction="row"
      sx={{
        mt: '-6px',
        mb: '-8px',
        justifyContent: 'space-between',
      }}
    >
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={(_event, value) => handleChange(value)}
              checked={isSelected}
              indeterminate={isIndeterminate}
            />
          }
          label={
            <Typography
              variant="text-md"
              fontWeight="500"
              color="text.secondary"
            >
              {message}
            </Typography>
          }
        />
      </Stack>

      <Fade in={selectedInboxItemsIds.length > 0}>
        <Tooltip title="Rejeitar">
          <IconButton onClick={onRejectionMany} aria-label="Rejeitar">
            <TrashIcon />
          </IconButton>
        </Tooltip>
      </Fade>
    </Stack>
  );
}
