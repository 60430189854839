import type { Components } from '@mui/material';
import { buttonClasses, buttonGroupClasses } from '@mui/material';

export function createMuiButtonGroup(): Components['MuiButtonGroup'] {
  return {
    styleOverrides: {
      text: {
        [`.${buttonClasses.text}.${buttonGroupClasses.grouped}:not(:last-of-type)`]:
          {
            border: 'none',
          },
      },
    },
  };
}
