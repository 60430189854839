import type { SelectProps } from 'antd';
import { Select } from 'antd';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCompanyUsers } from 'src/libs/finbits/Organization/Users';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import { selectFilter } from 'src/features/EntryForm/selectFilter';

import AssigneeTag from './AssigneeTag/AssigneeTag';
import styles from './AssigneeSelect.module.less';

type Props = {
  bill?: BillPayable;
} & SelectProps;

export default function AssigneeSelect({ bill, ...rest }: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { users, isLoading } = useCompanyUsers(
    {
      companyId,
      organizationId,
      onlyAvailableApprovers: true,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  return (
    <Select
      allowClear
      showSearch
      showArrow
      size="large"
      placeholder="Selecione"
      loading={isLoading}
      mode="multiple"
      filterOption={selectFilter}
      getPopupContainer={(trigger) => trigger.parentElement}
      tagRender={({ label, value, ...rest }) => (
        <AssigneeTag
          className={styles.tag}
          assigneeId={value}
          billStatus={bill?.status}
          assignments={bill?.lastApproval?.assignments}
          {...rest}
        >
          {label}
        </AssigneeTag>
      )}
      {...rest}
    >
      {users.map((user) => (
        <Select.Option value={user.id} key={user.id}>
          {user.name}
        </Select.Option>
      ))}
    </Select>
  );
}
