import { Box, Link, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Typography } from 'src/design-system/components';

import { snackbar } from 'src/mui';

import { useResetPassword } from 'src/libs/finbits/Auth/Reset';
import type { RecoverParams } from 'src/libs/finbits/Auth/Reset/types';
import { RecoverResolver } from 'src/libs/finbits/Auth/Reset/types';

function RecoverPassword() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RecoverParams>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(RecoverResolver),
  });

  const navigate = useNavigate();

  const { resetPassword, isLoading } = useResetPassword();

  async function onSubmit({ email }: RecoverParams) {
    resetPassword(
      {
        email,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'E-mail de redefinição de senha enviado',
          });

          navigate('/login');
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao enviar o convite',
          });
        },
      }
    );
  }

  return (
    <Box sx={{ mt: 6, mb: 16 }}>
      <Typography
        variant="h5"
        component="h2"
        marginBottom={4}
        align="center"
        fontWeight="600"
      >
        Redefinir senha
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={isLoading}
              error={!!errors.email}
              helperText={errors.email?.message}
              label="E-mail"
              margin="normal"
              placeholder="Insira seu e-mail"
            />
          )}
        />

        <LoadingButton
          color="primary"
          fullWidth
          loading={isLoading}
          sx={{ mt: 6, mb: 8 }}
          type="submit"
          variant="contained"
          size="large"
        >
          Enviar link de redefinição
        </LoadingButton>

        <Link component={RouterLink} to="/login">
          Fazer login
        </Link>
      </Box>
    </Box>
  );
}

export default RecoverPassword;
