import { Button, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Title } from 'src/ui';

type Props = {
  currentStep: number;
  onGoBack: () => void;
};

export default function ModalTitle({ currentStep, onGoBack }: Props) {
  if (currentStep === 3) {
    return <Title>Download iniciado</Title>;
  }

  return (
    <Space>
      {currentStep === 2 && (
        <Button
          aria-label="Voltar"
          icon={<ArrowLeftOutlined />}
          size="small"
          type="text"
          onClick={onGoBack}
        />
      )}
      <Title>Download de lançamentos</Title>
    </Space>
  );
}
