import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';

import type { Channel } from 'phoenix';

import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import { useChannel } from './SocketProvider';

type State = {
  channel: null | Channel;
  isConnected: boolean;
};

const initialState = { channel: null, isConnected: false };

const Context = createContext<State>(initialState);

type Props = {
  children: ReactNode;
  companyId?: string;
  organizationId?: string;
};

export function CompanyChannelProvider({
  children,
  companyId,
  organizationId,
}: Props) {
  const isAllowed = useAllowedPermission({
    action: 'join_channel_topic',
    resource: 'companies',
  });

  const topic = useMemo(() => {
    if (isAllowed && companyId && organizationId) {
      return `organizations:${organizationId}:companies:${companyId}`;
    }

    return null;
  }, [isAllowed, companyId, organizationId]);
  const state = useChannel(topic);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}

export function useCompanyListener<T>(
  event: string,
  handler: (message: T) => void
) {
  const { channel, isConnected } = useContext(Context);

  useEffect(() => {
    if (channel && isConnected) {
      if (import.meta.env.VITE_WEB_SOCKET_DEBUG === 'true') {
        console.debug(`[websocket] Subscribe to ${event}`);
      }

      const ref = channel.on(event, handler);

      return () => {
        if (import.meta.env.VITE_WEB_SOCKET_DEBUG === 'true') {
          console.debug(`[websocket] Unsubscribe to ${event}`);
        }

        channel.off(event, ref);
      };
    }
  }, [event, handler, channel, isConnected]);
}
