import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function PlusIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon aria-label="plus" viewBox={viewBox} fontSize={fontSize} {...rest}>
      <path
        d="M12 5V19M5 12H19"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
