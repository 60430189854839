import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { FormField, Input } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';

import BankSelect from 'src/features/bank/BankSelect/BankSelect';
import InitialBalanceFields from 'src/features/accounts/AccountModal/AccountForm/InitialBalanceFields';

export default function ManualCheckingAccountSteppe() {
  const { control } = useFormContext();
  const currentDate = new Date();

  return (
    <Stack display="flex" gap={5} flexDirection="column" paddingBottom={8}>
      <Typography variant="text-lg" fontWeight="600" component="h6">
        Dados da conta
      </Typography>
      <Typography color="grey.600" marginBottom={3}>
        Informe os dados da conta e a referência de valor e data do saldo.
      </Typography>

      <FormField control={control} label="Banco" name="routingNumber">
        {(field) => <BankSelect {...field} />}
      </FormField>

      <Stack display="flex" gap={5} flexDirection="row">
        <FormField control={control} label="Agência" name="branchNumber">
          {(field) => <Input {...field} placeholder="Digite o número" />}
        </FormField>

        <FormField
          control={control}
          label="Conta (com o dígito)"
          name="accountNumber"
        >
          {(field) => <Input {...field} placeholder="Digite o número" />}
        </FormField>
      </Stack>

      <InitialBalanceFields maxDate={currentDate} flexDirection="row" />
    </Stack>
  );
}
