import t from 'zod';

export enum FrequencyUnit {
  MONTHS = 'months',
  WEEKS = 'weeks',
}

export const ScheduledRecurrenceBaseDecoder = t.object({
  id: t.string(),
  companyId: t.string(),
  quantity: t.number(),
  initialDate: t.string().nullable(),
});
