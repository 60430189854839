import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd';

import { useStates } from 'src/libs/finbits/BrazilianData/Ibge/States';
import { filterOption } from 'src/libs/finbits/Form';

export default function StateSelect({ ...rest }: SelectProps<string>) {
  const { data: states, isLoading } = useStates();

  return (
    <Select
      filterOption={filterOption}
      loading={isLoading}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      placeholder="Digite o nome do estado"
      size="large"
      allowClear
      showSearch
      getPopupContainer={(trigger) => trigger.parentElement}
      {...rest}
    >
      {states?.map((state) => (
        <Select.Option value={state.code} key={state.code}>
          {state.name}
        </Select.Option>
      ))}
    </Select>
  );
}
