import type { JSXElementConstructor, SyntheticEvent } from 'react';
import { useState } from 'react';

import type {
  GridRenderEditCellParams,
  GridValidRowModel,
  GridValueOptionsParams,
  ValueOptions,
} from '@mui/x-data-grid-premium';

export type ComponentProps<T extends GridValidRowModel> = {
  options:
    | ValueOptions[]
    | ((params: GridValueOptionsParams<T>) => ValueOptions[])
    | undefined;
  onChange: (_e: SyntheticEvent, newValue: T | null) => void;
  value: GridRenderEditCellParams['value'];
  row: GridRenderEditCellParams['row'];
  bordered: boolean;
  multiple?: boolean;
  autoFocus?: boolean;
};

type EditParams<T extends GridValidRowModel> = Pick<
  GridRenderEditCellParams,
  'api' | 'value' | 'field' | 'id' | 'row'
> & {
  Component: JSXElementConstructor<ComponentProps<T>>;
  options?:
    | ValueOptions[]
    | ((params: GridValueOptionsParams<T>) => ValueOptions[])
    | undefined;
  componentProps?: Partial<ComponentProps<T>>;
};

export default function EditInlineWrapper<T extends GridValidRowModel>({
  api,
  value: valueProp,
  field,
  id,
  row,
  Component,
  options = [],
  componentProps,
}: EditParams<T>) {
  const [value, setValue] = useState(valueProp);

  function handleChange(_e: SyntheticEvent, newValue: T | null) {
    api.setEditCellValue({ id, field, value: newValue, debounceMs: 200 });
    setValue(newValue);
  }

  return (
    <Component
      {...componentProps}
      options={options}
      onChange={handleChange}
      value={value}
      row={row}
      bordered={false}
    />
  );
}
