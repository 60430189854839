import { useCallback, useEffect, useState } from 'react';

import create from 'zustand';

import { snackbar } from 'src/mui';

import { Modal } from 'src/ui';

import { useStatementImportFinishedListener } from 'src/libs/finbits/Listeners';
import type { StatementImportFinishedMessage } from 'src/libs/finbits/Channels/types';
import type { Provider } from 'src/libs/finbits/Bank/OpenBanking/types';
import { createOpenBankingConnection } from 'src/libs/finbits/Bank/OpenBanking';

import ImportLoader from './ImportLoader';
import ImportSuccess from './ImportSuccess';

type StoreState = {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
};

const useStore = create<StoreState>((set) => ({
  modalOpen: false,
  setModalOpen: (open: boolean) => set({ modalOpen: open }),
}));

export function useModalOpenState() {
  return useStore((store: StoreState) => store.modalOpen);
}

export function useSetModalOpen() {
  return useStore((store: StoreState) => store.setModalOpen);
}

type Props = {
  companyId: string;
  organizationId: string;
  providerConnectionId: string;
  providerInstitutionCode: string;
  provider: Provider;
  onClose: () => void;
};

function ImportFinish({
  companyId,
  organizationId,
  providerConnectionId,
  providerInstitutionCode,
  provider,
  onClose,
}: Props) {
  const [isLoading, setLoading] = useState(true);
  const [connectionId, setConnectionId] = useState<string>();
  const [minTimeElapsed, setMinTimeElapsed] = useState(false);
  const [message, setMessage] = useState<StatementImportFinishedMessage>();
  const setModalOpen = useSetModalOpen();

  useEffect(() => {
    setLoading(true);

    createOpenBankingConnection({
      companyId,
      organizationId,
      providerConnectionId,
      providerInstitutionCode,
      provider: provider,
    })
      .then((connection) => {
        setConnectionId(connection.id);
      })
      .catch((_error) => {
        snackbar({
          variant: 'error',
          message: 'Ops! Ocorreu um erro na criação da conexão.',
        });
      })
      .finally(() => setLoading(false));
  }, [
    companyId,
    organizationId,
    providerConnectionId,
    providerInstitutionCode,
    provider,
    setConnectionId,
    setLoading,
  ]);

  useEffect(() => {
    const ref = setTimeout(() => {
      setMinTimeElapsed(true);
    }, 10_000);

    return () => clearTimeout(ref);
  }, [setMinTimeElapsed]);

  useEffect(() => {
    setModalOpen(true);

    return () => setModalOpen(false);
  }, [setModalOpen]);

  const handler = useCallback(
    (newMessage: StatementImportFinishedMessage) => {
      if (
        !!connectionId &&
        newMessage.connectionId === connectionId &&
        !message
      ) {
        setMessage(newMessage);
      }
    },
    [message, connectionId, setMessage]
  );

  useStatementImportFinishedListener(handler);

  const isSuccess = minTimeElapsed && !!message;

  return (
    <Modal
      footer={null}
      visible={true}
      closable={!isLoading}
      onCancel={onClose}
      destroyOnClose
    >
      {isSuccess ? (
        <ImportSuccess
          totalAccounts={message!.totalAccounts}
          totalTransactions={message!.totalTransactions}
          institutionName={message!.institutionName}
          onClose={onClose}
        />
      ) : (
        <ImportLoader />
      )}
    </Modal>
  );
}

export default ImportFinish;
