import { useMutation, useQueryClient } from 'react-query';

import { authenticatedAPI } from 'src/libs/finbits/client';
import { invalidateNotasFiscaisQueries } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { invalidateScheduledEntriesQueries } from 'src/libs/finbits/Management/ScheduledEntries';
import { invalidateFinancialEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries';

import type { PatchParams } from './types';

function patchLinkToEntries({
  organizationId,
  companyId,
  notaFiscalId,
  ...params
}: PatchParams) {
  return authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/notas_fiscais/${notaFiscalId}/link_to_entries`,
    params
  );
}

function patchUnlinkEntries({
  organizationId,
  companyId,
  ...params
}: PatchParams) {
  return authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/notas_fiscais/unlink_entries`,
    params
  );
}

export function useLinkNotaFiscalToEntries() {
  const queryClient = useQueryClient();

  const { mutate: linkNotaFiscalToEntries, ...rest } = useMutation({
    mutationFn: patchLinkToEntries,
    onSuccess: () => {
      invalidateNotasFiscaisQueries(queryClient);
    },
  });

  return {
    linkNotaFiscalToEntries,
    ...rest,
  };
}

export function useUnlinkEntries() {
  const queryClient = useQueryClient();

  const { mutate: unlinkEntries, ...rest } = useMutation({
    mutationFn: patchUnlinkEntries,
    onSuccess: () => {
      invalidateScheduledEntriesQueries(queryClient);
      invalidateFinancialEntry(queryClient);
      invalidateNotasFiscaisQueries(queryClient);
    },
  });

  return {
    unlinkEntries,
    ...rest,
  };
}
