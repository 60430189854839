import { ExclamationCircleOutlined } from '@ant-design/icons';

import { ErrorMessage } from 'src/ui';

export default function ForbiddenPage() {
  return (
    <ErrorMessage
      icon={<ExclamationCircleOutlined />}
      message="Ops, você não tem acesso a essa área"
      description="Talvez você não tenha acesso a essa área, ou o link que você clicou esteja desatualizado."
    />
  );
}
