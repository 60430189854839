import type { ReactElement, ReactNode } from 'react';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Typography } from 'src/design-system/components';

type ItemProps = {
  icon: ReactElement;
  label: string | ReactNode;
  onClick: () => void;
  onClose: () => void;
};

export function ContextItem({ icon, label, onClick, onClose }: ItemProps) {
  function handleClick() {
    onClose();
    onClick();
  }

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        <Typography ellipsis>{label}</Typography>
      </ListItemText>
    </MenuItem>
  );
}
