import type { ReactNode } from 'react';

import {
  CloudOutlined,
  FileOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { Tag } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

type Props = {
  account: Account;
};

type TagData = {
  icon: ReactNode;
  color: string;
  message: string;
};

function buildTagData(account: Account): TagData {
  if (account.synchronizeType === 'manual') {
    return {
      icon: <FileOutlined />,
      color: 'default',
      message: 'Atualização manual',
    };
  }

  const connectionStatus =
    account.openBankingConnection?.status || account.btgConnection?.status;

  switch (connectionStatus) {
    case 'invalid_credentials':
      return {
        icon: <WarningOutlined />,
        color: 'red',
        message: 'Atualização automática pendente: credenciais desatualizadas',
      };

    case 'mfa_required':
      return {
        icon: <WarningOutlined />,
        color: 'red',
        message: 'Atualização automática pendente: autenticação necessária',
      };

    case 'activating':
      return {
        icon: <LoadingOutlined />,
        color: 'warning',
        message: 'Importando dados do banco',
      };

    default:
      return {
        icon: <CloudOutlined />,
        color: 'blue',
        message: 'Atualização automática',
      };
  }
}

function AccountStatusTag({ account }: Props) {
  const tagData = buildTagData(account);

  return (
    <Tag icon={tagData.icon} color={tagData.color}>
      {tagData.message}
    </Tag>
  );
}

export default AccountStatusTag;
