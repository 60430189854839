import type { Components } from '@mui/material';

export function createMuiTypography(): Components['MuiTypography'] {
  return {
    defaultProps: {
      variant: 'text-sm',
      variantMapping: {
        'text-xl': 'p',
        'text-lg': 'p',
        'text-md': 'p',
        'text-sm': 'p',
        'text-xs': 'p',
      },
    },
  };
}
