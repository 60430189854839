import { GridFilterPanel } from '@mui/x-data-grid-premium';
import { Stack } from '@mui/material';

import QuickFilters from './QuickFilters';
import type { QuickFilter } from './QuickFilters/types';

type FilterPanelProps = {
  quickFilters: QuickFilter[];
};

export default function FilterPanel({ quickFilters }: FilterPanelProps) {
  return (
    <Stack>
      <QuickFilters filters={quickFilters} />

      <GridFilterPanel />
    </Stack>
  );
}
