import t from 'zod';

const ServiceDecoder = t.object({
  id: t.string(),
  description: t.string(),
  code: t.string(),
  subitemCode: t.string().nullable(),
  active: t.boolean(),
  suggestedRate: t.number(),
});

export type Service = t.TypeOf<typeof ServiceDecoder>;

const CompanyServiceDecoder = t.object({
  municipalServiceId: t.string(),
  mainActivity: t.boolean(),
  code: t.string(),
  subitemCode: t.string().nullable(),
  description: t.string(),
  suggestedRate: t.number(),
  active: t.boolean(),
});

export type CompanyService = t.TypeOf<typeof CompanyServiceDecoder>;

export const ServicesDecoder = t.array(ServiceDecoder);
export const CompanyServicesDecoder = t.array(CompanyServiceDecoder);

export type GetServicesParams = {
  city?: string | null;
};

export type GetCompanyServicesParams = {
  companyId?: string;
  organizationId?: string;
};

export type UpdateCompanyServicesParams = {
  companyId: string;
  organizationId: string;
  services: Pick<CompanyService, 'municipalServiceId' | 'mainActivity'>[];
};

export type UpdateCompanyServicesFormParams = Pick<
  UpdateCompanyServicesParams,
  'services'
>;
