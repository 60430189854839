import type { ReactElement } from 'react';

import compact from 'lodash/compact';
import {
  CheckIcon,
  SendToApprovalIcon,
  SendToPaymentIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  UndoApprovedIcon,
  UndoSendToApprovalIcon,
  UndoWaitingPaymentIcon,
} from 'src/mui/_icons';
import { useRowAction } from 'src/mui/_scss/Table/hooks/useRowAction';

import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import {
  AvailableActions,
  EntrySituation,
} from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import { useRowActions as useEntryRowAction } from 'src/features/entries/EntriesDataGrid/ContextMenuRowActions/useRowActions';
import useWaitingPaymentAction from 'src/features/bills-to-pay/PaymentsFlow/useWaitingPaymentAction';
import useWaitingApprovalAction from 'src/features/bills-to-pay/PaymentsFlow/useWaitingApprovalAction';
import useApprovedAction from 'src/features/bills-to-pay/PaymentsFlow/useApprovedAction';
import useReprovedAction from 'src/features/bills-to-pay/PaymentsFlow/useReprovedAction';
import useMarkAsFinancialAction from 'src/features/bills-to-pay/Actions/MarkAsFinancialAction/useMarkAsFinancialAction';

type RowAction = {
  name: string;
  icon: ReactElement;
  label: string;
  onClick: () => void;
  dividerAfter?: boolean;
};

function parseRowToFinancialStatementEntry(
  row: BillPayable
): FinancialStatementEntry {
  return {
    ...row,
    isSplit: false,
    transactionId: null,
    notaFiscalId: null,
    classifications: row.classifications ?? [],
    currentSituation: row.currentSituation ?? EntrySituation.PENDING,
    editableFields: row.editableFields ?? [],
    availableActions: row.availableActions ?? [],
  };
}

export function useRowActions(row: BillPayable, onSuccess?: () => void) {
  const { actionsForRow: defaultActionForRow } = useEntryRowAction(
    parseRowToFinancialStatementEntry(row)
  );

  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  const { sendToWaitingApproval, undosWaitingApproval } =
    useWaitingApprovalAction({
      selecteds: [row],
      onSuccess,
    });

  const { sendToWaitingPayment, undosWaitingPayment } = useWaitingPaymentAction(
    {
      selecteds: [row],
      onSuccess,
    }
  );

  const { sendToApproved, undosApproved } = useApprovedAction({
    selecteds: [row],
    onSuccess,
  });

  const { sendToReproved } = useReprovedAction({
    selecteds: [row],
    onSuccess,
  });

  const { markAsFinancial } = useMarkAsFinancialAction({
    bill: row,
    onSuccess,
  });

  const sendToWaitingPaymentItem = useRowAction({
    rowAction: {
      'aria-label': 'Enviar para Pagamento',
      name: 'sendForPayment',
      label: 'Enviar para Pagamento',
      onClick: sendToWaitingPayment,
      icon: <SendToPaymentIcon />,
    },
    permissions: {
      action: 'create',
      resource: 'waitingPayments',
      scope: 'company',
    },
    enabled:
      canPerformAction(row, AvailableActions.SEND_TO_MANUAL_PAYMENT) ||
      canPerformAction(row, AvailableActions.SEND_TO_AUTOMATIC_PAYMENT),
  });

  const undoWaitingPaymentItemLabel = isEnabledBillsApprovals
    ? 'Retornar para Aprovados'
    : 'Reenviar para A pagar';
  const undoWaitingPaymentItem = useRowAction({
    rowAction: {
      'aria-label': undoWaitingPaymentItemLabel,
      name: 'undoSendToWaitingPayment',
      label: undoWaitingPaymentItemLabel,
      icon: <UndoWaitingPaymentIcon />,
      onClick: undosWaitingPayment,
    },
    permissions: {
      action: 'undo',
      resource: 'waitingPayments',
      scope: 'company',
    },
    enabled: canPerformAction(
      row,
      AvailableActions.UNDO_SEND_TO_WAITING_PAYMENT
    ),
  });

  const isReproved = row.status === BillStatus.REPROVED;
  const sendToWaitingApprovalItemLabel = isReproved
    ? 'Reenviar para aprovação'
    : 'Enviar para aprovação';
  const sendToWaitingApprovalItem = useRowAction({
    rowAction: {
      'aria-label': sendToWaitingApprovalItemLabel,
      name: 'sendForApproval',
      label: sendToWaitingApprovalItemLabel,
      onClick: sendToWaitingApproval,
      icon: isReproved ? <UndoApprovedIcon /> : <SendToApprovalIcon />,
    },
    permissions: {
      action: 'create',
      resource: 'waitingApprovalPayments',
      scope: 'company',
    },
    enabled: canPerformAction(row, AvailableActions.SEND_TO_WAITING_APPROVAL),
  });

  const undoWaitingApprovalItem = useRowAction({
    rowAction: {
      'aria-label': 'Retornar para ”A pagar”',
      name: 'undoSendToWaitingApproval',
      label: 'Retornar para ”A pagar”',
      icon: <UndoSendToApprovalIcon />,
      onClick: undosWaitingApproval,
    },
    permissions: {
      action: 'undo',
      resource: 'waitingApprovalPayments',
      scope: 'company',
    },
    enabled: canPerformAction(
      row,
      AvailableActions.UNDO_SEND_TO_WAITING_APPROVAL
    ),
  });

  const sendToApprovedItem = useRowAction({
    rowAction: {
      'aria-label': 'Aprovar',
      name: 'sendToApproved',
      label: 'Aprovar',
      icon: <ThumbsUpIcon />,
      onClick: () => sendToApproved(),
    },
    permissions: {
      action: 'create',
      resource: 'approvedPayments',
      scope: 'company',
    },
    enabled: canPerformAction(row, AvailableActions.SEND_TO_APPROVED),
  });

  const undoApprovedItem = useRowAction({
    rowAction: {
      'aria-label': 'Reenviar para aprovação',
      name: 'undoSendToApproved',
      label: 'Reenviar para aprovação',
      icon: <UndoApprovedIcon />,
      onClick: () => undosApproved(),
    },
    permissions: {
      action: 'undo',
      resource: 'approvedPayments',
      scope: 'company',
    },
    enabled: canPerformAction(row, AvailableActions.UNDO_SEND_TO_APPROVED),
  });

  const sendToReprovedItem = useRowAction({
    rowAction: {
      'aria-label': 'Reprovar',
      name: 'sendToReproved',
      label: 'Reprovar',
      icon: <ThumbsDownIcon />,
      onClick: sendToReproved,
    },
    permissions: {
      action: 'create',
      resource: 'reprovedPayments',
      scope: 'company',
    },
    enabled: canPerformAction(row, AvailableActions.SEND_TO_REPROVED),
  });

  const markAsFinancialItem = useRowAction({
    rowAction: {
      'aria-label': 'Marcar como realizado',
      name: 'markAsFinancial',
      label: 'Marcar como realizado',
      icon: <CheckIcon />,
      onClick: markAsFinancial,
    },
    permissions: {
      action: 'create_from_bill',
      resource: 'financialEntries',
      scope: 'company',
    },
    enabled: canPerformAction(row, AvailableActions.MARK_AS_FINANCIAL_ENTRY),
  });

  function rowActions(): RowAction[] {
    return compact([
      ...defaultActionForRow(),
      sendToApprovedItem,
      sendToReprovedItem,
      sendToWaitingApprovalItem,
      sendToWaitingPaymentItem,
      undoWaitingApprovalItem,
      undoApprovedItem,
      undoWaitingPaymentItem,
      markAsFinancialItem,
    ]);
  }

  return { rowActions };
}
