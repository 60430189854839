import type { MouseEvent, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import type { ButtonProps, PopoverProps } from '@mui/material';
import { Button, MenuList, Popover } from '@mui/material';

import DropdownOption from './DropdownOption';

type EndIconProps = {
  open: boolean;
};

type Props = {
  options?: ReactNode[];
  PopoverProps?: Partial<PopoverProps>;
  endIcon?: (props: EndIconProps) => ReactElement;
} & Omit<ButtonProps, 'endIcon'>;

export default function Dropdown({
  onClick,
  endIcon,
  children,
  options,
  PopoverProps,
  ...props
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [width, setWidth] = useState('auto');

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setWidth(`${event.currentTarget.offsetWidth}px`);

    if (!!onClick) {
      onClick(event);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        {...props}
        endIcon={endIcon && endIcon({ open })}
        onClick={handleClick}
      >
        {children}
      </Button>
      {!!options && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          PaperProps={{
            sx: { minWidth: width, px: 2 },
            onClick: handleClose,
          }}
          {...PopoverProps}
        >
          <MenuList sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {options}
          </MenuList>
        </Popover>
      )}
    </>
  );
}

Dropdown.Option = DropdownOption;
