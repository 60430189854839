import { Button, Col, Form, Input, Row } from 'antd';
import type { Store } from 'antd/lib/form/interface';

import { snackbar } from 'src/mui';

import { FormItem, MaskedInput } from 'src/ui';

import {
  cellphoneMask,
  e164Format,
  phoneFormat,
  phoneValidator,
} from 'src/libs/finbits/Organization/PhoneNumber';
import { errorsToFormField } from 'src/libs/finbits/Form';
import { useUpdateProfile } from 'src/libs/finbits/Organization/Users';
import type { ErrorResponse } from 'src/libs/finbits/client';
import type { User } from 'src/libs/finbits/Organization/Users/types';

type UpdatePhone = {
  number: string;
};

type UpdateParams = {
  id?: string;
  name?: string;
  email: string;
  phone?: UpdatePhone | null;
};

type Props = {
  user: User;
};

function UserProfileForm({ user }: Props) {
  const [form] = Form.useForm();
  const { updateProfile, isLoading } = useUpdateProfile();

  function onSubmit(store: Store) {
    const params = { name: store.name, phone: null } as UpdateParams;

    if (store.phone.number) {
      const phoneNumber = e164Format(store.phone.number);

      if (phoneNumber) {
        params.phone = {
          number: phoneNumber,
        };
      }
    }

    updateProfile(params, {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          message: 'Informações salvas com sucesso.',
        });
      },
      onError: (error) => {
        if (error.response?.status === 422) {
          const { errors } = error.response.data as ErrorResponse;

          form.setFields(errorsToFormField(errors));
        } else {
          snackbar({
            variant: 'error',
            message: 'Ops! Falha ao salvar as informações.',
          });
        }
      },
    });
  }

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Row>
        <Col span={24}>
          <FormItem required initialValue={user?.name} name="name" label="Nome">
            <Input size="large" />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            initialValue={
              user?.phone?.number ? phoneFormat(user.phone.number) : null
            }
            name={['phone', 'number']}
            label="Telefone"
            rules={[{ validator: phoneValidator }]}
          >
            <MaskedInput
              mask={[cellphoneMask]}
              placeholder="(11) 90000-0000"
              size="large"
            />
          </FormItem>
        </Col>
        <Col span={24} style={{ paddingTop: '24px' }}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Button
                loading={isLoading}
                size="large"
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default UserProfileForm;
