import type { InputHTMLAttributes } from 'react';
import { useEffect } from 'react';

import { VisualState, useKBar } from 'kbar';
import type { InputBaseProps } from '@mui/material';
import { InputAdornment, InputBase } from '@mui/material';
import { SearchIcon } from 'src/mui/_icons';

import { input, inputAdornment } from './CommandBarConsumer.sx';

export default function CommandBarInput(
  props: InputHTMLAttributes<HTMLInputElement> & InputBaseProps
) {
  const { query, search, actions, currentRootActionId, showing, options } =
    useKBar((state) => ({
      search: state.searchQuery,
      currentRootActionId: state.currentRootActionId,
      actions: state.actions,
      showing: state.visualState === VisualState.showing,
    }));

  useEffect(() => {
    query.setSearch('');
    (query.getInput().lastElementChild as HTMLInputElement)?.focus();

    return () => query.setSearch('');
  }, [currentRootActionId, query]);

  return (
    <InputBase
      {...props}
      sx={input}
      placeholder="Digite um comando ou busca"
      startAdornment={
        <InputAdornment position="start" sx={inputAdornment}>
          <SearchIcon />
        </InputAdornment>
      }
      ref={query.inputRefSetter}
      autoFocus={true}
      autoComplete="off"
      role="combobox"
      spellCheck="false"
      aria-expanded={showing}
      value={search}
      onChange={(event) => {
        props.onChange?.(event);
        query.setSearch(event.target.value);
        options?.callbacks?.onQueryChange?.(event.target.value);
      }}
      onKeyDown={(event) => {
        props.onKeyDown?.(event);
        if (currentRootActionId && !search && event.key === 'Backspace') {
          const parent = actions[currentRootActionId].parent;
          query.setCurrentRootAction(parent);
        }
      }}
    />
  );
}
