import type { SyntheticEvent } from 'react';

import type { GridRenderEditCellParams } from '@mui/x-data-grid-premium';

import type { Category } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';

import CategorySelect from 'src/features/categories/CategorySelect';

type EditParams = Pick<
  GridRenderEditCellParams,
  'api' | 'value' | 'field' | 'id' | 'row'
> & {
  onChange: (_e: SyntheticEvent, newValue: Category | null | string) => void;
  autoFocus?: boolean;
  bordered?: boolean;
};

export default function CategoriesAutocomplete({
  row,
  value,
  onChange,
  autoFocus = true,
  bordered = true,
}: EditParams) {
  return (
    <CategorySelect<false, false>
      disableCreateButton
      type={row?.amountType}
      bordered={bordered}
      autoHighlight
      openOnFocus
      autoFocus={autoFocus}
      onChange={onChange}
      value={value}
    />
  );
}
