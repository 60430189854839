import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Typography } from 'src/design-system/components';

import UnfilledContextIcon from './UnfilledContextIcon';
import styles from './DescriptionWithContextIcon.module.scss';

export default function DescriptionWithContextIcon({
  row,
  rowNode,
  formattedValue,
}: Partial<GridRenderCellParams>) {
  if (rowNode?.type === 'group') {
    return <Typography>{formattedValue}</Typography>;
  }

  const missingContext = Boolean(row?.unfilledContextFields?.length);

  return (
    <>
      <Typography className={styles.description}>{formattedValue}</Typography>
      {missingContext && <UnfilledContextIcon row={row} />}
    </>
  );
}
