import { CoinsHandIcon, ShieldZapIcon } from 'src/mui/_icons';
import type { FormFieldProps } from 'src/mui/_scss';
import { FormField, RadioCard, RadioGroup } from 'src/mui/_scss';

import { SynchronizeType } from 'src/libs/finbits/Bank/Accounts/types';

import AutomaticLabel from './AutomatichLabel/AutomaticLabel';

type Props = {
  allowAutomatic: boolean;
} & FormFieldProps;

export default function SynchronizeTypeRadio({
  allowAutomatic,
  ...props
}: Props) {
  const defaultValue = allowAutomatic
    ? SynchronizeType.AUTOMATIC
    : SynchronizeType.MANUAL;

  return (
    <FormField {...props} defaultValue={defaultValue}>
      {(field) => {
        return (
          <RadioGroup {...field}>
            {allowAutomatic && (
              <RadioCard
                checked={field.value === SynchronizeType.AUTOMATIC}
                label={<AutomaticLabel />}
                description="Suas movimentações financeiras são importadas de forma segura e automática."
                value={SynchronizeType.AUTOMATIC}
                name={SynchronizeType.AUTOMATIC}
                icon={<ShieldZapIcon fontSize="small" />}
              />
            )}

            <RadioCard
              checked={field.value === SynchronizeType.MANUAL}
              label="Manual"
              description="Exporte o extrato de seu banco em formato OFX e importe manualmente no FinBits."
              value={SynchronizeType.MANUAL}
              name={SynchronizeType.MANUAL}
              icon={<CoinsHandIcon fontSize="small" />}
            />
          </RadioGroup>
        );
      }}
    </FormField>
  );
}
