import type { ReactNode } from 'react';

import { Typography } from 'antd';

import styles from './InfoBox.module.less';

type Props = {
  children: ReactNode;
  icon: ReactNode;
};

function InfoBox({ children, icon }: Props) {
  return (
    <div className={styles.infoBox}>
      {icon}
      <Typography.Text>{children}</Typography.Text>
    </div>
  );
}

export default InfoBox;
