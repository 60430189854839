import type { ReactChild } from 'react';

import { getNodeText } from 'src/libs/finbits/filters';

type FilterOption = {
  children: ReactChild;
};

export function selectFilter(input: string, option: any) {
  const optionText = getNodeText((option as FilterOption).children);

  return optionText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}
