import Grid from '@mui/material/Unstable_Grid2';

import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import { contactTypeDictionary } from 'src/libs/finbits/Organization/Companies/Contacts/translations';
import type { AccountTypeLabel } from 'src/libs/finbits/Bank/Accounts/Accounts';
import { ACCOUNT_TYPE_DICTIONARY } from 'src/libs/finbits/Bank/Accounts/Accounts';
import { formatDocument } from 'src/libs/finbits/Documents';

import DetailsInfo from 'src/features/inbox-items/InboxItemDetails/DetailsInfo';

type Props = {
  paymentDetails: PaymentDetails;
};

export default function WireTransferDetails({ paymentDetails }: Props) {
  const documentType = paymentDetails.accountDocumentType;

  return (
    <Grid container columnGap={16} rowGap={6}>
      <DetailsInfo
        label="Tipo de Conta"
        value={paymentDetails.accountType}
        noContentMessage="Não informado"
        formatter={(value) =>
          ACCOUNT_TYPE_DICTIONARY[value as AccountTypeLabel]
        }
      />

      <DetailsInfo
        label={documentType ? contactTypeDictionary[documentType] : 'Documento'}
        value={paymentDetails.accountDocument}
        noContentMessage="Sem documento"
        formatter={(value) =>
          documentType ? formatDocument(documentType, value) : value
        }
      />

      <DetailsInfo
        label="Conta (com o dígito)"
        value={paymentDetails?.accountNumber}
        noContentMessage="Sem conta"
      />

      <DetailsInfo
        label="Banco"
        value={paymentDetails?.bankName}
        noContentMessage="Sem banco"
      />

      <DetailsInfo
        label="Agência"
        value={paymentDetails?.branchNumber}
        noContentMessage="Sem agência"
      />

      <DetailsInfo
        label="Recebedor"
        value={paymentDetails?.payeeName}
        noContentMessage="Sem nome do recebedor"
      />
    </Grid>
  );
}
