import Locale from 'date-fns/locale/pt-BR';
import type { GenerateConfig } from 'rc-picker/lib/generate/index';

import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  format as formatDate,
  getDate,
  getDay,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getWeek,
  getYear,
  isAfter,
  isValid,
  parse as parseDate,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  startOfWeek,
} from 'src/libs/finbits/Date';

function localeParse(format: string) {
  return format
    .replace(/Y/g, 'y')
    .replace(/D/g, 'd')
    .replace(/gggg/, 'yyyy')
    .replace(/g/g, 'G')
    .replace(/([Ww])o/g, 'wo');
}

const generateConfig: GenerateConfig<Date> = {
  // get
  getNow: () => new Date(),
  getFixedDate: (string) => new Date(string),
  getEndDate: endOfMonth,
  getWeekDay: getDay,
  getYear,
  getMonth,
  getDate,
  getHour: getHours,
  getMinute: getMinutes,
  getSecond: getSeconds,

  // set
  addYear: addYears,
  addMonth: addMonths,
  addDate: addDays,
  setYear,
  setMonth,
  setDate,
  setHour: setHours,
  setMinute: setMinutes,
  setSecond: setSeconds,

  // Compare
  isAfter,
  isValidate: isValid,

  locale: {
    getWeekFirstDay: () => {
      const clone = Locale as any;
      return clone.options.weekStartsOn;
    },
    getWeekFirstDate: (_, date) => {
      return startOfWeek(date);
    },
    getWeek: (_, date) => {
      return getWeek(date);
    },
    getShortWeekDays: () => {
      const clone = Locale as any;
      return Array.from({ length: 7 }).map((_, i) =>
        clone.localize.day(i, { width: 'short' })
      );
    },
    getShortMonths: () => {
      const clone = Locale as any;
      return Array.from({ length: 12 }).map((_, i) =>
        clone.localize.month(i, { width: 'abbreviated' })
      );
    },
    format: (_, date, format) => {
      if (!isValid(date)) {
        return '';
      }
      return formatDate(date, localeParse(format));
    },
    parse: (_, text, formats) => {
      for (let i = 0; i < formats.length; i += 1) {
        if (text.length !== formats[i].length) {
          return null;
        }
        const format = localeParse(formats[i]);
        const formatText = text;
        const date = parseDate(formatText, format, new Date());
        if (isValid(date)) {
          return date;
        }
      }
      return null;
    },
  },
};

export default generateConfig;
