import { Alert } from 'antd';
import type { RcFile } from 'antd/lib/upload';

import { isImageType, isPDFType } from 'src/libs/finbits/File/File';

import PreviewBase, { useControls } from 'src/features/preview/PreviewBase';
import PreviewIMGFile from 'src/features/preview/PreviewIMGFile';
import PreviewPDF from 'src/features/preview/PreviewPDF';
import Pagination, { usePageControls } from 'src/features/preview/Pagination';

type Props = {
  onClose: () => void;
  file: RcFile;
};

export default function PreviewFile({ onClose, file }: Props) {
  const {
    zoom,
    rotate,
    handleRotateLeft,
    handleRotateRight,
    handleZoomIn,
    handleZoomOut,
  } = useControls();

  const isPdf = isPDFType(file);
  const isImage = isImageType(file);

  const {
    handleNextPage,
    handlePreviousPage,
    numPages,
    onPDFLoadSuccess,
    pageNumber,
  } = usePageControls();

  return (
    <PreviewBase
      handleRotateLeft={handleRotateLeft}
      handleRotateRight={handleRotateRight}
      handleZoomIn={handleZoomIn}
      handleZoomOut={handleZoomOut}
      onClose={onClose}
      renderWithDrawer={false}
      footer={
        <>
          {isPdf && (
            <Pagination
              canPreviousPage={pageNumber > 1}
              canNextPage={pageNumber !== numPages}
              onPrevious={handlePreviousPage}
              onNext={handleNextPage}
            >
              Página {pageNumber}/{numPages}
            </Pagination>
          )}
        </>
      }
    >
      {isImage && <PreviewIMGFile file={file} zoom={zoom} rotate={rotate} />}
      {isPdf && (
        <PreviewPDF
          file={file}
          zoom={zoom}
          rotate={rotate}
          pageNumber={pageNumber}
          onDocumentLoadSuccess={onPDFLoadSuccess}
          onClose={onClose}
        />
      )}
      {!isImage && !isPdf && (
        <Alert showIcon type="warning" message="Preview não suportado" />
      )}
    </PreviewBase>
  );
}
