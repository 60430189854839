import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import type { ErrorsObject } from 'src/libs/finbits/Form';

import { ImpersonateDecoder } from './';
import type { Impersonate, PostParams } from './';

async function postImpersonate(params: PostParams) {
  const response = await authenticatedAPI.post(`/support/impersonate`, params);

  return decodeResponse<Impersonate>(response, ImpersonateDecoder);
}

export function useImpersonate() {
  const queryClient = useQueryClient();

  const { mutate: impersonate, ...rest } = useMutation<
    Impersonate,
    ApiError<ErrorsObject>,
    PostParams
  >(postImpersonate, {
    onSuccess: () => {
      queryClient.clear();
    },
  });

  return { impersonate, ...rest };
}
