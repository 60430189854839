import type { GridValidRowModel as RowModel } from '@mui/x-data-grid-premium';

type BuildUpdateParams = {
  updated: RowModel;
  oldRow: RowModel;
  hasChangeValidator?: (newValue: any, oldValue: any) => boolean;
};

export function checkRowChanges({
  updated,
  oldRow,
  hasChangeValidator,
}: BuildUpdateParams) {
  const hasChanges = Object.keys(updated).some((key) => {
    if (hasChangeValidator) {
      return hasChangeValidator(updated[key], oldRow[key]);
    }

    return updated[key] !== oldRow[key];
  });

  return { hasChanges, fields: updated };
}
