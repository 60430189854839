import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiAutocomplete({
  palette,
}: ThemeConfig): Components['MuiAutocomplete'] {
  return {
    styleOverrides: {
      option: {
        borderRadius: '6px',
        padding: '4px 8px !important',
        transition: 'all 0.3s ease-in-out',
        overflow: 'unset !important',

        [`&[aria-selected="true"]`]: {
          backgroundColor: `${palette.action?.selected} !important`,
        },
      },
      listbox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        maxHeight: '280px',
        padding: '8px',
      },
      endAdornment: {
        top: 'unset',
      },
    },
  };
}
