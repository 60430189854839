import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function CloseIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon aria-label="close" viewBox={viewBox} fontSize={fontSize} {...rest}>
      <path
        d="M18 6L6 18M6 6L18 18"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
