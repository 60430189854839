import cn from 'classnames';
import { Stack } from '@mui/material';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { AlertHexagonIcon, BuildingIcon } from 'src/mui/_icons';

import type { PixKey } from 'src/libs/finbits/Bank/Pix/types';
import { format } from 'src/libs/finbits/Date';
import { contactTypeDictionary } from 'src/libs/finbits/Organization/Companies/Contacts/translations';

import styles from './PixKeyAlert.module.scss';

type Props = {
  pixKey?: PixKey;
};

export default function PixKeyAlert({ pixKey }: Props) {
  if (!pixKey) return null;

  const matchesContact = Boolean(pixKey?.matchesContact);

  const alertClass = cn({
    [styles.noMatchContact]: !matchesContact,
  });

  return (
    <Alert
      className={alertClass}
      classes={{ message: styles.alertMessage }}
      severity="default"
      variant="filled"
      icon={
        matchesContact ? (
          <BuildingIcon fontSize="inherit" />
        ) : (
          <AlertHexagonIcon fontSize="inherit" />
        )
      }
    >
      <AlertTitle className={styles.alertTitle}>
        Para {pixKey?.pixKey.ownerName}
      </AlertTitle>

      <Stack gap={1}>
        <Typography component="p">
          {contactTypeDictionary[pixKey?.pixKey.ownerType]}:{' '}
          <b>{pixKey?.pixKey.ownerTaxIdText}</b>
        </Typography>
        <Typography component="p">
          Banco: <b>{pixKey.pixKey.bankName}</b>
        </Typography>
        <Typography component="p">
          Agência: <b>{pixKey.pixKey.branchNumber}</b> Conta:{' '}
          <b>{pixKey.pixKey.accountNumber}</b>
        </Typography>

        <Typography>
          Consulta feita em:{' '}
          <b>{format(pixKey?.pixKey.updatedAt!, 'dd/MM/yyyy')}</b>
        </Typography>
      </Stack>

      {!matchesContact && (
        <Alert icon={false} severity="warning" className={styles.noMatchAlert}>
          Estes dados não correspondem ao contato selecionado. Se prosseguir, a
          inclusão do pagamento será feito para a chave PIX informada.
        </Alert>
      )}
    </Alert>
  );
}
