import { Space, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { InfoBox, Mark, Popover } from 'src/ui';

import type { ImportedStatement } from 'src/libs/finbits/Bank/Statements/types';

import AccountInformation from './AccountInformation';

type Props = {
  importedStatement: ImportedStatement;
};

function NewTransactions({ importedStatement }: Props) {
  return (
    <Space direction="vertical" size="large">
      <Typography.Text>
        Tudo certo! 🎉 Importamos o conteúdo da conta{' '}
        <b>
          {importedStatement.name} (
          <AccountInformation importedStatement={importedStatement} />)
        </b>
        :
      </Typography.Text>
      <div>
        <InfoBox icon={<CheckOutlined />}>
          Lançamentos:{' '}
          <b data-testid="transactions">
            {importedStatement.transactions.length}
          </b>
        </InfoBox>

        {importedStatement?.ignoredRecords?.length > 0 ? (
          <InfoBox icon={<CloseOutlined />}>
            <Popover
              placement="bottomLeft"
              title={
                <Typography.Text strong>Registros ignorados 🔦</Typography.Text>
              }
              content={
                'O Finbits usa inteligência para descartar ' +
                'registros sem relevância para a gestão ' +
                'financeira.'
              }
            >
              <Mark>Registros ignorados:</Mark>
            </Popover>{' '}
            <b data-testid="ignored">
              {importedStatement.ignoredRecords.length}
            </b>
          </InfoBox>
        ) : null}
      </div>
    </Space>
  );
}

export default NewTransactions;
