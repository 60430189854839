import type { ForwardRefExoticComponent } from 'react';

import type { TextFieldProps } from '@mui/material';

import TextFieldPassword from './TextFieldPassword';
import TextFieldMasked from './TextFieldMasked';
import TextFieldBase from './TextFieldBase';

type TextFieldCompounded = {
  Password: typeof TextFieldPassword;
  Masked: typeof TextFieldMasked;
} & ForwardRefExoticComponent<TextFieldProps>;

export const TextField = {
  ...TextFieldBase,
  Password: TextFieldPassword,
  Masked: TextFieldMasked,
} as TextFieldCompounded;
