import type { GridActionsColDef } from '@mui/x-data-grid-premium';

type Props = {
  getActions: GridActionsColDef['getActions'];
};

export function actionColumn({ getActions }: Props): GridActionsColDef {
  return {
    field: 'Ações',
    type: 'actions',
    editable: false,
    getActions,
    align: 'right',
    resizable: false,
    hideable: false,
    maxWidth: 50,
    flex: 1,
    groupable: false,
    aggregable: false,
    disableExport: true,
  };
}
