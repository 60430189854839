import { Form } from 'antd';
import compact from 'lodash/compact';

import { FormItem, SelectAddButton, TreeSelect } from 'src/ui';

import {
  filterAvailable,
  filterLabelsIdsByClassification,
} from 'src/libs/finbits/Classifications';
import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { Label } from 'src/libs/finbits/Labels/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

type Props = {
  name: string | string[];
  disabled: boolean;
  selectedLabels?: Label[];
  classifications: Classification[];
  onOpenCreateLabel?: (classification: Classification) => void;
};

export default function LabelSelect({
  name = 'classifications',
  disabled,
  selectedLabels = [],
  classifications,
  onOpenCreateLabel,
}: Props) {
  const hasPermission = useAllowedPermission({
    action: 'create',
    resource: 'labels',
  });

  return (
    <Form.List name={name}>
      {() =>
        filterAvailable(classifications, selectedLabels).map(
          (classification, index) => {
            const isActive = classification.active;

            const selectedLabelsIds = filterLabelsIdsByClassification(
              selectedLabels,
              classification
            );

            const treeData = buildLabelOption(
              classification.labels,
              selectedLabelsIds
            );

            return (
              <div key={classification.id}>
                <FormItem name={[index, 'classificationId']} noStyle>
                  <input hidden />
                </FormItem>

                <FormItem
                  name={[index, 'labelsIds']}
                  label={classification.name}
                  hasMax={false}
                >
                  <TreeSelect
                    getPopupContainer={(trigger) => trigger.parentElement}
                    aria-label={classification.name}
                    dropdownRender={(menu) => {
                      if (!isActive) {
                        return <></>;
                      }

                      return (
                        <SelectAddButton
                          showButton={!!onOpenCreateLabel && hasPermission}
                          label="+ Adicionar Etiqueta"
                          onClick={() =>
                            onOpenCreateLabel &&
                            onOpenCreateLabel(classification)
                          }
                        >
                          {menu}
                        </SelectAddButton>
                      );
                    }}
                    multiple
                    showCheckedStrategy="SHOW_ALL"
                    treeDefaultExpandAll
                    placeholder="Selecione uma etiqueta"
                    treeNodeFilterProp="name"
                    treeData={treeData}
                    fieldNames={{
                      label: 'name',
                      value: 'id',
                      children: 'children',
                    }}
                    disabled={disabled}
                  />
                </FormItem>
              </div>
            );
          }
        )
      }
    </Form.List>
  );
}

function isRelated(parent: Label, childrenIds?: string[]): boolean {
  const parentIsChild = childrenIds?.some((id) => id === parent.id) ?? false;

  return (
    parent?.children?.some((parentChildLabel) => {
      return (
        childrenIds?.some((id) => id === parentChildLabel.id) ||
        isRelated(parentChildLabel, childrenIds)
      );
    }) || parentIsChild
  );
}

function buildLabelOption(
  labels: Label[] = [],
  selectedLabelIds: string[] = []
): Label[] {
  return compact(
    labels?.map((label) => {
      const inactiveLabel = !label.active;

      const selected = selectedLabelIds?.some((id) => id === label.id) ?? false;

      if (!isRelated(label, selectedLabelIds) && inactiveLabel)
        return undefined;

      return {
        ...label,
        children: buildLabelOption(label.children, selectedLabelIds),
        disabled: !selected && inactiveLabel,
      };
    })
  );
}
