import { Switch, Tooltip } from 'antd';

import { snackbar } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Label } from 'src/libs/finbits/Labels/types';
import { useActiveLabel, useDeactiveLabel } from 'src/libs/finbits/Labels';

type Props = {
  label: Label;
};

export default function ActiveLabelItemSwitch({ label }: Props) {
  const active = label.active;

  const parentLabelDeactive =
    label.parentLabel !== null && !label.parentLabel?.active;

  const { companyId, organizationId } = useCompanyParams();

  const { activeLabel, isLoading: isActivating } = useActiveLabel();

  const { deactiveLabel, isLoading: isDeactivating } = useDeactiveLabel();

  function handleToogle(checked: boolean) {
    if (checked) {
      activeLabel(
        {
          companyId,
          organizationId,
          labelId: label.id,
          classificationId: label.classificationId,
        },
        {
          onSuccess: () => {
            snackbar({ variant: 'success', message: 'Etiqueta ativada' });
          },
          onError: () => {
            snackbar({ variant: 'error', message: 'Erro ao ativar etiqueta' });
          },
        }
      );
    } else {
      deactiveLabel(
        {
          companyId,
          organizationId,
          labelId: label.id,
          classificationId: label.classificationId,
        },
        {
          onSuccess: () => {
            snackbar({ variant: 'success', message: 'Etiqueta desativada' });
          },
          onError: () => {
            snackbar({
              variant: 'error',
              message: 'Erro ao desativar etiqueta',
            });
          },
        }
      );
    }
  }

  return (
    <WithAllowedPermission
      permissions={{
        resource: 'labels',
        action: 'update',
      }}
    >
      <Tooltip
        placement="right"
        title={`${active ? 'Desativar' : 'Ativar'} etiqueta`}
      >
        <Switch
          disabled={parentLabelDeactive}
          checked={active}
          onChange={handleToogle}
          aria-label={`${active ? 'Desativar' : 'Ativar'} etiqueta`}
          loading={isActivating || isDeactivating}
        />
      </Tooltip>
    </WithAllowedPermission>
  );
}
