import { common } from '@mui/material/colors';
import type { PaletteMode, PaletteOptions } from '@mui/material';

import {
  brand,
  error,
  grey,
  info,
  primary,
  secondary,
  success,
  warning,
} from './colors';

// TODO: Remover esse arquivo quando tivermos as cores corretas em colorSchemes
export function createPalette(mode: PaletteMode): PaletteOptions {
  const backgroundColor = common.white;
  const secondaryBackgroundColor = grey[100];
  const dividerColor = grey[200];
  const selectedColor = grey[100];
  const hoverColor = grey[100];

  return {
    mode,
    primary,
    secondary,
    error,
    info,
    success,
    warning,
    divider: dividerColor,
    grey,
    brand,
    action: {
      selected: selectedColor,
      hover: hoverColor,
    },
    common: { white: '#FFFFFF', black: '#000000' },
    background: {
      default: backgroundColor,
      paper: backgroundColor,
      secondary: secondaryBackgroundColor,
    },
    text: {
      primary: grey[900],
      secondary: grey[600],
      disabled: grey[900],
    },
    highlight: {
      primary: warning[100],
    },
  };
}
