import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiDivider({
  palette,
}: ThemeConfig): Components['MuiDivider'] {
  return {
    styleOverrides: { root: { borderColor: palette.grey?.[200] } },
  };
}
