import { useEffect, useState, useTransition } from 'react';
import type { SyntheticEvent } from 'react';

import { Tab, Tabs } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';

import useBillStatusFromUrl from './hooks/useBillStatusFromUrl';
import styles from './BillPageTabs.module.scss';

function a11yProps(index: number) {
  return {
    id: `bill-tabs-${index}`,
    'aria-controls': `bill-tabpanel-${index}`,
  };
}

export default function BillPageTabs() {
  const [, setSearchParams] = useSearchParams();
  const [, startTransition] = useTransition();

  const { status: urlActiveTab } = useBillStatusFromUrl();

  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  const [selectedTab, setSelectedTab] = useState<BillStatus>(urlActiveTab);

  useEffect(() => {
    setSelectedTab(urlActiveTab);
  }, [urlActiveTab]);

  function handleChangeTab(
    _event: SyntheticEvent<Element, Event>,
    tab: BillStatus
  ) {
    setSelectedTab(tab);

    startTransition(() => {
      setSearchParams({
        status: tab,
      });
    });
  }

  return (
    <Tabs
      className={styles.tabs}
      value={selectedTab}
      textColor="secondary"
      indicatorColor="secondary"
      onChange={handleChangeTab}
      aria-label="contas a pagar"
    >
      <Tab label="A pagar" {...a11yProps(0)} value={BillStatus.PENDING} />

      {isEnabledBillsApprovals && (
        <Tab
          label="Em aprovação"
          {...a11yProps(1)}
          value={BillStatus.WAITING_APPROVAL}
        />
      )}

      {isEnabledBillsApprovals && (
        <Tab label="Aprovados" {...a11yProps(2)} value={BillStatus.APPROVED} />
      )}

      <Tab
        label="Enviados p/ pgto"
        {...a11yProps(3)}
        value={BillStatus.WAITING_PAYMENT}
      />

      {isEnabledBillsApprovals && (
        <Tab label="Reprovados" {...a11yProps(4)} value={BillStatus.REPROVED} />
      )}
    </Tabs>
  );
}
