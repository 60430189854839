import { Button, Tooltip } from 'antd';
import { UndoOutlined } from '@ant-design/icons';

type Props = {
  onClick: () => void;
};

export default function Undo({ onClick }: Props) {
  return (
    <Tooltip title="Restaurar" placement="top">
      <Button style={{ border: '0px' }} onClick={onClick}>
        <UndoOutlined />
      </Button>
    </Tooltip>
  );
}
