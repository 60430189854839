import type { Ref } from 'react';
import { forwardRef } from 'react';

import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { BalanceTypeDictionary } from 'src/libs/finbits/Organization/Companies/Balances/translations';

function AmountTypeSelect(
  {
    ...props
  }: Omit<
    SelectProps<BalanceType, false, true, false>,
    'options' | 'defaultValue'
  >,
  ref: Ref<HTMLDivElement>
) {
  return (
    <Select
      aria-label="amount-type"
      {...props}
      {...ref}
      disableClearable
      options={Object.values(BalanceType)}
      getOptionLabel={(option: BalanceType) => BalanceTypeDictionary[option]}
      isOptionEqualToValue={(option, value) => option === value}
    />
  );
}

export default forwardRef(AmountTypeSelect);
