import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiBadge({
  palette,
}: ThemeConfig): Components['MuiBadge'] {
  return {
    styleOverrides: {
      colorSecondary: {
        backgroundColor: palette?.grey?.[100],
        color: palette?.grey?.[700],
      },
    },
  };
}
