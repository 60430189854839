import { Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import type { ApprovalRule } from 'src/libs/finbits/ApprovalRules/types';
import { ApprovalRuleConditionField } from 'src/libs/finbits/ApprovalRules/types';

import AmountConditions from './components/AmountConditions';
import LabelsConditions from './components/LabelsConditions';
import CategoryConditions from './components/CategoryConditions';

type Props = {
  conditions: ApprovalRule['conditions'];
};

const ConditionComponent = {
  [ApprovalRuleConditionField.LABEL]: LabelsConditions,
  [ApprovalRuleConditionField.AMOUNT]: AmountConditions,
  [ApprovalRuleConditionField.CATEGORY]: CategoryConditions,
};

export default function Conditions({ conditions }: Props) {
  if (conditions.length === 0) {
    return <Typography>Se nenhuma outra regra é aplicada</Typography>;
  }

  return (
    <Stack>
      {conditions.map((condition, index) => {
        const Component = ConditionComponent[condition.field];

        return <Component key={index} condition={condition} />;
      })}
    </Stack>
  );
}
