import type { Ref } from 'react';
import { forwardRef } from 'react';

import MChip from '@mui/material/Chip';

import type { Props } from './type';

export function Chip({ square, ...props }: Props, ref: Ref<HTMLDivElement>) {
  return <MChip square={square} {...props} ref={ref} />;
}

export default forwardRef<HTMLInputElement, Props>(Chip);
