import { useMemo } from 'react';

import { Space } from 'antd';

import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillApprovalStatus } from 'src/libs/finbits/Bills/types';
import { useProfile } from 'src/libs/finbits/Organization/Users';
import { getAssigneeStatus } from 'src/libs/finbits/Bills';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';

import ApprovedButton from 'src/features/approval/ApprovedButton';
import ReprovedButton from 'src/features/approval/ReprovedButton';

type Props = {
  bill?: BillPayable;
  onSuccess: () => void;
};

export default function ApprovalHeaderButtons({ bill, onSuccess }: Props) {
  const { user } = useProfile();

  const hasPendingApproval = useMemo(() => {
    if (!user || !bill?.lastApproval?.assignments) return false;

    const statusFromAssignments = getAssigneeStatus(
      user.id,
      bill.lastApproval.assignments,
      bill.status
    );

    return statusFromAssignments === BillApprovalStatus.PENDING;
  }, [user, bill]);

  if (!bill || !hasPendingApproval) {
    return null;
  }

  const isApproveAvailable = canPerformAction(
    bill,
    AvailableActions.SEND_TO_APPROVED
  );
  const isRepproveAvailable = canPerformAction(
    bill,
    AvailableActions.SEND_TO_REPROVED
  );

  return (
    <Space direction="horizontal">
      {isApproveAvailable && (
        <ApprovedButton selectedBills={[bill]} onSuccess={onSuccess} />
      )}
      {isRepproveAvailable && (
        <ReprovedButton selectedBills={[bill]} onSuccess={onSuccess} />
      )}
    </Space>
  );
}
