import type { SelectProps } from 'antd';
import { Select } from 'antd';

import { snackbar } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  useProfile,
  useUpdateUserCompanyPermission,
} from 'src/libs/finbits/Organization/Users';
import type { UserWithPermissions } from 'src/libs/finbits/Organization/Users/types';
import { UserStatus } from 'src/libs/finbits/Organization/Users/types';
import {
  CompanyRole,
  OrganizationRole,
  companyRoleDictionary,
  getCompanyPermissionFromUser,
  useAllowedPermission,
} from 'src/libs/finbits/Permissions';

import styles from './RoleSelect.module.less';

type Props = {
  user: UserWithPermissions;
  companyId: string;
} & SelectProps;

export default function RoleSelect({ user, companyId, ...props }: Props) {
  const { organizationId } = useCompanyParams();

  const { user: currentUser } = useProfile();

  const canUpdateUserCompanyPermissions = useAllowedPermission({
    action: 'update',
    resource: 'userCompanyPermissions',
    companyId,
  });

  const { updateUserCompanyPermission, isLoading } =
    useUpdateUserCompanyPermission();

  function handleUpdateUserCompanyPermission(value: CompanyRole) {
    updateUserCompanyPermission(
      {
        companyId,
        organizationId,
        role: Boolean(value) ? value : null,
        userId: user.id,
      },
      {
        onSuccess: () => {
          snackbar({ variant: 'success', message: 'Permissões atualizadas.' });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Ocorreu um erro ao atualizar as permissões. Tente novamente dentro de alguns instantes.',
          });
        },
      }
    );
  }

  const roleFromUser =
    getCompanyPermissionFromUser(user, companyId)?.role ?? '';

  const roles = Object.values(CompanyRole).map((companyRole) => ({
    value: companyRole,
    label: companyRoleDictionary[companyRole],
  }));

  const isCurrentUser = currentUser?.id === user.id;
  const isInactive = user.status === UserStatus.INACTIVE;
  const isAdmin = user.organizationPermission?.role === OrganizationRole.ADMIN;

  return (
    <Select
      className={styles.select}
      value={roleFromUser}
      disabled={
        isCurrentUser ||
        isAdmin ||
        isInactive ||
        isLoading ||
        !canUpdateUserCompanyPermissions
      }
      loading={isLoading}
      onChange={handleUpdateUserCompanyPermission}
      {...props}
    >
      {roles.map((companyRole) => (
        <Select.Option key={companyRole.value} value={companyRole.value}>
          {companyRole.label}
        </Select.Option>
      ))}
      <Select.Option value="">Sem permissão</Select.Option>
    </Select>
  );
}
