import { Box, Stack } from '@mui/material';
import { BankIcon } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';

import type { SummaryAccount } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { convertToSignedNumber, toCurrency } from 'src/libs/finbits/Money';
import { format } from 'src/libs/finbits/Date';

type Props = {
  account: SummaryAccount;
};

export default function BalanceInconsistencyItem({ account }: Props) {
  const {
    finalBalance,
    inconsistency,
    account: { name, routingNumber, accountNumber, synchronizedAt },
  } = account;

  const finbitsBalance = convertToSignedNumber(
    finalBalance.amount,
    finalBalance.type
  );

  const inconsistencyValue = convertToSignedNumber(
    inconsistency.amount,
    inconsistency.type
  );

  const bankBalance = finbitsBalance - inconsistencyValue;

  return (
    <Stack direction="column" gap={4} width="100%">
      <Stack direction="row" alignItems="center" gap={2}>
        <BankIcon routingNumber={routingNumber} width={24} height={24} />
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="row" alignItems="flex-end" gap={3} width="50%">
            <Typography fontWeight={600} ellipsis>
              {name}
            </Typography>
            <Typography color="text.secondary" variant="text-xs">
              {accountNumber}
            </Typography>
          </Stack>
          <Typography color="text.secondary" variant="text-xs">
            Última atualização:{' '}
            {format(synchronizedAt!, "dd/MM/yyyy 'às' HH:mm")}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={8}>
          <Box>
            <Typography variant="text-xs">Saldo no banco</Typography>
            <Typography fontWeight={600}>{toCurrency(bankBalance)}</Typography>
          </Box>
          <Box>
            <Typography variant="text-xs">Saldo na Finbits</Typography>
            <Typography fontWeight={600}>
              {toCurrency(finbitsBalance)}
            </Typography>
          </Box>
        </Stack>

        <Box textAlign="right">
          <Typography variant="text-xs">Diferença</Typography>
          <Typography fontWeight={600}>
            {toCurrency(inconsistency.amount, inconsistency.type)}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
