import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function LockIcon({
  viewBox = '0 0 20 20',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon aria-label="lock" viewBox={viewBox} fontSize={fontSize} {...rest}>
      <path
        d="M14.1667 9.16667V6.66667C14.1667 4.36548 12.3012 2.5 10 2.5C7.69882 2.5 5.83334 4.36548 5.83334 6.66667V9.16667M7.33334 17.5H12.6667C14.0668 17.5 14.7669 17.5 15.3017 17.2275C15.7721 16.9878 16.1545 16.6054 16.3942 16.135C16.6667 15.6002 16.6667 14.9001 16.6667 13.5V13.1667C16.6667 11.7665 16.6667 11.0665 16.3942 10.5317C16.1545 10.0613 15.7721 9.67883 15.3017 9.43915C14.7669 9.16667 14.0668 9.16667 12.6667 9.16667H7.33334C5.93321 9.16667 5.23315 9.16667 4.69837 9.43915C4.22796 9.67883 3.84551 10.0613 3.60583 10.5317C3.33334 11.0665 3.33334 11.7665 3.33334 13.1667V13.5C3.33334 14.9001 3.33334 15.6002 3.60583 16.135C3.84551 16.6054 4.22796 16.9878 4.69837 17.2275C5.23315 17.5 5.93321 17.5 7.33334 17.5Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
