import t from 'zod';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';

export const cnpj = t
  .string()
  .refine((val) => val.length > 11 && cnpjValidator.isValid(val), {
    message: 'CNPJ inválido',
  });

export const cpf = t
  .string()
  .refine((val) => val.length >= 11 && cpfValidator.isValid(val), {
    message: 'CPF inválido',
  });
