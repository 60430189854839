import VMasker from 'vanilla-masker';

export function masker(
  value: string | null,
  patterns: string | string[]
): string {
  if (!value) return '';

  if (typeof patterns === 'string') {
    return VMasker.toPattern(value, patterns);
  }

  return VMasker.toPattern(
    value,
    patterns.reduce(
      (memo: string, pattern: string) =>
        value.length <= unMask(memo).length ? memo : pattern,
      patterns[0]
    )
  );
}

export function unMask(value: string | null): string {
  if (!value) return '';

  return value.replace(/\W/g, '');
}

export function isMaskFilled(patterns: string[], value: string) {
  const maskLength = Math.max(...patterns.map((pattern) => pattern.length));

  return value.length > maskLength;
}
