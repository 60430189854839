import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { RcFile } from 'rc-upload/lib/interface';

import { snackbar } from 'src/mui';

import { Modal, Title } from 'src/ui';

import { useImportContacts } from 'src/libs/finbits/Organization/Companies/Contacts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import ResultContent from './ResultContent';
import UploadContent from './UploadContent';

type Props = {
  visible: boolean;
  onClose: () => void;
};

export default function ImportContactsModal({ visible, onClose }: Props) {
  const {
    importContacts,
    data: importResponse,
    isLoading: isUploading,
  } = useImportContacts();
  const { companyId, organizationId } = useCompanyParams();

  function importRequest(file: RcFile) {
    if (!organizationId || !companyId) return;

    const requestParams = { organizationId, companyId, file };

    importContacts(requestParams, {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          message: 'Planilha importada com sucesso.',
        });
      },
      onError: ({ response }) => {
        const errors = response?.data.errors;

        snackbar({
          variant: 'error',
          title: 'Erro na importação',
          message: errors?.file,
          alignItems: 'start',
        });
      },
    });
  }

  const isUploaded = importResponse !== undefined;

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={onClose}
      footer={
        isUploaded && (
          <Button type="primary" onClick={onClose}>
            Ok
          </Button>
        )
      }
      title={
        isUploaded ? (
          <Title level={4}>Importação concluída</Title>
        ) : (
          <>
            <Title level={4} icon={<UploadOutlined />}>
              Importar planilha de contatos
            </Title>
          </>
        )
      }
    >
      {isUploaded ? (
        <ResultContent importResponse={importResponse} />
      ) : (
        <UploadContent
          importRequest={importRequest}
          isUploading={isUploading}
        />
      )}
    </Modal>
  );
}
