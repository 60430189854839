import { Avatar, Collapse, Space, Typography } from 'antd';

import { Drawer, DrawerHeader, Title } from 'src/ui';

import {
  SpreadsheetTab,
  SpreadsheetTabDictionary,
} from 'src/libs/finbits/Spreadsheet/types';

import type { ScheduledEntryDrawerErrors } from 'src/features/entries/Modals/ImportScheduledEntryModal/types';

import ErrorList from './ErrorList';
import styles from './ImportScheduledEntryDrawer.module.less';

const { Panel } = Collapse;

type Props = {
  errors: ScheduledEntryDrawerErrors;
  onClose: () => void;
};

function ImportScheduledEntryDrawer({ errors, onClose }: Props) {
  const tabs = Object.values(SpreadsheetTab).filter(
    (tab) => errors[tab].length > 0
  );

  function amountErrors(tab: SpreadsheetTab): number {
    return errors[tab].reduce(
      (acc, { errors }) => (acc += Object.keys(errors).length),
      0
    );
  }

  return (
    <Drawer
      title={
        <DrawerHeader title={<Title>Revisar erros de importação</Title>} />
      }
      onClose={onClose}
      visible
      destroyOnClose
    >
      <Space direction="vertical">
        <div>
          <Typography.Paragraph>
            Alguns erros foram encontrados. Para concluir a importação, corrija
            ou exclua a(s) linha(s) indicada(s) na planilha. Por fim, faça o
            upload do arquivo corrigido.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Atenção: contatos e categorias devem estar previamente cadastrados
            na Finbits.
          </Typography.Paragraph>
        </div>

        <Collapse
          bordered={false}
          ghost
          defaultActiveKey={tabs}
          className={styles.collapse}
          expandIconPosition="end"
        >
          {tabs.map((tab) => (
            <Panel
              header={
                <Space>
                  <Title>{SpreadsheetTabDictionary[tab]}</Title>
                  <Avatar size={24}>{amountErrors(tab)}</Avatar>
                </Space>
              }
              key={tab}
            >
              <ErrorList errors={errors} tab={tab} />
            </Panel>
          ))}
        </Collapse>
      </Space>
    </Drawer>
  );
}

export default ImportScheduledEntryDrawer;
