import { Tooltip, Typography } from 'antd';

type Props = {
  label: string;
  value?: string | null;
};

export default function Association({ label, value }: Props) {
  return (
    <Tooltip placement="topLeft" title={value}>
      <Typography.Text type={value ? undefined : 'secondary'}>
        {value ? value : `+ ${label}`}
      </Typography.Text>
    </Tooltip>
  );
}
