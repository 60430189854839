import { Autocomplete as MUIAutocomplete } from '@mui/material';

import AutocompleteSearch from './AutocompleteSearch';
import AutocompleteSelect from './AutocompleteSelect';

type AutocompleteCompounded = {
  Search: typeof AutocompleteSearch;
  Select: typeof AutocompleteSelect;
} & typeof MUIAutocomplete;

export const Autocomplete = {
  ...MUIAutocomplete,
  Search: AutocompleteSearch,
  Select: AutocompleteSelect,
} as AutocompleteCompounded;
