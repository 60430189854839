import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Permissions } from 'src/libs/finbits/Permissions/types';

export function useMenuItem({
  menuItem,
  permissions,
  enabled = true,
}: {
  menuItem: ItemType & { 'aria-label'?: string };
  permissions?: Permissions | Permissions[];
  enabled?: boolean;
}) {
  const havePermission = useAllowedPermission(permissions);

  if (!enabled || (!havePermission && Boolean(permissions))) return undefined;

  return menuItem;
}
