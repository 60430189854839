import { Button, Col, Form, Row, Switch } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { AmountInput, FormItem, Popover, RateInput } from 'src/ui';

import { greaterThanZeroValidator } from 'src/libs/finbits/Amount';
import {
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';

import TaxesCalculator from '../TaxesCalculator';
import type { Taxes } from '../types';
import { TAXES_DICTIONARY } from '../translations';

import ModalTaxes from './TaxesModal/TaxesModal';
import styles from './TaxesField.module.less';

export function equalOrLowerThanHundredValidator(
  _rule: any,
  value?: string | null
) {
  if (Number(value) <= 100 || !value) {
    return Promise.resolve(null);
  }

  return Promise.reject('Deve ser menor que 100');
}

type Props = {
  selectedTaxes?: Taxes[];
  onSelectedTaxesChange: (taxes: Taxes[]) => void;
};

export default function TaxesField({
  selectedTaxes = [],
  onSelectedTaxesChange,
}: Props) {
  const { organizationId, companyId } = useCompanyParams();
  const { company } = useCompany({
    organizationId,
    companyId,
  });

  const issDisabled = Boolean(company?.simpleNational);

  function handleRemoveTax(tax: Taxes) {
    onSelectedTaxesChange(selectedTaxes.filter((t) => t !== tax));
  }

  return (
    <>
      <Row gutter={[30, 40]} className={styles.row}>
        <Col span={24}>
          <FormItem
            required
            hasMax={false}
            label="Valor da nota fiscal"
            name="amount"
            rules={[
              {
                validator: greaterThanZeroValidator,
              },
            ]}
          >
            <AmountInput placeholder="0,00" ariaLabel="Valor da nota fiscal" />
          </FormItem>
        </Col>
      </Row>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.amount !== curValues.amount
        }
      >
        {() => {
          return (
            <Row gutter={[16, 40]} className={styles.row}>
              <Col span={5}>
                <span className={styles.label}>ISS</span>
                <Popover
                  size="small"
                  placement="bottom"
                  className={styles.popover}
                  content="Esta alíquota é sugerida pela prefeitura de acordo com o serviço municipal selecionado. Caso precise, este campo pode ser editado."
                >
                  <InfoCircleOutlined />
                </Popover>
              </Col>

              <Col span={4}>
                <FormItem
                  required
                  hasMax={false}
                  name="issRate"
                  rules={[
                    {
                      validator: greaterThanZeroValidator,
                    },
                    {
                      validator: equalOrLowerThanHundredValidator,
                    },
                  ]}
                >
                  <RateInput
                    placeholder="0,00"
                    ariaLabel="porcentagem iss"
                    disabled={issDisabled}
                  />
                </FormItem>
              </Col>

              <Col span={7}>
                <FormItem
                  required
                  hasMax={false}
                  name="issAmount"
                  rules={[
                    {
                      validator: greaterThanZeroValidator,
                    },
                  ]}
                >
                  <AmountInput
                    placeholder="0,00"
                    ariaLabel="valor iss"
                    disabled={issDisabled}
                  />
                </FormItem>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="issWithheldAtSource"
                  label="Reter na fonte?"
                  className={styles.issSwitch}
                  valuePropName="checked"
                >
                  <Switch disabled={issDisabled} />
                </Form.Item>
              </Col>
            </Row>
          );
        }}
      </Form.Item>
      {selectedTaxes.map((tax) => (
        <Row gutter={[16, 40]} key={tax} className={styles.row}>
          <Col span={5}>
            <span className={styles.label}>{TAXES_DICTIONARY[tax]}</span>

            <Popover
              size="small"
              placement="bottom"
              className={styles.popover}
              content="Esta alíquota é a padrão no seu município. Caso precise, este campo pode ser editado."
            >
              <InfoCircleOutlined />
            </Popover>
          </Col>

          <Col span={4}>
            <FormItem
              required
              hasMax={false}
              name={`${tax}Rate`}
              rules={[
                {
                  validator: greaterThanZeroValidator,
                },
                {
                  validator: equalOrLowerThanHundredValidator,
                },
              ]}
            >
              <RateInput
                placeholder="0,00"
                ariaLabel="porcentagem imposto federal"
              />
            </FormItem>
          </Col>

          <Col span={7}>
            <FormItem
              required
              hasMax={false}
              name={`${tax}Amount`}
              rules={[
                {
                  validator: greaterThanZeroValidator,
                },
              ]}
            >
              <AmountInput
                placeholder="0,00"
                ariaLabel="valor imposto federal"
              />
            </FormItem>
          </Col>

          <Col span={2}>
            <Button
              aria-label="deletar imposto federal"
              size="large"
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveTax(tax)}
            />
          </Col>

          <Col span={6}>
            <span className={styles.withheldAtSource}>
              (Imposto retido na fonte)
            </span>
          </Col>
        </Row>
      ))}
      <ModalTaxes taxes={selectedTaxes} onChangeTaxes={onSelectedTaxesChange} />
      <TaxesCalculator selectedTaxes={selectedTaxes} />
    </>
  );
}
