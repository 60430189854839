import { useState } from 'react';

import uniqBy from 'lodash/uniqBy';
import type { FormInstance } from 'antd';
import { Upload } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/lib/upload/interface';
import type {
  RcFile,
  UploadRequestOption as UploadParams,
} from 'rc-upload/lib/interface';

import { snackbar } from 'src/mui';

import { useUploadFile } from 'src/libs/finbits/Management/Attachments';
import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

import PreviewAttachment from 'src/features/preview/PreviewAttachment';

import ActionButtons from './ActionButtons';
import InboxDrawer from './InboxDrawer';
import { validateBeforeUpload } from './Validators';
import { attachmentsToUploadFiles } from './AttachmentsFunctions';
import styles from './UploadAttachments.module.less';

export type UploadAttachmentsProps = {
  fieldName?: string;
  form: FormInstance;
  organizationId?: string;
  companyId?: string;
  disabled?: boolean;
  onChange?: (attachments: Attachment[]) => void;
};

export default function UploadAttachments({
  fieldName = 'attachments',
  form,
  organizationId,
  companyId,
  onChange,
  disabled = false,
}: UploadAttachmentsProps) {
  const [previewAttachment, setPreviewAttachment] = useState<Attachment>();

  const [isInboxDrawerOpen, setIsInboxDrawerOpen] = useState(false);

  const { uploadFile, isLoading: isUploading } = useUploadFile();

  const attachments = form.getFieldValue(fieldName) ?? [];
  const fileList = attachmentsToUploadFiles(attachments);

  function openInboxDrawer() {
    return setIsInboxDrawerOpen(true);
  }

  function handleInboxSubmit(selectedAttachments: Attachment[]) {
    onChange &&
      onChange(uniqBy([...attachments, ...selectedAttachments], 'id'));
  }

  function handleOnChange(file: UploadFile, attachments: Attachment[]) {
    if (file.status === 'removed' && onChange) {
      return onChange(attachments.filter((a) => a.id !== file.uid));
    }
  }

  function handlePreview(file: UploadFile<Attachment>) {
    return setPreviewAttachment(file as any);
  }

  function uploadRequest({ file }: UploadParams) {
    const requestParams = { organizationId, companyId, file: file as RcFile };

    uploadFile(requestParams, {
      onSuccess: (attachment: Attachment) => {
        onChange && onChange([...attachments, attachment]);

        snackbar({ variant: 'success', message: 'Arquivo adicionado!' });
      },
      onError: () => {
        snackbar({ variant: 'error', message: 'Falha ao adicionar arquivo!' });
      },
    });
  }

  return (
    <>
      {isInboxDrawerOpen && (
        <InboxDrawer
          organizationId={organizationId}
          companyId={companyId}
          onClose={() => setIsInboxDrawerOpen(false)}
          onSubmit={handleInboxSubmit}
          unavailableAttachments={attachments}
        />
      )}

      {previewAttachment && (
        <PreviewAttachment
          attachments={[previewAttachment]}
          onClose={() => setPreviewAttachment(undefined)}
        />
      )}

      <Upload
        data-testid="file-upload"
        listType="picture"
        fileList={fileList}
        className={styles.upload}
        disabled={disabled || isUploading}
        customRequest={uploadRequest}
        onPreview={handlePreview}
        onChange={({ file }) => handleOnChange(file, attachments)}
        showUploadList={{
          removeIcon: <DeleteOutlined />,
        }}
        beforeUpload={(file: RcFile) => validateBeforeUpload(file, attachments)}
      >
        <ActionButtons
          loading={isUploading}
          disabled={disabled || isUploading}
          onInboxClick={openInboxDrawer}
        />
      </Upload>
    </>
  );
}
