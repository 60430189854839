import { ApprovalRuleConditionField, ApprovalRuleConditionType } from './types';

export const APPROVAL_RULE_CONDITION_TYPE_DICTIONARY = {
  [ApprovalRuleConditionType.ONE_OF]: 'contém',
  [ApprovalRuleConditionType.GREATER_THAN_OR_EQUAL_TO]: 'é maior ou igual a',
  [ApprovalRuleConditionType.LESS_THAN_OR_EQUAL_TO]: 'é menor ou igual a',
};

export const APPROVAL_RULE_CONDITION_FIELD_DICTIONARY = {
  [ApprovalRuleConditionField.AMOUNT]: 'valor',
  [ApprovalRuleConditionField.LABEL]: 'label',
  [ApprovalRuleConditionField.CATEGORY]: 'categoria',
};
