import { useState } from 'react';

import type { FormInstance } from 'antd';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import type {
  CompanyService,
  Service,
} from 'src/libs/finbits/NotaFiscal/Services/types';
import {
  getServiceId,
  isCompanyService,
} from 'src/libs/finbits/NotaFiscal/Services';

import ServicesDrawer from 'src/features/nota-fiscal/ServicesDrawer';

import ServiceCard from '../ServiceCard';

import styles from './CompanyServicesList.module.less';

type Props = {
  city?: string | null;
  form: FormInstance;
  hasMainActivity: boolean;
  companyServices: CompanyService[];
  onConfirm: () => void;
};

export default function CompanyServicesList({
  city,
  form,
  companyServices,
  onConfirm,
}: Props) {
  const [isServicesDrawerVisible, setIsServicesDrawerVisible] = useState(false);

  const hasDisabledService = companyServices.some((service) => !service.active);

  function handleOnChangeMainActivity(serviceId: string) {
    form.setFieldsValue({
      services: companyServices.map((companyService) => ({
        ...companyService,
        mainActivity: companyService.municipalServiceId === serviceId,
      })),
    });
  }

  function handleOnDelete(serviceId: string) {
    const deletedService = companyServices.find(
      (service) => service.municipalServiceId === serviceId
    );

    const filteredServices = companyServices.filter(
      (service) => service.municipalServiceId !== serviceId
    );

    if (deletedService?.mainActivity) {
      const firstActiveService = filteredServices.find(
        (service) => service.active
      );

      form.setFieldsValue({
        services: filteredServices.map((service) => {
          const canBeMainActivity =
            service.municipalServiceId ===
            firstActiveService?.municipalServiceId;

          return {
            ...service,
            mainActivity: canBeMainActivity,
          };
        }),
      });
    } else {
      form.setFieldsValue({
        services: filteredServices,
      });
    }
  }

  function handleOnConfirm(services: Array<Service | CompanyService>) {
    setIsServicesDrawerVisible(false);

    const firstActiveService = services.find((service) => service.active);

    form.setFieldsValue({
      services: services.map((service) => {
        const alreadyIsMainActivity =
          isCompanyService(service) && service.mainActivity;

        const canBeMainActivity =
          getServiceId(firstActiveService) === getServiceId(service);

        return {
          municipalServiceId: getServiceId(service),
          code: service.code,
          subitemCode: service.subitemCode,
          active: service.active,
          description: service.description,
          suggestedRate: service.suggestedRate,
          mainActivity: alreadyIsMainActivity || canBeMainActivity,
        };
      }),
    });

    onConfirm();
  }

  return (
    <>
      {isServicesDrawerVisible && (
        <ServicesDrawer
          city={city}
          type="checkbox"
          companyServices={companyServices}
          initialSelectedServices={companyServices}
          onConfirm={handleOnConfirm}
          onClose={() => setIsServicesDrawerVisible(false)}
        />
      )}

      <Button
        block
        size="large"
        icon={<PlusOutlined />}
        aria-label="Selecionar serviço"
        danger={hasDisabledService}
        type={hasDisabledService ? 'default' : 'dashed'}
        onClick={() => setIsServicesDrawerVisible(true)}
      >
        {hasDisabledService
          ? 'Selecionar um serviço válido'
          : 'Selecionar serviço'}
      </Button>

      <ul className={styles.listWrapper}>
        {companyServices.map((service) => (
          <li key={service.municipalServiceId}>
            <ServiceCard
              service={service}
              showSuggestedRate
              onChangeMainActivity={handleOnChangeMainActivity}
              onDelete={handleOnDelete}
            />
          </li>
        ))}
      </ul>
    </>
  );
}
