import { Stack } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { Typography } from 'src/design-system/components';

import { pluralize } from 'src/libs/finbits/Pluralize';

import Pagination from './Pagination';

type FooterProps = {
  CustomFooter?: React.JSXElementConstructor<any>;
  hasPagination: boolean;
};

export default function Footer({ CustomFooter, hasPagination }: FooterProps) {
  const { current: apiRef } = useGridApiContext();

  const allRows = apiRef.getRowModels().size;
  const selectedRows = apiRef.getSelectedRows().size;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={6} alignItems="center">
        {selectedRows ? (
          <Typography variant="text-xs">
            {selectedRows}{' '}
            {pluralize(
              selectedRows,
              'lançamento selecionado',
              'lançamentos selecionados'
            )}
          </Typography>
        ) : (
          <Typography variant="text-xs">
            {allRows} {pluralize(allRows, 'lançamento', 'lançamentos')}
          </Typography>
        )}

        {CustomFooter && <CustomFooter />}
      </Stack>

      {hasPagination && <Pagination />}
    </Stack>
  );
}
