import { Input } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

import { DrawerHeader, Title } from 'src/ui';

import styles from './ServicesDrawerHeader.module.less';

type Props = {
  isSearching?: boolean;
  onSearch: (value: string) => void;
};

export default function ServicesDrawerHeader({
  isSearching = false,
  onSearch,
}: Props) {
  return (
    <>
      <DrawerHeader
        title={
          <Title icon={<UnorderedListOutlined />}>Serviços municipais</Title>
        }
      />
      <Input.Search
        size="large"
        className={styles.search}
        placeholder="Busque por palavra-chave, código, descrição"
        aria-label="Buscar por serviços"
        onSearch={onSearch}
        loading={isSearching}
      />
    </>
  );
}
