import MuiPagination from '@mui/material/Pagination';
import {
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';

import styles from './PaginationActions.module.scss';

export default function PaginationActions() {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { page } = apiRef.current.state.pagination.paginationModel;

  function onPageChange(
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    apiRef.current.setPage(newPage);
  }

  return (
    <MuiPagination
      color="primary"
      variant="outlined"
      shape="rounded"
      classes={{
        root: styles.paginationActionsRoot,
      }}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}
