import type { CircularProgressProps, IconButtonProps } from '@mui/material';
import { CircularProgress, IconButton } from '@mui/material';

type Props = {
  loading: boolean;
  LoadingIconProps?: CircularProgressProps;
} & IconButtonProps;

export default function IconButtonLoading({
  loading,
  children,
  disabled,
  LoadingIconProps,
  ...rest
}: Props) {
  return (
    <IconButton disabled={loading || disabled} {...rest}>
      {loading ? (
        <CircularProgress size={20} color="secondary" {...LoadingIconProps} />
      ) : (
        children
      )}
    </IconButton>
  );
}
