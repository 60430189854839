import { useMemo } from 'react';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

export function useInboxItemsAttachments(inboxItem: NewInboxItem | undefined) {
  const attachments = useMemo(() => {
    if (!inboxItem) return [];

    const attachmentsFromSource = {
      nota_fiscal: inboxItem.receivedNotaFiscal?.attachments ?? [],
      product_nota_fiscal:
        inboxItem.receivedProductNotaFiscal?.attachments ?? [],
      email: inboxItem.receivedEmail?.attachments ?? [],
      whatsapp: inboxItem.receivedWhatsapp?.attachments ?? [],
      purchase_order: inboxItem.purchaseOrder?.attachments ?? [],
      upload: inboxItem.upload?.attachments ?? [],
      dda: [],
    };

    return attachmentsFromSource[inboxItem.source];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxItem?.id]);

  const externalFiles = useMemo(() => {
    if (!inboxItem || inboxItem.source !== 'email') return [];

    return inboxItem.receivedEmail?.externalFiles;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxItem?.id]);

  return { attachments, externalFiles };
}
