import { Collapse, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { Drawer, DrawerHeader, Title } from 'src/ui';

import type { ImportContactResponse } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import ErrorList from './ErrorList';
import IgnoreList from './IgnoreList';
import styles from './ImportErrorDrawer.module.less';
import ContactPluralize from './ContactPluralize';

type props = {
  onClose: () => void;
  importResponse?: ImportContactResponse;
};

const { Panel } = Collapse;

export default function ImportErrorDrawer({ importResponse, onClose }: props) {
  if (!importResponse) return null;

  const failureCount = importResponse.failures.length;

  return (
    <Drawer
      title={
        <DrawerHeader title={<Title>Revisar erros de importação</Title>} />
      }
      footer={null}
      onClose={onClose}
      visible
    >
      {Boolean(failureCount) && (
        <div className={styles.text}>
          <p>
            Detectamos que <ContactPluralize amount={failureCount} /> da sua
            planilha não foram importados.
          </p>
          <p>
            Fique tranquilo pois só será necessário fazer a correção do(s)
            dado(s) abaixo e importar sua planilha novamente.
          </p>
        </div>
      )}
      <Collapse
        bordered={false}
        className={styles.collapse}
        ghost
        defaultActiveKey={['failures', 'ignoreds']}
      >
        {Boolean(importResponse.failures.length) && (
          <Panel header="Contatos com erros" key="failures">
            <ErrorList failures={importResponse.failures} />
          </Panel>
        )}

        {Boolean(importResponse.ignoredRows.length) && (
          <Panel
            header="Contatos duplicados"
            key="ignoreds"
            extra={
              <Tooltip
                placement="left"
                title="Contatos identificados como duplicados não são importados, pois já estão cadastrados na plataforma."
              >
                <InfoCircleOutlined />
              </Tooltip>
            }
          >
            <IgnoreList ignoredRows={importResponse.ignoredRows} />
          </Panel>
        )}
      </Collapse>
    </Drawer>
  );
}
