import cn from 'classnames';
import { Tooltip, Typography } from 'antd';

import { toDecimal } from 'src/libs/finbits/Money';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './Amount.module.less';

type Props = {
  amount: number;
  type: BalanceType;
  isGroup?: boolean;
};

export default function Amount({ amount, type, isGroup }: Props) {
  const isCredit = type === BalanceType.CREDIT;
  const value = toDecimal(amount, type);

  return (
    <Tooltip placement="top" title={value}>
      <Typography.Text
        className={cn([isGroup && styles.group])}
        type={isCredit ? 'success' : undefined}
        strong={isCredit}
      >
        {value}
      </Typography.Text>
    </Tooltip>
  );
}
