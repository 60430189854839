import type { ReactNode } from 'react';

import { Badge } from 'antd';

import styles from './BadgeIcon.module.less';

type Props = {
  hidden?: boolean;
  children?: ReactNode;
};

export default function BadgeIcon({ hidden = false, children }: Props) {
  return (
    <>
      {children}
      {!hidden && (
        <Badge
          dot
          className={styles.badge}
          status="error"
          data-testid="badge"
        />
      )}
    </>
  );
}
