import { useMatch } from 'react-router-dom';

import { getItem, setItem } from 'src/libs/finbits/Storage';

type CompanyParams = {
  organizationId: string;
  companyId: string;
};

const CURRENT_COMPANY_STORAGE = '_current_company_';

export function useOptionalCompanyParams(): Partial<CompanyParams> {
  const match = useMatch(
    '/organizations/:organizationId/companies/:companyId/*'
  );

  if (!match) {
    return {
      organizationId: undefined,
      companyId: undefined,
    };
  }

  return {
    companyId: match.params.companyId,
    organizationId: match.params.organizationId,
  };
}

export function useCompanyParams(): CompanyParams {
  const params = useOptionalCompanyParams();

  if (!params.organizationId)
    throw new Error('Missing :organizationId url param');
  if (!params.companyId) throw new Error('Missing :companyId url param');

  return params as CompanyParams;
}

export function setCompanyParamsOnStorage(params: CompanyParams) {
  setItem(CURRENT_COMPANY_STORAGE, params);
}

export function getCompanyParamsFromStorage(): Partial<CompanyParams> {
  return getItem(CURRENT_COMPANY_STORAGE, {
    companyId: undefined,
    organizationId: undefined,
  });
}
