type Props = {
  color?: string;
  className?: string;
};

function PaymentIcon({ color, className }: Props) {
  return (
    <span className="anticon" role="img" aria-label="Ícone de Pagamento">
      <svg
        color={color}
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g clipPath="url(#clip0_1673_66070)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4333 6.21328V5.33328C11.4324 5.30471 11.4372 5.27625 11.4475 5.24959C11.4579 5.22294 11.4734 5.19863 11.4933 5.1781C11.5132 5.15758 11.537 5.14127 11.5634 5.13013C11.5897 5.119 11.618 5.11327 11.6466 5.11328H12.4133C12.4704 5.11502 12.5247 5.13896 12.5645 5.18003C12.6043 5.2211 12.6266 5.27607 12.6266 5.33328V6.20661C14.4599 6.39328 15.8333 7.45995 15.9999 9.13328C16.0008 9.1633 15.9956 9.19319 15.9845 9.2211C15.9735 9.24902 15.9568 9.27438 15.9356 9.29562C15.9144 9.31685 15.889 9.33351 15.8611 9.34458C15.8332 9.35564 15.8033 9.36087 15.7733 9.35995H14.5666C14.5143 9.36036 14.4638 9.34158 14.4244 9.30717C14.3851 9.27276 14.3598 9.22512 14.3533 9.17328C14.2801 8.75346 14.067 8.37075 13.7487 8.08748C13.4303 7.80422 13.0254 7.63708 12.5999 7.61328V11.2133L13.2666 11.3733C15.0066 11.8199 16.1866 12.6333 16.1866 14.4866C16.1866 16.4866 14.6866 17.6333 12.5933 17.8199V18.6666C12.5941 18.6952 12.5893 18.7236 12.579 18.7503C12.5686 18.777 12.5531 18.8013 12.5332 18.8218C12.5133 18.8423 12.4895 18.8586 12.4631 18.8698C12.4368 18.8809 12.4085 18.8866 12.3799 18.8866H11.6199C11.5627 18.8849 11.5085 18.8609 11.4686 18.8199C11.4288 18.7788 11.4066 18.7238 11.4066 18.6666V17.8266C9.01992 17.6533 7.90658 16.2999 7.78658 14.8266C7.78379 14.797 7.78724 14.7671 7.7967 14.7388C7.80617 14.7106 7.82144 14.6846 7.84153 14.6627C7.86163 14.6407 7.8861 14.6232 7.91338 14.6112C7.94066 14.5993 7.97014 14.5932 7.99992 14.5933H9.24658C9.29608 14.5926 9.34417 14.6098 9.38209 14.6416C9.42002 14.6734 9.44527 14.7178 9.45325 14.7666C9.60658 15.6199 10.2733 16.2533 11.4533 16.4133V12.5866L10.7866 12.4199C9.38658 12.0666 8.05325 11.2133 8.05325 9.39995C8.05325 7.44661 9.53325 6.39995 11.4333 6.21328ZM11.0266 10.8133L11.4333 10.9466L11.4199 7.61995C10.4266 7.74661 9.69325 8.30661 9.69325 9.27328C9.69325 9.95328 10.1266 10.4866 11.0266 10.8133ZM12.8266 12.8999L12.5866 12.8399V16.4199C13.7266 16.3199 14.5199 15.6866 14.5199 14.6399C14.5199 13.8199 14.0933 13.2799 12.8266 12.8999Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.4266 3.97146e-10H20.5733C20.6859 -3.43629e-06 20.7974 0.0222876 20.9014 0.0655879C21.0053 0.108888 21.0997 0.172341 21.179 0.252288C21.2584 0.332235 21.3211 0.427094 21.3636 0.531396C21.4061 0.635698 21.4275 0.747379 21.4266 0.86V23.14C21.4275 23.2526 21.4061 23.3643 21.3636 23.4686C21.3211 23.5729 21.2584 23.6678 21.179 23.7477C21.0997 23.8277 21.0053 23.8911 20.9014 23.9344C20.7974 23.9777 20.6859 24 20.5733 24H3.4266C3.31398 24 3.20247 23.9777 3.0985 23.9344C2.99453 23.8911 2.90016 23.8277 2.82083 23.7477C2.74151 23.6678 2.67879 23.5729 2.6363 23.4686C2.59381 23.3643 2.57239 23.2526 2.57327 23.14V0.86C2.57239 0.747379 2.59381 0.635698 2.6363 0.531396C2.67879 0.427094 2.74151 0.332235 2.82083 0.252288C2.90016 0.172341 2.99453 0.108888 3.0985 0.0655879C3.20247 0.0222876 3.31398 -3.43629e-06 3.4266 3.97146e-10ZM4.49994 22.0733H19.4999V1.92667H4.49994V22.0733Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1673_66070">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default PaymentIcon;
