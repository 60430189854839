import type { GridRowParams } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import { useRowActions } from '../useRowActions';

export function RowActions({ row }: Pick<GridRowParams<BillPayable>, 'row'>) {
  const { rowActions } = useRowActions(row);

  const actions = rowActions();

  return actions.map(({ dividerAfter, ...action }) => (
    <GridActionsCellItem {...action} key={action.name} showInMenu />
  ));
}
