import { Button, Stack } from '@mui/material';
import { RefreshIcon } from 'src/mui/_icons';
import type { GridRowModel } from '@mui/x-data-grid-premium';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import { useOpenConfirmRestore } from 'src/features/entries/Dialogs';
import { AmountTotal } from 'src/features/entries/EntriesDataGrid/GridToolBar/ToolbarSelectionActions';

import { useRestoreEntry } from '../useRestoreEntry';

type Props = {
  selecteds: GridRowModel<FinancialStatementEntry>[];
};

export default function ToolbarSelectedActions({ selecteds }: Props) {
  const { handleRestoreSelection } = useRestoreEntry({ selecteds });
  const confirmRestoreEntry = useOpenConfirmRestore();

  function handleClick() {
    confirmRestoreEntry({
      onConfirm: handleRestoreSelection,
    });
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        alignItems="center"
        gap="32px"
      >
        <WithAllowedPermission
          permissions={{
            resource: 'entries',
            action: 'restore_many',
          }}
        >
          <Button
            variant="text"
            startIcon={<RefreshIcon />}
            onClick={handleClick}
          >
            Restaurar
          </Button>
        </WithAllowedPermission>
      </Stack>

      <AmountTotal selecteds={selecteds} />
    </>
  );
}
