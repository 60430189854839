import { forwardRef } from 'react';
import type { Ref } from 'react';

import { Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import type { ApprovalRule } from 'src/libs/finbits/ApprovalRules/types';
import { APPROVAL_TYPE_DICTIONARY } from 'src/libs/finbits/Bills/translation';

import AssigneeChip from 'src/features/assignee/AssigneeChip';

import PositionChip from './PositionChip';
import ChangePositionActions from './ChangePositionActions';
import Conditions from './Conditions';
import Actions from './Actions';
import Card from './Card';
import Table from './Table';

type Props = {
  position: number;
  approvalRule: ApprovalRule;
  isFirst?: boolean;
  isBeforeDefaultRule?: boolean;
  moveUp: () => void;
  moveDown: () => void;
  classNames?: string;
  hidePositionActions?: boolean;
  isDragDisabled?: boolean;
};

function ApprovalRuleCard(
  {
    approvalRule,
    isFirst = false,
    position,
    isBeforeDefaultRule,
    moveUp,
    moveDown,
    classNames,
    isDragDisabled = false,
    hidePositionActions = false,
  }: Props,
  ref: Ref<HTMLDivElement>
) {
  const hasApprovalType = Boolean(approvalRule.assignees.length > 1);

  return (
    <Card
      notDraggable={isDragDisabled}
      ariaLabel={approvalRule.description}
      classNames={classNames}
      ref={ref}
      title={
        <Stack direction="row" alignItems="center" gap={3}>
          {!hidePositionActions && (
            <ChangePositionActions
              approvalRule={approvalRule}
              isFirst={isFirst}
              isBeforeDefaultRule={isBeforeDefaultRule}
              moveUp={moveUp}
              moveDown={moveDown}
            />
          )}
          <PositionChip
            position={position}
            isFirst={isFirst}
            defaultRule={approvalRule.defaultRule}
          />
          <Typography fontWeight="600" color="grey.600" marginBottom="3px">
            {approvalRule.description}
          </Typography>
        </Stack>
      }
      action={<Actions approvalRule={approvalRule} />}
    >
      <Table
        headers={
          <>
            <Table.HeaderCell>Condições</Table.HeaderCell>
            <Table.HeaderCell>Aprovadores</Table.HeaderCell>
            {hasApprovalType && (
              <Table.HeaderCell>Aprovações necessárias</Table.HeaderCell>
            )}
          </>
        }
        data={
          <>
            <Table.DataCell>
              <Conditions conditions={approvalRule.conditions} />
            </Table.DataCell>

            <Table.DataCell>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                flexWrap="wrap"
              >
                {approvalRule.assignees.map((assignee) => (
                  <AssigneeChip
                    key={assignee.id}
                    assignee={assignee}
                    variant="outlined"
                    size="xsmall"
                  />
                ))}
              </Stack>
            </Table.DataCell>
            {hasApprovalType && (
              <Table.DataCell>
                {APPROVAL_TYPE_DICTIONARY[approvalRule.approvalType!]}
              </Table.DataCell>
            )}
          </>
        }
      />
    </Card>
  );
}

export default forwardRef(ApprovalRuleCard);
