import type { ComponentType } from 'react';

import { LoadingButton } from '@mui/lab';
import type { SvgIconProps } from '@mui/material';
import { PlaceholderIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  useUndoApproved,
  useUndoWaitingApproval,
  useUndoWaitingPayment,
} from 'src/libs/finbits/PaymentsFlow';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { Resources } from 'src/libs/finbits/Permissions/types';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';

import ReviewModal from 'src/features/bills-to-pay/TabActions/DEPRECATED_ReviewModal';

type Props = {
  status: BillStatus;
  bills: BillPayable[];
  onSuccess: () => void;
  Icon?: ComponentType<SvgIconProps>;
};

const RESOURCE_FROM_STATUS = {
  [BillStatus.APPROVED]: Resources.approvedPayments,
  [BillStatus.WAITING_APPROVAL]: Resources.waitingApprovalPayments,
  [BillStatus.WAITING_PAYMENT]: Resources.waitingPayments,
};

const AVAILABLE_ACTION_FROM_STATUS = {
  [BillStatus.APPROVED]: AvailableActions.UNDO_SEND_TO_APPROVED,
  [BillStatus.WAITING_APPROVAL]: AvailableActions.UNDO_SEND_TO_WAITING_APPROVAL,
  [BillStatus.WAITING_PAYMENT]: AvailableActions.UNDO_SEND_TO_WAITING_PAYMENT,
  [BillStatus.PENDING]: undefined,
  [BillStatus.REPROVED]: undefined,
};

function useUndo(status: BillStatus) {
  const { undoApproved, isLoading: isApprovedLoading } = useUndoApproved();
  const { undoWaitingApproval, isLoading: isWaitingApprovalLoading } =
    useUndoWaitingApproval();
  const { undoWaitingPayment, isLoading: isWaitingPaymentLoading } =
    useUndoWaitingPayment();

  if (status === BillStatus.APPROVED) {
    return { apiCall: undoApproved, isLoading: isApprovedLoading };
  }

  if (status === BillStatus.WAITING_APPROVAL) {
    return {
      apiCall: undoWaitingApproval,
      isLoading: isWaitingApprovalLoading,
    };
  }

  if (status === BillStatus.WAITING_PAYMENT) {
    return { apiCall: undoWaitingPayment, isLoading: isWaitingPaymentLoading };
  }

  return { apiCall: () => {}, isLoading: false };
}

export default function UndoActionButton({
  status,
  bills,
  onSuccess,
  Icon = PlaceholderIcon,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const openPortal = useOpenPortal();

  const { billsAvailable, billsNotAvailable } = bills.reduce<{
    billsAvailable: BillPayable[];
    billsNotAvailable: BillPayable[];
  }>(
    (acc, bill) => {
      const availableAction = AVAILABLE_ACTION_FROM_STATUS[status];

      if (availableAction && canPerformAction(bill, availableAction)) {
        acc.billsAvailable.push(bill);
      } else {
        acc.billsNotAvailable.push(bill);
      }

      return acc;
    },
    { billsAvailable: [], billsNotAvailable: [] }
  );

  const { apiCall, isLoading } = useUndo(status);

  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  const TOOLTIP_FROM_STATUS = {
    [BillStatus.APPROVED]: 'Reenviar para aprovação',
    [BillStatus.WAITING_APPROVAL]: 'Retornar para ”A pagar”',
    [BillStatus.WAITING_PAYMENT]: isEnabledBillsApprovals
      ? 'Retornar para aprovados'
      : 'Retornar para ”A pagar”',
    [BillStatus.PENDING]: undefined,
    [BillStatus.REPROVED]: undefined,
  };

  function handleUndo() {
    if (billsNotAvailable.length !== 0) {
      openPortal(ReviewModal, {
        title: TOOLTIP_FROM_STATUS[status],
        description:
          'Ao clicar em continuar os lançamentos abaixo não serão reenviados para a etapa anterior pois se encontram em uma situação que não permite esta ação.',
        bills: billsNotAvailable,
        onSuccess: undos,
      });

      return;
    }

    undos();
  }

  function handleUndosSuccess(billsIds: string[]) {
    snackbar({
      variant: 'success',
      message: `${pluralize(
        billsIds.length,
        'Lançamento reenviado',
        'Lançamentos reenviados'
      )} para estado anterior`,
    });

    onSuccess();
  }

  function undos() {
    const billsIds = billsAvailable.map((bill) => bill.id);

    if (billsIds.length === 0) {
      handleUndosSuccess(billsIds);
      return;
    }

    apiCall(
      {
        companyId,
        organizationId,
        billsIds,
      },
      {
        onSuccess: () => {
          handleUndosSuccess(billsIds);
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: `Ocorreu um erro ao reenviar ${pluralize(
              billsIds.length,
              'lançamento',
              'lançamentos'
            )} para estado anterior`,
          });
        },
      }
    );
  }

  if (status === BillStatus.PENDING || status === BillStatus.REPROVED) {
    return null;
  }

  return (
    <WithAllowedPermission
      permissions={{
        action: 'undo',
        resource: RESOURCE_FROM_STATUS[status],
      }}
    >
      <LoadingButton
        variant="text"
        disabled={bills.length === 0}
        startIcon={<Icon />}
        onClick={handleUndo}
        loading={isLoading}
      >
        {TOOLTIP_FROM_STATUS[status]}
      </LoadingButton>
    </WithAllowedPermission>
  );
}
