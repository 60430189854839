import { useMemo } from 'react';

import { Empty, Table, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { snackbar } from 'src/mui';

import { format } from 'src/libs/finbits/Date';
import { useDeleteIgnoredRecord } from 'src/libs/finbits/Management/IgnoredRecords';
import type { IgnoredRecord } from 'src/libs/finbits/Management/IgnoredRecords/types';
import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useCalculatedScroll } from 'src/libs/finbits/Table';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import { useOpenConfirmRestore } from 'src/features/entries/Dialogs';
import { AccountInfo, Amount, Undo } from 'src/features/entries/Columns';
import Summary from 'src/features/entries/Summary';
import { DescriptionFilter } from 'src/features/filters';
import { amountSorter } from 'src/features/sorters';

import styles from './IgnoredRecordsTable.module.less';

type Props = {
  ignoredRecords: IgnoredRecord[];
  isLoading: boolean;
  selectedAccounts: Account[];
  parentClassName?: string;
  recalculateScroll?: boolean;
};

const HEADER_CLASS = 'finbitsTableHeader';
const FOOTER_CLASS = 'finbitsTableFooter';

export default function IgnoredRecordsTable({
  ignoredRecords,
  isLoading,
  selectedAccounts,
  parentClassName,
  recalculateScroll,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const confirmRestoreEntry = useOpenConfirmRestore();
  const { deleteIgnoredRecord } = useDeleteIgnoredRecord();

  const canUndo = useAllowedPermission({
    action: 'delete',
    resource: 'ignoredRecords',
  });

  const { calculatedScroll } = useCalculatedScroll({
    classNames: [HEADER_CLASS, FOOTER_CLASS, styles.pagination],
    parentClassName,
    items: ignoredRecords,
    recalculateScroll,
  });

  const accounts = useMemo(() => {
    return selectedAccounts.reduce(
      (
        total: {
          [key: string]: Account;
        },
        account: Account
      ) => {
        return {
          ...total,
          [account.id]: account,
        };
      },
      {}
    );
  }, [selectedAccounts]);

  const columns: ColumnsType<IgnoredRecord> = [
    {
      key: 'date',
      title: 'Data',
      render: (ignoredRecord: IgnoredRecord) => (
        <Typography.Text>
          {format(ignoredRecord.date, 'dd/MM/yy')}
        </Typography.Text>
      ),
      width: 96,
      sorter: (a: IgnoredRecord, b: IgnoredRecord) =>
        a.date.localeCompare(b.date),
    },
    {
      key: 'description',
      title: 'Descrição',
      ellipsis: {
        showTitle: false,
      },
      render: (ignoredRecord: IgnoredRecord) => (
        <Tooltip
          placement="top"
          title={ignoredRecord.description}
          overlayInnerStyle={{ textAlign: 'center' }}
        >
          <Typography.Text>{ignoredRecord.description}</Typography.Text>
        </Tooltip>
      ),
      ...DescriptionFilter<IgnoredRecord>(),
      sorter: (a: IgnoredRecord, b: IgnoredRecord) =>
        a.description.localeCompare(b.description),
    },
    {
      key: 'account',
      align: 'center' as 'center',
      className: 'account',
      title: 'Conta',
      render: ({ accountId }: IgnoredRecord) => (
        <AccountInfo account={accounts[accountId]} />
      ),
      sorter: (entryA: IgnoredRecord, entryB: IgnoredRecord) => {
        const a = accounts[entryA.accountId].name ?? '';
        const b = accounts[entryB.accountId].name ?? '';

        return a.localeCompare(b);
      },
      width: 120,
    },
    {
      key: 'amount',
      align: 'right' as 'right',
      title: 'Valor',
      render: (ignoredRecord: IgnoredRecord) => (
        <Amount amount={ignoredRecord.amount} type={ignoredRecord.type} />
      ),
      width: 180,
      sorter: amountSorter<IgnoredRecord>,
      filterMode: 'tree',
      filters: [
        {
          text: 'Entradas',
          value: BalanceType.CREDIT,
        },
        {
          text: 'Saídas',
          value: BalanceType.DEBIT,
        },
      ],
      onFilter: (
        value: string | number | boolean,
        transaction: IgnoredRecord
      ) => transaction.type === value,
    },
  ];

  const undoColumn = {
    key: 'undo',
    align: 'center' as 'center',
    title: '',
    render: (ignoredRecord: IgnoredRecord) => (
      <Undo
        onClick={() => {
          confirmRestoreEntry({
            onConfirm: () => onHandleUndoDelete(ignoredRecord.id),
          });
        }}
      />
    ),
    width: 100,
  };

  function onHandleUndoDelete(ignoredRecordId: string) {
    if (!companyId || !organizationId) return;

    deleteIgnoredRecord(
      { companyId, organizationId, ignoredRecordId },
      {
        onSuccess: () => {
          snackbar({ variant: 'success', message: 'Lançamento restaurado' });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao restaurar lançamento!',
          });
        },
      }
    );
  }

  const tableColumns = canUndo ? [...columns, undoColumn] : columns;

  return (
    <Table
      size="middle"
      className={styles.table}
      columns={tableColumns}
      dataSource={ignoredRecords}
      loading={isLoading}
      locale={{
        emptyText: (
          <Empty
            description="Você não tem lançamentos excluídos"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
      pagination={{
        hideOnSinglePage: false,
        pageSizeOptions: ['50', '100', '150'],
        defaultPageSize: 50,
        position: ['bottomCenter'],
        showSizeChanger: true,
        showQuickJumper: true,
        total: ignoredRecords.length,
        className: styles.pagination,
      }}
      rowKey="id"
      scroll={{
        y: calculatedScroll,
      }}
      onHeaderRow={() => ({
        className: HEADER_CLASS,
      })}
      footer={(data) => {
        const incoming = data
          .filter((entry) => entry.type === BalanceType.CREDIT)
          .reduce((acc, entry) => {
            return acc + entry.amount;
          }, 0);
        const outcoming = data
          .filter((entry) => entry.type === BalanceType.DEBIT)
          .reduce((acc, entry) => {
            return acc + entry.amount;
          }, 0);

        return (
          <div className={FOOTER_CLASS}>
            <Summary
              incoming={incoming}
              outcoming={outcoming}
              filtersActive={false}
            />
          </div>
        );
      }}
    />
  );
}
