import type { MouseEvent } from 'react';
import { useState } from 'react';

export function useDragging() {
  const [coordinates, setCoordinates] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [isDragging, setIsDragging] = useState(false);

  function handleMove(e: MouseEvent) {
    if (!isDragging) return;

    const x = e.movementX !== 0 ? coordinates.x + e.movementX : coordinates.x;
    const y = e.movementY !== 0 ? coordinates.y + e.movementY : coordinates.y;

    setCoordinates({ x, y });
  }

  return {
    coordinates,
    handleMove,
    isDragging,
    setIsDragging,
  };
}
