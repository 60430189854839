import t from 'zod';
import type { CountryCode } from 'libphonenumber-js';
import phoneUtil, { isValidPhoneNumber } from 'libphonenumber-js';

export const phoneMask = '(99) 9999-9999';
export const cellphoneMask = '(99) 99999-9999';

export const PhoneNumberDecoder = t.object({
  number: t.string(),
  verifiedAt: t.string().nullable(),
});

function parse(phoneNumber: string, countryCode?: CountryCode) {
  return phoneUtil(phoneNumber, countryCode ?? 'BR');
}

export function phoneFormat(
  phoneNumber: string,
  countryCode: CountryCode = 'BR'
) {
  const parsedPhone = parse(phoneNumber, countryCode);

  return countryCode === 'BR'
    ? parsedPhone?.formatNational()
    : parsedPhone?.formatInternational();
}

export function e164Format(phoneNumber?: string | null) {
  if (!phoneNumber) return null;
  return parse(phoneNumber)?.number.toString() || null;
}

export function isValidPhone(
  phoneNumber: string | undefined = '',
  countryCode?: CountryCode
) {
  return isValidPhoneNumber(phoneNumber, countryCode ?? 'BR');
}

export function phoneValidator(_rule: any, value: string) {
  if (!value || (value.length >= 10 && isValidPhone(value))) {
    return Promise.resolve();
  }

  return Promise.reject('Número de celular inválido.');
}

export function internationalPhoneValidator(
  _rule: any,
  value: string,
  countryCode?: CountryCode
) {
  if (!value || (value.length >= 10 && isValidPhone(value, countryCode))) {
    return Promise.resolve();
  }

  return Promise.reject('Número de celular inválido.');
}

export type PhoneNumber = t.TypeOf<typeof PhoneNumberDecoder>;
