import type { ReactNode } from 'react';

import { ConfigProvider } from 'antd';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';

import { ConfirmDialogProvider, PortalProvider } from 'src/ui';

import { SocketProvider } from 'src/libs/finbits/Channels';
import { CompanyProvider } from 'src/libs/finbits/Organization/Companies/CompanyProvider';
import { locale } from 'src/libs/finbits/Locale';
import { useVersionChecker } from 'src/libs/finbits/Version';
import ChannelsListeners from 'src/libs/finbits/Channels/ChannelsListeners';
import LaunchDarklyContextSetter from 'src/libs/launchDarkly/LaunchDarklyContextSetter';

import AuthRenewer from 'src/features/auth/AuthRenewer';
import StatementImportFinishedGlobalNotification from 'src/features/DEPRECATED_accounts/StatementImportFinishedGlobalNotification';
import AnalyticsIdentifier from 'src/features/analytics/AnalyticsIdentifier';

type Props = {
  children: ReactNode;
};

export default function Providers({ children }: Props) {
  useVersionChecker();

  return (
    <LaunchDarklyContextSetter>
      <ConfigProvider locale={locale}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <AuthRenewer>
            <SocketProvider>
              <CompanyProvider>
                <StatementImportFinishedGlobalNotification />
                <ChannelsListeners />
                <AnalyticsIdentifier />
                <ConfirmDialogProvider />
                <PortalProvider />
                {children}
              </CompanyProvider>
            </SocketProvider>
          </AuthRenewer>
        </LocalizationProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </ConfigProvider>
    </LaunchDarklyContextSetter>
  );
}
