import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { dateColumn as defaultDateColumn } from 'src/mui/_scss';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

import DateWithOverdueIcon from './DateWithOverdueIcon/DateWithOverdueIcon';

export function dateColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return defaultDateColumn({
    columnProps: {
      headerName: 'Data',
      field: 'date',
      minWidth: 110,
      flex: 2,
      editable: true,
      aggregable: false,
      renderCell: DateWithOverdueIcon,
      ...columnProps,
    },
  });
}
