import { useCallback, useEffect, useState } from 'react';

import { ArrowLeftOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import type { Item } from 'pluggy-js';
import { PluggyConnect } from 'react-pluggy-connect';

import { snackbar } from 'src/mui';

import { Loader, Modal, Title } from 'src/ui';

import { useCreateWidgetSession } from 'src/libs/finbits/Bank/OpenBanking';
import { Provider } from 'src/libs/finbits/Bank/OpenBanking/types';

import type { WidgetProps } from '../types';

import styles from './ConnectWidget.module.less';

type Props = WidgetProps & {
  onClose: () => void;
};

function ConnectWidget({
  organizationId,
  companyId,
  connectorIds,
  errorModalHeader,
  connectionId,
  connectionProviderId,
  onClose,
  onCancel,
  onSuccess,
}: Props) {
  const {
    createWidgetSession,
    data,
    isSuccess: isTokenSuccess,
    isError,
  } = useCreateWidgetSession();

  const onCancelError = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  const [token, setToken] = useState('');

  useEffect(() => {
    createWidgetSession({
      organizationId,
      companyId,
      connectionId,
      provider: Provider.PLUGGY,
    });
  }, [createWidgetSession, connectionId, organizationId, companyId]);

  const isReady = token !== '' && isTokenSuccess;

  useEffect(() => {
    if (isReady || !data?.token) return;
    setToken(data.token);
  }, [data?.token, isReady]);

  function onLoadError(error: Error) {
    console.error('pluggy:onLoadError', error);
  }

  function onConnectWidgetSuccess(data: { item: Item }) {
    const { item } = data;
    snackbar({
      variant: 'success',
      message:
        'Conexão realizada com sucesso. Aguarde a próxima atualizaçao automática da conta',
    });
    onSuccess?.(item.id, item.connector.name);
  }

  return (
    <>
      <Modal
        visible={!isReady}
        footer={null}
        className={styles.modal}
        onCancel={onCancelError}
        destroyOnClose
      >
        {isError ? (
          <>
            <div className={styles.modalHeader}>
              <Button
                icon={<ArrowLeftOutlined />}
                size="large"
                type="text"
                onClick={onCancelError}
              />
              <Title level={4}>{errorModalHeader}</Title>
            </div>

            <Space
              direction="vertical"
              size="large"
              className={styles.errorBody}
            >
              <WarningFilled className={styles.errorIcon} />
              <Typography.Text>
                Ocorreu um erro na comunicação com nosso servidor. Tente
                novamente em instantes.
              </Typography.Text>
              <Button onClick={onCancelError} type="primary" size="large">
                Voltar
              </Button>
            </Space>
          </>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </Modal>

      {isReady && (
        <PluggyConnect
          connectToken={token}
          allowConnectInBackground={true}
          allowFullscreen={false}
          connectorTypes={['BUSINESS_BANK', 'PERSONAL_BANK']}
          countries={['BR']}
          connectorIds={connectorIds}
          updateItem={connectionProviderId}
          onSuccess={onConnectWidgetSuccess}
          onClose={onClose}
          onLoadError={onLoadError}
        />
      )}
    </>
  );
}

export default ConnectWidget;
