import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd';

import { useBanks } from 'src/libs/finbits/Bank';
import { filterOption } from 'src/libs/finbits/Form';

export default function BankSelect({ ...rest }: SelectProps<string>) {
  const { data: banks, isLoading } = useBanks();

  return (
    <Select
      filterOption={filterOption}
      loading={isLoading}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      placeholder="Digite o nome do banco"
      showSearch
      size="large"
      getPopupContainer={(trigger) => trigger.parentElement}
      {...rest}
    >
      {banks?.map((bank) => (
        <Select.Option value={bank.routingNumber} key={bank.routingNumber}>
          {bank.fullName}
        </Select.Option>
      ))}
    </Select>
  );
}
