import type { ReactNode } from 'react';

import cn from 'classnames';
import type { DraggerProps } from 'antd/lib/upload';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import styles from './UploadDragger.module.less';

type Props = DraggerProps & {
  children: ReactNode;
};

export default function UploadDragger({
  children,
  className,
  ...draggerProps
}: Props) {
  return (
    <Upload.Dragger
      data-testid="file-upload"
      className={cn(styles.dragger, className)}
      {...draggerProps}
    >
      <p className={styles.uploadIcon}>
        <InboxOutlined />
      </p>

      <div className="ant-upload-hint">{children}</div>
    </Upload.Dragger>
  );
}
