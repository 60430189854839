import { Select } from 'antd';
import { BankIcon } from 'src/mui/_scss';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

import { selectFilter } from 'src/features/EntryForm/selectFilter';

import styles from './AccountSelect.module.less';

type Props = {
  loading: boolean;
  accounts: Account[];
  disabled?: boolean;
};

function AccountSelect({
  accounts,
  loading,
  disabled = false,
  ...rest
}: Props) {
  return (
    <Select
      showSearch
      placeholder="Digite o nome da conta"
      size="large"
      filterOption={selectFilter}
      loading={loading}
      disabled={disabled}
      getPopupContainer={(trigger) => trigger.parentElement}
      {...rest}
    >
      {accounts.map((account: Account) => (
        <Select.Option value={account.id} key={account.id}>
          <div className={styles.accountsSelectOption}>
            <span>{account.name}</span>
            <BankIcon
              routingNumber={account.routingNumber}
              width={24}
              height={24}
            />
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default AccountSelect;
