import type { SxProps, Theme } from '@mui/material';

export const header: SxProps<Theme> = {
  padding: '14px 8px 0',
};

export const pendingUpdate: SxProps<Theme> = {
  paddingInline: '12px',
  marginBottom: '16px',
};

export const divider: SxProps<Theme> = { mx: '10px', my: '8px !important' };
