import { Link, Stack } from '@mui/material';
import { BankIcon } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';

import { BTG_SIGNUP_URL } from 'src/libs/finbits/Bank';
import { useBTGAuthenticationURL } from 'src/libs/finbits/Bank/OpenBanking';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import style from './BalanceCTABTG.module.scss';

type Props = {
  onClose?: () => void;
};

export default function BalanceCTABTG({ onClose }: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { data } = useBTGAuthenticationURL({
    organizationId: organizationId!,
  });

  function redirectToAccountSettings() {
    onClose?.();
  }

  return (
    <Stack direction="row" alignItems="center" gap={3} className={style.stack}>
      <BankIcon routingNumber="208" />

      <div>
        <Typography variant="text-xs">
          Com o BTG, saldos e lançamentos são atualizados em tempo real.
        </Typography>

        <Typography variant="text-xs">
          <Link href={BTG_SIGNUP_URL} target="_blank">
            Abra uma conta no Banco BTG
          </Link>{' '}
          <WithAllowedPermission
            permissions={{
              resource: 'btgAuthentication',
              action: 'show',
              scope: 'company',
              companyId,
            }}
          >
            <>
              ou{' '}
              <Link
                onClick={redirectToAccountSettings}
                className={style.linkAccount}
                href={data?.url}
                target="_blank"
              >
                adicione sua conta
              </Link>
            </>
          </WithAllowedPermission>
        </Typography>
      </div>
    </Stack>
  );
}
