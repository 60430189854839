import type { QueryClient } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import type { AxiosError } from 'axios';

import { authenticatedAPI } from 'src/libs/finbits/client';
import { invalidateFinancialStatementEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import type { SpreadsheetPostParams } from 'src/libs/finbits/Spreadsheet/types';

import type { ScheduledEntryDrawerErrors } from 'src/features/entries/Modals/ImportScheduledEntryModal/types';

export function scheduledEntryCacheKey(scheduledEntryId: string) {
  return ['scheduled_entry', scheduledEntryId];
}

async function postImportScheduledEntriesSpreadsheet({
  organizationId,
  companyId,
  accountId,
  file,
}: SpreadsheetPostParams): Promise<any> {
  const url = `/organizations/${organizationId}/companies/${companyId}/entries/import`;

  return await authenticatedAPI.postForm(url, { accountId, file });
}

export function invalidateScheduledEntriesQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['scheduled_entry']);
  queryClient.invalidateQueries(['scheduled_entries']);
}

export function useImportScheduledEntriesSpreadsheet() {
  const queryClient = useQueryClient();
  const { mutate: importScheduledEntry, ...rest } = useMutation<
    unknown,
    AxiosError<{
      message: string;
      errors?: ScheduledEntryDrawerErrors;
    }>,
    SpreadsheetPostParams
  >(postImportScheduledEntriesSpreadsheet, {
    onSuccess: () => {
      invalidateFinancialStatementEntriesQueries(queryClient);
    },
  });

  return { importScheduledEntry, ...rest };
}
