import { Link } from 'src/mui/_scss';
import { PlusIcon } from 'src/mui/_icons';

import { useOpenPortal } from 'src/ui';

import analytics from 'src/libs/analytics';

import EditContactDrawer from 'src/features/contacts/EditContactDrawer';

type Props = {
  contactId: string;
};

export default function ShowContactDetails({ contactId }: Props) {
  const openPortal = useOpenPortal();

  function handleDrawerOpen() {
    analytics.track('Entry Form Show Contact Details Button Clicked');

    openPortal(EditContactDrawer, {
      contactId,
    });
  }

  return (
    <Link startIcon={<PlusIcon />} onClick={handleDrawerOpen}>
      Ver detalhes do contato
    </Link>
  );
}
