import { useMemo, useState } from 'react';

import { Table, Tabs } from 'antd';

import { useCompanyContacts } from 'src/libs/finbits/Organization/Companies/Contacts/Contacts';
import { formatCNPJ, formatCPF } from 'src/libs/finbits/Documents';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { Association } from 'src/features/entries/Columns';

import EmptyTable from './EmptyTable';
import TabTitle from './TabTitle';
import Actions from './Actions';
import type { CreateEntryParams } from './Actions';
import type { SelectedTab } from './types';
import styles from './ContactsTable.module.less';

type Props = {
  onEdit: (contact: Contact) => void;
  onCreateEntry: (createEntryParams: CreateEntryParams) => void;
};

export default function ContactsTable({ onEdit, onCreateEntry }: Props) {
  const { organizationId, companyId } = useCompanyParams();
  const { companyContacts, isLoading } = useCompanyContacts({
    organizationId,
    companyId,
  });
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('actives');

  const counter = useMemo(() => {
    return companyContacts.reduce(
      (acc, contact) => {
        if (contact.active) {
          return { ...acc, actives: acc.actives + 1 };
        }
        return { ...acc, inactives: acc.inactives + 1 };
      },
      { actives: 0, inactives: 0 }
    );
  }, [companyContacts]);

  const filteredContacts = useMemo(() => {
    return companyContacts.filter((contact) => {
      if (selectedTab === 'actives') {
        return contact.active === true;
      }

      return contact.active === false;
    });
  }, [companyContacts, selectedTab]);

  const columns = [
    {
      key: 'document',
      title: 'Documento',
      render: ({ type, document, isInternational }: Contact) => {
        if (!document) return '--';
        if (isInternational) return document;

        return type === 'legal' ? formatCNPJ(document) : formatCPF(document);
      },
      sorter: (a: Contact, b: Contact) => {
        const aDocument = a.document ?? '';
        const bDocument = b.document ?? '';
        return aDocument.localeCompare(bDocument, 'pt-BR', { numeric: true });
      },
    },
    {
      key: 'name',
      title: 'Nome / Razão Social',
      dataIndex: 'name',
      sorter: (a: Contact, b: Contact) => a.name.localeCompare(b.name),
    },
    {
      key: 'nickname',
      title: 'Apelido / Nome da Empresa',
      dataIndex: 'nickname',
      sorter: (a: Contact, b: Contact) => a.nickname.localeCompare(b.nickname),
    },
    {
      key: 'email',
      title: 'E-mail',
      render: ({ email }: Contact) => (
        <Association label="Preencher" value={email} />
      ),
      sorter: (a: Contact, b: Contact) => {
        const aEmail = a.email ?? '';
        const bEmail = b.email ?? '';
        return aEmail.localeCompare(bEmail);
      },
    },
    {
      title: '',
      width: 32,
      render: (contact: Contact) => (
        <Actions
          organizationId={organizationId}
          companyId={companyId}
          contact={contact}
          onEdit={onEdit}
          onCreateEntry={onCreateEntry}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs
        activeKey={selectedTab}
        onChange={(currentTab) => setSelectedTab(currentTab as SelectedTab)}
      >
        <Tabs.TabPane
          tab={<TabTitle title="Ativos" count={counter.actives} />}
          key="actives"
        />
        <Tabs.TabPane
          tab={<TabTitle title="Inativos" count={counter.inactives} />}
          key="inactives"
        />
      </Tabs>
      <Table
        size="middle"
        className={styles.table}
        columns={columns}
        loading={isLoading}
        dataSource={filteredContacts}
        locale={{
          emptyText: <EmptyTable selectedTab={selectedTab} />,
        }}
        onRow={(contact) => ({
          className: styles.tableRow,
          onClick: () => onEdit(contact),
        })}
        pagination={{
          hideOnSinglePage: true,
          pageSizeOptions: ['50', '100', '150'],
          defaultPageSize: 50,
          position: ['bottomCenter'],
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `Total ${total} itens`,
        }}
        rowKey="id"
      />
    </>
  );
}
