import t from 'zod';
import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ErrorsObject } from 'src/libs/finbits/Form';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import type { ApiError } from 'src/libs/finbits/client';

import type {
  DeletePublicApiTokenParams,
  PublicApiToken,
  PublicApiTokenPostParams,
} from './types';
import { PublicApiTokenDecoder } from './types';

const GET_API_KEY = 'publicApiTokens';

function apiTokensCacheKey(
  params: Pick<PublicApiTokenPostParams, 'organizationId' | 'companyId'>
) {
  return [GET_API_KEY, params];
}

function invalidatePublicApiTokensQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries([GET_API_KEY]);
}

async function createPublicApiTokenRequest({
  organizationId,
  companyId,
  ...params
}: PublicApiTokenPostParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/public_api_tokens`;

  const response = await authenticatedAPI.post(url, params);

  return decodeResponse<PublicApiToken>(response, PublicApiTokenDecoder);
}

async function deletePublicApiTokenRequest({
  organizationId,
  companyId,
  id,
}: DeletePublicApiTokenParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/public_api_tokens/${id}`;

  const response = await authenticatedAPI.delete(url);

  return response.data;
}

export function useCreatePublicApiToken() {
  const queryClient = useQueryClient();
  const { mutate: createPublicApiToken, ...rest } = useMutation<
    PublicApiToken,
    ApiError<ErrorsObject>,
    PublicApiTokenPostParams
  >(createPublicApiTokenRequest, {
    onSuccess: () => {
      invalidatePublicApiTokensQueriesCall(queryClient);
    },
  });

  return { createPublicApiToken, ...rest };
}

export function useDeletePublicApiToken() {
  const queryClient = useQueryClient();
  const { mutate: deletePublicApiToken, ...rest } = useMutation<
    string,
    ApiError<ErrorsObject>,
    DeletePublicApiTokenParams
  >(deletePublicApiTokenRequest, {
    onSuccess: () => {
      invalidatePublicApiTokensQueriesCall(queryClient);
    },
  });

  return { deletePublicApiToken, ...rest };
}

async function listPublicApiTokens(organizationId: string, companyId: string) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/public_api_tokens`
  );

  return decodeResponse<PublicApiToken[]>(
    response,
    t.array(PublicApiTokenDecoder)
  );
}

type QueryOptions = UseQueryOptions<PublicApiToken[], ApiError>;

export function usePublicApiToken(
  params: { organizationId: string; companyId: string },
  { enabled = true, ...queryConfig }: QueryOptions = {}
) {
  const { data, ...rest } = useQuery<PublicApiToken[], ApiError>({
    enabled: !!params.organizationId && !!params.companyId && enabled,
    queryFn: () => listPublicApiTokens(params.organizationId, params.companyId),
    queryKey: apiTokensCacheKey(params),
    ...queryConfig,
  });

  return { publicApiTokens: withEmptyArrayDefault(data), ...rest };
}
