import type { InputProps } from 'antd';
import { Input } from 'antd';

import { isMaskFilled, masker, unMask } from 'src/libs/finbits/Masker';

type Props = InputProps & {
  mask?: string[];
};

export default function MaskedInput({
  mask,
  onChange,
  value,
  ...props
}: Props) {
  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      if (mask && isMaskFilled(mask, event.target.value.trim())) return;

      onChange({
        ...event,
        target: {
          ...event.target,
          value: mask ? unMask(event.target.value) : event.target.value,
        },
      });
    }
  }

  const valueMasked = mask && value ? masker(value as string, mask) : undefined;

  return (
    <Input
      {...props}
      onChange={handleOnChange}
      value={mask ? valueMasked : value}
    />
  );
}
