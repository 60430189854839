import { DropdownActions } from 'src/ui';

import type { NotaFiscal } from 'src/libs/finbits/NotaFiscal/types';

import { useActionItems } from './useActionItems';
import ActionItems from './ActionItems';

type Props = {
  notaFiscal: NotaFiscal;
};

export default function Actions({ notaFiscal }: Props) {
  const items = useActionItems({
    notaFiscal,
  });

  if (items.length === 0) {
    return null;
  }

  return (
    <DropdownActions overlay={<ActionItems notaFiscalId={notaFiscal.id} />} />
  );
}
