import t from 'zod';
import type { RcFile } from 'rc-upload/lib/interface';

export enum AttachmentType {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  GIF = 'image/gif',
  DOC = 'text/plain',
  WEBP = 'image/webp',
}

export const AttachmentDecoder = t.object({
  id: t.string(),
  name: t.string(),
  type: t.string(),
  url: t.string(),
  insertedAt: t.string(),
  deletedAt: t.string().nullable(),
});

export const ExternalFilesDecoder = t.object({
  id: t.string(),
  name: t.string(),
  url: t.string(),
  insertedAt: t.string(),
});

export const AttachmentsDecoder = t.array(AttachmentDecoder);

export type Attachment = t.TypeOf<typeof AttachmentDecoder>;
export type ExternalFiles = t.TypeOf<typeof ExternalFilesDecoder>;

type Filters = {
  type?: string;
  nonAssociated?: boolean;
};

export type GetParams = {
  organizationId: string;
  companyId: string;
  attachmentId: string;
};

export type GetAllParams = {
  organizationId?: string;
  companyId?: string;
  filters?: Filters;
};

export type PostParams = {
  organizationId?: string;
  companyId?: string;
  file: RcFile | File;
};

export type PostInboxParams = {
  companyId?: string;
  organizationId?: string;
  attachmentsIds: string[];
};
