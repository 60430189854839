import t from 'zod';

import { FinancialEntryDecoder } from 'src/libs/finbits/Management/FinancialEntries/types';
import type { Label } from 'src/libs/finbits/Labels/types';
import type { ClassificationLabels } from 'src/libs/finbits/Bills/types';

export type SplitAttributes = {
  id?: string;
  description?: string;
  amount?: number | null;
  categoryId: string | null;
  contactId: string | null;
  relevantDate: string | null;
  attachmentsIds?: string[];
  scheduledEntryId: string | null;
  comments: string | null;
  labels?: Label[] | null;
  classifications?: ClassificationLabels[];
  notaFiscalId?: string | null;
};

export type SplitParams = {
  update: SplitAttributes[];
  create: SplitAttributes[];
  delete: string[];
};

export type GetParams = {
  organizationId?: string;
  companyId?: string;
  transactionId: string;
};

export type SplitCreateParams = {
  organizationId: string;
  companyId: string;
  transactionId: string;
  params: SplitParams;
};

export const SplitsDecoder = t.array(FinancialEntryDecoder);

export type Splits = t.TypeOf<typeof SplitsDecoder>;
