import { Empty, Typography } from 'antd';

import { Loader } from 'src/ui';

import { useClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import ClassificationCollapse from 'src/features/classifications/ClassificationCollapse';

import styles from './ClassificationList.module.less';

export default function ClassificationsList() {
  const { companyId, organizationId } = useCompanyParams();
  const { classifications, isLoading, isFetched } = useClassifications({
    organizationId,
    companyId,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (classifications.length === 0 && isFetched) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <Typography.Text>
            Adicione uma classificação para começar a se organizar.
          </Typography.Text>
        }
      />
    );
  }

  return (
    <div className={styles.list}>
      {classifications.map((classification) => (
        <ClassificationCollapse
          key={classification.id}
          classification={classification}
        />
      ))}
    </div>
  );
}
