import { cnpj, cpf } from 'cpf-cnpj-validator';

import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';
import type { InboxItemContactType } from 'src/libs/finbits/Management/NewInboxItems/types';

export const cnpjMask = '99.999.999/9999-99';
export const cpfMask = '999.999.999-99';

export function formCnpjValidator(_rule: any, value: string) {
  if (!value) {
    return Promise.resolve();
  }

  if (value.length > 11 && cnpj.isValid(value)) {
    return Promise.resolve();
  }

  return Promise.reject('CNPJ inválido');
}

export function formCpfValidator(_rule: any, value: string) {
  if (!value) {
    return Promise.resolve();
  }

  if (cpf.isValid(value)) {
    return Promise.resolve();
  }

  return Promise.reject('CPF inválido');
}

export function documentValidator(_rule: any, value: string) {
  if (!value) {
    return Promise.resolve();
  }

  if (value.length > 11 && cnpj.isValid(value)) {
    return Promise.resolve();
  }

  if (cpf.isValid(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Documento inválido');
}

export function formatCPF(cpf: string) {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
}

export function formatCNPJ(cnpj: string) {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
}

export function formatDocument(
  type: ContactType | InboxItemContactType,
  document: string
) {
  switch (type) {
    case 'legal':
      return formatCNPJ(document);
    case 'natural':
      return formatCPF(document);
    default:
      return document;
  }
}

export function typeFromDocument(document?: string | null) {
  if (!document) return undefined;

  const isLegalType = document.replace(/\D/g, '').length === 14;

  return isLegalType ? 'legal' : 'natural';
}
