import { Fab } from '@mui/material';
import { LogoutIcon, UserIcon } from 'src/mui/_icons';
import { useNavigate } from 'react-router';

import { useOpenPortal } from 'src/ui';

import { useProfile } from 'src/libs/finbits/Organization/Users';
import { impersonateLogout } from 'src/libs/finbits/Auth';

import ImpersonateModal from 'src/features/support/Impersonate/ImpersonateModal';

import styles from './Impersonate.module.scss';

function Impersonate() {
  const { user } = useProfile();

  const openPortal = useOpenPortal();

  const navigate = useNavigate();

  function handleClick() {
    if (user?.isImpersonated) {
      impersonateLogout();

      return navigate('/');
    }

    openPortal(ImpersonateModal);
  }

  if (!user?.supportStaff) {
    return (
      <span
        data-testid="noimpersonatebutton"
        className={styles.impersonateHiddenElement}
      ></span>
    );
  }

  return (
    <Fab
      variant="extended"
      color={user.isImpersonated ? 'warning' : 'primary'}
      className={styles.impersonateFab}
      size="small"
      onClick={handleClick}
    >
      {user.isImpersonated ? (
        <>
          <LogoutIcon className={styles.impersonateIcon} />
          <span>Encerrar Impersonate</span>
        </>
      ) : (
        <>
          <UserIcon className={styles.impersonateIcon} />
          <span>Impersonate</span>
        </>
      )}
    </Fab>
  );
}

export default Impersonate;
