import { forwardRef } from 'react';
import type { Ref } from 'react';

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers-pro';
import type { DatePickerProps } from '@mui/x-date-pickers-pro';
import { CalendarIcon } from 'src/mui/_icons';

type Props = DatePickerProps<Date>;

function DatePicker(
  { slots, format = 'dd/MM/yyyy', ...rest }: Props,
  ref: Ref<HTMLInputElement>
) {
  return (
    <MUIDatePicker
      {...rest}
      inputRef={ref}
      format={format}
      slots={{
        openPickerIcon: CalendarIcon,
        ...slots,
      }}
    />
  );
}

export default forwardRef(DatePicker);
