import { Button, Typography } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import type { RcFile, UploadRequestOption } from 'rc-upload/lib/interface';

import { UploadDragger } from 'src/ui';

import { validateBeforeUpload } from 'src/libs/finbits/Spreadsheet/validations';

import styles from './UploadContent.module.less';

type Props = {
  onImportRequest: (file: RcFile) => void;
  isUploading: boolean;
};

export default function UploadContent({ onImportRequest, isUploading }: Props) {
  return (
    <>
      <div className={styles.text}>
        <Typography.Paragraph>
          Faça a importação de sua planilha de categorias selecionando ou
          arrastando o arquivo .xlsx para a área abaixo.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Disponibilizamos um modelo de planilha para que não ocorram erros de
          dados e/ou formatação durante a importação.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Faça o download no link abaixo.
        </Typography.Paragraph>
      </div>

      <a
        href="https://finbits-public-assets.s3.amazonaws.com/spreadsheets/planilha-categorias-finbits.xlsx"
        className={styles.download}
        download
      >
        <PaperClipOutlined />
        <Typography.Text>planilha-categorias-finbits.xlsx</Typography.Text>
      </a>

      <UploadDragger
        data-testid="xlsx-upload"
        accept=".xlsx"
        multiple={false}
        showUploadList={false}
        disabled={isUploading}
        customRequest={({ file }: UploadRequestOption) =>
          onImportRequest(file as RcFile)
        }
        beforeUpload={validateBeforeUpload}
      >
        <p>
          Selecione ou arraste a planilha
          <br /> (arquivo .xlsx) para esta área.
        </p>
        <Button htmlType="button" type="primary" loading={isUploading}>
          Selecione o arquivo
        </Button>
      </UploadDragger>
    </>
  );
}
