import t from 'zod';

export const BankDecoder = t.object({
  name: t.string(),
  routingNumber: t.string(),
  providerCodes: t.string().array(),
});

export const BanksDecoder = t.array(BankDecoder);

export type Bank = t.TypeOf<typeof BankDecoder>;
export type BankExtended = Bank & { fullName: string };

export enum RoutingNumber {
  ASAAS = '461',
  BB = '001',
  BRADESCO = '237',
  BS2 = '218',
  BTG = '208',
  BV = '655',
  C6 = '336',
  CAIXA = '104',
  CORA = '403',
  INTER = '077',
  ITAU = '341',
  IUGU = '401',
  NUBANK = '260',
  SANTANDER = '033',
  SICOOB = '756',
  WARREN = '371',
  XP = '102',
}
