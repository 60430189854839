import t from 'zod';

import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { BankDetailsEncoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';
import { BoletoDecoder } from 'src/libs/finbits/Boletos/types';

export const PaymentDetailsDecoder = t
  .object({
    paymentDetails: t.string().nullish(),
    paymentMethod: t.nativeEnum(PaymentMethod).nullish(),
    accountDocument: t.string().nullish(),
    accountDocumentType: t.nativeEnum(ContactType).nullish(),
    boleto: BoletoDecoder.nullish(),
    payeeName: t.string().nullish(),
  })
  .merge(BankDetailsEncoder.partial());

export type PaymentDetails = t.TypeOf<typeof PaymentDetailsDecoder>;
