import { Button, Typography } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

import { UploadDragger } from 'src/ui';

import type { UploadFile } from 'src/libs/finbits/Bank/Statements/types';

import { validateBeforeUpload } from './validations';

type Props = {
  uploadFile: (params: { file: UploadFile }) => void;
  isUploading: boolean;
};

function UploadStep({ uploadFile, isUploading }: Props) {
  return (
    <>
      <Typography.Text>
        Acesse o site do banco e faça o 📥 &nbsp;download do extrato bancário
        para o seu computador. Em seguida selecione ou arraste para esta área,
        importaremos uma conta por vez.
      </Typography.Text>
      <UploadDragger
        data-testid="ofx-upload"
        name="file"
        multiple={false}
        showUploadList={false}
        customRequest={({ file }: UploadRequestOption) =>
          uploadFile({ file: file })
        }
        beforeUpload={validateBeforeUpload}
      >
        <p>
          Selecione ou arraste o extrato <br /> bancário (arquivo OFX) para esta
          área.
        </p>
        <p className="ant-upload-text">
          <Button type="primary" size="large" loading={isUploading}>
            Selecione o arquivo
          </Button>
        </p>
      </UploadDragger>
    </>
  );
}

export default UploadStep;
