import type { GridRowModel } from '@mui/x-data-grid-premium';

import { snackbar } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useRestoreEntry as useRestoreEntryAPI } from 'src/libs/finbits/Management/Entries/Restore';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { pluralize } from 'src/libs/finbits/Pluralize';

function buildEntriesIds(rows: FinancialStatementEntry[]) {
  return rows.reduce<{ billsIds: string[]; receivablesIds: string[] }>(
    (acc, row) => {
      if (row.entryType === EntryType.BILL) {
        acc.billsIds.push(row.id);
      }
      if (row.entryType === EntryType.RECEIVABLE) {
        acc.receivablesIds.push(row.id);
      }

      return acc;
    },
    { billsIds: [], receivablesIds: [] }
  );
}

type Props = {
  selecteds: GridRowModel<FinancialStatementEntry>[];
};

export function useRestoreEntry({ selecteds }: Props) {
  const { organizationId, companyId } = useCompanyParams();

  const { restoreEntry } = useRestoreEntryAPI();

  function handleRestoreSelection() {
    restoreEntry(
      { organizationId, companyId, ...buildEntriesIds(selecteds) },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: pluralize(
              selecteds.length,
              'O lançamento foi restaurado com sucesso',
              'Os lançamentos foram restaurados com sucesso'
            ),
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao restaurar os lançamentos',
          });
        },
      }
    );
  }

  return { handleRestoreSelection };
}
