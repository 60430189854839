import type { Components } from '@mui/material';
import { checkboxClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiCheckbox({
  palette,
}: ThemeConfig): Components['MuiCheckbox'] {
  const checkboxCheckedColor = palette?.grey?.[900];

  return {
    styleOverrides: {
      root: {
        color: palette?.grey?.[300],
        [`&.${checkboxClasses.checked}`]: {
          color: checkboxCheckedColor,
        },
        [`&.${checkboxClasses.indeterminate}`]: {
          color: checkboxCheckedColor,
        },
      },
    },
  };
}
