import type { ReactElement } from 'react';

import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'src/mui/_icons';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

const AMOUNT_TYPE_ICON: Record<BalanceType, ReactElement> = {
  [BalanceType.CREDIT]: (
    <ArrowCircleUpIcon color="success" aria-label="Entradas" />
  ),
  [BalanceType.DEBIT]: (
    <ArrowCircleDownIcon color="error" aria-label="Saídas" />
  ),
};

export default function AmountType({
  rowNode,
  value,
}: Partial<GridRenderCellParams>) {
  if (rowNode?.type === 'group') {
    return value;
  }

  return AMOUNT_TYPE_ICON[value as BalanceType];
}
