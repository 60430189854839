import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Chip, Typography } from 'src/design-system/components';
import { Stack } from '@mui/material';

import type { Label } from 'src/libs/finbits/Labels/types';

import styles from './ClassificationList.module.scss';

export default function ClassificationList({
  value,
  rowNode,
}: Partial<GridRenderCellParams>) {
  if (rowNode?.type === 'group') {
    return <Typography>{value}</Typography>;
  }

  return (
    <Stack direction="row" gap={2} className={styles.overflow}>
      {value.map(({ name, id }: Label) => (
        <Chip key={id} label={name} size="small" className={styles.overflow} />
      ))}
    </Stack>
  );
}
