import { Box, Stack } from '@mui/material';
import { BankIcon } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';

import { format } from 'src/libs/finbits/Date';
import { toCurrency } from 'src/libs/finbits/Money';

import type { BalanceInconsistency } from 'src/features/entries/BalanceInconsistency/types';

type Props = {
  account: BalanceInconsistency;
};

export default function BalanceInconsistencyItem({ account }: Props) {
  const {
    inconsistency: {
      inconsistencyValue,
      lastAccountBalance: balanceAccount,
      lastReportedBalance: balanceBank,
    },
    account: { name, routingNumber, accountNumber, synchronizedAt },
  } = account;

  const finbitsBalance = toCurrency(balanceAccount.amount, balanceAccount.type);

  const bankBalance = toCurrency(balanceBank.amount, balanceBank.type);

  const inconsistency = toCurrency(
    inconsistencyValue.amount,
    inconsistencyValue.type
  );

  return (
    <Stack direction="column" gap={4} width="100%">
      <Stack direction="row" alignItems="center" gap={2}>
        <BankIcon routingNumber={routingNumber} width={24} height={24} />

        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="row" alignItems="flex-end" gap={3} width="50%">
            <Typography fontWeight={600} ellipsis>
              {name}
            </Typography>

            <Typography color="text.secondary" variant="text-xs">
              {accountNumber}
            </Typography>
          </Stack>

          <Typography color="text.secondary" variant="text-xs">
            Última atualização:
            {format(synchronizedAt!, "dd/MM/yyyy 'às' HH:mm")}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={8}>
          <Box>
            <Typography variant="text-xs">Saldo no banco</Typography>
            <Typography fontWeight={600}>{bankBalance}</Typography>
          </Box>

          <Box>
            <Typography variant="text-xs">Saldo na Finbits</Typography>
            <Typography fontWeight={600}>{finbitsBalance}</Typography>
          </Box>
        </Stack>

        <Box textAlign="right">
          <Typography variant="text-xs">Diferença</Typography>
          <Typography fontWeight={600}>{inconsistency}</Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
