import { useState } from 'react';

import type { GridRenderEditCellParams } from '@mui/x-data-grid-premium';

import { InputBaseTable } from 'src/mui';

import styles from './AmountInput.module.scss';

type EditParams = Pick<
  GridRenderEditCellParams,
  'api' | 'value' | 'field' | 'id'
>;

export default function AmountInput({
  id,
  field,
  value: valueProp,
  api,
}: EditParams) {
  const [value, setValue] = useState(valueProp);

  function handleChange(newValue: number) {
    api.setEditCellValue({ id, field, value: newValue, debounceMs: 200 });
    setValue(newValue);
  }

  return (
    <InputBaseTable.Amount
      value={value}
      onChange={handleChange}
      fullWidth
      autoFocus
      inputProps={{
        className: styles.input,
      }}
    />
  );
}
