import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';

import PendingActions from './PendingActions';
import ApprovedActions from './ApprovedActions';
import ReprovedActions from './ReprovedActions';
import WaitingPaymentActions from './WaitingPaymentActions';
import WaitingApprovalActions from './WaitingApprovalActions';
import type { Action } from './types';

type Props = {
  selecteds: BillPayable[];
  onSuccess: (selecteds: BillPayable[]) => void;
};

const ACTIONS_BY_STATUS: Record<
  BillStatus,
  (props: Action) => JSX.Element | null
> = {
  [BillStatus.PENDING]: PendingActions,
  [BillStatus.APPROVED]: ApprovedActions,
  [BillStatus.REPROVED]: ReprovedActions,
  [BillStatus.WAITING_APPROVAL]: WaitingApprovalActions,
  [BillStatus.WAITING_PAYMENT]: WaitingPaymentActions,
};

export default function PaymentFlowActions({ selecteds, onSuccess }: Props) {
  return (
    <>
      {Object.values(BillStatus).map((status, index) => {
        const ActionComponent = ACTIONS_BY_STATUS[status];
        const filteredByStatus = selecteds.filter(
          (bill) => bill.status === status
        );

        if (!filteredByStatus.length) return null;

        function handleSuccess() {
          onSuccess(filteredByStatus);
        }

        return (
          <ActionComponent
            key={index}
            selecteds={filteredByStatus}
            onSuccess={handleSuccess}
          />
        );
      })}
    </>
  );
}
