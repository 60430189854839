import type { Ref } from 'react';
import { forwardRef } from 'react';

import { useWatch } from 'react-hook-form';
import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';

import {
  ApprovalRuleConditionField,
  ApprovalRuleConditionType,
} from 'src/libs/finbits/ApprovalRules/types';
import { APPROVAL_RULE_CONDITION_TYPE_DICTIONARY } from 'src/libs/finbits/ApprovalRules/translations';

type Props = {
  index: number;
  onChange: (value: string | null) => void;
} & Omit<
  SelectProps<ApprovalRuleConditionType, false, false, false>,
  'options' | 'defaultValue' | 'onChange'
>;

function filterOptionsByField(conditionField?: ApprovalRuleConditionField) {
  if (!conditionField) return Object.values(ApprovalRuleConditionType);

  if (conditionField === ApprovalRuleConditionField.AMOUNT) {
    return [
      ApprovalRuleConditionType.GREATER_THAN_OR_EQUAL_TO,
      ApprovalRuleConditionType.LESS_THAN_OR_EQUAL_TO,
    ];
  }

  return [ApprovalRuleConditionType.ONE_OF];
}

function ConditionTypeSelect(
  { index, ...props }: Props,
  ref: Ref<HTMLDivElement>
) {
  const formConditionFieldValue = useWatch({
    name: `conditions.${index}.field`,
  });

  const options = filterOptionsByField(formConditionFieldValue);

  const disabled =
    formConditionFieldValue === null ||
    formConditionFieldValue !== ApprovalRuleConditionField.AMOUNT;

  return (
    <Select
      options={options}
      placeholder="Selecione"
      {...props}
      disabled={disabled}
      disableClearable={disabled}
      ref={ref}
      getOptionLabel={(option) => {
        return APPROVAL_RULE_CONDITION_TYPE_DICTIONARY[option];
      }}
      isOptionEqualToValue={(option, value) => option === value}
      onChange={(_e, value) => {
        props.onChange(value);
      }}
    />
  );
}

export default forwardRef(ConditionTypeSelect);
