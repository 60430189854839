import type { ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import type { AutocompleteProps, TextFieldProps } from '@mui/material';
import {
  Divider,
  Autocomplete as MUIAutocomplete,
  Paper,
  TextField,
} from '@mui/material';
import merge from 'lodash/merge';
import { ChevronDownIcon } from 'src/mui/_icons';

import { autocomplete, icon, paper, textField } from './AutocompleteSelect.sx';

type Props<
  T = unknown,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = {
  footer?: ReactNode;
  TextFieldProps?: TextFieldProps;
} & Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
>;

const SLOT_PROPS: Props['slotProps'] = {
  paper: {
    sx: paper,
  },
  popupIndicator: {
    sx: icon,
  },
};

function AutocompleteSelect<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  {
    footer,
    slotProps = SLOT_PROPS,
    TextFieldProps,
    ...rest
  }: Props<T, Multiple, DisableClearable, FreeSolo>,
  ref: Ref<HTMLInputElement>
) {
  return (
    <MUIAutocomplete
      {...rest}
      ref={ref}
      openOnFocus
      sx={autocomplete}
      popupIcon={<ChevronDownIcon />}
      slotProps={slotProps}
      renderInput={(props) => (
        <TextField
          {...props}
          {...TextFieldProps}
          InputProps={{ ...props.InputProps, ...TextFieldProps?.InputProps }}
          sx={merge(textField, TextFieldProps?.sx)}
        />
      )}
      noOptionsText="Sem opções"
      PaperComponent={({ children, ...rest }) => (
        <Paper {...rest}>
          {children}
          {footer && (
            <>
              <Divider />
              {footer}
            </>
          )}
        </Paper>
      )}
    />
  );
}

export default forwardRef(AutocompleteSelect) as typeof AutocompleteSelect;
