import type { MouseEvent } from 'react';
import { useState } from 'react';

import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  BuildingIcon,
  DataFlowIcon,
  GridIcon,
  HomeLineIcon,
  SettingsIcon,
  TagIcon,
  UsersIcon,
} from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

export default function SettingsMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { companyId, organizationId } = useCompanyParams();

  const isVisibility = !!anchorEl;

  function toggleVisibility(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl((state) => (state ? null : event.currentTarget));
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        onClick={toggleVisibility}
        size="large"
        aria-label="Configurações"
      >
        <SettingsIcon />
      </IconButton>

      <Menu
        open={isVisibility}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        sx={{ mt: 2 }}
      >
        <MenuList sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <WithAllowedPermission
            permissions={{
              action: 'show',
              resource: 'companies',
            }}
          >
            <MenuItem
              component={Link}
              to={generateCompanyPath(RouteKey.COMPANY, {
                companyId,
                organizationId,
              })}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <HomeLineIcon />
                <Typography fontWeight={500}>Empresa</Typography>
              </Stack>
            </MenuItem>
          </WithAllowedPermission>
          <WithAllowedPermission
            permissions={{
              action: 'index',
              resource: 'contacts',
            }}
          >
            <MenuItem
              component={Link}
              to={generateCompanyPath(RouteKey.CONTACTS, {
                companyId,
                organizationId,
              })}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <UsersIcon />
                <Typography fontWeight={500}>Contatos</Typography>
              </Stack>
            </MenuItem>
          </WithAllowedPermission>
          <WithAllowedPermission
            permissions={{
              action: 'index',
              resource: 'accounts',
            }}
          >
            <MenuItem
              component={Link}
              to={generateCompanyPath(RouteKey.ACCOUNTS, {
                companyId,
                organizationId,
              })}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <BuildingIcon />
                <Typography fontWeight={500}>Contas</Typography>
              </Stack>
            </MenuItem>
          </WithAllowedPermission>
          <WithAllowedPermission
            permissions={{
              action: 'index',
              resource: 'categories',
            }}
          >
            <MenuItem
              component={Link}
              to={generateCompanyPath(RouteKey.CATEGORIES, {
                companyId,
                organizationId,
              })}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <GridIcon />
                <Typography fontWeight={500}>Categorias</Typography>
              </Stack>
            </MenuItem>
          </WithAllowedPermission>
          <WithAllowedPermission
            permissions={{
              action: 'index',
              resource: 'classifications',
            }}
          >
            <MenuItem
              component={Link}
              to={generateCompanyPath(RouteKey.LABELS, {
                companyId,
                organizationId,
              })}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <TagIcon />
                <Typography fontWeight={500}>Etiquetas</Typography>
              </Stack>
            </MenuItem>
          </WithAllowedPermission>
          <WithAllowedPermission
            permissions={{
              action: 'show',
              resource: 'companies',
            }}
          >
            <MenuItem
              component={Link}
              to={generateCompanyPath(RouteKey.CONFIG_APPROVAL_FLOW, {
                companyId,
                organizationId,
              })}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <DataFlowIcon />
                <Typography fontWeight={500}>Fluxo de aprovação</Typography>
              </Stack>
            </MenuItem>
          </WithAllowedPermission>

          <Divider sx={{ mx: -2 }} />

          <WithAllowedPermission
            permissions={{
              action: 'index',
              resource: 'users',
              scope: 'organization',
            }}
          >
            <MenuItem
              component={Link}
              to={generateCompanyPath(RouteKey.USERS, {
                companyId,
                organizationId,
              })}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <UsersIcon />
                <Typography fontWeight={500}>Usuários</Typography>
              </Stack>
            </MenuItem>
          </WithAllowedPermission>
        </MenuList>
      </Menu>
    </>
  );
}
