import { useFormContext } from 'react-hook-form';
import type { FieldValues, UseFormGetValues } from 'react-hook-form';
import { AlertCircleIcon, PlusIcon, SearchIcon } from 'src/mui/_icons';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Message } from 'src/mui/_scss';

import SimulationFields from './SimulationFields';
import Information from './Information';

function isRequiredFieldsValid(getValues: UseFormGetValues<FieldValues>) {
  const [date, description, amount, accountId] = getValues([
    'date',
    'description',
    'amount',
    'accountId',
  ]);

  return Boolean(date && description && amount && accountId);
}

export default function RecurrenceTab() {
  const { getValues, watch, setValue } = useFormContext();
  const isValid = isRequiredFieldsValid(getValues);

  if (!isValid) {
    return (
      <Message
        icon={<AlertCircleIcon />}
        title="Recorrência desabilitada"
        subtitle="Para criar uma recorrência, você precisa preencher os campos obrigatórios na aba Informações."
      />
    );
  }

  if (isValid && !watch('isRecurrenceEnabled')) {
    return (
      <Message
        icon={<SearchIcon />}
        title="Sem recorrências"
        subtitle="O lançamento ainda não possui uma recorrência."
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusIcon />}
          onClick={() => {
            setValue('isRecurrenceEnabled', true);
          }}
        >
          Criar recorrência
        </Button>
      </Message>
    );
  }

  return (
    <Grid container gap={6} alignItems="center">
      <Grid xs={12}>
        <SimulationFields />
      </Grid>

      <Grid xs={12}>
        {watch('isRecurrenceEnabled') ? (
          <Information />
        ) : (
          <Message
            icon={<SearchIcon />}
            title="Sem recorrências"
            subtitle="Preencha os campos acima para ver a simulação."
          />
        )}
      </Grid>
    </Grid>
  );
}
