import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCompany } from 'src/libs/finbits/Organization/Companies/Companies';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

export function useNotaFiscalConnection() {
  const { companyId, organizationId } = useCompanyParams();

  const { company, isLoading } = useCompany(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  return {
    ...company?.notaFiscalConnection,
    status: company?.notaFiscalConnection?.status ?? 'unset',
    isLoading,
  };
}
