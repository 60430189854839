import type { SyntheticEvent } from 'react';
import { useState } from 'react';

const initialPageNumber = 1;

export function usePageControls() {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(initialPageNumber);

  function onPDFLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function handleNextPage(event: SyntheticEvent) {
    event?.stopPropagation();

    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  function handlePreviousPage(event: SyntheticEvent) {
    event?.stopPropagation();

    setPageNumber((prevPageNumber) => prevPageNumber - 1);
  }

  function resetPageNumber() {
    setPageNumber(initialPageNumber);
    setNumPages(undefined);
  }

  return {
    numPages,
    pageNumber,
    onPDFLoadSuccess,
    handleNextPage,
    handlePreviousPage,
    resetPageNumber,
  };
}
