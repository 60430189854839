import { useSearchParams } from 'react-router-dom';

import type { InboxItemStatus } from 'src/libs/finbits/Management/NewInboxItems/types';

import { useInboxItemsContext } from 'src/features/inbox-items/InboxItemsProvider/InboxItemsProvider';

export function useInboxItemsParams() {
  const { filteredInboxItems, activeIndex } = useInboxItemsContext();

  const [searchParams, setSearchParams] = useSearchParams({
    status: 'pending',
  });

  const inboxItemId = searchParams.get('inboxItemId');
  const status = (searchParams.get('status') as InboxItemStatus) ?? 'pending';

  function navigateToNext(inboxItemId: string) {
    if (!filteredInboxItems) return;

    if (activeIndex === -1) {
      return closeInboxItem();
    }

    const activeItem = filteredInboxItems[activeIndex];
    const nextInboxItem = filteredInboxItems[activeIndex + 1];

    if (activeItem && activeItem.id !== inboxItemId) return;

    if (nextInboxItem) {
      return navigateToInboxItem(nextInboxItem.id);
    }

    return closeInboxItem();
  }

  function setStatus(
    status: InboxItemStatus,
    params?: { inboxItemId: string }
  ) {
    setSearchParams({
      status,
      ...params,
    });
  }

  function navigateToInboxItem(inboxItemId: string) {
    setSearchParams({
      status,
      inboxItemId,
    });
  }

  function closeInboxItem() {
    setSearchParams({
      status,
    });
  }

  return {
    inboxItemId,
    status,
    setStatus,
    closeInboxItem,
    navigateToInboxItem,
    navigateToNext,
  };
}
