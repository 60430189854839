import { useMemo } from 'react';

import { Button } from '@mui/material';
import { Alert, Typography } from 'src/design-system/components';
import { LightedBulbIcon } from 'src/mui/_icons';

import { BTG_ROUTING_NUMBER, BTG_SIGNUP_URL } from 'src/libs/finbits/Bank';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';

import BTGConnectionRedirectButton from 'src/features/open-banking/BTGConnectionRedirectButton';

import styles from './BtgAlert.module.scss';

export default function BtgAlert() {
  const { companyId, organizationId } = useCompanyParams();
  const { accounts } = useAccounts({ companyId, organizationId });

  const hasBTGAccount = useMemo(
    () => accounts.some((acc) => acc.routingNumber === BTG_ROUTING_NUMBER),
    [accounts]
  );

  return (
    <Alert
      severity="info"
      icon={<LightedBulbIcon color="primary" fontSize="inherit" />}
      classes={{
        action: hasBTGAccount ? styles.alertButton : styles.alertHasNoBtgButton,
        root: styles.btgRoot,
      }}
      action={
        hasBTGAccount ? (
          <BTGConnectionRedirectButton
            size="small"
            variant="text"
            color="primary"
          >
            Conectar conta
          </BTGConnectionRedirectButton>
        ) : (
          <Button href={BTG_SIGNUP_URL} variant="text" color="primary">
            Abra aqui sua conta BTG
          </Button>
        )
      }
    >
      {hasBTGAccount && (
        <Typography variant="text-xs" color="grey.700">
          <strong>
            Automatize transferências (TED), pagamentos de boletos e Pix.{' '}
          </strong>
          Conecte a sua conta do Banco BTG à FinBits.
        </Typography>
      )}

      {!hasBTGAccount && (
        <Typography variant="text-xs" color="grey.700">
          <strong>
            Automatize transferências (TED), pagamentos de boletos e Pix.
          </strong>
          <br />
          Abra sua conta no Banco BTG, conecte com a FinBits e aproveite esta
          facilidade!
        </Typography>
      )}
    </Alert>
  );
}
