import { useOpenPortal } from 'src/ui/PortalProvider';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useRestoreInboxItems } from 'src/libs/finbits/Management/NewInboxItems';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import useInboxItemsActions from 'src/features/inbox-items/useInboxItemsActions';
import CreateBillModal from 'src/features/bills-to-pay/CreateBillModal';
import CreateBillFromInboxItem from 'src/features/inbox-items/CreateBillFromInboxItem';
import ConciliationDrawer from 'src/features/conciliations/ConciliationDrawer';
import { useInboxItemsParams } from 'src/features/inbox-items/useInboxItemsParams';
import EditBillModal from 'src/features/bills-to-pay/EditBillModal';
import ConciliationModal from 'src/features/conciliations/ConciliationModal';

type Props = {
  inboxItemId?: string | null;
};

export default function useInboxItemActions({ inboxItemId }: Props) {
  const { organizationId, companyId } = useCompanyParams();
  const openPortal = useOpenPortal();

  const { isEnabled: isLinkBillFromInboxItemNewModalEnabled } =
    useExternalFeatureFlag(Feature.LINK_BILL_FROM_INBOX_ITEM_NEW_MODAL);

  const { isEnabled: isNewConciliationEnabled } = useExternalFeatureFlag(
    Feature.NEW_CONCILIATION_MODAL
  );

  const { restoreInboxItems } = useRestoreInboxItems();

  const inboxItemsIds = inboxItemId ? [inboxItemId] : [];
  const { onRejectionMany } = useInboxItemsActions({
    inboxItemsIds,
    onSuccess: onSuccessRejection,
  });

  const openConfirmDialog = useOpenConfirmDialog();

  const { navigateToNext } = useInboxItemsParams();

  const isAllowedCreateBill = useAllowedPermission({
    action: 'create',
    resource: 'bills',
  });

  const isAllowedUpdateBill = useAllowedPermission({
    action: 'update',
    resource: 'bills',
  });

  const isAllowedRejectManyInboxItems = useAllowedPermission({
    action: 'reject_many',
    resource: 'inboxItems',
  });

  const isAllowedRestoreManyInboxItems = useAllowedPermission({
    action: 'restore_many',
    resource: 'inboxItems',
  });

  const isAllowedActions =
    isAllowedCreateBill ||
    isAllowedUpdateBill ||
    isAllowedRejectManyInboxItems ||
    isAllowedRestoreManyInboxItems;

  function handleRestore() {
    if (!inboxItemId) return;

    restoreInboxItems(
      {
        companyId,
        organizationId,
        inboxItemsIds: [inboxItemId],
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Item restaurado',
          });

          navigateToNext(inboxItemId);
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao restaurar item',
          });
        },
      }
    );
  }

  function onSuccessRejection() {
    if (!inboxItemId) return;

    navigateToNext(inboxItemId);
  }

  function openRestoreConfirmationDialog() {
    openConfirmDialog({
      variant: 'primary',
      title: 'Restaurar entrada',
      content:
        'Ao confirmar, o item será movido de volta para a caixa de entrada.',
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
      onConfirm: handleRestore,
    });
  }

  function openCreateScheduledDrawer() {
    if (!inboxItemId) return;

    return openPortal(CreateBillModal, {
      inboxItemId,
      onSuccess: () => navigateToNext(inboxItemId),
    });
  }

  function handleConciliate(scheduledEntry: ScheduledStatementEntry) {
    if (!inboxItemId) return;

    if (isLinkBillFromInboxItemNewModalEnabled) {
      openPortal(EditBillModal, {
        inboxItemId,
        isLinkedEntry: true,
        billId: scheduledEntry?.id,
        onSuccess: () => navigateToNext(inboxItemId),
      });
    } else {
      openPortal(CreateBillFromInboxItem, {
        inboxItemId,
        linkedEntry: scheduledEntry as BillPayable,
        onSuccess: () => navigateToNext(inboxItemId),
      });
    }
  }

  function openConciliationDrawer() {
    if (isNewConciliationEnabled) {
      openPortal(ConciliationModal, {
        onConciliate: handleConciliate,
      });

      return;
    }

    openPortal(ConciliationDrawer, {
      multiple: false,
      onConciliate: handleConciliate,
      type: BalanceType.DEBIT,
      title: 'Vincular à lançamento existente',
      submitText: 'Vincular',
      organizationId: organizationId,
      companyId: companyId,
    });
  }

  return {
    onCreateEntry: openCreateScheduledDrawer,
    onConciliation: openConciliationDrawer,
    onRestore: openRestoreConfirmationDialog,
    onRejection: onRejectionMany,
    isAllowedActions,
    isAllowedCreateBill,
    isAllowedUpdateBill,
    isAllowedRejectManyInboxItems,
    isAllowedRestoreManyInboxItems,
  };
}
