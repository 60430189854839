import { useEffect } from 'react';

import type { RcFile } from 'antd/lib/upload';

import styles from './PreviewIMGFile.module.less';

type Props = {
  file: RcFile;
  zoom: number;
  rotate: number;
};

export default function PreviewIMGFile({ file, zoom, rotate }: Props) {
  const src = URL?.createObjectURL(file);

  useEffect(() => {
    return () => URL?.revokeObjectURL(src);
  }, [src]);

  return (
    <img
      onMouseDown={(e) => e.preventDefault()}
      draggable="false"
      className={styles.image}
      src={src}
      alt={file.name}
      style={{
        transform: `scale(${zoom}, ${zoom}) rotate(${rotate}deg)`,
      }}
    />
  );
}
