import type { ReactNode } from 'react';
import { Suspense, lazy, useEffect } from 'react';

import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { createTheme } from 'src/theme';

import {
  getMUIThemeModeItem,
  setMUIThemeModeItem,
} from 'src/libs/finbits/Storage';

type Props = {
  children: ReactNode;
};

const LightTheme = lazy(() => import('./themes/LightTheme'));

export function ThemeProvider({ children }: Props) {
  const muiTheme = createTheme('light');

  // TODO: Remove once all users are using light mode.
  useEffect(() => {
    if (getMUIThemeModeItem() === 'dark') {
      setMUIThemeModeItem('light');
      window.location.reload();
    }
  }, []);

  return (
    <CssVarsProvider theme={muiTheme}>
      <Suspense fallback={<span />}>
        <LightTheme />
      </Suspense>
      {children}
    </CssVarsProvider>
  );
}
