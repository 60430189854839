import { useEffect, useMemo, useState } from 'react';

import { CalendarOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import omitBy from 'lodash/omitBy';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Drawer, DrawerHeader, Loader, Title } from 'src/ui';

import { useProfile } from 'src/libs/finbits/Organization/Users';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import {
  buildSubmitParams,
  useCreateBill,
  DEPRECATED_useCreateRecurringBill as useCreateRecurringBill,
  useUpdateBill,
} from 'src/libs/finbits/Bills';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { isStructuredFormError } from 'src/libs/finbits/Form';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useIsEnabledBillsApprovalsRulesInCompany } from 'src/libs/finbits/Organization/Companies/Options';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { ContactSuggestion } from 'src/libs/finbits/Management/Entries/types';

import EntryForm from 'src/features/EntryForm';
import ConciliationDrawer from 'src/features/conciliations/ConciliationDrawer';
import type {
  EntryFormHiddenFields,
  FormValues,
  SubmitParams,
} from 'src/features/EntryForm/types';

import { buildOriginalValuesForConciliation } from './buildOriginalValuesForConciliation';
import ConciliateHeaderButton from './ConciliateHeaderButton';

type Props = {
  loading?: boolean;
  overlay?: boolean;
  originValues: FormValues;
  inboxItemId?: string;
  newContact?: ContactSuggestion;
  onSuccess?: () => void;
  fieldsToOmitOnConciliation?: Array<keyof FormValues>;
  scheduledStatementEntry?: BillPayable;
  submitButtonText?: string;
  hideExtraActions?: boolean;
} & PortalProps;

const HIDDEN_FIELDS: EntryFormHiddenFields = ['type'];
const HIDDEN_FIELDS_FOR_RECURRENCE: EntryFormHiddenFields = [
  'quantity',
  'frequencyInterval',
];

export default function CreateBillDrawer({
  loading = false,
  overlay = false,
  originValues,
  newContact,
  inboxItemId,
  onClose,
  onSuccess,
  fieldsToOmitOnConciliation = [],
  scheduledStatementEntry,
  open = true,
  onExit,
  submitButtonText,
  hideExtraActions = false,
}: Props) {
  const [errors, setErrors] = useState<ApiErrorForm>();
  const [isLinkDrawerVisible, setIsLinkDrawerVisible] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValues>();

  const [linkedEntry, setLinkedEntry] = useState(scheduledStatementEntry);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { companyId, organizationId } = useCompanyParams();
  const { isEnabledBillsApprovalsRules } =
    useIsEnabledBillsApprovalsRulesInCompany();
  const { createBill, isLoading: isSaving } = useCreateBill();
  const { createRecurringBill, isLoading: isLoadingRecurringBill } =
    useCreateRecurringBill();
  const { updateBill, isLoading: isUpdating } = useUpdateBill();

  const canCreateBills = useAllowedPermission({
    action: 'create',
    resource: 'bills',
  });

  const isLoading = isSaving || isLoadingRecurringBill || isUpdating;

  function onSubmit(params: SubmitParams) {
    if (linkedEntry) {
      updateBill(
        {
          ...buildSubmitParams(params),
          billId: linkedEntry.id,
          organizationId,
          companyId,
          inboxItemId,
        },
        {
          onSuccess: () => {
            snackbar({
              variant: 'success',
              message: 'Documento vinculado a lançamento existente',
            });

            onClose();
            onSuccess && onSuccess();
          },
          onError: ({ response }) => {
            snackbar({
              variant: 'error',
              message:
                'Ocorreu um erro ao vincular o Documento a lançamento existente',
            });

            if (isStructuredFormError(response?.data)) {
              setErrors(response?.data.errors as ApiErrorForm);
            }
          },
        }
      );

      return;
    }

    const billCallbacks = {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          message: 'O novo lançamento a pagar foi adicionado com sucesso!',
        });

        onClose();
        onSuccess?.();
      },
      onError: ({ response }: ApiError<ApiErrorForm>) => {
        snackbar({
          variant: 'error',
          message: 'Ocorreu um erro ao criar o lançamento a pagar!',
        });

        if (isStructuredFormError(response?.data)) {
          setErrors(response?.data.errors);
        }
      },
    };

    const payload = {
      ...buildSubmitParams(params),
      organizationId,
      companyId,
    };

    if (params.isRecurrent) {
      const payloadRecurrence = {
        ...payload,
        inboxItemsIds: inboxItemId ? [inboxItemId] : [],
      };

      createRecurringBill(payloadRecurrence, billCallbacks);

      return;
    }

    createBill({ ...payload, inboxItemId }, billCallbacks);
  }

  function onConciliate(scheduledEntry: ScheduledStatementEntry) {
    setLinkedEntry(scheduledEntry as BillPayable);

    const newInitialValues = buildOriginalValuesForConciliation(
      originValues,
      scheduledEntry,
      fieldsToOmitOnConciliation
    );

    setInitialValues(omitBy(newInitialValues, (value) => value === null));
  }

  function onUnConciliate() {
    setIsLinkDrawerVisible(false);
    setLinkedEntry(undefined);
    setInitialValues(originValues);
  }

  function getHiddenFields(): EntryFormHiddenFields {
    let hiddenFields = HIDDEN_FIELDS;

    if (linkedEntry) {
      hiddenFields = [...hiddenFields, ...HIDDEN_FIELDS_FOR_RECURRENCE];
    }

    if (isEnabledBillsApprovalsRules) {
      hiddenFields = [...hiddenFields, 'assigneesIds', 'approvalType'];
    }

    return hiddenFields;
  }

  useEffect(() => {
    if (!loading) {
      setInitialValues(originValues);
    }
  }, [originValues, loading]);

  const { user } = useProfile();

  const submitButtonTextFinal = useMemo(() => {
    if (!linkedEntry) return submitButtonText;

    const isAssignee = linkedEntry?.assignees?.some(
      (assignee) => assignee.id === user?.id
    );

    const isWaitingApproval =
      linkedEntry?.status === BillStatus.WAITING_APPROVAL;

    return isAssignee && isWaitingApproval
      ? 'Salvar e pedir aprovação'
      : 'Salvar';
  }, [linkedEntry, user, submitButtonText]);

  return (
    <Drawer
      title={
        <DrawerHeader
          title={<Title icon={<CalendarOutlined />}>Lançamento a pagar</Title>}
          extra={
            !hideExtraActions && (
              <ConciliateHeaderButton
                linkedEntry={linkedEntry}
                onUnConciliate={onUnConciliate}
                onConciliate={() => setIsLinkDrawerVisible(true)}
              />
            )
          }
        />
      }
      footer={null}
      onClose={onClose}
      visible={open}
      afterVisibleChange={onExit}
      mask={overlay}
    >
      <>
        {isLinkDrawerVisible && (
          <ConciliationDrawer
            multiple={false}
            onClose={() => setIsLinkDrawerVisible(false)}
            onConciliate={onConciliate}
            type={BalanceType.DEBIT}
            title="Vincular à lançamento existente"
            submitText="Vincular"
            organizationId={organizationId}
            companyId={companyId}
          />
        )}

        <Row gutter={[0, 24]}>
          {loading && <Loader forceCentered />}

          {!loading && (
            <EntryForm
              isFormDirty={isFormDirty}
              onFieldsChange={() => setIsFormDirty(true)}
              organizationId={organizationId}
              companyId={companyId}
              initialValues={initialValues}
              onSubmit={onSubmit}
              loading={isLoading}
              errors={errors}
              disabledFields={linkedEntry && ['isRecurrent']}
              hiddenFields={getHiddenFields()}
              submitButtonText={submitButtonTextFinal}
              newContact={newContact}
              disabled={!canCreateBills}
              editableFields={linkedEntry?.editableFields}
            />
          )}
        </Row>
      </>
    </Drawer>
  );
}
