import { useMemo } from 'react';

import type { TagProps } from 'antd';
import { Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import { Tag } from 'src/ui';

import { getAssigneeStatus } from 'src/libs/finbits/Bills';
import type {
  BillPayableAssignment,
  BillStatus,
} from 'src/libs/finbits/Bills/types';
import { BillApprovalStatus } from 'src/libs/finbits/Bills/types';
import { useOrganizationUsers } from 'src/libs/finbits/Organization/Users';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

type Props = {
  assigneeId: string;
  billStatus?: BillStatus;
  assignments?: BillPayableAssignment[];
} & TagProps;

const TAG_COLORS = {
  [BillApprovalStatus.APPROVED]: {
    tooltip: 'Aprovado',
    color: 'green',
  },
  [BillApprovalStatus.REPROVED]: {
    tooltip: 'Reprovado',
    color: 'red',
  },
  [BillApprovalStatus.PENDING]: {
    tooltip: 'Pendente',
    color: 'gold',
  },
};

export default function AssigneeTag({
  assignments = [],
  assigneeId,
  billStatus,
  closable,
  ...rest
}: Props) {
  const { organizationId } = useCompanyParams();

  const { users, isLoading } = useOrganizationUsers(
    {
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const tag = useMemo(() => {
    const assigneeStatus = getAssigneeStatus(
      assigneeId,
      assignments,
      billStatus
    );

    if (assigneeStatus) {
      return TAG_COLORS[assigneeStatus];
    }
  }, [assigneeId, assignments, billStatus]);

  const name = useMemo(() => {
    return users.find((user) => user.id === assigneeId)?.name;
  }, [users, assigneeId]);

  return (
    <Tooltip title={tag?.tooltip} placement="bottom">
      <Tag.Ellipsis
        {...rest}
        color={tag?.color}
        icon={isLoading && <SyncOutlined spin />}
        closable={!isLoading && closable}
        text={name}
      />
    </Tooltip>
  );
}
