/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { parseISO } from 'date-fns';

import type {
  PaymentSuggestion,
  SuggestionFields,
} from 'src/libs/finbits/Management/Entries/types';
import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import { useEntrySuggestionsContext } from 'src/features/entries/Suggestions/EntrySuggestionsProvider';

export default function useBillModalFormWatcher() {
  const {
    suggestionFromContact,
    updateSuggestionFromContact,
    getSuggestions,
    updateSuggestionSelected,
  } = useEntrySuggestionsContext();
  const { setValue } = useFormContext();
  const fields = useWatch();
  const { isEnabled: isLinkBillFromInboxItemNewModalEnabled } =
    useExternalFeatureFlag(Feature.LINK_BILL_FROM_INBOX_ITEM_NEW_MODAL);

  function setPaymentDetailsField(fieldName: keyof PaymentSuggestion) {
    const isPreviousSuggestion =
      fields.paymentDetails?.[fieldName] ===
      suggestionFromContact?.old?.paymentDetails?.[fieldName];

    // We need to apply suggestion when field is empty and has new suggestion, otherwise we clear it up
    if (!fields.paymentDetails?.[fieldName] || isPreviousSuggestion) {
      setValue(
        `paymentDetails.${fieldName}`,
        suggestionFromContact?.current?.paymentDetails?.[fieldName] ?? null
      );
    }

    // We need to reset field when new suggestion is empty and the old field was a suggestion
    if (
      !suggestionFromContact?.current?.paymentDetails?.[fieldName] &&
      isPreviousSuggestion
    ) {
      setValue(
        `paymentDetails.${fieldName}`,
        fieldName === 'accountType' ? null : ''
      );
    }
  }

  function resetPaymentField(fieldName: keyof PaymentSuggestion) {
    if (
      suggestionFromContact?.current?.paymentDetails?.[fieldName] &&
      fields.paymentDetails?.[fieldName] !==
        suggestionFromContact?.current?.paymentDetails?.[fieldName]
    ) {
      updateSuggestionFromContact('paymentDetails', { [fieldName]: null });
    }
  }

  function handleFirstRequest(suggestionFields: SuggestionFields) {
    setValue('description', suggestionFields?.description ?? '');
    setValue('amount', suggestionFields?.amount ?? 0);
    setValue('comments', suggestionFields?.comments ?? '');
    setValue('contactId', suggestionFields?.contact?.id ?? null);
    setValue('categoryId', suggestionFields?.category?.id ?? null);

    if (suggestionFields?.date) {
      const dateParsed = parseISO(suggestionFields.date);
      setValue('date', dateParsed);
    }

    if (suggestionFields?.relevantDate) {
      const relevantDateParsed = parseISO(suggestionFields?.relevantDate);
      setValue('relevantDate', relevantDateParsed);
    }

    const paymentMethod = suggestionFields?.paymentDetails ?? {
      paymentMethod: null,
    };
    setValue('paymentDetails', {
      ...(paymentMethod as PaymentDetails),
      digitableLine: suggestionFields?.paymentDetails?.boleto?.digitableLine,
    });

    updateSuggestionSelected(suggestionFields);
  }

  useEffect(() => {
    getSuggestions({
      contactId: fields.contactId,
      handleFirstRequest: isLinkBillFromInboxItemNewModalEnabled
        ? () => {}
        : handleFirstRequest,
    });
  }, [fields.contactId, fields.attachments?.length]);

  useEffect(() => {
    if (
      !fields.paymentDetails?.pixKey &&
      suggestionFromContact?.current?.paymentDetails?.pixKey
    ) {
      setValue(
        `paymentDetails.pixType`,
        suggestionFromContact?.current?.paymentDetails?.pixType
      );
    }
  }, [suggestionFromContact?.current?.paymentDetails?.pixKey]);

  useEffect(() => {
    if (!fields.categoryId) {
      updateSuggestionSelected({ category: null });
    }

    if (fields.categoryId !== suggestionFromContact?.current?.category?.id) {
      updateSuggestionFromContact('category', null);
    }
  }, [fields.categoryId]);

  useEffect(() => {
    resetPaymentField('accountDocument');
  }, [fields.paymentDetails?.accountDocument]);

  useEffect(() => {
    resetPaymentField('accountNumber');
  }, [fields.paymentDetails?.accountNumber]);

  useEffect(() => {
    resetPaymentField('accountType');
  }, [fields.paymentDetails?.accountType]);

  useEffect(() => {
    resetPaymentField('branchNumber');
  }, [fields.paymentDetails?.branchNumber]);

  useEffect(() => {
    resetPaymentField('routingNumber');
  }, [fields.paymentDetails?.routingNumber]);

  useEffect(() => {
    resetPaymentField('pixType');
  }, [fields.paymentDetails?.pixType]);

  useEffect(() => {
    resetPaymentField('pixKey');
  }, [fields.paymentDetails?.pixKey]);

  useEffect(() => {
    if (
      !fields.categoryId ||
      fields.categoryId === suggestionFromContact?.old?.category?.id
    ) {
      setValue(
        'categoryId',
        suggestionFromContact?.current?.category?.id ?? null
      );
    }
  }, [suggestionFromContact?.current?.category?.id]);

  useEffect(() => {
    setPaymentDetailsField('pixType');
    setPaymentDetailsField('pixKey');
    setPaymentDetailsField('accountDocument');
    setPaymentDetailsField('routingNumber');
    setPaymentDetailsField('accountType');
    setPaymentDetailsField('branchNumber');
    setPaymentDetailsField('accountNumber');
  }, [
    // TODO: Remove once Suggestion v1.0.0 deployed
    fields.paymentDetails?.paymentMethod,

    suggestionFromContact?.current?.paymentDetails?.pixType,
    suggestionFromContact?.current?.paymentDetails?.pixKey,
    suggestionFromContact?.current?.paymentDetails?.accountDocument,
    suggestionFromContact?.current?.paymentDetails?.routingNumber,
    suggestionFromContact?.current?.paymentDetails?.accountType,
    suggestionFromContact?.current?.paymentDetails?.branchNumber,
    suggestionFromContact?.current?.paymentDetails?.accountNumber,
  ]);
}
