import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { ONE_HOUR_IN_MS } from 'src/libs/finbits/Time';

import type { Bank, BankExtended } from './types';
import { BanksDecoder } from './types';

export const BTG_ROUTING_NUMBER = '208';
export const BTG_SIGNUP_URL =
  'https://app.empresas.btgpactual.com/criar-conta?origin=b750a43e-c571-43ee-b9de-c29b51bbee40';

export async function getSupportedBanks() {
  const response = await authenticatedAPI.get(
    '/open_banking/supported_institutions'
  );
  return decodeResponse<Bank[]>(response, BanksDecoder);
}

function addFullName(data: Bank[]) {
  return data.map((bank) => {
    return {
      ...bank,
      fullName: `${bank.routingNumber} - ${bank.name}`,
    };
  });
}

type UseSupportedBanksProps = {
  select?: (data: Bank[]) => any;
  enabled?: boolean;
};

export function useSupportedBanks<TData = BankExtended[]>({
  select,
  enabled,
}: UseSupportedBanksProps) {
  return useQuery<Bank[], ApiError, TData>({
    queryKey: 'supportedBanks',
    queryFn: () => getSupportedBanks(),
    staleTime: ONE_HOUR_IN_MS,
    select: select || addFullName,
    enabled,
  });
}

export function useSupportedBankProviderCodes(
  toFindRoutingNumber: string | null
) {
  const { data, ...rest } = useSupportedBanks<BankExtended['providerCodes']>({
    select: (data: Bank[]) =>
      data.find(({ routingNumber }) => toFindRoutingNumber === routingNumber)
        ?.providerCodes,
  });

  return { providerCodes: withEmptyArrayDefault(data), ...rest };
}
