import { FrownOutlined } from '@ant-design/icons';

import { ErrorMessage } from 'src/ui';

export default function NotFoundPage() {
  return (
    <ErrorMessage
      icon={<FrownOutlined />}
      message="Ops, a página não foi encontrada"
      description="Não encontramos este endereço, mas você pode voltar para a página inicial e retomar a navegação de lá."
    />
  );
}
