import type { Components, SimplePaletteColorOptions } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiButton({
  typography,
  palette,
  shadows,
}: ThemeConfig): Components['MuiButton'] {
  const { light } = palette.primary! as Partial<SimplePaletteColorOptions>;

  return {
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
      disableRipple: true,
      color: 'inherit',
    },
    styleOverrides: {
      root: {
        py: '8px',
        textTransform: 'none',
        minWidth: 'auto',
        transition: 'all 0.3s ease-in-out',
        fontWeight: 600,
        ':focus-visible': {
          boxShadow: `0px 0px 0px 4px ${light}, ${shadows[1]}`,
        },
      },
      sizeSmall: {
        fontSize: typography['text-xs']?.fontSize,
        height: '36px',
        paddingInline: '14px',
      },
      sizeMedium: {
        fontSize: typography['text-sm']?.fontSize,
        height: '40px',
        paddingInline: '16px',
      },
      sizeLarge: {
        fontSize: typography['text-sm']?.fontSize,
        height: '48px',
        paddingInline: '20px',
      },
      outlinedInherit: {
        boxShadow: shadows[1],
        border: `1px solid ${palette.divider}`,
      },
    },
  };
}
