import { Box } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { Link } from 'src/mui/_scss';

import { useOpenPortal } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useOpenBankingConnectionFeatures } from 'src/libs/finbits/Bank/OpenBanking';
import { PaymentConnectionScopes } from 'src/libs/finbits/Bank/OpenBanking/enum';
import { CONNECTION_SCOPES_DICTIONARY } from 'src/libs/finbits/Bank/OpenBanking/translation';
import { RoutingNumber } from 'src/libs/finbits/Bank/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import SantanderAutoPayment from '../SantanderAutoPayment';

import AccountFeatureBadge from './AccountFeatureBadge';

type Props = {
  account: Account;
  title?: string;
};

function getPaymentMethodsText(scopes: PaymentConnectionScopes[]) {
  const paymentText = scopes
    .filter((method) => method in CONNECTION_SCOPES_DICTIONARY)
    .map(
      (method) =>
        CONNECTION_SCOPES_DICTIONARY[
          method as keyof typeof CONNECTION_SCOPES_DICTIONARY
        ]
    )
    .join(', ')
    .replace(/, ([^,]*)$/, ' e $1');

  return paymentText
    ? `Pagamento automático via ${paymentText}`
    : 'Pagamento automático via TED, PIX e Boleto';
}

export default function AccountFeatures({
  account,
  title = 'Funcionalidades',
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const openPortal = useOpenPortal();

  const { bankStatement, paymentConnection } =
    useOpenBankingConnectionFeatures({
      companyId,
      organizationId,
      accountId: account.id,
    })?.features || {};

  const isSantander = account.routingNumber === RoutingNumber.SANTANDER;

  const hasPaymentConnection =
    paymentConnection?.scopes?.some((scope) =>
      [
        PaymentConnectionScopes.PIX,
        PaymentConnectionScopes.BOLETO,
        PaymentConnectionScopes.TED,
      ].includes(scope)
    ) || false;

  const hasDDA =
    paymentConnection?.scopes?.some(
      (scope) => scope === PaymentConnectionScopes.DDA
    ) || false;

  function handleRegisterBalance() {
    openPortal(SantanderAutoPayment, { account });
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        component="h6"
        variant="text-sm"
        color="black"
        fontWeight={700}
        marginBottom={1}
      >
        {title}
      </Typography>

      <AccountFeatureBadge
        isAccountFeatureEnabled={!!bankStatement}
        title="Leitura automática de extrato"
      />

      <AccountFeatureBadge
        isAccountFeatureEnabled={hasPaymentConnection}
        title={getPaymentMethodsText(paymentConnection?.scopes || [])}
      >
        {!hasPaymentConnection && isSantander && (
          <Link onClick={handleRegisterBalance} role="button">
            Conectar
          </Link>
        )}
      </AccountFeatureBadge>

      {!isSantander && (
        <AccountFeatureBadge
          isAccountFeatureEnabled={hasDDA}
          title="Débito direto autorizado"
        />
      )}
    </Box>
  );
}
