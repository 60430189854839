import type { TransitionProps } from '@mui/material/transitions';
import { Button, DialogContent, Divider, Slide, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { useUpdateCategory } from 'src/libs/finbits/Management/FinancialEntries/Categories';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type {
  Category,
  CategoryParams,
} from 'src/libs/finbits/Management/FinancialEntries/Categories/types';

import CategoryForm from 'src/features/categories/CategoryForm';

type Props = {
  category: Partial<Category>;
} & PortalProps;

export default function EditCategoryModal({
  category,
  onClose,
  open = true,
  onExit,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { updateCategory, isLoading, error } = useUpdateCategory();

  function handleUpdateCategory({ name, optionalAttachments }: CategoryParams) {
    updateCategory(
      {
        companyId,
        organizationId,
        payload: {
          name,
          optionalAttachments,
        },
        categoryId: category.id!,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Alteração concluída com sucesso.',
          });

          onClose();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Não foi possível aplicar as alterações. Por favor, tente novamente.',
          });
        },
      }
    );
  }

  return (
    <Dialog
      title="Editar Categoria"
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
    >
      <Dialog.Header
        size="small"
        title={
          <Stack direction="row" gap={2} alignItems="center">
            Editar Categoria
          </Stack>
        }
        onClose={onClose}
      />
      <Divider />
      <DialogContent>
        <CategoryForm
          disabledFields={['type']}
          defaultValues={category}
          responseError={error?.response?.data?.errors}
          onSubmit={handleUpdateCategory}
        />
      </DialogContent>
      <Divider />
      <Dialog.Actions size="small">
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          form="category-form"
          variant="contained"
          color="primary"
          loading={isLoading}
        >
          Salvar
        </LoadingButton>
      </Dialog.Actions>
    </Dialog>
  );
}
