import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd';

import { useCities } from 'src/libs/finbits/BrazilianData/Ibge/Cities';
import { filterOption } from 'src/libs/finbits/Form';

type Props = SelectProps<string> & {
  stateCode?: string;
};

export default function CitiesSelect({ stateCode, ...rest }: Props) {
  const { data: cities, isLoading } = useCities({ stateCode });

  return (
    <Select
      filterOption={filterOption}
      loading={isLoading}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      placeholder="Digite o nome da cidade"
      size="large"
      allowClear
      showSearch
      disabled={!stateCode}
      getPopupContainer={(trigger) => trigger.parentElement}
      {...rest}
    >
      {cities?.map((city) => (
        <Select.Option value={city.code} key={city.code}>
          {city.name}
        </Select.Option>
      ))}
    </Select>
  );
}
