import t from 'zod';

import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { ReceivableDecoder } from 'src/libs/finbits/Receivables/types';
import { FinancialEntryDecoder } from 'src/libs/finbits/Management/FinancialEntries/types';

import { NotaFiscalStatus } from 'src/features/nota-fiscal/NotaFiscalStatusTag/types';

export const NotaFiscalDecoder = t.object({
  id: t.string(),
  amount: t.number(),
  issWithheldAtSource: t.boolean(),
  issRate: t.number(),
  cofinsAmount: t.number().nullable(),
  csllAmount: t.number().nullable(),
  inssAmount: t.number().nullable(),
  irrfAmount: t.number().nullable(),
  pisAmount: t.number().nullable(),
  additionalInformation: t.string().nullable(),
  pdfUrl: t.string().nullable(),
  xmlUrl: t.string().nullable(),
  verificationCode: t.string().nullable(),
  number: t.string().nullable(),
  rpsSerie: t.string().nullable(),
  rpsNumber: t.number().nullable(),
  issuedAt: t.string().nullable(),
  contact: ContactDecoder,
  municipalServiceId: t.string(),
  recipientsEmails: t.string().array(),
  status: t.union([
    t.literal(NotaFiscalStatus.ISSUING),
    t.literal(NotaFiscalStatus.ISSUED),
    t.literal(NotaFiscalStatus.ISSUE_FAILED),
    t.literal(NotaFiscalStatus.CANCELLED),
    t.literal(NotaFiscalStatus.CANCELLING),
  ]),
  cancelledAt: t.string().nullable(),
  receivables: ReceivableDecoder.array().optional(),
  financialEntries: FinancialEntryDecoder.array().optional(),
  lastEvent: t
    .object({
      failedReason: t.string().nullable(),
      user: t
        .object({
          name: t.string(),
        })
        .optional(),
    })
    .nullish(),
});

export type NotaFiscal = t.TypeOf<typeof NotaFiscalDecoder>;

export type GetParams = {
  organizationId?: string;
  companyId?: string;
  id?: string;
};

export type GetAllParams = {
  organizationId?: string;
  companyId?: string;
};

export type PostParams = Omit<
  NotaFiscal,
  | 'id'
  | 'pdfUrl'
  | 'xmlUrl'
  | 'rpsSerie'
  | 'rpsNumber'
  | 'issuedAt'
  | 'verificationCode'
  | 'number'
> & {
  organizationId: string;
  companyId: string;
  issAmount?: string;
  cofinsRate?: string;
  csllRate?: string;
  inssRate?: string;
  irrfRate?: string;
  pisRate?: string;
  isSendEmailToContact?: boolean;
  contactId?: string;
  receivableId?: string;
  financialEntryId?: string;
};

export type DeleteParams = {
  notaFiscalId: string;
  organizationId: string;
  companyId: string;
};
