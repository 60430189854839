import type { SelectProps } from 'antd';
import { Select } from 'antd';

import { SelectAddButton } from 'src/ui';

import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { PublicCategory } from 'src/libs/finbits/PublicCompanies/types';

import { selectFilter } from 'src/features/EntryForm/selectFilter';

type Props = {
  categories: PublicCategory[];
  onOpenCreateCategory?: () => void;
} & SelectProps;

export default function CategorySelect({
  disabled,
  categories,
  loading,
  placeholder = 'Digite o nome da categoria',
  onOpenCreateCategory,
  ...rest
}: Props) {
  const hasPermission = useAllowedPermission({
    action: 'create',
    resource: 'categories',
  });

  return (
    <Select
      allowClear
      showSearch
      placeholder={placeholder}
      size="large"
      filterOption={selectFilter}
      loading={loading}
      disabled={disabled}
      getPopupContainer={(trigger) => trigger.parentElement}
      dropdownRender={(menu) => (
        <SelectAddButton
          showButton={!!onOpenCreateCategory && hasPermission}
          label="+ Adicionar Categoria"
          onClick={onOpenCreateCategory}
        >
          {menu}
        </SelectAddButton>
      )}
      {...rest}
    >
      {categories.map((category) => (
        <Select.Option value={category.id} key={category.id}>
          {category.name}
        </Select.Option>
      ))}
    </Select>
  );
}
