import { useState } from 'react';

import { Button, List, Switch, Tooltip } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { useUpdateCategory } from 'src/libs/finbits/Management/FinancialEntries/Categories';
import type { Category } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import EditCategoryModal from 'src/features/categories/EditCategoryModal';

import styles from './CategoryItem.module.less';

type Props = {
  category: Category;
};

function CategoryItem({ category }: Props) {
  const openPortal = useOpenPortal();

  const { organizationId, companyId } = useCompanyParams();

  const [checked, setChecked] = useState(category.active);
  const [loading, setLoading] = useState(false);

  const { updateCategory } = useUpdateCategory();

  function onChange() {
    setLoading(true);
    setChecked((prevChecked) => !prevChecked);

    const payload = {
      active: !category.active,
    };

    updateCategory(
      { categoryId: category.id, companyId, organizationId, payload },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: payload.active
              ? 'Categoria ativada!'
              : 'Categoria desativada!',
          });
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
          setChecked(category.active);

          snackbar({
            variant: 'error',
            message: 'Ops! Falha ao modificar a categoria.',
          });
        },
      }
    );
  }

  function handleOpenEditCategoryModal() {
    openPortal(EditCategoryModal, {
      category,
    });
  }

  return (
    <List.Item>
      <div className={styles.info}>
        {category.type === BalanceType.CREDIT ? (
          <ArrowUpOutlined className={styles.iconUp} />
        ) : (
          <ArrowDownOutlined className={styles.iconDown} />
        )}
        <span className={styles.title}>{category.name}</span>
      </div>
      <div className={styles.actions}>
        {category.optionalAttachments && (
          <Tooltip title="Lançamentos com esta categoria dispensam a obrigatoriedade de anexo">
            <InfoCircleOutlined />
          </Tooltip>
        )}
        <WithAllowedPermission
          permissions={{
            resource: 'categories',
            action: 'update',
          }}
        >
          <>
            <Button
              size="large"
              className={styles.button}
              onClick={handleOpenEditCategoryModal}
            >
              <EditOutlined />
            </Button>
            <Tooltip
              title={category.active ? 'Desativar' : 'Ativar'}
              placement="bottom"
            >
              <Switch checked={checked} loading={loading} onChange={onChange} />
            </Tooltip>
          </>
        </WithAllowedPermission>
      </div>
    </List.Item>
  );
}

export default CategoryItem;
