import { Typography } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { toCurrency } from 'src/libs/finbits/Money';

import FinalBalance from './FinalBalance';
import styles from './Summary.module.less';

type Props = {
  filtersActive?: boolean;
  incoming: number;
  outcoming: number;
};

export default function Summary({
  filtersActive = false,
  incoming,
  outcoming,
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.incomingOutcoming}>
        <div className={styles.total}>
          <Typography.Text>Entradas:</Typography.Text>
          <Typography.Text strong>{toCurrency(incoming)}</Typography.Text>
          <ArrowDownOutlined className={styles.incomingIcon} />
        </div>
        <div className={styles.total}>
          <Typography.Text>Saídas:</Typography.Text>
          <Typography.Text strong>{toCurrency(outcoming)}</Typography.Text>
          <ArrowUpOutlined />
        </div>
      </div>
      <div className={styles.finalBalance}>
        <FinalBalance filtersActive={filtersActive} />
      </div>
    </div>
  );
}
