import { useCallback, useMemo, useState } from 'react';

import { useQueryClient } from 'react-query';
import { useOpenConfirmDialog } from 'src/ui/ConfirmDialog/ConfirmDialog';
import { HelpCircleIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { importStatement } from 'src/libs/finbits/Bank/Statements';
import type { ErrorResponse } from 'src/libs/finbits/client';
import { isSuccess } from 'src/libs/finbits/client';
import type {
  ImportedStatement,
  UploadFile,
} from 'src/libs/finbits/Bank/Statements/types';
import { invalidateEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';

import DialogContent from './DialogContent';

type Props = {
  organizationId?: string;
  companyId?: string;
  onSuccess: () => void;
  onAcceptAccountCreation: () => void;
};

function extractAccountInformation(error: ErrorResponse) {
  const { routingNumberTemp, accountId, firstDailyBalanceDate } =
    error.errors.match(
      /\((?<routingNumberTemp>.+?)\)[\w\s]*\((?<accountId>.+?)\)[\w\s]*(\((?<firstDailyBalanceDate>.+)\))?/
    ).groups;

  const routingNumber = routingNumberTemp.replace(/^(0*)?(\d{3}$)/, '$2');

  const { branchNumber, accountNumber } = accountId
    .replace(/\W/g, '')
    .match(/(?<branchNumber>\w{0,4})(?<accountNumber>\w+)$/).groups;

  return { routingNumber, branchNumber, accountNumber, firstDailyBalanceDate };
}

function useUploadOfx({
  organizationId,
  companyId,
  onSuccess,
  onAcceptAccountCreation,
}: Props) {
  const [isUploading, setIsUploading] = useState(false);
  const openConfirmDialog = useOpenConfirmDialog();
  const [accountNumber, setAccountNumber] = useState('');
  const [branchNumber, setBranchNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [firstDailyBalanceDate, setFirstDailyBalanceDate] = useState<string>();
  const [currentFile, setCurrentFile] = useState<UploadFile>();
  const [importedStatement, setImportedStatement] =
    useState<ImportedStatement>();

  const queryClient = useQueryClient();

  const hasNewTransactions = useMemo(() => {
    if (!importedStatement) {
      return false;
    }

    return (
      importedStatement?.transactions?.length > 0 ||
      importedStatement?.ignoredRecords?.length > 0
    );
  }, [importedStatement]);

  const uploadFile = useCallback(
    async ({ file }: { file: UploadFile }) => {
      if (!organizationId || !companyId) return;

      setIsUploading(true);

      const response = await importStatement(organizationId, companyId, file);

      setIsUploading(false);

      if (isSuccess(response)) {
        invalidateEntriesQueries(queryClient);
        setImportedStatement(response as ImportedStatement);
        return onSuccess();
      }

      const error = response as ErrorResponse;

      if (
        error.status === 422 &&
        typeof error.errors === 'string' &&
        error.errors.match(/Account doesn't exist/)
      ) {
        const {
          routingNumber,
          branchNumber,
          accountNumber,
          firstDailyBalanceDate,
        } = extractAccountInformation(error);

        function handleConfirm() {
          setRoutingNumber(routingNumber);
          setBranchNumber(branchNumber);
          setAccountNumber(accountNumber);
          setFirstDailyBalanceDate(firstDailyBalanceDate);
          setCurrentFile(file);
          onAcceptAccountCreation();
        }

        return openConfirmDialog({
          variant: 'primary',
          icon: <HelpCircleIcon />,
          title: 'Deseja adicionar conta?',
          content: (
            <DialogContent
              routingNumber={routingNumber}
              branchNumber={branchNumber}
              accountNumber={accountNumber}
            />
          ),
          confirmText: 'Adicionar conta',
          cancelText: 'Voltar',
          onConfirm: handleConfirm,
        });
      }

      snackbar({ variant: 'error', message: error.errors });
    },
    [
      companyId,
      organizationId,
      openConfirmDialog,
      onAcceptAccountCreation,
      onSuccess,
      queryClient,
    ]
  );

  return {
    isUploading,
    uploadFile,
    currentFile,
    accountNumber,
    branchNumber,
    routingNumber,
    firstDailyBalanceDate,
    importedStatement,
    hasNewTransactions,
  };
}

export default useUploadOfx;
