import { useState } from 'react';

import { useStepsForm } from 'sunflower-antd';
import isEqual from 'lodash/isEqual';
import type { NamePath } from 'antd/lib/form/interface';

import { snackbar } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useSetupNotaFiscal } from 'src/libs/finbits/NotaFiscal/Setup';
import type { FieldError } from 'src/libs/finbits/Form';
import {
  errorsToFormField,
  isStructuredFormError,
} from 'src/libs/finbits/Form';
import { e164Format } from 'src/libs/finbits/Organization/PhoneNumber';

import { fields } from 'src/features/nota-fiscal/Steps/fields';

import type { ResultType } from '../types';

export function useEnableNotaFiscalDrawer() {
  const { companyId, organizationId } = useCompanyParams();
  const [resultType, setResultType] = useState<ResultType>();
  const [totalStepsTaken, setTotalStepsTaken] = useState(0);

  const [formErrors, setFormErrors] = useState<FieldError[]>([]);
  const [indexStepsWithError, setIndexStepsWithError] = useState<number[]>([]);

  const [isButtonFinishedLoading, setIsButtonFinishedLoading] = useState(false);
  const [isButtonSendLaterLoading, setIsButtonSendLaterLoading] =
    useState(false);

  const { setupNotaFiscal } = useSetupNotaFiscal({
    onError: ({ response }) => {
      const data = response?.data;

      if (isStructuredFormError(data)) {
        const structuredError = errorsToFormField(data.errors);

        const indexStepsWithError = getIndexStepsWithError(structuredError);

        setFormErrors(structuredError);
        setIndexStepsWithError(indexStepsWithError);
      }

      snackbar({
        variant: 'error',
        message: 'Ops! Falha ao habilitar a emissão de nota fiscal.',
      });
    },
  });

  const {
    form,
    current: currentStep,
    stepsProps,
    gotoStep,
  } = useStepsForm({
    total: 4,
    isBackValidate: false,
  });

  function handleOnSubmit() {
    const { file, phone, simpleNational, ...rest } = form.getFieldsValue(true);

    if (!companyId || !organizationId) return;

    setIsButtonFinishedLoading(true);

    const data = {
      ...rest,
      companyId,
      organizationId,
      phone: e164Format(phone),
      simpleNational: !!simpleNational,
      file,
    };

    setupNotaFiscal(data, {
      onSuccess: () => {
        setResultType('completed');
      },
      onSettled: () => {
        setIsButtonFinishedLoading(false);
      },
    });
  }

  function handleSendLater() {
    if (!companyId || !organizationId) return;

    const data = {
      ...form.getFieldsValue(true),
      companyId,
      organizationId,
      phone: e164Format(form.getFieldValue('phone')),
      simpleNational: !!form.getFieldValue('simpleNational'),
      password: null,
      file: null,
    };

    setIsButtonSendLaterLoading(true);

    setupNotaFiscal(data, {
      onSuccess: () => {
        setResultType('want_send_after');
      },
      onSettled: () => {
        setIsButtonSendLaterLoading(false);
      },
    });
  }

  function handleContinue() {
    const nextStep = currentStep + 1;

    gotoStep(nextStep);

    if (totalStepsTaken < nextStep) {
      setTotalStepsTaken(nextStep);
    }
  }

  function handlePrevious() {
    gotoStep(currentStep - 1);
  }

  function handleOnResolveError(stepIndex: number) {
    return function (fieldName: NamePath) {
      const hasError = formErrors.find((error) =>
        isEqual(error.name.toString(), fieldName.toString())
      );

      if (!hasError) return;

      form.setFields([
        {
          name: fieldName,
          errors: [],
        },
      ]);

      setFormErrors((prevState) =>
        prevState.filter(
          (error) => !isEqual(error.name.toString(), fieldName.toString())
        )
      );

      setIndexStepsWithError((prevIndexStepsWithError) =>
        prevIndexStepsWithError.filter(
          (stepIndexWithError) => stepIndexWithError !== stepIndex
        )
      );
    };
  }

  return {
    form,
    formErrors,
    resultType,
    stepsProps,
    isButtonFinishedLoading,
    isButtonSendLaterLoading,
    indexStepsWithError,
    totalStepsTaken,
    currentStep,
    handleContinue,
    handlePrevious,
    handleOnResolveError,
    handleOnSubmit,
    handleSendLater,
  };
}

function getIndexStepsWithError(errors: FieldError[]) {
  return errors.reduce<number[]>((accumulator, fieldError) => {
    const stepTypeIndex = fields.findIndex((fieldsInStep) =>
      fieldsInStep.find((field) => isEqual(field, fieldError.name))
    );

    if (!accumulator.includes(stepTypeIndex)) {
      return [...accumulator, stepTypeIndex];
    }

    return accumulator;
  }, []);
}
