import type { ImportedStatement } from 'src/libs/finbits/Bank/Statements/types';

type Props = {
  importedStatement: ImportedStatement;
};

function AccountInformation({ importedStatement }: Props) {
  return (
    <>
      {`${importedStatement.bankName} ${importedStatement.branchNumber} / ${importedStatement.accountNumber}`}
    </>
  );
}

export default AccountInformation;
