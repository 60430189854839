import type { ImporterIgnored } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import styles from './IgnoreList.module.less';

type props = {
  ignoredRows?: ImporterIgnored[];
};

export default function IgnoreList({ ignoredRows = [] }: props) {
  return (
    <ul className={styles.list}>
      {ignoredRows.map((ignored, index) => (
        <li key={index} className={styles.items}>
          Linha {ignored.rowNumber}
        </li>
      ))}
    </ul>
  );
}
