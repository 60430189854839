import t from 'zod';

import { email } from 'src/libs/finbits/resolverValidations';

export const ResetPasswordDecoder = t.object({
  message: t.string(),
});

export type ResetPassword = t.TypeOf<typeof ResetPasswordDecoder>;

export const RecoverResolver = t.object({
  email,
});

export type RecoverParams = t.TypeOf<typeof RecoverResolver>;
