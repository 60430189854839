import { useState } from 'react';

import type { QueryClient } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';

import {
  AttachmentDecoder,
  AttachmentsDecoder,
  ExternalFilesDecoder,
} from './types';
import type {
  Attachment,
  ExternalFiles,
  GetAllParams,
  GetParams,
  PostParams,
} from './types';

export function invalidateAttachmentsQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['attachments']);
}

export async function getAttachment({
  organizationId,
  companyId,
  attachmentId,
}: Partial<GetParams>) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/attachments/${attachmentId}`
  );

  return decodeResponse<Attachment>(response, AttachmentDecoder);
}

export async function getCompanyAttachments({
  organizationId,
  companyId,
  filters: params,
}: GetAllParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/attachments`,
    { params }
  );

  return decodeResponse<Attachment[]>(response, AttachmentsDecoder);
}

export async function postCompanyAttachment({
  organizationId,
  companyId,
  file,
}: PostParams) {
  const formData = new FormData();

  formData.append('file', file);

  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/attachments`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return decodeResponse<Attachment>(response, AttachmentDecoder);
}

function setAttachmentsQueryKey({
  organizationId,
  companyId,
  filters,
}: GetAllParams) {
  return ['attachments', { organizationId, companyId, filters }];
}

export function useAttachment({
  organizationId,
  companyId,
  attachmentId,
}: Partial<GetParams>) {
  const { data, ...rest } = useQuery<Attachment, ApiError>({
    enabled: !!organizationId && !!companyId && !!attachmentId,
    queryKey: ['attachment', { organizationId, companyId, attachmentId }],
    queryFn: () =>
      getAttachment({
        organizationId,
        companyId,
        attachmentId,
      }),
  });

  return { attachment: data, ...rest };
}

export function useAttachments({ organizationId, companyId }: GetAllParams) {
  const filters = { nonAssociated: true, order: 'desc' };

  const { data, ...rest } = useQuery<Attachment[], ApiError>({
    queryKey: setAttachmentsQueryKey({ organizationId, companyId, filters }),
    queryFn: () =>
      getCompanyAttachments({ organizationId, companyId, filters }),
  });

  return { attachments: withEmptyArrayDefault(data), ...rest };
}

export function useUploadFile() {
  const queryClient = useQueryClient();

  const { mutate: uploadFile, ...rest } = useMutation<
    Attachment,
    ApiError,
    PostParams
  >(postCompanyAttachment, {
    onSuccess: () => {
      invalidateAttachmentsQueries(queryClient);
    },
  });
  return { uploadFile, ...rest };
}

type DownloadOptions = {
  onError?: () => void;
};

function downloadAttachments(refreshedAttachment: Attachment | ExternalFiles) {
  const element = document.createElement('a');
  element.setAttribute('href', refreshedAttachment.url!);
  element.setAttribute('download', refreshedAttachment.name);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function useDownloadAttachment() {
  const [isDownloading, setIsDownloading] = useState(false);

  function downloadAttachment(params: GetParams, { onError }: DownloadOptions) {
    setIsDownloading(true);

    getAttachment(params)
      .then(downloadAttachments)
      .catch(() => {
        onError && onError();
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }

  return { downloadAttachment, isDownloading };
}

export async function getExternalFiles({
  organizationId,
  companyId,
  attachmentId,
}: Partial<GetParams>) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/external_files/${attachmentId}`
  );

  return decodeResponse<ExternalFiles>(response, ExternalFilesDecoder);
}

export function useDownloadExternalFile() {
  const [isDownloading, setIsDownloading] = useState(false);

  function downloadExternalFile(
    params: GetParams,
    { onError }: DownloadOptions
  ) {
    setIsDownloading(true);

    getExternalFiles(params)
      .then(downloadAttachments)
      .catch(() => {
        onError && onError();
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }

  return { downloadExternalFile, isDownloading };
}
