import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import throttle from 'lodash/throttle';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import type { ErrorsObject } from 'src/libs/finbits/Form';
import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';
import type { Label } from 'src/libs/finbits/Labels';

import type {
  ActiveParams,
  Classification,
  GetParams,
  PatchParams,
  PostParams,
} from './types';
import { ClassificationDecoder } from './types';

async function getClassifications({ organizationId, companyId }: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/classifications`
  );

  return decodeResponse<Classification[]>(
    response,
    ClassificationDecoder.array()
  );
}

async function postClassification({
  organizationId,
  companyId,
  ...params
}: PostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/classifications`,
    params
  );

  return decodeResponse<Classification>(response, ClassificationDecoder);
}

async function patchClassification({
  organizationId,
  companyId,
  classificationId,
  ...params
}: PatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/classifications/${classificationId}`,
    params
  );

  return decodeResponse<Classification>(response, ClassificationDecoder);
}

async function patchActiveClassification({
  organizationId,
  companyId,
  classificationId,
}: ActiveParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/classifications/${classificationId}/activate`
  );

  return decodeResponse<Classification>(response, ClassificationDecoder);
}

async function patchDeactiveClassification({
  organizationId,
  companyId,
  classificationId,
}: ActiveParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/classifications/${classificationId}/deactivate`
  );

  return decodeResponse<Classification>(response, ClassificationDecoder);
}

export function useClassifications(
  params: GetParams,
  customOptions: Partial<UseQueryOptions<Classification[], ApiError>> = {}
) {
  const enabled = customOptions.enabled ?? true;

  const { data, ...rest } = useQuery<Classification[], ApiError>({
    queryKey: ['classifications', params],
    queryFn: () => getClassifications(params),
    ...customOptions,
    enabled: !!params.companyId && !!params.organizationId && enabled,
  });

  return { classifications: withEmptyArrayDefault(data), ...rest };
}

export function useCreateClassifications() {
  const queryClient = useQueryClient();

  const { mutate: createClassification, ...rest } = useMutation<
    Classification,
    ApiError<ErrorsObject>,
    PostParams
  >(postClassification, {
    onSuccess: () => {
      invalidateClassificationsQueriesCall(queryClient);
    },
  });

  return { createClassification, ...rest };
}

export function useUpdateClassifications() {
  const queryClient = useQueryClient();

  const { mutate: updateClassification, ...rest } = useMutation<
    Classification,
    ApiError<ErrorsObject>,
    PatchParams
  >(patchClassification, {
    onSuccess: () => {
      invalidateClassificationsQueriesCall(queryClient);
    },
  });

  return { updateClassification, ...rest };
}

export function useActiveClassification() {
  const queryClient = useQueryClient();

  const { mutate: activeClassification, ...rest } = useMutation<
    Classification,
    ApiError<ErrorsObject>,
    ActiveParams
  >(patchActiveClassification, {
    onSuccess: () => {
      invalidateClassificationsQueriesCall(queryClient);
    },
  });

  return { activeClassification, ...rest };
}

export function useDeactiveClassification() {
  const queryClient = useQueryClient();

  const { mutate: deactiveClassification, ...rest } = useMutation<
    Classification,
    ApiError<ErrorsObject>,
    ActiveParams
  >(patchDeactiveClassification, {
    onSuccess: () => {
      invalidateClassificationsQueriesCall(queryClient);
    },
  });

  return { deactiveClassification, ...rest };
}

function invalidateClassificationsQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries(['classifications']);
}

export const invalidateClassificationsQueries = throttle(
  invalidateClassificationsQueriesCall,
  FIVE_SECONDS_IN_MS
);

export function filterAvailable(
  classifications: Classification[],
  selectedLabels: Label[] = []
) {
  return classifications.filter((classification) => {
    const isActive = classification.active;

    const selectedLabelsIds = filterLabelsIdsByClassification(
      selectedLabels,
      classification
    );

    return isActive || selectedLabelsIds.length > 0;
  });
}

export function filterLabelsIdsByClassification(
  selectedLabels: Label[] | null,
  classification: Classification
) {
  if (!selectedLabels) return [];

  return selectedLabels
    .filter((label) => label.classificationId === classification.id)
    .map((label) => label.id);
}
