import type { UseQueryOptions } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI } from 'src/libs/finbits/client';
import type { Company } from 'src/libs/finbits/Organization/Companies';
import {
  BillsApprovalsType,
  invalidateCompanyQueries,
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { invalidateApprovalRulesQueries } from 'src/libs/finbits/ApprovalRules/ApprovalRules';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import type { PatchCompanyEnabledBillsApprovals } from './types';

async function patchCompanyEnabledBillsApprovals({
  organizationId,
  companyId,
  ...params
}: PatchCompanyEnabledBillsApprovals) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/update_bills_approvals`,
    params
  );

  return response.data;
}

export function useUpdateCompanyEnabledBillsApprovals() {
  const queryClient = useQueryClient();

  const { mutate: updateCompanyEnabledBillsApprovals, ...rest } = useMutation<
    PatchCompanyEnabledBillsApprovals,
    ApiError,
    PatchCompanyEnabledBillsApprovals
  >({
    mutationFn: patchCompanyEnabledBillsApprovals,
    onSuccess: () => {
      invalidateCompanyQueries(queryClient);
      invalidateApprovalRulesQueries(queryClient);
    },
  });

  return {
    updateCompanyEnabledBillsApprovals,
    ...rest,
  };
}

export function useIsEnabledBillsApprovalsInCompany(
  options?: UseQueryOptions<Company, ApiError>
) {
  const { companyId, organizationId } = useCompanyParams();

  const { company, ...rest } = useCompany(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
      ...options,
    }
  );

  return {
    isEnabledBillsApprovals:
      company?.billsApprovals &&
      company.billsApprovals !== BillsApprovalsType.DISABLED,
    ...rest,
  };
}

export function useIsEnabledBillsApprovalsRulesInCompany(
  options?: UseQueryOptions<Company, ApiError>
) {
  const { companyId, organizationId } = useCompanyParams();

  const { company, ...rest } = useCompany(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
      ...options,
    }
  );

  return {
    isEnabledBillsApprovalsRules:
      company?.billsApprovals &&
      company?.billsApprovals === BillsApprovalsType.AUTOMATIC,
    ...rest,
  };
}
