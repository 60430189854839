import type { ResultProps } from 'antd';
import { Button, Result, Typography } from 'antd';
import { ClockCircleFilled } from '@ant-design/icons';

import { PaymentIcon, Title } from 'src/ui';

import type { ResultType } from '../types';

import styles from './ResultStep.module.less';

type Props = {
  resultType: ResultType;
  onClickWhenTypeIsCompleted?: () => void;
};

type ResultVariantProps = {
  completed: ResultProps;
  want_send_after: ResultProps;
};

const resultVariantProps: ResultVariantProps = {
  want_send_after: {
    status: 'warning',
    icon: <ClockCircleFilled />,
    title: (
      <Title level={4}>
        Ok, quase lá! Envie o certificado digital o quanto antes
      </Title>
    ),
    subTitle: (
      <Typography.Paragraph>
        Sua empresa está quase pronta para emitir notas fiscais de serviço pelo
        Finbits. Aguardamos o envio do <b>Certificado Digital A1</b> para
        completar o processo.
      </Typography.Paragraph>
    ),
  },
  completed: {
    status: 'success',
    title: (
      <Title level={4}>
        Tudo certo! A emissão de notas fiscais de serviço foi habilitada.
      </Title>
    ),
    subTitle: (
      <Typography.Paragraph>
        É importante frisar que a confirmação da comunicação completa com a
        Prefeitura é feita no momento de emissão da primeira nota fiscal.
      </Typography.Paragraph>
    ),
  },
};

export default function ResultStep({
  resultType,
  onClickWhenTypeIsCompleted,
}: Props) {
  return (
    <div className={styles.resultWrapper}>
      <Result
        {...resultVariantProps[resultType]}
        extra={
          resultType === 'completed' ? (
            <Button
              type="primary"
              size="large"
              icon={<PaymentIcon />}
              onClick={onClickWhenTypeIsCompleted}
            >
              Emitir nota fiscal
            </Button>
          ) : undefined
        }
      />
    </div>
  );
}
