type Props = {
  color?: string;
  className?: string;
};

export default function UsergroupIcon({ color, className }: Props) {
  return (
    <span className="anticon" role="img" aria-label="Users">
      <svg
        color={color}
        width="1em"
        height="1em"
        fill={color}
        className={className}
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.3788 16.881C12.3788 17.2386 12.3989 17.5921 12.4351 17.9417C12.4551 18.1024 12.3507 18.2511 12.194 18.2912C10.615 18.689 9.16854 19.5086 7.98729 20.6899C6.32791 22.3453 5.38372 24.5269 5.30738 26.8573C5.30336 27.0301 5.15872 27.1667 4.98595 27.1667H2.73193C2.68903 27.1667 2.64657 27.1581 2.60704 27.1415C2.56751 27.1248 2.53171 27.1004 2.50176 27.0697C2.4718 27.039 2.44829 27.0026 2.43262 26.9627C2.41695 26.9228 2.40943 26.8801 2.4105 26.8372C2.49086 23.7394 3.73639 20.8386 5.93818 18.6408C6.95872 17.6203 8.13595 16.8046 9.41363 16.214C7.59354 14.7394 6.43238 12.4894 6.43238 9.96623C6.43238 5.51847 10.1449 1.86623 14.6007 1.93454C17.6864 1.98275 20.3543 3.76668 21.6601 6.35016C21.7605 6.55105 21.6319 6.79213 21.4069 6.81623C20.6395 6.89659 19.8922 7.06936 19.1851 7.31445C19.0485 7.36266 18.8918 7.31043 18.8114 7.18588C18.5824 6.8323 18.3092 6.50284 17.9918 6.20552C17.0556 5.32963 15.8181 4.83543 14.5364 4.82338C13.1181 4.8073 11.7842 5.36177 10.7918 6.37829C9.88773 7.30239 9.36943 8.52784 9.33327 9.81757C9.31185 10.5295 9.43907 11.238 9.70679 11.898C9.97451 12.5579 10.3769 13.1548 10.8882 13.6506C11.3221 14.0765 11.8243 14.414 12.3708 14.6591C12.5034 14.7194 12.5797 14.868 12.5516 15.0127C12.4391 15.6194 12.3788 16.2421 12.3788 16.881Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6243 8.84525C27.0038 8.91356 30.5355 12.4854 30.5355 16.881C30.5355 19.4035 29.3734 21.6536 27.5562 23.1265C28.845 23.7247 30.0183 24.5453 31.0222 25.5508C33.224 27.7486 34.4695 30.6495 34.5499 33.7432C34.551 33.7861 34.5434 33.8288 34.5278 33.8687C34.5121 33.9086 34.4886 33.945 34.4586 33.9757C34.4287 34.0064 34.3929 34.0308 34.3533 34.0475C34.3138 34.0641 34.2714 34.0727 34.2285 34.0727H31.9785C31.8057 34.0727 31.661 33.9361 31.657 33.7633C31.5807 31.433 30.6365 29.2513 28.9771 27.5959C27.2938 25.9126 25.0751 24.9658 22.7013 24.9142C22.6343 24.9158 22.5671 24.9167 22.4998 24.9167C20.0489 24.9167 17.7507 25.8689 16.019 27.6006C14.3596 29.256 13.4154 31.4377 13.3391 33.768C13.3351 33.9408 13.1904 34.0774 13.0176 34.0774H10.7676C10.7247 34.0774 10.6823 34.0688 10.6428 34.0522C10.6032 34.0355 10.5674 34.0111 10.5375 33.9804C10.5075 33.9497 10.484 33.9133 10.4683 33.8734C10.4527 33.8335 10.4451 33.7908 10.4462 33.7479C10.5266 30.6542 11.7721 27.7533 13.9739 25.5555C14.9792 24.5486 16.1544 23.7271 17.4453 23.1287C15.6293 21.6542 14.4641 19.4042 14.4641 16.881C14.4641 12.4292 18.1726 8.77695 22.6243 8.84525ZM22.4998 22.0238C23.8739 22.0238 25.1636 21.4895 26.136 20.5171C27.1083 19.5448 27.6426 18.2551 27.6467 16.881C27.6467 15.4908 27.1002 14.185 26.1038 13.2086C25.1315 12.2564 23.8096 11.7261 22.4476 11.7381C21.0694 11.7542 19.7797 12.3046 18.8154 13.293C17.8953 14.2372 17.369 15.5109 17.3569 16.8287C17.3484 17.5131 17.4774 18.1922 17.7362 18.8257C17.9951 19.4593 18.3784 20.0345 18.8636 20.5171C19.8359 21.4895 21.1257 22.0238 22.4998 22.0238Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}
