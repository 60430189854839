import type { Action } from 'kbar';

import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Permissions } from 'src/libs/finbits/Permissions/types';

export default function useBarAction({
  action,
  permission,
  enabled = true,
}: {
  action: Action;
  permission?: Permissions | Permissions[];
  enabled?: boolean;
}) {
  const havePermission = useAllowedPermission(permission);

  if (!enabled || (!havePermission && Boolean(permission))) return undefined;

  return action;
}
