import type { FormInstance } from 'antd';
import { Form, Input, Select, Spin, Typography } from 'antd';
import type { RuleObject } from 'antd/lib/form';

import { FormItem } from 'src/ui';

import { emailRegex } from 'src/libs/finbits/Email';
import { useCompanyUsers } from 'src/libs/finbits/Organization/Users';
import { UserStatus } from 'src/libs/finbits/Organization/Users/types';

const MAX_LENGTH_MESSAGE = 255;

type Props = {
  organizationId?: string;
  companyId?: string;
  form: FormInstance;
};

function validateEmails(rule: RuleObject, emails?: string[]) {
  if (emails === undefined) return Promise.resolve();

  if (emails.every((email) => emailRegex.test(email))) {
    return Promise.resolve();
  }

  return Promise.reject(rule.message);
}

function ExportForm({ organizationId, companyId, form }: Props) {
  const { users, isLoading: isUsersLoading } = useCompanyUsers({
    companyId,
    organizationId,
    status: UserStatus.ACTIVE,
  });

  return (
    <>
      <Typography.Paragraph>
        Tudo pronto! O extrato detalhado será enviado por e-mail aos gestores da
        conta e aos contatos secionados abaixo.
      </Typography.Paragraph>
      <Form form={form} layout="vertical" name="exportForm">
        <FormItem
          hasMax={false}
          label="Enviar para"
          name="sendTo"
          rules={[
            {
              message: 'Formato de e-mail inválido',
              validator: validateEmails,
            },
          ]}
        >
          <Select
            mode="tags"
            showSearch
            placeholder="Selecione contatos ou digite emails"
            size="large"
            notFoundContent={isUsersLoading ? <Spin size="small" /> : null}
          >
            {users.map((user) => (
              <Select.Option value={user.email} key={user.id}>
                {`${user.name} <${user.email}>`}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem max={MAX_LENGTH_MESSAGE} label="Mensagem" name="message">
          <Input.TextArea
            size="large"
            rows={5}
            maxLength={MAX_LENGTH_MESSAGE}
            placeholder={`Adicione informações ou observações ao e-mail (até ${MAX_LENGTH_MESSAGE} caracteres).`}
            showCount
          />
        </FormItem>
      </Form>
    </>
  );
}
export default ExportForm;
