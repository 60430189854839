import React, { useState } from 'react';

import { Collapse } from 'antd';
import xorBy from 'lodash/xorBy';
import isEqual from 'lodash/isEqual';

import { Drawer } from 'src/ui';

import {
  getServiceId,
  useServices,
} from 'src/libs/finbits/NotaFiscal/Services';
import type {
  CompanyService,
  Service,
} from 'src/libs/finbits/NotaFiscal/Services/types';

import ServicesDrawerFooter from 'src/features/nota-fiscal/ServicesDrawer/ServicesDrawerFooter';
import ServicesDrawerHeader from 'src/features/nota-fiscal/ServicesDrawer/ServicesDrawerHeader';

import ServicesList from './ServicesList';
import styles from './ServicesDrawer.module.less';

type Props = {
  city?: string | null;
  type: 'checkbox' | 'radio';
  companyServices: Array<Service | CompanyService>;
  initialSelectedServices?: Array<Service | CompanyService>;
  onClose: () => void;
  onConfirm?: (services: Array<Service | CompanyService>) => void;
  onChangeService?: (service?: Service | CompanyService) => void;
};

export default function ServicesDrawer({
  type,
  city,
  initialSelectedServices = [],
  companyServices = initialSelectedServices,
  onClose,
  onConfirm,
  onChangeService,
}: Props) {
  const [searchValue, setSearchValue] = useState<string>();
  const [collapseListKey, setCollapseListKey] = useState<string | string[]>(
    companyServices.length === 0 ? '1' : []
  );

  const [selectedServices, setSelectedServices] = useState(
    initialSelectedServices
  );

  const { services } = useServices({ city });

  const hasChanges = !isEqual(selectedServices, initialSelectedServices);

  const selectedServicesAmount = selectedServices.length;
  const canShowFooter = hasChanges && onConfirm;

  const filteredServices = services.filter(
    (service) => !isInCompanyServices(service.id)
  );

  function isInCompanyServices(serviceId: string) {
    return companyServices.some(
      (companyService) => getServiceId(companyService) === serviceId
    );
  }

  function handleOnSearch(value: string) {
    setSearchValue(value);

    setCollapseListKey(value.length > 0 ? '1' : []);
  }

  function handleOnChangeService(service?: Service | CompanyService) {
    const serviceId = getServiceId(service);

    if (!service || !serviceId) return;

    const newSelectedServices = xorBy(selectedServices, [service]);

    setSelectedServices(newSelectedServices);

    onChangeService && onChangeService(service);
  }

  return (
    <Drawer
      visible
      onClose={onClose}
      className={styles.drawer}
      title={<ServicesDrawerHeader onSearch={handleOnSearch} />}
      footer={
        canShowFooter ? (
          <ServicesDrawerFooter
            selectedServicesAmount={selectedServicesAmount}
            onReset={() => setSelectedServices([])}
            onConfirm={() => onConfirm(selectedServices)}
          />
        ) : null
      }
    >
      <ServicesList
        type={type}
        searchValue={searchValue}
        services={companyServices}
        selectedServices={selectedServices}
        onChangeService={handleOnChangeService}
      />

      <Collapse
        ghost
        onChange={setCollapseListKey}
        activeKey={collapseListKey}
        className={styles.collpase}
      >
        <Collapse.Panel key="1" header="Lista completa de serviços">
          <ServicesList
            type={type}
            services={filteredServices}
            searchValue={searchValue}
            selectedServices={selectedServices}
            onChangeService={handleOnChangeService}
          />
        </Collapse.Panel>
      </Collapse>
    </Drawer>
  );
}
