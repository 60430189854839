import { Box, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import { format } from 'src/libs/finbits/Date';
import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

type Props = {
  inboxItem: NewInboxItem;
};

export default function DetailsHeader({ inboxItem }: Props) {
  const sentBy =
    inboxItem.fromDetail === ''
      ? inboxItem.fromName
      : `${inboxItem.fromName} (${inboxItem.fromDetail})`;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        color: 'text.secondary',
        py: 6,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="text-md"
          fontWeight="600"
          color="text.primary"
          marginBottom={1}
        >
          {inboxItem.title}
        </Typography>
        <Typography>{`Enviado por ${sentBy}`}</Typography>
      </Box>

      <Typography sx={{ width: 'fit-content' }}>
        {format(inboxItem.insertedAt, "dd/MM/yy 'às' HH:mm")}
      </Typography>
    </Stack>
  );
}
