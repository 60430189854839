import { Typography } from 'src/design-system/components';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

type Props = {
  inboxItem: NewInboxItem;
};

export default function UploadDetails({ inboxItem }: Props) {
  return (
    <Typography color="text.secondary">{inboxItem.description}</Typography>
  );
}
