import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiPaper({
  palette,
  shadows,
}: ThemeConfig): Components['MuiPaper'] {
  return {
    styleOverrides: {
      elevation8: {
        boxShadow: shadows[4],
        border: `1px solid ${palette.divider}`,
      },
    },
  };
}
