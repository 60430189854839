import { Form, Input } from 'antd';

import { FormItem } from 'src/ui';

import type { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { paymentDetailsPlaceholderDictionary } from 'src/libs/finbits/Payments/translations';

import CopyFieldButton from 'src/features/payments/CopyFieldButton';

type Props = {
  disabled?: boolean;
  fieldName?: string | string[];
};

export default function PaymentDetailsField({
  disabled = false,
  fieldName = 'paymentDetails',
}: Props) {
  const paymentDetails = Form.useWatch(fieldName);
  const paymentMethod = Form.useWatch<PaymentMethod>('paymentMethod');

  const paymentDetailsPlaceholder = paymentMethod
    ? paymentDetailsPlaceholderDictionary[paymentMethod]
    : 'Digite aqui';

  return (
    <CopyFieldButton valueToCopy={paymentDetails ?? ''}>
      <FormItem hasMax={false} label="Detalhes do pagamento" name={fieldName}>
        <Input.TextArea
          size="large"
          placeholder={paymentDetailsPlaceholder}
          autoSize={{ minRows: 2, maxRows: 12 }}
          disabled={disabled}
        />
      </FormItem>
    </CopyFieldButton>
  );
}
