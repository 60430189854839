import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useSetLDContext } from 'src/libs/launchDarkly/launchDarkly';

import { useOptionalCompanyParams } from '../finbits/Organization/Companies';

type Props = {
  children: ReactNode;
};

export default function LaunchDarklyContextSetter({ children }: Props) {
  const { companyId } = useOptionalCompanyParams();
  const { setContext } = useSetLDContext();

  useEffect(() => {
    setContext(companyId);
  }, [setContext, companyId]);

  return <>{children}</>;
}
