import type { User } from 'src/libs/finbits/Organization/Users/types';
import { useScript } from 'src/libs/finbits/hooks';

const DEFAULT_LEFT = '0px';
const DEFAULT_BOTTOM = '0px';

export function useLoadSDK() {
  return useScript(
    import.meta.env.VITE_MESSAGE_BIRD_WIDGET_ID
      ? `https://livechat.messagebird.com/bootstrap.js?widgetId=${
          import.meta.env.VITE_MESSAGE_BIRD_WIDGET_ID
        }`
      : undefined
  );
}

function setUserInfo(user?: User) {
  if (!user) return;

  const chat = window.MessageBirdChatWidget;

  chat?.setAttributes({
    email: user.email,
    name: user.name,
  });
}

export async function initChat(user?: User) {
  if (!import.meta.env.VITE_MESSAGE_BIRD_WIDGET_ID) return;

  const chat = window.MessageBirdChatWidget;

  try {
    await chat?.init(import.meta.env.VITE_MESSAGE_BIRD_WIDGET_ID);
    setUserInfo(user);

    chat?.hide();
    chat?.on('toggle', () => {
      chat.hide();
    });

    updateStyle();
  } catch (e) {
    console.error('messageBird init chat error:', e);
  }
}

export function toggleChat() {
  const chat = window.MessageBirdChatWidget;

  if (!chat) return;

  chat.hidden && chat.show();
  chat.isOpen && chat.hide();
  chat.toggleChat();
}

export function logOut() {
  const chat = window.MessageBirdChatWidget;

  if (!chat) return;

  chat.hide();
}

function updateStyle() {
  const chat = window.MessageBirdChatWidget;

  if (!chat || !chat.element) return;

  chat.element.style.left = DEFAULT_LEFT;
  chat.element.style.bottom = DEFAULT_BOTTOM;
}
