import type { ReactElement, ReactNode } from 'react';
import {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useState,
} from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Title } from 'src/ui';

import styles from './Stepper.module.less';

type StepperStore = {
  currentStep: number;
  goBackStep: () => void;
  goNextStep: () => void;
  goToStep: (step: number) => void;
  resetSteps: () => void;
};

type StepperProps = {
  children: ReactElement | ReactElement[];
  store: StepperStore;
  title?: string;
  icon?: ReactNode;
  showContinue?: boolean;
  showGoBack?: boolean;
};

type ChildrenProps = {
  lastStep: number;
  step: number;
  store: StepperStore;
  title?: string;
  icon?: ReactNode;
  showContinue?: boolean;
  showGoBack?: boolean;
};

type HeaderProps = {
  store?: StepperStore;
  title?: string;
  icon?: ReactNode;
  showGoBack?: boolean;
  onGoBack?: () => void;
};

type StepProps = {
  children: ReactNode;
  showContinue?: boolean;
  showGoBack?: boolean;
} & HeaderProps &
  Partial<ChildrenProps>;

export function useStepper(initialStep = 1) {
  const [currentStep, setCurrentStep] = useState(initialStep);

  const goBackStep = useCallback(() => {
    setCurrentStep((state) => (state === 1 ? state : state - 1));
  }, []);
  const goNextStep = useCallback(() => {
    setCurrentStep((state) => state + 1);
  }, []);
  const resetSteps = useCallback(() => {
    setCurrentStep(1);
  }, []);
  const goToStep = useCallback((step: number) => {
    setCurrentStep(step < 1 ? 1 : step);
  }, []);

  return {
    currentStep,
    goBackStep,
    goNextStep,
    resetSteps,
    goToStep,
  };
}

function Stepper({
  children,
  store,
  title,
  icon,
  showContinue = true,
  showGoBack = true,
}: StepperProps) {
  const lastStep = Children.count(children);
  const childrenWithProps = Children.map(
    children,
    (child: ReactElement<ChildrenProps>, index) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          lastStep,
          step: index + 1,
          store,
          title: child.props?.title || title,
          icon: child.props?.icon || icon,
          showContinue: child.props?.showContinue ?? showContinue,
          showGoBack: child.props?.showGoBack ?? showGoBack,
        });
      }
      return child;
    }
  );

  return <>{childrenWithProps}</>;
}

function Header({ title, store, showGoBack, icon, onGoBack }: HeaderProps) {
  const currentStep = store!.currentStep;
  const goBackStep = store!.goBackStep;
  const showGoBackButton = currentStep !== 1 && showGoBack;

  function handleOnGoBack() {
    if (onGoBack) return onGoBack();

    goBackStep();
  }

  return (
    <div className={styles.header}>
      {showGoBackButton && (
        <Button
          aria-label="Voltar"
          className={styles.goBack}
          icon={<ArrowLeftOutlined />}
          size="large"
          type="text"
          onClick={handleOnGoBack}
        />
      )}
      {!showGoBackButton && !!icon && (
        <div className={styles.iconWrapper}>{icon}</div>
      )}
      {title && (
        <Title level={4} className={styles.title}>
          {title}
        </Title>
      )}
    </div>
  );
}

function Step({
  children,
  lastStep,
  showContinue = true,
  showGoBack = true,
  step,
  store,
  title,
  icon,
  onGoBack,
}: StepProps) {
  const currentStep = store!.currentStep;
  const goNextStep = store!.goNextStep;

  if (currentStep === step) {
    return (
      <>
        <Header
          title={title}
          store={store}
          icon={icon}
          showGoBack={showGoBack}
          onGoBack={onGoBack}
        />
        {children}
        {lastStep && currentStep < lastStep && showContinue && (
          <div className={styles.continueContainer}>
            <Button onClick={goNextStep} size="large" type="primary">
              Continuar
            </Button>
          </div>
        )}
      </>
    );
  }
  return null;
}

Stepper.Step = Step;

export default Stepper;
