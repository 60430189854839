import create from 'zustand';

import type { WidgetProps } from './types';
import ConnectWidget from './ConnectWidget/ConnectWidget';

type StoreState = {
  visible: boolean;
  props?: WidgetProps;
  openConnectWidget: (props: WidgetProps) => void;
  closeConnectWidget: () => void;
};

const useStore = create<StoreState>((set) => ({
  visible: false,
  props: undefined,
  openConnectWidget: (props) => set({ visible: true, props }),
  closeConnectWidget: () => set({ visible: false, props: undefined }),
}));

function visibleSelector(state: StoreState) {
  return state.visible;
}

function propsSelector(state: StoreState) {
  return state.props;
}

function closeConnectWidgetSelector(state: StoreState) {
  return state.closeConnectWidget;
}

function openConnectWidgetSelector(state: StoreState) {
  return state.openConnectWidget;
}

export function useOpenPluggyWidget() {
  return useStore(openConnectWidgetSelector);
}

export function PluggyWidget() {
  const visible = useStore(visibleSelector);
  const props = useStore(propsSelector);
  const closeConnectWidget = useStore(closeConnectWidgetSelector);

  return (
    <>
      {visible && props && (
        <ConnectWidget {...props} onClose={closeConnectWidget} />
      )}
    </>
  );
}
