import {
  BillFrequencyRecurrence,
  BillPayableApprovalType,
  BillStatus,
} from './types';

export const billStatusDictionary = {
  [BillStatus.PENDING]: 'A pagar',
  [BillStatus.WAITING_APPROVAL]: 'Em aprovação',
  [BillStatus.APPROVED]: 'Aprovado',
  [BillStatus.REPROVED]: 'Reprovado',
  [BillStatus.WAITING_PAYMENT]: 'Enviado p/ pgto',
};

export const intervalOptionsDictionary = {
  [BillFrequencyRecurrence.DAILY]: 'Diário',
  [BillFrequencyRecurrence.MONTHLY]: 'Mensal',
  [BillFrequencyRecurrence.WEEKLY]: 'Semanal',
  [BillFrequencyRecurrence.QUARTERLY]: 'Trimestral',
  [BillFrequencyRecurrence.BIWEEKLY]: 'Quinzenal',
  [BillFrequencyRecurrence.YEARLY]: 'Anual',
};

export const APPROVAL_TYPE_DICTIONARY = {
  [BillPayableApprovalType.ALL]: 'Todos os aprovadores',
  [BillPayableApprovalType.ANY]: 'Qualquer aprovador',
};
