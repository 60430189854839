import { useEffect, useState } from 'react';

import { Button, Checkbox, Col, Form, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Modal } from 'src/ui';

import { Taxes } from '../../types';
import { TAXES_DICTIONARY } from '../../translations';

import styles from './TaxesModal.module.less';

type Props = {
  taxes?: Taxes[];
  onChangeTaxes: (taxes: Taxes[]) => void;
};

const FEDERAL_TAXES = Object.entries(Taxes).filter(
  ([_, tax]) => tax !== Taxes.ISS
);

export default function TaxesField({ taxes, onChangeTaxes }: Props) {
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    visible && form.setFieldsValue({ taxesType: taxes });
  }, [taxes, form, visible]);

  function onCancel() {
    setVisible(false);
  }

  return (
    <>
      <Button
        className={styles.modalTrigger}
        type="link"
        onClick={() => setVisible(true)}
        icon={<PlusOutlined />}
      >
        Inserir impostos federais retidos na fonte
      </Button>

      <Modal
        visible={visible}
        title="Inserir impostos federais"
        okText="Continuar"
        destroyOnClose
        onCancel={onCancel}
        onOk={() => {
          onChangeTaxes(form.getFieldValue('taxesType') ?? []);

          onCancel();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="chooseTaxes"
          initialValues={{ taxesType: taxes }}
        >
          <Form.Item name="taxesType">
            <Checkbox.Group>
              <Row gutter={[30, 30]}>
                {FEDERAL_TAXES.map(([key, value]) => (
                  <Col span={6} key={key}>
                    <Checkbox value={value}>{TAXES_DICTIONARY[value]}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
