import { Taxes } from './types';

export const TAXES_DICTIONARY: {
  [key in Taxes]: string;
} = {
  [Taxes.IRRF]: 'IRRF',
  [Taxes.INSS]: 'INSS',
  [Taxes.CSLL]: 'CSLL',
  [Taxes.COFINS]: 'COFINS',
  [Taxes.PIS]: 'PIS/PASEP',
  [Taxes.ISS]: 'ISS',
};
