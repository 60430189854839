import { AlertTriangleIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';
import type { PortalProps } from 'src/ui';

import { useUpdateApprovalRule } from 'src/libs/finbits/ApprovalRules/ApprovalRules';
import { ApprovalRuleConditionField } from 'src/libs/finbits/ApprovalRules/types';
import type {
  ApprovalRule,
  ApprovalRuleFormParams,
  ApprovalRulePatchParams,
  ApprovalRulePostParams,
} from 'src/libs/finbits/ApprovalRules/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { ApiErrorForm } from 'src/libs/finbits/client';

import ApprovalRuleModal from 'src/features/approval/approval-rules/ApprovalRuleModal/ApprovalRuleModal';

type Props = {
  approvalRule: ApprovalRule;
} & PortalProps;

function parseApprovalRuleToFormValue(
  approvalRule: ApprovalRule
): ApprovalRuleFormParams {
  return {
    defaultRule: approvalRule.defaultRule,
    description: approvalRule.description,
    assignees: approvalRule.assignees,
    approvalType: approvalRule.approvalType,
    conditions: approvalRule.defaultRule
      ? []
      : approvalRule.conditions.map((condition) => {
          if (condition.field === ApprovalRuleConditionField.CATEGORY) {
            return {
              field: condition.field,
              type: condition.type,
              categoriesValues: {
                categories:
                  condition.categoriesValues?.map(
                    (categoryValue) => categoryValue.category
                  ) ?? [],
              },
            };
          }

          if (condition.field === ApprovalRuleConditionField.LABEL) {
            return {
              type: condition.type,
              field:
                condition.labelsValues?.at(0)?.label.classificationId ?? null,
              labelsValues: {
                labels:
                  condition.labelsValues?.map(
                    (labelValue) => labelValue.label
                  ) ?? [],
              },
            };
          }

          return {
            type: condition.type,
            field: condition.field,
            amountValue: condition.amountValue,
          };
        }),
  };
}

export default function UpdateApprovalRuleModal({
  open = true,
  onClose,
  onExit,
  approvalRule,
}: Props) {
  const openConfirmDialog = useOpenConfirmDialog();
  const { organizationId, companyId } = useCompanyParams();

  const { updateApprovalRule, isLoading } = useUpdateApprovalRule();
  const defaultValues = parseApprovalRuleToFormValue(approvalRule);

  function handleConfirmDialog(
    params: ApprovalRulePostParams,
    setErrorsInForm?: (error: ApiErrorForm) => void
  ) {
    openConfirmDialog({
      variant: 'error',
      icon: <AlertTriangleIcon />,
      title: 'Confirmar alteração da regra existente?',
      content: (
        <>
          <Typography color="error.500" fontWeight="600">
            Muito cuidado! Esta ação não pode ser desfeita.
          </Typography>
          <Typography color="grey.600" marginTop={4} align="justify">
            Ao confirmar, lançamentos existentes em situação "a pagar" e
            "reprovados" cujos aprovadores foram definidos pela regra{' '}
            <strong>{params.description}</strong> também serão alterados.
          </Typography>
        </>
      ),
      checkConfirm: { text: 'Estou ciente dos riscos e quero confirmar.' },
      confirmText: 'Confirmar',
      cancelText: 'Voltar',
      onConfirm: () => handleUpdateApprovalRule(params, setErrorsInForm),
    });

    return;
  }

  function handleUpdateApprovalRule(
    params: ApprovalRulePatchParams,
    setErrorsInForm?: (error: ApiErrorForm) => void
  ) {
    updateApprovalRule(
      {
        organizationId,
        companyId,
        approvalRuleId: approvalRule.id,
        ...params,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            title: 'Regra alterada com sucesso',
            message:
              'A alteração impacta lançamentos novos e os existentes em situação "a pagar" e "reprovados" em que esta regra foi aplicada.',
          });
          onClose();
        },
        onError: (error) => {
          if (error.response?.data?.errors) {
            setErrorsInForm?.(error.response.data.errors);
          }
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao alterar a regra de aprovação.',
          });
        },
      }
    );
  }

  return (
    <ApprovalRuleModal
      open={open}
      onClose={onClose}
      onExit={onExit}
      title="Editar regra de aprovação"
      isLoading={isLoading}
      handleSubmit={handleConfirmDialog}
      defaultValues={defaultValues}
      isDefaultRule={approvalRule.defaultRule}
    />
  );
}
