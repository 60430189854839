import type { SxProps, Theme } from '@mui/material';
import { inputBaseClasses } from '@mui/material';

export const icon: SxProps<Theme> = {
  transition: '0.3s ease-in-out',
};

export const paper: SxProps<Theme> = {
  mt: 2,
  ml: -1,
  border: (theme) => `1px solid ${theme.palette.divider}`,
};

export const autocomplete: SxProps<Theme> = {
  [`.${inputBaseClasses.root}`]: {
    py: '0',
  },
};

export const textField: SxProps<Theme> = {
  height: 'auto',
};
