import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { enumColumn } from 'src/mui/_scss/Table/Columns/enumColumn/enumColumn';

import { BalanceTypeDictionary } from 'src/libs/finbits/Organization/Companies/Balances/translations';

import AmountType from 'src/features/entries/EntriesDataGrid/columns/amountTypeColumn/AmountType';
import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';
import AmountTypeSelect from 'src/features/entries/AmountTypeSelect';

export function amountTypeColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return enumColumn({
    enumTranslation: BalanceTypeDictionary,
    SelectComponent: AmountTypeSelect,
    columnProps: {
      headerName: 'Tipo',
      field: 'amountType',
      editable: false,
      disableExport: true,
      renderCell: (props) => <AmountType {...props} />,
      resizable: false,
      maxWidth: 48,
      ...columnProps,
    },
  });
}
