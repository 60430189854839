import type { PortalProps } from 'src/ui';
import { Drawer, DrawerHeader, Title } from 'src/ui';

import { pluralize } from 'src/libs/finbits/Pluralize';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useConciliationSuggestions } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';
import type { ConciliationSuggestion } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions/types';

import SuggestionBox from './SuggestionBox';
import styles from './ConciliationSuggestionsDrawer.module.less';

export function ConciliationSuggestionsDrawer({
  onClose,
  open = true,
  onExit,
}: PortalProps) {
  const { companyId, organizationId } = useCompanyParams();
  const { suggestions } = useConciliationSuggestions({
    organizationId,
    companyId,
  });

  const suggestionsCountText = pluralize(
    suggestions.length,
    '1 conciliação sugerida',
    `${suggestions.length} conciliações sugeridas`
  );

  return (
    <Drawer
      visible={open}
      title={<DrawerHeader title={<Title>Sugestões de conciliação</Title>} />}
      onClose={onClose}
      afterVisibleChange={onExit}
    >
      <div className={styles.container}>
        <summary>
          <Title level={5}> Você tem {suggestionsCountText} </Title>
        </summary>
        <ul>
          {suggestions.map((suggestion: ConciliationSuggestion) => (
            <SuggestionBox key={suggestion.id} suggestion={suggestion} />
          ))}
        </ul>
      </div>
    </Drawer>
  );
}
