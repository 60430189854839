import { useFormContext } from 'react-hook-form';
import { FormField } from 'src/mui/_scss';

import { InputCurrency } from 'src/mui';

type Props = {
  index: number;
};

export default function AmountValues({ index }: Props) {
  const form = useFormContext();

  return (
    <FormField
      label="Valor"
      name={`conditions.${index}.amountValue.amount`}
      control={form.control}
      shouldUnregister
    >
      {(field) => {
        return <InputCurrency {...field} placeholder="valor" />;
      }}
    </FormField>
  );
}
