import { useState } from 'react';

import type { FormInstance } from 'antd';
import { Button, Col, Form, Row } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { FormItem, MaskedInput, Popover } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCompanyContact } from 'src/libs/finbits/Organization/Companies/Contacts';
import { cnpjMask } from 'src/libs/finbits/Documents';
import { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import ContactSelect from 'src/features/contacts/ContactSelect';
import CreateContactDrawer from 'src/features/contacts/CreateContactDrawer';
import EditContactDrawer from 'src/features/contacts/EditContactDrawer';

import styles from './ContactField.module.less';

type Props = {
  form: FormInstance;
};

export default function ContactField({ form }: Props) {
  const [isCreateContactDrawer, setIsCreateContactDrawer] = useState(false);
  const [isEditContactDrawer, setIsEditContactDrawer] = useState(false);

  const contactId = Form.useWatch('contactId');

  const { organizationId, companyId } = useCompanyParams();

  const { contact, isLoading } = useCompanyContact(
    {
      companyId,
      organizationId,
      contactId,
    },
    {
      onSuccess: (contact) => {
        if (contact.type !== 'legal') {
          form.setFieldsValue({
            contactId: null,
          });
        }
      },
    }
  );

  function toggleCreateContactDrawerVisibility() {
    setIsCreateContactDrawer((prevState) => !prevState);
  }

  function toggleEditContactDrawerVisibility() {
    setIsEditContactDrawer((prevState) => !prevState);
  }

  function handleCreateContact(contactId: string) {
    form.setFieldsValue({
      contactId,
    });
  }

  return (
    <>
      {isCreateContactDrawer && (
        <CreateContactDrawer
          hideBackButton
          defaultContactDocument={{
            type: ContactType.LEGAL,
            isInternational: false,
          }}
          onCreate={handleCreateContact}
          onClose={toggleCreateContactDrawerVisibility}
        />
      )}

      {isEditContactDrawer && contactId && (
        <EditContactDrawer
          contactId={contactId}
          onClose={toggleEditContactDrawerVisibility}
        />
      )}

      <Row gutter={[16, 25]}>
        <Col span={12}>
          <FormItem
            required
            name="contactId"
            aria-label="Contado (tomador)"
            label={
              <>
                Contato (tomador)
                <Popover
                  trigger="hover"
                  content="Notas fiscais só podem ser emitidas para contatos nacionais e pessoa jurídica."
                  className={styles.labelIcon}
                  overlayClassName={styles.popoverOverlay}
                >
                  <InfoCircleOutlined />
                </Popover>
              </>
            }
          >
            <ContactSelect
              loading={isLoading}
              companyId={companyId}
              contactType={ContactType.LEGAL}
              excludeInternationalContacts
              organizationId={organizationId}
              onOpenCreateContact={toggleCreateContactDrawerVisibility}
            />
          </FormItem>
          {contactId && (
            <Button
              type="link"
              role="link"
              size="small"
              icon={<PlusOutlined />}
              onClick={toggleEditContactDrawerVisibility}
            >
              Ver detalhes do contato
            </Button>
          )}
        </Col>
        <Col span={12}>
          <FormItem shouldUpdate label="CNPJ">
            {() => (
              <MaskedInput
                disabled
                data-testid="contactDocument"
                value={contact?.document ?? ''}
                size="large"
                mask={[cnpjMask]}
              />
            )}
          </FormItem>
        </Col>
      </Row>
    </>
  );
}
