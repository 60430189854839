import { useEffect } from 'react';

import type t from 'zod';
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import { fromZodError } from 'zod-validation-error';
import type { UseInfiniteQueryOptions } from 'react-query';
import { useInfiniteQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';

import { snackbar } from 'src/mui';

import authorizationInterceptor from './AuthorizationInterceptor';
import unauthorizedInterceptor from './UnauthorizedInterceptor';
import apiErrorsInterceptor from './ApiErrorsInterceptor';

export const baseURL = import.meta.env.VITE_FINBITS_API_URL!;
const apiConfig = {
  baseURL,
  headers: { 'Content-Type': 'application/json', 'Accept-Language': 'pt_BR' },
  env: {
    FormData,
  },
};

const EMPTY_ARRAY: unknown[] = [];

export type ApiResponse = {
  type: 'success' | 'error';
  data: any;
  status?: number;
};

export type ErrorResponse = {
  isError: true;
  message: string;
  errors: any;
  status?: number;
};

export type ApiErrorForm = {
  [key: string]: string[];
};

export type ApiErrorDefault = string | ApiErrorForm;

export type ApiError<T = ApiErrorDefault> = AxiosError<{
  message: string;
  errors: T;
}>;

export function handleSuccess(response: AxiosResponse): Promise<ApiResponse> {
  return Promise.resolve({ data: response.data, type: 'success' });
}

export function handleError(err: ApiError): Promise<ApiResponse> {
  try {
    return Promise.resolve({
      data: { ...err?.response?.data, status: err.response?.status },
      type: 'error',
    });
  } catch {
    return Promise.reject(err);
  }
}

export function decodeResponse<T>(
  { data }: AxiosResponse<T>,
  decoder: t.Schema<T>
): T {
  const result = decoder.safeParse(data);

  if (result.success) return result.data;

  const error = fromZodError(result.error);

  snackbar({
    variant: 'error',
    message: 'Ocorreu um erro! Atualize sua página para prosseguir.',
  });

  throw new Error(error.message);
}

export function withEmptyArrayDefault<T>(value: T[] | undefined) {
  return value ?? (EMPTY_ARRAY as T[]);
}

function isError(response: any): boolean {
  return !!response.isError;
}
export function isSuccess(response: any): boolean {
  return !isError(response);
}

export function hasValidationErrors(response: any): boolean {
  return response.status === 422;
}

export const authenticatedAPI: AxiosInstance = axios.create(apiConfig);
export const API: AxiosInstance = axios.create(apiConfig);

authenticatedAPI.interceptors.request.use(authorizationInterceptor);
authenticatedAPI.interceptors.response.use(
  (response) => response,
  unauthorizedInterceptor
);

authenticatedAPI.interceptors.response.use(
  (response) => response,
  apiErrorsInterceptor
);

export type DecodedPaginatedResponse<T> = {
  data: T;
  startCursor?: string;
  endCursor?: string;
};

type AxiosPaginatedResponse<Data> = {
  data: Data;
  startCursor?: string;
  endCursor?: string;
  hasMore: boolean;
};

export function decodePaginatedResponse<T>(
  { data }: AxiosResponse<AxiosPaginatedResponse<T>>,
  decoder: t.Schema<T>
): DecodedPaginatedResponse<T> {
  const result = decoder.safeParse(data.data);

  if (result.success)
    return {
      startCursor: data.startCursor,
      endCursor: data.hasMore ? data.endCursor : undefined,
      data: result.data,
    };

  const error = fromZodError(result.error);

  snackbar({
    variant: 'error',
    message: 'Ocorreu um erro! Atualize sua página para prosseguir.',
  });

  throw new Error(error.message);
}

export function useInfiniteScroll<T>(
  options: UseInfiniteQueryOptions<DecodedPaginatedResponse<T>, ApiError>
) {
  const { data, hasNextPage, fetchNextPage, isFetching, ...rest } =
    useInfiniteQuery<DecodedPaginatedResponse<T>, ApiError>({
      ...options,
      getNextPageParam: (lastPage) => lastPage.endCursor,
      getPreviousPageParam: (previousPage) => previousPage.startCursor,
    });
  const { ref, inView } = useInView();

  useEffect(() => {
    if (!isFetching && hasNextPage && inView) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, inView, fetchNextPage]);

  return {
    ref,
    data: data?.pages.map((page) => page.data).flat(),
    isFetching,
    ...rest,
  };
}
