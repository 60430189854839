import type { ReactNode, SyntheticEvent } from 'react';

import { Button, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib/icons';

import styles from './Pagination.module.less';

type Props = {
  children: ReactNode;
  canNextPage: boolean;
  canPreviousPage: boolean;
  onPrevious: (e: SyntheticEvent) => void;
  onNext: (e: SyntheticEvent) => void;
};

export default function Pagination({
  children,
  canPreviousPage,
  canNextPage,
  onPrevious,
  onNext,
}: Props) {
  return (
    <div className={styles.pages}>
      <Button
        className={styles.pagesButton}
        type="text"
        onClick={onPrevious}
        disabled={!canPreviousPage}
        aria-label="anterior"
      >
        <LeftOutlined />
      </Button>
      <Typography.Text className={styles.pagesText}>{children}</Typography.Text>
      <Button
        className={styles.pagesButton}
        type="text"
        onClick={onNext}
        disabled={!canNextPage}
        aria-label="próximo"
      >
        <RightOutlined />
      </Button>
    </div>
  );
}
