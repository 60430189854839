export function greaterThanZeroValidator(
  _rule: any,
  value?: number | string | null
) {
  if (
    Number(value) > 0 ||
    value === null ||
    value === undefined ||
    value === ''
  ) {
    return Promise.resolve(null);
  }

  return Promise.reject('Deve ser maior que 0');
}
