type Props = {
  color?: string;
  className?: string;
};

function ConciliateIcon({ color, className }: Props) {
  return (
    <span className="anticon" role="img" aria-label="conciliar">
      <svg
        color={color}
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className={className}
      >
        <path
          d="M19.6875 3.3125H4.3125V9.09375H7.3125C7.36406 9.09375 7.40625 9.13594 7.40625 9.1875V10.5938C7.40625 10.6453 7.36406 10.6875 7.3125 10.6875H3.375C3.01172 10.6875 2.71875 10.3945 2.71875 10.0312V2.375C2.71875 2.01172 3.01172 1.71875 3.375 1.71875H20.625C20.9883 1.71875 21.2812 2.01172 21.2812 2.375V10.0312C21.2812 10.3945 20.9883 10.6875 20.625 10.6875H16.6875C16.6359 10.6875 16.5938 10.6453 16.5938 10.5938V9.1875C16.5938 9.13594 16.6359 9.09375 16.6875 9.09375H19.6875V3.3125Z"
          fill="currentColor"
        />
        <path
          d="M21.2812 13.9688V21.625C21.2812 21.9883 20.9883 22.2812 20.625 22.2812H3.375C3.01172 22.2812 2.71875 21.9883 2.71875 21.625V13.9688C2.71875 13.6055 3.01172 13.3125 3.375 13.3125H7.3125C7.36406 13.3125 7.40625 13.3547 7.40625 13.4062V14.8125C7.40625 14.8641 7.36406 14.9062 7.3125 14.9062H4.3125V20.6875H19.6875V14.9062H16.6875C16.6359 14.9062 16.5938 14.8641 16.5938 14.8125V13.4062C16.5938 13.3547 16.6359 13.3125 16.6875 13.3125H20.625C20.9883 13.3125 21.2812 13.6055 21.2812 13.9688Z"
          fill="currentColor"
        />
        <path
          d="M9 11.5135C9 11.4243 9.07297 11.3514 9.16216 11.3514H11.3514V9.16216C11.3514 9.07297 11.4243 9 11.5135 9H12.4865C12.5757 9 12.6486 9.07297 12.6486 9.16216V11.3514H14.8378C14.927 11.3514 15 11.4243 15 11.5135V12.4865C15 12.5757 14.927 12.6486 14.8378 12.6486H12.6486V14.8378C12.6486 14.927 12.5757 15 12.4865 15H11.5135C11.4243 15 11.3514 14.927 11.3514 14.8378V12.6486H9.16216C9.07297 12.6486 9 12.5757 9 12.4865V11.5135Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

export default ConciliateIcon;
