import { useCallback } from 'react';

import { snackbar } from 'src/mui';

import {
  useMigrationCompletedListener,
  useStatementImportFinishedListener,
} from 'src/libs/finbits/Listeners';
import type {
  MigrationFinishedMessage,
  StatementImportFinishedMessage,
} from 'src/libs/finbits/Channels/types';

import { useModalOpenState } from 'src/features/DEPRECATED_accounts/CreateAccount/ImportFinish/ImportFinish';

function StatementImportFinishedGlobalNotification() {
  const importFinishModalOpen = useModalOpenState();

  const handler = useCallback(
    (message: StatementImportFinishedMessage) => {
      if (importFinishModalOpen) return;

      const { institutionName, totalTransactions } = message;

      snackbar({
        variant: 'success',
        message:
          totalTransactions === 0
            ? `Conexão concluída com a instituição ${institutionName}! Novas transações serão importadas automaticamente.`
            : `Importação concluída! ${totalTransactions} transações foram importadas da instituição ${institutionName}.`,
        persist: true,
      });
    },
    [importFinishModalOpen]
  );

  useStatementImportFinishedListener(handler);

  function migrationHandler(message: MigrationFinishedMessage) {
    snackbar({
      variant: 'success',
      message: message.message,
    });
  }

  useMigrationCompletedListener(migrationHandler);

  return null;
}

export default StatementImportFinishedGlobalNotification;
