import { useReducer, useState } from 'react';

import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';
import sortBy from 'lodash/sortBy';
import { Button, List, Typography } from 'antd';
import { AlertCircleIcon } from 'src/mui/_icons';

import { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';

import { snackbar } from 'src/mui';

import { Drawer, DrawerHeader, Title, useOpenConfirmDialog } from 'src/ui';

import { format } from 'src/libs/finbits/Date';
import { patchImportedStatement } from 'src/libs/finbits/Bank/Statements';
import { invalidateEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { isSuccess } from 'src/libs/finbits/client';
import type {
  ImportedStatement,
  StatementTransactionRevision,
} from 'src/libs/finbits/Bank/Statements/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './RevisionDrawer.module.less';
import TransactionItem from './TransactionItem';

type Props = {
  importedStatement: ImportedStatement;
  visible?: boolean;
  onClose: () => void;
  closeModal?: () => void;
};

enum actions {
  TOGGLE,
  RESET,
}

type ActionToggleTransaction = {
  type: actions.TOGGLE;
  transactionId: string;
};
type ActionReset = {
  type: actions.RESET;
};
type Actions = ActionToggleTransaction | ActionReset;

export function reducer(
  state: StatementTransactionRevision[],
  action: Actions
) {
  switch (action.type) {
    case actions.TOGGLE:
      return state.map((transaction) => {
        if (transaction.id === action.transactionId) {
          return {
            ...transaction,
            shouldTransact: !transaction.shouldTransact,
          };
        }
        return transaction;
      });
    case actions.RESET:
      return state.map((transaction) => {
        return {
          ...transaction,
          shouldTransact: transaction.originalShouldTransact,
        };
      });
    default:
      return state;
  }
}

function transactionHaveChanged(transaction: StatementTransactionRevision) {
  return transaction.originalShouldTransact !== transaction.shouldTransact;
}

function RevisionDrawer({
  importedStatement,
  visible,
  onClose,
  closeModal,
}: Props) {
  const [isSaving, setIsSaving] = useState(false);
  const { organizationId, companyId } = useCompanyParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const openConfirmDialog = useOpenConfirmDialog();

  const transactions = importedStatement?.transactions
    ? importedStatement?.transactions.map((item) => ({
        ...item,
        originalShouldTransact: true,
        shouldTransact: true,
      }))
    : [];
  const ignoredRecords = importedStatement?.ignoredRecords
    ? importedStatement?.ignoredRecords.map((item) => ({
        ...item,
        originalShouldTransact: false,
        shouldTransact: false,
      }))
    : [];
  const transactionsAndIgnoreds = [...transactions, ...ignoredRecords];
  const transactionsSortedByDate = sortBy(
    transactionsAndIgnoreds,
    (transaction: StatementTransactionRevision) => transaction.date
  );

  const [state, dispatch] = useReducer(reducer, transactionsSortedByDate);

  const hasChanges = !!state.find(transactionHaveChanged)?.id;

  const firstTransactionDate = transactionsSortedByDate[0]?.date;
  const lastTransactionDate =
    transactionsSortedByDate[transactionsSortedByDate.length - 1]?.date;

  function onCloseDrawer() {
    if (hasChanges) {
      return openConfirmDialog({
        variant: 'error',
        icon: <AlertCircleIcon />,
        title: 'Tem certeza que deseja sair?',
        content:
          'Você não aplicou os ajustes, se sair as alterações serão perdidas.',
        confirmText: 'Sim, quero sair',
        cancelText: 'Cancelar',
        onConfirm: () => {
          dispatch({ type: actions.RESET });
          onClose();
        },
      });
    } else {
      onClose();
    }
  }

  function toggleTransaction(transactionId: string) {
    dispatch({
      type: actions.TOGGLE,
      transactionId,
    });
  }

  async function onSave() {
    setIsSaving(true);

    const payload = {
      accountId: importedStatement.accountId,
      toIgnore: state
        .filter(
          (transaction) =>
            transactionHaveChanged(transaction) && !transaction.shouldTransact
        )
        .map((transaction) => transaction.id),
      toTransaction: state
        .filter(
          (transaction) =>
            transactionHaveChanged(transaction) && transaction.shouldTransact
        )
        .map((transaction) => transaction.id),
    };

    const response = await patchImportedStatement({
      organizationId,
      companyId,
      payload,
    });
    setIsSaving(false);

    if (isSuccess(response)) {
      invalidateEntriesQueries(queryClient);
      const firstActiveTransaction = state.find(
        (transaction) => transaction.shouldTransact
      );
      if (firstActiveTransaction) {
        const params = new URLSearchParams();
        params.append('accountId', importedStatement.accountId);
        params.append('date', firstActiveTransaction.date);

        navigate({
          pathname: generateCompanyPath(RouteKey.ENTRIES, {
            companyId,
            organizationId,
          }),
          search: params.toString(),
        });
      }

      onClose();
      closeModal && closeModal();

      return snackbar({
        variant: 'success',
        message: 'Alterações concluídas com sucesso.',
      });
    }

    return snackbar({
      variant: 'error',
      message:
        'Não foi possível aplicar as alterações. Por favor, tente novamente.',
    });
  }

  return (
    <Drawer
      destroyOnClose={true}
      footer={
        <div className={styles.actionContainer}>
          <Button
            disabled={!hasChanges}
            onClick={onSave}
            size="large"
            type="primary"
            loading={isSaving}
          >
            Ajustar
          </Button>
        </div>
      }
      onClose={onCloseDrawer}
      title={<DrawerHeader title={<Title>Revisar importação</Title>} />}
      visible={visible}
    >
      <Typography.Text>
        Esta é a lista de lançamentos do OFX da conta{' '}
        <b>{importedStatement.name}</b> relativa ao período de{' '}
        <b>
          {firstTransactionDate && format(firstTransactionDate, 'dd/MM/yyyy')}
        </b>{' '}
        à{' '}
        <b>
          {lastTransactionDate && format(lastTransactionDate, 'dd/MM/yyyy')}
        </b>
        . O Finbits faz um filtro automático dos itens sem relevância para a
        gestão financeira, mas você pode gerenciar ativando ou desativando itens
        que devem ou não ser considerados.
      </Typography.Text>

      <div className={styles.list}>
        <List
          size="large"
          header="Lançamentos"
          dataSource={state}
          renderItem={(transaction) => (
            <TransactionItem
              key={transaction.id}
              transaction={transaction}
              toggleTransaction={toggleTransaction}
            />
          )}
        />
      </div>
    </Drawer>
  );
}

export default RevisionDrawer;
