import { Skeleton as MuiSkeleton, Stack } from '@mui/material';

import Card from './Card';
import Table from './Table/Table';

type Props = {
  classNames?: string;
};

export default function Skeleton({ classNames }: Props) {
  return (
    <Card
      classNames={classNames}
      title={
        <Stack direction="row" alignItems="center" gap={3} pl={1}>
          <MuiSkeleton variant="circular" width={24} height={24} />
          <MuiSkeleton variant="circular" width={24} height={24} />
          <MuiSkeleton variant="rounded" width={40} height={20} />
          <MuiSkeleton variant="rounded" width={130} height={20} />
        </Stack>
      }
      action={
        <Stack direction="row" alignItems="center" gap={3} pr={1}>
          <MuiSkeleton variant="circular" width={20} height={20} />
          <MuiSkeleton variant="circular" width={20} height={20} />
        </Stack>
      }
    >
      <Table
        headers={
          <>
            <Table.HeaderCell>
              <MuiSkeleton variant="rounded" width={100} height={12} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <MuiSkeleton variant="rounded" width={100} height={12} />
            </Table.HeaderCell>
          </>
        }
        data={
          <>
            <Table.DataCell>
              <MuiSkeleton variant="rounded" width={50} height={12} />{' '}
            </Table.DataCell>
            <Table.DataCell>
              <MuiSkeleton variant="rounded" width={50} height={12} />{' '}
            </Table.DataCell>
          </>
        }
      />
    </Card>
  );
}
