import type { ReactNode } from 'react';

import cn from 'classnames';

import styles from './Mark.module.less';

type Props = {
  children: ReactNode;
  className?: string;
  type?: 'full' | 'default';
};

function Mark({ children, className, type, ...rest }: Props) {
  const markClass = type === 'full' ? styles.fullMark : styles.mark;

  return (
    <span className={cn([markClass, className])} {...rest}>
      {children}
    </span>
  );
}

export default Mark;
