import { Button, Form, Input } from 'antd';

import { snackbar } from 'src/mui';

import { FormItem } from 'src/ui';

import type { ApiErrorForm } from 'src/libs/finbits/client';
import { emailRegex } from 'src/libs/finbits/Email';
import {
  errorsToFormField,
  isStructuredFormError,
} from 'src/libs/finbits/Form';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCreateInvitation } from 'src/libs/finbits/Organization/Invitation';
import type { PostInvitationParams } from 'src/libs/finbits/Organization/Invitation/types';

import showsInvitationSuccessNotification from './InvitationSuccessNotification';
import styles from './CreateInvitationForm.module.less';

type Props = {
  onSuccess: () => void;
};

export default function CreateInvitationForm({ onSuccess }: Props) {
  const [form] = Form.useForm();

  const { organizationId } = useCompanyParams();

  const { createInvitation, isLoading } = useCreateInvitation();

  async function handleCreateInvitation(
    params: Pick<PostInvitationParams, 'email'>
  ) {
    createInvitation(
      {
        organizationId,
        ...params,
      },
      {
        onSuccess: (user) => {
          showsInvitationSuccessNotification(user);
          onSuccess();
        },
        onError: ({ response }) => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao enviar o convite',
          });

          if (isStructuredFormError(response?.data)) {
            form.setFields(
              errorsToFormField(response?.data.errors as ApiErrorForm)
            );
          }
        },
      }
    );
  }

  return (
    <Form form={form} onFinish={handleCreateInvitation}>
      <FormItem
        name="email"
        rules={[
          {
            message: 'Formato de e-mail inválido',
            pattern: emailRegex,
          },
        ]}
      >
        <Input placeholder="E-mail" aria-label="E-mail" />
      </FormItem>
      <div className={styles.footer}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Enviar convite
        </Button>
      </div>
    </Form>
  );
}
