import type { ReactElement } from 'react';
import { useEffect } from 'react';

import type { PopperPlacementType } from '@mui/material';
import { ClickAwayListener, Grow, Popper } from '@mui/material';
import { Alert } from 'src/design-system/components';
import type {
  GridCellEditStartParams,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

import type { PortalProps } from 'src/ui';

import styles from './Alerts.module.scss';

type Props<T extends GridValidRowModel> = {
  text?: ReactElement | string;
  severity?: any;
  params: GridCellEditStartParams<T>;
  cellRef: HTMLDivElement | null;
  apiRef: GridApiPremium;
  anchorOrigin?: PopperPlacementType;
} & PortalProps;

export default function Alerts<T extends GridValidRowModel>({
  text,
  severity,
  params,
  cellRef,
  apiRef,
  onClose,
  open = true,
  onExit,
}: Props<T>) {
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        if (params.cellMode === 'edit') {
          apiRef.stopCellEditMode({ id: params.row.id, field: params.field });
        }
        onClose();
      }
    }

    cellRef?.addEventListener('keydown', handleKeyDown);

    return () => {
      cellRef?.removeEventListener('keydown', handleKeyDown);
    };
  }, [cellRef, onClose, params, apiRef]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={open}
        anchorEl={cellRef || undefined}
        transition
        placement="top"
        className={styles.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} onExit={onExit}>
            <div>
              <Alert severity={severity}>{text}</Alert>
            </div>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
}
