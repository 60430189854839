export enum NotaFiscalStatus {
  ISSUING = 'issuing',
  ISSUED = 'issued',
  ISSUE_FAILED = 'issue_failed',
  CANCELLED = 'cancelled',
  CANCELLING = 'cancelling',
}

export const notaFiscalStatusDictionary = {
  [NotaFiscalStatus.ISSUING]: 'Aguardando emissão',
  [NotaFiscalStatus.ISSUED]: 'Emitida',
  [NotaFiscalStatus.ISSUE_FAILED]: 'Falha na emissão',
  [NotaFiscalStatus.CANCELLED]: 'Cancelada',
  [NotaFiscalStatus.CANCELLING]: 'Em cancelamento',
};
