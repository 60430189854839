import { useEffect, useState } from 'react';

function buildScript(src: string): HTMLScriptElement {
  const existingScript: HTMLScriptElement | null = document.querySelector(
    `script[src="${src}"]`
  );

  if (existingScript && !existingScript.hasAttribute('data-status')) {
    existingScript.setAttribute('data-status', 'loading');
  }

  if (existingScript) return existingScript;

  const newScript = document.createElement('script');
  newScript.src = src;
  newScript.async = true;
  newScript.setAttribute('data-status', 'loading');
  document.head.appendChild(newScript);
  return newScript;
}

type Status = 'idle' | 'loading' | 'ready' | 'error';

function useScript(src?: string): Status {
  const [status, setStatus] = useState<Status>(src ? 'loading' : 'idle');

  useEffect(() => {
    if (!src) {
      setStatus('idle');
      return;
    }

    const script = buildScript(src);
    setStatus(script.getAttribute('data-status') as Status);

    function eventHandler(event: Event) {
      const status: Status = event.type === 'load' ? 'ready' : 'error';
      script.setAttribute('data-status', status);

      setStatus(status);
    }

    script.addEventListener('load', eventHandler);
    script.addEventListener('error', eventHandler);

    return () => {
      script.removeEventListener('load', eventHandler);
      script.removeEventListener('error', eventHandler);
    };
  }, [src]);

  return status;
}

export default useScript;
