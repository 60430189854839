import type { ComponentProps, ComponentType } from 'react';
import { useCallback, useState } from 'react';

import { v4 } from 'uuid';
import create from 'zustand';

export type PortalProps = {
  onClose: () => void;
  open?: boolean;
  onExit?: () => void;
};

type Props = PortalProps & any;

type Portal = {
  id: string;
  component: ComponentType<Props>;
};

type StoreState = {
  portals: Portal[];
  openPortal: <T extends ComponentType<Props>>(
    component: T,
    props?: Omit<
      ComponentProps<typeof component>,
      'onClose' | 'open' | 'onExit'
    >
  ) => void;
  closePortal: (id: string) => void;
};

const useStore = create<StoreState>((set) => {
  function closePortal(id: string) {
    set((state) => ({
      portals: state.portals.filter((portal) => portal.id !== id),
    }));
  }

  return {
    portals: [],
    openPortal: (Component: ComponentType<Props>, props) => {
      const id = v4();

      function PortalComponent() {
        const [isOpen, setIsOpen] = useState(true);

        const handleClose = useCallback(() => {
          setIsOpen(false);
        }, []);

        const handleExit = useCallback(() => {
          !isOpen && closePortal(id);
        }, [isOpen]);

        return (
          <Component
            {...props}
            open={isOpen}
            onClose={handleClose}
            onExit={handleExit}
          />
        );
      }

      set((state) => ({
        portals: [...state.portals, { component: PortalComponent, id }],
      }));
    },
    closePortal,
  };
});

function portalsSelector(state: StoreState) {
  return state.portals;
}

function openPortalSelector(state: StoreState) {
  return state.openPortal;
}

export function destroyPortals() {
  useStore.setState({
    portals: [],
  });
}

export function useOpenPortal() {
  return useStore(openPortalSelector);
}

export function PortalProvider() {
  const portals = useStore(portalsSelector);

  return (
    <>
      {portals.map(({ id, component: Component }) => (
        <Component key={id} />
      ))}
    </>
  );
}
