import type { FormListFieldData } from 'antd';
import { Col, Form, Input, Row, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import type { NamePath } from 'antd/lib/form/interface';

import { AmountInput, DrawerHeaderButton, FormItem, Title } from 'src/ui';

import { entryText } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { greaterThanZeroValidator } from 'src/libs/finbits/Amount';

import SplitDrawerButtonTrigger from 'src/features/entries/Drawers/SplitForm/SplitDrawerButtonTrigger';
import type { FormValues } from 'src/features/EntryForm/types';

import styles from './SplitItem.module.less';

type Props = {
  disabled: boolean;
  index: number;
  removeSplit: (index: number | number[]) => void;
  field: FormListFieldData;
  initialValues?: FormValues;
  onFormSubmit: (newFormValues?: FormValues) => void;
  scheduledIdsSelectedToConciliate?: string[];
  scheduledIdsConciliated?: string[];
  onUnconciliateSuccess: (scheduledEntryId?: string | null) => void;
  action: 'create' | 'update';
  shouldCallSubmitOnUnconciliate?: boolean;
};

export default function SplitItem({
  removeSplit,
  index,
  disabled,
  field,
  initialValues,
  onFormSubmit,
  scheduledIdsSelectedToConciliate,
  scheduledIdsConciliated,
  onUnconciliateSuccess,
  action,
  shouldCallSubmitOnUnconciliate = false,
}: Props) {
  const title = `${entryText({
    type: initialValues?.type,
    objectType: 'financial_entry',
  })} ${index + 1}`;

  return (
    <div className={styles.container} key={field.key}>
      <Row justify="space-between">
        <Title>{title}</Title>
        <Space align="center">
          <FormItem
            name={[field.name, 'scheduledEntryId']}
            className={styles.openDrawerButton}
          >
            <FormItemWrapper
              fieldPath={[action, field.name]}
              onFormSubmit={onFormSubmit}
              scheduledIdsSelectedToConciliate={
                scheduledIdsSelectedToConciliate
              }
              scheduledIdsConciliated={scheduledIdsConciliated}
              onUnconciliateSuccess={onUnconciliateSuccess}
              shouldCallSubmitOnUnconciliate={shouldCallSubmitOnUnconciliate}
            />
          </FormItem>

          <WithAllowedPermission
            permissions={{
              resource: 'financialEntrySplit',
              action: 'delete',
            }}
          >
            <DrawerHeaderButton
              aria-label="Remover Quebra"
              icon={<DeleteOutlined />}
              onClick={() => removeSplit(field.name)}
              title="Remover"
            />
          </WithAllowedPermission>
        </Space>
      </Row>

      <Form.Item name={[field.name, 'id']} hidden>
        <input />
      </Form.Item>

      <Form.Item hidden name={[field.name, 'type']}>
        <input />
      </Form.Item>

      <Row gutter={[24, 12]}>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              if (curValues[action][field.name] === undefined) return false;

              return (
                prevValues[action][field.name].description !==
                curValues[action][field.name].description
              );
            }}
          >
            {() => (
              <FormItem
                required
                label="Descrição"
                name={[field.name, 'description']}
              >
                <Input
                  aria-label="descrição"
                  size="large"
                  disabled={disabled}
                />
              </FormItem>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              if (curValues[action][field.name] === undefined) return false;

              return (
                prevValues[action][field.name].amount !==
                curValues[action][field.name].amount
              );
            }}
          >
            {() => (
              <FormItem
                name={[field.name, 'amount']}
                label="Valor"
                required
                hasMax={false}
                rules={[
                  {
                    validator: greaterThanZeroValidator,
                  },
                ]}
              >
                <AmountInput placeholder="0,00" disabled={disabled} />
              </FormItem>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

function FormItemWrapper({
  fieldPath,
  onFormSubmit,
  scheduledIdsSelectedToConciliate,
  scheduledIdsConciliated,
  onUnconciliateSuccess,
  shouldCallSubmitOnUnconciliate,
}: { fieldPath: NamePath } & Pick<
  Props,
  | 'onFormSubmit'
  | 'scheduledIdsSelectedToConciliate'
  | 'scheduledIdsConciliated'
  | 'onUnconciliateSuccess'
  | 'shouldCallSubmitOnUnconciliate'
>) {
  const { status } = Form.Item.useStatus();

  return (
    <SplitDrawerButtonTrigger
      fieldPath={fieldPath}
      onAdd={onFormSubmit}
      scheduledIdsSelectedToConciliate={scheduledIdsSelectedToConciliate}
      scheduledIdsConciliated={scheduledIdsConciliated}
      onUnconciliateSuccess={onUnconciliateSuccess}
      aria-label="Ver mais informações"
      danger={status === 'error'}
      shouldCallSubmitOnUnconciliate={shouldCallSubmitOnUnconciliate}
    >
      Ver mais informações
    </SplitDrawerButtonTrigger>
  );
}
