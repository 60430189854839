import type { ReactNode } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { useHasAccessToCurrentContext } from 'src/router/useHasAccessToCurrentContext';

import { Loader } from 'src/ui';

import ForbiddenPage from 'src/pages/ForbiddenPage/ForbiddenPage';

import { useIsAuthenticated } from 'src/libs/finbits/Auth';
import { useProfile } from 'src/libs/finbits/Organization/Users';

type Props = {
  children: ReactNode;
};

export default function AuthenticatedRoute({ children }: Props) {
  const isAuthenticated = useIsAuthenticated();

  const { user, isLoading } = useProfile();
  const { search, pathname } = useLocation();

  const hasAccessToCurrentContext = useHasAccessToCurrentContext(user);

  if (isLoading) {
    return <Loader size="large" full />;
  }

  if (isAuthenticated && !hasAccessToCurrentContext) {
    return <ForbiddenPage />;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  }

  const params = new URLSearchParams();
  params.set('afterLoginRedirectTo', pathname + search);

  return <Navigate to={{ pathname: '/login', search: params.toString() }} />;
}
