import { Table } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';

import { Badge } from 'src/mui';

import { useGetOverdueEntries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useClassifications } from 'src/libs/finbits/Classifications';

import { useBuildColumns } from '../EntriesDataGrid/useBuildColumns';

import styles from './EntriesOverdueDataGrid.module.scss';

type Props = {
  companyId: string;
  organizationId: string;
};

const STORAGE_KEY = 'overdue_entries_data_grid_state_v2_';

export default function EntriesOverdueDataGrid({
  companyId,
  organizationId,
}: Props) {
  const { data, isLoading } = useGetOverdueEntries({
    organizationId,
    companyId,
  });

  const { classifications } = useClassifications({ companyId, organizationId });

  const entries = data?.entries ?? [];

  const columns = useBuildColumns(classifications, { editable: false });

  return (
    <>
      <div className={styles.dataGridTitle}>
        <Typography variant="text-md" fontWeight="700" component="h6">
          Atrasados
        </Typography>
        <Badge color="secondary" badgeContent={entries.length} showZero />
      </div>

      <Table
        rows={entries}
        storageKey={`${STORAGE_KEY}${companyId}`}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
}
