import { useState } from 'react';

import { Button, Form, Typography } from 'antd';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Modal, Stepper, useStepper } from 'src/ui';

import type { CreateStatementExportParams } from 'src/libs/finbits/Management/StatementsExports';
import { useCreateStatementExport } from 'src/libs/finbits/Management/StatementsExports';
import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

import Confirmation from './Confirmation';
import ExportForm from './ExportForm';
import ModalTitle from './ModalTitle';

type Props = {
  organizationId?: string;
  companyId?: string;
  selectedAccounts: Account[];
  dateRange: [Date, Date];
  accounts?: Account[];
} & PortalProps;

type FormParams = Partial<
  Pick<
    CreateStatementExportParams,
    'includeScheduledEntries' | 'accounts' | 'message' | 'sendTo'
  > & {
    dateRange: [string, string];
  }
>;

function ExportModal({
  open = true,
  onClose,
  selectedAccounts,
  organizationId,
  companyId,
  dateRange,
  accounts,
  onExit,
}: Props) {
  const store = useStepper();
  const [formValues, setFormValues] = useState<FormParams>();

  const { createStatementExport, isLoading } = useCreateStatementExport();

  const [form] = Form.useForm();

  const { goNextStep, goBackStep, resetSteps, currentStep } = store;

  function closeModal() {
    resetSteps();
    onClose();
  }

  function handleSubmit() {
    if (currentStep === 3) {
      closeModal();

      return;
    }

    form.submit();
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        const formParams = { ...formValues, ...values };

        if (name === 'exportForm') {
          createStatementExport(
            {
              organizationId,
              companyId,
              startDate: formParams.dateRange![0],
              endDate: formParams.dateRange![1],
              accounts: formParams.accounts,
              message: formParams.message,
              sendTo: formParams.sendTo ?? [],
              includeScheduledEntries: formParams.includeScheduledEntries,
            },
            {
              onSuccess: () => {
                goNextStep();
              },
              onError: () => {
                snackbar({
                  variant: 'error',
                  message: 'Ops! Falha na exportação do extrato',
                });
              },
            }
          );
        }

        if (name === 'comfirmationForm') {
          goNextStep();
        }

        setFormValues(formParams);
      }}
    >
      <Modal
        visible={open}
        afterClose={onExit}
        onCancel={closeModal}
        title={<ModalTitle currentStep={currentStep} onGoBack={goBackStep} />}
        footer={
          <Button
            htmlType="submit"
            type="primary"
            onClick={handleSubmit}
            loading={isLoading}
          >
            {currentStep === 3 ? 'OK' : 'Continuar'}
          </Button>
        }
      >
        <Stepper store={store}>
          <Stepper.Step showContinue={false}>
            <Confirmation
              form={form}
              accounts={accounts}
              dateRange={dateRange}
              selectedAccounts={selectedAccounts}
            />
          </Stepper.Step>
          <Stepper.Step showGoBack={false} showContinue={false}>
            <ExportForm
              form={form}
              organizationId={organizationId}
              companyId={companyId}
            />
          </Stepper.Step>
          <Stepper.Step showGoBack={false}>
            <Typography.Paragraph>
              Agora deixa com a gente! Estamos reunindo os detalhes dos
              lançamentos e em seguida enviaremos compactados por e-mail. Esta
              ação pode levar alguns minutos.
            </Typography.Paragraph>
          </Stepper.Step>
        </Stepper>
      </Modal>
    </Form.Provider>
  );
}

export default ExportModal;
