import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { KBarResults, useMatches } from 'kbar';

import {
  avatar,
  listItem,
  listItemButton,
  listItemText,
  listSubheader,
  primaryTypography,
  secondaryTypography,
} from './RenderResults.sx';

export default function RenderResults() {
  const { results } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <ListSubheader sx={listSubheader}>{item}</ListSubheader>
        ) : (
          <ListItem sx={listItem}>
            <ListItemButton sx={listItemButton} selected={active}>
              <ListItemIcon>
                <Avatar sx={avatar}>{item.icon}</Avatar>
              </ListItemIcon>
              <ListItemText
                sx={listItemText}
                primary={item.name}
                primaryTypographyProps={primaryTypography}
                secondary={item.subtitle}
                secondaryTypographyProps={secondaryTypography}
              />
            </ListItemButton>
          </ListItem>
        )
      }
    />
  );
}
