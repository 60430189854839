import { Space, Switch, Typography } from 'antd';
import type { SwitchProps } from 'antd/lib/switch';

import styles from './OptedInSwitch.module.less';

type Props = {
  title: string;
  description?: string;
} & SwitchProps;

export default function OptedInSwitch({ title, description, ...rest }: Props) {
  return (
    <>
      <Typography.Paragraph className={styles.text}>
        {title}
      </Typography.Paragraph>

      <Space direction="vertical">
        <Switch {...rest} />

        {description && (
          <Typography.Text type="secondary" className={styles.text}>
            {description}
          </Typography.Text>
        )}
      </Space>
    </>
  );
}
