import type { MouseEvent } from 'react';
import { useState } from 'react';

import type { IconButtonProps } from '@mui/material';
import { IconButton } from '@mui/material';
import { DotsVerticalIcon } from 'src/mui/_icons';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

import InboxItemMenu from 'src/features/inbox-items/InboxItemMenu';

type Props = {
  onCloseFromOption?: () => void;
  inboxItem?: NewInboxItem;
  withCloseOption?: boolean;
} & IconButtonProps;

export default function InboxItemMenuWithIconButton({
  inboxItem,
  withCloseOption,
  onCloseFromOption,
  ...rest
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isVisibility = !!anchorEl;

  function toggleVisibility(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl((state) => (state ? null : event.currentTarget));
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        aria-label="Abrir menu com mais ações"
        onClick={toggleVisibility}
        {...rest}
      >
        <DotsVerticalIcon />
      </IconButton>
      <InboxItemMenu
        open={isVisibility}
        anchorEl={anchorEl}
        onClose={handleClose}
        onCloseFromOption={onCloseFromOption}
        inboxItem={inboxItem}
        withCloseOption={withCloseOption}
      />
    </>
  );
}
