import { useState } from 'react';

import type { FormInstance } from 'antd';
import { Button, Col, Form, Input, Row, Switch, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { FormItem } from 'src/ui';

import { emailRegex } from 'src/libs/finbits/Email';

import styles from './EmailField.module.less';

type Props = {
  form: FormInstance;
};

export default function EmailField({ form }: Props) {
  const [isSendEmailToContact, setIsSendEmailToContact] = useState(
    form.getFieldValue('isSendEmailToContact') ?? true
  );

  function handleOnChangeIsSendEmail(checked: boolean) {
    setIsSendEmailToContact(checked);

    if (!checked) {
      const recipientsEmailsFields = form.getFieldValue('recipientsEmails');

      recipientsEmailsFields.forEach((_: unknown, index: number) => {
        form.setFields([{ name: ['recipientsEmails', index], errors: [] }]);
      });
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Typography.Text>
          Enviar nota fiscal para contato (tomador)?
        </Typography.Text>
        <div className={styles.switchWrapper}>
          {isSendEmailToContact ? 'Sim' : 'Não'}
          <Form.Item
            name="isSendEmailToContact"
            valuePropName="checked"
            initialValue={isSendEmailToContact}
          >
            <Switch
              defaultChecked={isSendEmailToContact}
              aria-label="Enviar nota fiscal para contato (tomador)?"
              onChange={handleOnChangeIsSendEmail}
            />
          </Form.Item>
        </div>
      </Col>

      <Col span={24}>
        <Form.List name="recipientsEmails">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...rest }, index) => (
                <div key={key} className={styles.formItemWrapper}>
                  <FormItem
                    required={isSendEmailToContact}
                    name={name}
                    className={styles.inputWrapper}
                    rules={
                      isSendEmailToContact
                        ? [
                            {
                              message: 'Formato de e-mail inválido',
                              pattern: emailRegex,
                            },
                          ]
                        : undefined
                    }
                    {...rest}
                  >
                    <Input
                      disabled={!isSendEmailToContact}
                      placeholder="Digite um e-mail"
                      size="large"
                      aria-label="E-mail para receber nota fiscal"
                    />
                  </FormItem>
                  {fields.length > 1 && (
                    <Button
                      size="large"
                      htmlType="button"
                      disabled={!isSendEmailToContact || index === 0}
                      onClick={() => remove(name)}
                      aria-label="Remover campo de e-mail"
                    >
                      <DeleteOutlined />
                    </Button>
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  block
                  disabled={!isSendEmailToContact}
                  htmlType="button"
                  size="large"
                  type="dashed"
                  onClick={() => add(undefined)}
                  aria-label="Adicionar outro e-mail"
                  icon={<PlusOutlined />}
                >
                  Adicionar outro e-mail
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
}
