import type { QueryClient } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import { removeAccentuation } from 'src/libs/finbits/Accentuation';
import type { ErrorsObject } from 'src/libs/finbits/Form';

import { CompanyServicesDecoder, ServicesDecoder } from './types';
import type {
  CompanyService,
  GetCompanyServicesParams,
  GetServicesParams,
  Service,
  UpdateCompanyServicesParams,
} from './types';

type QueryOptions<T> = {
  onSuccess?: (data: T) => void;
};

async function getServices({ city }: GetServicesParams) {
  const url = `/nota_fiscal/cities/${city}/services`;

  const response = await authenticatedAPI.get(url);

  return decodeResponse<Service[]>(response, ServicesDecoder);
}

async function getCompanyServices({
  organizationId,
  companyId,
}: GetCompanyServicesParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/nota_fiscal/services`;

  const response = await authenticatedAPI.get(url);

  return decodeResponse<CompanyService[]>(response, CompanyServicesDecoder);
}

async function updateCompanyServices({
  organizationId,
  companyId,
  services,
}: UpdateCompanyServicesParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/nota_fiscal/services`;

  const response = await authenticatedAPI.put(url, {
    services,
  });

  return decodeResponse<CompanyService[]>(response, CompanyServicesDecoder);
}

export function useServices(
  { city }: GetServicesParams,
  { onSuccess }: QueryOptions<Service[]> = {}
) {
  const { data = [], ...rest } = useQuery({
    enabled: !!city,
    queryKey: ['services', { city }],
    queryFn: () =>
      getServices({
        city,
      }),
    onSuccess,
  });

  return {
    services: data,
    ...rest,
  };
}

export function useCompanyServices(
  { organizationId, companyId }: GetCompanyServicesParams,
  { onSuccess }: QueryOptions<CompanyService[]> = {}
) {
  const { data = [], ...rest } = useQuery({
    enabled: !!organizationId && !!companyId,
    queryKey: ['company_services', { organizationId, companyId }],
    queryFn: () => getCompanyServices({ organizationId, companyId }),
    onSuccess,
  });

  return {
    services: data,
    ...rest,
  };
}

export function useUpdateCompanyServices() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    CompanyService[],
    ApiError<ErrorsObject>,
    UpdateCompanyServicesParams
  >({
    mutationFn: updateCompanyServices,
    onSuccess: () => {
      invalidateCompanyServicesQueries(queryClient);
    },
  });

  return {
    updateCompanyServices: mutate,
    ...rest,
  };
}

function invalidateCompanyServicesQueries(queryClient: QueryClient) {
  return queryClient.invalidateQueries(['company_services']);
}

export function filterServices(
  searchValue: string,
  service: Service | CompanyService
) {
  const value = removeAccentuation(searchValue.toLowerCase());
  const description = removeAccentuation(service.description.toLowerCase());

  return service.code.includes(value) || description.includes(value);
}

export function isService(value: unknown): value is Service {
  return !!Object(value)?.id;
}

export function isCompanyService(value: unknown): value is CompanyService {
  return !!Object(value)?.municipalServiceId;
}

export function getServiceId(service?: Service | CompanyService) {
  if (isCompanyService(service)) {
    return service.municipalServiceId;
  }

  return service?.id;
}
