type Props = {
  amount: number;
};

export default function ContactPluralize({ amount }: Props) {
  const contactText = amount === 1 ? `contato` : `contatos`;

  return (
    <span data-testid="contactText">
      <b>{amount} </b>
      {contactText}
    </span>
  );
}
