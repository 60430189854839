import { useEffect } from 'react';

import { Col, Form, Input, Row, Select, Spin, Switch, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import type { FieldData } from 'rc-field-form/lib/interface';

import { DrawerFooter, FormItem, Popover } from 'src/ui';

import { useSpecifications } from 'src/libs/finbits/NotaFiscal/Specifications';
import { filterOption } from 'src/libs/finbits/Form';

import StepInformation from '../StepInformation';
import type { StepProps } from '../types';

import styles from './ComplementaryDataStep.module.less';

export default function ComplementaryDataStep({
  form,
  errors,
  showTitle = true,
  buttonText = 'Continuar',
  initialValues,
  isButtonLoading = false,
  onSubmit,
  onPrevious,
  onResolveError,
}: StepProps) {
  const cityCode = initialValues?.address?.city;

  const { specifications, isLoading } = useSpecifications({
    cityCode,
  });

  useEffect(() => {
    if (errors) {
      form.setFields(errors);
    }
  }, [form, errors]);

  function municipalRegistrationValidator(_: unknown, value: string) {
    if (!value) {
      return Promise.reject();
    }

    if (value.length > 0 && value.length < 16 && value.match(/^[0-9]+$/)) {
      return Promise.resolve();
    }

    return Promise.reject('Inscrição municipal inválida');
  }

  return (
    <>
      <StepInformation
        title={showTitle ? 'Dados Complementares' : undefined}
        description="Precisamos destas informações sobre a natureza da empresa. É possível
        encontrá-las na área logada da sua empresa no site da prefeitura."
      />
      <Form
        preserve
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
        onFieldsChange={(changedFields: FieldData[]) => {
          changedFields.forEach(({ name }) => {
            onResolveError && onResolveError(name);
          });
        }}
      >
        <Row className={styles.formWrapper} gutter={24}>
          <Col span={12}>
            <FormItem
              required
              name="municipalRegistration"
              label={
                <>
                  Inscrição Municipal
                  <Popover
                    trigger="hover"
                    content="A inscrição municipal da empresa deve conter de 1 a 15 dígitos (somente números). Exemplo válido: 11356083"
                    className={styles.labelIcon}
                    overlayClassName={styles.popoverOverlay}
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </>
              }
              rules={[
                {
                  validator: municipalRegistrationValidator,
                },
              ]}
            >
              <Input size="large" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              required
              name="specialTaxRuleId"
              label="Regime Especial de Tributação"
            >
              <Select
                filterOption={filterOption}
                placeholder="Selecione"
                size="large"
                allowClear
                showSearch
                loading={isLoading}
                notFoundContent={isLoading ? <Spin size="small" /> : null}
              >
                {specifications?.specialTaxRules.map((taxRule) => (
                  <Select.Option value={taxRule.id} key={taxRule.id}>
                    {taxRule.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <div className={styles.simpleNational}>
            <Form.Item name="simpleNational" valuePropName="checked">
              <Switch aria-label="Empresa optante pelo Simples Nacional" />
            </Form.Item>

            <Typography.Text>
              Empresa optante pelo Simples Nacional
            </Typography.Text>
          </div>

          <DrawerFooter>
            {onPrevious && <DrawerFooter.BackButton onClick={onPrevious} />}
            <DrawerFooter.SubmitButton loading={isButtonLoading}>
              {buttonText}
            </DrawerFooter.SubmitButton>
          </DrawerFooter>
        </Row>
      </Form>
    </>
  );
}
