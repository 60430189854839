import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function ServerIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="server"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M5 5H5.01M5 17H5.01M4.2 9H17.8C18.9201 9 19.4802 9 19.908 8.78201C20.2843 8.59027 20.5903 8.28431 20.782 7.90798C21 7.48016 21 6.92011 21 5.8V4.2C21 3.0799 21 2.51984 20.782 2.09202C20.5903 1.71569 20.2843 1.40973 19.908 1.21799C19.4802 1 18.9201 1 17.8 1H4.2C3.07989 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2V5.8C1 6.92011 1 7.48016 1.21799 7.90798C1.40973 8.28431 1.71569 8.59027 2.09202 8.78201C2.51984 9 3.0799 9 4.2 9ZM4.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V16.2C21 15.0799 21 14.5198 20.782 14.092C20.5903 13.7157 20.2843 13.4097 19.908 13.218C19.4802 13 18.9201 13 17.8 13H4.2C3.07989 13 2.51984 13 2.09202 13.218C1.71569 13.4097 1.40973 13.7157 1.21799 14.092C1 14.5198 1 15.0799 1 16.2V17.8C1 18.9201 1 19.4802 1.21799 19.908C1.40973 20.2843 1.71569 20.5903 2.09202 20.782C2.51984 21 3.0799 21 4.2 21Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
