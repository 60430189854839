import { forwardRef } from 'react';
import type { Ref } from 'react';

import type { InputBaseComponentProps } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Input } from 'src/mui/_scss';

import { toDecimal, toInteger } from 'src/libs/finbits/Money';

type Props = Omit<InputBaseComponentProps, 'onChange'> & {
  onChange?: (value: number, e?: InputEvent) => void;
  signed?: boolean;
};

function InputCurrency(
  { onChange, value, signed = false, inputRef, ...props }: Props,
  ref: Ref<HTMLInputElement>
) {
  return (
    <NumericFormat
      {...props}
      placeholder="0,00"
      getInputRef={ref}
      value={Boolean(value) ? toDecimal(value) : null}
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      allowNegative={signed}
      onValueChange={(values) => {
        const { value: inputValue } = values;
        onChange?.(Boolean(inputValue) ? toInteger(inputValue) : 0);
      }}
    />
  );
}

export default forwardRef(InputCurrency);
