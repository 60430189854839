import type { ReactElement } from 'react';

import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Permissions } from 'src/libs/finbits/Permissions/types';

type RowAction = {
  name: string;
  icon: ReactElement;
  label: string;
  onClick: () => void;
  'aria-label'?: string;
};

export function useRowAction({
  rowAction,
  permissions,
  enabled = true,
}: {
  rowAction: RowAction;
  permissions?: Permissions | Permissions[];
  enabled?: boolean;
}) {
  const havePermission = useAllowedPermission(permissions);

  if (!enabled || (!havePermission && Boolean(permissions))) return undefined;

  return rowAction;
}
