import { Button, Col, Form, Row, Switch } from 'antd';

import { FormItem } from 'src/ui';

import { isFormCompleted } from 'src/libs/finbits/Form';

import BankSelect from 'src/features/DEPRECATED_accounts/BankSelect';

import ModalActions from '../ModalActions';

export type SubmitProps = {
  routingNumber: string;
  isCashAccount: boolean;
};

type Props = {
  onSubmit: ({ routingNumber, isCashAccount }: SubmitProps) => void;
  initialValues?: {
    routingNumber?: string | null;
    isCashAccount?: boolean;
  };
};

export default function BankSelectForm({
  onSubmit,
  initialValues = { isCashAccount: false },
}: Props) {
  const [form] = Form.useForm();

  const isCashAccount = Form.useWatch('isCashAccount', form);

  return (
    <div>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item shouldUpdate noStyle>
              {() => (
                <FormItem
                  required={!isCashAccount}
                  name="routingNumber"
                  label="Banco"
                >
                  <BankSelect disabled={isCashAccount} />
                </FormItem>
              )}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="isCashAccount"
              label="Conta não bancarizada (exemplo: caixinha)"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          <ModalActions>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={
                    initialValues?.routingNumber || initialValues?.isCashAccount
                      ? undefined
                      : !isFormCompleted(
                          form,
                          isCashAccount ? ['isCashAccount'] : ['routingNumber']
                        )
                  }
                >
                  Continuar
                </Button>
              )}
            </Form.Item>
          </ModalActions>
        </Row>
      </Form>
    </div>
  );
}
