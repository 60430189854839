import type { RouteObject } from 'react-router';

import type { Permissions } from 'src/libs/finbits/Permissions';

export type PublicRoute = {
  title?: string;
} & RouteObject;

export type OrganizationRoute = {
  permission?: Permissions;
  title?: string;
} & RouteObject;

export enum RouteKey {
  INBOX_ITEMS = 'inbox_items',
  BILLS_TO_PAY = 'bills_to_pay',
  ENTRIES = 'entries',
  DASHBOARD = 'dashboard',
  NOTA_FISCAL = 'nota_fiscal',
  PROFILE = 'profile',
  APPROVAL = 'approval',
  LOGOUT = 'logout',
  USERS = 'users',
  BALANCES = 'balances',
  NO_COMPANY_PROFILE = 'no_company_profile',
  ACCOUNTS = 'accounts',
  CONTACTS = 'contacts',
  LABELS = 'labels',
  CATEGORIES = 'categories',
  FinBot = 'finbot',
  COMPANY = 'company',
  APPROVAL_RULES = 'approval_rules',
  CONFIG_APPROVAL_FLOW = 'config_approval_flow',
}
