import { Menu } from 'antd';

import { useNotaFiscal } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { useActionItems } from '../useActionItems';

type Props = {
  notaFiscalId: string;
};

export default function ActionItems({ notaFiscalId }: Props) {
  const { organizationId, companyId } = useCompanyParams();

  const { notaFiscal, isFetching, isError } = useNotaFiscal({
    organizationId,
    companyId,
    id: notaFiscalId,
  });

  const items = useActionItems({
    notaFiscal,
  });

  return <Menu disabled={isFetching || isError} items={items} />;
}
