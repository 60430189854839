import { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

export const inboxItemContactTypeDictionary: Record<string, string> = {
  legal: 'CNPJ',
  natural: 'CPF',
};

export const inboxItemIssuerTradeName = {
  [ContactType.LEGAL]: 'Razão Social',
  [ContactType.NATURAL]: 'Nome',
};
