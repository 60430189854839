import { useState } from 'react';

import cn from 'classnames';
import type { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
} from '@mui/material';
import { CloseIcon, DotIcon } from 'src/mui/_icons';
import { Link } from 'react-router-dom';
import { Typography } from 'src/design-system/components';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Dialog, IconButton } from 'src/mui';

import type { PortalProps } from 'src/ui';

import Img1 from 'src/assets/approval-tip-1.png';
import Img2 from 'src/assets/approval-tip-2.png';
import Img3 from 'src/assets/approval-tip-3.png';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './ApprovalTipModal.module.scss';

type Props = { automaticTips?: boolean } & PortalProps;

export default function ApprovalTipModal({
  open = true,
  onClose,
  onExit,
  automaticTips = false,
}: Props) {
  const [currentStepp, setCurrentStepp] = useState(1);

  const { organizationId, companyId } = useCompanyParams();

  function onNext() {
    if (currentStepp === 3) return onClose();

    if (automaticTips) return onClose();

    setCurrentStepp(currentStepp + 1);
  }

  function onBack() {
    if (currentStepp === 1) return onClose();

    setCurrentStepp(currentStepp - 1);
  }

  function buttonText() {
    if (automaticTips) return 'Entendi';

    return currentStepp === 3 ? 'Entendi' : 'Próximo';
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      fullWidth
      maxWidth="md"
    >
      <IconButton
        aria-label="Fechar modal de como atribuir aprovadores manualmente"
        onClick={onClose}
        className={styles.closeIcon}
      >
        <CloseIcon fontSize="medium" />
      </IconButton>

      <DialogTitle variant="text-lg" fontWeight="600">
        {automaticTips
          ? 'Quem pode ser um aprovador?'
          : 'Como atribuir aprovadores manualmente'}
      </DialogTitle>

      <DialogContent dividers className={styles.content}>
        <Slide
          key="1"
          direction="left"
          in={currentStepp === 1}
          unmountOnExit
          mountOnEnter
          timeout={{ appear: 200, enter: 200, exit: 0 }}
        >
          <Stack gap={10} direction="column" alignItems="center">
            <img srcSet={Img1} src={Img1} alt="start" className={styles.img} />
            <div>
              <Typography
                fontWeight="600"
                marginBottom={3}
                color="grey.600"
                align="center"
              >
                1. Verifique quais usuários devem ter permissão de aprovação
              </Typography>
              <Typography color="grey.600" fontWeight="400" align="center">
                Por padrão, usuários admin ou gestores já possuem permissão de
                aprovação.
                <br />
                Você também pode criar novos usuários aprovadores. Acesse as
                <Link
                  className={styles.link}
                  to={generateCompanyPath(RouteKey.USERS, {
                    companyId,
                    organizationId,
                  })}
                  aria-label="configurações de usuários"
                  role="link"
                  target="_blank"
                >
                  configurações de usuários
                </Link>
                <br />
                para gerenciar essas permissões.
              </Typography>
            </div>
          </Stack>
        </Slide>
        <Slide
          key="2"
          direction="left"
          in={currentStepp === 2}
          unmountOnExit
          mountOnEnter
          timeout={{ appear: 200, enter: 200, exit: 0 }}
        >
          <Stack gap={10} direction="column" alignItems="center">
            <img srcSet={Img2} src={Img2} alt="start" className={styles.img} />
            <div>
              <Typography
                fontWeight="600"
                marginBottom={3}
                color="grey.600"
                align="center"
              >
                2. Ao criar cada lançamento, informe quem deve aprová-lo
              </Typography>
              <Typography color="grey.600" fontWeight="400" align="center">
                Nas informações do lançamento, informe no campo "Aprovadores"
                quem deve aprovar o pagamento.
              </Typography>
            </div>
          </Stack>
        </Slide>
        <Slide
          key="3"
          direction="left"
          in={currentStepp === 3}
          unmountOnExit
          mountOnEnter
          timeout={{ appear: 200, enter: 200, exit: 0 }}
        >
          <Stack gap={10} direction="column" alignItems="center">
            <img srcSet={Img3} src={Img3} alt="start" className={styles.img} />
            <div>
              <Typography
                fontWeight="600"
                marginBottom={3}
                color="grey.600"
                align="center"
              >
                3. Informe também quais são as aprovações necessárias
              </Typography>
              <Typography color="grey.600" fontWeight="400" align="center">
                No campo "Aprovações necessárias", informe se todos precisam
                aprovar o lançamento ou se aprovação de qualquer um dos
                aprovadores selecionados é suficiente.
              </Typography>
            </div>
          </Stack>
        </Slide>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant="outlined" onClick={onBack}>
          Voltar
        </Button>

        {!automaticTips && (
          <Stack direction="row" gap={1}>
            <DotIcon fontSize="xxxsmall" color="secondary" />
            <DotIcon
              fontSize="xxxsmall"
              color="secondary"
              className={cn({ [styles.chipInactive]: currentStepp < 2 })}
            />
            <DotIcon
              fontSize="xxxsmall"
              color="secondary"
              className={cn({ [styles.chipInactive]: currentStepp < 3 })}
            />
          </Stack>
        )}

        <Button color="primary" variant="contained" onClick={onNext}>
          {buttonText()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
