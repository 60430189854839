import { useCallback } from 'react';

import { Col, Form, Row } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import ptBR from 'antd/lib/date-picker/locale/pt_BR';

import { AmountInput, DatePicker, FormItem } from 'src/ui';

import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import AccountSelect from 'src/features/DEPRECATED_accounts/AccountSelect';
import { buildAmountInfo } from 'src/features/entries/Drawers/SplitForm/AmountCalculator/AmountCalculationResult';

type Props = {
  totalAmount: number;
  companyId?: string;
  organizationId?: string;
  originDescription?: string | null;
};

export default function BaseFields({
  companyId,
  organizationId,
  originDescription,
  totalAmount,
}: Props) {
  const { accounts, isLoading: isLoadingAccounts } = useAccounts(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const form = Form.useFormInstance();

  const updateValues = Form.useWatch('update', form) ?? [];
  const createValues = Form.useWatch('create', form) ?? [];

  const { currentAmount, statusAmount } = buildAmountInfo(
    totalAmount,
    updateValues,
    createValues
  );

  const amountValidator = useCallback(
    (_rule: any, _value?: number | string | null) => {
      if (currentAmount > totalAmount) {
        return Promise.reject('Soma das quebras é maior que o Valor');
      }

      if (currentAmount < totalAmount) {
        return Promise.reject('Soma das quebras é menor que o Valor');
      }

      if (currentAmount === totalAmount) {
        return Promise.resolve(null);
      }
    },
    [totalAmount, currentAmount]
  );

  return (
    <>
      <Row gutter={[30, 10]}>
        <Col span={24}>
          <p>{originDescription}</p>
        </Col>
      </Row>
      <Row gutter={[30, 10]}>
        <Col span={8}>
          <FormItem
            name="amount"
            label="Valor"
            hasMax={false}
            rules={[
              {
                validator: amountValidator,
              },
            ]}
          >
            <AmountInput
              placeholder="0,00"
              disabled
              ariaLabel="total-amount"
              type={statusAmount}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem name="accountId" label="Conta" hasMax={false}>
            <AccountSelect
              accounts={accounts}
              loading={isLoadingAccounts}
              disabled
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem name="date" label="Data" hasMax={false}>
            <DatePicker
              placeholder="dd/mm/aaaa"
              aria-label="data"
              size="large"
              format="dd/MM/yyyy"
              suffixIcon={<CalendarOutlined />}
              disabled
              locale={ptBR}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
}
