import t from 'zod';

export enum Resources {
  accounts = 'accounts',
  approvalsRules = 'approvalsRules',
  approvedPayments = 'approvedPayments',
  attachments = 'attachments',
  bankStatement = 'bankStatement',
  bills = 'bills',
  brazilianDataAddress = 'brazilianDataAddress',
  btgAuthentication = 'btgAuthentication',
  btgConsentments = 'btgConsentments',
  categories = 'categories',
  classifications = 'classifications',
  companies = 'companies',
  companiesBalances = 'companiesBalances',
  companiesOptions = 'companiesOptions',
  companiesPendencies = 'companiesPendencies',
  companyUsers = 'companyUsers',
  contacts = 'contacts',
  countries = 'countries',
  entries = 'entries',
  entriesConciliationSuggestions = 'entriesConciliationSuggestions',
  financialEntries = 'financialEntries',
  financialEntrySplit = 'financialEntrySplit',
  financialStatements = 'financialStatements',
  ibgeData = 'ibgeData',
  ignoredRecords = 'ignoredRecords',
  inboxItems = 'inboxItems',
  institution = 'institution',
  invitations = 'invitations',
  labels = 'labels',
  notaFiscalCertificate = 'notaFiscalCertificate',
  notaFiscalCompanyServices = 'notaFiscalCompanyServices',
  notaFiscalMunicipalServices = 'notaFiscalMunicipalServices',
  notaFiscalMunicipalSpecifications = 'notaFiscalMunicipalSpecifications',
  notaFiscalSetup = 'notaFiscalSetup',
  notasFiscais = 'notasFiscais',
  openBankingConnections = 'openBankingConnections',
  openBankingWidgetSession = 'openBankingWidgetSession',
  otherFeature = 'otherFeature',
  permissions = 'permissions',
  profile = 'profile',
  publicApiTokens = 'publicApiTokens',
  receivables = 'receivables',
  reprovedPayments = 'reprovedPayments',
  setupAccount = 'setupAccount',
  statementExport = 'statementExport',
  superFeature = 'superFeature',
  uploads = 'uploads',
  userCompanyPermissions = 'userCompanyPermissions',
  userOrganizationPermissions = 'userOrganizationPermissions',
  users = 'users',
  waitingApprovalPayments = 'waitingApprovalPayments',
  waitingPayments = 'waitingPayments',
  webappPages = 'webappPages',
}

export type Resource = keyof typeof Resources;

export enum CompanyRole {
  MANAGER = 'manager',
  OPERATOR = 'operator',
  VIEWER = 'viewer',
  APPROVER = 'approver',
}

export const CompanyRoleDecoder = t.union([
  t.literal(CompanyRole.MANAGER),
  t.literal(CompanyRole.VIEWER),
  t.literal(CompanyRole.OPERATOR),
  t.literal(CompanyRole.APPROVER),
]);

export enum OrganizationRole {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export const OrganizationRoleDecoder = t.union([
  t.literal(OrganizationRole.ADMIN),
  t.literal(OrganizationRole.MEMBER),
]);

const ActionsDecoder = t.record(t.string(), t.string().array());

export const CompanyPermissionDecoder = t.object({
  companyId: t.string(),
  role: CompanyRoleDecoder,
  actions: ActionsDecoder.optional(),
});

export type CompanyPermission = t.TypeOf<typeof CompanyPermissionDecoder>;

export const OrganizationPermissionDecoder = t.object({
  actions: ActionsDecoder.optional(),
  role: OrganizationRoleDecoder,
});

export type OrganizationPermission = t.TypeOf<
  typeof OrganizationPermissionDecoder
>;

export type PermissionActions = t.TypeOf<typeof ActionsDecoder>;

export enum PermissionScope {
  company = 'company',
  organization = 'organization',
}

export type Permissions = {
  action: string | string[];
  resource: Resource;
  companyId?: string;
  scope?: keyof typeof PermissionScope;
};
