import t from 'zod';
import type { RcFile } from 'rc-upload/lib/interface';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { required } from 'src/libs/finbits/resolverValidations';

export const CategoryDecoder = t.object({
  active: t.boolean(),
  id: t.string(),
  name: t.string(),
  optionalAttachments: t.boolean(),
  type: t.nativeEnum(BalanceType),
});

export const CategoriesDecoder = t.array(CategoryDecoder);

export type Category = t.TypeOf<typeof CategoryDecoder>;

export const CategoryResolver = t.object({
  name: required,
  type: required,
  optionalAttachments: t.boolean(),
});

export type CategoryParams = t.TypeOf<typeof CategoryResolver>;

export type GetParams = {
  companyId?: string;
  organizationId?: string;
};

export type PostPayload = {
  name: string;
  optionalAttachments: boolean;
  type: string;
};

export type PatchPayload = Partial<{
  name: string;
  optionalAttachments: boolean;
  active: boolean;
}>;

export type PostParams = GetParams & { payload: PostPayload };

export type ImportParams = {
  organizationId: string;
  companyId: string;
  file: RcFile;
};

export type PatchParams = GetParams & {
  categoryId: string;
  payload: PatchPayload;
};
