import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { enumColumn } from 'src/mui/_scss/Table/Columns/enumColumn/enumColumn';

import { APPROVAL_TYPE_DICTIONARY } from 'src/libs/finbits/Bills/translation';

import ApprovalTypeSelect from 'src/features/approval/ApprovalTypeSelect';

import type { ColumnProps } from '../types';

export function approvalTypeColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return enumColumn({
    enumTranslation: APPROVAL_TYPE_DICTIONARY,
    SelectComponent: ApprovalTypeSelect,
    columnProps: {
      minWidth: 100,
      field: 'approvalType',
      editable: false,
      headerName: 'Aprovações necessárias',
      ...columnProps,
    },
  });
}
