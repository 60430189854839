export const PREFIX = '_finbits_';

export function getItem<TData>(
  key: string,
  defaultReturn: TData | null = null
): TData {
  const storaged = window.localStorage.getItem(PREFIX + key);

  return storaged ? JSON.parse(storaged) : defaultReturn;
}

export function setItem<TData>(key: string, data: TData) {
  window.localStorage.setItem(PREFIX + key, JSON.stringify(data));
}

export function setMUIThemeModeItem(theme: string) {
  window.localStorage.setItem('mui-mode', theme);
}

export function getMUIThemeModeItem() {
  return window.localStorage.getItem('mui-mode');
}

export function removeItem(key: string) {
  window.localStorage.removeItem(PREFIX + key);
}
