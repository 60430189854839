import type { FormInstance } from 'antd';
import { Col, Row } from 'antd';

import { useCompanyContact } from 'src/libs/finbits/Organization/Companies/Contacts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import type { Taxes } from '../Taxes/types';

import EmailField from './EmailField';
import SummaryNotaFiscal from './SummaryNotaFiscal';

type Props = {
  form: FormInstance;
  selectedTaxes?: Taxes[];
};

export default function Review({ form, selectedTaxes = [] }: Props) {
  const contactId = form.getFieldValue('contactId');

  const { organizationId, companyId } = useCompanyParams();

  const { contact } = useCompanyContact(
    {
      organizationId,
      companyId,
      contactId,
    },
    {
      onSuccess: (data) => {
        const recipientsEmails = form.getFieldValue('recipientsEmails');

        if (recipientsEmails?.length > 0) return;

        form.setFieldsValue({
          recipientsEmails: [data.email],
        });
      },
    }
  );

  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <SummaryNotaFiscal
          contact={contact}
          amount={form.getFieldValue('amount')}
          additionalInformation={form.getFieldValue('additionalInformation')}
          selectedTaxes={selectedTaxes}
        />
      </Col>
      <Col span={24}>
        <EmailField form={form} />
      </Col>
    </Row>
  );
}
