import { useWatch } from 'react-hook-form';
import { FormField, Input } from 'src/mui/_scss';

import { ApprovalRuleConditionField } from 'src/libs/finbits/ApprovalRules/types';

import AmountValues from './fields/AmountValue';
import LabelValues from './fields/LabelValues';
import CategoryValues from './fields/CategoryValues';

type Props = {
  index: number;
};

export default function ConditionValues({ index }: Props) {
  const formConditionFieldValue: ApprovalRuleConditionField | undefined =
    useWatch({
      name: `conditions.${index}.field`,
    });

  if (formConditionFieldValue === ApprovalRuleConditionField.AMOUNT)
    return <AmountValues index={index} />;

  if (formConditionFieldValue === ApprovalRuleConditionField.CATEGORY)
    return <CategoryValues index={index} />;

  if (Boolean(formConditionFieldValue)) return <LabelValues index={index} />;

  return (
    <FormField label="Valor" name="valor" shouldUnregister>
      {(field) => {
        return <Input {...field} disabled />;
      }}
    </FormField>
  );
}
