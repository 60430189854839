function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function reconnectAfterMs(tries: number) {
  const max = [10, 50, 100, 150, 200, 250, 500, 1000, 2000][tries - 1] || 5000;
  const min = Math.min(Math.pow(2, tries), max / 2);

  return randomInteger(min, max);
}

export function rejoinAfterMs(tries: number) {
  const max = [1000, 2000, 5000][tries - 1] || 10000;
  const min = Math.min(Math.pow(2, tries), max / 2);

  return randomInteger(min, max);
}
