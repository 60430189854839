import { AlertCircleIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useDeactivateOrganizationUser } from 'src/libs/finbits/Organization/Users';
import type { User } from 'src/libs/finbits/Organization/Users/types';

type Props = {
  user: User;
};

export default function useDeactivateModal({ user }: Props) {
  const { organizationId } = useCompanyParams();

  const openConfirmDialog = useOpenConfirmDialog();

  const { deactivateOrganizationUser } = useDeactivateOrganizationUser();

  function handleDeactivateUser() {
    deactivateOrganizationUser(
      {
        organizationId,
        userId: user.id,
      },
      {
        onSuccess: () => {
          snackbar({ variant: 'success', message: 'Usuário desativado.' });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Ocorreu um erro ao desativar o usuário. Tente novamente em instantes.',
          });
        },
      }
    );
  }

  function openDeactivateConfirmation() {
    openConfirmDialog({
      variant: 'error',
      icon: <AlertCircleIcon />,
      title: 'Deseja mesmo desativar esta conta?',
      content: `Essa conta será desativada e ${user.name} não poderá mais acessar a Finbits`,
      confirmText: 'Sim, quero desativar',
      cancelText: 'Cancelar',
      onConfirm: handleDeactivateUser,
    });
  }

  return {
    openDeactivateConfirmation,
  };
}
