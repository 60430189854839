import { useState } from 'react';

import type { RangeValue } from 'rc-picker/lib/interface';
import { Col, List, Row, Typography } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { DatePicker, Title } from 'src/ui';

import { toCurrency } from 'src/libs/finbits/Money';
import { endOfMonth, format, startOfMonth } from 'src/libs/finbits/Date';
import { useFinancialMovements } from 'src/libs/finbits/Management/FinancialStatements/FinancialMovements';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { movementTypeDictionary } from 'src/libs/finbits/Management/FinancialStatements/FinancialMovements/translations';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import TotalAmount from 'src/features/companies-balances/TotalAmount';

import styles from './MonthMovements.module.less';

const ICONS_FROM_TYPE = {
  credit: <ArrowDownOutlined className={styles.creditIcon} />,
  debit: <ArrowUpOutlined />,
};

function MonthMovements() {
  const { companyId, organizationId } = useCompanyParams();

  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  const { movements, isLoading } = useFinancialMovements({
    companyId,
    organizationId,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  });

  const movementCredit = movements?.credit ?? 0;
  const movementDebit = movements?.debit ?? 0;

  const dataSource = [
    {
      type: BalanceType.CREDIT,
      value: movementCredit,
    },
    {
      type: BalanceType.DEBIT,
      value: movementDebit,
    },
  ];

  const totalBalances = [
    {
      balance: movementCredit,
      balanceType: BalanceType.CREDIT,
    },
    {
      balance: movementDebit,
      balanceType: BalanceType.DEBIT,
    },
  ];

  function onDateChange(dates: RangeValue<Date>) {
    if (!dates) return;

    const [startDate, endDate] = dates;

    setStartDate(startDate!);
    setEndDate(endDate!);

    return dates;
  }

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Title>Lançamentos</Title>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            allowClear={false}
            size="large"
            format="dd/MM/yyyy"
            placeholder={['Data inicial', 'Data final']}
            onChange={onDateChange}
            disabled={isLoading}
          />
        </Row>
      </Col>
      <Col span={24}>
        <List
          dataSource={dataSource}
          loading={isLoading}
          footer={<TotalAmount balances={totalBalances} />}
          renderItem={(movement) => (
            <List.Item>
              <List.Item.Meta
                avatar={ICONS_FROM_TYPE[movement.type]}
                title={movementTypeDictionary[movement.type]}
              />
              <Typography.Text>{toCurrency(movement.value)}</Typography.Text>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
}

export default MonthMovements;
