import type { AnalyticsInstance } from 'analytics';
import { Analytics } from 'analytics';
import segmentPlugin from '@analytics/segment';
import doNotTrack from 'analytics-plugin-do-not-track';

const app = 'plutus';
const writeKey = import.meta.env.VITE_SEGMENT_WRITE_KEY;
const flushInterval = 10 * 1000; // 10s
const enable = import.meta.env.VITE_ANALYTICS_ENABLE === 'true';

const analytics = Analytics({
  app,
  plugins: [
    doNotTrack(),
    segmentPlugin({
      writeKey,
      flushInterval,
      enable,
    }),
  ],
}) as {
  plugins: {
    segment: {
      group: (groupId: unknown, traits?: unknown) => unknown;
    };
  };
} & AnalyticsInstance;

export default analytics;
