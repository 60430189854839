import { Container, Link } from '@mui/material';
import { Outlet, Link as RouterLink } from 'react-router-dom';

import { Logo } from 'src/ui';

import styles from './SideContentLayout.module.scss';

function SideContentLayout() {
  return (
    <Container component="main" maxWidth="xs" className={styles.container}>
      <Link component={RouterLink} to="https://finbits.com.br">
        <Logo className={styles.logo} />
      </Link>
      <Outlet />
    </Container>
  );
}

export default SideContentLayout;
