import { useEffect } from 'react';

import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormField } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';

import { SynchronizeType } from 'src/libs/finbits/Bank/Accounts/types';
import { useSupportedBankProviderCodes } from 'src/libs/finbits/Bank/SupportedBank';

import SynchronizeTypeRadio from 'src/features/accounts/SynchronizeTypeRadio/SynchronizeTypeRadio';

export default function SynchronizedTypeSteppe() {
  const { control, getValues, setValue } = useFormContext();
  const { providerCodes } = useSupportedBankProviderCodes(
    getValues('routingNumber')
  );
  const isBankAllowAutomatic = providerCodes.length > 0;

  useEffect(() => {
    if (!isBankAllowAutomatic) {
      setValue('synchronizeType', SynchronizeType.MANUAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBankAllowAutomatic]);

  return (
    <Stack display="flex" gap={5} flexDirection="column" paddingBottom={8}>
      <Typography variant="text-lg" fontWeight="600" component="h6">
        Modo de atualização
      </Typography>

      <Typography color="grey.600" marginBottom={3}>
        Para este tipo de conta e banco, estão disponíveis os seguintes modos de
        atualização:
      </Typography>

      <FormField name="synchronizeType" control={control}>
        {(field) => (
          <SynchronizeTypeRadio
            allowAutomatic={isBankAllowAutomatic}
            {...field}
          />
        )}
      </FormField>
    </Stack>
  );
}
