import { Button, Col, Form, Space, Upload } from 'antd';
import { TrashIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { FormItem, useOpenConfirmDialog } from 'src/ui';

import { useNotaFiscal } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import { useUnlinkEntries } from 'src/libs/finbits/NotaFiscal/LinkToEntries';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import NotaFiscalStatusTag from 'src/features/nota-fiscal/NotaFiscalStatusTag';
import { NotaFiscalStatus } from 'src/features/nota-fiscal/NotaFiscalStatusTag/types';

import Actions from './Actions';

type Props = {
  notaFiscalId?: string;
  entryId: string;
  isFinancialEntry: boolean;
};

export default function NotaFiscalField({
  notaFiscalId,
  entryId,
  isFinancialEntry,
}: Props) {
  const form = Form.useFormInstance();

  const { companyId, organizationId } = useCompanyParams();

  const { status: connectionStatus } = useNotaFiscalConnection();

  const openConfirmDialog = useOpenConfirmDialog();

  const { notaFiscal } = useNotaFiscal({
    id: notaFiscalId,
    companyId,
    organizationId,
  });

  const { unlinkEntries, isLoading: isUnlinking } = useUnlinkEntries();

  const isConnectionActive = connectionStatus === 'active';

  const isNotaFiscalIssued = notaFiscal?.status === NotaFiscalStatus.ISSUED;
  const isNotaFiscalIssuing = notaFiscal?.status === NotaFiscalStatus.ISSUING;

  const entries = notaFiscal
    ? [
        ...(notaFiscal.receivables ?? []),
        ...(notaFiscal.financialEntries ?? []),
      ]
    : [];

  const isEntryLinked = entries.some(({ id }) => id === entryId);

  const fileList = notaFiscal?.pdfUrl
    ? [
        {
          uid: String(notaFiscal.number),
          name: `${notaFiscal.contact.name} - ${notaFiscal.number}`,
          url: notaFiscal.pdfUrl,
          type: 'pdf',
        },
      ]
    : [];

  if (!notaFiscalId || !isConnectionActive) {
    return null;
  }

  function handleUnlinkReceivable() {
    const entriesIds = isFinancialEntry
      ? { financialEntriesIds: [entryId] }
      : { receivablesIds: [entryId] };

    unlinkEntries(
      {
        companyId,
        organizationId,
        ...entriesIds,
      },
      {
        onSuccess: () => {
          snackbar({ variant: 'success', message: 'Recebimento desvinculado' });
          form.setFieldValue('notaFiscalId', '');
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao desvincular recebimento!',
          });
        },
      }
    );
  }

  function handleUnlinkClick() {
    openConfirmDialog({
      variant: 'error',
      icon: <TrashIcon />,
      title: 'Tem certeza que deseja desvincular?',
      content:
        'Ao confirmar, este recebimento será desvinculado da nota fiscal.',
      confirmText: 'Sim, quero desvincular',
      onConfirm: handleUnlinkReceivable,
      cancelText: 'Cancelar',
    });
  }

  return (
    <Col span={24}>
      <FormItem
        label={
          <Space size={8}>
            Nota Fiscal
            {notaFiscal && <NotaFiscalStatusTag notaFiscal={notaFiscal} />}
          </Space>
        }
      >
        {isNotaFiscalIssued ? (
          <Upload
            fileList={fileList}
            listType="picture"
            showUploadList={{
              showRemoveIcon: true,
              removeIcon: (
                <Actions
                  onUnlinkClick={handleUnlinkClick}
                  notaFiscal={notaFiscal}
                  isEntryLinked={isEntryLinked}
                />
              ),
            }}
          />
        ) : (
          <Space>
            {isNotaFiscalIssuing && (
              <Button disabled>Emitindo nota fiscal...</Button>
            )}
            {isEntryLinked && (
              <WithAllowedPermission
                permissions={{
                  action: 'unlink_entries',
                  resource: 'notasFiscais',
                }}
              >
                <Button onClick={handleUnlinkClick} loading={isUnlinking}>
                  Desvincular
                </Button>
              </WithAllowedPermission>
            )}
          </Space>
        )}
      </FormItem>
    </Col>
  );
}
