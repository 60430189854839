import type { FormFieldProps } from 'src/mui/_scss';
import { FormField } from 'src/mui/_scss';

import { InputCurrency } from 'src/mui';

export default function AmountField({
  defaultValue = 0,
  name,
  ...rest
}: FormFieldProps) {
  return (
    <FormField defaultValue={defaultValue} name={name} label="Valor" {...rest}>
      {({ error, ...field }) => {
        return <InputCurrency {...field} />;
      }}
    </FormField>
  );
}
