import { useState } from 'react';

import { Button } from 'antd';
import ReactMarkdown from 'react-markdown';

import type { PortalProps } from 'src/ui';
import { Modal } from 'src/ui';

import styles from './MarkdownModal.module.less';

type Props = {
  label?: string;
  markdown: any;
} & Partial<PortalProps>;

export default function MarkdownModal({
  label,
  markdown,
  open = false,
  onClose,
  onExit,
}: Props) {
  const [isVisible, setIsVisible] = useState(open);

  function handleOnClose() {
    setIsVisible(false);
    onClose?.();
  }

  return (
    <>
      {label && (
        <Button
          type="link"
          onClick={() => setIsVisible(true)}
          className={styles.link}
        >
          {label}
        </Button>
      )}

      <Modal
        afterClose={onExit}
        footer={null}
        title={null}
        visible={isVisible}
        onCancel={handleOnClose}
        data-testid="markdown-modal"
        destroyOnClose
        className={styles.modal}
        width={572}
      >
        <ReactMarkdown className={styles.markdown}>{markdown}</ReactMarkdown>
      </Modal>
    </>
  );
}
