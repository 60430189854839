import cn from 'classnames';
import type { TagProps as AntdProps } from 'antd';
import { Tag as AntdTag, Typography } from 'antd';

import styles from './Tag.module.less';

type Props = {
  text?: string | null;
} & AntdProps;

export default function EllipsisTag({ className, text, ...rest }: Props) {
  if (!text) return null;

  return (
    <AntdTag className={cn([styles.tag, className])} {...rest}>
      <Typography.Text className={styles.title} ellipsis={{ tooltip: text }}>
        {text}
      </Typography.Text>
    </AntdTag>
  );
}
