import { Box } from '@mui/material';

import { Loader } from 'src/ui';

import style from './LoaderOverlay.module.scss';

export function LoaderOverlay() {
  return (
    <Box data-testid="LoaderOverlay" className={style.overlay}>
      <Loader forceCentered />
    </Box>
  );
}
