import t from 'zod';

import type {
  CompanyRole,
  OrganizationRole,
} from 'src/libs/finbits/Permissions/types';
import {
  CompanyPermissionDecoder,
  OrganizationPermissionDecoder,
} from 'src/libs/finbits/Permissions/types';
import { PhoneNumberDecoder } from 'src/libs/finbits/Organization/PhoneNumber';
import { CompanyDecoder } from 'src/libs/finbits/Organization/Companies/types';

export enum UserStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  INACTIVE = 'inactive',
}

export const UserStatusDecoder = t.union([
  t.literal(UserStatus.PENDING),
  t.literal(UserStatus.ACTIVE),
  t.literal(UserStatus.INACTIVE),
]);

export const UserDecoder = t.object({
  id: t.string(),
  name: t.string().nullable(),
  email: t.string(),
  phone: PhoneNumberDecoder.nullish(),
  organizationId: t.string(),
  optedInDailyReports: t.boolean(),
  optedInPendingApprovalsReport: t.boolean(),
  status: UserStatusDecoder,
  acceptedTermsAt: t.string().nullish(),
  companies: CompanyDecoder.array().optional(),
  companiesPermissions: CompanyPermissionDecoder.array().optional(),
  signupUrl: t.string().nullable(),
  supportStaff: t.boolean(),
  isImpersonated: t.boolean(),
});

export const PermissionDecoder = t.object({
  organizationPermission: OrganizationPermissionDecoder,
});

export const UserWithPermissionsDecoder = UserDecoder.merge(PermissionDecoder);
export type UserWithPermissions = t.TypeOf<typeof UserWithPermissionsDecoder>;

export type User = t.TypeOf<typeof UserDecoder>;

type PhoneUpdateParams = {
  number: string;
};

export type ActivateOrganizationUserPostParams = {
  userId: string;
  organizationId?: string;
};

export type DeactivateOrganizationUserPostParams = {
  userId: string;
  organizationId?: string;
};

export type UserCompanyPermissionPatchParams = {
  organizationId: string;
  companyId: string;
  userId: string;
  role: CompanyRole | null;
};

export type UserOrganizationPermissionPatchParams = {
  organizationId: string;
  userId: string;
  role: OrganizationRole | null;
};

export type UserProfileUpdateParams = {
  name?: string;
  email?: string;
  phone?: null | PhoneUpdateParams;
  acceptedTermsAt?: null | string;
};

export type UserInfo = {
  name: string;
  phone: string | null;
  password: string;
  userCandidateId?: string;
};

export type GetParams = {
  organizationId?: string;
  status?: UserStatus;
  onlyAvailableApprovers?: boolean;
};

export type UserOptInUpdateParams = {
  optedInDailyReports: boolean;
};

export type PatchOptedInPendingApprovalsReportParams = {
  optedInPendingApprovalsReport: boolean;
};

export type CompanyUsersGetParams = GetParams & {
  companyId?: string;
};
