import type { UploadFile } from 'antd/lib/upload/interface';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

function attachmentToUploadFile(
  attachment: Attachment
): UploadFile<Attachment> {
  return {
    ...attachment,
    uid: attachment.id,
    url: attachment.url ?? undefined,
    status: 'done',
  };
}

export function attachmentsToUploadFiles(attachments?: Attachment[]) {
  if (attachments) {
    return attachments.map(attachmentToUploadFile);
  }

  return [];
}
