import type { TransitionProps } from '@mui/material/transitions';
import { Box, Button, DialogContent, Slide, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { errorsToFormField } from 'src/libs/finbits/Form';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { useUpdateClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type {
  Classification,
  FormParams,
} from 'src/libs/finbits/Classifications/types';
import { FormParamsDecoder } from 'src/libs/finbits/Classifications/types';

import ClassificationField from 'src/features/classifications/ClassificationField';

import styles from './EditClassificationModal.module.scss';

type Props = {
  initialValues: Classification;
} & PortalProps;

export default function EditClassificationModal({
  onClose,
  onExit,
  open = true,
  initialValues,
}: Props) {
  const form = useForm<FormParams>({
    defaultValues: {
      name: initialValues.name,
    },
    resolver: zodResolver(FormParamsDecoder),
  });

  const { handleSubmit, setError } = form;

  const { companyId, organizationId } = useCompanyParams();

  const { updateClassification, isLoading } = useUpdateClassifications();

  function onSubmit(params: FormParams) {
    updateClassification(
      {
        companyId,
        organizationId,
        classificationId: initialValues.id,
        ...params,
      },
      {
        onSuccess: () => {
          onClose();

          snackbar({ variant: 'success', message: 'Classificação atualizada' });
        },
        onError: ({ response }: ApiError<ApiErrorForm>) => {
          if (response?.status === 422) {
            const formErrors = errorsToFormField(response.data.errors);

            formErrors.forEach(({ errors }) => {
              errors.forEach((error) => {
                setError('name', { message: error });
              });
            });

            return;
          }

          snackbar({
            variant: 'error',
            message: 'Erro ao atualizar classificação',
          });
        },
      }
    );
  }

  return (
    <Dialog
      title="Editar classificação"
      fullWidth
      maxWidth="xs"
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
    >
      <Dialog.Header
        size="small"
        title={
          <Stack direction="row" gap={2} alignItems="center">
            Editar classificação
          </Stack>
        }
        onClose={onClose}
      />
      <FormProvider {...form}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={styles.dialogContent}>
            <ClassificationField defaultValue={initialValues.name} />
          </DialogContent>

          <Dialog.Actions>
            <Button fullWidth size="large" variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              Editar
            </LoadingButton>
          </Dialog.Actions>
        </Box>
      </FormProvider>
    </Dialog>
  );
}
