import t from 'zod';

import type { CompanyCreateParams } from 'src/libs/finbits/Organization/Companies/types';
import { CompanyDecoder } from 'src/libs/finbits/Organization/Companies/types';
import {
  cnpj,
  email,
  newPassword,
  phoneNumber,
  required,
} from 'src/libs/finbits/resolverValidations';

export const OrganizationDecoder = t.object({
  id: t.string(),
  companies: CompanyDecoder.array(),
});

export type Organization = t.TypeOf<typeof OrganizationDecoder>;

export const UserInfoFormResolver = t.object({
  name: required,
  email,
  phone: t.object({
    number: phoneNumber,
  }),
  password: newPassword,
});

export type UserInfo = t.TypeOf<typeof UserInfoFormResolver>;

export const CompanyInfoFormResolver = t.object({
  document: cnpj,
  name: required,
  tradeName: required,
});

export type CompanyInfo = t.TypeOf<typeof CompanyInfoFormResolver>;

export type PostParams = {
  companies: CompanyCreateParams[];
  user: UserInfo;
};
