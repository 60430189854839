import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCompanyUsers } from 'src/libs/finbits/Organization/Users';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import type { User } from 'src/libs/finbits/Organization/Users/types';

type Props = Omit<
  SelectProps<User, false, false, false>,
  'options' | 'defaultValue'
>;

export default function RequesterSelect(props: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { users, isLoading } = useCompanyUsers(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  return (
    <Select<User, false>
      disableCloseOnSelect
      multiple={false}
      {...props}
      aria-label="solicitado por"
      placeholder="Selecione os usúarios"
      options={users}
      loading={isLoading}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}
