import { useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import {
  ConciliateIcon,
  Drawer,
  DrawerHeader,
  DrawerHeaderButton,
  Title,
  UndoConciliateIcon,
} from 'src/ui';

import { entryText } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useUnconciliateFinancialEntry } from 'src/libs/finbits/Management/FinancialEntries';
import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import { scheduledEntryToFormValues } from 'src/features/EntryForm/formValues';
import EntryForm from 'src/features/EntryForm';
import type {
  EntryFormDisabledFields,
  EntryFormHiddenActions,
  EntryFormHiddenFields,
  FormValues,
  SubmitParams,
} from 'src/features/EntryForm/types';
import ConciliationDrawer from 'src/features/conciliations/ConciliationWithSuggestionDrawer';
import { useOpenConfirmUndoConciliationDialog } from 'src/features/entries/Dialogs';

type Props = {
  splitInitialValues?: FormValues;
  onClose: () => void;
  onSubmit: (formValues: FormValues) => void;
  scheduledIdsSelectedToConciliate?: string[];
  scheduledIdsConciliated?: string[];
  onUnconciliateSuccess: (scheduledEntryId?: string | null) => void;
  shouldCallSubmitOnUnconciliate?: boolean;
};

const NON_UPDATABLE_FIELDS: EntryFormDisabledFields = ['accountId', 'date'];

const HIDDEN_FIELDS: EntryFormHiddenFields = [
  'type',
  'paymentDetails',
  'isRecurrent',
  'approvalType',
  'assigneesIds',
  'originDescription',
];

const HIDDEN_ACTIONS: EntryFormHiddenActions = [
  'createNotaFiscalFromReceivable',
  'createReceivableWithNotaFiscal',
];

function SplitItemFormDrawer({
  onClose,
  splitInitialValues,
  onSubmit,
  scheduledIdsSelectedToConciliate,
  scheduledIdsConciliated,
  onUnconciliateSuccess,
  shouldCallSubmitOnUnconciliate = false,
}: Props) {
  const [initialValues, setInitialValues] = useState<FormValues | undefined>(
    splitInitialValues
  );

  const [isFormDirty, setIsFormDirty] = useState(false);

  const openConfirmUndoConciliationDialog =
    useOpenConfirmUndoConciliationDialog();

  const [isConciliationDrawerVisible, setIsConciliationDrawerVisible] =
    useState(false);
  const { organizationId, companyId } = useCompanyParams();

  const canEditFinancialEntry = useAllowedPermission({
    action: 'update',
    resource: 'financialEntrySplit',
  });

  const { unconciliateFinancialEntry } = useUnconciliateFinancialEntry();

  const isConciliated = !!initialValues?.scheduledEntryId;

  function handleConciliation() {
    if (isConciliated) {
      return openUnconciliateConfirmation();
    }

    setIsConciliationDrawerVisible(true);
  }

  function handleConciliate(scheduledEntry: ScheduledStatementEntry) {
    setInitialValues({
      scheduledEntryId: scheduledEntry.id,
      ...scheduledEntryToFormValues(scheduledEntry, initialValues?.type),
      id: initialValues?.id,
      date: initialValues?.date,
      accountId: initialValues?.accountId,
    });
  }

  function handleUnconciliate() {
    if (
      initialValues?.id &&
      initialValues?.scheduledEntryId &&
      scheduledIdsConciliated?.includes(initialValues?.scheduledEntryId)
    ) {
      unconciliateFinancialEntry(
        {
          organizationId,
          companyId,
          financialEntryId: initialValues?.id,
        },
        {
          onSuccess: () => {
            onUnconciliateSuccess(splitInitialValues?.scheduledEntryId);

            snackbar({
              variant: 'success',
              message: 'A conciliação foi desfeita com sucesso.',
            });
          },
          onError: () => {
            snackbar({
              variant: 'error',
              message: 'Ocorreu um erro ao desfazer a conciliação.',
            });
          },
        }
      );
    }

    const newFormValues = {
      ...initialValues,
      scheduledEntryId: '',
    };

    setInitialValues(newFormValues);

    if (shouldCallSubmitOnUnconciliate) {
      const newSplitValues = {
        ...splitInitialValues,
        scheduledEntryId: '',
      };

      onSubmit(newSplitValues);
    }
  }

  async function handleSubmit(params: SubmitParams) {
    onSubmit && onSubmit(params);
    onClose();
  }

  function openUnconciliateConfirmation() {
    return openConfirmUndoConciliationDialog({
      onConfirm: handleUnconciliate,
    });
  }

  return (
    <Drawer
      title={
        <DrawerHeader
          title={
            <Title icon={<CheckOutlined />}>
              {entryText({
                type: initialValues?.type,
                objectType: 'financial_entry',
              })}
            </Title>
          }
          extra={
            canEditFinancialEntry && (
              <DrawerHeaderButton
                icon={
                  isConciliated ? <UndoConciliateIcon /> : <ConciliateIcon />
                }
                aria-label={
                  isConciliated ? 'Desfazer conciliação' : 'Conciliar'
                }
                title={isConciliated ? 'Desfazer conciliação' : 'Conciliar'}
                onClick={handleConciliation}
              />
            )
          }
        />
      }
      footer={null}
      onClose={onClose}
      visible
    >
      {isConciliationDrawerVisible && (
        <ConciliationDrawer
          multiple={false}
          organizationId={organizationId}
          companyId={companyId}
          financialEntryId={initialValues?.id}
          type={initialValues?.type!}
          onConciliate={handleConciliate}
          onClose={() => setIsConciliationDrawerVisible(false)}
          scheduledIdsToHide={scheduledIdsSelectedToConciliate}
        />
      )}

      <EntryForm
        isFormDirty={isFormDirty}
        onFieldsChange={() => setIsFormDirty(true)}
        organizationId={organizationId}
        companyId={companyId}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        disabled={!canEditFinancialEntry}
        disabledFields={NON_UPDATABLE_FIELDS}
        hiddenFields={HIDDEN_FIELDS}
        hiddenActions={HIDDEN_ACTIONS}
        isFinancialEntry
      />
    </Drawer>
  );
}

export default SplitItemFormDrawer;
