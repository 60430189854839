import t from 'zod';

import { REQUIRED_MESSAGE } from './required';

export const INVALID_EMAIL_MESSAGE = 'Formato de e-mail inválido';

export const email = t
  .string({
    required_error: REQUIRED_MESSAGE,
  })
  .email(INVALID_EMAIL_MESSAGE);
