import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { API, decodeResponse } from 'src/libs/finbits/client';

import { LegalEntityDecoder } from './types';
import type { GetParams, LegalEntity } from './types';

async function getLegalEntity({ document }: GetParams) {
  const response = await API.get(`/legal_entities/${document}/contact`);

  return decodeResponse<LegalEntity>(response, LegalEntityDecoder);
}

type QueryOpts = {
  onSuccess?: (entity: LegalEntity) => void;
  onError?: () => void;
  retry?: boolean;
};

export function useLegalEntity(
  { document }: Partial<GetParams>,
  { onSuccess, onError, retry }: QueryOpts = {}
) {
  const { data, ...rest } = useQuery<LegalEntity, ApiError>({
    queryKey: ['legal_entities', { document }],
    queryFn: () => getLegalEntity({ document: document! }),
    retry,
    onSuccess,
    onError,
    enabled: !!document,
  });

  return { legalEntity: data, ...rest };
}
