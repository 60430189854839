import t from 'zod';

export const PublicCategoryDecoder = t.object({
  id: t.string(),
  name: t.string(),
});

export const PublicCompanyDecoder = t.object({
  categories: PublicCategoryDecoder.array(),
  id: t.string(),
  name: t.string(),
  tradeName: t.string(),
});

export type PublicCompany = t.TypeOf<typeof PublicCompanyDecoder>;

export type PublicCategory = t.TypeOf<typeof PublicCategoryDecoder>;

export type GetPublicCompanyParams = {
  publicId?: string;
};
