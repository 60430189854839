import type { DatePickerProps } from '@mui/x-date-pickers-pro';
import type { FormFieldProps } from 'src/mui/_scss';
import { FormField } from 'src/mui/_scss';

import { DatePicker } from 'src/mui';

type Props = {
  DatePickerProps?: DatePickerProps<Date>;
} & FormFieldProps;

export default function DateField({
  defaultValue = null,
  DatePickerProps,
  ...rest
}: Props) {
  return (
    <FormField {...rest} defaultValue={defaultValue}>
      {(field) => (
        <DatePicker
          {...field}
          {...DatePickerProps}
          slotProps={{
            ...DatePickerProps?.slotProps,
            textField: {
              error: field.error,
              id: field.id,
              ...(rest.placeholder ? { placeholder: rest.placeholder } : {}),
            },
          }}
        />
      )}
    </FormField>
  );
}
