import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function ArrowUpIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="arrow-up"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M12 19V5M12 5L5 12M12 5L19 12"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
