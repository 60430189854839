import type { ReactNode } from 'react';

import { Col } from 'antd';

import styles from './ModalActions.module.less';

type Props = {
  children: ReactNode;
};

function ModalActions({ children }: Props) {
  return (
    <Col span={24} className={styles.modalActions}>
      {children}
    </Col>
  );
}

export default ModalActions;
