import { useMutation } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import type {
  EntrySuggestions,
  EntrySuggestionsPostParams,
  NewEntrySuggestion,
  NewEntrySuggestionsPostParams,
} from './types';
import {
  EntrySuggestionsDecoder,
  NewEntrySuggestionsDecoder,
} from './validations';

// TODO: usar a rota nova de sugestões e remover todos os usos da antiga.
async function createEntrySuggestions({
  organizationId,
  companyId,
  contactId,
  type,
}: EntrySuggestionsPostParams): Promise<any> {
  const url = `/organizations/${organizationId}/companies/${companyId}/entries/suggestions`;
  const response = await authenticatedAPI.post(url, { contactId, type });

  return decodeResponse<EntrySuggestions>(response, EntrySuggestionsDecoder);
}

async function newCreateEntrySuggestions({
  organizationId,
  companyId,
  params,
}: NewEntrySuggestionsPostParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/entries/suggestions/new`;

  const response = await authenticatedAPI.post(url, params);

  return decodeResponse<NewEntrySuggestion[]>(
    response,
    NewEntrySuggestionsDecoder
  );
}

export function useEntrySuggestions() {
  const { mutate: entrySuggestions, ...rest } = useMutation<
    NewEntrySuggestion[],
    ApiError,
    NewEntrySuggestionsPostParams
  >(newCreateEntrySuggestions);

  return { entrySuggestions, ...rest };
}

export function DEPRECATED_useEntrySuggestions() {
  const { mutate: entrySuggestions, ...rest } = useMutation<
    EntrySuggestions,
    ApiError,
    EntrySuggestionsPostParams
  >(createEntrySuggestions);

  return { entrySuggestions, ...rest };
}
