import { zodResolver } from '@hookform/resolvers/zod';
import type { DialogProps } from '@mui/material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { CloseIcon } from 'src/mui/_icons';
import { BankIcon, FormField, Input } from 'src/mui/_scss';

import { Dialog, InputBase, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { format, startOfMonth } from 'src/libs/finbits/Date/Date';
import { getAmountType, toIntRepresentation } from 'src/libs/finbits/Money';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCreateInitialBalance } from 'src/libs/finbits/Organization/Companies/Balances';
import type { Account } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './RegisterBalance.module.scss';
import { RegisterBalanceResolver } from './validations';

type Props = DialogProps &
  PortalProps & {
    account: Account;
  };

export default function RegisterBalance({
  onClose,
  onExit,
  open,
  account,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { createInitialBalance, isLoading } = useCreateInitialBalance();

  const form = useForm({
    resolver: zodResolver(RegisterBalanceResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      balance: '',
    },
  });

  const getFirstDayOfCurrentMonth = format(
    startOfMonth(new Date()),
    'dd/MM/yyyy'
  );

  function handleClose() {
    return onClose();
  }

  async function handleRegisterBalance() {
    const isValid = await form.trigger();

    if (isValid) {
      const balance = form.getValues('balance');
      const cutDate = format(startOfMonth(new Date()), 'yyyy-MM-dd');
      const { amount, type } = getAmountType(
        toIntRepresentation(String(balance)) ?? 0
      );

      createInitialBalance(
        {
          organizationId,
          companyId,
          accountId: account.id!,
          cut_date: cutDate,
          initial_balance: String(amount),
          balance_type: type,
        },
        {
          onSuccess: () => {
            onClose();
            snackbar({
              variant: 'success',
              message: `O saldo inicial da conta “${account.name}” foi cadastrado.`,
              title: 'Saldo inicial cadastrado com sucesso',
            });
          },
          onError: (error) => {
            onClose();
            snackbar({
              variant: 'error',
              message: 'Ocorreu um erro ao criar o saldo inicial',
            });
          },
        }
      );
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={styles.header} variant="text-md" fontWeight="500">
        Cadastrar saldo inicial
        <IconButton
          aria-label="Fechar modal de cadastro de saldo inicial"
          onClick={handleClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.content}>
        <Typography variant="text-md">
          O saldo inicial será o ponto de partida para a atualização de sua
          conta bancária dentro da FinBits.
        </Typography>

        <Typography variant="text-md" fontWeight="600">
          Por favor, confira o extrato de seu banco e informe o valor abaixo:
        </Typography>

        <Box>
          <Typography marginBottom={2} fontWeight="500">
            Conta bancária
          </Typography>

          <Stack direction="row" alignItems="center" gap={3}>
            <BankIcon
              routingNumber={account.routingNumber}
              width={24}
              height={24}
            />

            <Box>
              <Typography color="grey.600">{account.name}</Typography>

              <Typography variant="text-xs" color="grey.500">
                {account.accountNumber}
              </Typography>
            </Box>
          </Stack>
        </Box>

        <FormProvider {...form}>
          <FormField
            name="balance"
            label={`Saldo em ${getFirstDayOfCurrentMonth}`}
            helperText={`Informe o saldo que a conta tinha ao final do dia ${getFirstDayOfCurrentMonth}.`}
          >
            {(field) => {
              return (
                <InputBase.Amount
                  {...field}
                  placeholder="0,00"
                  components={{ Input }}
                  signed
                />
              );
            }}
          </FormField>
        </FormProvider>

        <Alert severity="error" color="warning">
          <AlertTitle>
            Atenção caso a conta possua aplicação automática
          </AlertTitle>
          Neste caso, é importante que você informe acima o valor do saldo da
          conta-corrente somado ao saldo do investimento.
        </Alert>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancelar
        </Button>

        <Button
          onClick={handleRegisterBalance}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
