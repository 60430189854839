import type { SelectProps } from 'antd';
import { Select } from 'antd';

import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { PAYMENT_METHOD_DICTIONARY } from 'src/libs/finbits/PaymentMethods/translations';

import { selectFilter } from 'src/features/EntryForm/selectFilter';

const paymentMethodOptions = Object.values(PaymentMethod).map(
  (paymentMethod) => ({
    value: paymentMethod,
    label: PAYMENT_METHOD_DICTIONARY[paymentMethod],
  })
);

export default function PaymentMethodSelect({
  disabled,
  loading,
  ...rest
}: SelectProps) {
  return (
    <Select
      loading={loading}
      disabled={disabled}
      showSearch
      allowClear
      placeholder="Não informado"
      size="large"
      filterOption={selectFilter}
      {...rest}
    >
      {paymentMethodOptions.map((option) => (
        <Select.Option value={option.value} key={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
}
