import type { RcFile } from 'antd/lib/upload';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import { AttachmentType } from 'src/libs/finbits/Management/Attachments/types';

export function isRcFile(value: string | Blob | RcFile): value is RcFile {
  return typeof value !== 'string' && 'uid' in value;
}

export function isPDFType(file?: RcFile | Attachment) {
  return file?.type === AttachmentType.PDF;
}

export function isImageType(file?: RcFile | Attachment) {
  return (
    file?.type === AttachmentType.JPEG ||
    file?.type === AttachmentType.GIF ||
    file?.type === AttachmentType.PNG ||
    file?.type === AttachmentType.WEBP
  );
}
