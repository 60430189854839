import { Stack, Tooltip } from '@mui/material';
import type { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { FileIcon, FileSearchIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import { pluralize } from 'src/libs/finbits/Pluralize';
type EditParams = Pick<GridRenderEditCellParams, 'row'>;

export default function Attachment({ row }: EditParams) {
  const { status: connectionStatus } = useNotaFiscalConnection();

  if (!row.attachments) return null;

  const attachmentsCount = row.attachments.length;
  const hasNF = !!row.notaFiscalId;
  const hasAttachments = attachmentsCount > 0;

  const attachmentsMsg = `Possui ${attachmentsCount} ${pluralize(
    attachmentsCount,
    'anexo',
    'anexos'
  )}`;

  return (
    <Stack direction="row" gap={1}>
      {connectionStatus === 'active' && (
        <Tooltip
          title={hasNF ? 'Possui Nota Fiscal' : 'Não possui Nota Fiscal'}
        >
          <Stack direction="row">
            <FileSearchIcon color={hasNF ? 'inherit' : 'disabled'} />
          </Stack>
        </Tooltip>
      )}

      <Tooltip title={attachmentsMsg}>
        <Stack direction="row">
          <FileIcon color={hasAttachments ? 'inherit' : 'disabled'} />
        </Stack>
      </Tooltip>

      <Typography>{attachmentsCount}</Typography>
    </Stack>
  );
}
