import t from 'zod';

import {
  REQUIRED_MESSAGE,
  required,
} from 'src/libs/finbits/resolverValidations';
import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';
import { LabelDecoder } from 'src/libs/finbits/Labels/types';
import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { UserDecoder } from 'src/libs/finbits/Organization/Users/types';

import { ApprovalRuleConditionField, ApprovalRuleConditionType } from './types';

export const ApprovalRuleConditionFormResolver = t.object({
  field: t
    .union(
      [
        t.string({ invalid_type_error: REQUIRED_MESSAGE }),
        t.nativeEnum(ApprovalRuleConditionField),
      ],
      { required_error: REQUIRED_MESSAGE }
    )
    .nullable()
    .refine((value) => value !== null, { message: REQUIRED_MESSAGE }),
  type: t
    .nativeEnum(ApprovalRuleConditionType, {
      required_error: REQUIRED_MESSAGE,
      invalid_type_error: REQUIRED_MESSAGE,
    })
    .nullable()
    .refine((value) => value !== null, { message: REQUIRED_MESSAGE }),
  amountValue: t
    .object({
      amount: t.number({ required_error: REQUIRED_MESSAGE }),
    })
    .nullish(),
  labelsValues: t
    .object({
      labels: LabelDecoder.array().min(1, REQUIRED_MESSAGE),
    })
    .nullish(),
  categoriesValues: t
    .object({
      categories: CategoryDecoder.array().min(1, REQUIRED_MESSAGE),
    })
    .nullish(),
});

export const ApprovalRulesFormResolver = t
  .object({
    defaultRule: t.boolean(),
    description: required.max(
      255,
      'Esse campo não pode ser maior que 255 caracteres'
    ),
    assignees: UserDecoder.array().min(1, REQUIRED_MESSAGE),
    approvalType: t.nativeEnum(BillPayableApprovalType),
    conditions: ApprovalRuleConditionFormResolver.array(),
  })
  .superRefine(({ assignees, approvalType, defaultRule, conditions }, ctx) => {
    if (assignees.length > 1 && !approvalType) {
      ctx.addIssue({
        code: 'custom',
        message: REQUIRED_MESSAGE,
        path: ['approvalType'],
      });
    }

    if (!defaultRule && conditions.length === 0) {
      ctx.addIssue({
        code: 'custom',
        message: 'Deve incluir no mínimo uma condição',
        path: ['conditions'],
      });
    }
  });
