import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';

export const PAYMENT_METHOD_DICTIONARY: Record<PaymentMethod, string> = {
  [PaymentMethod.BOLETO]: 'Boleto',
  [PaymentMethod.CREDIT_CARD]: 'Cartão',
  [PaymentMethod.DIRECT_DEBIT]: 'Débito automático',
  [PaymentMethod.PIX]: 'Chave Pix',
  [PaymentMethod.WIRE_TRANSFER]: 'Transferência',
  [PaymentMethod.PIX_QR_CODE]: 'Pix Copia e Cola',
};
