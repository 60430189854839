import { List, ListItem, Skeleton, Stack } from '@mui/material';

export default function Loading() {
  return (
    <List disablePadding>
      {[...Array(4)].map((_, index) => (
        <ListItem
          key={index}
          divider
          disablePadding
          sx={{ pl: 11, py: 4, pr: 8 }}
        >
          <Stack width="100%" gap={4}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack width="100%" gap={2}>
                <Skeleton width="40%" variant="rectangular" />
                <Skeleton width="20%" variant="rectangular" />
              </Stack>
            </Stack>
            <Skeleton variant="rectangular" />
            <Stack direction="row" justifyContent="space-between">
              <Skeleton width="60px" variant="rectangular" />
              <Skeleton width="60px" variant="rectangular" />
            </Stack>
          </Stack>
        </ListItem>
      ))}
    </List>
  );
}
