import { forwardRef } from 'react';
import type { ReactNode, Ref } from 'react';

import { CardContent, CardHeader, Card as MuiCard } from '@mui/material';
import cn from 'classnames';

import styles from './Card.module.scss';

type Props = {
  notDraggable?: boolean;
  title: ReactNode;
  action: ReactNode;
  children: ReactNode;
  classNames?: string;
  ariaLabel?: string;
};

function Card(
  {
    notDraggable = false,
    title,
    action,
    classNames,
    children,
    ariaLabel,
  }: Props,
  ref: Ref<HTMLDivElement>
) {
  const cardClassName = cn(classNames, styles.card, {
    [styles.notDraggable]: notDraggable,
  });

  return (
    <MuiCard
      aria-label={ariaLabel}
      role="approval-rule-card"
      variant="outlined"
      className={cardClassName}
      ref={ref}
    >
      <CardHeader
        classes={{ action: styles.cardAction, root: styles.cardHeader }}
        title={title}
        action={action}
      />
      <CardContent className={styles.cardContent}>{children}</CardContent>
    </MuiCard>
  );
}

export default forwardRef(Card);
