import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiDialog({
  palette,
}: ThemeConfig): Components['MuiDialog'] {
  const backgroundColor = palette.background?.default;

  return {
    styleOverrides: {
      paper: {
        backgroundImage: 'none',
        backgroundColor,
      },
    },
  };
}
