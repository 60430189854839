import { snackbar } from 'src/mui';

import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import { useCreateFinancialFromReceivable } from 'src/libs/finbits/Receivables';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Receivable } from 'src/libs/finbits/Receivables/types';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';

import ManualConciliateHeaderButton from 'src/features/entries/ManualConciliateHeaderButton';
import type { ManualConciliateFormProps } from 'src/features/entries/ManualConciliateHeaderButton/ManualConciliateModal';

type Props = {
  receivable?: Receivable;
  onConciliate?: (financialEnty: FinancialEntry) => void;
};

export default function ConciliateHeaderButton({
  receivable,
  onConciliate,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { createFinancialFromReceivable } = useCreateFinancialFromReceivable();

  function handleManualConciliate(params: ManualConciliateFormProps) {
    createFinancialFromReceivable(
      {
        companyId,
        organizationId,
        receivableId: receivable?.id,
        ...params,
      },
      {
        onSuccess: (financialEntry: FinancialEntry) => {
          onConciliate && onConciliate(financialEntry);

          snackbar({
            variant: 'success',
            message: 'O lançamento programado foi realizado com sucesso!',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Ocorreu um erro ao marcar o lançamento programado como realizado!',
          });
        },
      }
    );
  }

  if (
    !useAllowedPermission({
      action: 'create_from_receivable',
      resource: 'financialEntries',
    }) ||
    !canPerformAction(receivable, AvailableActions.MARK_AS_FINANCIAL_ENTRY)
  ) {
    return null;
  }

  return (
    <ManualConciliateHeaderButton
      onConciliate={handleManualConciliate}
      account={receivable?.account}
    />
  );
}
