import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function ChevronSelectorVerticalIcon({
  viewBox = '0 0 24 24',
  fontSize = 'inherit',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="chevron-selector-vertical"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M7 15L12 20L17 15M7 9L12 4L17 9"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
