import { SendToApprovalIcon } from 'src/mui/_icons';
import { LoadingButton } from '@mui/lab';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import useWaitingApprovalAction from 'src/features/bills-to-pay/PaymentsFlow/useWaitingApprovalAction';

import type { Action } from '../../types';

export default function SendToWaitingApprovalButton({
  selecteds,
  onSuccess,
  Icon = SendToApprovalIcon,
  label = 'Enviar para aprovação',
}: Action) {
  const { sendToWaitingApproval, isLoading } = useWaitingApprovalAction({
    selecteds,
    onSuccess,
  });

  return (
    <WithAllowedPermission
      permissions={{
        resource: 'waitingApprovalPayments',
        action: 'create',
      }}
    >
      <LoadingButton
        variant="text"
        disabled={selecteds.length === 0}
        startIcon={<Icon />}
        onClick={sendToWaitingApproval}
        aria-label={label}
        loading={isLoading}
      >
        {label}
      </LoadingButton>
    </WithAllowedPermission>
  );
}
