import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function UndoWaitingPaymentIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="undo-waiting-payment"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M20 7.8C20 6.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0763 22V15.4M10 16.6V20.8C10 21.4627 10.5509 22 11.2305 22H19.4912C20.4022 22 21.177 21.3518 21.3155 20.4737L21.9781 16.2737C22.1502 15.1833 21.2851 14.2 20.1538 14.2H17.9984C17.6586 14.2 17.3832 13.9314 17.3832 13.6V11.4795C17.3832 10.6624 16.7039 10 15.866 10C15.6662 10 15.4851 10.1148 15.4039 10.2929L13.2387 15.0437C13.14 15.2604 12.9196 15.4 12.6765 15.4H11.2305C10.5509 15.4 10 15.9373 10 16.6Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11C8.65685 11 8 11 8 11M13 7C9.87581 7 8 7 8 7"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
