import t from 'zod';

export const CityDecoder = t.object({
  code: t.string(),
  name: t.string(),
  stateCode: t.string(),
});

export const CitiesDecoder = t.array(CityDecoder);

export type City = t.TypeOf<typeof CityDecoder>;
