import { useCallback } from 'react';

import merge from 'lodash/merge';
import type {
  LDClient,
  LDContext,
  LDFlagSet,
} from 'launchdarkly-react-client-sdk';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { useProfile } from 'src/libs/finbits/Organization/Users';
import type { User } from 'src/libs/finbits/Organization/Users/types';

import type { FeatureFlagContext } from './type';

async function setLDContext(context: LDContext | null, ldClient?: LDClient) {
  if (!context) return;

  try {
    await ldClient?.identify(context);
    await ldClient?.flush();
  } catch (e) {
    console.error('LaunchDarkly context error:', e);
  }
}

function isEnabledLDFeature(flagName: string, flags: LDFlagSet) {
  return flags[flagName] || false;
}

function buildFlagContext(user?: User, companyId?: string) {
  if (!user) return null;

  const company = user.companies?.find((comp) => comp.id === companyId);

  const context: FeatureFlagContext = {
    kind: 'multi',
    user: {
      key: user.id,
      name: user.name,
      email: user.email,
    },
    organization: {
      key: user.organizationId,
    },
  };

  if (company) {
    return merge(context, {
      company: {
        key: company.id,
        name: company.name,
        document: company.document,
        email: company.email,
        tradeName: company.tradeName,
      },
    });
  }

  return context;
}

function useSetLDContext() {
  const ldClient = useLDClient();
  const { user } = useProfile();

  const setContext = useCallback(
    async (companyId?: string) => {
      try {
        await ldClient?.waitUntilReady();

        const context = buildFlagContext(user, companyId);

        await setLDContext(context, ldClient);
      } catch (e) {
        console.error('LaunchDarkly context error:', e);
      }
    },
    [user, ldClient]
  );

  return { setContext };
}

export type { LDContext };
export { isEnabledLDFeature, useFlags, useSetLDContext, buildFlagContext };
