import cn from 'classnames';
import { Stack } from '@mui/material';
import CountUp from 'react-countup';
import { Typography } from 'src/design-system/components';

import { Tooltip } from 'src/mui';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './AmountBox.module.scss';

type Props =
  | {
      amount: number;
      amountType: BalanceType;
      tipText?: string;
      textColor?: string;
      onClick?: () => void;
      Icon?: JSX.Element;
      alternativeText?: string;
    }
  | {
      alternativeText: string;
      amount?: number;
      amountType?: BalanceType;
      tipText?: string;
      textColor?: string;
      onClick?: () => void;
      Icon?: JSX.Element;
    };

export default function AmountBox({
  amount = 0,
  amountType,
  tipText,
  textColor = 'text.primary',
  onClick,
  Icon,
  alternativeText,
}: Props) {
  const typographyColor = alternativeText ? 'grey.500' : textColor;
  const prefix = amountType === BalanceType.DEBIT ? '-R$ ' : 'R$ ';
  const cursorStyle = cn({
    [styles.clickableAmount]: Boolean(onClick),
    [styles.notClickableAmount]: !Boolean(onClick),
  });

  return (
    <Stack className={cursorStyle} direction="row" gap={1} onClick={onClick}>
      <Typography fontWeight="600" color={typographyColor}>
        {alternativeText || (
          <CountUp
            end={amount / 100}
            prefix={prefix}
            decimals={2}
            decimal=","
            separator="."
            duration={0.75}
            preserveValue
            useEasing={false}
            delay={0}
          />
        )}
      </Typography>
      <Tooltip title={tipText}>
        <Stack alignItems="center" justifyContent={'center'}>
          {Icon}
        </Stack>
      </Tooltip>
    </Stack>
  );
}
