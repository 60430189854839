import t from 'zod';

import { UserDecoder } from 'src/libs/finbits/Organization/Users/types';
import {
  AttachmentDecoder,
  ExternalFilesDecoder,
} from 'src/libs/finbits/Management/Attachments/types';
import { PaymentDetailsDecoder } from 'src/libs/finbits/Payments/types';
import { BoletoDecoder } from 'src/libs/finbits/Boletos/types';
import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';
import { BillPayableDecoder } from 'src/libs/finbits/Bills/validations';
import { AccountDecoder } from 'src/libs/finbits/Bank/Accounts/types';

import { BalanceType } from 'src/features/entries/BalanceInconsistency/types';

import { InboxItemSource } from './types';

export const InboxItemContactTypeDecoder = t.union([
  t.literal('legal'),
  t.literal('natural'),
  t.literal('international'),
]);

export const InboxItemStatusDecoder = t.union([
  t.literal('pending'),
  t.literal('accepted'),
  t.literal('rejected'),
]);

export const ReceivedEmailDecoder = t.object({
  id: t.string(),
  from: t.string(),
  to: t.string(),
  fromName: t.string(),
  subject: t.string().nullable(),
  htmlBody: t.string().nullable(),
  textBody: t.string().nullable(),
  attachments: AttachmentDecoder.array().nullish(),
  externalFiles: ExternalFilesDecoder.array().nullish(),
});

export const ReceivedNotaFiscalDecoder = t.object({
  id: t.string(),
  amount: t.number(),
  issuerLegalDocument: t.string(),
  issuerLegalDocumentType: t.nativeEnum(ContactType).nullish(),
  issuerTradeName: t.string(),
  number: t.string(),
  relevantDate: t.string().nullable(),
  serviceDescription: t.string().nullable(),
  authorizationDate: t.string().nullable(),
  attachments: AttachmentDecoder.array().nullish(),
});

export const ReceivedProductNotaFiscalDecoder = t.object({
  id: t.string(),
  amount: t.number(),
  issuerLegalDocument: t.string(),
  issuerLegalDocumentType: t.nativeEnum(ContactType).nullish(),
  issuerTradeName: t.string(),
  number: t.string(),
  authorizationDate: t.string().nullable(),
  attachments: AttachmentDecoder.array().nullish(),
});

export const UploadDecoder = t.object({
  id: t.string(),
  attachments: AttachmentDecoder.array().nullish(),
});

export const ReceivedWhatsAppDecoder = t.object({
  id: t.string(),
  attachments: AttachmentDecoder.array().nullish(),
});

export const PurchaseOrderDecoder = t.object({
  id: t.string(),
  requesterName: t.string(),
  requesterEmail: t.string(),
  description: t.string(),
  amount: t.number(),
  contactType: InboxItemContactTypeDecoder,
  contactDocument: t.string(),
  category: CategoryDecoder.nullish(),
  contact: ContactDecoder.nullable(),
  date: t.string().nullable(),
  notaFiscalNumber: t.string().nullish(),
  notaFiscalIssueDate: t.string().nullish(),
  dueDate: t.string().nullish(),
  comments: t.string().nullable(),
  paymentDetails: PaymentDetailsDecoder.nullable(),
  attachments: AttachmentDecoder.array().nullish(),
});

export const NewInboxItemDecoder = t.object({
  id: t.string(),
  title: t.string(),
  description: t.string(),
  fromName: t.string(),
  filesCount: t.number(),
  insertedAt: t.string(),
  source: t.nativeEnum(InboxItemSource),
  status: InboxItemStatusDecoder,
  fromDetail: t.string().nullable(),
  rejectionReason: t.string().nullable(),
  rejectedAt: t.string().nullable(),
  receivedEmail: ReceivedEmailDecoder.nullish(),
  receivedNotaFiscal: ReceivedNotaFiscalDecoder.nullish(),
  receivedProductNotaFiscal: ReceivedProductNotaFiscalDecoder.nullish(),
  boleto: BoletoDecoder.nullish(),
  receivedWhatsapp: ReceivedWhatsAppDecoder.nullish(),
  upload: UploadDecoder.nullish(),
  purchaseOrder: PurchaseOrderDecoder.nullish(),
  assignee: UserDecoder.nullish(),
});

export const ContactOptionalDecoder = ContactDecoder.extend({
  id: t.string().nullable(),
  name: t.string().nullable(),
  nickname: t.string().nullable(),
  active: t.boolean().nullable(),
  isInternational: t.boolean().nullable(),
  type: InboxItemContactTypeDecoder.nullable(),
});

export const BillPayableOptionalDecoder = BillPayableDecoder.extend({
  account: AccountDecoder.optional().nullish(),
  accountId: t.string().nullable(),
  amount: t.number().nullable(),
  amountType: t.nativeEnum(BalanceType).nullable(),
  companyId: t.string().nullable(),
  date: t.string().nullable(),
  id: t.string().nullable(),
  publicId: t.string().nullable(),
  contact: ContactOptionalDecoder.nullish(),
});
