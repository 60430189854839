import type { RcFile } from 'rc-upload/lib/interface';

export type SpreadsheetPostParams = {
  organizationId: string;
  companyId: string;
  accountId: string;
  file: RcFile;
};

export type SubmitParams = {
  accountId: string;
  file: RcFile;
};

export enum SpreadsheetTab {
  BILLS = 'bills',
  RECEIVABLES = 'receivables',
}

export const SpreadsheetTabDictionary = {
  [SpreadsheetTab.BILLS]: 'Pagamentos',
  [SpreadsheetTab.RECEIVABLES]: 'Recebimentos',
};
