import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import { CountriesDecoder } from './types';
import type { Country } from './types';

async function getCountries() {
  const response = await authenticatedAPI.get('/countries');
  return decodeResponse<Country[]>(response, CountriesDecoder);
}

export function useInternationalCountries() {
  return useQuery<Country[], ApiError>({
    queryKey: ['countries'],
    queryFn: () => getCountries(),
    select: (data: Country[]) =>
      data.filter((country) => country.code !== 'BR'),
  });
}
