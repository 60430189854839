import t from 'zod';

export const PublicApiTokenResolver = t.object({
  description: t.string().max(80, 'O campo deve ter no máximo 80 caracteres'),
});

export type PublicApiTokenPostParams = t.TypeOf<
  typeof PublicApiTokenResolver
> & {
  organizationId?: string;
  companyId?: string;
};

export type DeletePublicApiTokenParams = {
  id?: string;
  organizationId?: string;
  companyId?: string;
};

export const PublicApiTokenDecoder = t.object({
  id: t.string(),
  description: t.string(),
  token: t.string(),
  insertedAt: t.string(),
});

export type PublicApiToken = t.TypeOf<typeof PublicApiTokenDecoder>;
