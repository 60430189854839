export const fields = [
  [
    'document',
    'name',
    'tradeName',
    'email',
    'phone',
    ['address', 'postalCode'],
    ['address', 'state'],
    ['address', 'city'],
    ['address', 'district'],
    ['address', 'street'],
    ['address', 'number'],
    ['address', 'details'],
  ],
  ['municipalRegistration', 'simpleNational', 'specialTaxRuleId'],
  ['services'],
  ['file', 'password'],
];
