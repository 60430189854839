import { Box, Skeleton, Stack } from '@mui/material';

export default function Loading() {
  return (
    <>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ mt: 6 }}
      >
        <Stack>
          <Skeleton variant="text" width="200px" />
          <Skeleton variant="text" width="140px" />
        </Stack>
        <Skeleton variant="text" width="200px" />
      </Stack>
      <Box sx={{ pt: 4 }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Box>
    </>
  );
}
