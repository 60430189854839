import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function RotateLeftIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="rotate-left"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M16.2861 9.4805H2.14328C1.66917 9.4805 1.28613 9.86353 1.28613 10.3376V21.4269C1.28613 21.901 1.66917 22.2841 2.14328 22.2841H16.2861C16.7602 22.2841 17.1433 21.901 17.1433 21.4269V10.3376C17.1433 9.86353 16.7602 9.4805 16.2861 9.4805ZM15.1076 20.2484H3.32185V11.5162H15.1076V20.2484Z"
        fill="currentColor"
      />

      <path
        d="M20.2317 7.08421C18.121 4.38689 14.9817 2.96992 11.8049 2.95385L11.7995 1.23957C11.7995 1.06546 11.596 0.969031 11.462 1.07617L8.03346 3.78153C7.92631 3.86457 7.92899 4.02528 8.03346 4.111L11.4647 6.81903C11.6013 6.92617 11.8049 6.82975 11.8022 6.65564V4.94403C12.1477 4.94671 12.496 4.96814 12.8415 5.011C13.9692 5.15028 15.0406 5.4985 16.029 6.0476C17.0495 6.61546 17.9361 7.37885 18.6647 8.30564C19.3906 9.2351 19.9156 10.2797 20.221 11.4074C20.5538 12.6377 20.6132 13.9259 20.3951 15.1815H22.4013C22.7977 12.4065 22.0986 9.47617 20.2317 7.08421Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
