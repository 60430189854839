import type { SxProps, Theme } from '@mui/material';

export const title: SxProps<Theme> = {
  fontSize: 18,
  fontWeight: 600,

  mt: 4,

  py: 0,
  px: 6,
};

export const actions: SxProps<Theme> = {
  display: 'flex',

  p: 6,
  pt: 0,
};

export const action: SxProps<Theme> = {
  height: '44px',
};
