import { useMutation } from 'react-query';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { authenticatedAPI } from 'src/libs/finbits/client';

import type { PostParams } from './types';

async function postPurchaseOrder(purchaseOrder: PostParams) {
  const response = await authenticatedAPI.postForm(
    `/public/purchase_orders`,
    purchaseOrder
  );

  return response;
}

export function useCreatePurchaseOrder() {
  const { mutate: createPurchaseOrder, ...rest } = useMutation<
    unknown,
    ApiError<ApiErrorForm>,
    PostParams
  >(postPurchaseOrder);
  return { createPurchaseOrder, ...rest };
}
