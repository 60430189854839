import type { BadgeUnstyledTypeMap } from '@mui/base';
import type { BadgeProps } from '@mui/material';
import { Badge as MUIBadge } from '@mui/material';

export default function Badge({
  children,
  componentsProps,
  ...props
}: BadgeProps) {
  const badgeWithoutPosition: BadgeUnstyledTypeMap['props']['slotProps'] = {
    ...componentsProps,
    badge: {
      ...componentsProps?.badge,
      style: {
        position: 'unset',
        transform: 'none',
      },
    },
  };

  return (
    <MUIBadge
      {...props}
      componentsProps={!children ? badgeWithoutPosition : componentsProps}
    >
      {children}
    </MUIBadge>
  );
}
