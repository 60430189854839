import type { HighlighterProps } from 'react-highlight-words';
import Highlighter from 'react-highlight-words';

import { removeAccentuation } from 'src/libs/finbits/Accentuation';

import styles from './TextHighlight.module.less';

export default function TextHighlight(props: HighlighterProps) {
  return (
    <Highlighter
      autoEscape
      highlightClassName={styles.highlight}
      sanitize={removeAccentuation}
      {...props}
    />
  );
}
