import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiFormLabel({
  typography,
}: ThemeConfig): Components['MuiFormLabel'] {
  return {
    styleOverrides: { root: { ...typography['text-xs'], fontWeight: 500 } },
  };
}
