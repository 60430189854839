import { useState } from 'react';

import { snackbar } from 'src/mui';

import { OptedInSwitch } from 'src/ui';

import {
  useProfile,
  useUpdateOptedInApprovalsReport,
} from 'src/libs/finbits/Organization/Users';

export default function UserOptedInPendingApprovalsReport() {
  const { user } = useProfile();

  const [checked, setChecked] = useState(user?.optedInPendingApprovalsReport);

  const { updateOptedInApprovalsReport, isLoading } =
    useUpdateOptedInApprovalsReport();

  function onChange() {
    setChecked((prevChecked) => !prevChecked);

    updateOptedInApprovalsReport(
      { optedInPendingApprovalsReport: !checked },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Alteração salva com sucesso.',
          });
        },
        onError: () => {
          setChecked(user?.optedInPendingApprovalsReport);

          snackbar({
            variant: 'error',
            message: 'Ops! Falha ao salvar alteração.',
          });
        },
      }
    );
  }

  return (
    <OptedInSwitch
      checked={checked}
      loading={isLoading}
      onChange={onChange}
      title="Receber e-mail diário com lista de aprovações pendentes"
      description="Apenas para empresas que tenham o fluxo de aprovações habilitado"
    />
  );
}
