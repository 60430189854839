import { useEffect, useMemo } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';
import type { RuleObject } from 'rc-field-form/lib/interface';
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { AmountInput, FormItem, MaskedDatePicker, Popover } from 'src/ui';

import { convertToSignedNumber, getAmountType } from 'src/libs/finbits/Money';
import { format, isAfter, isSameDay, parseISO } from 'src/libs/finbits/Date';
import type { FieldError } from 'src/libs/finbits/Form';
import type {
  Account,
  AccountFormParams,
} from 'src/libs/finbits/Bank/Accounts/types';
import {
  AccountType,
  SynchronizeType,
} from 'src/libs/finbits/Bank/Accounts/types';

import BankSelect from 'src/features/DEPRECATED_accounts/BankSelect';
import BankSelected from 'src/features/DEPRECATED_accounts/BankSelected';

import styles from './AccountForm.module.less';
import type { CustomValidators } from './types';

type Props = {
  initialValues?: Partial<Account>;
  submitButtonTitle: string;
  onSubmit: (params: AccountFormParams) => Promise<void>;
  loading?: boolean;
  customValidators?: Partial<CustomValidators>;
  showBankSelect?: boolean;
  errors?: FieldError[];
  disabledFields?: Array<
    keyof Pick<AccountFormParams, 'branchNumber' | 'accountNumber'>
  >;
};

function defaultValidator() {
  return Promise.resolve();
}

function getValidator(
  customValidators: Partial<CustomValidators> | undefined,
  key: keyof CustomValidators
) {
  return customValidators ? customValidators[key] : defaultValidator;
}

function AccountForm({
  submitButtonTitle,
  onSubmit,
  initialValues,
  loading,
  customValidators,
  showBankSelect = false,
  errors,
  disabledFields = [],
}: Props) {
  const [form] = Form.useForm();

  const formValues = useMemo(() => {
    if (!initialValues) {
      return {
        accountNumber: null,
        bankName: '',
        branchNumber: null,
        name: '',
        routingNumber: null,
        synchronizeType: SynchronizeType.MANUAL,
        type: AccountType.CHECKING_ACCOUNT,
        initialBalance: {
          balance: null,
          date: null,
          balanceType: null,
        },
      };
    }

    return {
      ...initialValues,
      initialBalance: {
        ...initialValues?.initialBalance,
        balance:
          initialValues?.initialBalance?.balance != null
            ? convertToSignedNumber(
                initialValues?.initialBalance?.balance,
                initialValues?.initialBalance?.balanceType
              )
            : null,
        date: initialValues?.initialBalance?.date
          ? parseISO(initialValues?.initialBalance?.date)
          : null,
      },
    };
  }, [initialValues]);

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [form, formValues]);

  useEffect(() => {
    errors && form.setFields(errors);
  }, [form, errors]);

  function handleOnSubmit(values: AccountFormParams) {
    const { amount, type } = getAmountType(values.initialBalance?.balance ?? 0);

    const formattedDate = values.initialBalance?.date
      ? format(values.initialBalance.date, 'yyyy-MM-dd')
      : null;

    const parsedValues = {
      ...values,
      initialBalance: {
        balanceType: type,
        balance: amount,
        date: formattedDate,
      },
    };

    return onSubmit(parsedValues);
  }

  function isDateAfterOrEqualFirstsDailyBalance(date: Date) {
    if (!initialValues?.firstDailyBalanceDate) return false;

    const firstDailyBalanceDate = parseISO(
      initialValues?.firstDailyBalanceDate
    );

    return (
      isSameDay(date, firstDailyBalanceDate) ||
      isAfter(date, firstDailyBalanceDate)
    );
  }

  function dateValidator(_rule: RuleObject, value: Date) {
    if (isDateAfterOrEqualFirstsDailyBalance(value)) {
      return Promise.reject(
        `Não é possível cadastrar a data de referência posterior às datas de lançamentos. Utilize uma data anterior à ${format(
          initialValues?.firstDailyBalanceDate!,
          'dd/MM/yyyy'
        )}`
      );
    }
    return Promise.resolve();
  }

  const isManual = initialValues?.synchronizeType === 'manual';

  const isCashAccount = initialValues?.type === AccountType.CASH_ACCOUNT;

  return (
    <Form
      initialValues={formValues}
      onFinish={handleOnSubmit}
      layout="vertical"
      form={form}
    >
      <Form.Item name="type" hidden>
        <input />
      </Form.Item>
      <Row gutter={16}>
        {showBankSelect && (
          <Col span={24}>
            {isCashAccount ? (
              <BankSelected routingNumber="" />
            ) : (
              <FormItem
                required
                hasMax={false}
                name="routingNumber"
                label="Banco"
              >
                <BankSelect disabled={!isManual} />
              </FormItem>
            )}
          </Col>
        )}
        {!isCashAccount && (
          <>
            <Col span={24}>
              <FormItem
                required
                name="branchNumber"
                label="Agência (sem o dígito)"
                rules={[
                  { validator: getValidator(customValidators, 'branchNumber') },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Exemplo: 0000"
                  disabled={loading || disabledFields.includes('branchNumber')}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                required
                name="accountNumber"
                label="Conta (com o dígito)"
                rules={[
                  {
                    validator: getValidator(customValidators, 'accountNumber'),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Exemplo: 000000-0"
                  disabled={loading || disabledFields.includes('accountNumber')}
                />
              </FormItem>
            </Col>
          </>
        )}
        <Col span={24}>
          <FormItem
            required
            name="name"
            label="Nome da conta"
            rules={[{ validator: getValidator(customValidators, 'name') }]}
          >
            <Input
              size="large"
              placeholder="Exemplo: Conta Principal"
              disabled={loading}
            />
          </FormItem>
        </Col>

        {isManual && (
          <>
            <Col span={24}>
              <FormItem
                required={isManual}
                hasMax={false}
                name={['initialBalance', 'balance']}
                label={
                  <>
                    Saldo
                    <Popover
                      className={styles.popover}
                      placement="bottom"
                      content="Este valor será usado como base inicial para o cálculo do saldo"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </>
                }
              >
                <AmountInput
                  placeholder="0,00"
                  allowNegative
                  disabled={loading}
                />
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem
                required={isManual}
                hasMax={false}
                name={['initialBalance', 'date']}
                label={
                  <>
                    Data de referência
                    <Popover
                      className={styles.popover}
                      placement="bottom"
                      content="Data de referência do valor informado para o saldo"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </>
                }
                rules={[{ validator: dateValidator }]}
              >
                <MaskedDatePicker
                  placeholder="dd/mm/aaaa"
                  className={styles.datePicker}
                  size="large"
                  format="dd/MM/yyyy"
                  suffixIcon={<CalendarOutlined />}
                  disabled={loading}
                  disabledDate={isDateAfterOrEqualFirstsDailyBalance}
                />
              </FormItem>
            </Col>
          </>
        )}

        <Col span={24} className={styles.modalActions}>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={
                  !form.isFieldsTouched() ||
                  form.getFieldsError().some(({ errors }) => errors.length)
                }
              >
                {submitButtonTitle}
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default AccountForm;
