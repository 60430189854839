import { Dialog as MUIDialog } from '@mui/material';

import Header from './Header';
import Actions from './Actions';

type DialogCompounded = {
  Header: typeof Header;
  Actions: typeof Actions;
} & typeof MUIDialog;

export const Dialog = {
  ...MUIDialog,
  Header,
  Actions,
} as DialogCompounded;
