import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import { FinancialEntryDecoder } from 'src/libs/finbits/Management/FinancialEntries/types';
import { invalidateFinancialStatementEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { invalidateConciliationSuggestionsQueries } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';

import type { GetParams, SplitCreateParams, Splits } from './types';
import { SplitsDecoder } from './types';

async function postSplitTransaction({
  organizationId,
  companyId,
  transactionId,
  params,
}: SplitCreateParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/splits/${transactionId}`,
    params
  );

  return decodeResponse<Splits>(response, SplitsDecoder);
}

async function deleteSplitTransaction({
  organizationId,
  companyId,
  transactionId,
}: GetParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/splits/${transactionId}`
  );

  return decodeResponse<FinancialEntry>(response, FinancialEntryDecoder);
}

async function getSplits({
  organizationId,
  companyId,
  transactionId,
}: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/splits/${transactionId}`
  );

  return decodeResponse<Splits>(response, SplitsDecoder);
}

export function useCreateSplits() {
  const queryClient = useQueryClient();
  const { mutate: createSplits, ...rest } = useMutation<
    Splits,
    ApiError,
    SplitCreateParams
  >(postSplitTransaction, {
    onSuccess: () => {
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
    },
  });

  return { createSplits, ...rest };
}

export function useUndoSplits() {
  const queryClient = useQueryClient();
  const { mutate: undoSplits, ...rest } = useMutation<
    FinancialEntry,
    ApiError,
    GetParams
  >(deleteSplitTransaction, {
    onSuccess: () => {
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
    },
  });

  return { undoSplits, ...rest };
}

export function useGetSplits(params: GetParams) {
  const { data, ...rest } = useQuery<Splits, ApiError>({
    enabled: !!params.organizationId && !!params.companyId,
    queryKey: ['splits', params],
    queryFn: () => getSplits(params),
  });

  return { splits: withEmptyArrayDefault(data), ...rest };
}
