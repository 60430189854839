import type { ReactNode } from 'react';

import styles from './Table.module.scss';

type Props = {
  headers: ReactNode;
  data: ReactNode;
};

function Table({ headers, data }: Props) {
  return (
    <table className={styles.table}>
      <thead>
        <tr>{headers}</tr>
      </thead>
      <tbody>
        <tr>{data}</tr>
      </tbody>
    </table>
  );
}

type CellProps = {
  children: ReactNode;
};

function HeaderCell({ children }: CellProps) {
  return <th className={styles.th}>{children}</th>;
}

function DataCell({ children }: CellProps) {
  return <td className={styles.td}>{children}</td>;
}

Table.HeaderCell = HeaderCell;
Table.DataCell = DataCell;

export default Table;
