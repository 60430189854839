import { useEffect } from 'react';

import type { FormProps } from 'antd';
import { Button, Col, Form, Input, Row } from 'antd';

import { FormItem, MaskedInput, MaskedSearch } from 'src/ui';

import { cnpjMask, formCnpjValidator } from 'src/libs/finbits/Documents';
import {
  addressPostalCodeValidator,
  cepMask,
} from 'src/libs/finbits/BrazilianData/Addresses';
import {
  cellphoneMask,
  phoneMask,
  phoneValidator,
} from 'src/libs/finbits/Organization/PhoneNumber';
import { emailRegex } from 'src/libs/finbits/Email';
import type {
  Company,
  CompanyUpdateParams,
} from 'src/libs/finbits/Organization/Companies/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import StatesSelect from 'src/features/location/StatesSelect';
import CitiesSelect from 'src/features/location/CitiesSelect';

import { useCompanyForm } from './useCompanyForm';

export type CompanyFormProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  initialValues?: Company;
} & Pick<FormProps<CompanyUpdateParams>, 'form' | 'onFinish'>;

function CompanyForm({
  form,
  initialValues,
  isLoading = false,
  isDisabled = false,
  onFinish,
}: CompanyFormProps) {
  const {
    companyForm,
    stateCode,
    searchingDocument,
    searchingAddress,
    initialValuesFormatted,
    isNotaFiscalEnabled,
    onSubmit,
    onSearchDocument,
    onSearchAddress,
    onFieldsChange,
  } = useCompanyForm({
    form,
    initialValues,
    onFinish,
  });

  useEffect(() => {
    companyForm?.setFieldsValue(initialValuesFormatted);
  }, [companyForm, initialValuesFormatted]);

  return (
    <Form
      form={companyForm}
      initialValues={initialValuesFormatted}
      layout="vertical"
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            required
            name="document"
            label="CNPJ"
            rules={[{ validator: formCnpjValidator }]}
          >
            <MaskedSearch
              mask={[cnpjMask]}
              size="large"
              placeholder="Informe o CNPJ"
              onSearch={onSearchDocument}
              loading={searchingDocument}
              disabled={isDisabled || isNotaFiscalEnabled}
            />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem required name="name" label="Razão social">
            <Input
              size="large"
              placeholder="Ex: Empresa LTDA"
              disabled={isDisabled}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem required name="tradeName" label="Nome da empresa">
            <Input
              size="large"
              placeholder="Ex: Empresa"
              disabled={isDisabled}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem
            name="email"
            label="E-mail da empresa"
            rules={[
              {
                message: 'Formato de e-mail inválido',
                pattern: emailRegex,
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Ex: empresa@mail.com"
              disabled={isDisabled}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="Telefone"
            name="phone"
            rules={[{ validator: phoneValidator }]}
          >
            <MaskedInput
              mask={[phoneMask, cellphoneMask]}
              size="large"
              placeholder="(00) 00000-0000"
              disabled={isDisabled}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem
            label="CEP"
            name={['address', 'postalCode']}
            rules={[{ validator: addressPostalCodeValidator }]}
          >
            <MaskedSearch
              mask={[cepMask]}
              size="large"
              placeholder="00000-000"
              onSearch={onSearchAddress}
              loading={searchingAddress}
              disabled={isDisabled || isNotaFiscalEnabled}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label="Estado" name={['address', 'state']}>
            <StatesSelect disabled={isDisabled || isNotaFiscalEnabled} />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label="Cidade" name={['address', 'city']}>
            <CitiesSelect
              stateCode={stateCode}
              disabled={isDisabled || isNotaFiscalEnabled}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label="Bairro" name={['address', 'district']}>
            <Input size="large" disabled={isDisabled} />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label="Rua" name={['address', 'street']}>
            <Input size="large" disabled={isDisabled} />
          </FormItem>
        </Col>

        <Col span={3}>
          <FormItem label="Número" name={['address', 'number']} max={10}>
            <Input size="large" disabled={isDisabled} />
          </FormItem>
        </Col>

        <Col span={5}>
          <FormItem label="Complemento" name={['address', 'details']}>
            <Input size="large" disabled={isDisabled} />
          </FormItem>
        </Col>

        <Col span={24}>
          <WithAllowedPermission
            permissions={{ resource: 'companies', action: 'update' }}
          >
            <FormItem shouldUpdate>
              {({ getFieldsError }) => {
                const isError = getFieldsError().some(
                  ({ errors }) => errors.length
                );

                return (
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    disabled={isError || isDisabled}
                    loading={isLoading}
                  >
                    Salvar
                  </Button>
                );
              }}
            </FormItem>
          </WithAllowedPermission>
        </Col>
      </Row>
    </Form>
  );
}

export default CompanyForm;
