import { useCallback } from 'react';

import { AlertCircleIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useUpdateContact } from 'src/libs/finbits/Organization/Companies/Contacts';

type Props = {
  organizationId?: string;
  companyId?: string;
  contactId: string;
  onChangeActive?: () => void;
};

export function useActivationActions({
  organizationId,
  companyId,
  contactId,
  onChangeActive,
}: Props) {
  const openConfirmDialog = useOpenConfirmDialog();
  const { updateContact } = useUpdateContact();

  const handleDeactivateContact = useCallback(() => {
    updateContact(
      { organizationId, companyId, id: contactId, active: false },
      {
        onSuccess: () => {
          onChangeActive && onChangeActive();
          snackbar({
            variant: 'success',
            message: 'Contato desativado com sucesso.',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Não foi possível desativar o contato.',
          });
        },
      }
    );
  }, [organizationId, companyId, contactId, updateContact, onChangeActive]);

  const handleActivateContact = useCallback(() => {
    updateContact(
      { organizationId, companyId, id: contactId, active: true },
      {
        onSuccess: () => {
          onChangeActive && onChangeActive();
          snackbar({
            variant: 'success',
            message: 'Contato ativado com sucesso.',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Não foi possível ativar o contato.',
          });
        },
      }
    );
  }, [organizationId, companyId, contactId, updateContact, onChangeActive]);

  function openDeactivationDialog() {
    return openConfirmDialog({
      variant: 'error',
      icon: <AlertCircleIcon />,
      title: 'Tem certeza que deseja desativar?',
      content:
        'Ao desativar, este contato não poderá ser vinculado aos lançamentos. Mas fique tranquilo que ele não será desvinculado dos lançamentos existentes.',
      confirmText: 'Sim, quero desativar',
      onConfirm: handleDeactivateContact,
      cancelText: 'Cancelar',
    });
  }

  function openActivationDialog() {
    return openConfirmDialog({
      variant: 'error',
      icon: <AlertCircleIcon />,
      title: 'Tem certeza que deseja ativar?',
      content:
        'Ao ativar, este contato poderá ser vinculado aos lançamentos e entrará na lista de contatos ativos novamente.',
      confirmText: 'Sim, quero ativar',
      onConfirm: handleActivateContact,
      cancelText: 'Cancelar',
    });
  }

  return { openDeactivationDialog, openActivationDialog };
}
