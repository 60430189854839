import { Space, Typography } from 'antd';

import { Logo, Title } from 'src/ui';

import styles from './PurchaseOrderContent.module.less';

type Props = {
  title: string;
  text: string;
};

export default function PurchaseOrderContent({ title, text }: Props) {
  return (
    <>
      <Logo />
      <Space direction="vertical" size={32}>
        <Title level={2} className={styles.title}>
          {title}
        </Title>
        <Typography.Text className={styles.text}>{text}</Typography.Text>
      </Space>
    </>
  );
}
