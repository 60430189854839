import { FormField } from 'src/mui/_scss';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import CategorySelect from 'src/features/categories/CategorySelect';

type Props = {
  index: number;
};

export default function CategoryValues({ index }: Props) {
  return (
    <FormField
      label="Valor"
      name={`conditions.${index}.categoriesValues.categories`}
      shouldUnregister
      defaultValue={[]}
    >
      {(field) => {
        return (
          <CategorySelect<true, false>
            {...field}
            type={BalanceType.DEBIT}
            limitTags={1}
            multiple
            disableCloseOnSelect
            disableCreateButton
            onChange={(event, values) => field.onChange(values)}
            placeholder="valor"
          />
        );
      }}
    </FormField>
  );
}
