import t from 'zod';

import { REQUIRED_MESSAGE } from 'src/libs/finbits/resolverValidations';
import type { User } from 'src/libs/finbits/Organization/Users/types';
import { UserDecoder } from 'src/libs/finbits/Organization/Users/types';
import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';

export const FormResolver = t.object({
  assignees: UserDecoder.array().nonempty(REQUIRED_MESSAGE),
  approvalType: t.nativeEnum(BillPayableApprovalType),
  description: t.string(),
});

export type FormProps = {
  assignees: User[];
  approvalType: BillPayableApprovalType;
  description: string;
};
