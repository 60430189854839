import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from 'src/design-system/components';

import type { CompanyCreateParams } from 'src/libs/finbits/Organization/Companies/types';
import type { ErrorsObject } from 'src/libs/finbits/Form';

import CompanyCard from 'src/features/sign-up/CompanyCard';
import TermsOfUseModal from 'src/features/terms-of-use/TermsOfUseModal';
import PrivacyPolicyModal from 'src/features/terms-of-use/PrivacyPolicyModal';
import SaasContractModal from 'src/features/terms-of-use/SaasContractModal';

type Props = {
  companies: CompanyCreateParams[];
  isSaving: boolean;
  onClickAdd: () => void;
  onClickFinish: () => void;
  onClickRemove: (document: string) => void;
  submitErrors?: ErrorsObject;
};

export default function CompaniesList({
  companies,
  submitErrors,
  isSaving,
  onClickAdd,
  onClickFinish,
  onClickRemove,
}: Props) {
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  function onAcceptTerms(e: ChangeEvent<HTMLInputElement>) {
    setAcceptedTerms(e.target.checked);
  }

  return (
    <>
      <Grid container gap={8} sx={{ mt: 8 }}>
        {companies.map((company) => (
          <Grid xs={12} key={company.document}>
            <CompanyCard
              document={company.document}
              name={company.name}
              tradeName={company.tradeName}
              onClickRemove={onClickRemove}
              submitErrors={
                submitErrors &&
                submitErrors.find(
                  (e: ErrorsObject) => e.document === company.document
                )?.errors
              }
            />
          </Grid>
        ))}
      </Grid>
      <Button
        fullWidth
        onClick={onClickAdd}
        sx={{ mt: 8, mb: 8 }}
        variant="outlined"
        size="large"
      >
        Adicionar outra empresa
      </Button>
      <FormControlLabel
        control={<Checkbox onChange={onAcceptTerms} />}
        label={
          <Typography>
            Estou ciente e concordo com os{' '}
            <TermsOfUseModal label="Termos de Uso," />{' '}
            <PrivacyPolicyModal label="Política de Privacidade" /> e{' '}
            <SaasContractModal label="Contrato SaaS" /> da Finbits
          </Typography>
        }
      />
      <LoadingButton
        color="primary"
        disabled={companies.length === 0 || !acceptedTerms}
        fullWidth
        loading={isSaving}
        onClick={onClickFinish}
        sx={{ mt: 8, mb: 8 }}
        variant="contained"
        size="large"
      >
        Concluir
      </LoadingButton>
    </>
  );
}
