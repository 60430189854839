import { useMutation } from 'react-query';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { authenticatedAPI } from 'src/libs/finbits/client';

export type CreateStatementExportParams = {
  organizationId?: string;
  companyId?: string;
  accounts?: string[];
  startDate?: string;
  endDate?: string;
  message?: string;
  sendTo?: string[];
  includeScheduledEntries?: boolean;
};

async function postStatementExport({
  organizationId,
  companyId,
  ...params
}: CreateStatementExportParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/statements_exports`,
    params
  );

  return response.data;
}

export function useCreateStatementExport() {
  const { mutate: createStatementExport, ...rest } = useMutation<
    unknown,
    ApiError<ApiErrorForm>,
    CreateStatementExportParams
  >(postStatementExport);
  return { createStatementExport, ...rest };
}
