import { Stack } from '@mui/material';
import { ArrowNarrowDownIcon, ArrowUpIcon } from 'src/mui/_icons';

import type { ApprovalRule } from 'src/libs/finbits/ApprovalRules/types';

import ActionButton from '../ActionButton';

type Props = {
  approvalRule: ApprovalRule;
  isFirst?: boolean;
  isBeforeDefaultRule?: boolean;
  moveUp: () => void;
  moveDown: () => void;
};

export default function ChangePositionActions({
  approvalRule: { defaultRule },
  isFirst = false,
  isBeforeDefaultRule = false,
  moveUp,
  moveDown,
}: Props) {
  const tooltipText = 'A prioridade desta regra não pode ser alterada.';

  return (
    <Stack direction="row" alignItems="center">
      <ActionButton
        permissions={{ resource: 'approvalsRules', action: 'update_position' }}
        icon={<ArrowUpIcon />}
        disabled={defaultRule || isFirst}
        tooltip={defaultRule ? tooltipText : 'Subir prioridade'}
        label="Subir prioridade"
        onClick={moveUp}
      />
      <ActionButton
        permissions={{ resource: 'approvalsRules', action: 'update_position' }}
        icon={<ArrowNarrowDownIcon />}
        disabled={defaultRule || isBeforeDefaultRule}
        tooltip={defaultRule ? tooltipText : 'Descer prioridade'}
        label="Descer prioridade"
        onClick={moveDown}
      />
    </Stack>
  );
}
