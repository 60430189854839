import { Grid } from '@mui/material';
import { Typography } from 'src/design-system/components';

import {
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';

import DevDocCard from 'src/features/companies/PublicApiTokens/DevDocCard';
import TokensCard from 'src/features/companies/PublicApiTokens/TokensCard';

import styles from './PublicApiTokens.module.scss';

export default function ApiTokens() {
  const { companyId, organizationId } = useCompanyParams();

  const { company } = useCompany({
    companyId,
    organizationId,
  });

  return (
    <Grid container rowSpacing={9} columnSpacing={7}>
      <Grid item xs={12} sm={12} md={12} className={styles.description}>
        <Typography>
          Crie e gerencie tokens da API pública para a empresa{' '}
          <b>{company?.name}.</b>
        </Typography>
        <Typography>
          Os tokens funcionam como uma forma de identificação segura para seus
          desenvolvedores se conectarem a sua API.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={8}>
        <TokensCard />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <DevDocCard />
      </Grid>
    </Grid>
  );
}
