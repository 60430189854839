import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';

import { useBanks } from 'src/libs/finbits/Bank';

type Props = {
  onChange: (routingNumber: string | undefined) => void;
} & Omit<
  SelectProps<any, false, false, false>,
  'options' | 'defaultValue' | 'onChange'
>;

export default function BankSelect({ onChange, ...props }: Props) {
  const { data: banks = [], isLoading } = useBanks();
  const value = banks.find((bank) => bank.routingNumber === props?.value);

  return (
    <Select
      aria-label="Banco"
      placeholder="Digite o nome do banco"
      options={banks}
      loading={isLoading}
      value={value || null}
      onChange={(_e, value) => onChange(value?.routingNumber)}
      getOptionLabel={(option) => option.fullName}
    />
  );
}
