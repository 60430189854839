import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Button as MUIButton } from '@mui/material';
import { Avatar, Button, Col, Modal, Row, Space, Typography } from 'antd';
import {
  CheckOutlined,
  CopyOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

import { Mark, Title } from 'src/ui';

import Illustration from 'src/assets/illustration_02.png';

import {
  e164Format,
  phoneFormat,
} from 'src/libs/finbits/Organization/PhoneNumber';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './WhatsAppInfo.module.less';
import { button } from './WhatsAppInfo.sx';

const phoneNumber = import.meta.env.VITE_WHATSAPP_NUMBER ?? '';

function WhatsAppInfo() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { companyId, organizationId } = useCompanyParams();

  useEffect(() => {
    if (!isCopied) return;

    const timeout = setTimeout(() => setIsCopied(false), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [setIsCopied, isCopied]);

  function copyPhone() {
    const phoneNumberFormatted = e164Format(phoneNumber);

    if (phoneNumberFormatted) {
      setIsCopied(true);
      navigator.clipboard.writeText(phoneNumberFormatted);
    }
  }

  function openWhatsApp() {
    window.open(`https://wa.me/${e164Format(phoneNumber)}`, '_blank');
  }

  return (
    <div>
      <MUIButton
        onClick={() => setIsModalVisible(!isModalVisible)}
        aria-label="Envio WhatsApp"
        size="small"
        sx={button}
      >
        Ver Instruções
      </MUIButton>

      <Modal
        centered
        destroyOnClose
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width={652}
        className={styles.modal}
      >
        <Space direction="vertical" size={50}>
          <Row>
            <Col span={15}>
              <Row>
                <Col>
                  <Title level={2} className={styles.title}>
                    Envie anexos pelo WhatsApp
                  </Title>
                </Col>
                <Col>
                  <Typography.Text className={styles.info}>
                    O número
                    <Mark type="full" className={styles.mark}>
                      {phoneFormat(phoneNumber)}
                    </Mark>
                    é o WhatsApp do Finbits. Siga os passos abaixo para enviar
                    seus recibos, notas, comprovantes para a plataforma
                    diretamente por lá:
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={9}>
              <div className={styles.illustration}>
                <img
                  src={Illustration}
                  alt="Ilustração de uma pessoa com uma lupa"
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[20, 32]} className={styles.steps}>
            <Col span={12}>
              <Row>
                <Col span={4}>
                  <Avatar size={30}> 1 </Avatar>
                </Col>
                <Col span={20}>
                  <Typography.Text className={styles.stepInfo}>
                    Acesse
                    <Link
                      to={`/organizations/${organizationId}/companies/${companyId}/profile`}
                    >
                      {' '}
                      seu perfil no Finbits{' '}
                    </Link>
                    e confirme se o seu número está informado no campo “Celular”
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={4}>
                  <Avatar size={30}> 2 </Avatar>
                </Col>
                <Col span={20}>
                  <Typography.Text className={styles.stepInfo}>
                    Na agenda de contatos do seu celular, salve o número do
                    Finbits: <br /> {phoneFormat(phoneNumber)}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={4}>
                  <Avatar size={30}> 3 </Avatar>
                </Col>
                <Col span={20}>
                  <Typography.Text className={styles.stepInfo}>
                    No WhatsApp, abra o chat com o Finbits e comece a enviar
                    seus arquivos
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={4}>
                  <Avatar size={30}> 4 </Avatar>
                </Col>
                <Col span={20}>
                  <Typography.Text className={styles.stepInfo}>
                    No Finbits, abra um lançamento e na parte de anexos clique
                    em “Caixa de Entrada” para selecionar o seu arquivo
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className={styles.buttons}>
            <Button
              icon={
                isCopied ? (
                  <CheckOutlined className={styles.successCheck} />
                ) : (
                  <CopyOutlined />
                )
              }
              size="large"
              onClick={copyPhone}
            >
              Copiar Número
            </Button>
            <Button
              icon={<WhatsAppOutlined />}
              type="primary"
              size="large"
              onClick={openWhatsApp}
            >
              Abrir no WhatsApp
            </Button>
          </div>
        </Space>
      </Modal>
    </div>
  );
}

export default WhatsAppInfo;
