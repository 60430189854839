import { Tag, Tooltip } from 'antd';

import { translateCurrentSituation } from 'src/libs/finbits/Management/Entries/currentSituationTranslations';
import { EntrySituation } from 'src/libs/finbits/Management/Entries/types';

function colors(currentSituation: EntrySituation | null | undefined) {
  const situationsToUseErrorColor = [
    EntrySituation.UNAUTHORIZED,
    EntrySituation.FAILED,
    EntrySituation.REVERTED,
    EntrySituation.CANCELED,
    EntrySituation.DELETED,
  ];

  if (!currentSituation) {
    return 'warning';
  }

  if (situationsToUseErrorColor.includes(currentSituation)) {
    return 'error';
  }

  if (currentSituation === EntrySituation.PAID) {
    return 'success';
  }

  if (currentSituation === EntrySituation.WAITING_PAYMENT) {
    return 'warning';
  }

  return 'default';
}

function title(currentSituation: EntrySituation | null | undefined) {
  if (currentSituation === EntrySituation.WAITING_PAYMENT) {
    return 'Você precisa incluir o pagamento manualmente no seu banco.';
  }

  return '';
}

type Props = {
  currentSituation?: EntrySituation | null;
};

export default function CurrentSituationTag({ currentSituation }: Props) {
  return (
    <Tooltip title={title(currentSituation)}>
      <Tag color={colors(currentSituation)}>
        {translateCurrentSituation(currentSituation)}
      </Tag>
    </Tooltip>
  );
}
