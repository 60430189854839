import { Box, FormHelperText } from '@mui/material';

import type { ErrorsObject } from 'src/libs/finbits/Form';

type Props = {
  submitErrors?: ErrorsObject;
};

export default function CompanyErrors({ submitErrors }: Props) {
  const errorsDocument = submitErrors?.document;
  const errorsName = submitErrors?.name;
  const errorsTradeName = submitErrors?.tradeName;

  return (
    <Box sx={{ mt: 2 }}>
      {errorsDocument && (
        <FormHelperText error>CNPJ: {errorsDocument}</FormHelperText>
      )}
      {errorsName && (
        <FormHelperText error>Razão social: {errorsName}</FormHelperText>
      )}
      {errorsTradeName && (
        <FormHelperText error>
          Nome da empresa: {errorsTradeName}
        </FormHelperText>
      )}
    </Box>
  );
}
