import { Tag, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { format } from 'src/libs/finbits/Date';
import type { NotaFiscal } from 'src/libs/finbits/NotaFiscal/types';

import { NotaFiscalStatus, notaFiscalStatusDictionary } from './types';
import styles from './NotaFiscalStatusTag.module.less';

type Props = {
  notaFiscal: NotaFiscal;
};

const statusTag = {
  [NotaFiscalStatus.ISSUING]: {
    icon: <ClockCircleOutlined />,
    color: 'warning',
    content: notaFiscalStatusDictionary[NotaFiscalStatus.ISSUING].toLowerCase(),
    tooltip: () => 'Aguardando emissão pela prefeitura.',
  },
  [NotaFiscalStatus.ISSUED]: {
    icon: <CheckCircleOutlined />,
    color: 'success',
    content: notaFiscalStatusDictionary[NotaFiscalStatus.ISSUED].toLowerCase(),
    tooltip: ({ issuedAt, lastEvent }: NotaFiscal) => {
      return `Emitido por ${lastEvent?.user?.name} em ${format(
        issuedAt!,
        'dd/MM/yy'
      )}.`;
    },
  },
  [NotaFiscalStatus.ISSUE_FAILED]: {
    icon: <ExclamationCircleOutlined />,
    color: 'error',
    content:
      notaFiscalStatusDictionary[NotaFiscalStatus.ISSUE_FAILED].toLowerCase(),
    tooltip: ({ lastEvent }: NotaFiscal) => {
      const reason =
        lastEvent?.failedReason ||
        'Falha de emissão desconhecida. Entre em contato com a prefeitura para mais detalhes';
      return reason;
    },
  },
  [NotaFiscalStatus.CANCELLING]: {
    icon: <ClockCircleOutlined />,
    color: 'warning',
    content:
      notaFiscalStatusDictionary[NotaFiscalStatus.CANCELLING].toLowerCase(),
    tooltip: () => 'Em estágio de cancelamento.',
  },
  [NotaFiscalStatus.CANCELLED]: {
    icon: <CloseCircleOutlined />,
    color: 'default',
    content:
      notaFiscalStatusDictionary[NotaFiscalStatus.CANCELLED].toLowerCase(),
    tooltip: ({ cancelledAt, lastEvent }: NotaFiscal) => {
      try {
        const formattedDate = format(cancelledAt!, 'dd/MM/yy');
        return `Cancelado por ${lastEvent?.user?.name} em ${formattedDate}.`;
      } catch {
        return `Cancelado por ${lastEvent?.user?.name}`;
      }
    },
  },
};

export default function NotaFiscalStatusTag({ notaFiscal }: Props) {
  const { icon, color, content, tooltip } = statusTag[notaFiscal.status];

  return (
    <Tooltip title={tooltip(notaFiscal)} placement="top">
      <Tag color={color} icon={icon} className={styles.tag}>
        {content}
      </Tag>
    </Tooltip>
  );
}
