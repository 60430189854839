import { forwardRef } from 'react';

import type { SearchProps } from 'antd/lib/input';
import { Input } from 'antd';
import type { InputRef } from 'rc-input/lib/interface';

import { isMaskFilled, masker, unMask } from 'src/libs/finbits/Masker';

type Props = SearchProps & {
  mask: string[];
  loading?: boolean;
};

const MaskedSearch = forwardRef<InputRef, Props>(
  ({ mask, onChange, value, loading, ...props }, ref) => {
    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        const unMaskedValue = isMaskFilled(mask, event.target.value.trim())
          ? unMask(value as string)
          : unMask(event.target.value);

        onChange({
          ...event,
          target: { ...event.target, value: unMaskedValue },
        });
      }
    }

    return (
      <Input.Search
        {...props}
        onChange={handleOnChange}
        value={value ? masker(value as string, mask) : undefined}
        loading={loading}
        ref={ref}
      />
    );
  }
);

export default MaskedSearch;
