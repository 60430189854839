import type { SxProps, Theme } from '@mui/material';
import {
  listItemButtonClasses,
  listItemSecondaryActionClasses,
} from '@mui/material';

export const baseListItemButton = {
  pl: 12,
  py: 4,
  pr: 8,

  height: '159px',

  [`&.${listItemButtonClasses.selected}`]: {
    background: (theme: Theme) => theme.palette.action.hover,

    '&:hover': {
      background: (theme: Theme) => theme.palette.action.hover,
    },
  },

  '&:hover': {
    background: 'none',
  },
};

export const active: SxProps<Theme> = {
  background: (theme) => theme.palette.action.hover,
};

export const secondaryAction: SxProps<Theme> = {
  [`.${listItemButtonClasses.root}`]: {
    pr: '32px',
  },
  [`.${listItemSecondaryActionClasses.root}`]: {
    position: 'absolute',
    top: '32px',
    left: '37px',
    width: '20px',
  },
};
