import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import { pixTypeDictionary } from 'src/libs/finbits/Bank/Pix/translations';
import { formatPixKey } from 'src/libs/finbits/Bank/Pix/Pix';

import DetailsInfo from 'src/features/inbox-items/InboxItemDetails/DetailsInfo';

type Props = {
  paymentDetails: PaymentDetails;
};

export default function PixDetails({ paymentDetails }: Props) {
  return (
    <DetailsInfo
      label={pixTypeDictionary[paymentDetails.pixType!]}
      value={paymentDetails.pixKey}
      formatter={(value) => formatPixKey(value, paymentDetails.pixType!)}
    />
  );
}
