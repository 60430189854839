import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function FilterLinesIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="filter-lines"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M6 12H18M3 6H21M9 18H15"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
