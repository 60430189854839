import type { QueryClient } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { invalidateEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';

import { IgnoredRecordsDecoder } from './types';
import type {
  GetParams,
  IgnoredRecord,
  IgnoredRecordDeleteParams,
} from './types';

async function getIgnoredRecords({
  organizationId,
  companyId,
  ...params
}: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/ignored_records`,
    { params }
  );
  return decodeResponse<IgnoredRecord[]>(response, IgnoredRecordsDecoder);
}

async function deleteIgnoredRecord({
  organizationId,
  companyId,
  ignoredRecordId,
}: IgnoredRecordDeleteParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/ignored_records/${ignoredRecordId}`
  );

  return response.data;
}

export function useIgnoredRecords(params: GetParams) {
  const { data, ...rest } = useQuery<IgnoredRecord[], ApiError>({
    queryKey: ['ignoredRecords', { ...params }],
    enabled:
      params.accountsIds.length > 0 &&
      !!params.organizationId &&
      !!params.companyId,
    queryFn: () => getIgnoredRecords(params),
  });

  return { ignoredRecords: withEmptyArrayDefault(data), ...rest };
}

export function invalidateIgnoredRecordsQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['ignoredRecords']);
}

export function useDeleteIgnoredRecord() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    string,
    ApiError,
    IgnoredRecordDeleteParams
  >(deleteIgnoredRecord, {
    onSuccess: () => {
      invalidateEntriesQueries(queryClient);
    },
  });

  return { deleteIgnoredRecord: mutate, ...rest };
}
