import type { ReactNode } from 'react';

import { Button, Typography } from 'antd';

import { Title } from 'src/ui';

import styles from './ErrorMessage.module.less';

type Props = {
  icon?: ReactNode;
  message: string;
  description?: string;
};

function ErrorMessage({ icon, message, description }: Props) {
  return (
    <div className={styles.container}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.text}>
        <Title level={4}>{message}</Title>
        {description && (
          <Typography.Text type="secondary">{description}</Typography.Text>
        )}
      </div>
      <Button size="large" type="primary" href="/">
        Voltar à página inicial
      </Button>
    </div>
  );
}

export default ErrorMessage;
