export function abbreviateLastName(lastName?: string) {
  if (lastName) {
    return `${lastName.charAt(0).toUpperCase()}.`;
  }

  return null;
}

export function formatName(name?: string | null) {
  if (name?.includes(' ')) {
    const [firstName, ...rest] = name.split(' ');

    const lastName = rest.at(-1);

    return [firstName, abbreviateLastName(lastName)].join(' ');
  }

  return name;
}
