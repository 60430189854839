import { Typography } from 'antd';
import { Button } from '@mui/material';
import { closeSnackbar } from 'notistack';

import { snackbar, snackbarAction } from 'src/mui';

import type { User } from 'src/libs/finbits/Organization/Users/types';

export const SNACKBAR_KEY = 'invitationSuccess';

export default function showsInvitationSuccessNotification(user: User) {
  function copySignUpUrl() {
    navigator.clipboard.writeText(user.signupUrl ?? '');
    closeSnackbar(SNACKBAR_KEY);
  }

  return snackbar({
    variant: 'success',
    title: <Typography.Text strong>Convite enviado</Typography.Text>,
    message: 'Se preferir, copie o link abaixo e envie manualmente',
    alignItems: 'start',
    customActions: (
      <Button
        size="small"
        color="secondary"
        sx={snackbarAction}
        onClick={copySignUpUrl}
      >
        Copiar link do convite
      </Button>
    ),
  });
}
