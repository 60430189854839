import type { SxProps, Theme } from '@mui/material';
import { buttonClasses } from '@mui/material';

export const button: SxProps<Theme> = {
  pl: {
    xs: 4,
    lg: 3,
  },

  [`& .${buttonClasses.startIcon}`]: {
    mr: {
      xs: 0,
      lg: 2,
    },
  },
};

export const typography: SxProps<Theme> = {
  display: {
    xs: 'none',
    lg: 'flex',
  },
};
