import { DisconnectOutlined, LinkOutlined } from '@ant-design/icons';

import { DrawerHeaderButton } from 'src/ui';

import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import analytics from 'src/libs/analytics';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

type Props = {
  linkedEntry?: ScheduledStatementEntry;
  onUnConciliate: () => void;
  onConciliate: () => void;
};

export default function ConciliateHeaderButton({
  linkedEntry,
  onConciliate,
  onUnConciliate,
}: Props) {
  const { companyId } = useCompanyParams();

  function handleClick() {
    analytics.track('Entry Drawer Header Conciliation Button Clicked', {
      company_id: companyId,
    });

    if (linkedEntry) return onUnConciliate();

    return onConciliate();
  }

  if (linkedEntry) {
    return (
      <DrawerHeaderButton
        onClick={handleClick}
        icon={<DisconnectOutlined />}
        text="Desfazer vinculação"
      />
    );
  }

  return (
    <DrawerHeaderButton
      onClick={handleClick}
      icon={<LinkOutlined />}
      text="Vincular a lançamento existente"
    />
  );
}
