import type { InputProps } from 'antd';

import { MaskedInput, MaskedSearch } from 'src/ui';

import { cnpjMask, cpfMask } from 'src/libs/finbits/Documents';

type Props = InputProps & {
  type: 'legal' | 'natural';
  onSearch?: () => void;
  loading?: boolean;
};

export default function DocumentInput({
  type,
  onSearch,
  loading,
  ...props
}: Props) {
  if (type === 'legal') {
    return (
      <MaskedSearch
        mask={[cnpjMask]}
        size="large"
        onSearch={onSearch}
        loading={loading}
        {...props}
      />
    );
  }

  return <MaskedInput mask={[cpfMask]} size="large" {...props} />;
}
