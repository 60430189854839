import { LicenseInfo } from '@mui/x-license';
import { StyledEngineProvider } from '@mui/material/styles';
import { QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { LDProvider } from 'launchdarkly-react-client-sdk';

import { globalStyledComponentsSnackbar } from 'src/mui';

import { queryClient } from 'src/libs/react-query';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import { ThemeProvider } from 'src/features/ThemeProvider';
import SentryErrorBoundary from 'src/features/sentry/SentryErrorBoundary';
import SentryUserSetter from 'src/features/sentry/SentryUserSetter';

import Router from './router';

LicenseInfo.setLicenseKey(import.meta.env.VITE_FINBITS_MUI_KEY!);

const clientSideID = import.meta.env.VITE_LAUNCHDARKLY_KEY!;

export default function App() {
  return (
    <ThemeProvider>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider Components={globalStyledComponentsSnackbar}>
          <SentryErrorBoundary>
            <LDProvider
              clientSideID={clientSideID}
              options={{
                flushInterval: FIVE_MINUTES_IN_MS,
                streaming: true,
                bootstrap: 'localStorage',
              }}
            >
              <QueryClientProvider client={queryClient}>
                <SentryUserSetter>
                  <Router />
                </SentryUserSetter>
              </QueryClientProvider>
            </LDProvider>
          </SentryErrorBoundary>
        </SnackbarProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
