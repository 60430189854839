import { useParams } from 'react-router';

import type { UserWithPermissions } from 'src/libs/finbits/Organization/Users/types';

export function useHasAccessToCurrentContext(user?: UserWithPermissions) {
  const { organizationId, companyId } = useParams();

  if (!user || (!organizationId && !companyId)) {
    return true;
  }

  if (!companyId) return user?.organizationId === organizationId;

  return (
    user?.organizationId === organizationId &&
    user?.companies?.find((company) => company.id === companyId)
  );
}
