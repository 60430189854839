import type { ReactNode } from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

import { ErrorMessage } from 'src/ui';

if (
  import.meta.env.VITE_SENTRY_DSN &&
  import.meta.env.VITE_ENVIRONMENT &&
  import.meta.env.VITE_FINBITS_VERSION
) {
  try {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_ENVIRONMENT,
      release: `plutus@${import.meta.env.VITE_FINBITS_VERSION}`,
      integrations: [
        new Integrations.BrowserTracing(),
        new CaptureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      tracesSampleRate: 0.2,
      ignoreErrors: [/Request failed with status code 4\d\d/i],
    });
  } catch {}
}

type Props = {
  children: ReactNode;
};

export default function SentryErrorBoundary({ children }: Props) {
  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorMessage message="Ops, ocorreu um erro inesperado." />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
