import type { ReactNode } from 'react';

import type { PopperProps } from '@mui/material';
import { Fade, Popper as MUIPopper, Paper } from '@mui/material';

import { paper } from './Popper.sx';

type Props = {
  children: ReactNode;
} & Omit<PopperProps, 'children'>;

export default function Popper({ children, ...rest }: Props) {
  return (
    <MUIPopper {...rest} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper sx={paper} elevation={8}>
            {children}
          </Paper>
        </Fade>
      )}
    </MUIPopper>
  );
}
