import { AlertCircleIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useActivateOrganizationUser } from 'src/libs/finbits/Organization/Users';
import type { User } from 'src/libs/finbits/Organization/Users/types';

type Props = {
  user: User;
};

export default function useActivateModal({ user }: Props) {
  const { organizationId } = useCompanyParams();
  const openConfirmDialog = useOpenConfirmDialog();

  const { activateOrganizationUser } = useActivateOrganizationUser();

  function handleActivateUser() {
    activateOrganizationUser(
      {
        organizationId,
        userId: user.id,
      },
      {
        onSuccess: () => {
          snackbar({ variant: 'success', message: 'Usuário reativado.' });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Ocorreu um erro ao reativar o usuário. Tente novamente em instantes.',
          });
        },
      }
    );
  }

  function openActivateConfirmation() {
    openConfirmDialog({
      variant: 'error',
      icon: <AlertCircleIcon />,
      title: 'Deseja mesmo reativar esta conta?',
      content: `${user.name} terá o acesso à Finbits restaurado`,
      confirmText: 'Sim, quero reativar',
      cancelText: 'Cancelar',
      onConfirm: handleActivateUser,
    });
  }

  return {
    openActivateConfirmation,
  };
}
