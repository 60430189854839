import { Form, Typography } from 'antd';
import { CalculatorOutlined, CheckOutlined } from '@ant-design/icons';

import { toCurrency } from 'src/libs/finbits/Money';

import type { FormValues } from 'src/features/EntryForm/types';

import type { AmountInfo, AmountStatus } from './types';
import styles from './AmountCalculationResult.module.less';

type Props = {
  totalAmount: number;
};

function sumAmount(formInstances?: Array<FormValues>): number {
  if (!formInstances) return 0;

  return formInstances
    .map((form: FormValues) => form?.amount ?? 0)
    .reduce((acc: number, amount: number) => acc + amount, 0);
}

function getAmountStatus(
  totalAmount: number,
  curentAmount: number
): AmountStatus {
  const isMissingAmount = totalAmount - curentAmount > 0;
  const isExceedingAmount = totalAmount - curentAmount < 0;

  if (isMissingAmount) {
    return 'warning';
  }

  if (isExceedingAmount) {
    return 'danger';
  }

  return 'success';
}

export function buildAmountInfo(
  totalAmount: number,
  updateValues: FormValues[],
  createValues: FormValues[]
): AmountInfo {
  const currentAmount = sumAmount(updateValues) + sumAmount(createValues);

  const statusAmount = getAmountStatus(totalAmount, currentAmount);

  return {
    currentAmount,
    totalAmount,
    statusAmount,
  };
}

export default function AmountCalculationResult({ totalAmount }: Props) {
  const form = Form.useFormInstance();

  const updateValues = Form.useWatch('update', form) ?? [];
  const createValues = Form.useWatch('create', form) ?? [];

  const { currentAmount, statusAmount } = buildAmountInfo(
    totalAmount,
    updateValues,
    createValues
  );

  const total =
    statusAmount === 'success' ? currentAmount : totalAmount - currentAmount;

  return (
    <div className={styles.container}>
      <CalculatorOutlined className={styles.colorWhite} />
      <Typography.Text type={statusAmount}>
        {statusAmount === 'warning' && (
          <span className={styles.colorWhite}>Diferença: </span>
        )}
        {statusAmount === 'danger' && (
          <span className={styles.colorWhite}>Remova: </span>
        )}
        {toCurrency(total)}
      </Typography.Text>
      {statusAmount === 'success' && (
        <CheckOutlined className={styles.colorWhite} />
      )}
    </div>
  );
}
