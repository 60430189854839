import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function MessageBirdIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="message-question-square"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M 1 7.188 L 10.35 7.188 C 11.262 7.188 12 6.572 12 5.813 L 1 5.813 L 1 3.75 C 1 2.231 2.231 1 3.75 1 L 20.25 1 C 21.77 1 23 2.231 23 3.75 L 23 15.438 C 23 16.957 21.77 18.188 20.25 18.188 L 16.813 18.188 L 16.813 21.132 C 16.813 22.64 15.059 23.525 13.785 22.659 L 7.188 18.188 L 3.75 18.188 C 2.231 18.188 1 16.957 1 15.438 L 1 13.375 L 7.702 13.375 C 8.557 13.375 9.25 12.759 9.25 12 L 1 12 L 1 7.188 Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
