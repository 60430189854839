import { useMemo } from 'react';

import type { SelectProps } from 'antd';
import { Select } from 'antd';
import sortBy from 'lodash/sortBy';

import { SelectAddButton } from 'src/ui';

import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import { removeAccentuation } from 'src/libs/finbits/Accentuation';
import { useCompanyContacts } from 'src/libs/finbits/Organization/Companies/Contacts';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import { contactSelectFilter } from './contactSelectFilter';

export type ContactSelectProps = {
  companyId?: string;
  contactType?: ContactType;
  organizationId?: string;
  excludeInternationalContacts?: boolean;
  onOpenCreateContact: () => void;
} & SelectProps<string>;

export default function ContactSelect({
  companyId,
  organizationId,
  defaultValue,
  disabled,
  value,
  contactType,
  excludeInternationalContacts,
  onOpenCreateContact,
  ...rest
}: ContactSelectProps) {
  const { companyContacts: contacts, isLoading } = useCompanyContacts(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
      select: (contacts) => {
        const filteredContactsByType = contacts.filter((contact) =>
          contactType ? contact?.type === contactType : true
        );

        return filteredContactsByType.filter((contact) =>
          excludeInternationalContacts ? !contact.isInternational : true
        );
      },
    }
  );

  const filteredContacts = useMemo<Contact[]>(() => {
    if (isLoading || !contacts) {
      return [];
    }

    const contactsSortedByNickname = sortBy(contacts, (contact: Contact) =>
      removeAccentuation(contact.nickname).toLowerCase()
    );

    return contactsSortedByNickname.filter(
      ({ id, active }) => active || value === id
    );
  }, [contacts, isLoading, value]);

  const hasPermission = useAllowedPermission({
    action: 'create',
    resource: 'contacts',
  });

  return (
    <Select
      allowClear
      showSearch
      placeholder="Digite o nome do contato"
      size="large"
      filterOption={contactSelectFilter}
      loading={isLoading}
      disabled={disabled}
      value={value}
      dropdownRender={(menu) => (
        <SelectAddButton
          showButton={hasPermission}
          label="+ Adicionar Contato"
          onClick={onOpenCreateContact}
        >
          {menu}
        </SelectAddButton>
      )}
      getPopupContainer={(trigger) => trigger.parentElement}
      {...rest}
    >
      {filteredContacts.map((contact) => (
        <Select.Option
          value={contact.id}
          key={contact.id}
          data-name={contact.name}
          data-nickname={contact.nickname}
          data-document={contact.document}
        >
          {contact.nickname}
        </Select.Option>
      ))}
    </Select>
  );
}
