import type { Key } from 'react';
import { useMemo, useState } from 'react';

import { Button, Input, Tree, Typography } from 'antd';
import type { FilterDropdownProps } from 'antd/lib/table/interface';

import styles from './FilterTree.module.less';

const SELECT_ALL_KEY = 'select_all';

type Props = Pick<
  FilterDropdownProps,
  'filters' | 'selectedKeys' | 'clearFilters' | 'confirm' | 'setSelectedKeys'
>;

export default function FilterTree({
  filters = [],
  selectedKeys,
  clearFilters,
  confirm,
  setSelectedKeys,
}: Props) {
  const [searchValue, setSearchValue] = useState('');

  const treeDataChildren = filters.map((filter) => ({
    key: filter.value.toString(),
    title: String(filter.text),
  }));

  const filteredData = useMemo(() => {
    return treeDataChildren.filter(({ title }) =>
      title?.toString().toLowerCase().includes(searchValue.toLocaleLowerCase())
    );
  }, [searchValue, treeDataChildren]);

  const filteredKeys = filteredData.map(({ key }) => key);
  const isSelectedAll = selectedKeys.length === filteredKeys.length;

  function handleSelect(newSelectedKeys: Key[], infoKey: string) {
    const newKeysWithoutParent = newSelectedKeys.filter(
      (key) => key !== SELECT_ALL_KEY
    );

    if (infoKey === SELECT_ALL_KEY) {
      return setSelectedKeys(isSelectedAll ? [] : filteredKeys);
    }

    return setSelectedKeys(newKeysWithoutParent);
  }

  function handleConfirm() {
    setSearchValue('');
    confirm();
  }

  function handleClearFilters() {
    setSearchValue('');
    clearFilters && clearFilters();
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Input
          size="middle"
          value={searchValue}
          placeholder="Pesquisar"
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          aria-label="Pesquisar"
        />
        <Tree
          className={styles.tree}
          checkable
          selectable
          multiple
          checkedKeys={isSelectedAll ? [SELECT_ALL_KEY] : selectedKeys}
          selectedKeys={selectedKeys}
          onSelect={(selectedKeys, info) =>
            handleSelect(selectedKeys, info.node.key)
          }
          onCheck={(checked, info) =>
            handleSelect(checked as Key[], info.node.key)
          }
          expandedKeys={[SELECT_ALL_KEY]}
          treeData={[
            {
              key: SELECT_ALL_KEY,
              title: 'Selecionar Todos',
              children: filteredData,
            },
          ]}
          titleRender={({ title }) => {
            return (
              <div className={styles.title}>
                <Typography.Text ellipsis={{ tooltip: title }}>
                  {title}
                </Typography.Text>
              </div>
            );
          }}
        />
      </div>
      <div className={styles.actions}>
        <Button
          type="link"
          size="small"
          disabled={!Boolean(selectedKeys.length)}
          onClick={handleClearFilters}
        >
          Limpar
        </Button>
        <Button type="primary" size="small" onClick={handleConfirm}>
          Aplicar
        </Button>
      </div>
    </>
  );
}
