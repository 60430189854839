import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import type { User } from 'src/libs/finbits/Organization/Users/types';
import { UserDecoder } from 'src/libs/finbits/Organization/Users/types';

import { invalidateUsersQueries } from '../Users';

import type {
  GetInvitationParams,
  Invitation,
  PatchInvitationParams,
  PostAcceptInvitationParams,
  PostInvitationParams,
} from './types';
import { InvitationDecoder } from './types';

function invalidateInvitationQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['invitations']);
}

async function postInvitationUser({
  organizationId,
  ...params
}: PostInvitationParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/invitations`,
    params
  );

  return decodeResponse<User>(response, UserDecoder);
}

async function patchInvitationUser({
  organizationId,
  userId,
}: PatchInvitationParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/users/${userId}/invitations`
  );

  return decodeResponse<User>(response, UserDecoder);
}

async function getInvitation({
  invitationCode,
  organizationId,
}: GetInvitationParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/invitations/${invitationCode}`
  );

  return decodeResponse<Invitation>(response, InvitationDecoder);
}

async function postAcceptInvitation({
  organizationId,
  invitationCode,
  ...params
}: PostAcceptInvitationParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/invitations/${invitationCode}`,
    params
  );

  return decodeResponse<User>(response, UserDecoder);
}

export function useCreateInvitation() {
  const queryClient = useQueryClient();

  const { mutate: createInvitation, ...rest } = useMutation<
    User,
    ApiError,
    PostInvitationParams
  >(postInvitationUser, {
    onSuccess: () => {
      invalidateUsersQueries(queryClient);
      invalidateInvitationQueries(queryClient);
    },
  });

  return { createInvitation, ...rest };
}

export function useResendInvitation() {
  const queryClient = useQueryClient();

  const { mutate: resendInvitation, ...rest } = useMutation<
    User,
    ApiError,
    PatchInvitationParams
  >(patchInvitationUser, {
    onSuccess: () => {
      invalidateUsersQueries(queryClient);
      invalidateInvitationQueries(queryClient);
    },
  });

  return { resendInvitation, ...rest };
}

export function useInvitation(
  { invitationCode, organizationId }: GetInvitationParams,
  queryConfigCustom: Partial<UseQueryOptions<Invitation, ApiError>> = {}
) {
  const { data, ...rest } = useQuery<Invitation, ApiError>({
    queryKey: ['invitations', { organizationId, invitationCode }],
    enabled: Boolean(invitationCode) && Boolean(organizationId),
    queryFn: () =>
      getInvitation({
        invitationCode,
        organizationId,
      }),
    retry: false,
    ...queryConfigCustom,
  });

  return {
    invitation: data,
    ...rest,
  };
}

export function useAcceptInvitation() {
  const queryClient = useQueryClient();

  const { mutate: acceptInvitation, ...rest } = useMutation<
    User,
    ApiError<ApiErrorForm>,
    PostAcceptInvitationParams
  >(postAcceptInvitation, {
    onSuccess: () => {
      invalidateUsersQueries(queryClient);
    },
  });

  return { acceptInvitation, ...rest };
}
