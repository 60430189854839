import { TabList } from '@mui/lab';
import { Tab } from '@mui/material';

export enum NotaFiscalSetupTab {
  ADDITIONAL_DATA = 'additional_data',
  SERVICES = 'services',
  DIGITAL_CERTIFICATE = 'digital_certificate',
}

type Props = {
  onChange: (newSelectedTab: NotaFiscalSetupTab) => void;
};

function a11yProps(index: number) {
  return {
    id: `nota-fiscal-tabs-${index}`,
    'aria-controls': `nota-fiscal-tabpanel-${index}`,
  };
}

export default function NotaFiscalSetupTabs({ onChange }: Props) {
  return (
    <TabList
      textColor="secondary"
      indicatorColor="secondary"
      onChange={(_, value) => onChange(value)}
      centered
    >
      <Tab
        label="Dados Complementares"
        value={NotaFiscalSetupTab.ADDITIONAL_DATA}
        {...a11yProps(0)}
      />
      <Tab
        label="Serviços"
        value={NotaFiscalSetupTab.SERVICES}
        {...a11yProps(1)}
      />
      <Tab
        label="Certificado Digital"
        value={NotaFiscalSetupTab.DIGITAL_CERTIFICATE}
        {...a11yProps(3)}
      />
    </TabList>
  );
}
