import { useCallback, useMemo, useState } from 'react';

import { MergeCellsOutlined } from '@ant-design/icons';
import compact from 'lodash/compact';
import { TrashIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import {
  Drawer,
  DrawerHeader,
  DrawerHeaderButton,
  Loader,
  PaymentIcon,
  Title,
  useOpenConfirmDialog,
} from 'src/ui';

import { entryText } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import {
  useGetSplits,
  useUndoSplits,
} from 'src/libs/finbits/Management/FinancialEntries/Splits';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { parseISO } from 'src/libs/finbits/Date';
import type { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import { financialEntryToFormValues } from 'src/features/EntryForm/formValues';
import type { OriginValues } from 'src/features/entries/Drawers/SplitForm/types';
import SplitForm from 'src/features/entries/Drawers/SplitForm';
import { useOpenConfirmLeaveFormDialog } from 'src/features/entries/Dialogs';

type Props = {
  originValues?: Required<OriginValues>;
  transactionId: string;
  type: BalanceType;
  onClose: () => void;
} & PortalProps;

export default function EditSplitsDrawer({
  originValues,
  transactionId,
  type,
  onClose,
  open = true,
  onExit,
}: Props) {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const openConfirmLeaveDialog = useOpenConfirmLeaveFormDialog();
  const openConfirmDialog = useOpenConfirmDialog();
  const { organizationId, companyId } = useCompanyParams();
  const { splits, isLoading, isFetchedAfterMount } = useGetSplits({
    organizationId,
    companyId,
    transactionId,
  });

  const { undoSplits, isLoading: isSaving } = useUndoSplits();

  const canEditFinancialEntrySplit = useAllowedPermission({
    action: 'update',
    resource: 'financialEntrySplit',
  });

  const transactionValues = useMemo(() => {
    if (splits.length > 0) {
      return {
        originDescription: splits[0].originDescription,
        accountId: splits[0].accountId,
        date: parseISO(splits[0].date),
        amount: splits.reduce((acc, split) => acc + split.amount, 0),
      };
    }

    return originValues;
  }, [splits, originValues]);

  const onFieldsChange = useCallback(() => {
    setIsFormDirty(true);
  }, []);

  function handleCloseDrawer() {
    if (isFormDirty) {
      return openConfirmLeaveDialog({
        onConfirm: onClose,
      });
    }

    return onClose();
  }

  function performUndoSplits() {
    undoSplits(
      { organizationId, companyId, transactionId },
      {
        onSuccess: () => {
          onClose();

          snackbar({
            variant: 'success',
            message: 'Alterações salvas com sucesso!',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar as alterações!',
          });
        },
      }
    );
  }

  function handleUndoSlice() {
    return openConfirmDialog({
      variant: 'error',
      icon: <TrashIcon />,
      title: 'Tem certeza que deseja desfazer a quebra?',
      content:
        'Conciliações serão desfeitas e outras informações serão excluídas permanentemente.',
      confirmText: 'Sim, quero desfazer',
      onConfirm: performUndoSplits,
      cancelText: 'Cancelar',
    });
  }

  const initialSplits = useMemo(
    () => splits?.map(financialEntryToFormValues),
    [splits]
  );

  const scheduledIdsConciliated = compact(
    splits.map((split) => split.scheduledEntry?.id)
  );

  return (
    <>
      <Drawer
        title={
          <DrawerHeader
            title={
              <Title icon={<PaymentIcon />}>
                {entryText({ type, objectType: 'financial_entry' })}
              </Title>
            }
            extra={
              canEditFinancialEntrySplit && (
                <DrawerHeaderButton
                  icon={<MergeCellsOutlined />}
                  title="Desfazer quebra"
                  aria-label="Desfazer quebra"
                  onClick={handleUndoSlice}
                />
              )
            }
          />
        }
        footer={null}
        onClose={handleCloseDrawer}
        visible={open}
        afterVisibleChange={onExit}
      >
        {isLoading || isSaving || !isFetchedAfterMount || !transactionValues ? (
          <Loader size="small" forceCentered />
        ) : (
          <SplitForm
            companyId={companyId}
            organizationId={organizationId}
            initialValues={{
              update: initialSplits,
              create: [],
              delete: [],
              amount: transactionValues.amount,
              accountId: transactionValues.accountId,
              originDescription: transactionValues.originDescription,
              date: transactionValues.date,
            }}
            transactionId={transactionId}
            type={type}
            onFieldsChange={onFieldsChange}
            onClose={onClose}
            disabled={!canEditFinancialEntrySplit}
            scheduledEntryIdsConciliated={scheduledIdsConciliated}
          />
        )}
      </Drawer>
    </>
  );
}
