import { useMemo, useState } from 'react';

import type { ButtonProps } from 'antd';
import { Button, Form } from 'antd';

import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import type { NotaFiscal } from 'src/libs/finbits/NotaFiscal/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import analytics from 'src/libs/analytics';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import CreateNotaFiscalDrawer from 'src/features/nota-fiscal/CreateNotaFiscalDrawer';

type Props = {
  isFinancialEntry?: boolean;
} & ButtonProps;

export default function CreateNotaFiscalFromReceivableButton({
  isFinancialEntry = false,
  ...rest
}: Props) {
  const form = Form.useFormInstance();
  const { companyId } = useCompanyParams();

  const id = Form.useWatch('id');
  const amount = Form.useWatch('amount');
  const contactId = Form.useWatch('contactId');
  const notaFiscalId = Form.useWatch('notaFiscalId');

  const { status: connectionStatus } = useNotaFiscalConnection();

  const [isCreateNotaFiscalDrawerVisible, setIsCreateNotaFiscalDrawerVisible] =
    useState(false);

  const isConnectionActive = connectionStatus === 'active';

  const initialValues = useMemo(() => {
    return {
      amount,
      contactId,
      [isFinancialEntry ? 'financialEntryId' : 'receivableId']: id,
    };
  }, [id, amount, contactId, isFinancialEntry]);

  function handleCreateNotaFiscal(notaFiscal: NotaFiscal) {
    form.setFieldValue('notaFiscalId', notaFiscal.id);

    setIsCreateNotaFiscalDrawerVisible(false);
  }

  function handleIssueInvoice() {
    analytics.track('Entry Drawer Issue Invoice Button Clicked', {
      company_id: companyId,
    });

    setIsCreateNotaFiscalDrawerVisible(true);
  }

  if (!isConnectionActive || !!notaFiscalId) {
    return null;
  }

  return (
    <>
      {isCreateNotaFiscalDrawerVisible && (
        <CreateNotaFiscalDrawer
          initialValues={initialValues}
          onCreate={handleCreateNotaFiscal}
          onClose={() => setIsCreateNotaFiscalDrawerVisible(false)}
        />
      )}

      <WithAllowedPermission
        permissions={{
          resource: 'notasFiscais',
          action: 'create',
        }}
      >
        <Button size="large" onClick={handleIssueInvoice} {...rest}>
          Emitir NF
        </Button>
      </WithAllowedPermission>
    </>
  );
}
