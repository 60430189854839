import { Box } from '@mui/material';

import noPreviewImg from 'src/assets/no-preview.svg';
import pdfPreviewImg from 'src/assets/pdf-preview.svg';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import { isImageType, isPDFType } from 'src/libs/finbits/File';

import PreviewIMG from 'src/features/preview/PreviewIMG';

import styles from './Preview.module.less';

type Props = { attachment: Attachment };

export default function Preview({ attachment }: Props) {
  const isPdf = isPDFType(attachment);
  const isImage = isImageType(attachment);

  if (isImage) {
    return (
      <Box sx={{ px: 4, pt: 4 }}>
        <PreviewIMG attachment={attachment} className={styles.previewImg} />
      </Box>
    );
  }

  return (
    <Box
      component="img"
      src={isPdf ? pdfPreviewImg : noPreviewImg}
      alt={isPdf ? attachment.name : 'Preview não suportado'}
      sx={{ mx: 'auto', pt: 6, px: 4, pb: 2 }}
    />
  );
}
