import {
  ProfileOutlined,
  ThunderboltOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import type { RuleObject } from 'rc-field-form/lib/interface';
import type { Location } from 'history';

import { snackbar } from 'src/mui';

import { Stepper, useStepper } from 'src/ui';

import type { ImportRedirectParams } from 'src/libs/finbits/Bank/Statements/types';
import {
  AccountType,
  SynchronizeType,
} from 'src/libs/finbits/Bank/Accounts/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { parseISO, subDays } from 'src/libs/finbits/Date';

import CreateAccountForm from 'src/features/DEPRECATED_accounts/CreateAccountForm';

import SuccessStep from '../SuccessStep';
import UploadStep from '../UploadStep';

import useUploadOfx from './useUploadOfx';

type Props = {
  organizationId?: string;
  companyId?: string;
  onClose: () => void;
  redirectPathAfterImport: (params: ImportRedirectParams) => Partial<Location>;
};

function ImportOfx({
  organizationId,
  companyId,
  onClose,
  redirectPathAfterImport,
}: Props) {
  const store = useStepper();
  const { goToStep, goNextStep } = store;

  function onAcceptAccountCreation() {
    goNextStep();
  }

  function onSuccess() {
    goToStep(3);
  }

  const {
    isUploading,
    uploadFile,
    accountNumber,
    branchNumber,
    routingNumber,
    firstDailyBalanceDate,
    currentFile,
    importedStatement,
    hasNewTransactions,
  } = useUploadOfx({
    organizationId,
    companyId,
    onAcceptAccountCreation,
    onSuccess,
  });

  function onAccountCreateError() {
    snackbar({
      variant: 'error',
      message:
        'Ops! Falha na criação da conta. Não foi possível importar o arquivo OFX',
    });
    onClose();
  }

  function onAccountCreateSuccess() {
    uploadFile({ file: currentFile! });
  }

  const dayBeforeFirstDailyBalanceDate = firstDailyBalanceDate
    ? subDays(parseISO(firstDailyBalanceDate), 1).toISOString()
    : null;

  return (
    <Stepper
      store={store}
      title="Importar extrato OFX"
      icon={<UploadOutlined />}
    >
      <Stepper.Step showContinue={false}>
        <UploadStep uploadFile={uploadFile} isUploading={isUploading} />
      </Stepper.Step>
      <Stepper.Step title="Nova conta" showContinue={false}>
        <CreateAccountForm
          initialValues={{
            synchronizeType: SynchronizeType.MANUAL,
            accountNumber,
            branchNumber,
            name: '',
            firstDailyBalanceDate,
            type: AccountType.CHECKING_ACCOUNT,
            initialBalance: {
              date: dayBeforeFirstDailyBalanceDate,
              balance: null,
              balanceType: BalanceType.CREDIT,
            },
          }}
          customValidators={{
            accountNumber: (_rule: RuleObject, value: string) => {
              const accountId = `${branchNumber}${accountNumber}`;

              if (accountId.endsWith(value.replace(/\W/g, ''))) {
                return Promise.resolve();
              }

              return Promise.reject(
                'O número da conta não consta no arquivo OFX'
              );
            },
          }}
          routingNumber={routingNumber}
          onError={onAccountCreateError}
          onSuccess={onAccountCreateSuccess}
          companyId={companyId}
          organizationId={organizationId}
          submitButtonTitle="Concluir importação"
          isLoading={isUploading}
        />
      </Stepper.Step>
      <Stepper.Step
        showGoBack={false}
        title={
          hasNewTransactions ? 'Importação concluída' : 'Extrato identificado'
        }
        icon={
          hasNewTransactions ? <ThunderboltOutlined /> : <ProfileOutlined />
        }
      >
        <SuccessStep
          importedStatement={importedStatement!}
          closeModal={onClose}
          redirectPathAfterImport={redirectPathAfterImport}
        />
      </Stepper.Step>
    </Stepper>
  );
}

export default ImportOfx;
