import type { ReactChild } from 'react';

export function getNodeText(node: ReactChild): string {
  if (['string', 'number'].includes(typeof node)) {
    return node.toString();
  }

  if (node instanceof Array) {
    return node.map(getNodeText).join('');
  }

  if (typeof node === 'object' && node) {
    return getNodeText(node.props.children);
  }

  return '';
}
