import { Input } from 'antd';
import type { SearchProps } from 'antd/lib/input';

import { MaskedInput, MaskedSearch } from 'src/ui';

import { cnpjMask, cpfMask } from 'src/libs/finbits/Documents';
import { cellphoneMask } from 'src/libs/finbits/Organization/PhoneNumber';
import type { PixType } from 'src/libs/finbits/Bank/Pix/types';

type Props = SearchProps & {
  pixType?: PixType;
  searchEnabled?: boolean;
};

export default function PixKeyInput({
  pixType,
  searchEnabled = false,
  onSearch,
  loading,
  ...props
}: Props) {
  const MaskedField = searchEnabled ? MaskedSearch : MaskedInput;
  const Field = searchEnabled ? Input.Search : Input;

  const fieldProps = searchEnabled ? { loading, onSearch, ...props } : props;

  if (pixType === 'cpf') {
    return (
      <MaskedField
        mask={[cpfMask]}
        size="large"
        placeholder="Insira o CPF"
        {...fieldProps}
      />
    );
  }

  if (pixType === 'cnpj') {
    return (
      <MaskedField
        mask={[cnpjMask]}
        size="large"
        placeholder="Insira o CNPJ"
        {...fieldProps}
      />
    );
  }

  if (pixType === 'cellphone_number') {
    return (
      <MaskedField
        mask={[cellphoneMask]}
        size="large"
        placeholder="Insira o Celular"
        {...fieldProps}
      />
    );
  }

  if (pixType === 'email') {
    return (
      <Field
        size="large"
        type="email"
        placeholder="Insira o e-mail"
        {...fieldProps}
      />
    );
  }
  if (pixType === 'random') {
    return (
      <Field
        size="large"
        placeholder="Insira a Chave Aleatória"
        {...fieldProps}
      />
    );
  }

  return <Field size="large" placeholder="Chave PIX" {...props} disabled />;
}
