import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import cn from 'classnames';
import { Checkbox, Typography } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Image } from 'src/ui';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

import PreviewAttachment from 'src/features/preview/PreviewAttachment';

import styles from './AttachmentCheckbox.module.less';

type Props = {
  checked: boolean;
  attachment: Attachment;
  onChange: (checked: boolean) => void;
};

export default function AttachmentCheckbox({
  checked,
  attachment,
  onChange,
}: Props) {
  const [showPreview, setShowPreview] = useState(false);

  function handleCheck(event: SyntheticEvent | CheckboxChangeEvent) {
    event.stopPropagation();
    return onChange(!checked);
  }

  const isImage = attachment.type.includes('image');
  const isFile = !isImage;

  return (
    <>
      {showPreview && (
        <PreviewAttachment
          attachments={[attachment]}
          onClose={() => setShowPreview(false)}
        />
      )}

      <div
        className={cn([
          styles.checkboxContainer,
          isFile && styles.fileCheckbox,
          checked && styles.checked,
        ])}
        onClick={() => setShowPreview(true)}
      >
        {isImage ? (
          <Image src={attachment.url ?? ''} alt={attachment.name} />
        ) : (
          <>
            <FileOutlined className={styles.fileIcon} />
            <div className={styles.fileName}>
              <Typography.Text ellipsis>{attachment.name}</Typography.Text>
            </div>
          </>
        )}

        <div className={styles.checkboxArea} onClick={handleCheck}>
          <Checkbox
            className={styles.checkbox}
            onChange={handleCheck}
            checked={checked}
          />
        </div>
      </div>
    </>
  );
}
