import type { KeyboardEvent } from 'react';

import lowerCase from 'lodash/lowerCase';
import deburr from 'lodash/deburr';
import { Button, Input } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/lib/table/interface';

import styles from './filters.module.less';

function DescriptionFilter<
  T extends {
    description?: string;
    entry?: {
      description?: string;
    };
  }
>(): ColumnType<T> {
  function handleEnter(
    e: KeyboardEvent<HTMLDivElement>,
    confirm: (param?: FilterConfirmProps) => void
  ) {
    if (e.code === 'Enter') {
      e.stopPropagation();
      confirm();
    }
  }
  return {
    filterDropdown: ({
      setSelectedKeys,
      confirm,
      selectedKeys,
      clearFilters,
    }) => (
      <div className={styles.wrapper}>
        <Input
          value={selectedKeys[0]}
          placeholder="Pesquisar"
          onChange={(e) => {
            setSelectedKeys([e.target.value]);
          }}
          onKeyDown={(e) => handleEnter(e, confirm)}
          autoFocus
        />

        <div className={styles.actions}>
          <Button
            type="link"
            size="small"
            disabled={!Boolean(selectedKeys.length)}
            onClick={() => {
              clearFilters && clearFilters();
            }}
          >
            Limpar
          </Button>
          <Button type="primary" size="small" onClick={() => confirm()}>
            Aplicar
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => {
      const description = record?.entry?.description
        ? record.entry.description
        : record.description;
      const parsedValue = lowerCase(deburr(value.toString()));
      const parsedText = lowerCase(deburr(description));

      return parsedText.includes(parsedValue);
    },
  };
}

export default DescriptionFilter;
