import { useMemo } from 'react';

import { Empty, Table, Tooltip, Typography } from 'antd';
import { AlertOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';
import xorBy from 'lodash/xorBy';

import { differenceInDays, format, parseISO } from 'src/libs/finbits/Date';
import { toCurrency, toDecimal } from 'src/libs/finbits/Money';
import type { ConciliationSuggestion } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions/types';
import { pluralize } from 'src/libs/finbits/Pluralize';
import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { translateCurrentSituation } from 'src/libs/finbits/Management/Entries/currentSituationTranslations';

import {
  AccountFilter,
  AmountFilter,
  ContactFilter,
  CurrentSituationFilter,
  DateFilter,
  DescriptionFilter,
} from 'src/features/filters';
import { amountSorter } from 'src/features/sorters';
import { AccountInfo } from 'src/features/entries/Columns';
import CurrentSituationTag from 'src/features/entries/CurrentSituationTag';

import styles from './ConciliationTable.module.less';

type Props = {
  multiple?: boolean;
  selectedEntries?: ScheduledStatementEntry[];
  onSelect: (scheduledEntries: ScheduledStatementEntry[]) => void;
  loading: boolean;
  financialEntryId?: string;
  rows: ScheduledStatementEntry[];
  suggestions?: ConciliationSuggestion[];
  isLoadingSuggestions?: boolean;
  type: BalanceType;
};

export default function ConciliationTable({
  multiple = false,
  loading,
  rows,
  financialEntryId,
  onSelect,
  selectedEntries = [],
  suggestions = [],
  isLoadingSuggestions = false,
  type,
}: Props) {
  const accounts = useMemo(() => {
    return uniqBy(compact(rows.map(({ account }) => account)), 'id');
  }, [rows]);

  const suggestion = useMemo(() => {
    return suggestions.find(
      ({ financialEntry }) => financialEntry.id === financialEntryId
    )?.scheduledEntry.id;
  }, [suggestions, financialEntryId]);

  const contacts = useMemo(() => {
    const rowsWithContact = rows.filter(({ contact }) => Boolean(contact));

    return compact(
      uniqBy(
        rowsWithContact.map(({ contact }) => contact),
        'id'
      )
    );
  }, [rows]);

  const totalAmount = selectedEntries.reduce((acc, s) => acc + s.amount, 0);

  function handleSelect(scheduledEntry: ScheduledStatementEntry) {
    if (multiple) {
      return onSelect(xorBy(selectedEntries, [scheduledEntry]));
    }

    return onSelect([scheduledEntry]);
  }

  const formattedTotalAmount = toCurrency(totalAmount, type);

  const columns: ColumnsType<ScheduledStatementEntry> = [
    {
      key: 'date',
      title: 'Data',
      width: 96,
      ...DateFilter<ScheduledStatementEntry>('date'),
      render: ({ date }: ScheduledStatementEntry) => format(date, 'dd/MM/yy'),
      sorter: (a: ScheduledStatementEntry, b: ScheduledStatementEntry) =>
        differenceInDays(parseISO(a.date), parseISO(b.date)),
    },
    {
      key: 'suggestion',
      className: styles.suggestionColumn,
      width: 16,
      render: ({ id }: ScheduledStatementEntry) =>
        id === suggestion && (
          <Tooltip title="Conciliação Sugerida">
            <AlertOutlined />
          </Tooltip>
        ),
    },
    {
      key: 'description',
      title: 'Descrição',
      ...DescriptionFilter<ScheduledStatementEntry>(),
      render: ({ description }: ScheduledStatementEntry) => (
        <Typography.Text ellipsis={{ tooltip: { title: description } }}>
          {description}
        </Typography.Text>
      ),
      sorter: (a: ScheduledStatementEntry, b: ScheduledStatementEntry) =>
        a.description.localeCompare(b.description),
    },
    {
      key: 'situation',
      title: 'Situação',
      ...CurrentSituationFilter<ScheduledStatementEntry>(),
      render: ({ currentSituation }: ScheduledStatementEntry) => (
        <Typography.Text
          ellipsis={{
            tooltip: { title: translateCurrentSituation(currentSituation) },
          }}
        >
          <CurrentSituationTag currentSituation={currentSituation} />
        </Typography.Text>
      ),
      sorter: (a: ScheduledStatementEntry, b: ScheduledStatementEntry) => {
        const entryA = a.currentSituation;
        const entryB = b.currentSituation;

        const translationA = translateCurrentSituation(entryA);
        const translationB = translateCurrentSituation(entryB);

        return translationA.localeCompare(translationB);
      },
    },
    {
      key: 'account',
      align: 'center' as 'center',
      title: 'Conta',
      width: 96,
      ...AccountFilter<ScheduledStatementEntry>({ accounts }),
      render: ({ account }: ScheduledStatementEntry) => (
        <AccountInfo account={account} />
      ),
      sorter: (
        entryA: ScheduledStatementEntry,
        entryB: ScheduledStatementEntry
      ) => {
        const a = entryA.account?.name ?? '';
        const b = entryB.account?.name ?? '';

        return a.localeCompare(b);
      },
    },
    {
      key: 'contact',
      title: 'Contato',
      ellipsis: {
        showTitle: true,
      },
      ...ContactFilter<ScheduledStatementEntry>({ contacts }),
      render: ({ contact }: ScheduledStatementEntry) => contact?.nickname,
      sorter: (a: ScheduledStatementEntry, b: ScheduledStatementEntry) => {
        const entryA = a.contact?.nickname ?? '';
        const entryB = b.contact?.nickname ?? '';

        return entryA.localeCompare(entryB);
      },
    },
    {
      key: 'amount',
      title: 'Valor',
      width: 135,
      className: styles.amount,
      render: ({ amount }: ScheduledStatementEntry) => toDecimal(amount, type),
      sorter: (entryA, entryB) =>
        amountSorter<ScheduledStatementEntry & { type: BalanceType }>(
          { ...entryA, type },
          { ...entryB, type }
        ),
      ...AmountFilter<ScheduledStatementEntry>(),
    },
  ];

  return (
    <Table
      rowKey="id"
      size="middle"
      scroll={
        selectedEntries.length > 0
          ? { y: 'calc(100vh - 320px)' }
          : { y: 'calc(100vh - 248px)' }
      }
      columns={columns}
      pagination={{
        position: ['bottomCenter'],
        pageSizeOptions: ['50', '100', '150'],
        defaultPageSize: 50,
      }}
      dataSource={rows}
      className={styles.table}
      loading={loading || isLoadingSuggestions}
      onRow={(scheduledEntry) => ({
        className: styles.tableRow,
        onClick: () => handleSelect(scheduledEntry),
      })}
      footer={() =>
        multiple &&
        selectedEntries.length > 0 && (
          <Typography.Text>
            {pluralize(
              selectedEntries.length,
              `${selectedEntries.length} lançamento selecionado: `,
              `${selectedEntries.length} lançamentos selecionados: `
            )}
            <b>{formattedTotalAmount}</b>
          </Typography.Text>
        )
      }
      rowSelection={{
        type: multiple ? 'checkbox' : 'radio',
        selectedRowKeys: selectedEntries?.map(({ id }) => id),
        onChange: (_, newSelectedEntries) => onSelect(newSelectedEntries),
      }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <div className={styles.emptyText}>
                Não encontramos resultados. Talvez você possa tentar uma nova
                pesquisa.
              </div>
            }
          />
        ),
      }}
    />
  );
}
