import type { SyntheticEvent } from 'react';

import { Autocomplete, Box, InputBase, ListItemText } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import type { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import classNames from 'classnames';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './AccountsAutocomplete.module.scss';

const filterOptions = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (account: Account) => `${account.name}${account.accountNumber}`,
});

type EditParams = Pick<
  GridRenderEditCellParams,
  'api' | 'value' | 'field' | 'id'
> & {
  onChange?: (_e: SyntheticEvent, newValue: Account | null | string) => void;
  autoFocus?: boolean;
  bordered?: boolean;
};

export default function AccountsAutocomplete({
  onChange,
  value,
  autoFocus = true,
  bordered = true,
}: EditParams) {
  const { companyId, organizationId } = useCompanyParams();
  const { accounts, isLoading } = useAccounts({
    companyId,
    organizationId,
  });

  const className = classNames({
    [styles.bordered]: bordered,
  });

  return (
    <Autocomplete<Account, false, false, true>
      fullWidth
      autoHighlight
      className={className}
      openOnFocus
      loading={isLoading}
      options={accounts}
      filterOptions={filterOptions}
      onChange={onChange}
      value={value}
      freeSolo
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option?.name
      }
      isOptionEqualToValue={(opt, selected) => opt.id === selected.id}
      renderInput={(params) => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus={autoFocus}
          fullWidth
          className={styles.input}
        />
      )}
      renderOption={(props, account) => (
        <Box component="li" {...props} key={account.id}>
          <ListItemText
            primary={account.name}
            secondary={account.accountNumber}
          />
        </Box>
      )}
      componentsProps={{ paper: { className: styles.dropdown } }}
    />
  );
}
