import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { DrawerFooter } from 'src/ui';

import { pluralize } from 'src/libs/finbits/Pluralize';

import styles from './ServicesDrawerFooter.module.less';

type Props = {
  selectedServicesAmount: number;
  onReset: () => void;
  onConfirm: () => void;
};

export default function ServicesDrawerFooter({
  selectedServicesAmount,
  onReset,
  onConfirm,
}: Props) {
  return (
    <DrawerFooter>
      <div className={styles.servicesSelected}>
        <Button
          aria-label="Excluir selecionados"
          size="small"
          type="text"
          icon={<CloseOutlined />}
          onClick={onReset}
        />

        {pluralize(
          selectedServicesAmount,
          `${selectedServicesAmount} selecionado`,
          `${selectedServicesAmount} selecionados`
        )}
      </div>

      <DrawerFooter.SubmitButton onClick={onConfirm}>
        Confirmar
      </DrawerFooter.SubmitButton>
    </DrawerFooter>
  );
}
