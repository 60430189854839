import type {
  PaymentSuggestion,
  SuggestionFields,
} from 'src/libs/finbits/Management/Entries/types';
import { ACCOUNT_TYPE_DICTIONARY } from 'src/libs/finbits/Bank/Accounts';
import type { AccountTypeLabel } from 'src/libs/finbits/Bank/Accounts';
import { PAYMENT_METHOD_DICTIONARY } from 'src/libs/finbits/PaymentMethods/translations';
import type { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { format as formatDate } from 'src/libs/finbits/Date';
import { formatCNPJ, formatCPF } from 'src/libs/finbits/Documents';
import { toCurrency } from 'src/libs/finbits/Money';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';
import type { Bank } from 'src/libs/finbits/Bank/types';

import { suggestionFieldNameDictionary } from './translation';

type BuildFieldsArguments = {
  fieldName: string;
  value: string;
  label?: string;
  type?: 'international' | ContactType | null;
};

export type FieldItem = BuildFieldsArguments & {
  fieldNameDisplay: string;
};

type FlatFields = {
  [key: string]: FieldItem;
};

type FieldOptions = keyof typeof suggestionFieldNameDictionary;

function buildField({
  fieldName,
  value,
  label,
  type,
}: BuildFieldsArguments): FlatFields {
  return {
    [fieldName]: {
      value,
      label,
      fieldName,
      fieldNameDisplay:
        suggestionFieldNameDictionary[fieldName as FieldOptions],
      type,
    },
  };
}

type SuggestionKeys = keyof SuggestionFields;
type PaymentSuggestionKeys = keyof PaymentSuggestion;

type Options = {
  banks?: Bank[];
};

function buildCategory(fields: SuggestionFields) {
  const categoryField = fields.category!;

  return buildField({
    fieldName: 'categoryId',
    value: categoryField.id!,
    label: categoryField.name,
  });
}

function buildContact(fields: SuggestionFields) {
  const contactField = fields.contact;

  return buildField({
    fieldName: 'contactId',
    value: contactField?.id! || contactField?.document!,
    label: contactField?.nickname!,
    type: contactField?.type,
  });
}

function buildPaymentDetails(fields: SuggestionFields, banks: Bank[]) {
  const paymentDetailsObject = fields.paymentDetails!;

  const paymentFields = Object.keys(
    paymentDetailsObject as PaymentSuggestion
  ).filter((field) =>
    Boolean(paymentDetailsObject[field as PaymentSuggestionKeys])
  );

  const paymentFieldsFlat = paymentFields.map((paymentFieldName) => {
    if (paymentFieldName === 'boleto') {
      const boletoField = paymentDetailsObject.boleto!;
      return buildField({
        fieldName: 'paymentDetails.digitableLine',
        value: boletoField.digitableLine,
      });
    }

    if (paymentFieldName === 'bankName') {
      return null;
    }

    if (paymentFieldName === 'routingNumber') {
      const bank = banks.find(
        ({ routingNumber }) =>
          paymentDetailsObject.routingNumber === routingNumber
      );

      return buildField({
        fieldName: 'paymentDetails.routingNumber',
        value: paymentDetailsObject.routingNumber!,
        label: `${paymentDetailsObject.routingNumber} - ${bank?.name}`,
      });
    }

    return buildField({
      fieldName: `paymentDetails.${paymentFieldName}`,
      value: paymentDetailsObject[
        paymentFieldName as PaymentSuggestionKeys
      ] as string,
    });
  });

  return Object.assign({}, ...paymentFieldsFlat);
}

export function buildSuggestionItems(
  fields: SuggestionFields,
  options: Options = { banks: [] }
): FlatFields {
  if (!fields) return {};

  const fieldsValid = Object.keys(fields).filter((field) =>
    Boolean(fields[field as SuggestionKeys])
  );

  const flatFields = fieldsValid.map((fieldName) => {
    if (fieldName === 'category') {
      return buildCategory(fields);
    }

    if (fieldName === 'contact') {
      return buildContact(fields);
    }

    if (fieldName === 'paymentDetails') {
      return buildPaymentDetails(fields, options.banks!);
    }

    return buildField({
      fieldName,
      value: fields[fieldName as SuggestionKeys] as string,
    });
  });

  return Object.assign({}, ...flatFields);
}

export function formatValue(fieldName: string, value: string) {
  if (fieldName === 'paymentDetails.paymentMethod') {
    return PAYMENT_METHOD_DICTIONARY[value as PaymentMethod];
  }

  if (fieldName === 'paymentDetails.accountType') {
    return ACCOUNT_TYPE_DICTIONARY[value as AccountTypeLabel];
  }

  if (fieldName === 'date') {
    return formatDate(value, 'dd/MM/yyyy');
  }

  if (fieldName === 'notaFiscalIssueDate') {
    return formatDate(value, 'dd/MM/yyyy');
  }

  if (fieldName === 'dueDate') {
    return formatDate(value, 'dd/MM/yyyy');
  }

  if (fieldName === 'relevantDate') {
    return formatDate(value, 'MMM/yyyy');
  }

  if (fieldName === 'paymentDetails.accountDocument') {
    return value.length === 11 ? formatCPF(value) : formatCNPJ(value);
  }

  if (fieldName === 'amount') {
    return toCurrency(Number(value), BalanceType.CREDIT);
  }

  return value;
}
