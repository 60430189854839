import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormField, Input } from 'src/mui/_scss';

import { DatePicker, InputBase } from 'src/mui';

type Props = {
  flexDirection?: 'column' | 'row';
  maxDate?: Date;
};

export default function InitialBalanceFields({
  flexDirection = 'column',
  maxDate = undefined,
}: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FormField
        control={control}
        label="Nome da conta"
        name="name"
        helperText={!errors.name ? 'Ex.: Conta Principal' : ''}
      >
        {(field) => <Input {...field} placeholder="Informe o nome" />}
      </FormField>

      <Stack display="flex" gap={5} flexDirection={flexDirection}>
        <FormField
          control={control}
          name="initialBalance.balance"
          tooltip="Este valor será usado como base inicial para o cálculo do saldo."
          label="Saldo"
        >
          {(field) => {
            return (
              <InputBase.Amount
                {...field}
                placeholder="0,00"
                components={{ Input }}
              />
            );
          }}
        </FormField>

        <FormField
          control={control}
          tooltip="Data de referência do valor informado para o saldo."
          label="Data de referência"
          name="initialBalance.date"
        >
          {(field) => <DatePicker maxDate={maxDate} {...field} />}
        </FormField>
      </Stack>
    </>
  );
}
