import { UndoSendToApprovalIcon } from 'src/mui/_icons';

import { BillStatus } from 'src/libs/finbits/Bills/types';

import UndoActionButton from 'src/features/bills-to-pay/UndoActionButton';
import SendToApprovedButton from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar/ToolbarSelectedActions/PaymentFlowActions/buttons/SendToApprovedButton';
import SendToReprovedButton from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar/ToolbarSelectedActions/PaymentFlowActions/buttons/SendToReprovedButton';

import type { Action } from '../types';

export default function WaitingApprovalActions({
  selecteds,
  onSuccess,
}: Action) {
  return (
    <>
      <UndoActionButton
        Icon={UndoSendToApprovalIcon}
        status={BillStatus.WAITING_APPROVAL}
        bills={selecteds}
        onSuccess={onSuccess}
      />

      <SendToApprovedButton selectedBills={selecteds} onSuccess={onSuccess}>
        Aprovar
      </SendToApprovedButton>

      <SendToReprovedButton selectedBills={selecteds} onSuccess={onSuccess}>
        Reprovar
      </SendToReprovedButton>
    </>
  );
}
