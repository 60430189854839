import type t from 'zod';

import type {
  BillPayableOptionalDecoder,
  ContactOptionalDecoder,
  InboxItemContactTypeDecoder,
  InboxItemStatusDecoder,
  NewInboxItemDecoder,
  PurchaseOrderDecoder,
  ReceivedEmailDecoder,
  ReceivedNotaFiscalDecoder,
  ReceivedProductNotaFiscalDecoder,
  ReceivedWhatsAppDecoder,
  UploadDecoder,
} from './validations';

export enum InboxItemSource {
  EMAIL = 'email',
  UPLOAD = 'upload',
  WHATSAPP = 'whatsapp',
  NOTA_FISCAL = 'nota_fiscal',
  PRODUCT_NOTA_FISCAL = 'product_nota_fiscal',
  PURCHASE_ORDER = 'purchase_order',
  DDA = 'dda',
}

export type BillPayableOptional = t.TypeOf<typeof BillPayableOptionalDecoder>;
export type NewInboxItem = t.TypeOf<typeof NewInboxItemDecoder>;
export type ReceivedEmail = t.TypeOf<typeof ReceivedEmailDecoder>;
export type ReceivedNotaFiscal = t.TypeOf<typeof ReceivedNotaFiscalDecoder>;
export type ReceivedProductNotaFiscal = t.TypeOf<
  typeof ReceivedProductNotaFiscalDecoder
>;
export type ReceivedWhatsApp = t.TypeOf<typeof ReceivedWhatsAppDecoder>;
export type Upload = t.TypeOf<typeof UploadDecoder>;
export type PurchaseOrder = t.TypeOf<typeof PurchaseOrderDecoder>;
export type InboxItemStatus = t.TypeOf<typeof InboxItemStatusDecoder>;
export type InboxItemContactType = t.TypeOf<typeof InboxItemContactTypeDecoder>;
export type ContactOptional = t.TypeOf<typeof ContactOptionalDecoder>;

export type GetParams = {
  organizationId: string;
  companyId: string;
  status: InboxItemStatus;
};

export type GetInboxItemParams = {
  organizationId: string;
  companyId: string;
  inboxItemId?: string | null;
};

export type GetInboxItemMergeParams = {
  organizationId: string;
  companyId: string;
  inboxItemId?: string | null;
  billId?: string | null;
};

export type PostRejectParams = {
  organizationId: string;
  companyId: string;
  inboxItemsIds: string[];
  rejectionReason?: string;
};

export type PostRestoreParams = {
  organizationId: string;
  companyId: string;
  inboxItemsIds: string[];
};
