import create from 'zustand';
import { useMutation } from 'react-query';
import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';

import { queryClient } from 'src/libs/react-query';
import type {
  ApiError,
  ApiErrorForm,
  ApiResponse,
  ErrorResponse,
} from 'src/libs/finbits/client';
import {
  API,
  decodeResponse,
  handleError,
  handleSuccess,
} from 'src/libs/finbits/client';
import analytics from 'src/libs/analytics';
import * as Storage from 'src/libs/finbits/Storage';

import { AuthenticationTokensDecoder, RedefinePasswordDecoder } from './types';
import type {
  AuthenticationTokens,
  RedefinePassword,
  RedefinePasswordParams,
  SignInParams,
} from './types';

type State = {
  isInitialized: boolean;
  isAuthenticated: boolean;
  accessToken: null | string;
  isRefreshingTokens: boolean;
};

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  accessToken: null,
  isRefreshingTokens: false,
};

const useStore = create<State>(() => initialState);

function isAuthenticatedSelector(state: State) {
  return state.isAuthenticated;
}

function isInitializedSelector(state: State) {
  return state.isInitialized;
}

export function useIsAuthenticated() {
  return useStore(isAuthenticatedSelector);
}

export function useIsInitialized() {
  return useStore(isInitializedSelector);
}

export function setTokens({ access, refresh }: AuthenticationTokens) {
  useStore.setState({
    accessToken: access,
    isAuthenticated: true,
    isInitialized: true,
  });
  Storage.setItem('refresh_token', refresh);
}

export function clearTokens() {
  Storage.removeItem('refresh_token');
  useStore.setState({
    accessToken: null,
    isAuthenticated: false,
  });
}

export function clearTokensAsync() {
  return new Promise((resolve, reject) => {
    try {
      clearTokens();
      resolve('Clear tokens finished');
    } catch {
      reject('Error to clear tokens');
    }
  });
}

export function resetAuthState() {
  useStore.setState(initialState);
}

export function getAccessToken() {
  const state = useStore.getState();
  return state.accessToken;
}

export function getWebsocketAccessToken() {
  const { accessToken, isRefreshingTokens } = useStore.getState();
  if (shouldRenew(accessToken, isRefreshingTokens)) {
    useStore.setState({ isRefreshingTokens: true });
    renewAuth().finally(() => {
      useStore.setState({ isRefreshingTokens: false });
    });
  }

  return accessToken;
}

function shouldRenew(accessToken: string | null, isRefreshingTokens: boolean) {
  if (!accessToken) return false;
  if (isRefreshingTokens) return false;
  if (import.meta.env.VITE_ENVIRONMENT === 'test') return false;

  const jwt = jwtDecode<JwtPayload>(accessToken);

  if (!!jwt.exp && Date.now() >= jwt.exp * 1000) {
    return true;
  }

  return false;
}

export function getRefreshToken() {
  return Storage.getItem<string>('refresh_token');
}

export async function renewAuth() {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    return useStore.setState({ isInitialized: true, isAuthenticated: false });
  }

  const response = await refreshTokensWithRetry(refreshToken);

  if ((response as ErrorResponse).isError) {
    return logOut({ redirect: true });
  }

  setTokens(response as AuthenticationTokens);
}
const maxAttempts = 5;

function timeout(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

async function refreshTokensWithRetry(
  refreshToken: string,
  attempt: number = 0
): Promise<AuthenticationTokens | ErrorResponse> {
  const response = await refreshTokens(refreshToken);

  if (
    (response as ErrorResponse).isError &&
    (response as ErrorResponse).status === 401
  ) {
    return response;
  }

  if ((response as ErrorResponse).isError && attempt >= maxAttempts) {
    return response;
  }

  if ((response as ErrorResponse).isError) {
    await timeout(attempt * 100);
    return refreshTokensWithRetry(refreshToken, attempt + 1);
  }

  return response;
}

export async function logOut({
  redirect = false,
}: { redirect?: boolean } = {}) {
  await clearTokensAsync();
  await untrackUserIdentity();

  queryClient.clear();

  if (redirect) {
    window.location.assign('/login');
  }
}

export async function impersonateAuth({
  access,
  refresh,
}: AuthenticationTokens) {
  Storage.setItem('support_refresh', getRefreshToken());
  Storage.setItem('support_access', getAccessToken());
  logOut();
  setTokens({ access, refresh });
}

export async function impersonateLogout() {
  logOut();
  setTokens({
    access: Storage.getItem('support_access'),
    refresh: Storage.getItem('support_refresh'),
  });
}

async function postAuthenticationTokens({ login, password }: SignInParams) {
  const response = await API.post('/authentications', {
    login,
    password,
  });

  return decodeResponse<AuthenticationTokens>(
    response,
    AuthenticationTokensDecoder
  );
}

export function useSignIn() {
  const { mutate: signIn, ...rest } = useMutation<
    AuthenticationTokens,
    ApiError,
    SignInParams
  >(postAuthenticationTokens, {
    onSuccess: (tokens) => {
      setTokens(tokens);

      analytics.track('User Logged In');
    },
    onError: (error) => {
      analytics.track('User Login Failed', {
        error: error.response?.data.message,
      });
    },
  });

  return { signIn, ...rest };
}

export async function refreshTokens(
  token: string
): Promise<AuthenticationTokens | ErrorResponse> {
  const response = await API.put('/authentications', { token })
    .then(handleSuccess)
    .catch(handleError);

  return formatTokens(response);
}

function formatTokens(
  response: ApiResponse
): AuthenticationTokens | ErrorResponse {
  if (response.type === 'success') {
    return { access: response.data.access, refresh: response.data.refresh };
  }
  return { ...response.data, isError: true } as ErrorResponse;
}

async function postRedefinePassword(
  params: RedefinePasswordParams & { resetHash: string | null }
): Promise<any | ErrorResponse> {
  const response = await API.put('/authentication/reset', params);

  return decodeResponse<RedefinePassword>(response, RedefinePasswordDecoder);
}

export function useRedefinePassword() {
  const { mutate: redefinePassword, ...rest } = useMutation<
    RedefinePassword,
    ApiError<ApiErrorForm>,
    RedefinePasswordParams & { resetHash: string | null }
  >(postRedefinePassword, {
    onSuccess: () => {
      analytics.track('User Redefined Password');
    },
    onError: ({ response }) => {
      analytics.track('User Failed to Redefine Password', {
        error: response?.data?.message,
      });
    },
  });
  return { redefinePassword, ...rest };
}

async function untrackUserIdentity() {
  await analytics.track('User Logged Out');
  await analytics.reset();
}
