import { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

import {
  EXPANSION_ENTER_TIME,
  EXPANSION_EXIT_TIME,
  useBillPreviewContext,
} from 'src/features/bills-to-pay/BillContent/BillPreviewProvider';

import styles from './PreviewIMG.module.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  attachment: Attachment;
  zoom?: number;
  rotate?: number;
  renderWithModal?: boolean;
  onLoad?: () => void;
};

export default function PreviewIMG({
  attachment,
  zoom = 1,
  rotate = 0,
  className,
  style,
  renderWithModal = false,
  onLoad,
}: Props) {
  const { isExpandedPreview } = useBillPreviewContext();

  const ref = useRef<HTMLImageElement>(null);

  const [initialSizes, setInitialSizes] = useState<{
    width: number;
    height: number;
  }>();

  function resizeImage() {
    if (ref.current) {
      setInitialSizes({
        width: ref.current.width,
        height: ref.current.height,
      });
    }
  }

  useEffect(resizeImage, [zoom, rotate]);

  useEffect(() => {
    const time = isExpandedPreview ? EXPANSION_EXIT_TIME : EXPANSION_ENTER_TIME;
    const timer = setTimeout(resizeImage, time);

    return () => clearTimeout(timer);
  }, [isExpandedPreview]);

  const imageModalStyles = useMemo(() => {
    if (!renderWithModal) return;

    const isRotateVertical = rotate === 90 || rotate === 270;

    if (isRotateVertical && initialSizes) {
      return {
        width: `${initialSizes.height}px`,
        height: `${initialSizes.width}px`,
      };
    }
  }, [initialSizes, rotate, renderWithModal]);

  return (
    <img
      ref={ref}
      onLoad={onLoad}
      onMouseDown={(e) => e.preventDefault()}
      draggable="false"
      className={cn(styles.image, className, {
        [styles.imageModal]: renderWithModal,
      })}
      src={attachment.url}
      alt={attachment.name}
      style={{
        transform: `scale(${zoom}, ${zoom}) rotate(${rotate}deg)`,
        ...imageModalStyles,
        ...style,
      }}
    />
  );
}
