import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { singleSelectColumn } from 'src/mui/_scss';

import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import ContactsAutocomplete from 'src/features/entries/EntriesDataGrid/columns/contactColumn/ContactsAutocomplete';
import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

export function contactColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return singleSelectColumn({
    columnProps: {
      minWidth: 100,
      flex: 2,
      editable: true,
      aggregable: false,
      headerName: 'Contato',
      valueFormatter: (value: Contact) => {
        return value?.nickname;
      },
      getOptionValue: (value: any) => value?.id,
      getOptionLabel: (value: any) => value?.nickname,
      groupingValueGetter: (value: Contact) => {
        return value?.nickname ?? 'sem contato';
      },
      sortComparator: (contA, contB) => {
        const nameA = contA?.nickname ?? '';
        const nameB = contB?.nickname ?? '';

        return nameA.localeCompare(nameB);
      },
      ...columnProps,
      field: 'contact',
    },
    SelectComponent: ContactsAutocomplete,
  });
}
