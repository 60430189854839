import type { Components, Theme } from '@mui/material';

import { primary } from '../colors';

export function createMuiStepButton(): Components['MuiStepButton'] {
  return {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&:focus': {
          outline: `2px solid ${primary?.[500]}`,
          borderRadius: (theme as Theme).shape.borderRadius,
        },
      }),
    },
  };
}
