import { Row, Space, Typography } from 'antd';

import type { Label } from 'src/libs/finbits/Labels/types';
import type { Classification } from 'src/libs/finbits/Classifications/types';

import ActiveLabelItemSwitch from 'src/features/labels/LabelList/ActiveLabelItemSwitch';
import EditLabelButton from 'src/features/labels/EditLabelButton';

type Props = {
  classification: Classification;
  label: Label;
};

export default function LabelItem({ classification, label }: Props) {
  return (
    <Row justify="space-between" align="middle">
      <Typography.Text>{label.name}</Typography.Text>
      <Space>
        <ActiveLabelItemSwitch label={label} />
        <EditLabelButton label={label} classification={classification} />
      </Space>
    </Row>
  );
}
