import compact from 'lodash/compact';

import { formatDocument } from 'src/libs/finbits/Documents';
import type { ContactSuggestion } from 'src/libs/finbits/Management/Entries/types';
import { inboxItemContactTypeDictionary } from 'src/libs/finbits/Management/InboxItems/translations';

export function formatNotFoundContactText(contact?: ContactSuggestion | null) {
  if (!contact) return undefined;

  const typeFormatted = contact.type
    ? inboxItemContactTypeDictionary[contact.type]
    : null;

  const documentFormatted =
    contact.document && contact.type
      ? formatDocument(contact.type, contact.document)
      : contact.document;

  const contactDetails = compact([typeFormatted, documentFormatted]).join(' ');

  return `${contactDetails} informado não foi encontrado.`;
}
