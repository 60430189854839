import { Button, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  useAcceptSuggestion,
  useRefuseSuggestion,
} from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';

type Props = {
  suggestionId: string;
};

export default function Actions({ suggestionId }: Props) {
  const { organizationId, companyId } = useCompanyParams();

  const { acceptSuggestion, isLoading: isLoadingAccept } =
    useAcceptSuggestion();

  const { refuseSuggestion, isLoading: isLoadingRefuse } =
    useRefuseSuggestion();

  const isLoading = isLoadingAccept || isLoadingRefuse;

  function handleAcceptSuggestion() {
    acceptSuggestion(
      {
        organizationId,
        companyId,
        suggestionId,
      },
      {
        onSuccess: () =>
          snackbar({
            variant: 'success',
            message: 'Conciliação realizada com sucesso',
          }),

        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao processar conciliação',
          });
        },
      }
    );
  }

  function handleRefuseSuggestion() {
    refuseSuggestion(
      {
        organizationId,
        companyId,
        suggestionId,
      },
      {
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao processar conciliação',
          });
        },
      }
    );
  }

  return (
    <Space size="small">
      <Button
        disabled={isLoading}
        icon={<CheckOutlined />}
        type="primary"
        size="large"
        onClick={handleAcceptSuggestion}
        loading={isLoading}
      >
        Conciliar
      </Button>
      <Button
        disabled={isLoading}
        icon={<CloseOutlined />}
        size="large"
        onClick={handleRefuseSuggestion}
        loading={isLoading}
      />
    </Space>
  );
}
