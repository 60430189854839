import t from 'zod';

const SpecialTaxRuleDecoder = t.object({
  id: t.string(),
  code: t.string(),
  name: t.string(),
});

export type SpecialTaxRule = t.TypeOf<typeof SpecialTaxRuleDecoder>;

export const SpecificationsDecoder = t.object({
  specialTaxRules: SpecialTaxRuleDecoder.array(),
  usesSpecialTaxRules: t.boolean(),
});

export type Specifications = t.TypeOf<typeof SpecificationsDecoder>;
