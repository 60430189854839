import type { FormInstance } from 'antd';
import { Checkbox, Form, Select, Typography } from 'antd';

import { DatePicker, FormItem } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

type Props = {
  selectedAccounts: Account[];
  dateRange: [Date, Date];
  accounts?: Account[];
  form: FormInstance;
};

function Confirmation({ accounts, dateRange, selectedAccounts, form }: Props) {
  const accountIds = selectedAccounts.map((account) => account.id);

  return (
    <>
      <Typography.Paragraph>
        Confirme o que será exportado:
      </Typography.Paragraph>
      <Form
        form={form}
        initialValues={{
          dateRange,
          accounts: accountIds,
          includeScheduledEntries: false,
        }}
        layout="vertical"
        name="comfirmationForm"
        validateTrigger="onChange"
        requiredMark={false}
      >
        <FormItem required hasMax={false} label="Período" name="dateRange">
          <DatePicker.RangePicker
            allowClear={false}
            size="large"
            format="dd/MM/yyyy"
            placeholder={['Data inicial', 'Data final']}
          />
        </FormItem>

        <FormItem required hasMax={false} label="Contas" name="accounts">
          <Select
            mode="tags"
            showSearch
            placeholder="Selecione contas"
            size="large"
          >
            {accounts?.map((account) => (
              <Select.Option value={account.id} key={account.id}>
                {account.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <Form.Item name="includeScheduledEntries" valuePropName="checked">
          <Checkbox>Incluir lançamento programados</Checkbox>
        </Form.Item>
      </Form>
    </>
  );
}

export default Confirmation;
