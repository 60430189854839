import cn from 'classnames';
import { PercentageOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';

import styles from './RateInput.module.less';

type Props = {
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  ariaLabel?: string;
};

export default function RateInput({
  ariaLabel = 'porcentagem',
  placeholder = '0,00',
  disabled,
  ...props
}: Props) {
  return (
    <>
      <InputNumber
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        aria-label={ariaLabel}
        size="large"
        decimalSeparator=","
        maxLength={6}
        className={cn([styles.input])}
        {...props}
      />
      <PercentageOutlined className={cn([styles.grayIcon, styles.icon])} />
    </>
  );
}
