import t from 'zod';

import { AccountType } from 'src/libs/finbits/Bank/Accounts/types';
import {
  REQUIRED_MESSAGE,
  date,
  required,
} from 'src/libs/finbits/resolverValidations';

export const AccountResolver = t
  .object({
    type: required,
    routingNumber: t.string().nullable().optional(),
    name: t.string().optional(),
    initialBalance: t
      .object({
        date: date.nullable(),
        balance: t.string().nullable(),
      })
      .optional(),
  })
  .superRefine(({ type, routingNumber, name, initialBalance }, ctx) => {
    if (type === AccountType.CHECKING_ACCOUNT) {
      if (!routingNumber) {
        return ctx.addIssue({
          code: 'custom',
          message: REQUIRED_MESSAGE,
          path: ['routingNumber'],
        });
      }
    }

    if (type === AccountType.CASH_ACCOUNT) {
      if (!name) {
        ctx.addIssue({
          code: 'custom',
          message: REQUIRED_MESSAGE,
          path: ['name'],
        });
      }

      if (!initialBalance?.balance) {
        ctx.addIssue({
          code: 'custom',
          message: REQUIRED_MESSAGE,
          path: ['initialBalance', 'balance'],
        });
      }

      if (!initialBalance?.date) {
        ctx.addIssue({
          code: 'custom',
          message: REQUIRED_MESSAGE,
          path: ['initialBalance', 'date'],
        });
      }
    }
  });

export const ManualCheckingAccountSteppeResolver = t.object({
  name: required,
  routingNumber: required,
  branchNumber: required,
  accountNumber: required,
  initialBalance: t.object({
    balance: required.nullable().refine((val) => val !== null, {
      message: REQUIRED_MESSAGE,
    }),
    date: date,
  }),
});
