import { useState } from 'react';

import { Box, Card } from '@mui/material';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { parseISO } from 'date-fns';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { Typography } from 'src/design-system/components';

import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';
import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import analytics from 'src/libs/analytics';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { useEntrySuggestionsContext } from 'src/features/entries/Suggestions/EntrySuggestionsProvider';
import { EditableFields } from 'src/features/EntryForm/types';
import { useEditableFieldsContext } from 'src/features/bills-to-pay/BillContent/EditableFieldsProvider/EditableFieldsProvider';

import style from './SuggestionItem.module.scss';

export type Item = {
  text: string;
  subText: string;
  value: string;
  origin: string;
};

type Props = {
  item: Item;
  fieldName: EditableFields;
  extraInfo?: {
    type?: 'international' | ContactType | null;
    typePix?: string;
    paymentMethod: string;
  };
};

type TransformFiledValueProps = {
  fieldValue: string;
  fieldName: EditableFields;
  item: Item;
};

type PaymentMethodByField = {
  fieldValue: string;
  fieldName: EditableFields;
};

function transformFieldValue({
  fieldName,
  fieldValue,
  item,
}: TransformFiledValueProps) {
  const dateFields = ['date', 'relevantDate', 'notaFiscalIssueDate', 'dueDate'];

  if (dateFields.includes(fieldName)) {
    return parseISO(item.value);
  }

  if (fieldName === 'comments' && fieldValue !== '') {
    return `${fieldValue}\n${item.value}`;
  }

  return item.value;
}

function scrollToField(fieldName: string) {
  const fieldRef = document.querySelector(
    `input[name="${fieldName}"], select[name="${fieldName}"], [for*="${fieldName}"]`
  );

  if (fieldRef) {
    fieldRef.scrollIntoView({
      behavior: 'instant',
      block: 'center',
    });
  }
}

export default function SuggestionItem({ item, fieldName, extraInfo }: Props) {
  const { setValue, setFocus, getValues } = useFormContext();
  const { editableFields } = useEditableFieldsContext();
  const { updateSuggestionSelected } = useEntrySuggestionsContext();
  const { organizationId, companyId } = useCompanyParams();
  const [isFlash, setIsFlash] = useState(false);
  const className = cn(style.item, {
    [style.flash]: isFlash,
    [style.disabled]: isSuggestionDisabled(),
  });

  function isSuggestionDisabled() {
    const isEditableSimpleField = editableFields?.includes(fieldName);
    const isEditableFieldsDefined = typeof editableFields !== 'undefined';
    const paymentDetailsPattern = /^paymentDetails/;
    const isPaymentDetailsField = paymentDetailsPattern.test(fieldName);
    const isPaymentDetailsEditable = editableFields?.includes(
      EditableFields.paymentDetails
    );

    if (!isEditableFieldsDefined || isEditableSimpleField) return false;
    if (isPaymentDetailsField && isPaymentDetailsEditable) {
      return false;
    }

    return true;
  }

  function setPaymentDetailsField({
    fieldValue,
    fieldName,
  }: PaymentMethodByField) {
    const setValueOptions = {
      shouldValidate: true,
    };

    setValue(
      'paymentDetails.paymentMethod',
      extraInfo?.paymentMethod,
      setValueOptions
    );

    if (extraInfo?.paymentMethod === PaymentMethod.PIX) {
      window.setTimeout(() => {
        setValue('paymentDetails.pixType', extraInfo?.typePix, setValueOptions);
      }, 0);
    }

    window.setTimeout(() => {
      setValue(fieldName, fieldValue, setValueOptions);
      setFocus(fieldName);

      scrollToField(fieldName);
    }, 10);
  }

  function flashSuggestionItemColor() {
    setIsFlash(true);

    const FLASH_TIME = 400;
    window.setTimeout(() => setIsFlash(false), FLASH_TIME);
  }

  function handleClick(_event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (isSuggestionDisabled()) return;

    flashSuggestionItemColor();

    const fieldValue = getValues(fieldName);
    const transformedFieldValue = transformFieldValue({
      fieldName,
      fieldValue,
      item,
    });

    if (fieldName === 'contactId') {
      const value = transformedFieldValue as string;
      const isDocument = cpf.isValid(value) || cnpj.isValid(value);

      if (isDocument) {
        updateSuggestionSelected({
          contact: {
            type: extraInfo?.type || null,
            document: value,
          },
        });

        setValue(fieldName, '', { shouldValidate: true });

        return;
      }
    }

    const paymentPattern = /paymentDetails/;
    if (paymentPattern.test(fieldName)) {
      const fieldValue = transformedFieldValue as string;
      setPaymentDetailsField({ fieldValue, fieldName });

      analytics.track('Entry Suggestion Clicked', {
        company_id: companyId,
        organization_id: organizationId,
        origin: item.origin,
        field: fieldName,
      });
      return;
    }

    setValue(fieldName, transformedFieldValue, { shouldValidate: true });
    setFocus(fieldName);
    scrollToField(fieldName);

    analytics.track('Entry Suggestion Clicked', {
      company_id: companyId,
      organization_id: organizationId,
      origin: item.origin,
      field: fieldName,
    });
  }

  return (
    <Card
      className={className}
      variant="outlined"
      onClick={handleClick}
      aria-disabled={isSuggestionDisabled()}
    >
      <Box className={style.text}>
        <Typography fontWeight={500} ellipsis>
          {item.text}
        </Typography>
        <Typography className={style.subText} variant="text-xs" ellipsis>
          {item.subText}
        </Typography>
      </Box>
    </Card>
  );
}
