import cn from 'classnames';
import type { PopoverProps as AntdPopoverProps } from 'antd';
import { Popover as AntdPopover } from 'antd';

import styles from './Popover.module.less';

type Props = AntdPopoverProps & {
  size?: 'small' | 'medium';
};

function Popover({ overlayClassName, size = 'medium', ...props }: Props) {
  return (
    <AntdPopover
      overlayClassName={cn([
        styles.popoverOverlay,
        size === 'small' && styles.small,
        size === 'medium' && styles.medium,
        overlayClassName,
      ])}
      {...props}
    />
  );
}

export default Popover;
