import { useState } from 'react';

import { Collapse } from '@mui/material';
import { Col, Form, Radio, Space, Tag } from 'antd';

import { snackbar } from 'src/mui';

import { FormItem } from 'src/ui';

import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import type { PixKey } from 'src/libs/finbits/Bank/Pix/types';
import { PixType } from 'src/libs/finbits/Bank/Pix/types';
import { pixTypeDictionary } from 'src/libs/finbits/Bank/Pix/translations';
import { useCreatePixKey } from 'src/libs/finbits/Bank/Pix';
import { useOptionalCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import { validatePixKey } from 'src/features/contacts/ContactForm/Validations';
import PixKeyAlert from 'src/features/payments/PixKeyAlert';

import PixKeyInput from './PixKeyInput';

type Props = {
  disabled?: boolean;
  requiredFields?: Array<keyof PaymentDetails> | string[];
  baseFieldName?: 'bankDetails' | 'paymentDetails';
  contactParams?: {
    contactType?: ContactType | null;
    contactDocument?: string | null;
  };
  keySearchEnabled?: boolean;
  isSuggestion?: boolean;
};

export default function PixFields({
  disabled = false,
  requiredFields = [],
  baseFieldName = 'bankDetails',
  contactParams,
  keySearchEnabled = false,
  isSuggestion = false,
}: Props) {
  const [pixKey, setPixKey] = useState<PixKey>();

  const { companyId, organizationId } = useOptionalCompanyParams();
  const { createPixKey, isLoading } = useCreatePixKey();

  const pixType = Form.useWatch([baseFieldName, 'pixType']);
  const { setFields } = Form.useFormInstance();

  async function handlePixKeySearch(pixKey: string) {
    if (!keySearchEnabled) return;

    if (!companyId || !organizationId || !pixKey) return;

    handleClearSearch();

    try {
      await validatePixKey(undefined, pixKey, pixType);

      createPixKey(
        {
          companyId,
          organizationId,
          key: pixKey as PixType,
          type: pixType,
          ...contactParams,
        },
        {
          onSuccess: (data) => {
            setPixKey(data);
          },
          onError: (error) => {
            if (error.response?.status === 404) {
              setFields([
                {
                  name: [baseFieldName, 'pixKey'],
                  errors: ['Chave inválida'],
                },
              ]);

              return;
            }

            snackbar({
              variant: 'error',
              message: 'Ocorreu um erro ao buscar chave Pix.',
            });
          },
        }
      );
    } catch {
      setFields([
        {
          name: [baseFieldName, 'pixKey'],
          errors: ['Chave inválida'],
        },
      ]);
    }
  }

  function handleClearSearch() {
    setPixKey(undefined);
  }

  return (
    <Col span={24}>
      <FormItem
        name={[baseFieldName, 'pixType']}
        label={
          <Space>
            Chave PIX
            {isSuggestion && <Tag color="blue">Preenchimento sugerido</Tag>}
          </Space>
        }
        required={requiredFields.includes('pixType')}
      >
        <Radio.Group
          disabled={disabled || isLoading}
          onChange={handleClearSearch}
        >
          <Radio value={PixType.CPF}>{pixTypeDictionary['cpf']}</Radio>
          <Radio value={PixType.CNPJ}>{pixTypeDictionary['cnpj']}</Radio>
          <Radio value={PixType.EMAIL}>{pixTypeDictionary['email']}</Radio>
          <Radio value={PixType.CELLPHONE_NUMBER}>
            {pixTypeDictionary['cellphone_number']}
          </Radio>
          <Radio value={PixType.RANDOM}>{pixTypeDictionary['random']}</Radio>
        </Radio.Group>
      </FormItem>

      <FormItem
        name={[baseFieldName, 'pixKey']}
        required={requiredFields.includes('pixKey')}
        rules={[
          {
            validator: (rule: any, value: string) =>
              validatePixKey(rule, value, pixType),
          },
        ]}
        shouldUpdate
      >
        <PixKeyInput
          pixType={pixType}
          disabled={disabled || isLoading}
          onSearch={handlePixKeySearch}
          onBlur={(event) => handlePixKeySearch(event.target.value)}
          loading={isLoading}
          searchEnabled={keySearchEnabled}
        />
      </FormItem>

      <Collapse in={Boolean(pixKey)} orientation="vertical">
        <PixKeyAlert pixKey={pixKey} />
      </Collapse>
    </Col>
  );
}
