import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { API, decodeResponse } from 'src/libs/finbits/client';

import type { GetPublicCompanyParams, PublicCompany } from './types';
import { PublicCompanyDecoder } from './types';

async function getPublicCompany({ publicId }: GetPublicCompanyParams) {
  const response = await API.get(`/public/companies/${publicId}`);

  return decodeResponse<PublicCompany>(response, PublicCompanyDecoder);
}

export function usePublicCompany({ publicId }: GetPublicCompanyParams) {
  const { data: publicCompany, ...rest } = useQuery<PublicCompany, ApiError>({
    enabled: Boolean(publicId),
    queryKey: ['public_companies', { publicId }],
    queryFn: () => getPublicCompany({ publicId }),
  });

  return {
    publicCompany,
    ...rest,
  };
}
