import { UndoApprovedIcon } from 'src/mui/_icons';

import SendToWaitingApprovalButton from '../buttons/SendToWaitingApprovalButton';
import type { Action } from '../types';

export default function ReprovedActions(props: Action) {
  return (
    <SendToWaitingApprovalButton
      {...props}
      Icon={UndoApprovedIcon}
      label="Reenviar para aprovação"
    />
  );
}
