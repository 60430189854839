import { CalendarOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import type { RuleObject } from 'antd/lib/form';

import type { PortalProps } from 'src/ui';
import { FormItem, MaskedDatePicker, Modal, Title } from 'src/ui';

import {
  format,
  isBefore,
  isFuture,
  isSameDay,
  parseISO,
} from 'src/libs/finbits/Date';

import type { ManualConciliateFormProps } from './types';

type Props = {
  handleOnSubmit: (values: ManualConciliateFormProps) => void;
  initialBalanceDate?: string | null;
  isLoading?: boolean;
} & PortalProps;

export default function ManualConciliateModal({
  handleOnSubmit,
  initialBalanceDate,
  isLoading = false,
  open = true,
  onClose,
  onExit,
}: Props) {
  const [form] = Form.useForm();

  function dateFutureValidator(_rule: RuleObject, value: Date) {
    if (isFuture(value)) {
      return Promise.reject(
        'Não é possível realizar a transação em uma data futura'
      );
    }

    return Promise.resolve();
  }

  function dateBeforeInitialBalanceDateValidator(
    _rule: RuleObject,
    value: Date
  ) {
    if (!initialBalanceDate) return Promise.resolve();

    const parsedInitialBalanceDate = parseISO(initialBalanceDate);

    if (isBefore(value, parsedInitialBalanceDate)) {
      return Promise.reject(
        `Não é possível realizar uma transação anterior à data do saldo inicial ${format(
          initialBalanceDate,
          'dd/MM/yyyy'
        )}`
      );
    }

    if (isSameDay(value, parsedInitialBalanceDate)) {
      return Promise.reject(
        `Não é possível realizar uma transação na mesma data do saldo inicial ${format(
          initialBalanceDate,
          'dd/MM/yyyy'
        )}`
      );
    }

    return Promise.resolve();
  }

  return (
    <Modal
      title={<Title level={4}>Marcar como realizado</Title>}
      footer={
        <Button
          onClick={form.submit}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          OK
        </Button>
      }
      visible={open}
      onCancel={onClose}
      afterClose={onExit}
      destroyOnClose
    >
      <Form onFinish={handleOnSubmit} layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <FormItem
              required
              hasMax={false}
              name="financialEntryDate"
              rules={[
                { validator: dateFutureValidator },
                { validator: dateBeforeInitialBalanceDateValidator },
              ]}
              label="Data de realização"
            >
              <MaskedDatePicker
                placeholder="dd/mm/aaaa"
                size="large"
                format="dd/MM/yyyy"
                suffixIcon={<CalendarOutlined />}
                disabledDate={isFuture}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
