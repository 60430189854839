import { Alert, Form, Row } from 'antd';
import {
  DollarCircleOutlined,
  DoubleRightOutlined,
  PercentageOutlined,
} from '@ant-design/icons';
import cn from 'classnames';

import { toDecimal } from 'src/libs/finbits/Money';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import type { Taxes } from '../types';

import styles from './TaxesCalculator.module.less';

type Props = {
  selectedTaxes?: Taxes[];
};

export function amountFromRate(totalAmount: number, rate: number) {
  const value = (totalAmount * rate) / 100;

  return Math.trunc(value);
}

export function rateFromAmount(
  totalAmount?: number | null,
  rateAmount?: number | null
) {
  if (!totalAmount || !rateAmount) return;

  const value = (100 * rateAmount) / totalAmount;

  return value.toFixed(2);
}

export default function TaxesCalculator({ selectedTaxes = [] }: Props) {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const amount = getFieldValue('amount');

        const issWithheldAtSource = getFieldValue('issWithheldAtSource');
        const issAmount = issWithheldAtSource ? getFieldValue('issAmount') : 0;

        const taxesAmount = selectedTaxes.reduce((acc, taxe) => {
          return acc + getFieldValue(`${taxe}Amount`);
        }, issAmount);

        const amoutWithTax = amount - taxesAmount;

        if (!amount) return null;

        return (
          <Row gutter={[30, 10]} className={styles.wrapper}>
            {taxesAmount > 0 && (
              <>
                <div className={styles.alertWrapper}>
                  <Alert
                    type="warning"
                    aria-label="Retido na fonte"
                    message={
                      <span className={styles.textWrapper}>
                        <PercentageOutlined
                          className={cn(styles.rateIcon, styles.icon)}
                        />
                        Retido na fonte: R${' '}
                        {toDecimal(taxesAmount, BalanceType.CREDIT)}
                      </span>
                    }
                  />
                </div>

                <DoubleRightOutlined className={styles.arrowIcon} />
              </>
            )}
            <div className={styles.alertWrapper}>
              <Alert
                type="success"
                aria-label="A receber"
                message={
                  <span className={styles.textWrapper}>
                    <DollarCircleOutlined
                      className={cn(styles.amountIcon, styles.icon)}
                    />
                    A receber: R$ {toDecimal(amoutWithTax, BalanceType.CREDIT)}
                  </span>
                }
              />
            </div>
          </Row>
        );
      }}
    </Form.Item>
  );
}
