import { useState } from 'react';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageWrapper from 'src/ui/PageWrapper';

import { Title, UsergroupIcon } from 'src/ui';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import UsersTable from 'src/features/users/UsersTable/UsersTable';
import CreateInvitationModal from 'src/features/users/CreateInvitationModal';

import styles from './Users.module.less';

export default function Users() {
  const [isCreateInvitationModalVisible, setIsCreateInvitationModalVisible] =
    useState(false);

  function handleCloseCreateInvitationModal() {
    setIsCreateInvitationModalVisible(false);
  }

  function handleOpenCreateInvitationModal() {
    setIsCreateInvitationModalVisible(true);
  }

  return (
    <PageWrapper>
      {isCreateInvitationModalVisible && (
        <CreateInvitationModal onClose={handleCloseCreateInvitationModal} />
      )}

      <div className={styles.container}>
        <Title icon={<UsergroupIcon />}>Usuários</Title>
        <WithAllowedPermission
          permissions={{
            action: 'create',
            resource: 'invitations',
            scope: 'organization',
          }}
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleOpenCreateInvitationModal}
          >
            Convidar usuário
          </Button>
        </WithAllowedPermission>
      </div>
      <UsersTable />
    </PageWrapper>
  );
}
