import { EntrySituation } from './types';

const CURRENT_SITUATION_DICTIONARY = {
  [EntrySituation.SETTLED]: 'Realizado',
  [EntrySituation.SCHEDULED]: 'Programado',
  [EntrySituation.UNAUTHORIZED]: 'Não autorizado',
  [EntrySituation.FAILED]: 'Falhou',
  [EntrySituation.PAID]: 'Pago',
  [EntrySituation.REVERTED]: 'Estornado',
  [EntrySituation.CANCELED]: 'Cancelado',
  [EntrySituation.PROCESSING]: 'Processando',
  [EntrySituation.PENDING_AUTHORIZATION]: 'Aguardando autorização',
  [EntrySituation.PENDING]: 'A pagar',
  [EntrySituation.WAITING_APPROVAL]: 'Aguardando aprovação',
  [EntrySituation.APPROVED]: 'Aprovado',
  [EntrySituation.REPROVED]: 'Reprovado',
  [EntrySituation.WAITING_PAYMENT]: 'Aguardando pagamento manual',
  [EntrySituation.PAYMENT_SCHEDULED]: 'Pagamento agendado',
  [EntrySituation.DELETED]: 'Excluído',
};

export function translateCurrentSituation(
  currentSituation?: EntrySituation | null
) {
  if (!currentSituation) return '';

  return CURRENT_SITUATION_DICTIONARY[currentSituation];
}
