import { AlertCircleIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useUpdateUserOrganizationPermission } from 'src/libs/finbits/Organization/Users';
import type { User } from 'src/libs/finbits/Organization/Users/types';
import { OrganizationRole } from 'src/libs/finbits/Permissions/types';

type Props = {
  user: User;
};

export default function useMakeAdminModal({ user }: Props) {
  const { organizationId } = useCompanyParams();
  const openConfirmDialog = useOpenConfirmDialog();

  const { updateUserOrganizationPermission } =
    useUpdateUserOrganizationPermission();

  function handleMakeAdmin() {
    updateUserOrganizationPermission(
      {
        organizationId,
        userId: user.id,
        role: OrganizationRole.ADMIN,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Novo usuário administrador criado',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Ocorreu um erro ao atualizar as permissões. Tente novamente dentro de alguns instantes.',
          });
        },
      }
    );
  }

  function openMakeAdminConfirmation() {
    openConfirmDialog({
      variant: 'error',
      icon: <AlertCircleIcon />,
      title: 'Tornar este usuário administrador?',
      content: `Ao conceder permissão de administrador, ${user.name} poderá acessar e editar informações sensíveis de todas as empresas cadastradas na sua conta Finbits`,
      confirmText: 'Sim, tornar administrador',
      onConfirm: handleMakeAdmin,
    });
  }

  return {
    openMakeAdminConfirmation,
  };
}
