import { TrashIcon } from 'src/mui/_icons';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Link } from 'src/mui/_scss';

import styles from './Information.module.scss';

const RESET_FIELDS = ['quantity', 'endDate', 'frequencyInterval'];

export default function Information() {
  const { setValue, resetField } = useFormContext();

  function handleReset() {
    setValue('isRecurrenceEnabled', false);

    RESET_FIELDS.forEach((field) => {
      resetField(field);
    });
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="end"
      className={styles.wrapper}
    >
      <Link
        onClick={handleReset}
        startIcon={<TrashIcon />}
        component="button"
        role="button"
      >
        Descartar recorrência
      </Link>
    </Stack>
  );
}
