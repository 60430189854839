import {
  format as dFormat,
  getWeek as dGetWeek,
  parse as dParse,
  startOfWeek as dStartOfWeek,
  parseISO,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import capitalize from 'lodash/capitalize';

function format(date: Date | string, formatStr: string) {
  if (typeof date === 'string') {
    return capitalize(
      dFormat(parseISO(date), formatStr, {
        locale: ptBR,
      })
    );
  }

  return capitalize(
    dFormat(date, formatStr, {
      locale: ptBR,
    })
  );
}

function startOfWeek(date: Date) {
  return dStartOfWeek(date, {
    locale: ptBR,
  });
}

function getWeek(date: Date) {
  return dGetWeek(date, {
    locale: ptBR,
  });
}

function parse(
  dateString: string,
  formatString: string,
  referenceDate: number | Date
) {
  return dParse(dateString, formatString, referenceDate, {
    locale: ptBR,
  });
}

export * from 'date-fns';
export { format, startOfWeek, getWeek, parse };
