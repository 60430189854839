import { add } from 'date-fns';

import type {
  BillFormParams,
  BillRecurrence,
} from 'src/libs/finbits/Bills/types';
import { BillFrequencyRecurrence } from 'src/libs/finbits/Bills/types';

type Fields = {
  initialDate: Date;
  frequencyInterval: string;
  quantity: string;
};

export function getEndDate({
  initialDate,
  frequencyInterval,
  quantity,
}: Fields) {
  // We need to consider initial date as the first recurrence count,
  // so we need to remove it from the calcululation,
  const repetitionFix = parseInt(quantity) - 1;

  if (frequencyInterval === BillFrequencyRecurrence.DAILY) {
    return add(initialDate, { days: repetitionFix });
  }

  if (frequencyInterval === BillFrequencyRecurrence.WEEKLY) {
    return add(initialDate, { weeks: repetitionFix });
  }

  if (frequencyInterval === BillFrequencyRecurrence.BIWEEKLY) {
    const BIWEEKLY_IN_WEEKS = 2;
    return add(initialDate, { weeks: repetitionFix * BIWEEKLY_IN_WEEKS });
  }

  if (frequencyInterval === BillFrequencyRecurrence.MONTHLY) {
    return add(initialDate, { months: repetitionFix });
  }

  if (frequencyInterval === BillFrequencyRecurrence.QUARTERLY) {
    const QUATERLY_IN_MONTHS = 3;
    return add(initialDate, {
      months: repetitionFix * QUATERLY_IN_MONTHS,
    });
  }

  if (frequencyInterval === BillFrequencyRecurrence.YEARLY) {
    return add(initialDate, { years: repetitionFix });
  }

  return initialDate;
}

export function buildRecurrencePayload(
  bill: BillFormParams,
  inboxItemsIds: string[]
): BillRecurrence {
  function generateEntry(_: undefined[], index: number) {
    const isFirstBill = index === 0;
    const isLastBill = index === Number(bill.quantity) - 1;

    if (!bill.endDate) {
      throw new Error(
        `End date is missing at buildRecurrencePayload: Start Date: ${bill.date}, End Date: ${bill.endDate}, Frequency Interval: ${bill.frequencyInterval}`
      );
    }

    let newDate = bill.date;
    if (isFirstBill) {
      newDate = bill.date;
    } else if (isLastBill) {
      newDate = bill.endDate;
    } else {
      newDate = getEndDate({
        initialDate: bill.date,
        frequencyInterval: bill.frequencyInterval!,
        quantity: (index + 1).toString(),
      });
    }

    return {
      date: newDate,
      accountId: bill.accountId,
      amount: bill.amount,
      description: bill.description,
      attachmentsIds:
        bill.attachments?.map((attachment) => attachment.id) || [],
      classifications: bill.classifications
        ? Object.values(bill.classifications)
        : [],
      comments: bill.comments || null,
      paymentDetails: bill.paymentDetails || {},
      relevantDate: bill.relevantDate || null,
      approvalType: bill.approvalType || undefined,
      assigneesIds: bill.assigneesIds || null,
      categoryId: bill.categoryId || null,
      contactId: bill.contactId || null,
      notaFiscalNumber: bill.notaFiscalNumber || null,
      notaFiscalIssueDate: bill.notaFiscalIssueDate || null,
      dueDate: bill.dueDate || null,
      inboxItemsIds: inboxItemsIds || [],
    };
  }

  const entries = Array.from({ length: Number(bill.quantity) }, generateEntry);

  return {
    entries,
  };
}
