import t from 'zod';

import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';
import { AccountDecoder } from 'src/libs/finbits/Bank/Accounts/types';
import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { LabelDecoder } from 'src/libs/finbits/Labels/types';
import { ScheduledRecurrenceBaseDecoder } from 'src/libs/finbits/Management/ScheduledRecurrences/types';
import {
  AvailableActions,
  EntrySituation,
} from 'src/libs/finbits/Management/Entries/types';

import { EditableFields } from 'src/features/EntryForm/types';
import type { ManualConciliateFormProps } from 'src/features/entries/ManualConciliateHeaderButton/ManualConciliateModal';

export const ReceivableDecoder = t.object({
  account: AccountDecoder.optional(),
  accountId: t.string(),
  amount: t.number(),
  attachments: AttachmentDecoder.array().optional(),
  availableActions: t.nativeEnum(AvailableActions).array().nullish(),
  category: CategoryDecoder.nullish(),
  categoryId: t.string().nullable(),
  comments: t.string().nullable(),
  companyId: t.string(),
  contact: ContactDecoder.nullish(),
  contactId: t.string().nullable(),
  date: t.string(),
  description: t.string(),
  currentSituation: t.nativeEnum(EntrySituation).nullish(),
  editableFields: t.nativeEnum(EditableFields).array().nullish(),
  id: t.string(),
  publicId: t.string(),
  notaFiscalId: t.string().nullish(),
  relevantDate: t.string().nullable(),
  labels: LabelDecoder.array().optional(),
  scheduledRecurrence: ScheduledRecurrenceBaseDecoder.nullish(),
  scheduledRecurrenceId: t.string().nullable(),
});

export const RecurringReceivableDecoder = t.object({
  id: t.string(),
  initialDate: t.string().nullable(),
  quantity: t.number(),
  receivables: ReceivableDecoder.array(),
});

export type Receivable = t.TypeOf<typeof ReceivableDecoder>;
export type RecurringReceivable = t.TypeOf<typeof RecurringReceivableDecoder>;

type EditableParams = Pick<
  Partial<Receivable>,
  | 'amount'
  | 'description'
  | 'accountId'
  | 'categoryId'
  | 'contactId'
  | 'comments'
> & {
  date: Date;
  relevantDate?: Date;
  attachmentsIds?: string[];
};

export type ReceivablePostParams = EditableParams & {
  organizationId?: string;
  companyId?: string;
};

export type RecurringReceivablePostParams = EditableParams & {
  organizationId?: string;
  companyId?: string;
  quantity?: number | null;
  frequencyInterval?: number | null;
  frequencyUnit?: string | null;
};

export type ReceivablesGetAllParams = {
  organizationId?: string;
  companyId?: string;
  order?: 'asc' | 'desc';
};

export type ReceivablesGetParams = {
  organizationId?: string;
  companyId?: string;
  receivableId?: string;
};

export type ReceivablePatchParams = EditableParams & {
  organizationId?: string;
  companyId?: string;
  receivableId?: string;
};

export type CreateFinancialFromReceivableParams = {
  organizationId?: string;
  companyId?: string;
  receivableId?: string;
} & ManualConciliateFormProps;

export type ReceivableRecurrencesDeleteParams = {
  organizationId?: string;
  companyId?: string;
  receivableId?: string;
};
