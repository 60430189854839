import type { Ref } from 'react';
import { forwardRef } from 'react';

import type { TreeSelectProps } from 'antd';
import { TreeSelect as AntdTreeSelect } from 'antd';
import cn from 'classnames';

import styles from './TreeSelect.module.scss';

type Props = {
  className?: string;
} & TreeSelectProps;

function TreeSelect(
  { className, dropdownClassName, ...rest }: Props,
  ref?: Ref<any>
) {
  return (
    <AntdTreeSelect
      ref={ref}
      dropdownClassName={cn(styles.treeSelect, dropdownClassName)}
      className={className}
      allowClear
      showSearch
      size="large"
      {...rest}
    />
  );
}

export default forwardRef(TreeSelect);
