import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function ArrowNarrowDownIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  strokeWidth = '2',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="arrow-narrow-down"
      viewBox={viewBox}
      fontSize={fontSize}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M12 4V20M12 20L18 14M12 20L6 14"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
