import { List, ListItem } from '@mui/material';

import type { BalanceInconsistency } from '../types';

import BalanceInconsistencyItem from './BalanceInconsistencyItem';
import styles from './BalanceInconsistencyList.module.scss';

type Props = {
  accounts: BalanceInconsistency[];
};

export default function BalanceInconsistencyList({ accounts }: Props) {
  return (
    <List disablePadding>
      {accounts.map((account) => (
        <ListItem
          key={account.account.id}
          divider={account !== accounts.at(-1)}
          disablePadding
          className={styles.listItem}
        >
          <BalanceInconsistencyItem account={account} />
        </ListItem>
      ))}
    </List>
  );
}
