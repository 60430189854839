import {
  PlusIcon,
  SearchIcon,
  SettingsIcon,
  SlashCircleIcon,
} from 'src/mui/_icons';
import { Button, Stack } from '@mui/material';
import { Link, Message } from 'src/mui/_scss';
import { useFormContext } from 'react-hook-form';
import { Typography } from 'src/design-system/components';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Tooltip } from 'src/mui';

import { Loader, useOpenPortal } from 'src/ui';

import { useClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { Label } from 'src/libs/finbits/Labels';

import LabelModal from 'src/features/labels/LabelModal';
import CreateClassificationModal from 'src/features/classifications/CreateClassificationModal';

import styles from './ClassificationsTab.module.scss';
import LabelSelect from './LabelSelect';

export default function ClassificationsTab() {
  const { companyId, organizationId } = useCompanyParams();
  const { classifications, isLoading, isFetched } = useClassifications({
    organizationId,
    companyId,
  });
  const hasClassificationActive = classifications.some(
    (classification) => classification.active
  );

  const { setValue, getValues } = useFormContext();
  const openPortal = useOpenPortal();

  function openCreateClassificationModal() {
    openPortal(CreateClassificationModal);
  }

  function handleCreateLabel(label: Label, classificationId: string) {
    const classification = getValues(`classifications.${classificationId}`);

    const labelsIds = classification
      ? [...classification.labelsIds, label.id]
      : [label.id];

    setValue(`classifications.${classificationId}`, {
      classificationId,
      labelsIds,
    });
  }

  function openCreateLabel(classification: Classification) {
    openPortal(LabelModal, {
      classification,
      onCreate: (label) => handleCreateLabel(label, classification.id),
    });
  }

  const isAllowedCreateClassifications = useAllowedPermission({
    action: 'create',
    resource: 'classifications',
  });

  if (isLoading) {
    return <Loader />;
  }

  if (classifications.length === 0 && isFetched) {
    return (
      <Message
        icon={<SearchIcon />}
        title="Sem etiquetas"
        subtitle={
          isAllowedCreateClassifications
            ? 'Você ainda não possui etiquetas. Você pode criar e editar as etiquetas, dentro de suas classificações.'
            : `Você ainda não possui etiquetas. Seu perfil é "visualizador" e não permite criar classificações. 
               Você precisa pedir a um administrador que mude seu perfil para ter essa permissão.`
        }
      >
        <Tooltip
          title={
            <Stack>
              <Typography gutterBottom>
                Você não tem permissão para criar uma nova classificação.
              </Typography>
              <Typography>
                Caso precise, entre em contato com o administrador.
              </Typography>
            </Stack>
          }
          placement="top"
          arrow
          disableHoverListener={isAllowedCreateClassifications}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<PlusIcon />}
            onClick={openCreateClassificationModal}
            disabled={!isAllowedCreateClassifications}
          >
            Nova classificação
          </Button>
        </Tooltip>
      </Message>
    );
  }

  if (!hasClassificationActive) {
    return (
      <Message
        icon={<SlashCircleIcon />}
        title="Classificações desabilitadas"
        subtitle="Você pode criar uma classificação nova ou ativar alguma classificação já criada. 
                  Para ativá-las, é necessário acessar as configurações no botão abaixo e ativar a classificação desejada."
      >
        <Stack direction="row" gap={3}>
          <Tooltip
            title={
              <Stack>
                <Typography gutterBottom>
                  Você não tem permissão para criar uma nova classificação.
                </Typography>
                <Typography>
                  Caso precise, entre em contato com o administrador.
                </Typography>
              </Stack>
            }
            placement="top"
            arrow
            disableHoverListener={isAllowedCreateClassifications}
          >
            <Button
              onClick={openCreateClassificationModal}
              startIcon={<PlusIcon />}
              variant="contained"
              color="primary"
              className={styles.configLabelsLink}
              disabled={!isAllowedCreateClassifications}
            >
              Nova classificação
            </Button>
          </Tooltip>

          <Button
            startIcon={<SettingsIcon />}
            href={generateCompanyPath(RouteKey.LABELS, {
              companyId,
              organizationId,
            })}
            target="_blank"
            variant="outlined"
          >
            Configurar etiquetas
          </Button>
        </Stack>
      </Message>
    );
  }

  return (
    <Stack className={styles.stack}>
      <Typography className={styles.typography}>
        {isAllowedCreateClassifications
          ? 'Aqui você cria e edita as etiquetas, dentro de suas classificações.'
          : `Seu perfil é "visualizador" e não permite criar classificações. 
             Você precisa pedir a um administrador que mude seu perfil para ter essa permissão.`}
      </Typography>
      <Tooltip
        title={
          <Stack>
            <Typography gutterBottom>
              Você não tem permissão para criar uma nova classificação.
            </Typography>
            <Typography>
              Caso precise, entre em contato com o administrador.
            </Typography>
          </Stack>
        }
        placement="top"
        arrow
        disableHoverListener={isAllowedCreateClassifications}
      >
        <Link
          component="button"
          role="button"
          className={styles.link}
          onClick={openCreateClassificationModal}
          startIcon={<PlusIcon />}
          disabled={!isAllowedCreateClassifications}
        >
          Nova classificação
        </Link>
      </Tooltip>

      <LabelSelect
        classifications={classifications}
        onOpenCreateLabel={openCreateLabel}
      />
    </Stack>
  );
}
