import { useState } from 'react';

import type { RadioChangeEvent } from 'antd';
import { Radio, Space, Typography } from 'antd';

import type { PortalProps } from 'src/ui';
import { Modal, Title } from 'src/ui';

export enum DeleteOccurrenceType {
  THIS = 'thisOccurrence',
  NEXT = 'thisAndNextOccurrences',
}

type Props = {
  onDelete: () => void;
  onDeletedRecurrence: () => void;
} & PortalProps;

export default function DeleteScheduledRecurrenceModal({
  onClose,
  onDelete,
  onDeletedRecurrence,
  open = true,
  onExit,
}: Props) {
  const [deleteScheduledRecurrenceValue, setDeleteScheduledRecurrenceValue] =
    useState(DeleteOccurrenceType.THIS);

  function handleDelete() {
    if (deleteScheduledRecurrenceValue === DeleteOccurrenceType.THIS) {
      onDelete();
      onClose();
      return;
    }

    onDeletedRecurrence();
    onClose();
  }

  function onDeleteScheduledRecurrenceChange(e: RadioChangeEvent) {
    setDeleteScheduledRecurrenceValue(e.target.value);
  }

  return (
    <Modal
      title={<Title>Tem certeza que deseja excluir?</Title>}
      visible={open}
      onOk={handleDelete}
      onCancel={onClose}
      afterClose={onExit}
      okText="Excluir"
      cancelText="Cancelar"
    >
      <Typography.Paragraph>
        As informações serão excluídas permanentemente.
      </Typography.Paragraph>
      <Radio.Group
        onChange={onDeleteScheduledRecurrenceChange}
        value={deleteScheduledRecurrenceValue}
      >
        <Space direction="vertical">
          <Radio value={DeleteOccurrenceType.THIS}>
            Apenas esta ocorrência
          </Radio>
          <Radio value={DeleteOccurrenceType.NEXT}>
            Esta e as ocorrências seguintes{' '}
          </Radio>
        </Space>
      </Radio.Group>
    </Modal>
  );
}
