import { Modal } from 'src/ui';

import CreateInvitationForm from 'src/features/users/CreateInvitationForm';

type Props = {
  onClose: () => void;
};

export default function CreateInvitationModal({ onClose }: Props) {
  return (
    <Modal
      visible
      footer={null}
      onCancel={onClose}
      title="Convidar novo usuário"
      destroyOnClose
    >
      <CreateInvitationForm onSuccess={onClose} />
    </Modal>
  );
}
