import type { SxProps, Theme } from '@mui/material';
import { alertClasses } from '@mui/material';

export const alert: SxProps<Theme> = {
  mt: 6,

  [`.${alertClasses.message}`]: {
    width: '100%',
  },
};
