import { useNavigate } from 'react-router';
import { closeSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { snackbar, snackbarAction } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import type { WaitingPaymentParams } from 'src/libs/finbits/PaymentsFlow/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import {
  useCreateWaitingPayment,
  useUndoWaitingPayment,
} from 'src/libs/finbits/PaymentsFlow';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';

import MuiPaymentModal from 'src/features/bills-to-pay/PaymentReviewModal';

type Props = {
  selecteds: BillPayable[];
  onSuccess?: () => void;
};

const SNACKBAR_KEY = 'undo';

export default function useWaitingPaymentAction({
  selecteds,
  onSuccess,
}: Props) {
  const openPortal = useOpenPortal();
  const selectedIds = selecteds.map((bill) => bill.id);

  const navigate = useNavigate();
  const { companyId, organizationId } = useCompanyParams();
  const { createWaitingPayment, isLoading } = useCreateWaitingPayment();
  const { undoWaitingPayment } = useUndoWaitingPayment();
  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  const previousStep = isEnabledBillsApprovals ? 'Aprovados' : 'A pagar';

  function handleUndo() {
    closeSnackbar(SNACKBAR_KEY);

    undoWaitingPayment(
      {
        organizationId,
        companyId,
        billsIds: selectedIds,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: pluralize(
              selectedIds.length,
              `Lançamento reenviado para ${previousStep}`,
              `Lançamentos reenviados para ${previousStep}`
            ),
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: `Erro ao reenviar lançamento para ${previousStep}`,
          });
        },
      }
    );
  }

  function sendToWaitingPayment() {
    function handleConfirm(params?: WaitingPaymentParams) {
      createWaitingPayment(
        {
          organizationId,
          companyId,
          ...params,
        },
        {
          onSuccess: () => {
            onSuccess && onSuccess();

            snackbar({
              variant: 'success',
              key: SNACKBAR_KEY,
              title: `${pluralize(
                selectedIds.length,
                'Lançamento enviado',
                'Lançamentos enviados'
              )} para pagamento`,
              alignItems: 'start',
              customActions: (
                <Button
                  aria-label="Ver enviados para pagamento"
                  size="small"
                  color="secondary"
                  sx={snackbarAction}
                  onClick={() =>
                    navigate({
                      search: `status=${BillStatus.WAITING_PAYMENT}`,
                    })
                  }
                >
                  Ver enviados para pagamento
                </Button>
              ),
            });
          },
          onError: (e) => {
            snackbar({ variant: 'error', message: e.response?.data.message });
          },
        }
      );
    }

    openPortal(MuiPaymentModal, {
      selecteds: selecteds,
      onConfirm: handleConfirm,
    });
  }

  return {
    sendToWaitingPayment,
    isLoading,
    undosWaitingPayment: handleUndo,
  };
}
