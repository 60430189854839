import { BankIcon as BankIconSvg, WalletIcon } from 'src/mui/_icons';

import {
  BB,
  BS2,
  BTG,
  BV,
  Bradesco,
  C6,
  Caixa,
  Cora,
  Inter,
  Itau,
  Iugu,
  Nubank,
  Santander,
  Sicoob,
  Warren,
  XP,
} from './icons';
import styles from './BankIcon.module.scss';

type Props = {
  routingNumber?: string | null;
  width?: number;
  height?: number;
};

function bankData(routingNumber: string) {
  switch (routingNumber) {
    case '001':
      return { src: BB, alt: 'Banco do Brasil logo' };
    case '033':
      return { src: Santander, alt: 'Santander logo' };
    case '077':
      return { src: Inter, alt: 'Banco Inter logo' };
    case '104':
      return { src: Caixa, alt: 'Caixa Economica logo' };
    case '237':
      return { src: Bradesco, alt: 'Bradesco logo' };
    case '260':
      return { src: Nubank, alt: 'Nubank logo' };
    case '341':
      return { src: Itau, alt: 'Itaú logo' };
    case '401':
      return { src: Iugu, alt: 'Iugu logo' };
    case '655':
      return { src: BV, alt: 'BV logo' };
    case '208':
      return { src: BTG, alt: 'BTG logo' };
    case '218':
      return { src: BS2, alt: 'BS2 logo' };
    case '403':
      return { src: Cora, alt: 'Cora logo' };
    case '756':
      return { src: Sicoob, alt: 'Sicoob logo' };
    case '102':
      return { src: XP, alt: 'XP logo' };
    case '336':
      return { src: C6, alt: 'C6 logo' };
    case '371':
      return { src: Warren, alt: 'Warren logo' };
    default:
      return { Icon: BankIconSvg, alt: 'Banco logo' };
  }
}

function BankIcon({ routingNumber, width = 32, height = 32 }: Props) {
  const style = { width, height };

  if (!routingNumber) {
    return (
      <div className={styles.iconWrapper} style={style}>
        <WalletIcon
          aria-label="Conta não bancarizada logo"
          className={styles.icon}
        />
      </div>
    );
  }

  const { src, alt, Icon } = bankData(routingNumber);

  if (Icon) {
    return (
      <div className={styles.iconWrapper} style={style}>
        <Icon aria-label={alt} className={styles.icon} />
      </div>
    );
  }

  return <img style={style} src={src} alt={alt} className={styles.img} />;
}

export default BankIcon;
