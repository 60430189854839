import { createContext, useContext, useEffect, useState } from 'react';

import omit from 'lodash/omit';

import { queryClient } from 'src/libs/react-query';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useEntrySuggestions } from 'src/libs/finbits/Management/Entries';
import { SuggestionSource } from 'src/libs/finbits/Management/Entries/types';
import type {
  NewEntrySuggestion,
  SuggestionFields,
} from 'src/libs/finbits/Management/Entries/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { getCompanyContact } from 'src/libs/finbits/Organization/Companies/Contacts';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import type {
  EntrySuggestionsContextProps,
  EntrySuggestionsProviderProps,
  GetSuggestionsArgs,
  SuggestionContactFromFields,
  SuggestionFromContact,
} from './types';

const EntrySuggestionsContext = createContext<
  EntrySuggestionsContextProps | undefined
>(undefined);

const INBOX_SOURCES = [
  SuggestionSource.DDA,
  SuggestionSource.EMAIL,
  SuggestionSource.NOTA_FISCAL,
  SuggestionSource.PRODUCT_NOTA_FISCAL,
  SuggestionSource.PURCHASE_ORDER,
  SuggestionSource.UPLOAD,
  SuggestionSource.WHATSAPP,
];

export default function EntrySuggestionsProvider({
  children,
  inboxItemId,
  attachments,
  contact,
}: EntrySuggestionsProviderProps) {
  const { companyId, organizationId } = useCompanyParams();
  const { entrySuggestions } = useEntrySuggestions();
  const [suggestions, setSuggestions] = useState<NewEntrySuggestion[]>([]);
  const [suggestionFromContact, setSuggestionFromContact] =
    useState<SuggestionFromContact>({
      current: { paymentDetails: null, category: null },
    });
  const [suggestionSelected, setSuggestionSelected] =
    useState<SuggestionFields>();
  const [isLoadingSuggestions, SetIsLoadingSuggestions] = useState(true);

  const [isFirstRequest, setIsFirstRequest] = useState(true);

  useEffect(() => {
    const hasDocumentWithNoUser = !contact?.id && contact?.document;
    if (hasDocumentWithNoUser) {
      handleUpdateSuggestion({
        contact,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.id || contact?.document]);

  async function getSuggestions(
    { contactId, handleFirstRequest }: GetSuggestionsArgs = {
      contactId: undefined,
    }
  ) {
    SetIsLoadingSuggestions(true);

    try {
      // TODO: Remove once Suggestion v0.5 deployed
      let contactResponse: Contact;
      if (contactId) {
        contactResponse = await queryClient.fetchQuery(
          ['company_contact', { contactId, organizationId, companyId }],
          {
            queryKey: [
              'company_contact',
              { contactId, organizationId, companyId },
            ],
            queryFn: () =>
              getCompanyContact({ organizationId, companyId, contactId }),
          }
        );
      }

      const params = {
        contactId,
        inboxItemsIds: [inboxItemId!],
        attachmentsIds: attachments?.map((attachment) => attachment.id),
        type: BalanceType.DEBIT,
      };

      entrySuggestions(
        {
          organizationId,
          companyId,
          params: contactId ? params : omit(params, 'contactId'),
        },
        {
          onSuccess: (response) => {
            function buildCategorySuggestion() {
              const contactOriginIndex = response.findIndex(
                (origin) => origin.sourceType === SuggestionSource.CONTACT
              );

              const categoryResponse =
                response?.[contactOriginIndex]?.fields?.category;

              if (!categoryResponse || suggestionSelected?.category) {
                return null;
              }

              return categoryResponse;
            }

            const newSuggestion = {
              category: buildCategorySuggestion(),
              paymentDetails: contactResponse?.bankDetails,
            };

            setSuggestionFromContact((suggestion) => ({
              current: newSuggestion,
              old: suggestion.current,
            }));

            if (isFirstRequest) {
              const suggestionAutoFillIndex = response.findIndex((origin) =>
                INBOX_SOURCES.includes(origin.sourceType)
              );
              const autofillFormFromInboxSource =
                response[suggestionAutoFillIndex];

              handleFirstRequest?.(autofillFormFromInboxSource?.fields);
              setIsFirstRequest(false);
            }

            setSuggestions(response);
          },
          onSettled: () => {
            SetIsLoadingSuggestions(false);
          },
        }
      );
    } catch (error) {
      console.error(`Error to get suggestion from contact: ${error}`, {
        organizationId,
        companyId,
        contactId,
      });
    }
  }

  function handleUpdateSuggestion(newSuggetion = {}) {
    setSuggestionSelected((prev) => {
      return { ...prev, ...newSuggetion };
    });
  }

  function handleUpdateSuggestionFromContact(
    key: 'category' | 'paymentDetails',
    value: unknown
  ) {
    setSuggestionFromContact((suggestionContact: SuggestionFromContact) => {
      const currentState =
        suggestionContact.current as SuggestionContactFromFields;
      const newState = {
        ...currentState,
        [key]: value
          ? {
              ...currentState[key],
              ...value,
            }
          : null,
      };

      return {
        old: currentState,
        current: newState,
      };
    });
  }

  return (
    <EntrySuggestionsContext.Provider
      value={{
        suggestions,
        suggestionSelected,
        suggestionFromContact,
        isLoadingSuggestions,
        updateSuggestionSelected: handleUpdateSuggestion,
        updateSuggestionFromContact: handleUpdateSuggestionFromContact,
        getSuggestions,
        updateSuggestions: setSuggestions,
      }}
    >
      {children}
    </EntrySuggestionsContext.Provider>
  );
}

export function useEntrySuggestionsContext() {
  const context = useContext(EntrySuggestionsContext);

  if (!context) {
    throw new Error(
      'To use context, you must provide a EntrySuggestionsProvider'
    );
  }

  return context;
}
