import type { GridColDef } from '@mui/x-data-grid-premium';

import { formatList } from 'src/libs/finbits/List';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { translateCurrentSituation } from 'src/libs/finbits/Management/Entries/currentSituationTranslations';
import type { EntrySituation } from 'src/libs/finbits/Management/Entries/types';

type Props = {
  message: 'category' | 'situation';
  amountType: BalanceType;
  situations: EntrySituation[];
  colDef: GridColDef;
};

export function EditAlert({ message, amountType, situations, colDef }: Props) {
  const statusElements = formatList(
    situations.map((s) => translateCurrentSituation(s))
  );

  return (
    <>
      {message === 'category' && (
        <>
          A alteração do campo <b>{colDef.headerName}</b> será aplicada apenas
          aos lançamentos de{' '}
          <b>{amountType === BalanceType.CREDIT ? 'Entrada' : 'Saida'}</b>
        </>
      )}
      {message === 'situation' && (
        <>
          A alteração do campo <b>{colDef.headerName}</b> não será aplicada aos
          lançamentos selecionados com situação: {statusElements}
        </>
      )}
      . Pressione <b>Esc</b> para cancelar.
    </>
  );
}
