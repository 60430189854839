import type { InputProps } from 'antd';
import { Form, Select } from 'antd';

import { MaskedInput } from 'src/ui';

import { cnpjMask, cpfMask } from 'src/libs/finbits/Documents';
import type { InboxItemContactType } from 'src/libs/finbits/Management/NewInboxItems/types';

const documentPlaceholder: Record<InboxItemContactType, string> = {
  legal: '00.000.000/0000-00',
  natural: '000.000.000-00',
  international: 'Nome',
};

const documentMask: Record<InboxItemContactType, string[] | undefined> = {
  legal: [cnpjMask],
  natural: [cpfMask],
  international: undefined,
};

export default function DocumentSelect({
  name = '',
  disabled,
  ...rest
}: InputProps) {
  const contactType = Form.useWatch<InboxItemContactType>('contactType');

  return (
    <Form.Item shouldUpdate noStyle>
      {({ setFieldValue }) => {
        function handleChangeContactType() {
          setFieldValue(name, '');
        }

        return (
          <MaskedInput
            addonBefore={
              <Form.Item name="contactType" noStyle>
                <Select
                  onChange={handleChangeContactType}
                  size="large"
                  data-testid="document-select"
                  disabled={disabled}
                >
                  <Select.Option value="natural">CPF</Select.Option>
                  <Select.Option value="legal">CNPJ</Select.Option>
                  <Select.Option value="international">Intl.</Select.Option>
                </Select>
              </Form.Item>
            }
            placeholder={documentPlaceholder[contactType]}
            size="large"
            mask={documentMask[contactType]}
            name={name}
            disabled={disabled}
            {...rest}
          />
        );
      }}
    </Form.Item>
  );
}
