import { useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';

import { DrawerHeaderButton } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import analytics from 'src/libs/analytics';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import type { ManualConciliateFormProps } from './ManualConciliateModal';
import ManualConciliateModal from './ManualConciliateModal';

type Props = {
  account?: Account;
  onConciliate: (params: ManualConciliateFormProps) => void;
};

export default function ManualConciliateHeaderButton({
  account,
  onConciliate,
}: Props) {
  const { companyId } = useCompanyParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleClick() {
    analytics.track('Entry Drawer Header Conciliation Button Clicked', {
      company_id: companyId,
    });

    setIsModalOpen(true);
  }

  return (
    <>
      <DrawerHeaderButton
        icon={<CheckOutlined />}
        title="Marcar como realizado"
        onClick={handleClick}
        aria-label="Marcar como realizado"
      />

      {isModalOpen && (
        <ManualConciliateModal
          handleOnSubmit={onConciliate}
          onClose={() => setIsModalOpen(false)}
          initialBalanceDate={account?.initialBalance?.date}
        />
      )}
    </>
  );
}
