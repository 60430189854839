import type { Ref } from 'react';
import { forwardRef } from 'react';

import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCompanyUsers } from 'src/libs/finbits/Organization/Users';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { User } from 'src/libs/finbits/Organization/Users/types';

import AssigneeChip from 'src/features/assignee/AssigneeChip';

type Props = {
  bill?: BillPayable;
} & Omit<SelectProps<User, boolean, false, false>, 'options' | 'defaultValue'>;

function AssigneeSelect(
  { bill, disableCloseOnSelect = true, ...rest }: Props,
  ref: Ref<HTMLDivElement>
) {
  const { companyId, organizationId } = useCompanyParams();

  const { users, isLoading } = useCompanyUsers(
    {
      companyId,
      organizationId,
      onlyAvailableApprovers: true,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  return (
    <Select<User, boolean>
      disableCloseOnSelect={disableCloseOnSelect}
      aria-label="aprovadores"
      placeholder="Selecione os aprovadores"
      {...rest}
      ref={ref}
      openOnFocus
      options={users}
      loading={isLoading}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderTags={(assignees, getTagProps) => {
        if (!assignees) return null;

        if (!Array.isArray(assignees)) return null;

        return assignees.map((assignee, index) => {
          const tagProps = getTagProps({ index });

          return (
            <AssigneeChip
              assignee={assignee}
              billStatus={bill?.status}
              assignments={bill?.lastApproval?.assignments}
              variant="outlined"
              {...tagProps}
            />
          );
        });
      }}
    />
  );
}

export default forwardRef(AssigneeSelect);
