import { Chip, Typography } from 'src/design-system/components';
import { Stack } from '@mui/material';
import cn from 'classnames';
import CountUp from 'react-countup';
import { AlertTriangleIcon, ClockIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import type { SummaryAccountsBalances } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './TotalSum.module.scss';

type Props = {
  showToolTipPendency?: boolean;
  accountsBalances: SummaryAccountsBalances[];
  description?: string;
  titleToolTipPendency?: string;
  titleToolTipInconsistency?: string;
  totalInconsistencies?: number;
  onClick?: () => void;
};

export default function TotalSum({
  showToolTipPendency = false,
  accountsBalances,
  description = 'Total a pagar',
  titleToolTipPendency = 'Pendência',
  titleToolTipInconsistency = 'inconsistência',
  totalInconsistencies,
  onClick,
}: Props) {
  const total = accountsBalances.reduce((total, current) => {
    const { balance, balanceType } = current.accountBalance;
    const amount = balance !== null ? balance : 0;

    if (balanceType === BalanceType.DEBIT) {
      return total - amount;
    }

    return total + amount;
  }, 0);

  return (
    <Stack alignItems="end" flexGrow="1" order="2">
      <Typography variant="text-xs">{description}</Typography>

      <Typography
        fontWeight="600"
        textTransform="uppercase"
        className={styles.total}
      >
        {showToolTipPendency && !totalInconsistencies && (
          <Tooltip
            title={titleToolTipPendency}
            enterDelay={100}
            placement="top"
          >
            <ClockIcon color="warning" role="img" fontSize="xsmall" />
          </Tooltip>
        )}

        {!!totalInconsistencies && (
          <Tooltip
            title={titleToolTipInconsistency}
            enterDelay={100}
            placement="top"
          >
            <Chip
              onClick={onClick}
              color="warning"
              size="small"
              label={totalInconsistencies}
              icon={<AlertTriangleIcon color="warning" fontSize="xsmall" />}
            />
          </Tooltip>
        )}

        <CountUp
          className={cn({
            [styles.negative]: total < 0,
            [styles.inconsistency]: total > 0 && totalInconsistencies,
          })}
          prefix="R$ "
          end={total / 100}
          decimals={2}
          decimal=","
          separator="."
          duration={0.5}
          preserveValue
          useEasing={false}
          delay={0}
        />
      </Typography>
    </Stack>
  );
}
