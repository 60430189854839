import { useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { TransitionProps } from '@mui/material/transitions';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Stack,
  TextField,
} from '@mui/material';
import {
  Alert,
  AlertTitle,
  Chip,
  Typography,
} from 'src/design-system/components';
import LoadingButton from '@mui/lab/LoadingButton';
import { AlertCircleIcon, CloseIcon, TrashIcon } from 'src/mui/_icons';
import type { PortalProps } from 'src/ui/PortalProvider';

import { FocusIcon, snackbar } from 'src/mui';

import type {
  Account,
  DeleteAccountForm,
  SubmitParamsType,
} from 'src/libs/finbits/Bank/Accounts/types';
import { useAccountDelete } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyUsers } from 'src/libs/finbits/Organization/Users';
import { UserStatus } from 'src/libs/finbits/Organization/Users/types';
import { DeleteAccountResolver } from 'src/libs/finbits/Bank/Accounts/types';
import { INVALID_EMAIL_MESSAGE } from 'src/libs/finbits/resolverValidations';

import styles from './DeleteAccountModal.module.scss';

type Props = {
  organizationId: string;
  companyId: string;
  account: Account;
} & PortalProps;

export default function DeleteAccountModal({
  organizationId,
  companyId,
  onClose,
  account,
  open = true,
  onExit,
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    trigger,
  } = useForm<DeleteAccountForm>({
    defaultValues: {
      accountName: null,
      accountNumber: null,
      isCashAccount: false,
      confirmation: '',
      backupEnabled: false,
      sendTo: [],
    },
    resolver: zodResolver(DeleteAccountResolver),
  });

  const isCashAccount = account?.type === 'cash_account';

  const backupEnabled = watch('backupEnabled');

  useEffect(() => {
    if (!account) return;

    setValue('isCashAccount', isCashAccount);
    setValue('accountName', account.name);
    setValue('accountNumber', account.accountNumber);
  }, [account, isCashAccount, setValue]);

  const { users, isLoading: isUsersLoading } = useCompanyUsers({
    companyId,
    organizationId,
    status: UserStatus.ACTIVE,
  });

  const { deleteAccount, isLoading } = useAccountDelete();

  function onSubmit({ backupEnabled, sendTo }: SubmitParamsType) {
    deleteAccount(
      {
        organizationId,
        companyId,
        accountId: account.id,
        backupEnabled,
        sendTo,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'O processo de deleção da conta foi iniciado!',
          });

          onClose();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao deletar conta!',
          });
        },
      }
    );
  }

  function handleChange(value: string[]) {
    setValue('sendTo', value);
    trigger('sendTo');
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
    const value = e.target.value;

    if (!value) return;

    handleChange([...getValues('sendTo'), value]);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
      aria-labelledby="delete-account-dialog-title"
      aria-describedby="delete-account-dialog-description"
    >
      <IconButton
        aria-label="Fechar modal de deleção de conta"
        onClick={onClose}
        className={styles.close}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        id="delete-account-dialog-title"
        variant="text-lg"
        fontWeight="600"
      >
        <Stack gap={1}>
          <FocusIcon color="error" size="lg">
            <TrashIcon />
          </FocusIcon>
          Excluir conta
          <Typography
            id="delete-account-dialog-description"
            data-testid="delete-account-dialog-description"
            color="text.secondary"
          >
            A exclusão de <strong>{account?.name}</strong> será permanente.
            Recomendamos a realização de uma cópia de segurança. Isso pode levar
            alguns minutos.
          </Typography>
        </Stack>
      </DialogTitle>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={styles.dialogContent}>
          <Alert
            variant="filled"
            severity="default"
            icon={<AlertCircleIcon />}
            className={styles.alert}
          >
            <AlertTitle>
              A exclusão da conta também deleta os seguintes itens relacionados:
            </AlertTitle>
            <List dense sx={{ my: 0, p: 0 }}>
              {[
                'Lançamentos programados',
                'Lançamentos realizados',
                'Anexos',
              ].map((item: string, index: number) => (
                <ListItem key={index} className={styles.alertList}>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 14,
                      display: 'list-item',
                    }}
                    primary={item}
                  />
                </ListItem>
              ))}
            </List>
          </Alert>

          <Box sx={{ my: 8 }}>
            <Typography variant="text-md" component="h3" fontWeight="600">
              Deseja fazer uma cópia de segurança?
            </Typography>

            <Typography color="text.secondary">
              Um link para o download de segurança será enviado para o email dos
              gestores da conta e aos emails abaixo com o conteúdo relacionado à
              conta desde a sua criação.
            </Typography>

            <Controller
              name="backupEnabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  id="has-backup-enabled"
                  control={<Checkbox size="small" />}
                  label="Fazer uma cópia de segurança"
                />
              )}
            />

            <Collapse in={backupEnabled}>
              <Controller
                name="sendTo"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    className={styles.autocomplete}
                    classes={{ endAdornment: styles.clear }}
                    onChange={(_event, value) => handleChange(value)}
                    value={getValues('sendTo')}
                    multiple
                    loading={isUsersLoading}
                    id="tags-outlined"
                    filterSelectedOptions
                    freeSolo
                    clearOnBlur
                    options={users.map((user) => user.email)}
                    renderTags={(options: readonly string[], getTagProps) =>
                      options.map((option: string, index: number) => (
                        <Chip
                          data-testid="email-tag-chip"
                          color={errors.sendTo?.[index] ? 'error' : 'default'}
                          variant="outlined"
                          label={option}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.autocomplete}
                        size="small"
                        data-testid="send-to-text-field"
                        error={!!errors.sendTo}
                        helperText={!!errors.sendTo && INVALID_EMAIL_MESSAGE}
                        onBlur={handleBlur}
                      />
                    )}
                  />
                )}
              />

              <Typography color="text.secondary">
                Selecione os contatos ou digite os emails que deseja enviar a
                cópia de segurança.
              </Typography>
            </Collapse>
          </Box>

          <Controller
            name="confirmation"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="confirmation"
                error={!!errors.confirmation}
                helperText={errors.confirmation?.message}
                label={
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontWeight={600}>
                      Para confirmar a exclusão da conta, digite:
                    </Typography>
                    <Chip
                      size="small"
                      label={
                        isCashAccount ? account?.name : account?.accountNumber
                      }
                    />
                  </Stack>
                }
                variant="outlined"
              />
            )}
          />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <LoadingButton
            color="error"
            loading={isLoading}
            variant="contained"
            type="submit"
          >
            Excluir Conta
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
