import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { Typography } from 'src/design-system/components';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import emptyState from 'src/assets/empty-state-balance.svg';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './InitialBalanceEmptyState.module.scss';

type Props = {
  onClose?: () => void;
};

export default function InitialBalanceEmptyState({ onClose }: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const navigate = useNavigate();

  function redirectToAccountSettings() {
    onClose?.();

    navigate({
      pathname: generateCompanyPath(RouteKey.ACCOUNTS, {
        companyId,
        organizationId,
      }),
    });
  }

  return (
    <Stack className={styles.stack}>
      <img
        srcSet={emptyState}
        src={emptyState}
        alt="Você ainda não adicionou uma conta"
      />

      <Stack direction="column" alignItems="center" gap={2}>
        <Typography fontWeight="bold" variant="text-md">
          Você ainda não adicionou uma conta
        </Typography>

        <Typography align="center">
          Adicione uma ou mais contas bancárias ou não <br />
          bancarizadas para ver o saldo realizado por dia.
        </Typography>
      </Stack>

      <Button
        onClick={redirectToAccountSettings}
        color="primary"
        variant="contained"
      >
        Adicionar conta
      </Button>
    </Stack>
  );
}
