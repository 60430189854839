import { useFormContext } from 'react-hook-form';
import { FormField, Input } from 'src/mui/_scss';

type Props = {
  defaultValue?: string;
};

export default function ClassificationField({ defaultValue = '' }: Props) {
  const { control } = useFormContext();

  return (
    <FormField
      required
      control={control}
      label="Nome da classificação"
      name="name"
      defaultValue={defaultValue}
    >
      {(field) => <Input placeholder="Ex: Centro de custo" {...field} />}
    </FormField>
  );
}
