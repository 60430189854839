import { useState } from 'react';

import { CloseOutlined, LinkOutlined } from '@ant-design/icons';
import type { TablePaginationConfig } from 'antd';
import { Button } from 'antd';
import { Button as MUIButton } from '@mui/material';
import type { SorterResult } from 'antd/lib/table/interface';
import { closeSnackbar } from 'notistack';

import { snackbar, snackbarAction } from 'src/mui';

import type { PortalProps } from 'src/ui';
import {
  Drawer,
  DrawerFooter,
  DrawerHeader,
  Title,
  useOpenPortal,
} from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Receivable } from 'src/libs/finbits/Receivables/types';
import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { addDays, format, subDays } from 'src/libs/finbits/Date';
import { useAvailableEntriesToLinkNotaFiscal } from 'src/libs/finbits/Management/Entries/AvailableToLinkNotaFiscal';
import { useLinkNotaFiscalToEntries } from 'src/libs/finbits/NotaFiscal/LinkToEntries';

import type { FilteredInfo } from 'src/features/nota-fiscal/LinkEntriesTable';
import LinkEntriesTable from 'src/features/nota-fiscal/LinkEntriesTable';
import LinkedEntriesDrawer from 'src/features/nota-fiscal/LinkedEntriesDrawer';

import styles from './LinkEntriesDrawer.module.less';

type Props = {
  notaFiscalId: string;
} & PortalProps;

const SNACKBAR_KEY = 'linkToEntries';

export default function LinkEntriesDrawer({
  notaFiscalId,
  onClose,
  open = true,
  onExit,
}: Props) {
  const openPortal = useOpenPortal();

  const { companyId, organizationId } = useCompanyParams();

  const [filteredInfo, setFilteredInfo] = useState<FilteredInfo>({
    date: [[subDays(new Date(), 7), addDays(new Date(), 7)]],
  });

  const [startDate, endDate] = filteredInfo?.date[0];

  const { receivables, financialEntries, isLoading } =
    useAvailableEntriesToLinkNotaFiscal({
      companyId,
      organizationId,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    });

  const { linkNotaFiscalToEntries, isLoading: isLinking } =
    useLinkNotaFiscalToEntries();

  const [selectedEntries, setSelectedEntries] = useState<
    Array<Receivable | FinancialEntry>
  >([]);

  function handleOpenLinkedEntriesDrawer() {
    openPortal(LinkedEntriesDrawer, {
      notaFiscalId,
    });

    closeSnackbar(SNACKBAR_KEY);
  }

  function handleLinkReceivable() {
    if (selectedEntries.length === 0) {
      return snackbar({
        variant: 'error',
        message: 'Você precisa selecionar pelo menos um recebimento.',
      });
    }

    const selectedEntriesLength = selectedEntries.length;
    const selectedEntriesIds = selectedEntries.map((entry) => entry.id);

    const receivablesIds = selectedEntriesIds.filter((entryId) =>
      receivables.find((receivable) => receivable.id === entryId)
    );

    const financialEntriesIds = selectedEntriesIds.filter((entryId) =>
      financialEntries.find((financialEntry) => financialEntry.id === entryId)
    );

    linkNotaFiscalToEntries(
      {
        companyId,
        organizationId,
        notaFiscalId,
        receivablesIds,
        financialEntriesIds,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            key: SNACKBAR_KEY,
            message: `${pluralize(
              selectedEntriesLength,
              `${selectedEntriesLength} recebimento vinculado`,
              `${selectedEntriesLength} recebimentos vinculados`
            )}`,
            alignItems: 'start',
            customActions: (
              <MUIButton
                size="small"
                color="secondary"
                sx={snackbarAction}
                onClick={handleOpenLinkedEntriesDrawer}
              >
                {pluralize(
                  selectedEntriesLength,
                  'Ver recebimento vinculado',
                  'Ver recebimentos vinculados'
                )}
              </MUIButton>
            ),
          });

          onClose();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao vincular com a nota fiscal',
          });
        },
      }
    );
  }

  function handleClearSelection() {
    setSelectedEntries([]);
  }

  function handleChangeTable(
    _pagination: TablePaginationConfig,
    filters: FilteredInfo,
    _sorter:
      | SorterResult<Receivable | FinancialEntry>
      | SorterResult<Receivable | FinancialEntry>[]
  ) {
    setFilteredInfo(filters);
  }

  return (
    <Drawer
      title={
        <DrawerHeader
          title={
            <Title icon={<LinkOutlined />}>
              Vincular recebimentos com nota fiscal
            </Title>
          }
        />
      }
      footer={null}
      onClose={onClose}
      visible={open}
      afterVisibleChange={onExit}
    >
      <LinkEntriesTable
        loading={isLoading}
        financialEntries={financialEntries}
        receivables={receivables}
        filteredInfo={filteredInfo}
        onChange={handleChangeTable}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedEntries.map((entry) => entry.id),
          onChange: (_, selectedRows) => setSelectedEntries(selectedRows),
        }}
        allowClearDateFilter={false}
      />
      <DrawerFooter>
        {selectedEntries.length > 0 && (
          <div className={styles.selectedEntries}>
            <Button
              title="Excluir selecionados"
              aria-label="Excluir selecionados"
              size="small"
              type="text"
              icon={<CloseOutlined />}
              onClick={handleClearSelection}
            />

            {pluralize(
              selectedEntries.length,
              `${selectedEntries.length} selecionado`,
              `${selectedEntries.length} selecionados`
            )}
          </div>
        )}
        <DrawerFooter.SubmitButton
          onClick={handleLinkReceivable}
          loading={isLinking}
        >
          Vincular
        </DrawerFooter.SubmitButton>
      </DrawerFooter>
    </Drawer>
  );
}
