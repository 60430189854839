import { Chip } from 'src/design-system/components';
import { Box } from '@mui/material';
import type {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { ClockIcon } from 'src/mui/_icons';
import { dateColumn as defaultDateColumn } from 'src/mui/_scss';

import { Tooltip } from 'src/mui';

import { RoutingNumber } from 'src/libs/finbits/Bank/types';
import { SynchronizeType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

export const SYNCHRONIZED_OPTIONS: Record<
  string,
  { label: string; message: string }
> = {
  [SynchronizeType.AUTOMATIC]: {
    label: 'D-1',
    message:
      'Data do último lançamento importado. Lançamentos deste banco podem levar até 24h para serem importados e refletirem no saldo.',
  },
  [SynchronizeType.MANUAL]: {
    label: 'Manual',
    message: 'Saldo realizado conforme atualização manual.',
  },
};

export function dateColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return defaultDateColumn({
    columnProps: {
      headerName: 'Realizado até',
      field: 'accountBalance',
      minWidth: 160,
      flex: 1,
      editable: true,
      aggregable: false,
      maxWidth: 170,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const { pendingInitialBalance, account } = params.row;
        const accountNotChip = [
          RoutingNumber.BTG,
          RoutingNumber.ASAAS,
        ].includes(account?.routingNumber);

        return (
          <>
            {pendingInitialBalance ? (
              <Tooltip
                title="Pendente cadastro de saldo inicial desta conta."
                enterDelay={100}
                placement="top"
              >
                <ClockIcon color="warning" role="img" fontSize="xsmall" />
              </Tooltip>
            ) : (
              <Box component="span" display="flex" alignItems="center" gap={1}>
                {params.formattedValue}

                {account.synchronizeType !== SynchronizeType.SYNC &&
                  !accountNotChip && (
                    <Tooltip
                      title={
                        SYNCHRONIZED_OPTIONS[account.synchronizeType].message
                      }
                      enterDelay={100}
                      placement="top"
                    >
                      <Chip
                        label={
                          SYNCHRONIZED_OPTIONS[account.synchronizeType].label
                        }
                        size="small"
                        variant="outlined"
                        color="secondary"
                      />
                    </Tooltip>
                  )}
              </Box>
            )}
          </>
        );
      },
      valueGetter: (value: { refDate: any }) => value?.refDate,
      ...columnProps,
    },
  });
}
