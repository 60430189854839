import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { singleSelectColumn } from 'src/mui/_scss';

import type { User } from 'src/libs/finbits/Organization/Users/types';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

import RequesterSelect from './RequesterSelect/RequesterSelect';

export function requesterColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return singleSelectColumn({
    columnProps: {
      aggregable: false,
      editable: false,
      headerName: 'Solicitado por',
      field: 'requester',
      valueGetter: (value, row) => row.lastApproval?.requester,
      valueFormatter: (value: User) => value?.name,
      groupingValueGetter: (value, row) => {
        return row.lastApproval?.requester?.name ?? 'Sem solicitante';
      },
      sortComparator: (requesterA, requesterB) => {
        const nameA = requesterA?.name ?? '';
        const nameB = requesterB?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      minWidth: 100,
      ...columnProps,
    },
    SelectComponent: RequesterSelect,
  });
}
