import { useCallback, useEffect, useState } from 'react';

import { Space } from 'antd';
import { Button, Stack } from '@mui/material';
import {
  CheckOutlined,
  DeleteOutlined,
  MergeCellsOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';

import { snackbar, snackbarAction } from 'src/mui';

import type { PortalProps } from 'src/ui';
import {
  ConciliateIcon,
  Drawer,
  DrawerHeader,
  DrawerHeaderButton,
  Loader,
  Title,
  UndoConciliateIcon,
  useOpenPortal,
} from 'src/ui';

import {
  useConciliateFinancialEntry,
  useDeleteFinancialEntry,
  useFinancialEntry,
  useUnconciliateFinancialEntry,
  useUpdateFinancialEntry,
} from 'src/libs/finbits/Management/FinancialEntries';
import { useDeleteIgnoredRecord } from 'src/libs/finbits/Management/IgnoredRecords';
import { entryText } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import analytics from 'src/libs/analytics';
import type { ApiErrorForm } from 'src/libs/finbits/client';
import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import EntryForm from 'src/features/EntryForm';
import {
  financialEntryToFormValues,
  scheduledEntryToFormValues,
} from 'src/features/EntryForm/formValues';
import { IgnoredRecordsDrawer } from 'src/features/entries/IgnoredRecordsDrawer';
import type {
  EntryFormDisabledFields,
  EntryFormHiddenActions,
  EntryFormHiddenFields,
  FormValues,
  SubmitParams,
} from 'src/features/EntryForm/types';
import SplitForm from 'src/features/entries/Drawers/SplitForm';
import ConciliationDrawer from 'src/features/conciliations/ConciliationWithSuggestionDrawer';
import {
  useOpenConfirmDeletionDialog,
  useOpenConfirmLeaveFormDialog,
  useOpenConfirmUndoConciliationDialog,
} from 'src/features/entries/Dialogs';
import UnfilledContextAlert from 'src/features/entries/UnfilledContextAlert';

import styles from './EditFinancialEntryDrawer.module.less';

type Props = {
  financialEntryId: string;
  isSplit?: boolean;
  isSplitCreation?: boolean;
  isConciliation?: boolean;
  hiddenActions?: EntryFormHiddenActions;
} & PortalProps;

function sliceTitle(type?: BalanceType) {
  return `Quebrar ${entryText({
    type,
    objectType: 'financial_entry',
    capitalize: false,
  })}`;
}

const NON_UPDATABLE_FIELDS: EntryFormDisabledFields = [
  'accountId',
  'amount',
  'date',
  'paymentDetails',
];

const HIDDEN_FIELDS: EntryFormHiddenFields = [
  'type',
  'paymentDetails',
  'isRecurrent',
  'approvalType',
  'assigneesIds',
];

const HIDDEN_ACTIONS: EntryFormHiddenActions = [
  'createReceivableWithNotaFiscal',
];

function EditFinancialEntryDrawer({
  financialEntryId,
  onClose,
  isSplit = false,
  isSplitCreation = false,
  isConciliation = false,
  hiddenActions = HIDDEN_ACTIONS,
  open = true,
  onExit,
}: Props) {
  const openPortal = useOpenPortal();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isSliceFormOpen, setSliceFormOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [initialSplits, setInitialSplits] = useState<FormValues[]>([]);
  const [initialNewSplits, setInitialNewSplits] = useState<FormValues[]>([]);
  const [errors, setErrors] = useState<ApiErrorForm>();
  const [isConciliationDrawerVisible, setIsConciliationDrawerVisible] =
    useState(false);
  const { organizationId, companyId } = useCompanyParams();
  const { isLoading, financialEntry, isFetchedAfterMount } = useFinancialEntry({
    organizationId,
    companyId,
    financialEntryId,
  });
  const { updateFinancialEntry, isLoading: isSaving } =
    useUpdateFinancialEntry();
  const { deleteFinancialEntry } = useDeleteFinancialEntry();
  const { deleteIgnoredRecord } = useDeleteIgnoredRecord();

  const openConfirmDeletionDialog = useOpenConfirmDeletionDialog();
  const openConfirmUndoConciliationDialog =
    useOpenConfirmUndoConciliationDialog();
  const openConfirmLeaveFormDialog = useOpenConfirmLeaveFormDialog();

  const { conciliateFinancialEntry, isLoading: isConciliating } =
    useConciliateFinancialEntry();

  const { unconciliateFinancialEntry, isLoading: isUnconciliating } =
    useUnconciliateFinancialEntry();

  const canEditFinancialEntry = useAllowedPermission({
    action: 'update',
    resource: 'financialEntries',
  });

  const isConciliated = !!financialEntry?.scheduledEntry;

  const onUnconciliate = useCallback(() => {
    if (!organizationId || !companyId) return;

    unconciliateFinancialEntry(
      {
        organizationId,
        companyId,
        financialEntryId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'A conciliação foi desfeita com sucesso.',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao desfazer a conciliação.',
          });
        },
      }
    );
  }, [organizationId, companyId, financialEntryId, unconciliateFinancialEntry]);

  const openUnconciliateConfirmation = useCallback(() => {
    return openConfirmUndoConciliationDialog({
      onConfirm: onUnconciliate,
    });
  }, [openConfirmUndoConciliationDialog, onUnconciliate]);

  const handleConciliation = useCallback(() => {
    if (isConciliated && isConciliation) {
      return onClose();
    }

    if (isConciliated) {
      return openUnconciliateConfirmation();
    }

    setIsConciliationDrawerVisible(true);
  }, [isConciliated, openUnconciliateConfirmation, isConciliation, onClose]);

  function createSplitsFromConciliation(
    scheduledEntries: ScheduledStatementEntry[]
  ) {
    const newConciliatedSplits = scheduledEntries.map((scheduledEntry) => ({
      scheduledEntry,
      scheduledEntryId: scheduledEntry.id,
      ...scheduledEntryToFormValues(scheduledEntry, financialEntry?.type),
    }));

    const initialConciliatedSplit = {
      ...newConciliatedSplits[0],
      id: financialEntryId,
    };

    const newConciliatedSplitsWithoutFirst = newConciliatedSplits.slice(1);

    const splits = [
      initialConciliatedSplit,
      ...newConciliatedSplitsWithoutFirst,
    ];

    setSliceFormOpen(true);
    setInitialSplits([initialConciliatedSplit]);
    setInitialNewSplits(newConciliatedSplitsWithoutFirst);

    analytics.track('CashFlow Splits from Conciliation', {
      company_id: companyId,
      splits_count: splits.length,
    });
  }

  function handleConciliate(
    scheduled: ScheduledStatementEntry[] | ScheduledStatementEntry
  ) {
    if (!organizationId || !companyId) return;

    const isNeedCreateSplits = Array.isArray(scheduled) && scheduled.length > 1;

    if (isNeedCreateSplits) {
      createSplitsFromConciliation(scheduled);
      return;
    }

    const scheduledEntry = Array.isArray(scheduled)
      ? scheduled[0]
      : (scheduled as ScheduledStatementEntry);

    const toConciliateParams =
      financialEntry?.type === BalanceType.DEBIT
        ? { billId: scheduledEntry.id }
        : { receivableId: scheduledEntry.id };

    conciliateFinancialEntry(
      {
        organizationId,
        companyId,
        financialEntryId,
        ...toConciliateParams,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'A conciliação foi feita com sucesso.',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao conciliar.',
          });
        },
      }
    );
  }

  async function onSubmit({
    categoryId,
    contactId,
    attachments,
    ...params
  }: SubmitParams) {
    updateFinancialEntry(
      {
        ...params,
        financialEntryId,
        organizationId,
        companyId,
        categoryId: categoryId ?? null,
        contactId: contactId ?? null,
        attachmentsIds: attachments?.map((attachment) => attachment.id),
      },
      {
        onSuccess: () => {
          onClose();
          snackbar({
            variant: 'success',
            message: 'Lançamentos salvos com sucesso!',
          });
        },
        onError: ({ response }) => {
          setErrors(response?.data.errors);

          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar os lançamentos!',
          });
        },
      }
    );
  }

  const openSliceForm = useCallback(() => {
    setSliceFormOpen(true);
    setInitialSplits(
      initialValues ? [{ ...initialValues, description: '', amount: 0 }] : []
    );
    setInitialNewSplits([
      {
        type: initialValues?.type,
        accountId: initialValues?.accountId,
        date: initialValues?.date,
        categoryId: null,
        contactId: null,
      },
    ]);
  }, [initialValues]);

  const onFieldsChange = useCallback(() => {
    setIsFormDirty(true);
  }, []);

  useEffect(() => {
    if (
      !financialEntry ||
      !isFetchedAfterMount ||
      !isSplitCreation ||
      !initialValues
    )
      return;

    openSliceForm();
  }, [
    financialEntry,
    isFetchedAfterMount,
    isSplitCreation,
    initialValues,
    openSliceForm,
  ]);

  useEffect(() => {
    if (!financialEntry || !isFetchedAfterMount || !isConciliation) return;

    handleConciliation();
  }, [financialEntry, isFetchedAfterMount, isConciliation, handleConciliation]);

  useEffect(() => {
    if (!financialEntry || !isFetchedAfterMount) return;

    setInitialValues(financialEntryToFormValues(financialEntry));
  }, [financialEntry, isFetchedAfterMount]);

  const undoSplit = useCallback(() => {
    setSliceFormOpen(false);
    setInitialSplits([]);
    setInitialNewSplits([]);
  }, []);

  function handleDelete() {
    deleteFinancialEntry(
      {
        organizationId,
        companyId,
        financialEntryId,
      },
      {
        onSuccess: ({ id }) => {
          snackbar({
            variant: 'success',
            title: 'Lançamento excluído!',
            alignItems: 'start',
            customActions: (
              <Stack direction="row" spacing={2}>
                <Button
                  aria-label="Desfazer"
                  size="small"
                  className={styles.button}
                  color="secondary"
                  sx={snackbarAction}
                  onClick={() => onHandleUndoDelete(id)}
                >
                  Desfazer
                </Button>
                <Button
                  aria-label="Ver excluídos"
                  size="small"
                  color="secondary"
                  sx={snackbarAction}
                  onClick={() => {
                    openPortal(IgnoredRecordsDrawer);
                  }}
                >
                  Ver excluídos
                </Button>
              </Stack>
            ),
          });

          onClose();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao excluir lançamento!',
          });
        },
      }
    );
  }

  function onHandleUndoDelete(ignoredRecordId: string) {
    if (!companyId || !organizationId) return;

    deleteIgnoredRecord(
      { companyId, organizationId, ignoredRecordId },
      {
        onSuccess: () => {
          snackbar({ variant: 'success', message: 'Exclusão desfeita!' });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao desfazer exclusão!',
          });
        },
      }
    );
  }

  function openDeleteConfirmation() {
    return openConfirmDeletionDialog({
      onConfirm: handleDelete,
    });
  }

  function handleCloseDrawer() {
    if (isFormDirty) {
      return openConfirmLeaveFormDialog({
        onConfirm: onClose,
      });
    }

    return onClose();
  }

  return (
    <Drawer
      title={
        <DrawerHeader
          title={
            <Title icon={<CheckOutlined />}>
              {isSliceFormOpen
                ? sliceTitle(financialEntry?.type)
                : entryText({
                    type: financialEntry?.type,
                    objectType: 'financial_entry',
                  })}
            </Title>
          }
          extra={
            canEditFinancialEntry ? (
              <>
                {isSliceFormOpen ? (
                  <DrawerHeaderButton
                    icon={<MergeCellsOutlined />}
                    title="Desfazer quebra"
                    aria-label="Desfazer quebra"
                    onClick={undoSplit}
                  />
                ) : (
                  <>
                    <DrawerHeaderButton
                      icon={
                        isConciliated ? (
                          <UndoConciliateIcon />
                        ) : (
                          <ConciliateIcon />
                        )
                      }
                      aria-label={
                        isConciliated ? 'Desfazer conciliação' : 'Conciliar'
                      }
                      title={
                        isConciliated ? 'Desfazer conciliação' : 'Conciliar'
                      }
                      onClick={handleConciliation}
                    />
                    {!isSplit && (
                      <>
                        <DrawerHeaderButton
                          icon={<SplitCellsOutlined />}
                          title="Quebrar lançamento"
                          aria-label="Quebrar lançamento"
                          onClick={openSliceForm}
                        />
                        <DrawerHeaderButton
                          icon={<DeleteOutlined />}
                          title="Excluir"
                          onClick={openDeleteConfirmation}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : undefined
          }
        />
      }
      footer={null}
      onClose={handleCloseDrawer}
      visible={open}
      afterVisibleChange={onExit}
    >
      {isConciliationDrawerVisible && (
        <ConciliationDrawer
          multiple={!isSplit}
          organizationId={organizationId}
          companyId={companyId}
          financialEntryId={financialEntryId}
          type={financialEntry!.type}
          onConciliate={handleConciliate}
          onClose={() => setIsConciliationDrawerVisible(false)}
        />
      )}

      {isLoading ||
      isConciliating ||
      isUnconciliating ||
      !isFetchedAfterMount ||
      !initialValues ? (
        <Loader size="small" forceCentered />
      ) : isSliceFormOpen && !!financialEntry ? (
        <SplitForm
          onClose={onClose}
          organizationId={organizationId}
          companyId={companyId}
          onFieldsChange={onFieldsChange}
          initialValues={{
            update: initialSplits,
            create: initialNewSplits,
            delete: [],
            originDescription: initialValues.originDescription,
            accountId: initialValues.accountId,
            date: initialValues.date,
            amount: initialValues.amount,
          }}
          type={initialValues.type}
          transactionId={financialEntry.transactionId}
          disabled={!canEditFinancialEntry}
        />
      ) : (
        <Space direction="vertical" size="large">
          <UnfilledContextAlert
            entry={financialEntry}
            entryType={EntryType.FINANCIAL_ENTRY}
          />
          <EntryForm
            isFormDirty={isFormDirty}
            onFieldsChange={onFieldsChange}
            organizationId={organizationId}
            companyId={companyId}
            initialValues={initialValues}
            onSubmit={onSubmit}
            loading={isSaving}
            disabled={!canEditFinancialEntry}
            disabledFields={NON_UPDATABLE_FIELDS}
            hiddenFields={HIDDEN_FIELDS}
            hiddenActions={hiddenActions}
            isFinancialEntry
            errors={errors}
          />
        </Space>
      )}
    </Drawer>
  );
}

export default EditFinancialEntryDrawer;
