import { useCallback, useEffect, useMemo, useState } from 'react';

import { Form } from 'antd';
import { Collapse } from '@mui/material';

import { snackbar } from 'src/mui';

import { FormItem, MaskedInput, MaskedSearch } from 'src/ui';

import { useBanks } from 'src/libs/finbits/Bank';
import {
  boletoBancarioMask,
  boletoConsumoMask,
} from 'src/libs/finbits/Boletos/masks';
import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import { useCreateBoleto } from 'src/libs/finbits/Boletos';
import { useOptionalCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCompanyListener } from 'src/libs/finbits/Channels';
import { isAfter, parseISO } from 'src/libs/finbits/Date';
import type { Boleto } from 'src/libs/finbits/Boletos/types';

import BoletoAlert from 'src/features/payments/BoletoAlert';
import CopyFieldButton from 'src/features/payments/CopyFieldButton';

type Props = {
  disabled?: boolean;
  fieldName: string | string[];
  requiredFields?: Array<keyof PaymentDetails> | string[];
  boletoSearchEnabled?: boolean;
};

const BOLETO_BANKING_SIZE = 47;
const BOLETO_CONSUMPTION_SIZE = 48;

export default function BoletoField({
  disabled,
  fieldName,
  requiredFields = [],
  boletoSearchEnabled = false,
}: Props) {
  const form = Form.useFormInstance();
  const digitableLine = Form.useWatch(fieldName);
  const boleto = Form.useWatch(['paymentDetails', 'boleto']);

  const [showAlert, setShowAlert] = useState(Boolean(boleto?.id));

  useEffect(() => {
    setShowAlert(Boolean(boleto?.id));
  }, [boleto]);

  const { data: banks } = useBanks();
  const { createBoleto, isLoading } = useCreateBoleto();

  const { companyId, organizationId } = useOptionalCompanyParams();

  const routingNumbers = useMemo(
    () => banks?.map((bank) => bank.routingNumber),
    [banks]
  );

  const masks = useMemo(() => {
    const hasRoutingNumber =
      routingNumbers &&
      routingNumbers?.includes(digitableLine?.substring(0, 3));

    const masksToUse =
      hasRoutingNumber || digitableLine?.length === BOLETO_BANKING_SIZE
        ? [boletoBancarioMask, boletoConsumoMask]
        : [boletoConsumoMask, boletoBancarioMask];

    return masksToUse;
  }, [digitableLine, routingNumbers]);

  async function handleSearch(digitableLine: string) {
    setShowAlert(false);

    if (!boletoSearchEnabled) return;

    try {
      await form.validateFields([fieldName]);

      createBoleto(
        {
          organizationId,
          companyId,
          digitableLine,
        },
        {
          onError: () => {
            snackbar({
              variant: 'error',
              message: 'Ocorreu um erro ao buscar dados do boleto.',
            });

            form.setFields([
              {
                name: fieldName,
                errors: ['Linha digitável inválida'],
              },
            ]);
            form.setFieldValue(['paymentDetails', 'boleto', 'id'], '');
          },
          onSuccess: (createdBoleto) => {
            form.setFieldValue(['paymentDetails', 'boleto'], createdBoleto);

            setShowAlert(true);
          },
        }
      );
    } catch {
      form.setFieldValue(['paymentDetails', 'boleto', 'id'], '');
    }
  }

  const [socketBoleto, setSocketBoleto] = useState<Boleto>();
  const listener = useCallback(
    (boleto: Boleto) => {
      boleto && setSocketBoleto(boleto);
    },
    [setSocketBoleto]
  );
  useCompanyListener('boleto_updated', listener);

  const boletoUpdatedAt = boleto?.updatedAt;
  const boletoId = boleto?.id;
  useEffect(() => {
    if (!socketBoleto) return;

    if (
      isAfter(parseISO(socketBoleto.updatedAt), parseISO(boletoUpdatedAt)) &&
      boletoId === socketBoleto.id
    ) {
      form.setFieldValue(['paymentDetails', 'boleto'], socketBoleto);
    }
  }, [form, boletoUpdatedAt, boletoId, socketBoleto]);

  const MaskedField = boletoSearchEnabled ? MaskedSearch : MaskedInput;
  const searchFieldProps = boletoSearchEnabled
    ? { onSearch: handleSearch, loading: isLoading }
    : undefined;

  const hasErrorOnBoleto = Boolean(
    form.getFieldError(['paymentDetails', 'boleto', 'id']).length
  );

  return (
    <CopyFieldButton valueToCopy={digitableLine ?? ''}>
      <FormItem
        name={['paymentDetails', 'boleto', 'id']}
        hasMax={false}
        required={requiredFields.includes('boleto') && boletoSearchEnabled}
        hidden
      >
        <input value="" />
      </FormItem>

      <FormItem
        name={['paymentDetails', 'boleto', 'amount']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'bankName']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'discount']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'dueDate']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'fine']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'interest']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'payeeDocument']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'payeeName']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'payeeTradeName']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'routingNumber']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'segment']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'status']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'type']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>
      <FormItem
        name={['paymentDetails', 'boleto', 'completedAt']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>

      <FormItem
        name={['paymentDetails', 'boleto', 'updatedAt']}
        hidden
        hasMax={false}
      >
        <input value="" />
      </FormItem>

      <FormItem
        name={fieldName}
        label="Linha digitável"
        min={BOLETO_BANKING_SIZE}
        max={BOLETO_CONSUMPTION_SIZE}
        required={requiredFields.includes('boleto')}
        help={hasErrorOnBoleto ? 'Linha digitável inválida' : undefined}
        validateStatus={hasErrorOnBoleto ? 'error' : undefined}
      >
        <MaskedField
          {...searchFieldProps}
          onBlur={(event) => handleSearch(event.target.value)}
          disabled={disabled || isLoading}
          mask={masks}
          placeholder="Insira a linha digitável do boleto"
          size="large"
        />
      </FormItem>

      <Collapse in={showAlert}>
        <BoletoAlert boleto={boleto} />
      </Collapse>
    </CopyFieldButton>
  );
}
