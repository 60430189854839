import { Switch, Tooltip } from 'antd';

import { snackbar } from 'src/mui';

import {
  useActiveClassification,
  useDeactiveClassification,
} from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Classification } from 'src/libs/finbits/Classifications/types';

type Props = {
  classification: Classification;
};

export default function ActiveClassificationSwitch({ classification }: Props) {
  const active = classification.active;

  const { companyId, organizationId } = useCompanyParams();

  const { activeClassification, isLoading: isActivating } =
    useActiveClassification();

  const { deactiveClassification, isLoading: isDeactivating } =
    useDeactiveClassification();

  function handleToogle(checked: boolean) {
    if (checked) {
      activeClassification(
        {
          companyId,
          organizationId,
          classificationId: classification.id,
        },
        {
          onSuccess: () => {
            snackbar({ variant: 'success', message: 'Classificação ativada' });
          },
          onError: () => {
            snackbar({
              variant: 'error',
              message: 'Erro ao ativar classificação',
            });
          },
        }
      );
    } else {
      deactiveClassification(
        {
          companyId,
          organizationId,
          classificationId: classification.id,
        },
        {
          onSuccess: () => {
            snackbar({
              variant: 'success',
              message: 'Classificação desativada',
            });
          },
          onError: () => {
            snackbar({
              variant: 'error',
              message: 'Erro ao desativar classificação',
            });
          },
        }
      );
    }
  }

  return (
    <WithAllowedPermission
      permissions={{ resource: 'classifications', action: 'update' }}
    >
      <Tooltip
        placement="bottom"
        title={`${active ? 'Desativar' : 'Ativar'} classificação`}
      >
        <Switch
          checked={active}
          onChange={handleToogle}
          aria-label={`${active ? 'Desativar' : 'Ativar'} classificação`}
          loading={isActivating || isDeactivating}
        />
      </Tooltip>
    </WithAllowedPermission>
  );
}
