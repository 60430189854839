import t from 'zod';
import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import { invalidateAccountsQueries } from 'src/libs/finbits/Bank/Accounts';
import type { BtgConnection } from 'src/libs/finbits/Bank/Accounts/types';
import { BtgConnectionDecoder } from 'src/libs/finbits/Bank/Accounts/types';

import type {
  CreateBTGConnectionParams,
  CreateBTGConsentmentsParams,
} from './types';

export async function postBTGConnections({
  organizationId,
  consentmentId,
  ...params
}: CreateBTGConnectionParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/btg_consentments/${consentmentId}/connections`,
    params
  );

  return decodeResponse<BtgConnection[]>(
    response,
    t.array(BtgConnectionDecoder)
  );
}

export function useCreateBTGConnections() {
  const queryClient = useQueryClient();
  const { mutate: createBTGConnections, ...rest } = useMutation<
    BtgConnection[],
    ApiError,
    CreateBTGConnectionParams
  >(postBTGConnections, {
    onSuccess: () => {
      invalidateAccountsQueries(queryClient);
    },
  });
  return { createBTGConnections, ...rest };
}

export async function createBTGConsentments({
  organizationId,
  companyId,
  ...params
}: CreateBTGConsentmentsParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/btg_consentments`,
    params
  );
  return decodeResponse<BtgConnection[]>(
    response,
    t.array(BtgConnectionDecoder)
  );
}

export function useCreateBTGConsentments() {
  const { mutate, ...rest } = useMutation<
    BtgConnection[],
    ApiError,
    CreateBTGConsentmentsParams
  >(createBTGConsentments);
  return { createBTGConsentments: mutate, ...rest };
}
