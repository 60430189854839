import type { KeyboardEvent } from 'react';

import get from 'lodash/get';
import cn from 'classnames';
import { Button } from 'antd';
import type { ColumnType } from 'antd/es/table';
import DatePicker from 'src/ui/DatePicker';
import ptBR from 'antd/lib/date-picker/locale/pt_BR';
import type { FilterConfirmProps } from 'antd/lib/table/interface';

import { endOfDay, isWithinInterval, startOfDay } from 'src/libs/finbits/Date';

import styles from './filters.module.less';

type Options = {
  allowClear?: boolean;
  controlledValue?: Date[];
};

function DateFilter<T extends { date?: string | null; insertedAt?: string }>(
  key: 'date' | 'insertedAt' | 'lastApproval.requestedAt',
  { allowClear = true, controlledValue }: Options = {}
): ColumnType<T> {
  function handleEnter(
    e: KeyboardEvent<HTMLDivElement>,
    confirm: (param?: FilterConfirmProps) => void
  ) {
    if (e.code === 'Enter') {
      e.stopPropagation();
      confirm();
    }
  }

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className={styles.wrapper}>
        <DatePicker.RangePicker
          format="DD/MM/yyyy"
          onChange={(dates) => {
            setSelectedKeys([dates as any]);
          }}
          allowClear={false}
          value={
            controlledValue ||
            (selectedKeys[0] as any) || [undefined, undefined]
          }
          locale={ptBR}
          onKeyDown={(e) => handleEnter(e, confirm)}
        />

        <div
          className={cn(styles.actions, {
            [styles.actionsFlexEnd]: !allowClear,
          })}
        >
          {allowClear && (
            <Button
              type="link"
              size="small"
              disabled={Boolean(!selectedKeys.length)}
              onClick={() => {
                clearFilters && clearFilters();
              }}
            >
              Limpar
            </Button>
          )}
          <Button type="primary" size="small" onClick={() => confirm()}>
            Aplicar
          </Button>
        </div>
      </div>
    ),
    onFilter: (filterValue, record) => {
      if (!Array.isArray(filterValue)) return false;

      const date = get(record, key, '');
      const [startDate, endDate] = filterValue as any;

      const parsedStartDate = startOfDay(startDate);
      const parsedEndDate = endOfDay(endDate);

      return isWithinInterval(new Date(date as string), {
        start: parsedStartDate,
        end: parsedEndDate,
      });
    },
  };
}

export default DateFilter;
