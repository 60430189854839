import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { Button } from '@mui/material';
import { closeSnackbar } from 'notistack';
import { useQuery } from 'react-query';

import { snackbar, snackbarAction } from 'src/mui';

import type { ApiError } from 'src/libs/finbits/client';

const SNACKBAR_KEY = 'version_update';

function forceReload() {
  closeSnackbar(SNACKBAR_KEY);
  window.location.reload();
}

async function getVersion(): Promise<AxiosResponse<string>> {
  const response = await axios.get('/VERSION', {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
    responseType: 'text',
  });

  return response;
}

export function useVersionChecker() {
  const currentVersion = window.FINBITS_VERSION;

  const { data, ...rest } = useQuery<AxiosResponse<string>, ApiError>({
    queryKey: ['version'],
    queryFn: () => getVersion(),
    onSuccess: ({ data }) => {
      if (data.trim() !== currentVersion) {
        snackbar({
          variant: 'info',
          key: SNACKBAR_KEY,
          persist: true,
          title: 'Uma nova versão está disponível, Atualize sua página!',
          alignItems: 'start',
          customActions: (
            <Button
              size="small"
              color="secondary"
              sx={snackbarAction}
              onClick={forceReload}
            >
              Atualizar
            </Button>
          ),
        });
      }
    },
    cacheTime: 0,
    refetchInterval: 60000,
  });

  const newVersionAvailable = data && data.data.trim() !== currentVersion;

  return { newVersionAvailable, ...rest };
}
