import { Alert, AlertTitle, Typography } from 'src/design-system/components';

import { format } from 'src/libs/finbits/Date';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

type Props = {
  bill?: BillPayable;
};

export default function ReprovedReasonAlert({ bill }: Props) {
  const reprovedAssignment = bill?.lastApproval?.assignments?.find(
    (assignment) => Boolean(assignment.reprovedAt)
  );

  const formattedReprovedAt = reprovedAssignment?.reprovedAt
    ? format(reprovedAssignment?.reprovedAt, 'dd/MM/yyyy, HH:mm')
    : '';

  return (
    <Alert severity="warning">
      <AlertTitle>
        {reprovedAssignment?.reprovalReason
          ? `Motivo da reprovação: ${reprovedAssignment?.reprovalReason}`
          : 'Nenhum motivo de reprovação'}
      </AlertTitle>
      <Typography>
        {reprovedAssignment?.assignee?.name} em {formattedReprovedAt}
      </Typography>
    </Alert>
  );
}
