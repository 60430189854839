import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import type { ErrorsObject } from 'src/libs/finbits/Form';
import { invalidateCompanyQueries } from 'src/libs/finbits/Organization/Companies/Companies';
import type { Company } from 'src/libs/finbits/Organization/Companies/types';
import { CompanyDecoder } from 'src/libs/finbits/Organization/Companies/types';
import type { NotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection/types';
import { NotaFiscalConnectionDecoder } from 'src/libs/finbits/NotaFiscal/Connection/types';

import type {
  SetupNotaFiscalParams,
  UpdateComplementaryDataParams,
  UpdateDigitalCertificateParams,
  UpdateMonitoringParams,
} from './types';

type Options = {
  onError?: (errors: ApiError<ErrorsObject>) => void;
};

function postSetupNotaFiscal({
  organizationId,
  companyId,
  file,
  password,
  services,
  address,
  ...rest
}: SetupNotaFiscalParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/nota_fiscal/setup`;

  const formData = new FormData();

  Object.entries(rest).forEach(([key, value]) => {
    formData.append(key, String(value));
  });

  if (address) {
    Object.entries(address).forEach(([key, value]) => {
      formData.append(`address[${key}]`, value ?? '');
    });
  }

  if (file && password) {
    formData.append('file', file);
    formData.append('password', password);
  }

  services.forEach((service, index) => {
    formData.append(
      `services[${index}][mainActivity]`,
      String(service.mainActivity)
    );
    formData.append(
      `services[${index}][municipalServiceId]`,
      service.municipalServiceId
    );
    formData.append(
      `services[${index}][suggestedRate]`,
      String(service.suggestedRate)
    );
    formData.append(`services[${index}][description]`, service.description);
  });

  return authenticatedAPI.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

async function patchComplementaryData({
  organizationId,
  companyId,
  simpleNational,
  ...rest
}: UpdateComplementaryDataParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/nota_fiscal/setup`;

  const response = await authenticatedAPI.patch(url, {
    simpleNational: Boolean(simpleNational),
    ...rest,
  });

  return decodeResponse<Company>(response, CompanyDecoder);
}

function updateDigitalCertificate({
  organizationId,
  companyId,
  file,
  password,
}: UpdateDigitalCertificateParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/nota_fiscal/certificates`;

  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }
  formData.append('password', password);

  return authenticatedAPI.put(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function useSetupNotaFiscal({ onError }: Options = {}) {
  const queryClient = useQueryClient();

  const { mutate: setupNotaFiscal, ...rest } = useMutation<
    unknown,
    ApiError<ErrorsObject>,
    SetupNotaFiscalParams
  >({
    mutationFn: postSetupNotaFiscal,
    onError,
    onSuccess: () => {
      invalidateCompanyQueries(queryClient);
    },
  });

  return {
    setupNotaFiscal,
    ...rest,
  };
}

export function useUpdateComplementaryData() {
  const queryClient = useQueryClient();

  const { mutate: updateComplementaryData, ...rest } = useMutation<
    Company,
    ApiError<ErrorsObject>,
    UpdateComplementaryDataParams
  >({
    mutationFn: patchComplementaryData,
    onSuccess: () => {
      invalidateCompanyQueries(queryClient);
    },
  });

  return {
    updateComplementaryData,
    ...rest,
  };
}

export function useUpdateDigitalCertificate() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    unknown,
    ApiError<ErrorsObject>,
    UpdateDigitalCertificateParams
  >({
    mutationFn: updateDigitalCertificate,
    onSuccess: () => {
      invalidateCompanyQueries(queryClient);
    },
  });

  return {
    updateDigitalCertificate: mutate,
    ...rest,
  };
}

async function patchMonitoring({
  organizationId,
  companyId,
  ...params
}: UpdateMonitoringParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/nota_fiscal/monitoring`,
    params
  );

  return decodeResponse<NotaFiscalConnection>(
    response,
    NotaFiscalConnectionDecoder
  );
}

export function useUpdateMonitoring() {
  const queryClient = useQueryClient();

  const { mutate: updateMonitoring, ...rest } = useMutation<
    NotaFiscalConnection,
    ApiError<ErrorsObject>,
    UpdateMonitoringParams
  >({
    mutationFn: patchMonitoring,
    onSuccess: () => {
      invalidateCompanyQueries(queryClient);
    },
  });

  return {
    updateMonitoring,
    ...rest,
  };
}
