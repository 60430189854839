import { useMemo } from 'react';

import {
  CalendarOutlined,
  CheckOutlined,
  CopyOutlined,
  DollarOutlined,
  FileTextOutlined,
  LinkOutlined,
  StopOutlined,
} from '@ant-design/icons';
import compact from 'lodash/compact';
import { TrashIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog, useOpenPortal } from 'src/ui';

import type { NotaFiscal } from 'src/libs/finbits/NotaFiscal/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCancelNotaFiscal } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { useMenuItem } from 'src/libs/finbits/MenuItem/useMenuItem';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import CreateFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/CreateFinancialEntryDrawer/CreateFinancialEntryDrawer';
import CreateScheduledDrawer from 'src/features/scheduled/CreateScheduledDrawer/CreateScheduledDrawer';
import LinkEntriesDrawer from 'src/features/nota-fiscal/LinkEntriesDrawer';
import LinkedEntriesDrawer from 'src/features/nota-fiscal/LinkedEntriesDrawer';
import CreateNotaFiscalDrawer from 'src/features/nota-fiscal/CreateNotaFiscalDrawer';
import type {
  EntryFormHiddenActions,
  EntryFormHiddenFields,
} from 'src/features/EntryForm/types';
import { NotaFiscalStatus } from 'src/features/nota-fiscal/NotaFiscalStatusTag/types';
import { rateFromAmount } from 'src/features/nota-fiscal/CreateNotaFiscalDrawer/Taxes/TaxesCalculator';

type Props = {
  notaFiscal?: NotaFiscal;
};

const HIDDEN_FIELDS: EntryFormHiddenFields = ['type'];

const HIDDEN_ACTIONS: EntryFormHiddenActions = [
  'createNotaFiscalFromReceivable',
  'createReceivableWithNotaFiscal',
];

export function useActionItems({ notaFiscal }: Props) {
  const openPortal = useOpenPortal();
  const openConfirmDialog = useOpenConfirmDialog();

  const { organizationId, companyId } = useCompanyParams();
  const { cancelNotaFiscal } = useCancelNotaFiscal();

  const entries = notaFiscal
    ? [
        ...(notaFiscal.receivables ?? []),
        ...(notaFiscal.financialEntries ?? []),
      ]
    : [];

  const hasLinkedEntry = entries?.length > 0;

  const isNotaFiscalIssued = notaFiscal?.status === NotaFiscalStatus.ISSUED;
  const isNotaFiscalFailed =
    notaFiscal?.status === NotaFiscalStatus.ISSUE_FAILED;

  function handleCancel() {
    if (!companyId || !organizationId || !notaFiscal) return;

    cancelNotaFiscal(
      {
        companyId,
        organizationId,
        notaFiscalId: notaFiscal.id,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Solicitação enviada com sucesso',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao solicitar o cancelamento',
          });
        },
      }
    );
  }

  function openCancellationModal() {
    openConfirmDialog({
      variant: 'error',
      icon: <TrashIcon />,
      title: 'Cancelar nota fiscal',
      content:
        'A solicitação será enviada à prefeitura e não poderá ser desfeita. ' +
        'Certifique-se do cancelamento permanente da nota fiscal.',
      cancelText: 'Cancelar',
      confirmText: 'Solicitar cancelamento',
      onConfirm: handleCancel,
    });
  }

  const createEntryInitialValues = useMemo(() => {
    return {
      contactId: notaFiscal?.contact.id,
      amount: notaFiscal?.amount,
      type: BalanceType.CREDIT,
      notaFiscalId: notaFiscal?.id,
    };
  }, [notaFiscal]);

  const createNotaFiscalInitialValues = useMemo(() => {
    if (!notaFiscal) return undefined;

    const taxes = {
      pisRate: rateFromAmount(notaFiscal.amount, notaFiscal.pisAmount),
      cofinsRate: rateFromAmount(notaFiscal.amount, notaFiscal.cofinsAmount),
      csllRate: rateFromAmount(notaFiscal.amount, notaFiscal.csllAmount),
      irrfRate: rateFromAmount(notaFiscal.amount, notaFiscal.irrfAmount),
      inssRate: rateFromAmount(notaFiscal.amount, notaFiscal.inssAmount),
    };

    return { ...notaFiscal, contactId: notaFiscal?.contact.id, ...taxes };
  }, [notaFiscal]);

  const items = compact([
    useMenuItem({
      menuItem: {
        key: 'pdfUrl',
        label: (
          <a
            href={notaFiscal?.pdfUrl ?? ''}
            target="_blank"
            rel="noreferrer"
            download
          >
            Baixar arquivo PDF
          </a>
        ),
        icon: <FileTextOutlined />,
      },
      enabled: Boolean(notaFiscal?.pdfUrl),
    }),
    useMenuItem({
      menuItem: {
        key: 'xmlUrl',
        label: (
          <a
            href={notaFiscal?.xmlUrl ?? ''}
            target="_blank"
            rel="noreferrer"
            download
          >
            Baixar arquivo XML
          </a>
        ),
        icon: <FileTextOutlined />,
      },
      enabled: Boolean(notaFiscal?.xmlUrl),
    }),
    useMenuItem({
      menuItem: {
        'aria-label': 'Replicar nota fiscal',
        key: 'replicate',
        label: 'Replicar nota fiscal',
        icon: <CopyOutlined />,
        onClick: () =>
          openPortal(CreateNotaFiscalDrawer, {
            initialValues: createNotaFiscalInitialValues,
          }),
      },
      permissions: {
        action: 'create',
        resource: 'notasFiscais',
        scope: 'company',
      },
      enabled: isNotaFiscalIssued,
    }),
    useMenuItem({
      menuItem: {
        'aria-label': 'Cancelar nota fiscal',
        key: 'cancellation',
        label: 'Cancelar nota fiscal',
        icon: <StopOutlined />,
        onClick: openCancellationModal,
      },
      permissions: {
        action: 'delete',
        resource: 'notasFiscais',
        scope: 'company',
      },
      enabled: isNotaFiscalIssued,
    }),
    useMenuItem({
      menuItem: {
        'aria-label': 'Vincular recebimentos',
        key: 'link-receivable',
        label: 'Vincular recebimentos',
        icon: <LinkOutlined />,
        onClick: () =>
          notaFiscal &&
          openPortal(LinkEntriesDrawer, {
            notaFiscalId: notaFiscal.id,
          }),
      },
      permissions: {
        action: 'link_to_entries',
        resource: 'notasFiscais',
        scope: 'company',
      },
      enabled: !isNotaFiscalFailed,
    }),
    useMenuItem({
      menuItem: {
        'aria-label': 'Visualizar recebimentos',
        key: 'show-receivables',
        label: 'Visualizar recebimentos',
        icon: <DollarOutlined />,
        onClick: () =>
          notaFiscal &&
          openPortal(LinkedEntriesDrawer, {
            notaFiscalId: notaFiscal.id,
          }),
      },
      enabled: hasLinkedEntry,
    }),
    useMenuItem({
      menuItem: {
        'aria-label': 'Criar recebimento realizado',
        key: 'create-financial-entry',
        label: 'Criar recebimento realizado',
        icon: <CheckOutlined />,
        onClick: () =>
          openPortal(CreateFinancialEntryDrawer, {
            title: 'Recebimento realizado',
            initialValues: createEntryInitialValues,
            hiddenFields: HIDDEN_FIELDS,
            hiddenActions: HIDDEN_ACTIONS,
          }),
      },
      enabled: !isNotaFiscalFailed,
      permissions: {
        action: 'create',
        resource: 'financialEntries',
        scope: 'company',
      },
    }),
    useMenuItem({
      menuItem: {
        'aria-label': 'Criar recebimento programado',
        key: 'create-scheduled-entry',
        label: 'Criar recebimento programado',
        icon: <CalendarOutlined />,
        onClick: () =>
          openPortal(CreateScheduledDrawer, {
            title: 'Recebimento programado',
            initialValues: createEntryInitialValues,
            hiddenFields: HIDDEN_FIELDS,
            hiddenActions: HIDDEN_ACTIONS,
          }),
      },
      enabled: !isNotaFiscalFailed,
      permissions: {
        action: 'create',
        resource: 'receivables',
        scope: 'company',
      },
    }),
  ]);

  return items;
}
