import { useState } from 'react';

import { AlertCircleIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import {
  Drawer,
  DrawerHeader,
  PaymentIcon,
  Title,
  useOpenConfirmDialog,
} from 'src/ui';

import type { ApiErrorForm } from 'src/libs/finbits/client';
import { isStructuredFormError } from 'src/libs/finbits/Form';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCreateNotaFiscal } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import type { NotaFiscal, PostParams } from 'src/libs/finbits/NotaFiscal/types';

import CreateNotaFiscalForm from 'src/features/nota-fiscal/CreateNotaFiscalDrawer/CreateNotaFiscalForm';

import Confirmation from './Confirmation';
import styles from './CreateNotaFiscalDrawer.module.less';

type Props = {
  initialValues?: Partial<PostParams>;
  onCreate?: (notaFiscal: NotaFiscal) => void;
} & PortalProps;

export default function CreateNotaFiscalDrawer({
  initialValues,
  onClose,
  onCreate,
  open = true,
  onExit,
}: Props) {
  const [errors, setErros] = useState<ApiErrorForm>();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const openConfirmDialog = useOpenConfirmDialog();

  const {
    createNotaFiscal,
    isLoading: isCreating,
    isSuccess,
  } = useCreateNotaFiscal();
  const { companyId, organizationId } = useCompanyParams();

  function handleCreateNotaFiscal({
    isSendEmailToContact,
    recipientsEmails,
    ...params
  }: PostParams) {
    if (!organizationId || !companyId) return;

    createNotaFiscal(
      {
        ...params,
        organizationId,
        companyId,
        recipientsEmails: isSendEmailToContact ? recipientsEmails : [],
      },
      {
        onSuccess: (notaFiscal) => {
          setIsFormDirty(false);

          onCreate && onCreate(notaFiscal);
        },
        onError: ({ response }) => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao emitir Nota Fiscal!',
          });

          if (isStructuredFormError(response?.data)) {
            setErros(response?.data.errors as ApiErrorForm);
          }
        },
      }
    );
  }

  function handleCloseDrawer() {
    if (!isFormDirty) {
      return onClose();
    }

    return openConfirmDialog({
      variant: 'error',
      icon: <AlertCircleIcon />,
      title: 'Emissão de nota fiscal',
      content:
        'Tem certeza que deseja sair sem concluir a emissão da nota fiscal?',
      cancelText: 'Cancelar',
      confirmText: 'Sim, quero sair',
      onConfirm: onClose,
    });
  }

  const hasLinked =
    !!initialValues?.receivableId || !!initialValues?.financialEntryId;

  return (
    <Drawer
      title={
        <DrawerHeader
          title={
            <Title icon={<PaymentIcon />}>Emitir nota fiscal de serviço</Title>
          }
        />
      }
      className={styles.drawer}
      footer={null}
      onClose={handleCloseDrawer}
      visible={open}
      afterVisibleChange={onExit}
    >
      {isSuccess ? (
        <Confirmation onClose={onClose} hasLinked={hasLinked} />
      ) : (
        <CreateNotaFiscalForm
          errors={errors}
          isCreating={isCreating}
          initialValues={initialValues}
          onFinish={handleCreateNotaFiscal}
          onFieldsChange={() => setIsFormDirty(true)}
        />
      )}
    </Drawer>
  );
}
