import { useState } from 'react';

import type {
  DateRange,
  DateRangePickerSlotsComponentsProps,
} from '@mui/x-date-pickers-pro';
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import debounce from 'lodash/debounce';
import {
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns';
import { CalendarIcon } from 'src/mui/_icons';

import { isValid } from 'src/libs/finbits/Date';

const changeDateWithDebounce = debounce(
  (range: DateRange<Date>, setRange: any) => setRange(range),
  800
);

type Props = {
  selectedDateRange: [Date, Date];
  setSelectedDateRange: (dates: [Date, Date]) => void;
};

export function ToolbarDatePicker({
  selectedDateRange,
  setSelectedDateRange,
}: Props) {
  const [value, setValue] = useState(selectedDateRange);

  function handleDateChange(dateRange: DateRange<Date>) {
    const [startDate, endDate] = dateRange;
    if (!!startDate && !!endDate && isValid(startDate) && isValid(endDate)) {
      setValue([startDate, endDate]);
      changeDateWithDebounce([startDate, endDate], setSelectedDateRange);
    }
  }

  const slotProps: DateRangePickerSlotsComponentsProps<Date> = {
    popper: { placement: 'bottom-start' },
    textField: {
      variant: 'standard',
      InputProps: { startAdornment: <CalendarIcon /> },
      sx: { width: '230px' },
    },
    shortcuts: {
      items: [
        {
          label: 'Hoje',
          getValue: () => {
            const today = new Date();
            return [startOfDay(today), endOfDay(today)];
          },
        },
        {
          label: 'Semana atual',
          getValue: () => {
            const today = new Date();
            return [startOfWeek(today), endOfWeek(today)];
          },
        },
        {
          label: 'Semana seguinte',
          getValue: () => {
            const base = addWeeks(selectedDateRange[0], 1);
            return [startOfWeek(base), endOfWeek(base)];
          },
        },
        {
          label: 'Semana anterior',
          getValue: () => {
            const base = subWeeks(selectedDateRange[0], 1);
            return [startOfWeek(base), endOfWeek(base)];
          },
        },
        {
          label: 'Mês atual',
          getValue: () => {
            const today = new Date();
            return [startOfMonth(today), endOfMonth(today)];
          },
        },
        {
          label: 'Mês seguinte',
          getValue: () => {
            const base = addMonths(selectedDateRange[0], 1);
            return [startOfMonth(base), endOfMonth(base)];
          },
        },
        {
          label: 'Mês anterior',
          getValue: () => {
            const base = subMonths(selectedDateRange[0], 1);
            return [startOfMonth(base), endOfMonth(base)];
          },
        },
      ],
    },
  };

  return (
    <DateRangePicker
      slots={{ field: SingleInputDateRangeField }}
      value={value}
      onChange={handleDateChange}
      format="dd/MM/yyyy"
      slotProps={slotProps}
      formatDensity="dense"
    />
  );
}
