import { useEffect, useState } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  ToggleButtonGroup,
} from '@mui/material';
import { AlertTriangleIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog, useOpenPortal } from 'src/ui';

import {
  BillsApprovalsType,
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { useUpdateCompanyEnabledBillsApprovals } from 'src/libs/finbits/Organization/Companies/Options';
import { useHasApprovalRules } from 'src/libs/finbits/ApprovalRules/ApprovalRules';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import ModeButton from './ModeButton';
import CreateDefaultRuleModal from './CreateDefaultRuleModal';
import styles from './SelectApprovalModeCard.module.scss';

export default function SelectApprovalModeCard() {
  const { updateCompanyEnabledBillsApprovals } =
    useUpdateCompanyEnabledBillsApprovals();

  const { isEnabled: isApprovalPageEnabled } = useExternalFeatureFlag(
    Feature.APPROVAL_RULES_PAGE
  );

  const openConfirmDialog = useOpenConfirmDialog();
  const openPortal = useOpenPortal();
  const [mode, setMode] = useState(BillsApprovalsType.DISABLED);

  const { companyId, organizationId } = useCompanyParams();

  const { hasApprovalRules } = useHasApprovalRules({
    organizationId,
    companyId,
  });
  const { company } = useCompany({ organizationId, companyId });
  const isAllowedUpdateConfig = useAllowedPermission({
    scope: 'company',
    action: 'update_bills_approvals',
    resource: 'companiesOptions',
  });

  const currentApprovalRule =
    company?.billsApprovals ?? BillsApprovalsType.DISABLED;

  useEffect(() => {
    setMode(currentApprovalRule);
  }, [currentApprovalRule]);

  function updateConfig(newApprovalRuleValue: BillsApprovalsType) {
    updateCompanyEnabledBillsApprovals(
      {
        companyId,
        organizationId,
        billsApprovals: newApprovalRuleValue,
      },
      {
        onSuccess: () => {
          setMode(newApprovalRuleValue);
          snackbar({
            variant: 'success',
            message: `O fluxo de aprovação alterado para ${
              newApprovalRuleValue === BillsApprovalsType.AUTOMATIC
                ? 'automático'
                : 'manual'
            }.`,
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar as alterações!',
          });
        },
      }
    );
  }

  function handleChange(
    _event: React.MouseEvent<HTMLElement>,
    value: BillsApprovalsType
  ) {
    if (value === null) return;

    if (value === BillsApprovalsType.MANUAL) {
      openConfirmDialog({
        variant: 'error',
        icon: <AlertTriangleIcon />,
        title: 'Confirmar alteração para modo manual?',
        content: (
          <>
            <Typography color="grey.600" marginTop={4}>
              As regras de aprovação criadas continuarão salvas mas serão
              desativadas.
            </Typography>
            <Typography color="grey.600" marginTop={4}>
              A atribuição dos aprovadores desta empresa voltará a ser feita de
              forma manual.
            </Typography>
          </>
        ),
        confirmText: 'Confirmar',
        cancelText: 'Voltar',
        onConfirm: () => updateConfig(value),
      });

      return;
    }

    if (value === BillsApprovalsType.AUTOMATIC && !hasApprovalRules) {
      openPortal(CreateDefaultRuleModal, {
        onConfirm: () => setMode(value),
      });

      return;
    }

    updateConfig(value);
    return;
  }

  const isDisabled = currentApprovalRule === BillsApprovalsType.DISABLED;

  return (
    <Card variant="outlined" className={styles.card}>
      <CardHeader
        title="2. Escolha o modo de atribuição de aprovadores"
        titleTypographyProps={{ variant: 'text-md', fontWeight: '500' }}
        className={styles.cardHeader}
      />
      <CardContent className={styles.cardContent}>
        <ToggleButtonGroup
          aria-label="regras"
          orientation="vertical"
          value={mode}
          disabled={!isAllowedUpdateConfig}
          exclusive
          onChange={handleChange}
          fullWidth
          size="large"
          className={styles.buttonGroup}
        >
          <ModeButton
            value={BillsApprovalsType.DISABLED}
            title="Não atribuir"
            description="O fluxo de aprovação está desligado."
            tip="Para deixar de atribuir aprovadores a pagamentos, desative o fluxo de aprovação."
            disabled={!isDisabled}
          />

          <ModeButton
            value={BillsApprovalsType.MANUAL}
            title="Manual (padrão)"
            description="Os aprovadores devem ser informados manualmente em cada lançamento criado."
            tip="Para alterar o modo de atribuição de aprovadores, por favor, ative o fluxo de aprovação."
            disabled={isDisabled}
          />

          {isApprovalPageEnabled && (
            <ModeButton
              value={BillsApprovalsType.AUTOMATIC}
              title="Automatizado por regras de aprovação"
              description="Crie regras de aprovação para atribuir aprovadores de forma automática."
              tip="Para alterar o modo de atribuição de aprovadores, por favor, ative o fluxo de aprovação."
              disabled={isDisabled}
            />
          )}
        </ToggleButtonGroup>
      </CardContent>
    </Card>
  );
}
