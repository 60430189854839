import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import { masker } from 'src/libs/finbits/Masker';
import { cnpjMask } from 'src/libs/finbits/Documents';

import { AddressDecoder } from './types';
import type { Address, GetParams } from './types';

export const cepMask = '99999-999';

async function getAddress({ postalCode }: GetParams) {
  const response = await authenticatedAPI.get(`/addresses/${postalCode}`);

  return decodeResponse<Address>(response, AddressDecoder);
}

type QueryOpts = {
  onSuccess?: (entity: Address) => void;
  retry?: boolean;
};

export function useAddress(
  { postalCode }: Partial<GetParams>,
  { onSuccess, retry }: QueryOpts = {}
) {
  const { data, ...rest } = useQuery<Address, ApiError>({
    queryKey: ['addresses', { postalCode }],
    queryFn: () => getAddress({ postalCode: postalCode! }),
    retry,
    onSuccess,
    enabled: !!postalCode,
  });

  return { address: data, ...rest };
}

export function addressPostalCodeValidator(
  _rule: unknown,
  value?: string | null
) {
  if (value && value.length !== 8) {
    return Promise.reject('Deve ter 8 caracteres');
  }

  return Promise.resolve();
}

type FormatAddressWithDocument = {
  document?: string | null;
  cityName?: string | null;
} & Partial<Address>;

export function formatAddressWithDocument({
  document,
  cityName,
  ...address
}: FormatAddressWithDocument) {
  const formattedDocument = document
    ? `CNPJ ${masker(document, cnpjMask)}`
    : null;

  if (!address) return formattedDocument;

  const postalCode = address?.postalCode
    ? masker(address?.postalCode, cepMask)
    : null;

  const arrayWithAddress = [
    address?.number,
    address?.details,
    address?.district,
    cityName,
    address?.state,
    postalCode,
    formattedDocument,
  ]
    .filter((value) => !!value)
    .join(' - ');

  return [address?.street, arrayWithAddress]
    .filter((value) => !!value)
    .join(', ');
}
