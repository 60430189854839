import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { FileQuestion } from 'src/mui/_icons';

import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import styles from './UnfilledContextAlert.module.scss';

type Props = {
  entry?: Pick<FinancialStatementEntry, 'unfilledContextFields'>;
  entryType: EntryType;
};

const FIELDS_DICTIONARY: Record<string, string> = {
  attachments: 'Anexos',
  category_id: 'Categoria',
  contact_id: 'Contato',
  assignees: 'Aprovadores',
  relevant_date: 'Competência',
};

export default function UnfilledContextAlert({ entry, entryType }: Props) {
  const hasUnfilledContext = Boolean(entry?.unfilledContextFields?.length);

  if (!hasUnfilledContext) return null;

  return (
    <Alert severity="warning" icon={<FileQuestion />}>
      <AlertTitle>
        Por favor, verifique as informações pendentes deste lançamento
      </AlertTitle>
      {entryType === EntryType.BILL && (
        <Typography>
          Para prosseguir com este pagamento, é necessário informar:
        </Typography>
      )}
      <ul className={styles.list}>
        {entry?.unfilledContextFields?.map((field, index) => {
          return (
            <li key={index} aria-label={FIELDS_DICTIONARY[field]}>
              {FIELDS_DICTIONARY[field]}
            </li>
          );
        })}
      </ul>
    </Alert>
  );
}
