import type { ReactNode } from 'react';

import cn from 'classnames';

import styles from './HelperText.module.scss';

type Props = {
  error?: boolean;
  children: ReactNode;
};

export default function HelperText({ error = false, children }: Props) {
  return (
    <small
      className={cn(styles.helperText, {
        [styles.error]: error,
      })}
    >
      {children}
    </small>
  );
}
