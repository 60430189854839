import type { ColumnType } from 'antd/es/table';
import sortBy from 'lodash/sortBy';

import { FilterTree } from 'src/ui';

import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';

type Params = {
  contacts: Contact[];
};

export default function ContactFilter<T extends { contactId?: string | null }>({
  contacts,
}: Params): ColumnType<T> {
  const filters = sortBy(contacts, (contact) =>
    contact.nickname.toLowerCase()
  ).map((contact) => {
    return {
      text: contact.nickname,
      value: contact.id,
    };
  });

  return {
    filterMode: 'tree',
    filters,
    filterDropdown: (props) => <FilterTree {...props} />,
    onFilter: (value, record) => record?.contactId === value,
  };
}
