import t from 'zod';

export const StateDecoder = t.object({
  code: t.string(),
  name: t.string(),
});

export const StatesDecoder = t.array(StateDecoder);

export type State = t.TypeOf<typeof StateDecoder>;
