import { Dialog, DialogTitle, IconButton, Slide } from '@mui/material';
import { CloseIcon } from 'src/mui/_icons';
import type { TransitionProps } from '@mui/material/transitions';

import type { PortalProps } from 'src/ui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { WaitingPaymentParams } from 'src/libs/finbits/PaymentsFlow/types';

import styles from './PaymentReviewModal.module.scss';
import PaymentReviewModalContent from './PaymentReviewModalContent';

type Props = {
  onConfirm: (params: WaitingPaymentParams) => void;
  selecteds: BillPayable[];
} & PortalProps;

export default function PaymentReviewModal({
  onClose,
  onConfirm,
  open = true,
  selecteds,
  onExit,
}: Props) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="modal-title"
      disableEnforceFocus
    >
      <DialogTitle
        id="review-payment-dialog-title"
        variant="text-lg"
        fontWeight="600"
        fontSize={16}
      >
        Pagamento Programado
      </DialogTitle>
      <IconButton
        aria-label="Fechar modal"
        onClick={onClose}
        className={styles.closeIcon}
      >
        <CloseIcon />
      </IconButton>

      <PaymentReviewModalContent
        selecteds={selecteds}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </Dialog>
  );
}
