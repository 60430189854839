import { UndoApprovedIcon } from 'src/mui/_icons';

import { BillStatus } from 'src/libs/finbits/Bills/types';

import UndoActionButton from 'src/features/bills-to-pay/UndoActionButton';

import SendToPaymentButton from '../buttons/SendToPaymentButton';
import type { Action } from '../types';

export default function ApprovedActions({ selecteds, onSuccess }: Action) {
  return (
    <>
      <UndoActionButton
        Icon={UndoApprovedIcon}
        status={BillStatus.APPROVED}
        bills={selecteds}
        onSuccess={onSuccess}
      />
      <SendToPaymentButton selecteds={selecteds} onSuccess={onSuccess} />
    </>
  );
}
