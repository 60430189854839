import type { ButtonProps } from 'antd';
import { Button, Form } from 'antd';

import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import analytics from 'src/libs/analytics';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import type { SubmitParams } from 'src/features/EntryForm/types';

type Props = {
  contact?: Contact;
  onCreateReceivable?: (params: SubmitParams) => void;
} & ButtonProps;

export default function CreateReceivableWithNotaFiscalButton({
  contact,
  onCreateReceivable,
  ...rest
}: Props) {
  const form = Form.useFormInstance();
  const { companyId } = useCompanyParams();

  const { status: connectionStatus } = useNotaFiscalConnection();

  async function handleCreateReceivableWithNotaFiscal() {
    analytics.track(
      'Entry Drawer Create Receivable and Issue Invoice Button Clicked',
      {
        company_id: companyId,
      }
    );

    if (contact && contact?.type !== 'legal') {
      form.setFields([
        {
          name: 'contactId',
          errors: ['O contato deve ser nacional e pessoa jurídica.'],
        },
      ]);
      return;
    }

    try {
      await form.validateFields();

      const params = form.getFieldsValue();

      if (onCreateReceivable) {
        onCreateReceivable(params);
      }
    } catch {}
  }

  const isConnectionActive = connectionStatus === 'active';

  if (!isConnectionActive) {
    return null;
  }

  return (
    <WithAllowedPermission
      permissions={{
        action: 'create',
        resource: 'notasFiscais',
      }}
    >
      <Button
        size="large"
        onClick={handleCreateReceivableWithNotaFiscal}
        {...rest}
      >
        Salvar e Emitir NF
      </Button>
    </WithAllowedPermission>
  );
}
