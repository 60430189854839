import { Tooltip } from 'antd';
import { BankIcon } from 'src/mui/_scss';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

type Props = {
  account?: Account;
};

export default function AccountInfo({ account }: Props) {
  if (!account) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      overlayInnerStyle={{ textAlign: 'center' }}
      title={
        <span data-testid="accountInfos">
          {account.name} <br />
          {[account.branchNumber, account.accountNumber]
            .filter(Boolean)
            .join(' ')}
        </span>
      }
    >
      <span>
        <BankIcon
          routingNumber={account.routingNumber}
          width={24}
          height={24}
        />
      </span>
    </Tooltip>
  );
}
