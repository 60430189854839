import { convertToSignedNumber } from 'src/libs/finbits/Money';
import type { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

export function amountSorter<
  T extends {
    amount: number;
    type: BalanceType;
  }
>(entryA: T, entryB: T) {
  const aAmount = convertToSignedNumber(entryA.amount, entryA.type);
  const bAmount = convertToSignedNumber(entryB.amount, entryB.type);
  return aAmount - bAmount;
}
