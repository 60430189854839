import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { invalidateFinancialStatementEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';

import { ConciliationSuggestionsDecoder } from './types';
import type { ConciliationSuggestion, GetParams, PatchParams } from './types';

export function invalidateConciliationSuggestionsQueries(
  queryClient: QueryClient
) {
  queryClient.invalidateQueries(['accountsConciliationSuggestions']);
}

export async function getAccountsConciliationSuggestions({
  organizationId,
  companyId,
  ...params
}: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/financial_statements/conciliation_suggestions`,
    { params }
  );

  return decodeResponse<ConciliationSuggestion[]>(
    response,
    ConciliationSuggestionsDecoder
  );
}

export async function patchConciliationSuggestion({
  organizationId,
  companyId,
  suggestionId,
  status,
}: PatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/financial_statements/conciliation_suggestions/${suggestionId}`,
    { status }
  );

  return response;
}

function setQueryKey({ organizationId, companyId }: GetParams) {
  return ['accountsConciliationSuggestions', { companyId, organizationId }];
}

export function useConciliationSuggestions(
  params: GetParams,
  queryConfigCustom: UseQueryOptions<ConciliationSuggestion[], ApiError> = {}
) {
  const enabled =
    queryConfigCustom.enabled && !!params.companyId && !!params.organizationId;

  const { data, ...rest } = useQuery<ConciliationSuggestion[], ApiError>({
    ...queryConfigCustom,
    queryKey: setQueryKey(params),
    queryFn: () => getAccountsConciliationSuggestions(params),
    enabled,
  });

  return { suggestions: withEmptyArrayDefault(data), ...rest };
}

export function useAcceptSuggestion() {
  const queryClient = useQueryClient();

  const { mutate: acceptSuggestion, ...rest } = useMutation<
    unknown,
    ApiError,
    PatchParams
  >(
    (params) => patchConciliationSuggestion({ ...params, status: 'accepted' }),

    {
      onSuccess: () => {
        invalidateFinancialStatementEntriesQueries(queryClient);
        invalidateConciliationSuggestionsQueries(queryClient);
      },
    }
  );
  return { acceptSuggestion, ...rest };
}

export function useRefuseSuggestion() {
  const queryClient = useQueryClient();

  const { mutate: refuseSuggestion, ...rest } = useMutation<
    unknown,
    ApiError,
    PatchParams
  >(
    (params) => patchConciliationSuggestion({ ...params, status: 'refused' }),

    {
      onSuccess: () => {
        invalidateConciliationSuggestionsQueries(queryClient);
      },
    }
  );
  return { refuseSuggestion, ...rest };
}
