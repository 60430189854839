import { useMemo, useState } from 'react';

import { Empty } from 'antd';

import type { PortalProps } from 'src/ui';
import { Drawer, DrawerFooter, DrawerHeader, Loader, Title } from 'src/ui';

import type { ConciliationSuggestion } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions/types';
import { useGetBills } from 'src/libs/finbits/Bills';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useGetReceivables } from 'src/libs/finbits/Receivables';
import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import ConciliationTable from './ConciliationTable';
import styles from './ConciliationDrawer.module.less';

type BaseProps = {
  organizationId?: string;
  companyId?: string;
  financialEntryId?: string;
  type: BalanceType;
  scheduledIdsToHide?: string[];
  title?: string;
  submitText?: string;
  suggestions?: ConciliationSuggestion[];
  isLoadingSuggestions?: boolean;
} & PortalProps;

export type Props =
  | (BaseProps & {
      multiple: true;
      onConciliate: (scheduledEntries: ScheduledStatementEntry[]) => void;
    })
  | (BaseProps & {
      multiple: false;
      onConciliate: (scheduledEntry: ScheduledStatementEntry) => void;
    });

export default function ConciliationDrawer({
  multiple,
  organizationId,
  companyId,
  financialEntryId,
  type,
  onConciliate,
  onClose,
  scheduledIdsToHide,
  title = type === BalanceType.CREDIT
    ? 'Conciliar recebimento'
    : 'Conciliar pagamento',
  submitText = 'Conciliar',
  suggestions,
  isLoadingSuggestions,
  open = true,
  onExit,
}: Props) {
  const isDebit = type === BalanceType.DEBIT;
  const isCredit = type === BalanceType.CREDIT;

  const [selectedEntries, setSelectedEntries] = useState<
    ScheduledStatementEntry[]
  >([]);

  const { receivables, isLoading: isLoadingReceivables } = useGetReceivables(
    { organizationId, companyId },
    { enabled: isCredit }
  );

  const { bills, isLoading: isLoadingBills } = useGetBills(
    { organizationId, companyId },
    { enabled: isDebit }
  );

  const isLoading = isLoadingBills || isLoadingReceivables;

  const availableEntries = useMemo(() => {
    const entries: ScheduledStatementEntry[] = isDebit ? bills : receivables;

    return entries
      .filter((entry) => !scheduledIdsToHide?.includes(entry.id))
      .filter((entry) => {
        return canPerformAction(entry, AvailableActions.CONCILIATE);
      });
  }, [receivables, scheduledIdsToHide, bills, isDebit]);

  const isEmpty = availableEntries.length === 0;

  const emptyStateTitle = isCredit
    ? 'recebimentos programados'
    : 'pagamentos programados';

  function handleConciliate() {
    if (selectedEntries) {
      onConciliate(multiple ? selectedEntries : (selectedEntries[0] as any));
      onClose();
    }
  }

  return (
    <Drawer
      title={<DrawerHeader title={<Title>{title}</Title>} onBack={onClose} />}
      footer={null}
      closable={false}
      onClose={onClose}
      visible={open}
      afterVisibleChange={onExit}
    >
      {isLoading && <Loader size="small" />}
      {!isLoading && isEmpty && (
        <Empty
          className={styles.empty}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <div className={styles.emptyText}>
              Não existem {emptyStateTitle} disponíveis para conciliação.
            </div>
          }
        />
      )}

      {!isLoading && !isEmpty && (
        <ConciliationTable
          multiple={multiple}
          loading={isLoading}
          rows={availableEntries}
          financialEntryId={financialEntryId}
          selectedEntries={selectedEntries}
          onSelect={setSelectedEntries}
          suggestions={suggestions}
          isLoadingSuggestions={isLoadingSuggestions}
          type={type}
        />
      )}

      {selectedEntries.length > 0 && (
        <DrawerFooter>
          <DrawerFooter.SubmitButton
            disabled={selectedEntries.length === 0}
            onClick={handleConciliate}
          >
            {submitText}
          </DrawerFooter.SubmitButton>
        </DrawerFooter>
      )}
    </Drawer>
  );
}
