import cn from 'classnames';
import type { TagProps as AntdProps } from 'antd';
import { Tag as AntdTag } from 'antd';

import styles from './Tag.module.less';
import EllipsisTag from './EllipsisTag';

export default function Tag({ className, ...rest }: AntdProps) {
  return <AntdTag className={cn([styles.tag, className])} {...rest} />;
}

Tag.Ellipsis = EllipsisTag;
