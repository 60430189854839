import cn from 'classnames';
import { Form } from 'antd';
import type { FormItemProps } from 'antd/lib/form';
import compact from 'lodash/compact';

import styles from './FormItem.module.less';

type Props = FormItemProps & {
  max?: number;
  min?: number;
  hasMax?: boolean;
};

function FormItem({
  className,
  min,
  max = 255,
  hasMax = true,
  rules = [{ validator: () => Promise.resolve() }],
  required,
  ...rest
}: Props) {
  const maxLengthRule = {
    max,
    message: `Deve ter no máximo ${max} caracteres`,
  };

  const minLengthRule = {
    min,
    message: `Deve ter no mínimo ${min} caracteres`,
  };

  const requiredRule = {
    required: true,
    message: 'Esse campo precisa ser preenchido',
  };

  const fieldRules = compact([
    required && requiredRule,
    hasMax && maxLengthRule,
    !!min && minLengthRule,
    ...rules,
  ]);

  return (
    <Form.Item
      rules={fieldRules}
      className={cn([styles.formItem, className])}
      {...rest}
    />
  );
}

export default FormItem;
