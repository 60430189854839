import { Avatar } from '@mui/material';
import { SparklesIcon } from 'src/mui/_icons';

import styles from './BotAvatar.module.scss';

export default function BotAvatar() {
  return (
    <Avatar className={styles.botAvatar}>
      <SparklesIcon className={styles.botIcon} />
    </Avatar>
  );
}
