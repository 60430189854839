import cn from 'classnames';
import type { PageHeaderProps } from 'antd';
import { PageHeader } from 'antd';

import styles from './DrawerHeader.module.less';

function DrawerHeader({ className, ...rest }: PageHeaderProps) {
  return (
    <PageHeader className={cn([styles.pageHeader, className])} {...rest} />
  );
}

export default DrawerHeader;
