import { createContext, useContext, useState } from 'react';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import type { TabPanelsContextProps, TabPanelsProviderProps } from './types';
import { BillTab, PreviewTab } from './types';

const TabPanelsContext = createContext({} as TabPanelsContextProps);

export default function TabPanelsProvider({
  children,
  disabledTabs,
}: TabPanelsProviderProps) {
  const { isEnabled: isEnabledSuggestionsTab } = useExternalFeatureFlag(
    Feature.SUGGESTIONS_TAB
  );

  const initialPreviewTab = isEnabledSuggestionsTab
    ? PreviewTab.SUGGESTIONS
    : PreviewTab.ATTACHMENTS;

  const [selectedBillTab, setSelectedBillTab] = useState(BillTab.INFORMATION);

  const [selectedPreviewTab, setSelectedPreviewTab] =
    useState(initialPreviewTab);

  return (
    <TabPanelsContext.Provider
      value={{
        selectedBillTab,
        setSelectedBillTab,
        selectedPreviewTab,
        setSelectedPreviewTab,
        disabledTabs,
      }}
    >
      {children}
    </TabPanelsContext.Provider>
  );
}

export function useTabPanelsContext() {
  const context = useContext(TabPanelsContext);

  if (!context) {
    throw new Error('To use context, you must provide a TabPanelsProvider');
  }

  return context;
}
