import { validate as uuidValidate } from 'uuid';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';

import { emailValidator } from 'src/libs/finbits/Email';
import { isValidPhone } from 'src/libs/finbits/Organization/PhoneNumber';

export function pixKeyValidation({
  pixKey,
  pixType,
}: {
  pixKey: string;
  pixType: string;
}) {
  if (pixType === 'cpf') {
    return pixKey.length >= 11 && cpfValidator.isValid(pixKey);
  }

  if (pixType === 'cnpj') {
    return pixKey.length > 11 && cnpjValidator.isValid(pixKey);
  }

  if (pixType === 'cellphone_number') {
    return isValidPhone(pixKey);
  }

  if (pixType === 'email') {
    return emailValidator(pixKey);
  }

  if (pixType === 'random') {
    return uuidValidate(pixKey);
  }

  return true;
}
