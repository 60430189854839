import { useMemo } from 'react';

import { Checkbox } from '@mui/material';

import { useInboxItemsContext } from 'src/features/inbox-items/InboxItemsProvider/InboxItemsProvider';

type Props = {
  inboxItemId: string;
};

export default function InboxItemCheckbox({ inboxItemId }: Props) {
  const { selectedInboxItemsIds, setSelectedInboxItemsIds } =
    useInboxItemsContext();

  const isSelected = useMemo(() => {
    return !!selectedInboxItemsIds.find((id) => id === inboxItemId);
  }, [selectedInboxItemsIds, inboxItemId]);

  function handleChange(checked: boolean) {
    if (checked) {
      return setSelectedInboxItemsIds([...selectedInboxItemsIds, inboxItemId]);
    }

    setSelectedInboxItemsIds(
      selectedInboxItemsIds.filter((id) => id !== inboxItemId)
    );
  }

  return (
    <Checkbox
      size="small"
      onChange={(_event, value) => handleChange(value)}
      checked={isSelected}
    />
  );
}
