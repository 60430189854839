import t from 'zod';
import type { RcFile } from 'rc-upload/lib/interface';

import type { CompanyService } from 'src/libs/finbits/NotaFiscal/Services/types';
import type { Company } from 'src/libs/finbits/Organization/Companies/types';
import type { Address } from 'src/libs/finbits/BrazilianData/Addresses/types';
import {
  REQUIRED_MESSAGE,
  required,
} from 'src/libs/finbits/resolverValidations/required';

export const NotaFiscalSetupResolver = t.object({
  specialTaxRuleId: required,
  municipalRegistration: t
    .string()
    .min(1, REQUIRED_MESSAGE)
    .max(15, 'deve conter ate 15 caracteres')
    .regex(new RegExp(/^[0-9]+$/), 'deve conter apenas números'),
});

export type SetupNotaFiscalFormParams = {
  password: string;
  services: CompanyService[];
  address?: Partial<Address> | null;
  file?: RcFile;
  monitoringEnabled: boolean;
  monitoringProductEnabled: boolean;
} & Pick<
  Company,
  | 'document'
  | 'name'
  | 'tradeName'
  | 'email'
  | 'phone'
  | 'municipalRegistration'
  | 'simpleNational'
  | 'specialTaxRuleId'
>;

export type SetupNotaFiscalParams = {
  companyId: string;
  organizationId: string;
} & SetupNotaFiscalFormParams;

export type UpdateComplementaryDataParams = Pick<
  SetupNotaFiscalParams,
  | 'organizationId'
  | 'companyId'
  | 'municipalRegistration'
  | 'specialTaxRuleId'
  | 'simpleNational'
>;

export type UpdateComplementaryDataFormParams = Omit<
  UpdateComplementaryDataParams,
  'companyId' | 'organizationId'
>;

export type UpdateDigitalCertificateParams = Pick<
  SetupNotaFiscalParams,
  'organizationId' | 'companyId' | 'file' | 'password'
>;

export type UpdateDigitalCertificateFormParams = Pick<
  UpdateDigitalCertificateParams,
  'file' | 'password'
>;

export type UpdateMonitoringParams = Pick<
  SetupNotaFiscalParams,
  | 'organizationId'
  | 'companyId'
  | 'monitoringEnabled'
  | 'monitoringProductEnabled'
>;
