import type { TypographyOptions } from '@mui/material/styles/createTypography';

const textSm = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
};

const textXs = {
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 400,
};

const textLg = {
  fontSize: '18px',
  lineHeight: '28px',
  fontWeight: 400,
};

export const typography: TypographyOptions = {
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  body1: textSm,
  body2: textXs,
  button: {
    ...textSm,
    fontWeight: 600,
  },
  caption: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  subtitle1: {
    ...textLg,
    fontWeight: 600,
  },
  subtitle2: undefined,
  overline: {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 2.5,
    textTransform: 'uppercase',
  },
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: {
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '38px',
  },
  h6: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
  },
  'text-xl': {
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 400,
  },
  'text-lg': textLg,
  'text-md': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  'text-sm': textSm,
  'text-xs': textXs,
};
