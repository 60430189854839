import { List, Typography } from 'antd';
import { Link } from 'react-router-dom';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { toCurrency } from 'src/libs/finbits/Money';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Balance } from 'src/libs/finbits/Organization/Companies/Balances/types';

import { AccountInfo } from 'src/features/entries/Columns';

type Props = {
  account: Account;
  balance?: Balance;
};

export default function AccountBox({ account, balance }: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const formattedBalance = toCurrency(
    balance?.balance ?? 0,
    balance?.balanceType
  );

  const statementUrl = `/organizations/${organizationId}/companies/${companyId}/entries?accountId=${account.id}`;

  return (
    <List.Item>
      <List.Item.Meta
        avatar={<AccountInfo account={account} />}
        title={<Link to={statementUrl}>{account.name}</Link>}
      />
      <Typography.Text>{formattedBalance}</Typography.Text>
    </List.Item>
  );
}
