import type { PortalProps } from 'src/ui';
import { Drawer, DrawerHeader, Title } from 'src/ui';

import { useIgnoredRecords } from 'src/libs/finbits/Management/IgnoredRecords';
import { format } from 'src/libs/finbits/Date';

import IgnoredRecordsTable from 'src/features/entries/IgnoredRecordsTable';
import useEntriesParams from 'src/features/entries/useEntriesParams';

export function IgnoredRecordsDrawer({
  onClose,
  open = true,
  onExit,
}: PortalProps) {
  const {
    organizationId,
    companyId,
    selectedAccounts,
    selectedAccountIds,
    selectedDateRange,
  } = useEntriesParams();

  const { ignoredRecords, isLoading } = useIgnoredRecords({
    accountsIds: selectedAccountIds,
    companyId,
    organizationId,
    startDate: format(selectedDateRange[0], 'yyyy-MM-dd'),
    endDate: format(selectedDateRange[1], 'yyyy-MM-dd'),
  });

  return (
    <Drawer
      visible={open}
      title={<DrawerHeader title={<Title>Lançamentos Excluídos</Title>} />}
      destroyOnClose={true}
      onClose={onClose}
      afterVisibleChange={onExit}
    >
      <IgnoredRecordsTable
        isLoading={isLoading}
        ignoredRecords={ignoredRecords}
        selectedAccounts={selectedAccounts}
      />
    </Drawer>
  );
}
