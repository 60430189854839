import type { GridRenderEditCellParams } from '@mui/x-data-grid-premium';

import { DatePicker } from 'src/mui';

import { isValid } from 'src/libs/finbits/Date';

type EditParams = Pick<
  GridRenderEditCellParams,
  'api' | 'value' | 'field' | 'id'
>;

export default function DateInput({
  id,
  field,
  api,
  value,
  ...props
}: EditParams) {
  function handleChange(newDate: Date | null) {
    api.setEditCellValue({
      id,
      field,
      value: isValid(newDate) ? newDate : null,
      debounceMs: 200,
    });
  }

  return (
    <DatePicker
      {...props}
      onChange={handleChange}
      value={value}
      slotProps={{ textField: { variant: 'standard' } }}
    />
  );
}
