import { Empty } from 'antd';

import styles from './EmptyState.module.less';

function EmptyState() {
  return (
    <div className={styles.emptyContainer}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Nenhum anexo na Caixa de Entrada"
      />
    </div>
  );
}

export default EmptyState;
