import { useMemo, useState } from 'react';

import uniqBy from 'lodash/uniqBy';
import { Table, Typography } from 'antd';
import type {
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import type { ColumnsType, TableProps } from 'antd/lib/table';

import { differenceInMinutes, parseISO } from 'src/libs/finbits/Date';
import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import type { Receivable } from 'src/libs/finbits/Receivables/types';
import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import {
  AccountFilter,
  AmountFilter,
  ContactFilter,
  DateFilter,
  DescriptionFilter,
} from 'src/features/filters';
import {
  AccountInfo,
  Amount,
  Association,
  Date as DateTime,
} from 'src/features/entries/Columns';
import EditReceivableDrawer from 'src/features/receivables/EditReceivableDrawer/EditReceivableDrawer';
import EditFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/EditFinancialEntryDrawer/EditFinancialEntryDrawer';
import type { EntryFormHiddenActions } from 'src/features/EntryForm/types';

import styles from './LinkEntriesTable.module.less';

export type FilteredInfo = Record<string, any>;

type Entry = Receivable | FinancialEntry;

type Props = {
  receivables: Receivable[];
  financialEntries: FinancialEntry[];
  filteredInfo?: FilteredInfo;
  onChange?: (
    _pagination: TablePaginationConfig,
    filters: FilteredInfo,
    _sorter:
      | SorterResult<Receivable | FinancialEntry>
      | SorterResult<Receivable | FinancialEntry>[]
  ) => void;
  allowClearDateFilter?: boolean;
} & Partial<TableProps<Receivable | FinancialEntry>>;

const HIDDEN_ACTIONS: EntryFormHiddenActions = [
  'createReceivableWithNotaFiscal',
];

export default function LinkEntriesTable({
  receivables,
  financialEntries,
  filteredInfo,
  allowClearDateFilter = true,
  ...rest
}: Props) {
  const [receivableId, setReceivableId] = useState<string>();
  const [financialEntryId, setFinancialEntryId] = useState<string>();

  const entries = useMemo(() => {
    return [...receivables, ...financialEntries];
  }, [receivables, financialEntries]);

  const contacts = useMemo(() => {
    const entriesWithContact = entries.filter((entry) =>
      Boolean(entry.contact)
    );

    return uniqBy(
      entriesWithContact.map((entry) => entry.contact),
      'id'
    ) as Contact[];
  }, [entries]);

  const accounts = useMemo(() => {
    const entriesWithAccounts = entries.filter((entry) =>
      Boolean(entry.account)
    );

    return uniqBy(
      entriesWithAccounts.map((entry) => entry.account),
      'id'
    ) as Account[];
  }, [entries]);

  const columns: ColumnsType<Entry> = useMemo(() => {
    return [
      {
        key: 'date',
        title: 'Data',
        filteredValue: filteredInfo?.date || null,
        shouldCellUpdate: () => filteredInfo?.date !== null,
        ...DateFilter<Entry>('date', {
          controlledValue: filteredInfo?.date
            ? filteredInfo?.date[0]
            : undefined,
          allowClear: allowClearDateFilter,
        }),
        render: ({ date }: Entry) => <DateTime date={date} />,
        sorter: (a: Entry, b: Entry) =>
          differenceInMinutes(parseISO(a.date), parseISO(b.date)),
      },
      {
        key: 'description',
        className: 'description',
        title: 'Descrição',
        filteredValue: filteredInfo?.description || null,
        shouldCellUpdate: () => filteredInfo?.description !== null,
        ...DescriptionFilter<Entry>(),
        render: ({ description }: Entry) => (
          <Typography.Text ellipsis={{ tooltip: description }}>
            {description}
          </Typography.Text>
        ),
        sorter: (a: Entry, b: Entry) =>
          a.description.localeCompare(b.description),
      },
      {
        key: 'account',
        align: 'center',
        title: 'Conta',
        filteredValue: filteredInfo?.account || null,
        shouldCellUpdate: () => filteredInfo?.account !== null,
        ...AccountFilter<Entry>({ accounts }),
        render: (record: Entry) => {
          return <AccountInfo account={record.account} />;
        },
        sorter: (recordA: Entry, recordB: Entry) => {
          const a = recordA.account?.name ?? '';
          const b = recordB.account?.name ?? '';

          return a.localeCompare(b);
        },
      },
      {
        key: 'contact',
        title: 'Contato',
        filteredValue: filteredInfo?.contact || null,
        shouldCellUpdate: () => filteredInfo?.contact !== null,
        ...ContactFilter<Entry>({ contacts }),
        render: ({ contact }: Entry) => (
          <Association label="Vincular" value={contact?.nickname ?? ''} />
        ),
        sorter: (a: Entry, b: Entry) => {
          const entryA = a.contact?.nickname ?? '';
          const entryB = b.contact?.nickname ?? '';

          return entryA.localeCompare(entryB);
        },
      },
      {
        key: 'amount',
        title: 'Valor',
        align: 'right',
        filteredValue: filteredInfo?.amount || null,
        shouldCellUpdate: () => filteredInfo?.amount !== null,
        render: ({ amount }: Entry) => (
          <Amount amount={amount} type={BalanceType.CREDIT} />
        ),
        sorter: (a: Entry, b: Entry) => a.amount - b.amount,
        ...AmountFilter<Entry>(),
      },
    ];
  }, [accounts, contacts, filteredInfo, allowClearDateFilter]);

  return (
    <>
      <Table
        rowKey="id"
        size="middle"
        columns={columns}
        dataSource={entries}
        className={styles.table}
        pagination={{
          position: ['bottomCenter'],
          pageSizeOptions: ['25', '50', '75'],
          defaultPageSize: 25,
        }}
        scroll={{ y: 'calc(100vh - 280px)' }}
        onRow={(row) => ({
          onClick: () => {
            const isReceivable = receivables.find(
              (receivable) => receivable.id === row.id
            );

            if (isReceivable) {
              return setReceivableId(row.id);
            }

            setFinancialEntryId(row.id);
          },
          className: styles.tableRow,
        })}
        {...rest}
      />

      {receivableId && (
        <EditReceivableDrawer
          id={receivableId}
          hiddenActions={HIDDEN_ACTIONS}
          onClose={() => setReceivableId(undefined)}
        />
      )}

      {financialEntryId && (
        <EditFinancialEntryDrawer
          financialEntryId={financialEntryId}
          hiddenActions={HIDDEN_ACTIONS}
          onClose={() => setFinancialEntryId(undefined)}
        />
      )}
    </>
  );
}
