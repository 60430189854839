import t from 'zod';

import { AddressDecoder } from 'src/libs/finbits/BrazilianData/Addresses/types';

export const LegalEntityDecoder = t.object({
  name: t.string(),
  nickname: t.string().nullable(),
  document: t.string().nullable(),
  email: t.string().nullable(),
  phone: t.string().nullable(),
  address: AddressDecoder,
});

export type LegalEntity = t.TypeOf<typeof LegalEntityDecoder>;

export type GetParams = {
  document: string | null;
};
