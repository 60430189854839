import { useState } from 'react';

import type { ButtonProps } from 'antd';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import type { InboxItemContactType } from 'src/libs/finbits/Management/NewInboxItems/types';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import CreateContactDrawer from 'src/features/contacts/CreateContactDrawer';
import type { Document } from 'src/features/contacts/CreateContactDrawer/DocumentForm';

import styles from './CreateContactFromEntry.module.less';

type Props = {
  document?: string;
  name?: string;
  nickname?: string;
  type?: InboxItemContactType | null;
  onCreate?: (contactId: string) => void;
} & Omit<ButtonProps, 'type'>;

export default function CreateContactFromEntry({
  document = '',
  name = '',
  nickname = '',
  type,
  onCreate,
  ...rest
}: Props) {
  const [isCreateContactDrawerVisible, setIsCreateContactDrawerVisible] =
    useState(false);

  const isInternational = type === 'international';

  const defaultDocument: Document = isInternational
    ? {
        name: document,
        nickname: document,
        isInternational: true,
      }
    : {
        number: document,
        name,
        nickname,
        type: type as ContactType,
        isInternational: false,
      };

  function handleClose() {
    setIsCreateContactDrawerVisible(false);
  }

  function handleCreateContact(contactId: string) {
    handleClose();
    onCreate && onCreate(contactId);
  }

  return (
    <>
      <Button
        type="link"
        icon={<PlusOutlined />}
        className={styles.button}
        onClick={() => setIsCreateContactDrawerVisible(true)}
        {...rest}
      >
        Criar contato
      </Button>

      {isCreateContactDrawerVisible && (
        <CreateContactDrawer
          hideBackButton
          defaultContactDocument={defaultDocument}
          onClose={handleClose}
          onCreate={handleCreateContact}
        />
      )}
    </>
  );
}
