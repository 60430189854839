import type { InternalAxiosRequestConfig } from 'axios';

import { getAccessToken } from 'src/libs/finbits/Auth';

async function authorizationInterceptor(
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  const token = getAccessToken();

  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }

  return config;
}

export default authorizationInterceptor;
