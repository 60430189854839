import { useState } from 'react';

import { Col, List, Row } from 'antd';

import { MaskedDatePicker, Title } from 'src/ui';

import { format } from 'src/libs/finbits/Date';
import { useCompaniesBalances } from 'src/libs/finbits/Organization/Companies/Balances';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import TotalAmount from 'src/features/companies-balances/TotalAmount';

import CompanyBalanceBox from './CompanyBalanceBox';
import styles from './CompaniesBalances.module.less';

export default function CompaniesBalances() {
  const { organizationId } = useCompanyParams();

  const [selectedDate, setSelectedDate] = useState(new Date());

  const { companiesBalances, isLoading } = useCompaniesBalances({
    organizationId,
    date: format(selectedDate, 'yyyy-MM-dd'),
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Title level={4}>Saldo consolidado</Title>
          <MaskedDatePicker
            allowClear={false}
            placeholder="Selecione um dia"
            value={selectedDate}
            onChange={(value) => value && setSelectedDate(value)}
            format="dd/MM/yyyy"
          />
        </Row>
      </Col>
      <Col span={24}>
        <List
          className={styles.companiesBalances}
          dataSource={companiesBalances}
          loading={isLoading}
          renderItem={(companyBalance) => (
            <CompanyBalanceBox
              key={companyBalance.company.id}
              companyBalance={companyBalance}
            />
          )}
        />
        <TotalAmount balances={companiesBalances} />
      </Col>
    </Row>
  );
}
