import { IconButton, Stack } from '@mui/material';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

import { useBillPreviewContext } from 'src/features/bills-to-pay/BillContent/BillPreviewProvider';

type Props = {
  attachment: Attachment;
  totalAttachments: number;
};

export default function AttachmentPagination({
  attachment,
  totalAttachments,
}: Props) {
  const { currentAttachmentIndex, setCurrentAttachmentIndex } =
    useBillPreviewContext();

  function handleNextDocument() {
    setCurrentAttachmentIndex(currentAttachmentIndex + 1);
  }

  function handlePreviousDocument() {
    setCurrentAttachmentIndex(currentAttachmentIndex - 1);
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      sx={{ mb: 4 }}
    >
      <Typography
        ellipsis
        tooltip={{ title: attachment.name }}
        sx={{ width: 'calc(100% - 110px)' }}
      >
        Visualizando o anexo <b>{attachment.name}</b>
      </Typography>

      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={handlePreviousDocument}
          disabled={currentAttachmentIndex === 1}
          aria-label="Voltar para o anexo anterior"
        >
          <ChevronLeftIcon />
        </IconButton>

        <Typography variant="text-xs">
          {currentAttachmentIndex} de {totalAttachments}
        </Typography>

        <IconButton
          onClick={handleNextDocument}
          disabled={currentAttachmentIndex === totalAttachments}
          aria-label="Ir para o próximo anexo"
        >
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
