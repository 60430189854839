import { Stack } from '@mui/material';
import CountUp from 'react-countup';
import { ClockIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { Tooltip } from 'src/mui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import styles from './TotalSum.module.scss';

type Props = {
  showToolTip?: boolean;
  bills: BillPayable[];
  description?: string;
  titleToolTip?: string;
};

export default function TotalSum({
  showToolTip = false,
  bills,
  description = 'Total a pagar',
  titleToolTip = 'Pendência',
}: Props) {
  const total = bills.reduce((acc, bill) => {
    return acc + bill.amount;
  }, 0);

  return (
    <Stack alignItems="end" flexGrow="1" order="2">
      <Typography variant="text-xs">{description}</Typography>

      <Typography
        textTransform="uppercase"
        fontWeight="600"
        className={styles.total}
      >
        {showToolTip && (
          <Tooltip title={titleToolTip} enterDelay={100} placement="top">
            <ClockIcon color="warning" role="img" fontSize="xsmall" />
          </Tooltip>
        )}

        <CountUp
          prefix="R$ "
          end={total / 100}
          decimals={2}
          decimal=","
          separator="."
          duration={0.5}
          preserveValue
          useEasing={false}
          delay={0}
        />
      </Typography>
    </Stack>
  );
}
