import type { SyntheticEvent } from 'react';
import { useMemo } from 'react';

import { Autocomplete, Box, InputBase, ListItemText } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import type { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import classNames from 'classnames';

import { useCompanyContacts } from 'src/libs/finbits/Organization/Companies/Contacts';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { formatDocument } from 'src/libs/finbits/Documents';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './ContactsAutocomplete.module.scss';

const filterOptions = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (contact: Contact) => {
    return contact.nickname + contact.name + formatContactDocument(contact);
  },
});

type EditParams = Pick<
  GridRenderEditCellParams,
  'api' | 'value' | 'field' | 'id'
> & {
  onChange?: (_e: SyntheticEvent, newValue: Contact | null | string) => void;
  autoFocus?: boolean;
  bordered?: boolean;
};

function formatContactDocument(contact: Contact) {
  if (!contact.type || !contact.document) {
    return '';
  }
  return formatDocument(contact.type, contact.document);
}

export default function ContactsAutocomplete({
  value,
  onChange,
  autoFocus = true,
  bordered = true,
}: EditParams) {
  const { organizationId, companyId } = useCompanyParams();

  const { companyContacts: contacts, isLoading } = useCompanyContacts({
    companyId,
    organizationId,
  });

  const availablesContacts = useMemo(
    () => contacts.filter((c) => c.active),
    [contacts]
  );

  const className = classNames({
    [styles.bordered]: bordered,
  });

  return (
    <Autocomplete<Contact, false, false, true>
      className={className}
      fullWidth
      freeSolo
      autoHighlight
      openOnFocus
      loading={isLoading}
      options={availablesContacts}
      filterOptions={filterOptions}
      onChange={onChange}
      value={value}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option?.nickname
      }
      isOptionEqualToValue={(opt, selected) => opt.id === selected.id}
      renderInput={(params) => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus={autoFocus}
          fullWidth
          className={styles.input}
        />
      )}
      renderOption={(props, contact) => (
        <Box component="li" {...props} key={contact.id}>
          <ListItemText
            primary={contact.nickname}
            secondary={
              <Box component="span">
                {contact.name}
                <br />
                {formatContactDocument(contact)}
              </Box>
            }
          />
        </Box>
      )}
      componentsProps={{ paper: { className: styles.dropdown } }}
    />
  );
}
