import { Tooltip } from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';

import { formatList } from 'src/libs/finbits/List';

import styles from './UnfilledContext.module.less';

type Props = {
  unfilledContextFields: string[] | null;
};

const FIELDS_DICTIONARY: Record<string, string> = {
  attachments: 'Anexos',
  category_id: 'Categoria',
  contact_id: 'Contato',
  relevant_date: 'Competência',
};

export default function UnfilledContext({ unfilledContextFields }: Props) {
  if (!unfilledContextFields || !unfilledContextFields.length) return null;

  const fieldsToString = formatList(
    unfilledContextFields.map((field) => {
      return FIELDS_DICTIONARY[field];
    })
  );

  return (
    <Tooltip placement="top" title={`Falta preencher ${fieldsToString}`}>
      <ExclamationOutlined className={styles.danger} />
    </Tooltip>
  );
}
