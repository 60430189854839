import { useMemo } from 'react';

import type { TreeDataNode } from 'antd';
import { Tree, Typography } from 'antd';

import type { Label } from 'src/libs/finbits/Labels/types';
import type { Classification } from 'src/libs/finbits/Classifications/types';

import LabelItem from 'src/features/labels/LabelList/LabelItem/LabelItem';

import styles from './LabelList.module.less';

type Props = {
  classification: Classification;
};

type TreeDataProps = (TreeDataNode & Label)[];

function LabelList({ classification }: Props) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const treeKey = useMemo(() => new Date().getTime(), [classification]);

  if (classification.labels?.length === 0) {
    return (
      <Typography.Text type="secondary">
        Ainda não há etiquetas criadas nesta classificação
      </Typography.Text>
    );
  }

  return (
    <Tree
      key={treeKey}
      className={styles.tree}
      blockNode
      defaultExpandAll
      fieldNames={{
        title: 'name',
        key: 'id',
        children: 'children',
      }}
      treeData={classification.labels as TreeDataProps}
      titleRender={(label) => (
        <LabelItem classification={classification} label={label} />
      )}
    />
  );
}

export default LabelList;
