import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { CompanyChannelProvider } from 'src/libs/finbits/Channels';
import {
  setCompanyParamsOnStorage,
  useOptionalCompanyParams,
} from 'src/libs/finbits/Organization/Companies';

type Props = {
  children: ReactNode;
};

export function CompanyProvider({ children }: Props) {
  const { organizationId, companyId } = useOptionalCompanyParams();

  useEffect(() => {
    if (companyId && organizationId)
      setCompanyParamsOnStorage({ companyId, organizationId });
  }, [companyId, organizationId]);

  return (
    <CompanyChannelProvider
      organizationId={organizationId}
      companyId={companyId}
    >
      {children}
    </CompanyChannelProvider>
  );
}
