import { useEffect, useState } from 'react';

import { Col, Form, Input, Row } from 'antd';
import type { FieldData } from 'rc-field-form/lib/interface';

import {
  DrawerFooter,
  FormItem,
  Loader,
  MaskedInput,
  MaskedSearch,
} from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAllowCityNotaFiscal } from 'src/libs/finbits/NotaFiscal/Cities';
import { useCompany } from 'src/libs/finbits/Organization/Companies/Companies';
import {
  cellphoneMask,
  phoneMask,
  phoneValidator,
} from 'src/libs/finbits/Organization/PhoneNumber';
import { emailRegex } from 'src/libs/finbits/Email';
import {
  addressPostalCodeValidator,
  cepMask,
} from 'src/libs/finbits/BrazilianData/Addresses';
import { cnpjMask, formCnpjValidator } from 'src/libs/finbits/Documents';
import type { SetupNotaFiscalFormParams } from 'src/libs/finbits/NotaFiscal/Setup/types';

import { useCompanyForm } from 'src/features/companies/CompanyForm/useCompanyForm';
import CitiesSelect from 'src/features/location/CitiesSelect';
import StatesSelect from 'src/features/location/StatesSelect';

import StepInformation from '../StepInformation';
import type { StepProps } from '../types';

import styles from './CompanyStep.module.less';

const CITY_NOT_ALLOWED_MESSAGE = 'Emissão indisponível nesta cidade.';

export default function CompanyStep({
  form,
  errors,
  onSubmit,
  onResolveError,
}: StepProps) {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { organizationId, companyId } = useCompanyParams();
  const { company, isLoading } = useCompany({
    organizationId,
    companyId,
  });

  useEffect(() => {
    if (errors) {
      form.setFields(errors);
    }
  }, [form, errors]);

  const {
    isCityAllowed,
    isSuccess,
    refetch: refetchAllowCity,
  } = useAllowCityNotaFiscal(
    {
      city: form.getFieldValue(['address', 'city']),
      state: form.getFieldValue(['address', 'state']),
    },
    {
      onSuccess: (isCityAllowed) => {
        form.setFields([
          {
            name: ['address', 'postalCode'],
            errors: !isCityAllowed ? [CITY_NOT_ALLOWED_MESSAGE] : [],
          },
        ]);

        setIsSubmiting(false);
      },
    }
  );

  const {
    companyForm,
    searchingAddress,
    searchingDocument,
    stateCode,
    initialValuesFormatted,
    onSearchAddress,
    onSearchDocument,
    onFieldsChange,
  } = useCompanyForm({
    form,
    initialValues: company,
    onChangeAddress: refetchAllowCity,
  });

  async function handleOnFinish(values: SetupNotaFiscalFormParams) {
    setIsSubmiting(true);

    const { data: isCityAllowedOnRefetch = false } = await refetchAllowCity();

    if (isCityAllowedOnRefetch) {
      onSubmit(values);
    }
  }

  function cityIsAllowedValidator() {
    return !isCityAllowed && isSuccess
      ? Promise.reject(CITY_NOT_ALLOWED_MESSAGE)
      : Promise.resolve();
  }

  if (isLoading) {
    return <Loader forceCentered />;
  }

  return (
    <>
      <StepInformation
        title="Dados da Empresa"
        description="Confira os dados de contato e endereço de sua empresa, que devem ser
          os mesmos que constam no cadastro da Receita Federal."
      />
      <Form
        preserve
        form={companyForm}
        initialValues={initialValuesFormatted}
        layout="vertical"
        onFinish={handleOnFinish}
        onFieldsChange={(changedFields: FieldData[]) => {
          onFieldsChange(changedFields);

          changedFields.forEach(({ name }) => {
            onResolveError && onResolveError(name);
          });
        }}
      >
        <Row className={styles.formWrapper} gutter={32}>
          <Col span={12}>
            <FormItem
              required
              name="document"
              label="CNPJ"
              rules={[{ validator: formCnpjValidator }]}
            >
              <MaskedSearch
                mask={[cnpjMask]}
                size="large"
                placeholder="Informe o CNPJ"
                onSearch={onSearchDocument}
                loading={searchingDocument}
              />
            </FormItem>
          </Col>

          <Col span={12} />

          <Col span={12}>
            <FormItem name="name" label="Razão social" required>
              <Input size="large" placeholder="Ex: Empresa LTDA" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem name="tradeName" label="Nome da empresa" required>
              <Input size="large" placeholder="Ex: Empresa" />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              name="email"
              label="E-mail da empresa"
              required
              rules={[
                {
                  message: 'Formato de e-mail inválido',
                  pattern: emailRegex,
                },
              ]}
            >
              <Input size="large" placeholder="Ex: empresa@mail.com" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              required
              name="phone"
              label="Telefone"
              rules={[{ validator: phoneValidator }]}
            >
              <MaskedInput
                mask={[phoneMask, cellphoneMask]}
                size="large"
                placeholder="(00) 00000-0000"
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              required
              label="CEP"
              name={['address', 'postalCode']}
              shouldUpdate
              rules={[
                { validator: addressPostalCodeValidator },
                { validator: cityIsAllowedValidator },
              ]}
            >
              <MaskedSearch
                mask={[cepMask]}
                size="large"
                placeholder="00000-000"
                onSearch={onSearchAddress}
                loading={searchingAddress}
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem required label="Estado" name={['address', 'state']}>
              <StatesSelect />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem required label="Cidade" name={['address', 'city']}>
              <CitiesSelect stateCode={stateCode} />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem required label="Bairro" name={['address', 'district']}>
              <Input size="large" />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem required label="Rua" name={['address', 'street']}>
              <Input size="large" />
            </FormItem>
          </Col>

          <Col span={4}>
            <FormItem
              required
              label="Número"
              name={['address', 'number']}
              max={10}
            >
              <Input size="large" />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem label="Complemento" name={['address', 'details']}>
              <Input size="large" />
            </FormItem>
          </Col>
        </Row>

        <DrawerFooter>
          <DrawerFooter.SubmitButton loading={isSubmiting}>
            Continuar
          </DrawerFooter.SubmitButton>
        </DrawerFooter>
      </Form>
    </>
  );
}
