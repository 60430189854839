import t from 'zod';

export const FormParamsDecoder = t.object({
  key: t.string().min(1, 'Esse campo precisa ser preenchido'),
});

export type FormParams = t.TypeOf<typeof FormParamsDecoder>;

export type PostParams = {
  key: string;
};

export const ImpersonateDecoder = t.object({
  access: t.string(),
  refresh: t.string(),
  companyId: t.string(),
  organizationId: t.string(),
});

export type Impersonate = t.TypeOf<typeof ImpersonateDecoder>;
