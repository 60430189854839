import { Button, Tooltip, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { BankIcon } from 'src/mui/_scss';

import { useBank } from 'src/libs/finbits/Bank';

import styles from './BankSelected.module.less';

type Props = {
  routingNumber: string | null;
  onClickEdit?: () => void;
};

export default function BankSelected({ onClickEdit, routingNumber }: Props) {
  const { data: bank } = useBank(routingNumber);

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <BankIcon routingNumber={routingNumber} />
        <Typography.Text
          strong
          style={{ maxWidth: onClickEdit ? 240 : 300, marginLeft: '16px' }}
          ellipsis={{ tooltip: bank?.fullName }}
        >
          {bank?.fullName ?? 'Conta não bancarizada'}
        </Typography.Text>
      </div>
      {onClickEdit && (
        <Tooltip title="Mudar banco" placement="bottom">
          <Button
            aria-label="Mudar banco"
            icon={<EditOutlined />}
            size="large"
            type="text"
            onClick={onClickEdit}
          />
        </Tooltip>
      )}
    </div>
  );
}
