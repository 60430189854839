import styles from './TabTitle.module.less';

type Props = {
  title: string;
  count: number;
};

function TabTitle({ title, count }: Props) {
  return (
    <div className={styles.tabTitle}>
      {title}
      <span className={styles.count}>{count}</span>
    </div>
  );
}

export default TabTitle;
