import { useGridApiContext } from '@mui/x-data-grid-premium';
import { Button, Stack, Tooltip } from '@mui/material';
import { AlertTriangleIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { pluralize } from 'src/libs/finbits/Pluralize';

import styles from './AutomaticPaymentTableFooter.module.scss';

const TOOLTOP_MESSAGE =
  'Lembre-se: itens desmarcados precisarão ser incluídos manualmente no banco.';

export default function AutomaticPaymentTableFooter() {
  const { current: apiRef } = useGridApiContext();

  const allRows = apiRef.getRowModels().size;
  const selectedRows = apiRef.getSelectedRows().size;
  const unselectedRows = allRows - selectedRows;

  return (
    <>
      {unselectedRows > 0 && (
        <Stack direction="row" alignItems="center">
          <Typography variant="text-xs">
            {unselectedRows}{' '}
            {pluralize(unselectedRows, 'desmarcado', 'desmarcados')}
          </Typography>
          <Tooltip
            title={TOOLTOP_MESSAGE}
            data-testid="tooltip"
            placement="top"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <span>
              <Button
                size="small"
                aria-label="unselected-button"
                className={styles.tooltipButton}
                disabled
              >
                <AlertTriangleIcon className={styles.tooltip} />
              </Button>
            </span>
          </Tooltip>
        </Stack>
      )}
    </>
  );
}
