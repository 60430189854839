import { CollapseText } from 'src/mui';

import type { PaymentDetails } from 'src/libs/finbits/Payments/types';

type Props = {
  paymentDetails: PaymentDetails;
};

export default function PaymentDetailsInfo({ paymentDetails }: Props) {
  return (
    <CollapseText
      text={paymentDetails.paymentDetails ?? 'Sem detalhes de pagamento'}
    />
  );
}
