import { useState } from 'react';

import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { UsersIcon } from 'src/mui/_icons';

import { PageWrapper, Title } from 'src/ui';

import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import type { CreateEntryParams } from 'src/features/contacts/ContactsTable/Actions';
import CreateContactDrawer from 'src/features/contacts/CreateContactDrawer';
import ContactsTable from 'src/features/contacts/ContactsTable';
import EditContactDrawer from 'src/features/contacts/EditContactDrawer/EditContactDrawer';
import CreateScheduledDrawer from 'src/features/scheduled/CreateScheduledDrawer';
import ImportContactsModal from 'src/features/contacts/ImportContactsModal';
import CreateFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/CreateFinancialEntryDrawer';

import styles from './Contacts.module.less';

export default function Contacts() {
  const [editContact, setEditContact] = useState<Contact>();
  const [createScheduledEntry, setCreateScheduled] =
    useState<CreateEntryParams>();
  const [createFinancialEntry, setCreateFinancialEntry] =
    useState<CreateEntryParams>();

  const [isCreateDrawerVisible, setIsCreateDrawerVisible] = useState(false);
  const [isImportContactsModalVisible, setIsImportContactsModalVisible] =
    useState(false);

  function onCreateEntry(createEntryParams: CreateEntryParams) {
    if (createEntryParams.type === 'scheduled') {
      setCreateScheduled(createEntryParams);
    } else if (createEntryParams.type === 'financial') {
      setCreateFinancialEntry(createEntryParams);
    }
  }

  return (
    <PageWrapper>
      <Row align="top" gutter={[10, 32]}>
        <Title icon={<UsersIcon />}>Contatos</Title>

        {isCreateDrawerVisible && (
          <CreateContactDrawer
            onClose={() => setIsCreateDrawerVisible(false)}
          />
        )}

        {editContact && (
          <EditContactDrawer
            contactId={editContact.id}
            onClose={() => setEditContact(undefined)}
          />
        )}

        {createScheduledEntry && (
          <CreateScheduledDrawer
            initialValues={{
              contactId: createScheduledEntry.contactId,
            }}
            onClose={() => setCreateScheduled(undefined)}
          />
        )}

        {createFinancialEntry && (
          <CreateFinancialEntryDrawer
            initialValues={{
              contactId: createFinancialEntry.contactId,
            }}
            onClose={() => setCreateFinancialEntry(undefined)}
          />
        )}

        {isImportContactsModalVisible && (
          <ImportContactsModal
            visible={isImportContactsModalVisible}
            onClose={() => setIsImportContactsModalVisible(false)}
          />
        )}

        <Col span={24}>
          <Row justify="space-between">
            <Typography.Paragraph className={styles.description}>
              Para um controle de cadastros mais preciso, você poderá editar,
              deletar, adicionar ou ver o extrato de um contato a qualquer
              momento.
            </Typography.Paragraph>

            <Space>
              <WithAllowedPermission
                permissions={{
                  resource: 'contacts',
                  action: 'import_spreadsheet',
                }}
              >
                <Tooltip
                  placement="bottom"
                  title="Importar planilha de contatos"
                >
                  <Button
                    type="default"
                    aria-label="Importar planilha de contatos"
                    onClick={() => setIsImportContactsModalVisible(true)}
                    icon={<UploadOutlined />}
                  />
                </Tooltip>
              </WithAllowedPermission>
              <WithAllowedPermission
                permissions={{
                  resource: 'contacts',
                  action: 'create',
                }}
              >
                <Button
                  type="primary"
                  onClick={() => setIsCreateDrawerVisible(true)}
                  icon={<PlusOutlined />}
                >
                  Adicionar contato
                </Button>
              </WithAllowedPermission>
            </Space>
          </Row>
        </Col>
      </Row>

      <ContactsTable onEdit={setEditContact} onCreateEntry={onCreateEntry} />
    </PageWrapper>
  );
}
