import type { AxiosError } from 'axios';
import type { QueryClient } from 'react-query';
import { useQuery } from 'react-query';

import { authenticatedAPI } from 'src/libs/finbits/client';

import type { FinancialMovements } from './types';

type Params = {
  organizationId: string;
  companyId: string;
  startDate: string;
  endDate: string;
};

export async function financialMovements({
  organizationId,
  companyId,
  startDate,
  endDate,
}: Params) {
  const params = { startDate, endDate };

  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/financial_statements/movements`,
    { params }
  );

  return response.data;
}

export function useFinancialMovements({
  organizationId,
  companyId,
  startDate,
  endDate,
}: Params) {
  const { data, ...rest } = useQuery<
    FinancialMovements,
    AxiosError<{ message: string; errors: string }>
  >({
    queryKey: [
      'financialMovements',
      { organizationId, companyId, startDate, endDate },
    ],
    queryFn: () =>
      financialMovements({
        organizationId,
        companyId,
        startDate,
        endDate,
      }),
  });
  return { movements: data, ...rest };
}

export function invalidateFinancialMovementsQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['financialMovements']);
}
