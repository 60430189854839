import { useEffect } from 'react';

import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { isBefore } from 'date-fns';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { ONE_HOUR_IN_MS } from 'src/libs/finbits/Time';
import analytics from 'src/libs/analytics';
import {
  useCompany,
  useOptionalCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { useIsAuthenticated } from 'src/libs/finbits/Auth';

import { UserDecoder, UserWithPermissionsDecoder } from './types';
import type {
  ActivateOrganizationUserPostParams,
  CompanyUsersGetParams,
  DeactivateOrganizationUserPostParams,
  GetParams,
  PatchOptedInPendingApprovalsReportParams,
  User,
  UserCompanyPermissionPatchParams,
  UserOptInUpdateParams,
  UserOrganizationPermissionPatchParams,
  UserProfileUpdateParams,
  UserWithPermissions,
} from './types';

async function postActivateOrganizationUser({
  organizationId,
  userId,
}: ActivateOrganizationUserPostParams) {
  const url = `/organizations/${organizationId}/users/${userId}/activate`;
  const response = await authenticatedAPI.post(url);

  return decodeResponse<User>(response, UserDecoder);
}

async function postDeactivateOrganizationUser({
  organizationId,
  userId,
}: DeactivateOrganizationUserPostParams) {
  const url = `/organizations/${organizationId}/users/${userId}/deactivate`;
  const response = await authenticatedAPI.post(url);

  return decodeResponse<User>(response, UserDecoder);
}

async function patchUserCompanyPermission({
  organizationId,
  companyId,
  userId,
  ...params
}: UserCompanyPermissionPatchParams) {
  return authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/users/${userId}/permission`,
    params
  );
}

async function patchUserOrganizationPermission({
  organizationId,
  userId,
  ...params
}: UserOrganizationPermissionPatchParams) {
  return authenticatedAPI.patch(
    `/organizations/${organizationId}/users/${userId}/permissions`,
    params
  );
}

export function useActivateOrganizationUser() {
  const queryClient = useQueryClient();

  const { mutate: activateOrganizationUser, ...rest } = useMutation<
    User,
    ApiError,
    ActivateOrganizationUserPostParams
  >(postActivateOrganizationUser, {
    onSuccess: () => {
      invalidateUsersQueries(queryClient);
    },
  });

  return { activateOrganizationUser, ...rest };
}

export function useDeactivateOrganizationUser() {
  const queryClient = useQueryClient();

  const { mutate: deactivateOrganizationUser, ...rest } = useMutation<
    User,
    ApiError,
    DeactivateOrganizationUserPostParams
  >(postDeactivateOrganizationUser, {
    onSuccess: () => {
      invalidateUsersQueries(queryClient);
    },
  });

  return { deactivateOrganizationUser, ...rest };
}

export function useUpdateUserCompanyPermission() {
  const queryClient = useQueryClient();

  const { mutate: updateUserCompanyPermission, ...rest } = useMutation<
    unknown,
    ApiError,
    UserCompanyPermissionPatchParams
  >(patchUserCompanyPermission, {
    onSuccess: () => {
      invalidateUsersQueries(queryClient);
      invalidateProfileQueries(queryClient);
    },
  });

  return {
    updateUserCompanyPermission,
    ...rest,
  };
}

export function useUpdateUserOrganizationPermission() {
  const queryClient = useQueryClient();

  const { mutate: updateUserOrganizationPermission, ...rest } = useMutation<
    unknown,
    ApiError,
    UserOrganizationPermissionPatchParams
  >(patchUserOrganizationPermission, {
    onSuccess: () => {
      invalidateUsersQueries(queryClient);
      invalidateProfileQueries(queryClient);
    },
  });

  return {
    updateUserOrganizationPermission,
    ...rest,
  };
}

async function getOrganizationUsers({ organizationId, ...params }: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/users`,
    { params }
  );

  return decodeResponse<UserWithPermissions[]>(
    response,
    UserWithPermissionsDecoder.array()
  );
}

async function getCompanyUsers({
  organizationId,
  companyId,
  ...params
}: CompanyUsersGetParams) {
  const url = `/organizations/${organizationId}/companies/${companyId}/users`;

  const response = await authenticatedAPI.get(url, {
    params,
  });

  return decodeResponse<User[]>(response, UserDecoder.array());
}

export function useOrganizationUsers(
  params: GetParams,
  options?: UseQueryOptions<UserWithPermissions[], ApiError>
) {
  const { data, ...rest } = useQuery<UserWithPermissions[], ApiError>({
    enabled: !!params.organizationId,
    queryKey: ['organizationUsers', params],
    queryFn: () => getOrganizationUsers(params),
    ...options,
  });

  return { users: withEmptyArrayDefault(data), ...rest };
}

async function getProfile() {
  const response = await authenticatedAPI.get('/profile');

  return decodeResponse<UserWithPermissions>(
    response,
    UserWithPermissionsDecoder
  );
}

export function isFinbitsLabUser(email: string): boolean {
  const labRegex = /^bpo\+.*@finbits\.com\.br$/;
  return labRegex.test(email);
}

export function isFinbitsTeamUser(email: string): boolean {
  const teamRegex = /^.*@finbits\.co(m\.br)?$/;
  return teamRegex.test(email);
}

export function useProfile(
  queryConfigCustom: Partial<
    UseQueryOptions<UserWithPermissions, ApiError>
  > = {}
) {
  const isAuthenticated = useIsAuthenticated();
  const enabled = queryConfigCustom.enabled ?? true;

  const { data, ...rest } = useQuery<UserWithPermissions, ApiError>({
    queryKey: ['profile'],
    queryFn: () => getProfile(),
    staleTime: ONE_HOUR_IN_MS,
    ...queryConfigCustom,
    enabled: isAuthenticated && enabled,
  });

  return { user: data, ...rest };
}

export function useProfileInitialCompanyParams() {
  const { isLoading, user } = useProfile();

  if (isLoading || !user) {
    return { isLoading: true, companyId: undefined, organizationId: undefined };
  }

  const hasCompany = Boolean(user.companies?.length);

  return {
    isLoading: false,
    companyId: hasCompany ? user.companies![0].id : undefined,
    organizationId: user.organizationId,
  };
}

export function useAnalyticsIdentify() {
  const { user, isLoading: isLoadingUser } = useProfile();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!user || !isAuthenticated) return;

    analytics?.identify(user.id, {
      id: user.id,
      name: user.name,
      email: user.email,
      finbits_lab: isFinbitsLabUser(user.email),
      organization_id: user.organizationId,
      theme: 'light',
    });
  }, [user, isAuthenticated]);

  const { organizationId, companyId } = useOptionalCompanyParams();

  const { company, isLoading: isLoadingCompany } = useCompany(
    { organizationId, companyId },
    { staleTime: ONE_HOUR_IN_MS }
  );

  useEffect(() => {
    if (!company || !isAuthenticated) return;

    analytics?.plugins?.segment?.group(company.id, {
      organization_id: company.organizationId,
      company_id: company.id,
      name: company.name,
      email: company.email,
      document: company.document,
    });
  }, [company, isAuthenticated]);

  return { isLoadingUser, isLoadingCompany };
}

async function patchProfile(params: UserProfileUpdateParams) {
  const response = await authenticatedAPI.patch('/profile', params);

  return decodeResponse<User>(response, UserDecoder);
}

export function useUpdateProfile() {
  const queryClient = useQueryClient();

  const { mutate: updateProfile, ...rest } = useMutation<
    User,
    ApiError,
    UserProfileUpdateParams
  >(patchProfile, {
    onSuccess: () => {
      invalidateProfileQueries(queryClient);
    },
  });

  return { updateProfile, ...rest };
}

async function patchOptIn(params: UserOptInUpdateParams) {
  const response = await authenticatedAPI.patch(
    '/opted_in_daily_reports',
    params
  );

  return response.data;
}

async function patchOptedInPendingApprovalsReport(
  params: PatchOptedInPendingApprovalsReportParams
) {
  const response = await authenticatedAPI.patch(
    '/opted_in_pending_approvals_report',
    params
  );

  return response.data;
}

export function useUpdateOptedInDailyReports() {
  const queryClient = useQueryClient();

  const { mutate: updateOptedInDailyReports, ...rest } = useMutation<
    Pick<User, 'optedInDailyReports'>,
    ApiError,
    UserOptInUpdateParams
  >(patchOptIn, {
    onSuccess: () => {
      invalidateProfileQueries(queryClient);
    },
  });

  return { updateOptedInDailyReports, ...rest };
}

export function useUpdateOptedInApprovalsReport() {
  const queryClient = useQueryClient();

  const { mutate: updateOptedInApprovalsReport, ...rest } = useMutation<
    Pick<User, 'optedInDailyReports'>,
    ApiError,
    PatchOptedInPendingApprovalsReportParams
  >(patchOptedInPendingApprovalsReport, {
    onSuccess: () => {
      invalidateProfileQueries(queryClient);
    },
  });

  return { updateOptedInApprovalsReport, ...rest };
}

export function useCompanyUsers(
  { organizationId, companyId, ...params }: CompanyUsersGetParams,
  options?: UseQueryOptions<User[], ApiError>
) {
  const { data, ...rest } = useQuery<User[], ApiError>({
    enabled: !!organizationId && !!companyId,
    queryKey: ['companyUsers', { organizationId, companyId, ...params }],
    queryFn: () =>
      getCompanyUsers({
        organizationId,
        companyId,
        ...params,
      }),
    ...options,
  });

  return { users: withEmptyArrayDefault(data), ...rest };
}

export function useUserCompanies() {
  const { user, isLoading } = useProfile();

  const companies = user?.companies || [];

  return { companies, isLoading };
}

const TERMS_OF_USE_DATE = '2024-10-07 00:00:00';

export function userNeedsToAcceptTerms(user?: User): boolean {
  if (!user) {
    return false;
  }

  const acceptedAtIsBeforeTermsDate =
    user?.acceptedTermsAt &&
    isBefore(new Date(TERMS_OF_USE_DATE), new Date(user?.acceptedTermsAt));

  if (acceptedAtIsBeforeTermsDate) {
    return false;
  }

  return true;
}

export function invalidateProfileQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['profile']);
}

export function invalidateUsersQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['organizationUsers']);
  queryClient.invalidateQueries(['companyUsers']);
}
