import cn from 'classnames';
import type { DrawerProps } from 'antd';
import { Drawer as AntdDrawer } from 'antd';

import styles from './Drawer.module.less';

function Drawer({ className, ...rest }: DrawerProps) {
  return (
    <AntdDrawer
      className={cn([styles.drawer, className])}
      {...rest}
      width={800}
    />
  );
}

export default Drawer;
