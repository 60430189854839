import { useEffect, useState } from 'react';

import { MessageBirdIcon } from 'src/mui/_icons';
import { IconButton } from '@mui/material';

import { Badge } from 'src/mui';

import { initChat, logOut, toggleChat, useLoadSDK } from 'src/libs/messageBird';
import { useIsAuthenticated } from 'src/libs/finbits/Auth';
import { useProfile } from 'src/libs/finbits/Organization/Users';
import type { MessageHandlerData } from 'src/libs/messageBird/types';

function MessageBirdChat() {
  const [hasMessage, setHasMessage] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const sdkStatus = useLoadSDK();

  const { user } = useProfile();

  useEffect(() => {
    function handler(message: MessageEvent<MessageHandlerData>) {
      if (message.data.type === 'SET_DOCUMENT_TITLE' && message.data.title) {
        setHasMessage(true);
      }
    }

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  useEffect(() => {
    if (sdkStatus !== 'ready') return;

    if (isAuthenticated && user) {
      initChat(user);
    }

    return () => {
      if (isAuthenticated) logOut();
    };
  }, [user, isAuthenticated, sdkStatus]);

  function handleClick() {
    toggleChat();
    setHasMessage(false);
  }

  return (
    <IconButton size="large" aria-label="Ajuda" onClick={handleClick}>
      <Badge variant="dot" color="error" invisible={!hasMessage}>
        <MessageBirdIcon />
      </Badge>
    </IconButton>
  );
}

export default MessageBirdChat;
