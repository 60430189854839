import isArray from 'lodash/isArray';

import type {
  ImporterAddressError,
  ImporterBankDetailsError,
  ImporterError,
} from 'src/libs/finbits/Organization/Companies/Contacts/types';

type Props = {
  errors: ImporterError;
};

type flatErrorKey = ImporterError &
  ImporterAddressError &
  ImporterBankDetailsError;

const DICTIONARY: {
  [field in keyof flatErrorKey]: string;
} = {
  document: 'CPF / CNPJ',
  email: 'E-mail',
  name: 'Nome / Razão Social',
  nickname: 'Apelido / Nome da Empresa',
  phone: 'Telefone',
  type: 'Tipo',
  details: 'Complemento',
  number: 'Número do Endereço',
  postalCode: 'CEP',
  comments: 'Observação',
  accountNumber: 'Conta (com o dígito)',
  accountType: 'Tipo de Conta',
  branchNumber: 'Agência',
  pixKey: 'Chave PIX',
  routingNumber: 'Código do Banco',
};

type flatErrorsType = {
  key: keyof flatErrorKey;
  value: string[];
};

function flatErrors(
  errors:
    | ImporterError
    | ImporterAddressError
    | ImporterBankDetailsError
    | string[]
): flatErrorsType[] {
  return Object.entries(errors).reduce(
    (acc: flatErrorsType[], [key, value]) => {
      if (!isArray(value)) {
        return acc.concat(flatErrors(value));
      }

      return [...acc, { key: key as keyof flatErrorKey, value }];
    },
    []
  );
}

export default function RowErrors({ errors }: Props) {
  if (!errors) {
    return null;
  }

  const errorsFlat = flatErrors(errors);

  return (
    <>
      {errorsFlat.map((error) => (
        <li key={error.key}>
          {DICTIONARY[error.key]}: {error.value.join('; ')}
        </li>
      ))}
    </>
  );
}
