import compact from 'lodash/compact';
import t from 'zod';

import { REQUIRED_MESSAGE } from './required';

export const passwordValidationRules = [
  {
    pattern: /^.{8,}$/,
    message: 'use 8 caracteres ou mais',
  },
  {
    pattern: /[a-z]/,
    message: 'deve conter letras minúsculas',
  },
  {
    pattern: /[A-Z]/,
    message: 'deve conter letras maiúsculas',
  },
  {
    pattern: /\d/,
    message: 'deve conter números',
  },
  {
    pattern: /[^a-zA-Z\d]/,
    message: 'deve conter caracteres especiais (Ex.: @, # ou ^)',
  },
];

function validatePassword(password: string) {
  const errors = passwordValidationRules.map((rule) => {
    return password.match(rule.pattern) ? undefined : rule.message;
  });

  return compact(errors);
}

export const password = t
  .string({
    required_error: REQUIRED_MESSAGE,
  })
  .min(8, { message: 'Senha deve ter pelo menos 8 caracteres' });

export const newPassword = t
  .string({
    required_error: REQUIRED_MESSAGE,
  })
  .refine(
    (password) => {
      const errors = validatePassword(password);
      return errors.length === 0;
    },
    (password) => {
      const errors = validatePassword(password);
      const message = errors.join(', ');
      return {
        message: message.charAt(0).toUpperCase() + message.slice(1),
      };
    }
  );
