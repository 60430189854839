import type { LoadingButtonProps } from '@mui/lab';
import { LoadingButton } from '@mui/lab';

import { useBTGAuthenticationURL } from 'src/libs/finbits/Bank/OpenBanking';
import { useProfileInitialCompanyParams } from 'src/libs/finbits/Organization/Users';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

export default function BTGConnectionRedirectButton({
  children,
  companyId,
  ...rest
}: LoadingButtonProps & { companyId?: string }) {
  const { organizationId } = useProfileInitialCompanyParams();

  const { data, isLoading } = useBTGAuthenticationURL({
    organizationId: organizationId!,
  });

  return (
    <WithAllowedPermission
      permissions={{
        resource: 'btgAuthentication',
        action: 'show',
        scope: 'company',
        companyId,
      }}
    >
      <LoadingButton href={data?.url} loading={isLoading} {...rest}>
        {children}
      </LoadingButton>
    </WithAllowedPermission>
  );
}
