import type { AxiosError } from 'axios';

import { snackbar } from 'src/mui';

async function apiErrorsInterceptor(error: AxiosError) {
  const responseStatus = error?.response?.status ?? 0;

  if (responseStatus >= 500) {
    snackbar({
      variant: 'error',
      message:
        'Ocorreu um erro na comunicação com nosso servidor. Tente novamente em instantes.',
    });
  }

  return Promise.reject(error);
}

export default apiErrorsInterceptor;
