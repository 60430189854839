import { payeeDocumentType } from 'src/libs/finbits/Boletos/types';

export const boletoTypeDictionary = {
  banking: 'Boleto bancário',
  consumption: 'Boleto de consumo',
};

export const payeeDocumentTypeDictionary = {
  [payeeDocumentType.LEGAL]: 'CNPJ',
  [payeeDocumentType.NATURAL]: 'CPF',
};

export const payeeNameDictionary = {
  [payeeDocumentType.LEGAL]: 'Razão Social',
  [payeeDocumentType.NATURAL]: 'Nome',
};
