import type { QueryClient } from 'react-query';
import { useQuery } from 'react-query';

import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import type { AvailableToLinkNotaFiscal, GetParams } from './types';
import { AvailableToLinkNotaFiscalDecoder } from './types';

function availableEntriesToLinkCacheKey(params: GetParams) {
  return ['availableEntriesToLinkNotaFiscal', params];
}

export function invalidateEntriesToLinkNotaFiscalCache(
  queryClient: QueryClient
) {
  queryClient.invalidateQueries(['availableEntriesToLinkNotaFiscal']);
}

async function getAvailableEntriesToLinkNotaFiscal({
  organizationId,
  companyId,
  ...params
}: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/entries/available_to_link_notas_fiscais`,
    { params }
  );

  return decodeResponse<AvailableToLinkNotaFiscal>(
    response,
    AvailableToLinkNotaFiscalDecoder
  );
}

export function useAvailableEntriesToLinkNotaFiscal(params: GetParams) {
  const { data, ...rest } = useQuery({
    queryKey: availableEntriesToLinkCacheKey(params),
    queryFn: () => getAvailableEntriesToLinkNotaFiscal(params),
  });

  return {
    receivables: data?.receivables ?? [],
    financialEntries: data?.financialEntries ?? [],
    ...rest,
  };
}
