import { Tooltip, Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import { Card } from 'src/ui';

import { toDecimal } from 'src/libs/finbits/Money';
import { format } from 'src/libs/finbits/Date';
import type { ConciliationSuggestion } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions/types';

import { AccountInfo } from 'src/features/entries/Columns';

import styles from './SuggestionBox.module.less';
import Actions from './Actions';

type Props = {
  suggestion: ConciliationSuggestion;
};

export default function SuggestionBox({ suggestion }: Props) {
  const entries = [
    { type: 'financialEntry', entry: suggestion.financialEntry },
    { type: 'scheduledEntry', entry: suggestion.scheduledEntry },
  ] as const;

  return (
    <Card className={styles.suggestionBox}>
      <div className={styles.entries}>
        {entries.map(({ type, entry }) => (
          <div key={entry.id} className={styles.entryInfo}>
            <Typography.Text>{format(entry.date, 'dd/MM')}</Typography.Text>
            <div className={styles.calendarIcon}>
              {type === 'scheduledEntry' && (
                <Tooltip placement="top" title="Programado">
                  <CalendarOutlined className={styles.calendarIcon} />
                </Tooltip>
              )}
            </div>
            <Typography.Text
              className={styles.entryDescription}
              ellipsis={{ tooltip: entry.description }}
            >
              {entry.description}
            </Typography.Text>
            <AccountInfo account={entry.account} />
            <Typography.Text className={styles.entryAmount}>
              {toDecimal(entry.amount, entry.type)}
            </Typography.Text>
          </div>
        ))}
      </div>
      <Actions suggestionId={suggestion.id} />
    </Card>
  );
}
