import { useMutation } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import type {
  Boleto,
  CreateBoletoParams,
} from 'src/libs/finbits/Boletos/types';
import { BoletoDecoder } from 'src/libs/finbits/Boletos/types';

export const BOLETO_BANKING_SIZE = 47;
export const BOLETO_CONSUMPTION_SIZE = 48;

async function postBoleto({
  organizationId,
  companyId,
  ...params
}: CreateBoletoParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/boletos`,
    params
  );

  return decodeResponse<Boleto>(response, BoletoDecoder);
}

export function useCreateBoleto() {
  const { mutate: createBoleto, ...rest } = useMutation<
    Boleto,
    ApiError,
    CreateBoletoParams
  >(postBoleto);
  return { createBoleto, ...rest };
}
