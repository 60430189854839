import { useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';
import xor from 'lodash/xor';
import omitBy from 'lodash/omitBy';
import { Divider, Form } from 'antd';
import type { FieldData } from 'rc-field-form/lib/interface';

import { DrawerFooter, Stepper, useStepper } from 'src/ui';

import { errorsToFormField } from 'src/libs/finbits/Form';
import type { ApiErrorForm } from 'src/libs/finbits/client';
import type { PostParams } from 'src/libs/finbits/NotaFiscal/types';

import Review from '../Review';
import ServiceField from '../ServiceField';
import ContactField from '../ContactField';
import DescriptionField from '../DescriptionField';
import TaxesField from '../Taxes/TaxesField';
import { Taxes, TaxesRate } from '../Taxes/types';
import { amountFromRate, rateFromAmount } from '../Taxes/TaxesCalculator';

import styles from './CreateNotaFiscalForm.module.less';

type Props = {
  isCreating?: boolean;
  errors?: ApiErrorForm;
  initialValues?: Partial<PostParams>;
  onFieldsChange: () => void;
  onFinish: (params: PostParams) => void;
};

export default function CreateNotaFiscalForm({
  isCreating = false,
  errors,
  initialValues,
  onFinish,
  onFieldsChange,
}: Props) {
  const [form] = Form.useForm();

  const stepperStore = useStepper();
  const { currentStep, goNextStep, goBackStep } = stepperStore;
  const isReviewVisible = currentStep === 2;

  const [firstStepParams, setFirstStepParams] = useState<PostParams>();

  const [taxes, setTaxes] = useState<Taxes[]>(() => {
    if (!initialValues) return [];

    const { irrfAmount, inssAmount, csllAmount, cofinsAmount, pisAmount } =
      initialValues;

    const initialTaxes = omitBy(
      {
        [Taxes.IRRF]: irrfAmount,
        [Taxes.INSS]: inssAmount,
        [Taxes.CSLL]: csllAmount,
        [Taxes.COFINS]: cofinsAmount,
        [Taxes.PIS]: pisAmount,
      },
      (value) => value === null || value === undefined || value === 0
    );

    return Object.keys(initialTaxes) as Taxes[];
  });

  useEffect(() => {
    errors && form.setFields(errorsToFormField(errors));
  }, [form, errors]);

  function handleOnFinish(params: PostParams) {
    if (isReviewVisible) {
      onFinish({ ...firstStepParams, ...params });

      return;
    }

    setFirstStepParams(params);

    goNextStep();
  }

  function handleAddTaxes(newTaxes: Taxes[]) {
    setTaxes(newTaxes);

    const taxesToAdd = xor(taxes, newTaxes);

    const amount = form.getFieldValue('amount');

    const taxAmountFields = taxesToAdd.reduce((acc, tax) => {
      return {
        ...acc,
        [`${tax}Rate`]: TaxesRate[tax],
        [`${tax}Amount`]: amountFromRate(amount, TaxesRate[tax]),
      };
    }, {});

    form.setFieldsValue(taxAmountFields);
  }

  function handleFieldsChange(changedFields: FieldData[]) {
    if (!changedFields[0]?.name) return;

    const [{ name, value: fieldValue }] = changedFields;

    if (isEqual(name, ['amount'])) {
      const taxAmountFields = Object.values(Taxes).reduce((acc, tax) => {
        const taxRate = form.getFieldValue(`${tax}Rate`);

        return {
          ...acc,
          [`${tax}Amount`]: amountFromRate(fieldValue, taxRate),
        };
      }, {});

      form.setFieldsValue(taxAmountFields);
    }

    Object.values(Taxes).forEach((tax) => {
      if (isEqual(name, [`${tax}Rate`])) {
        const nfTotalAmount = form.getFieldValue('amount');

        const amount = amountFromRate(nfTotalAmount, fieldValue);

        form.setFieldsValue({
          [`${tax}Amount`]: amount,
        });
      }

      if (isEqual(name, [`${tax}Amount`])) {
        const nfTotalAmount = form.getFieldValue('amount');

        const rate = rateFromAmount(nfTotalAmount, fieldValue);

        form.setFieldsValue({
          [`${tax}Rate`]: rate,
        });
      }
    });
  }

  return (
    <Form
      preserve
      className={styles.form}
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={handleOnFinish}
      onFieldsChange={handleFieldsChange}
      onValuesChange={onFieldsChange}
      initialValues={{
        irrfRate: TaxesRate[Taxes.IRRF],
        inssRate: TaxesRate[Taxes.INSS],
        csllRate: TaxesRate[Taxes.CSLL],
        cofinsRate: TaxesRate[Taxes.COFINS],
        pisRate: TaxesRate[Taxes.PIS],
        issWithheldAtSource: false,
        ...initialValues,
      }}
    >
      <Form.Item name="receivableId" hidden>
        <input />
      </Form.Item>

      <Form.Item name="financialEntryId" hidden>
        <input />
      </Form.Item>

      <Stepper store={stepperStore} showContinue={false} showGoBack={false}>
        <Stepper.Step>
          <ContactField form={form} />
          <Divider />
          <ServiceField form={form} />
          <Divider />
          <TaxesField
            selectedTaxes={taxes}
            onSelectedTaxesChange={handleAddTaxes}
          />
          <Divider />
          <DescriptionField />
        </Stepper.Step>
        <Stepper.Step>
          <Review form={form} selectedTaxes={taxes} />
        </Stepper.Step>
      </Stepper>

      <DrawerFooter>
        {isReviewVisible && <DrawerFooter.BackButton onClick={goBackStep} />}

        <DrawerFooter.SubmitButton loading={isCreating}>
          {isReviewVisible ? 'Emitir nota fiscal' : 'Continuar para revisão'}
        </DrawerFooter.SubmitButton>
      </DrawerFooter>
    </Form>
  );
}
