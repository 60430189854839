import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import { ONE_HOUR_IN_MS } from 'src/libs/finbits/Time';

import { StatesDecoder } from './types';
import type { State } from './types';

async function getStates() {
  const response = await authenticatedAPI.get('/ibge/states');
  return decodeResponse<State[]>(response, StatesDecoder);
}

export function useStates<TData = State[]>() {
  return useQuery<State[], ApiError, TData>({
    queryKey: 'states',
    staleTime: ONE_HOUR_IN_MS,
    queryFn: getStates,
  });
}
