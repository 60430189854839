import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { useFormContext } from 'react-hook-form';
import { FormField, Input } from 'src/mui/_scss';

import styles from './ReproveFields.module.scss';

export default function ReproveFields() {
  const { control } = useFormContext();
  return (
    <Stack className={styles.form}>
      <FormField
        control={control}
        label="Motivo da reprovação (opcional)"
        name="reason"
      >
        {(field) => (
          <Input
            {...field}
            className={styles.motive}
            multiline
            rows={5}
            placeholder="Escreva o motivo da reprovação"
            title="Motivo"
          />
        )}
      </FormField>

      <Alert
        variant="filled"
        severity="default"
        icon={false}
        className={styles.alert}
      >
        <AlertTitle className={styles.alertTitle}>
          <FormField name="shouldDelete" control={control}>
            {(field) => (
              <FormControlLabel
                {...field}
                id="has-deletion-enabled"
                control={<Checkbox size="small" title="Excluir" />}
                label="Desejo excluir o pagamento"
                title="Excluir"
              />
            )}
          </FormField>
        </AlertTitle>
        <Stack>
          <Typography variant="text-xs" className={styles.checkboxInfo}>
            Ao excluir, o item será movido para a área de{' '}
            <strong>lançamentos excluídos</strong>, onde o pagamento
            correspondente não será realizado nem exibido no seu fluxo de caixa.
          </Typography>
        </Stack>
      </Alert>
    </Stack>
  );
}
