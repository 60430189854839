import type { ComponentProps, ReactNode } from 'react';

import { MenuItem, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

type Props = {
  icon?: ReactNode;
  children?: ReactNode;
} & ComponentProps<typeof MenuItem>;

export default function DropdownOption({ icon, children, ...rest }: Props) {
  return (
    <MenuItem {...rest}>
      <Stack direction="row" alignItems="center" gap={2}>
        {icon}
        <Typography fontWeight={500}>{children}</Typography>
      </Stack>
    </MenuItem>
  );
}
