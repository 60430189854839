import { useEffect, useMemo, useState } from 'react';

import type { FormProps } from 'antd';
import { Form, Modal } from 'antd';
import type { FieldData } from 'rc-field-form/lib/interface';
import isEqual from 'lodash/isEqual';
import { AlertCircleIcon } from 'src/mui/_icons';

import { useOpenConfirmDialog } from 'src/ui';

import {
  e164Format,
  phoneFormat,
} from 'src/libs/finbits/Organization/PhoneNumber';
import { unMask } from 'src/libs/finbits/Masker';
import { useAddress } from 'src/libs/finbits/BrazilianData/Addresses';
import { useLegalEntity } from 'src/libs/finbits/BrazilianData/LegalEntities';
import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import type {
  Company,
  CompanyUpdateParams,
} from 'src/libs/finbits/Organization/Companies/types';
import type { Address } from 'src/libs/finbits/BrazilianData/Addresses/types';

type Props = {
  initialValues?: Company;
  onChangeAddress?: () => void;
} & Pick<FormProps, 'form' | 'onFinish'>;

export function useCompanyForm({
  form,
  initialValues,
  onFinish,
  onChangeAddress,
}: Props) {
  const [companyForm] = Form.useForm(form);

  const [stateCode, setStateCode] = useState(
    initialValues?.address?.state ?? undefined
  );
  const [cityCode, setCityCode] = useState(
    initialValues?.address?.city ?? undefined
  );

  const [searchDocument, setSearchDocument] = useState<string>();
  const [searchPostalCode, setSearchPostalCode] = useState<string>();

  const { status: notaFiscalConnectionStatus } = useNotaFiscalConnection();
  const openConfirmDialog = useOpenConfirmDialog();
  const isNotaFiscalEnabled = notaFiscalConnectionStatus !== 'unset';

  useEffect(() => {
    return () => Modal.destroyAll();
  }, []);

  const { isFetching: searchingAddress, refetch: refetchAddress } = useAddress(
    { postalCode: searchPostalCode },
    {
      retry: false,
      onSuccess: (addressInfo) => {
        const { state, city, ...restAddress } = addressInfo;

        const isAddressIncompatibility = validateAddress({
          city,
          state,
        });

        if (isAddressIncompatibility) return;

        setStateCode(state ?? undefined);
        setCityCode(city ?? undefined);

        companyForm.setFieldsValue({
          address: {
            ...restAddress,
            state,
            city,
            postalCode: searchPostalCode,
          },
        });

        onChangeAddress && onChangeAddress();
      },
    }
  );

  const { isFetching: searchingDocument, refetch: refetchDocument } =
    useLegalEntity(
      {
        document: searchDocument,
      },
      {
        retry: false,
        onSuccess: (legalEntity) => {
          const {
            name,
            email,
            nickname: tradeName,
            phone,
            address,
          } = legalEntity;

          const { city, state } = address;

          const isAddressIncompatibility = validateAddress({
            city,
            state,
          });

          if (isAddressIncompatibility) return;

          setCityCode(city ?? undefined);
          setStateCode(state ?? undefined);

          companyForm.setFieldsValue({
            name,
            email: email ? email.toLowerCase() : null,
            tradeName,
            phone: phone ? phoneFormat(phone) : null,
            address: {
              ...address,
              postalCode: address.postalCode
                ? unMask(address.postalCode)
                : null,
            },
          });

          onChangeAddress && onChangeAddress();
        },
      }
    );

  const initialValuesFormatted = useMemo(() => {
    return {
      ...initialValues,
      phone: initialValues?.phone ? phoneFormat(initialValues.phone) : null,
    };
  }, [initialValues]);

  function validateAddress(address: Pick<Address, 'city' | 'state'>) {
    const isAddressIncompatibility =
      isNotaFiscalEnabled &&
      !isEqual(address, { state: stateCode, city: cityCode });

    companyForm.setFields([
      {
        name: ['address', 'postalCode'],
        errors: isAddressIncompatibility ? [''] : [],
      },
    ]);

    if (isAddressIncompatibility) {
      openConfirmDialog({
        variant: 'error',
        icon: <AlertCircleIcon />,
        title: 'Ops!',
        content:
          'Não é possível mudar de cidade em razão da emissão de notas fiscais de serviço.',
        confirmText: 'Entendi',
        confirmButtonProps: {
          variant: 'outlined',
          color: 'inherit',
        },
      });
    }

    return isAddressIncompatibility;
  }

  function onSubmit(data: CompanyUpdateParams) {
    const params: CompanyUpdateParams = {
      ...data,
      phone: data.phone ? e164Format(data.phone) : null,
    };

    onFinish && onFinish(params);
  }

  function onFieldsChange(changedFields: FieldData[]) {
    if (!changedFields[0]?.name) return;

    const [{ name }] = changedFields;

    if (isEqual(name, ['address', 'state'])) {
      setStateCode(changedFields[0].value);
      companyForm.setFieldsValue({ address: { city: undefined } });
    }

    if (isEqual(name, ['address', 'city'])) {
      onChangeAddress && onChangeAddress();
    }
  }

  function onSearchAddress() {
    const currentPostalCode = companyForm.getFieldValue([
      'address',
      'postalCode',
    ]);

    if (currentPostalCode?.length !== 8) return;

    if (currentPostalCode === searchPostalCode) {
      return refetchAddress();
    }

    setSearchPostalCode(currentPostalCode);
  }

  function onSearchDocument() {
    const currentDocument = companyForm.getFieldValue('document');

    if (currentDocument === searchDocument) {
      return refetchDocument();
    }

    setSearchDocument(currentDocument);
  }

  return {
    companyForm,
    stateCode,
    searchingAddress,
    searchingDocument,
    initialValuesFormatted,
    isNotaFiscalEnabled,
    onSubmit,
    onSearchDocument,
    onSearchAddress,
    onFieldsChange,
  };
}
