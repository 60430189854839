import { Typography } from 'antd';

import { useProfile } from 'src/libs/finbits/Organization/Users';
import type { User } from 'src/libs/finbits/Organization/Users/types';
import { UserStatus } from 'src/libs/finbits/Organization/Users/types';

type Props = {
  user: User;
};

export default function UserName({ user }: Props) {
  const { user: currentUser } = useProfile();

  const isCurrentUser = currentUser?.id === user.id;
  const isActive = user.status === UserStatus.ACTIVE;

  if (isActive) {
    return (
      <Typography.Text
        ellipsis={{
          suffix: isCurrentUser ? ' (você)' : undefined,
          tooltip: user.name,
        }}
      >
        {user.name}
      </Typography.Text>
    );
  }

  return (
    <Typography.Text
      ellipsis={{
        tooltip: user.name,
        suffix: ' (desativado)',
      }}
      type="secondary"
    >
      {user.name}
    </Typography.Text>
  );
}
