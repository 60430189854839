import { Box } from '@mui/material';
import { Typography } from 'src/design-system/components';

import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useBillsPendingMyApproval } from 'src/libs/finbits/Bills';

import BillsToPayDataGrid from 'src/features/bills-to-pay/BillsToPayDataGrid';

import styles from './Approval.module.scss';

export default function Approval() {
  const { companyId, organizationId } = useCompanyParams();

  const { bills, isLoading, isFetching } = useBillsPendingMyApproval({
    companyId,
    organizationId,
  });

  return (
    <Box className={styles.containerMui}>
      <Typography variant="text-lg" fontWeight="bold">
        Aprovações
      </Typography>

      <BillsToPayDataGrid
        bills={bills}
        isLoading={isLoading || isFetching}
        billStatus={BillStatus.WAITING_APPROVAL}
      />
    </Box>
  );
}
