import type { ReactNode } from 'react';
import { Children, useState } from 'react';

import { Box, Button } from '@mui/material';
import { ArrowLeftIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import styles from './Stepper.module.scss';

export type Props = {
  children: ReactNode;
  currentStep?: number;
  disabledNextButton?: boolean;
  onNextStep?: (currentStep: number) => Promise<boolean> | boolean;
  onPreviousStep?: (currentStep: number) => void;
  showButtonLastStep?: boolean;
  textButtonLastStep?: string;
};

export default function Stepper({
  children,
  currentStep = 0,
  disabledNextButton = false,
  onNextStep,
  onPreviousStep,
  showButtonLastStep = false,
  textButtonLastStep = 'Adicionar',
}: Props) {
  const childrenArray = Children.toArray(children);
  const [step, setStep] = useState(currentStep);
  const totalSteps = childrenArray.length;
  const isLastStep = step + 1 === totalSteps;
  const isFirstStep = step === 0;

  function handleBack() {
    if (isFirstStep) {
      onPreviousStep?.(step);
      return step;
    }

    onPreviousStep?.(step - 1);
    return setStep(step - 1);
  }

  async function handleNext() {
    const goToNextStep = (await onNextStep?.(step)) ?? true;

    if (!goToNextStep) return;

    return isLastStep ? step : setStep(step + 1);
  }

  return (
    <Box>
      <Typography color="grey.500" variant="overline">
        ETAPA {step + 1} DE {totalSteps}
      </Typography>

      {childrenArray[step]}

      <Box className={styles.bottom}>
        <Box className={styles.backButtonContainer}>
          {!isFirstStep && (
            <Button
              onClick={handleBack}
              color="secondary"
              startIcon={<ArrowLeftIcon />}
            >
              VOLTAR
            </Button>
          )}
        </Box>

        <Box className={styles.nextButtonContainer}>
          {(!isLastStep || showButtonLastStep) && (
            <Button
              onClick={handleNext}
              color="primary"
              variant="contained"
              disabled={disabledNextButton}
            >
              {showButtonLastStep && isLastStep
                ? textButtonLastStep
                : 'Continuar'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
