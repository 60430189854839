import t from 'zod';
import type { RcFile } from 'rc-upload/lib/interface';

import { AddressDecoder } from 'src/libs/finbits/BrazilianData/Addresses/types';
import { PixType } from 'src/libs/finbits/Bank/Pix/types';

import { ContactType } from './enum';

export const BankDetailsEncoder = t.object({
  routingNumber: t.string().nullable(),
  bankName: t.string().nullable(),
  branchNumber: t.string().nullable(),
  accountNumber: t.string().nullable(),
  accountType: t
    .union([t.literal('checking_account'), t.literal('savings_account')])
    .nullable(),
  pixType: t.nativeEnum(PixType).nullable(),
  pixKey: t.string().nullable(),
});

export const ContactDecoder = t.object({
  id: t.string(),
  document: t.string().nullable(),
  email: t.string().nullable(),
  name: t.string(),
  nickname: t.string(),
  phone: t.string().nullable(),
  type: t.nativeEnum(ContactType).nullable(),
  active: t.boolean(),
  address: AddressDecoder.nullish(),
  comments: t.string().nullable(),
  bankDetails: BankDetailsEncoder.nullish(),
  isInternational: t.boolean(),
});

export const ContactsDecoder = t.array(ContactDecoder);

export type Contact = t.TypeOf<typeof ContactDecoder>;
export type BankDetails = t.TypeOf<typeof BankDetailsEncoder>;

export type GetParams = {
  organizationId?: string;
  companyId?: string;
};

export type GetCompanyContactParams = {
  contactId?: string | null;
  organizationId?: string;
  companyId?: string;
};

export type PostParams = Omit<Contact, 'id' | 'active' | 'bankDetails'> & {
  bankDetails?: null | Omit<BankDetails, 'bankName'>;
  organizationId: string;
  companyId: string;
};

export type PatchParams = Omit<PostParams, 'document' | 'type'> & {
  id: string;
  active?: boolean;
};

export type ImportParams = {
  organizationId: string;
  companyId: string;
  file: RcFile;
};

const ImporterIgnoredEncoder = t.object({
  rowNumber: t.number(),
});

const AddressErrorEncoder = t
  .object({
    details: t.string().array(),
    number: t.string().array(),
    postalCode: t.string().array(),
  })
  .partial();

const BankDetailsErrorEncoder = t
  .object({
    accountNumber: t.string().array(),
    accountType: t.string().array(),
    branchNumber: t.string().array(),
    pixKey: t.string().array(),
    routingNumber: t.string().array(),
  })
  .partial();

const ErrorEncoder = t
  .object({
    document: t.string().array(),
    email: t.string().array(),
    name: t.string().array(),
    nickname: t.string().array(),
    phone: t.string().array(),
    type: t.string().array(),
    comments: t.string().array(),
    address: AddressErrorEncoder,
    bankDetails: BankDetailsErrorEncoder,
  })
  .partial();

export type ImporterAddressError = t.TypeOf<typeof AddressErrorEncoder>;

export type ImporterBankDetailsError = t.TypeOf<typeof BankDetailsErrorEncoder>;

export type ImporterError = t.TypeOf<typeof ErrorEncoder>;

const ImporterFailuresEncoder = t.object({
  errors: ErrorEncoder,
  rowNumber: t.number(),
});

const ImporterContactEncoder = t.object({
  rowNumber: t.number(),
  data: ContactDecoder,
});

export type ImporterFailure = t.TypeOf<typeof ImporterFailuresEncoder>;

export type ImporterIgnored = t.TypeOf<typeof ImporterIgnoredEncoder>;

type ImporterContact = t.TypeOf<typeof ImporterContactEncoder>;

export const ImportContactDecoder = t.object({
  contacts: ImporterContactEncoder.array(),
  failures: ImporterFailuresEncoder.array(),
  ignoredRows: ImporterIgnoredEncoder.array(),
});

export type ImportContactResponse = {
  contacts: ImporterContact[];
  failures: ImporterFailure[];
  ignoredRows: ImporterIgnored[];
};
