import { DialogContent, Divider, Link, Stack } from '@mui/material';
import { ScalesIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { Dialog } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import BalanceInconsistencyList from '../BalanceInconsistencyList';
import type { BalanceInconsistency } from '../types';

import BalanceInconsistencyHeader from './BalanceInconsistencyHeader';

type Props = {
  accounts: BalanceInconsistency[];
  onClose: () => void;
} & PortalProps;

export default function BalanceInconsistencyDialog({
  onClose,
  accounts,
  open = true,
  onExit,
}: Props) {
  const inconsistencyAccounts = accounts.filter(
    (account) => account.inconsistency !== null
  );

  const creditAccounts = inconsistencyAccounts.filter(
    (account) =>
      account?.inconsistency?.inconsistencyValue.type === BalanceType.CREDIT
  );

  const debitAccounts = inconsistencyAccounts.filter(
    (account) =>
      account?.inconsistency?.inconsistencyValue.type === BalanceType.DEBIT
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: onExit }}
    >
      <Dialog.Header
        icon={ScalesIcon}
        title="Diferença de Saldo"
        description="Identificamos diferenças de saldo nas suas contas."
        onClose={onClose}
      />

      <Divider />

      <DialogContent>
        <Stack direction="column" gap={8}>
          {creditAccounts?.length > 0 && (
            <>
              <BalanceInconsistencyHeader
                title="Diferenças Positivas"
                description="Verifique se existem transações duplicadas em seu extrato.
                  Essa é a causa mais provável para uma diferença positiva."
                length={creditAccounts?.length}
              />
              <BalanceInconsistencyList accounts={creditAccounts} />
            </>
          )}

          {debitAccounts?.length > 0 && (
            <>
              <BalanceInconsistencyHeader
                title="Diferenças Negativas"
                description={
                  <>
                    Uma diferença negativa indica que você possivelmente excluiu
                    transações de seu extrato. Verifique as transações
                    excluídas.
                  </>
                }
                length={debitAccounts?.length}
              />
              <BalanceInconsistencyList accounts={debitAccounts} />
            </>
          )}
        </Stack>
      </DialogContent>

      <Divider />

      <DialogContent>
        <Typography>
          Precisa de ajuda? Fale conosco em{' '}
          <Link href="mailto:suporte@finbits.com.br" fontWeight="500">
            suporte@finbits.com.br
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
