import type { PortalProps } from 'src/ui';

import { Provider } from 'src/libs/finbits/Bank/OpenBanking/types';

import ChooseBank from './ChooseBank';

type Props = {
  organizationId: string;
  companyId: string;
  openModal: () => void;
  onCloseImportFinish: () => void;
} & PortalProps;

function CreateAccount({
  organizationId,
  companyId,
  open = true,
  onClose,
  openModal,
  onExit,
}: Props) {
  return (
    <>
      {open && (
        <ChooseBank
          organizationId={organizationId}
          companyId={companyId}
          open={open}
          onExit={onExit}
          onClose={onClose}
          onConnectWidgetCancel={openModal}
          provider={Provider.PLUGGY}
        />
      )}
    </>
  );
}

export default CreateAccount;
