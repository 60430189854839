import { Tabs } from 'antd';

import type { PortalProps } from 'src/ui';
import { BadgeIcon, Drawer, DrawerHeader, PaymentIcon, Title } from 'src/ui';

import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';

import Services from './Services';
import ComplementaryData from './ComplementaryData';
import DigitalCertificate from './DigitalCertificate';
import styles from './EditSetupNotaFiscalDrawer.module.less';

enum TabKey {
  COMPLEMENTARY_DATA = '1',
  SERVICES = '2',
  MONITORING = '3',
  CERTIFICATE = '4',
}

export default function EditSetupNotaFiscalDrawer({
  onClose,
  open = true,
  onExit,
}: PortalProps) {
  const { status } = useNotaFiscalConnection();

  const isPendingUploadDigitalCertificate = status === 'activating';

  return (
    <Drawer
      title={
        <DrawerHeader
          title={<Title icon={<PaymentIcon />}>Nota fiscal de serviço</Title>}
        />
      }
      className={styles.drawer}
      footer={null}
      onClose={onClose}
      visible={open}
      afterVisibleChange={onExit}
    >
      <Tabs
        defaultActiveKey={
          isPendingUploadDigitalCertificate
            ? TabKey.CERTIFICATE
            : TabKey.COMPLEMENTARY_DATA
        }
        className={styles.tabs}
      >
        <Tabs.TabPane
          tab="Dados Complementares"
          key={TabKey.COMPLEMENTARY_DATA}
        >
          <ComplementaryData />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Serviços" key={TabKey.SERVICES}>
          <Services />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <BadgeIcon hidden={!isPendingUploadDigitalCertificate}>
              Certificado Digital
            </BadgeIcon>
          }
          key={TabKey.CERTIFICATE}
        >
          <DigitalCertificate />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
}
