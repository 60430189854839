import { Form } from 'antd';
import { CheckIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import { useUpdateDigitalCertificate } from 'src/libs/finbits/NotaFiscal/Setup';
import {
  errorsToFormField,
  isStructuredFormError,
} from 'src/libs/finbits/Form';
import type { UpdateDigitalCertificateFormParams } from 'src/libs/finbits/NotaFiscal/Setup/types';

import DigitalCertificateStep from 'src/features/nota-fiscal/Steps/DigitalCertificateStep';

export default function DigitalCertificate() {
  const [form] = Form.useForm();

  const { status, certificateExpiration } = useNotaFiscalConnection();
  const openConfirmDialog = useOpenConfirmDialog();

  const { companyId, organizationId } = useCompanyParams();
  const { updateDigitalCertificate, isLoading } = useUpdateDigitalCertificate();

  const isPendingUploadDigitalCertificate = status === 'activating';

  function handleOnSubmit({
    password,
    file,
  }: UpdateDigitalCertificateFormParams) {
    if (!companyId || !organizationId) return;

    updateDigitalCertificate(
      {
        file,
        password,
        organizationId,
        companyId,
      },
      {
        onSuccess: () => {
          if (isPendingUploadDigitalCertificate) {
            openEnabledEmissionDialog();
          }

          snackbar({
            variant: 'success',
            message: 'Alterações salvas com sucesso.',
          });

          form.setFields([
            { name: 'file', touched: false },
            { name: 'password', touched: false },
          ]);
        },
        onError: ({ response }) => {
          const data = response?.data;

          if (isStructuredFormError(data)) {
            return form.setFields(errorsToFormField(data.errors));
          }

          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar as alterações.',
          });
        },
      }
    );
  }

  function openEnabledEmissionDialog() {
    openConfirmDialog({
      title: 'Emissão de notas fiscais habilitada!',
      content:
        'É importante frisar que a confirmação da comunicação completa com a ' +
        'Prefeitura é feita no momento de emissão da primeira nota fiscal.',
      variant: 'success',
      icon: <CheckIcon />,
    });
  }

  return (
    <DigitalCertificateStep
      form={form}
      showTitle={false}
      onSubmit={handleOnSubmit}
      buttonText="Salvar"
      isButtonLoading={isLoading}
      certificateExpiration={certificateExpiration}
    />
  );
}
