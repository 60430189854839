import { Empty, List } from 'antd';

import { Title } from 'src/ui';

import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAccountsBalances } from 'src/libs/finbits/Organization/Companies/Balances';

import TotalAmount from 'src/features/companies-balances/TotalAmount';

import AccountBox from './AccountBox';
import styles from './CurrentMonthBankBalance.module.less';

export default function CurrentMonthBankBalance() {
  const { companyId, organizationId } = useCompanyParams();

  const { accounts, isLoading: isLoadingAccounts } = useAccounts({
    companyId,
    organizationId,
  });

  const { accountsBalances, isLoading: isLoadingAccountsBalances } =
    useAccountsBalances({
      companyId,
      organizationId,
    });

  const isLoading = isLoadingAccounts || isLoadingAccountsBalances;

  return (
    <>
      <Title>Saldo bancário do mês atual</Title>
      <List
        dataSource={accounts}
        loading={isLoading}
        className={styles.accountsList}
        renderItem={(account) => {
          const balance = accountsBalances.find(
            ({ accountId }) => accountId === account.id
          );

          return <AccountBox account={account} balance={balance} />;
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Você ainda não tem contas adicionadas"
            />
          ),
        }}
      />
      {accountsBalances.length > 0 && (
        <TotalAmount balances={accountsBalances} />
      )}
    </>
  );
}
