import { CollapseText } from 'src/mui';

import type { ReceivedEmail } from 'src/libs/finbits/Management/NewInboxItems/types';

type Props = {
  receivedEmail?: ReceivedEmail | null;
};

export default function EmailDetails({ receivedEmail }: Props) {
  if (!receivedEmail) return null;

  const textBody =
    receivedEmail.textBody === ''
      ? null
      : receivedEmail.textBody?.replaceAll('\n', '<br />');

  const htmlBody =
    receivedEmail.htmlBody === '' ? null : receivedEmail.htmlBody;

  return <CollapseText text={(htmlBody || textBody) ?? 'Email sem conteúdo'} />;
}
