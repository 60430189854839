import { useState } from 'react';

import type { FormInstance } from 'antd';
import { Button, Row, Skeleton, Space, Typography } from 'antd';
import type { ButtonType } from 'antd/lib/button';
import { RetweetOutlined } from '@ant-design/icons';

import { FormItem } from 'src/ui';

import {
  getServiceId,
  useCompanyServices,
  useServices,
} from 'src/libs/finbits/NotaFiscal/Services';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type {
  CompanyService,
  Service,
} from 'src/libs/finbits/NotaFiscal/Services/types';
import { useCompany } from 'src/libs/finbits/Organization/Companies/Companies';

import ServicesDrawer from 'src/features/nota-fiscal/ServicesDrawer';
import ServiceCard from 'src/features/nota-fiscal/Steps/ServicesStep/ServiceCard';
import { amountFromRate } from 'src/features/nota-fiscal/CreateNotaFiscalDrawer/Taxes/TaxesCalculator';

type Props = {
  form: FormInstance;
};

export default function ServiceField({ form }: Props) {
  const [isServicesDrawerVisible, setIsServicesDrawerVisible] = useState(false);
  const [service, setService] = useState<Service | CompanyService>();
  const [buttonProps, setButtonProps] = useState<{
    type: ButtonType;
    title: string;
    danger: boolean;
  }>({
    title: 'Trocar serviço',
    type: 'dashed',
    danger: false,
  });

  const { companyId, organizationId } = useCompanyParams();
  const { company } = useCompany({ organizationId, companyId });

  const city = company?.address?.city;
  const initialCompanyServices = service ? [service] : [];

  useServices(
    { city },
    {
      onSuccess: (services) => {
        const municipalServiceId = form.getFieldValue('municipalServiceId');
        const issRate = form.getFieldValue('issRate');

        if (municipalServiceId && !service) {
          const serviceByMunicipalId = services.find(
            (service) => getServiceId(service) === municipalServiceId
          );

          changeService(serviceByMunicipalId, issRate);
        }
      },
    }
  );

  const { services: companyServices = [], isLoading } = useCompanyServices(
    {
      companyId,
      organizationId,
    },
    {
      onSuccess: (companyServices) => {
        const municipalServiceId = form.getFieldValue('municipalServiceId');

        if (!municipalServiceId && !service) {
          return changeService(
            companyServices.find(
              (companyService) => companyService.mainActivity
            )
          );
        }
      },
    }
  );

  function toggleServicesDrawerVisibility() {
    setIsServicesDrawerVisible((prevState) => !prevState);
  }

  function toggleButtonPropsByService(service?: Service | CompanyService) {
    if (service?.active) {
      setButtonProps({
        type: 'dashed',
        title: 'Trocar serviço',
        danger: false,
      });
      return;
    }

    setButtonProps({
      type: 'default',
      title: 'Selecione um serviço válido',
      danger: true,
    });
  }

  function changeService(service?: Service | CompanyService, issRate?: number) {
    setService(service);

    if (service?.active) {
      const serviceRate = issRate ?? service?.suggestedRate ?? 0;
      const totalAmount = form.getFieldValue('amount');

      form.setFieldsValue({
        municipalServiceId: getServiceId(service),
        issRate: serviceRate,
        issAmount: amountFromRate(totalAmount, serviceRate),
      });
    } else {
      form.setFieldsValue({
        municipalServiceId: undefined,
        issRate: undefined,
        issAmount: undefined,
      });
    }

    toggleButtonPropsByService(service);

    setIsServicesDrawerVisible(false);
  }

  return (
    <>
      {isServicesDrawerVisible && (
        <ServicesDrawer
          city={city}
          type="radio"
          companyServices={companyServices}
          initialSelectedServices={initialCompanyServices}
          onChangeService={changeService}
          onClose={toggleServicesDrawerVisibility}
        />
      )}

      <Row gutter={[0, 24]}>
        <Space direction="vertical">
          <Typography.Text strong>Serviço municipal</Typography.Text>
          <Typography.Text>
            O serviço principal está selecionado, mas você também pode trocá-lo.
          </Typography.Text>
        </Space>

        <Skeleton
          title={false}
          loading={!service || isLoading}
          paragraph={{ rows: 3, width: '100%' }}
        >
          {service && <ServiceCard service={service} showSuggestedRate />}
        </Skeleton>

        <FormItem required name="municipalServiceId" noStyle>
          <Button
            {...buttonProps}
            block
            size="large"
            htmlType="button"
            aria-label={buttonProps.title}
            icon={<RetweetOutlined />}
            onClick={toggleServicesDrawerVisibility}
            title="Trocar serviço"
          >
            {buttonProps.title}
          </Button>
        </FormItem>
      </Row>
    </>
  );
}
