import { Fragment, useState } from 'react';

import { Col, Divider, Empty, Row, Space, Typography } from 'antd';

import { Loader } from 'src/ui';

import { redirectPathAfterStatementsImport } from 'src/libs/finbits/Bank/Statements';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import type { OpenBankingConnection } from 'src/libs/finbits/Bank/OpenBanking/types';

import AccountCard from 'src/features/DEPRECATED_accounts/AccountsList/AccountCard';
import ConnectionCard from 'src/features/DEPRECATED_accounts/AccountsList/ConnectionCard';
import ImportOfxModal from 'src/features/ofx/ImportOfxModal';

import styles from './AccountsList.module.less';

type Props = {
  loading: boolean;
  accounts?: Account[];
  pendingConnections?: OpenBankingConnection[];
  openEdit: (account: Account) => void;
  openAccountCreation: () => void;
};

function AccountsList({
  loading,
  accounts,
  pendingConnections,
  openEdit,
  openAccountCreation,
}: Props) {
  const [ofxModalVisible, setOfxModalVisible] = useState(false);

  const { organizationId, companyId } = useCompanyParams();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ImportOfxModal
        organizationId={organizationId}
        companyId={companyId}
        open={ofxModalVisible}
        redirectPathAfterImport={redirectPathAfterStatementsImport}
        onClose={() => {
          setOfxModalVisible(false);
        }}
      />
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        {accounts?.length === 0 && pendingConnections?.length === 0 && (
          <Row>
            <Col span={24} className={styles.empty}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <Typography.Text>
                    Você ainda não tem contas adicionadas.
                  </Typography.Text>
                }
              />
            </Col>
          </Row>
        )}
        {pendingConnections?.map((connection) => (
          <Fragment key={connection.id}>
            <Divider />
            <Row>
              <Col span={24}>
                <ConnectionCard connection={connection} />
              </Col>
            </Row>
          </Fragment>
        ))}
        {accounts?.map((account) => (
          <Fragment key={account.id}>
            <Divider />
            <Row>
              <Col span={24}>
                <AccountCard
                  account={account}
                  openEdit={openEdit}
                  openImportOfx={() => setOfxModalVisible(true)}
                  openAccountCreation={openAccountCreation}
                />
              </Col>
            </Row>
          </Fragment>
        ))}
      </Space>
    </>
  );
}

export default AccountsList;
