import { Chip, Typography } from 'src/design-system/components';
import { Avatar, Box, Stack } from '@mui/material';
import { InboxIcon } from 'src/mui/_icons';

export default function Components() {
  return (
    <Stack gap={2}>
      <Typography>Variants</Typography>
      <Box>
        <Chip label="meu chipzao" variant="filled" />
        <br />
        <Chip label="meu chipzao" variant="outlined" square />
        <br />
        <Chip label="meu chipzao" variant="standard" />
      </Box>

      <Typography>Sizes</Typography>

      <Box>
        <Chip variant="outlined" size="large" label="meu chipzao" />
        <br />
        <Chip
          variant="outlined"
          size="large"
          label="meu chipzao"
          icon={<InboxIcon />}
        />
        <br />
        <Chip
          variant="outlined"
          size="large"
          label="meu chipzao"
          avatar={<Avatar>M</Avatar>}
        />
        <br />
        <Chip
          variant="outlined"
          size="large"
          label="meu chipzao"
          onDelete={() => {}}
        />
        <br />
        <Chip variant="outlined" size="large" label="1" />
        <Chip variant="outlined" size="large" label="1" square />
        <br />
        <Chip avatar={<Avatar>M</Avatar>} variant="outlined" size="large" />
        <br />
        <Chip
          avatar={<Avatar>M</Avatar>}
          variant="outlined"
          size="large"
          onDelete={() => {}}
        />
        <br />
        <Chip
          icon={<InboxIcon />}
          variant="outlined"
          size="large"
          onDelete={() => {}}
        />
        <br />
        <Chip icon={<InboxIcon />} variant="outlined" size="large" />
        <br />
        <br />
        <Chip variant="outlined" size="medium" label="meu chipzao" />
        <br />
        <Chip
          variant="outlined"
          size="medium"
          label="meu chipzao"
          icon={<InboxIcon />}
        />
        <br />
        <Chip
          variant="outlined"
          size="medium"
          label="meu chipzao"
          avatar={<Avatar>M</Avatar>}
        />
        <br />
        <Chip
          variant="outlined"
          size="medium"
          label="meu chipzao"
          onDelete={() => {}}
        />
        <br />
        <Chip variant="outlined" size="medium" label="1" />
        <Chip variant="outlined" size="medium" label="1" square />
        <br />
        <Chip avatar={<Avatar>M</Avatar>} variant="outlined" size="medium" />
        <br />
        <Chip
          avatar={<Avatar>M</Avatar>}
          variant="outlined"
          size="medium"
          onDelete={() => {}}
        />
        <br />
        <Chip
          icon={<InboxIcon />}
          variant="outlined"
          size="medium"
          onDelete={() => {}}
        />
        <br />
        <Chip icon={<InboxIcon />} variant="outlined" size="medium" />
        <br />
        <br />
        <Chip variant="outlined" size="small" label="meu chipzao" />
        <br />
        <Chip
          variant="outlined"
          size="small"
          label="meu chipzao"
          icon={<InboxIcon />}
        />
        <br />
        <Chip
          variant="outlined"
          size="small"
          label="meu chipzao"
          avatar={<Avatar>M</Avatar>}
        />
        <br />
        <Chip
          variant="outlined"
          size="small"
          label="meu chipzao"
          onDelete={() => {}}
        />
        <br />
        <Chip variant="outlined" size="small" label="1" />
        <Chip variant="outlined" size="small" label="1" square />
        <br />
        <Chip avatar={<Avatar>M</Avatar>} variant="outlined" size="small" />
        <br />
        <Chip
          avatar={<Avatar>M</Avatar>}
          variant="outlined"
          size="small"
          onDelete={() => {}}
        />
        <br />
        <Chip
          icon={<InboxIcon />}
          variant="outlined"
          size="small"
          onDelete={() => {}}
        />
        <br />
        <Chip icon={<InboxIcon />} variant="outlined" size="small" />

        <br />
        <br />
        <Chip variant="outlined" size="xsmall" label="meu chipzao" />
        <br />
        <Chip
          variant="outlined"
          size="xsmall"
          label="meu chipzao"
          icon={<InboxIcon />}
        />
        <br />
        <Chip
          variant="outlined"
          size="xsmall"
          label="meu chipzao"
          avatar={<Avatar>M</Avatar>}
        />
        <br />
        <Chip
          variant="outlined"
          size="xsmall"
          label="meu chipzao"
          onDelete={() => {}}
        />
        <br />
        <Chip variant="outlined" size="xsmall" label="1" />
        <Chip variant="outlined" size="xsmall" label="1" square />
        <br />
        <Chip avatar={<Avatar>M</Avatar>} variant="outlined" size="xsmall" />
        <br />
        <Chip
          avatar={<Avatar>M</Avatar>}
          variant="outlined"
          size="xsmall"
          onDelete={() => {}}
        />
        <br />
        <Chip
          icon={<InboxIcon />}
          variant="outlined"
          size="xsmall"
          onDelete={() => {}}
        />
        <br />
        <Chip icon={<InboxIcon />} variant="outlined" size="xsmall" />
      </Box>
      <Typography>Color</Typography>

      <Typography>primary</Typography>
      <Chip
        label="meu chipzao"
        variant="standard"
        color="primary"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="outlined"
        color="primary"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="filled"
        color="primary"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />

      <Typography>secondary</Typography>
      <Chip
        label="meu chipzao"
        variant="standard"
        color="secondary"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="outlined"
        color="secondary"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="filled"
        color="secondary"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />

      <Typography>default</Typography>
      <Chip
        label="meu chipzao"
        variant="standard"
        color="default"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="outlined"
        color="default"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="filled"
        color="default"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />

      <Typography>success</Typography>
      <Chip
        label="meu chipzao"
        variant="standard"
        color="success"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="outlined"
        color="success"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="filled"
        color="success"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />

      <Typography>error</Typography>
      <Chip
        label="meu chipzao"
        variant="standard"
        color="error"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="outlined"
        color="error"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="filled"
        color="error"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />

      <Typography>warning</Typography>
      <Chip
        label="meu chipzao"
        variant="standard"
        color="warning"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="outlined"
        color="warning"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="filled"
        color="warning"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />

      <Typography>info</Typography>
      <Chip
        label="meu chipzao"
        variant="standard"
        color="info"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="outlined"
        color="info"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
      <Chip
        label="meu chipzao"
        variant="filled"
        color="info"
        clickable
        avatar={<Avatar>M</Avatar>}
        onDelete={() => {}}
      />
    </Stack>
  );
}
