import type { Ref } from 'react';
import { forwardRef } from 'react';

import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';

import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';
import { APPROVAL_TYPE_DICTIONARY } from 'src/libs/finbits/Bills/translation';

function ApprovalTypeSelect(
  {
    ...props
  }: Omit<
    SelectProps<BillPayableApprovalType, false, true, false>,
    'options' | 'defaultValue'
  >,
  ref: Ref<HTMLDivElement>
) {
  return (
    <Select
      aria-label="approval-type"
      {...props}
      {...ref}
      disableClearable
      options={[BillPayableApprovalType.ALL, BillPayableApprovalType.ANY]}
      getOptionLabel={(option: BillPayableApprovalType) =>
        APPROVAL_TYPE_DICTIONARY[option]
      }
      isOptionEqualToValue={(option, value) => option === value}
    />
  );
}

export default forwardRef(ApprovalTypeSelect);
