import t from 'zod';

export const CitiesDecoder = t.record(
  t.string(),
  t.object({
    cities: t.string().array(),
  })
);

export type Cities = t.TypeOf<typeof CitiesDecoder>;
