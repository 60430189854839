import { Box, Link, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Typography } from 'src/design-system/components';

import { TextField, snackbar } from 'src/mui';

import { useSignIn } from 'src/libs/finbits/Auth';
import type { SignInParams } from 'src/libs/finbits/Auth/types';
import { SignInResolver } from 'src/libs/finbits/Auth/types';

import styles from './SignIn.module.scss';

function SignIn() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SignInParams>({
    defaultValues: {
      login: '',
      password: '',
    },
    resolver: zodResolver(SignInResolver),
  });

  const { signIn, isLoading } = useSignIn();

  function onSubmit(params: SignInParams) {
    signIn(params, {
      onError: (error) => {
        if (error.response?.status === 401) {
          return setError('password', {
            message: 'E-mail ou senha inválidos!',
          });
        }

        snackbar({
          variant: 'error',
          message: 'Ops! Houve uma falha na conexão. Tente novamente.',
        });
      },
    });
  }

  return (
    <Box sx={{ mt: 6, mb: 16 }}>
      <Typography
        variant="h5"
        component="h2"
        marginBottom={4}
        align="center"
        fontWeight="600"
      >
        Faça login na sua conta
      </Typography>
      <Typography marginBottom={4} align="center">
        Bem vindo de volta! Por favor, insira seus dados.
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="login"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.login}
              helperText={errors.login?.message}
              label="E-mail"
              margin="normal"
              placeholder="E-mail"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField.Password
              {...field}
              error={!!errors.password}
              helperText={errors.password?.message}
              label="Senha"
              margin="normal"
              placeholder="••••••••"
              type="password"
            />
          )}
        />

        <Stack className={styles.buttons}>
          <Link component={RouterLink} to="/recover_password">
            Esqueceu sua senha?
          </Link>

          <LoadingButton
            color="primary"
            fullWidth
            loading={isLoading}
            type="submit"
            variant="contained"
            size="large"
          >
            Entrar
          </LoadingButton>

          <Typography>
            Gestão sem burocracia?{' '}
            <Link component={RouterLink} to="/signup-trial">
              Crie sua conta
            </Link>
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

export default SignIn;
