import { Button, Checkbox, Col, Form, Row } from 'antd';
import isEqual from 'lodash/isEqual';

import { FormItem, MaskedInput } from 'src/ui';

import {
  cnpjMask,
  cpfMask,
  documentValidator,
} from 'src/libs/finbits/Documents';
import { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import styles from './DocumentForm.module.less';

export type Document = {
  name?: string;
  nickname?: string;
  type?: ContactType;
  number?: string;
  isInternational: boolean;
};

type FormParams = {
  documentNumber?: string;
  isInternational: boolean;
};

type Props = {
  onFinish: (doc: Document) => void;
};

export default function DocumentForm({ onFinish }: Props) {
  const [form] = Form.useForm();

  const isInternational = Form.useWatch('isInternational', form);

  function handleOnFinish({ documentNumber, isInternational }: FormParams) {
    if (isInternational) {
      return onFinish({
        isInternational,
      });
    }

    return onFinish({
      type:
        documentNumber && documentNumber.length > 11
          ? ContactType.LEGAL
          : ContactType.NATURAL,
      number: documentNumber,
      isInternational,
    });
  }

  return (
    <Form
      form={form}
      className={styles.form}
      layout="vertical"
      onFinish={handleOnFinish}
      initialValues={{
        isInternational: false,
      }}
      onFieldsChange={(changedFields) => {
        const [{ name, value }] = changedFields;
        if (isEqual(name, ['isInternational']) && value) {
          form.setFieldsValue({ documentNumber: undefined });
        }
      }}
    >
      <Row gutter={32}>
        <Col span={24}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <FormItem
                required={!isInternational}
                label="CPF ou CNPJ"
                name="documentNumber"
                rules={[{ validator: documentValidator }]}
              >
                <MaskedInput
                  mask={[cpfMask, cnpjMask]}
                  size="large"
                  placeholder="Digite um CPF ou CNPJ"
                  disabled={isInternational}
                />
              </FormItem>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="isInternational"
            valuePropName="checked"
            shouldUpdate
          >
            <Checkbox>Contato Internacional</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                className={styles.nextButton}
                size="large"
                type="primary"
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched() ||
                  form.getFieldsError().some((item) => item.errors.length > 0)
                }
              >
                Continuar
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
