import { FormControlLabel, Stack, Switch } from '@mui/material';
import { InfoCircleIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { Tooltip } from 'src/mui';

import styles from './ToggleSwitch.module.scss';

type Props = {
  onChange?: (checked: boolean) => void;
};

export default function ToggleSwitch({ onChange }: Props) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event.target.checked);
  }

  return (
    <FormControlLabel
      className={styles.position}
      control={
        <Switch
          color="primary"
          inputProps={{
            'aria-label': 'toggle switch',
          }}
          onChange={handleChange}
        />
      }
      label={
        <Stack
          display="flex"
          direction="row"
          alignItems="center"
          gap={2}
          mr={2}
        >
          <Typography color="grey.600" fontWeight={600}>
            Resumo do período
          </Typography>

          <Tooltip
            title="Veja o resumo calculado com base nas transações lancadas na FinBits dentro do período filtrado para as contas selecionadas."
            placement="top"
          >
            <InfoCircleIcon className={styles.icon} />
          </Tooltip>
        </Stack>
      }
      labelPlacement="start"
    />
  );
}
