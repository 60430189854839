import { useMutation } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import type { RecoverParams, ResetPassword } from './types';
import { ResetPasswordDecoder } from './types';

async function postResetPassword({ email }: RecoverParams) {
  const response = await authenticatedAPI.post('/authentication/reset', {
    email,
  });

  return decodeResponse<ResetPassword>(response, ResetPasswordDecoder);
}

export function useResetPassword() {
  const { mutate: resetPassword, ...rest } = useMutation<
    ResetPassword,
    ApiError,
    RecoverParams
  >(postResetPassword);
  return { resetPassword, ...rest };
}
