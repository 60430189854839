import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium';

export function checkboxColumn<T extends GridValidRowModel>(
  columnProps?: GridColDef<T>
): GridColDef<T> {
  return {
    // TOOD: Workaround for: https://github.com/mui/mui-x/issues/4248
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: (params) => {
      if (params.rowNode.type === 'group') {
        return null;
      }

      return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell!(params);
    },
    aggregable: false,
    ...columnProps,
  };
}
