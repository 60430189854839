import { IconButton as MUIIconButton } from '@mui/material';

import IconButtonLoading from './IconButtonLoading';

type IconButtonCompound = {
  Loading: typeof IconButtonLoading;
} & typeof MUIIconButton;

export const IconButton = {
  ...MUIIconButton,
  Loading: IconButtonLoading,
} as IconButtonCompound;
