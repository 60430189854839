import { Divider, Input, Row, Space, Typography } from 'antd';

import { toDecimal } from 'src/libs/finbits/Money';
import { formatAddressWithDocument } from 'src/libs/finbits/BrazilianData/Addresses';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { useCities } from 'src/libs/finbits/BrazilianData/Ibge/Cities';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import TaxesCalculator from 'src/features/nota-fiscal/CreateNotaFiscalDrawer/Taxes/TaxesCalculator';
import type { Taxes } from 'src/features/nota-fiscal/CreateNotaFiscalDrawer/Taxes/types';

import styles from './SummaryNotaFiscal.module.less';

type Props = {
  amount: number;
  contact?: Contact;
  additionalInformation: string;
  selectedTaxes?: Taxes[];
};

export default function SummaryNotaFiscal({
  contact,
  amount,
  additionalInformation,
  selectedTaxes = [],
}: Props) {
  const { data: cities } = useCities({ stateCode: contact?.address?.state });

  const amountFormatted = toDecimal(amount, BalanceType.CREDIT);
  const city = cities?.find((city) => city.code === contact?.address?.city);
  const cityName = city?.name;

  const address = formatAddressWithDocument({
    ...contact?.address,
    document: contact?.document,
    cityName,
  });

  return (
    <>
      <Typography.Text>Confira os dados da nota fiscal:</Typography.Text>
      <div className={styles.reviewWrapper}>
        {contact && (
          <>
            <Space direction="vertical" size="small">
              <Typography.Text className={styles.smallText} type="secondary">
                Para:
              </Typography.Text>
              <Typography.Text strong>{contact?.name}</Typography.Text>
              {contact.address && <Typography.Text>{address}</Typography.Text>}
            </Space>
            <Divider />
          </>
        )}
        <Space
          size="large"
          direction="vertical"
          className={styles.taxesWrapper}
        >
          <Row>
            <Typography.Text strong>Valor da nota fiscal:</Typography.Text>
            <Typography.Text className={styles.totalAmountText} strong>
              R$ {amountFormatted}
            </Typography.Text>
          </Row>
          <TaxesCalculator selectedTaxes={selectedTaxes} />
        </Space>
        <Divider />
        <Input.TextArea
          readOnly
          autoSize={{ minRows: 1 }}
          value={additionalInformation}
          className={styles.additionalInformationTextArea}
        />
      </div>
    </>
  );
}
