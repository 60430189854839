import { useState } from 'react';

import type { ButtonProps } from 'antd';
import { Button, Form } from 'antd';
import type { NamePath } from 'antd/lib/form/interface';

import type { FormValues } from 'src/features/EntryForm/types';
import SplitItemFormDrawer from 'src/features/entries/Drawers/SplitForm/SplitItemFormDrawer';

type Props = {
  fieldPath?: NamePath;
  initialValues?: FormValues;
  scheduledIdsSelectedToConciliate?: string[];
  scheduledIdsConciliated?: string[];
  onUnconciliateSuccess: () => void;
  onAdd: (formValues: FormValues) => void;
  shouldCallSubmitOnUnconciliate?: boolean;
  children?: React.ReactNode;
} & ButtonProps;

function SplitDrawerButtonTrigger({
  fieldPath,
  initialValues,
  scheduledIdsSelectedToConciliate,
  scheduledIdsConciliated,
  onUnconciliateSuccess,
  onAdd,
  shouldCallSubmitOnUnconciliate = false,
  children,
  ...buttonProps
}: Props) {
  const [isCreateSplitOpen, setIsCreateSplitOpen] = useState(false);
  const [currentFormValue, setCurrentFormValue] = useState(initialValues);

  const form = Form.useFormInstance();

  function handleOpenDrawer() {
    if (fieldPath) {
      const fieldValues = form.getFieldValue(fieldPath);

      fieldValues && setCurrentFormValue(fieldValues);
    }

    setIsCreateSplitOpen(true);
  }

  return (
    <>
      {isCreateSplitOpen && (
        <SplitItemFormDrawer
          onClose={() => setIsCreateSplitOpen(false)}
          splitInitialValues={currentFormValue}
          onSubmit={onAdd}
          scheduledIdsSelectedToConciliate={scheduledIdsSelectedToConciliate}
          scheduledIdsConciliated={scheduledIdsConciliated}
          onUnconciliateSuccess={onUnconciliateSuccess}
          shouldCallSubmitOnUnconciliate={shouldCallSubmitOnUnconciliate}
        />
      )}

      <Button onClick={handleOpenDrawer} {...buttonProps}>
        {children}
      </Button>
    </>
  );
}

export default SplitDrawerButtonTrigger;
