import type { Props as ModalProps } from 'src/ui/Modal/Modal';
import { default as Modal } from 'src/ui/Modal/Modal';
import { Typography } from 'antd';

import type { PortalProps } from 'src/ui';
import { Title } from 'src/ui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import ReviewTable from 'src/features/bills-to-pay/TabActions/DEPRECATED_ReviewTable';

type DialogProps = Omit<ModalProps, 'children'>;

type Props = DialogProps & {
  title?: string;
  description?: string;
  onSuccess: () => void;
  bills: BillPayable[];
} & PortalProps;

export default function ReviewModal({
  title = 'Title',
  description,
  bills,
  onSuccess,
  onClose,
  open = true,
  onExit,
}: Props) {
  function handleOnOk() {
    onSuccess();
    onClose();
  }

  return (
    <Modal
      title={<Title>{title}</Title>}
      visible={open}
      onCancel={onClose}
      onOk={handleOnOk}
      okText="Continuar"
      cancelText="Cancelar"
      afterClose={onExit}
      width={980}
    >
      {description && (
        <Typography.Paragraph>{description}</Typography.Paragraph>
      )}
      {bills && <ReviewTable bills={bills} />}
    </Modal>
  );
}
