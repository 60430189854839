import type { FormFieldProps } from 'src/mui/_scss';
import { FormField, Select } from 'src/mui/_scss';
import { Button, createFilterOptions } from '@mui/material';
import { PlusIcon } from 'src/mui/_icons';
import compact from 'lodash/compact';
import type { ControllerRenderProps } from 'react-hook-form';

import { useOpenPortal } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { useCompanyContacts } from 'src/libs/finbits/Organization/Companies/Contacts';
import { formatDocument } from 'src/libs/finbits/Documents';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import type { ContactSuggestion } from 'src/libs/finbits/Management/Entries/types';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import CreateContactDrawer from 'src/features/contacts/CreateContactDrawer';
import type { Document } from 'src/features/contacts/CreateContactDrawer/DocumentForm';

import styles from './ContactsField.module.scss';
import ContactActions from './ContactActions';

const contactFilterOptions = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (contact: Contact) => {
    const formattedDocument =
      contact?.type && contact.document
        ? formatDocument(contact.type, contact.document)
        : undefined;

    const query = [contact.nickname, contact.document, formattedDocument];

    return compact(query).join();
  },
});

type ContactFieldProps = FormFieldProps & {
  suggestion?: ContactSuggestion | null;
  onChange?: (value: Contact | null) => void;
};

function parseDocument(contactSuggetion: ContactSuggestion): Document {
  const isInternational = contactSuggetion.type === 'international';

  return {
    name: contactSuggetion.name ?? undefined,
    nickname: contactSuggetion.nickname ?? undefined,
    number: contactSuggetion.document,
    type: isInternational ? undefined : (contactSuggetion.type as ContactType),
    isInternational,
  };
}

export default function ContactsField({
  defaultValue = null,
  suggestion,
  onChange,
  ...rest
}: ContactFieldProps) {
  const openPortal = useOpenPortal();
  const { companyId, organizationId } = useCompanyParams();
  const { companyContacts: contacts, isLoading } = useCompanyContacts(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const filteredContacts = contacts.filter((contact) => contact.active);

  function handleCreateContact(field: ControllerRenderProps) {
    openPortal(CreateContactDrawer, {
      onCreate: field.onChange,
    });
  }

  function handleCreateContactFromSuggestion(field: ControllerRenderProps) {
    if (!suggestion) return;

    openPortal(CreateContactDrawer, {
      onCreate: field.onChange,
      defaultContactDocument: parseDocument(suggestion),
    });
  }

  function handleChange(field: ControllerRenderProps, value: Contact | null) {
    onChange?.(value);

    return field.onChange?.(value?.id);
  }

  return (
    <FormField defaultValue={defaultValue} label="Contato" {...rest}>
      {(field) => {
        const contact =
          contacts.find((contact) => contact.id === field.value) ?? null;

        return (
          <>
            <Select
              disableClearable={field.disabled}
              {...field}
              placeholder="Digite o contato"
              value={contact}
              options={filteredContacts}
              loading={isLoading}
              filterOptions={contactFilterOptions}
              getOptionLabel={(option) => option.nickname || ''}
              onChange={(_e, value) => handleChange(field, value)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(contact) => {
                const formattedDocument = formatDocument(
                  contact.type!,
                  contact.document!
                );

                return (
                  <div className={styles.contact}>
                    <span className={styles.nickname}>{contact.nickname}</span>
                    <small className={styles.document}>
                      {formattedDocument}
                    </small>
                  </div>
                );
              }}
            >
              <WithAllowedPermission
                permissions={{ action: ['create'], resource: 'contacts' }}
              >
                <Button
                  size="medium"
                  startIcon={<PlusIcon />}
                  onClick={() => handleCreateContact(field)}
                  className={styles.createContactButton}
                >
                  Criar contato
                </Button>
              </WithAllowedPermission>
            </Select>

            <ContactActions
              contact={contact}
              suggestion={suggestion}
              onCreateContact={() => handleCreateContactFromSuggestion(field)}
              disabled={field.disabled}
            />
          </>
        );
      }}
    </FormField>
  );
}
