import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { invalidateInboxItemsQueries } from 'src/libs/finbits/Management/NewInboxItems';

import { ContactDecoder, ContactsDecoder, ImportContactDecoder } from './types';
import type {
  Contact,
  GetCompanyContactParams,
  GetParams,
  ImportContactResponse,
  ImportParams,
  PatchParams,
  PostParams,
} from './types';

async function getCompanyContacts({ organizationId, companyId }: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/contacts`
  );

  return decodeResponse<Contact[]>(response, ContactsDecoder);
}

export async function getCompanyContact({
  organizationId,
  companyId,
  contactId,
}: GetCompanyContactParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/contacts/${contactId}`
  );

  return decodeResponse<Contact>(response, ContactDecoder);
}

async function postContact({
  organizationId,
  companyId,
  ...contact
}: PostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/contacts`,
    contact
  );

  return decodeResponse<Contact>(response, ContactDecoder);
}

async function patchContact({
  organizationId,
  companyId,
  id,
  ...contact
}: Partial<PatchParams>) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/contacts/${id}`,
    contact
  );

  return decodeResponse<Contact>(response, ContactDecoder);
}

async function postImportContact({
  organizationId,
  companyId,
  file,
}: ImportParams) {
  const formData = new FormData();

  formData.append('file', file);

  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/contacts/import`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return decodeResponse<ImportContactResponse>(response, ImportContactDecoder);
}

function setQueryKey({ companyId, organizationId }: GetParams) {
  return ['company_contacts', { companyId, organizationId }];
}

export function useCompanyContact(
  { contactId, organizationId, companyId }: GetCompanyContactParams,
  customOptions?: Partial<UseQueryOptions<Contact, ApiError>>
) {
  const { data, ...rest } = useQuery<Contact, ApiError>({
    queryKey: ['company_contact', { contactId, organizationId, companyId }],
    enabled: !!contactId && !!organizationId && !!companyId,
    queryFn: () => getCompanyContact({ organizationId, companyId, contactId }),
    ...customOptions,
  });

  return {
    contact: data,
    ...rest,
  };
}

export function useCompanyContacts(
  params: GetParams,
  customOptions: Partial<UseQueryOptions<Contact[], ApiError>> = {}
) {
  const { data, ...rest } = useQuery<Contact[], ApiError>({
    enabled: !!params.companyId && !!params.organizationId,
    queryKey: setQueryKey(params),
    queryFn: () => getCompanyContacts(params),
    ...customOptions,
  });

  return { companyContacts: withEmptyArrayDefault(data), ...rest };
}

export function useCreateContact() {
  const queryClient = useQueryClient();
  const { mutate: createContact, ...rest } = useMutation<
    Contact,
    ApiError,
    PostParams
  >(postContact, {
    onSuccess: () => {
      queryClient.invalidateQueries(['company_contacts']);
      invalidateInboxItemsQueries(queryClient);
    },
  });
  return { createContact, ...rest };
}

export function useUpdateContact() {
  const queryClient = useQueryClient();
  const { mutate: updateContact, ...rest } = useMutation<
    Contact,
    ApiError,
    Partial<PatchParams>
  >(patchContact, {
    onSuccess: () => {
      queryClient.invalidateQueries(['company_contact']);
      queryClient.invalidateQueries(['company_contacts']);
    },
  });
  return { updateContact, ...rest };
}

export function useImportContacts() {
  const queryClient = useQueryClient();
  const { mutate: importContacts, ...rest } = useMutation<
    ImportContactResponse,
    ApiError<{ file?: string }>,
    ImportParams
  >(postImportContact, {
    onSuccess: () => queryClient.invalidateQueries(['company_contacts']),
  });
  return { importContacts, ...rest };
}
