import { useMemo, useState } from 'react';

import type { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { Button, DialogActions, DialogContent } from '@mui/material';
import _debounce from 'lodash/debounce';
import type { PortalProps } from 'src/ui/PortalProvider';

import { SearchInput } from 'src/mui';

import { sleep } from 'src/libs/finbits/Time/sleep';
import { endOfMonth, format, startOfMonth } from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useGetBills } from 'src/libs/finbits/Bills';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

import BillsToPayDataGrid from 'src/features/bills-to-pay/BillsToPayDataGrid';
import { ToolbarDatePicker } from 'src/features/entries/EntriesDataGrid/GridToolBar/ToolbarQuickActions/ToolbarDatePicker';

import styles from './ConciliationModal.module.scss';

type Props = {
  onConciliate: (bill: BillPayable) => void;
  onClose: PortalProps['onClose'];
};

export default function Content({ onClose, onConciliate }: Props) {
  const { organizationId, companyId } = useCompanyParams();

  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [selectedBillIds, setSelectedBillIds] = useState<GridRowSelectionModel>(
    []
  );

  const [isSearching, setIsSearching] = useState(false);
  const [quickFilter, setQuickFilter] = useState<string[]>([]);

  const { bills, isLoading, isFetching } = useGetBills({
    companyId,
    organizationId,
    startDate: format(selectedDateRange[0], 'yyyy-MM-dd'),
    endDate: format(selectedDateRange[1], 'yyyy-MM-dd'),
  });

  const quickFilterWithDebounce = useMemo(
    () =>
      _debounce((value) => {
        setQuickFilter(value.trim().split(' '));
        setIsSearching(false);
      }, 350),

    [setQuickFilter]
  );

  function handleChangeSearch(value: string) {
    setIsSearching(true);
    quickFilterWithDebounce(value);
  }

  async function handleConciliate() {
    const selectedBill = bills.find((bill) =>
      selectedBillIds.includes(bill.id)
    );

    onClose();

    await sleep(() => {
      selectedBill && onConciliate(selectedBill);
    }, 50);
  }

  return (
    <>
      <DialogContent dividers className={styles.content}>
        <SearchInput
          className={styles.searchInput}
          onChange={handleChangeSearch}
          loading={isSearching}
        />

        <BillsToPayDataGrid
          bills={bills}
          isLoading={isLoading || isFetching}
          checkboxSelection
          disableMultipleRowSelection
          quickFilter={quickFilter}
          editable={false}
          RowSelection={{
            rowSelectionModel: selectedBillIds,
            onRowSelectionModelChange: setSelectedBillIds,
          }}
          GridToolBarSlots={{
            QuickActions: () => (
              <ToolbarDatePicker
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
              />
            ),
            selectedText: [
              'lançamento selecionado',
              'lançamentos selecionados',
            ],
          }}
        />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConciliate}
          disabled={!selectedBillIds.length}
        >
          Vincular
        </Button>
      </DialogActions>
    </>
  );
}
