import { useMemo } from 'react';

import type { ChipProps } from 'src/design-system/components/types';
import { Chip } from 'src/design-system/components';
import { Tooltip } from '@mui/material';
import { ClockIcon, ThumbsDownIcon, ThumbsUpIcon } from 'src/mui/_icons';

import { getAssigneeStatus } from 'src/libs/finbits/Bills';
import { BillApprovalStatus } from 'src/libs/finbits/Bills/types';
import type {
  BillPayableAssignment,
  BillStatus,
} from 'src/libs/finbits/Bills/types';
import type { User } from 'src/libs/finbits/Organization/Users/types';
import { formatName } from 'src/libs/finbits/Name';

import styles from './AssigneeChip.module.scss';

type Props = {
  assignee: User;
  billStatus?: BillStatus;
  assignments?: BillPayableAssignment[];
  showIcon?: boolean;
} & ChipProps;

type TagColor = {
  color: ChipProps['color'];
  tooltip: string;
  icon: ChipProps['icon'];
};

const TAG_COLORS: Record<BillApprovalStatus, TagColor> = {
  [BillApprovalStatus.APPROVED]: {
    tooltip: 'Aprovado',
    color: 'success',
    icon: <ThumbsUpIcon fontSize="xsmall" className={styles.icon} />,
  },
  [BillApprovalStatus.REPROVED]: {
    tooltip: 'Reprovado',
    color: 'error',
    icon: <ThumbsDownIcon fontSize="xsmall" className={styles.icon} />,
  },
  [BillApprovalStatus.PENDING]: {
    tooltip: 'Pendente',
    color: 'warning',
    icon: <ClockIcon fontSize="xsmall" className={styles.icon} />,
  },
  [BillApprovalStatus.WAITING_APPROVAL]: {
    tooltip: '',
    color: 'default',
    icon: <ClockIcon fontSize="xsmall" className={styles.icon} />,
  },
};

export default function AssigneeChip({
  assignments = [],
  assignee,
  billStatus,
  showIcon = false,
  variant = 'outlined',
  ...rest
}: Props) {
  const tag = useMemo<TagColor>(() => {
    const assigneeStatus = getAssigneeStatus(
      assignee.id,
      assignments,
      billStatus
    );

    if (assigneeStatus) {
      return TAG_COLORS[assigneeStatus];
    }

    return {
      color: 'default',
      tooltip: '',
      icon: <ClockIcon fontSize="xsmall" className={styles.icon} />,
    };
  }, [assignee, assignments, billStatus]);

  const name = formatName(assignee.name);

  return (
    <Tooltip title={tag.tooltip}>
      <Chip
        label={name}
        size="small"
        variant={variant}
        color={tag.color}
        {...rest}
        className={styles.chip}
        icon={showIcon ? tag.icon : undefined}
      />
    </Tooltip>
  );
}
