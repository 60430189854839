import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiTooltip({
  palette,
  shadows,
}: ThemeConfig): Components['MuiTooltip'] {
  const tooltipBackgroundColor = palette.grey?.[900];

  return {
    styleOverrides: {
      tooltip: {
        color: palette.common?.white,
        backgroundColor: tooltipBackgroundColor,
        fontSize: '12px',
        fontWeight: '600',
        padding: '8px 12px',
        boxShadow: shadows[4],
      },
      arrow: {
        color: tooltipBackgroundColor,
      },
    },
  };
}
