import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd';

import { useInternationalCountries } from 'src/libs/finbits/Countries';
import { filterOption } from 'src/libs/finbits/Form';

export default function CountriesSelect({ ...rest }: SelectProps<string>) {
  const { data: countries, isLoading } = useInternationalCountries();

  return (
    <Select
      filterOption={filterOption}
      loading={isLoading}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      placeholder="Selecione"
      size="large"
      allowClear
      showSearch
      getPopupContainer={(trigger) => trigger.parentElement}
      {...rest}
    >
      {countries?.map((country) => (
        <Select.Option value={country.code} key={country.code}>
          {country.name}
        </Select.Option>
      ))}
    </Select>
  );
}
