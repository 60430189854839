import { GridPagination } from '@mui/x-data-grid-premium';

import PaginationActions from './PaginationActions';

export default function Pagination() {
  return (
    <GridPagination
      ActionsComponent={PaginationActions}
      labelDisplayedRows={() => <></>}
    />
  );
}
