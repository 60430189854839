import { Box, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import {
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import SelectApprovalModeCard from 'src/features/approval/config-approval-flow/SelectApprovalModeCard';
import CreateApprovalRulesCard from 'src/features/approval/config-approval-flow/CreateApprovalRulesCard';
import ActiveApprovalCard from 'src/features/approval/config-approval-flow/ActiveApprovalCard';

import styles from './ConfigApprovalFlow.module.scss';

export default function ConfigApprovalFlow() {
  const { organizationId, companyId } = useCompanyParams();
  const { isEnabled: isApprovalPageEnabled } = useExternalFeatureFlag(
    Feature.APPROVAL_RULES_PAGE
  );

  const { company } = useCompany({ organizationId, companyId });

  return (
    <Box className={styles.container}>
      <Typography
        variant="text-lg"
        fontWeight="bold"
        component="h6"
        className={styles.title}
      >
        Fluxo de aprovação
      </Typography>

      <Typography>
        Em três passos, você garante maior controle e segurança nas
        movimentações da empresa <strong>{company?.tradeName}</strong>.
      </Typography>
      <Typography>
        Com o fluxo de aprovação ativado, você pode atribuir aprovadores para
        suas despesas de forma manual
        {isApprovalPageEnabled &&
          ' ou criar regras de aprovação para automatizar este processo'}
        .
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4, lg: 6 }}
        className={styles.cardWrapper}
      >
        <ActiveApprovalCard />
        <SelectApprovalModeCard />
        <CreateApprovalRulesCard />
      </Stack>
    </Box>
  );
}
