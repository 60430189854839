import { useMemo, useState } from 'react';

import { Popover, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import uniqBy from 'lodash/uniqBy';
import { InfoCircleOutlined } from '@ant-design/icons';

import { differenceInDays, format, parseISO } from 'src/libs/finbits/Date';
import type { NotaFiscal } from 'src/libs/finbits/NotaFiscal/types';
import { toDecimal } from 'src/libs/finbits/Money';

import NotaFiscalStatusTag from 'src/features/nota-fiscal/NotaFiscalStatusTag';
import {
  NotaFiscalStatus,
  notaFiscalStatusDictionary,
} from 'src/features/nota-fiscal/NotaFiscalStatusTag/types';

import Actions from './Actions';
import LinkedEntriesTooltip from './LinkedEntriesTooltip';
import styles from './NotaFiscalTable.module.less';

type Props = {
  isLoading: boolean;
  notasFiscais: NotaFiscal[];
};

const statusFilter = Object.values(NotaFiscalStatus).map((status) => ({
  text: notaFiscalStatusDictionary[status],
  value: status,
}));

export default function NotaFiscalTable({
  isLoading,
  notasFiscais = [],
}: Props) {
  const [currentPage, setCurrentPage] = useState(1);

  const contactsFilter = useMemo(
    () =>
      uniqBy(notasFiscais, 'contact.id')
        .map(({ contact }) => ({
          text: contact.nickname,
          value: contact.id,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [notasFiscais]
  );

  const columns: ColumnsType<NotaFiscal> = [
    {
      key: 'issuedAt',
      title: 'Data de Emissão',
      width: 156,
      render: ({ issuedAt }: NotaFiscal) =>
        issuedAt && (
          <Typography.Text>
            {format(parseISO(issuedAt), 'dd/MM/yy')}
          </Typography.Text>
        ),
      sorter: (a: NotaFiscal, b: NotaFiscal) => {
        const aDate = a.issuedAt;
        const bDate = b.issuedAt;

        if (aDate && bDate) {
          return differenceInDays(parseISO(aDate), parseISO(bDate));
        }

        return aDate ? 1 : -1;
      },
    },
    {
      key: 'status',
      title: 'Status',
      width: 156,
      render: (notaFiscal: NotaFiscal) => (
        <NotaFiscalStatusTag notaFiscal={notaFiscal} />
      ),
      sorter: (a: NotaFiscal, b: NotaFiscal) =>
        notaFiscalStatusDictionary[a.status].localeCompare(
          notaFiscalStatusDictionary[b.status]
        ),
      filters: statusFilter,
      onFilter: (value, entry: NotaFiscal) => entry.status === value,
    },
    {
      key: 'contact',
      title: 'Contato (tomador)',
      render: ({ contact }: NotaFiscal) => (
        <Typography.Text>{contact.nickname}</Typography.Text>
      ),
      sorter: (a: NotaFiscal, b: NotaFiscal) =>
        a.contact.nickname.localeCompare(b.contact.nickname),
      filters: contactsFilter,
      onFilter: (value, entry: NotaFiscal) => entry.contact.id === value,
    },
    {
      key: 'amount',
      title: 'Valor',
      align: 'right',
      width: 176,
      render: ({ amount }: NotaFiscal) => (
        <Typography.Text>{toDecimal(amount)}</Typography.Text>
      ),
      sorter: (a: NotaFiscal, b: NotaFiscal) => a.amount - b.amount,
    },
    {
      key: 'situation',
      align: 'center',
      width: 156,
      title: () => <Typography.Text>Situação</Typography.Text>,
      render: (notaFiscal: NotaFiscal) => (
        <LinkedEntriesTooltip notaFiscal={notaFiscal} />
      ),
    },
    {
      key: 'number',
      className: 'number',
      width: 156,
      title: () => <Typography.Text>Número</Typography.Text>,
      render: (notaFiscal: NotaFiscal) => (
        <Typography.Text>{notaFiscal.number}</Typography.Text>
      ),
      sorter: (a: NotaFiscal, b: NotaFiscal) => {
        const aNumber = a.number || '';
        const bNumber = b.number || '';
        return aNumber.localeCompare(bNumber);
      },
      filterIcon: () => (
        <Popover
          placement="bottomLeft"
          title="Número da série"
          content="A série das notas fiscais emitidas pela Finbits é NF."
          trigger="hover"
          overlayClassName={styles.popover}
          arrowPointAtCenter
        >
          <InfoCircleOutlined className={styles.infoCircle} />
        </Popover>
      ),
      filters: [],
      filterDropdown: () => null,
    },
    {
      title: '',
      width: 32,
      render: (notaFiscal: NotaFiscal) => <Actions notaFiscal={notaFiscal} />,
    },
  ];

  return (
    <Table
      columns={columns}
      loading={isLoading}
      dataSource={notasFiscais}
      className={styles.table}
      rowKey="id"
      size="middle"
      pagination={{
        hideOnSinglePage: true,
        pageSize: 25,
        position: ['bottomCenter'],
        showSizeChanger: false,
        current: currentPage,
        onChange: setCurrentPage,
      }}
    />
  );
}
