import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

export function toIntRepresentation(amount: string) {
  const [int, dec] = amount.replaceAll('.', '').split(',');

  return parseInt(int + (dec || '').padEnd(2, '0'));
}

export function toCurrency(
  amount: number,
  type: BalanceType = BalanceType.CREDIT
) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(toFloat(amount, type));
}

export function toDecimal(
  amount: number,
  type: BalanceType = BalanceType.CREDIT
) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(toFloat(amount, type));
}

export function toInteger(amount: string) {
  return Math.round(Number(amount) * 100);
}

function toFloat(amount: number, type: BalanceType = BalanceType.CREDIT) {
  const convertedAmount = convertToSignedNumber(amount, type);

  return convertedAmount / 100;
}

export function convertToSignedNumber(amount: number, type: BalanceType) {
  if (type === BalanceType.DEBIT) {
    return amount * -1;
  }

  return amount;
}

export function getAmountType(amount: number): {
  amount: number;
  type: BalanceType;
} {
  return {
    amount: Math.abs(amount),
    type: amount < 0 ? BalanceType.DEBIT : BalanceType.CREDIT,
  };
}
