import { useBankName } from 'src/libs/finbits/Bank';

type Props = {
  routingNumber: string;
};

export default function BankName({ routingNumber }: Props) {
  const { data: bankName, isLoading } = useBankName(routingNumber);

  if (isLoading) {
    return (
      <span className="ant-skeleton ant-skeleton-element ant-skeleton-active">
        <span
          className="ant-skeleton-button ant-skeleton-button-sm"
          style={{ width: 140 }}
        ></span>
      </span>
    );
  }

  return <>{bankName}</>;
}
