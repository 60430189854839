import Grid from '@mui/material/Unstable_Grid2';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormField } from 'src/mui/_scss';

import { Autocomplete, DatePicker } from 'src/mui';

import { BillFrequencyRecurrence } from 'src/libs/finbits/Bills/types';
import { intervalOptionsDictionary } from 'src/libs/finbits/Bills/translation';
import { getEndDate } from 'src/libs/finbits/Management/ScheduledRecurrences';

import styles from './SimulationFields.module.scss';

export default function SimulationFields() {
  const { control, getValues, setValue } = useFormContext();

  function isNotValid({
    frequencyInterval,
    quantity,
  }: {
    frequencyInterval: BillFrequencyRecurrence;
    quantity: string;
  }) {
    return !frequencyInterval || !quantity || Number(quantity) <= 1;
  }

  function updateEndDate() {
    const frequencyInterval = getValues('frequencyInterval');
    const quantity = getValues('quantity');
    const initialDate = getValues('date');

    if (isNotValid({ quantity, frequencyInterval })) return;

    const newEndDate = getEndDate({
      initialDate,
      frequencyInterval,
      quantity,
    });

    setValue('endDate', newEndDate);
  }

  return (
    <Grid className={styles.gridContainer} container spacing={6}>
      <Grid xs={3}>
        <DatePicker
          defaultValue={getValues('date')}
          label="Data inicial"
          disabled
        />
      </Grid>

      <Grid xs={3}>
        <FormField
          label="Intervalo da recorrência"
          name="frequencyInterval"
          control={control}
          defaultValue={null}
          required
        >
          {({ error, ...field }) => (
            <Autocomplete.Select
              {...field}
              TextFieldProps={{
                placeholder: 'Selecione o intervalo',
                error,
              }}
              placeholder="test"
              disableClearable
              options={Object.values(BillFrequencyRecurrence)}
              getOptionLabel={(option: BillFrequencyRecurrence) =>
                intervalOptionsDictionary[option]
              }
              onChange={(_event, value) => {
                field.onChange(value);
                updateEndDate();
              }}
              isOptionEqualToValue={(option, value) => option === value}
            />
          )}
        </FormField>
      </Grid>

      <Grid xs={3}>
        <FormField
          label="Repetir por quantas vezes"
          defaultValue=""
          required
          name="quantity"
          control={control}
        >
          {(field) => (
            <TextField
              {...field}
              type="number"
              placeholder="Ex: 3"
              onChange={(event) => {
                field.onChange(event);
                updateEndDate();
              }}
            />
          )}
        </FormField>
      </Grid>

      <Grid xs={3}>
        <FormField
          defaultValue={null}
          name="endDate"
          control={control}
          disabled
        >
          {(field) => (
            <DatePicker
              slotProps={{ textField: { placeholder: 'Cálculo Automático' } }}
              label="Data final"
              {...field}
            />
          )}
        </FormField>
      </Grid>
    </Grid>
  );
}
