import type { SxProps, Theme } from '@mui/material';

import type { FocusIconColor, FocusIconSize } from './FocusIcon';

export const baseFocusIcon: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: '50%',
  border: '8px solid transparent',
};

export const colors: Record<FocusIconColor, SxProps<Theme>> = {
  primary: {
    background: (theme) => theme.palette.grey[100],
    borderColor: (theme) => theme.palette.grey[50],
    color: (theme) => theme.palette.text.primary,
  },
  error: {
    background: (theme) => theme.palette.error[100],
    borderColor: (theme) => theme.palette.error[50],
    color: (theme) => theme.palette.error.main,
  },
  success: {
    background: (theme) => theme.palette.success[100],
    borderColor: (theme) => theme.palette.success[50],
    color: (theme) => theme.palette.success.main,
  },
  warning: {
    background: (theme) => theme.palette.warning[100],
    borderColor: (theme) => theme.palette.warning[50],
    color: (theme) => theme.palette.warning.main,
  },
};

export const sizes: Record<FocusIconSize, SxProps<Theme>> = {
  xs: {
    width: '24px',
    height: '24px',
  },
  sm: {
    width: '32px',
    height: '32px',
  },
  md: {
    width: '40px',
    height: '40px',
  },
  lg: {
    width: '48px',
    height: '48px',
  },
  xl: {
    width: '56px',
    height: '56px',
  },
};
