import { createContext, useContext, useState } from 'react';

import { usePageControls } from 'src/features/preview/Pagination';

import type {
  BillPreviewContextProps,
  BillPreviewProviderProps,
} from './types';

export const EXPANSION_EXIT_TIME = 500;
export const EXPANSION_ENTER_TIME = 1000;

const BillPreviewContext = createContext({} as BillPreviewContextProps);

export default function BillPreviewProvider({
  children,
  defaultToolbarVisible = false,
}: BillPreviewProviderProps) {
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(1);
  const [isExpandedPreview, setIsExpandedPreview] = useState(false);
  const [isToolbarVisible, setIsToolbarVisible] = useState(
    defaultToolbarVisible
  );

  const {
    pageNumber,
    numPages,
    onPDFLoadSuccess,
    handleNextPage,
    handlePreviousPage,
    resetPageNumber,
  } = usePageControls();

  function toggleExpansionPreview() {
    setIsExpandedPreview((prevState) => !prevState);
  }

  return (
    <BillPreviewContext.Provider
      value={{
        pageNumber,
        numPages,
        isExpandedPreview,
        isToolbarVisible,
        toggleExpansionPreview,
        setIsToolbarVisible,
        handleNextPage,
        handlePreviousPage,
        handlePDFLoadSuccess: onPDFLoadSuccess,
        resetPageNumber,
        currentAttachmentIndex,
        setCurrentAttachmentIndex,
      }}
    >
      {children}
    </BillPreviewContext.Provider>
  );
}

export function useBillPreviewContext() {
  const context = useContext(BillPreviewContext);

  if (!context) {
    throw new Error('To use context, you must provide a BillPreviewProvider');
  }

  return context;
}
