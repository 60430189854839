import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

import { baseFocusIcon, colors, sizes } from './FocusIcon.sx';
import Square from './Square';

export type FocusIconColor = 'primary' | 'success' | 'error' | 'warning';
export type FocusIconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Props = {
  size: FocusIconSize;
  color?: FocusIconColor;
} & Omit<BoxProps, 'color'>;

export default function FocusIcon({
  children,
  size,
  sx,
  color = 'primary',
  ...rest
}: Props) {
  const styles = merge(baseFocusIcon, sizes[size], colors[color], sx);

  return (
    <Box {...rest} sx={styles} data-testid="focus-icon">
      {children}
    </Box>
  );
}

FocusIcon.Square = Square;
