import { useState } from 'react';

import type { UploadProps } from 'antd';
import { Button, Form, Upload } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import type { RcFile, UploadChangeParam } from 'antd/lib/upload';
import xorBy from 'lodash/xorBy';
import type { UploadFile } from 'antd/lib/upload/interface';

import PreviewFile from 'src/features/preview/PreviewFile';
import { validateBeforeUpload } from 'src/features/attachments/UploadAttachments/Validators';

import styles from './UploadAttachments.module.less';

type FilesState = {
  fileList: RcFile[];
};

export default function UploadAttachments({ onChange, ...rest }: UploadProps) {
  const [previewFile, setPreviewFile] = useState<RcFile>();

  const files = Form.useWatch<FilesState>('files') ?? {
    fileList: [],
  };

  function handleBeforeUpload(file: RcFile) {
    if (onChange) {
      const isValid = validateBeforeUpload(file, files.fileList, {
        shouldSumAllFiles: true,
      });

      const newFileList = isValid ? [...files.fileList, file] : files.fileList;

      onChange({
        file,
        fileList: newFileList,
      });
    }

    return false;
  }

  function handleOnChange({ file, fileList }: UploadChangeParam) {
    if (file.status === 'removed' && onChange) {
      return onChange({
        file,
        fileList: xorBy(fileList, 'uid'),
      });
    }
  }

  function handlePreview(file: UploadFile) {
    return setPreviewFile(file as RcFile);
  }

  return (
    <>
      {previewFile && (
        <PreviewFile
          file={previewFile}
          onClose={() => setPreviewFile(undefined)}
        />
      )}
      <Upload
        data-testid="file-upload"
        listType="picture"
        fileList={files.fileList}
        className={styles.upload}
        beforeUpload={handleBeforeUpload}
        onChange={handleOnChange}
        showUploadList={{
          removeIcon: <DeleteOutlined />,
        }}
        onPreview={handlePreview}
        {...rest}
      >
        <Button size="large" icon={<UploadOutlined />}>
          Novo anexo
        </Button>
      </Upload>
    </>
  );
}
