import type { SxProps, Theme } from '@mui/material';

export const iconButton: SxProps<Theme> = {
  color: 'grey.500',
  position: 'absolute',
  right: '4px',
};

export const snackbarTitle: SxProps<Theme> = {
  fontWeight: 600,
  color: (theme) => theme.palette.grey[900],
};

export const description: SxProps<Theme> = {
  color: (theme) => theme.palette.grey[600],
};

export const actions: SxProps<Theme> = {
  ml: '-12px',
  mb: '-8px',
  mt: '-12px',
};

export const snackbarAction: SxProps<Theme> = {
  color: (theme) => theme.palette.grey[600],
};

export const info: SxProps<Theme> = {
  translate: 'rotate(-90deg)',
  color: (theme) => theme.palette.grey[600],
};
