import type { ReactElement } from 'react';

import { useAllowedPermission } from '../Permissions';
import type { Permissions } from '../types';

type Props = {
  children: ReactElement;
  permissions?: Permissions | Permissions[];
};

export default function WithAllowedPermission({
  children,
  permissions,
}: Props) {
  const isAllowed = useAllowedPermission(permissions);

  if (!isAllowed) {
    return null;
  }

  return children;
}
