import { useMemo, useRef, useState } from 'react';

import { Box, Collapse, Link } from '@mui/material';

import useWindowSize from 'src/libs/finbits/hooks/useWindowSize';

type Props = {
  text: string;
};

export default function CollapseText({ text }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [width, height] = useWindowSize();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  function toggleCollapse() {
    setIsCollapseOpen((prev) => !prev);
  }

  const [isCollapsibleText, collapsedSize] = useMemo(() => {
    const collapsedSize = Math.ceil(height * 0.3);
    const contentSize = ref?.current?.clientHeight || 0;

    const isCollapsibleText = contentSize > collapsedSize;

    if (isCollapsibleText) {
      return [isCollapsibleText, collapsedSize];
    }

    return [isCollapsibleText, contentSize];
    /**
     * Precisamos observar as mudanças nas medidas da tela
     * para saber se precisamos do comportamento collapsible
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, ref.current?.clientHeight]);

  return (
    <>
      <Collapse in={isCollapseOpen} collapsedSize={`${collapsedSize}px`}>
        <Box
          ref={ref}
          sx={{
            wordBreak: 'break-word',
            fontSize: 14,
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Collapse>

      {isCollapsibleText && (
        <Link
          component="button"
          onClick={toggleCollapse}
          sx={{ mt: 3, color: 'primary.dark', fontSize: 14 }}
        >
          {isCollapseOpen ? 'Fechar' : 'Leia Mais'}
        </Link>
      )}
    </>
  );
}
