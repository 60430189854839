import type { ReactNode, Ref } from 'react';
import { forwardRef, useImperativeHandle } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import type { UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';

import type { SetupNotaFiscalFormParams } from 'src/libs/finbits/NotaFiscal/Setup/types';
import { NotaFiscalSetupResolver } from 'src/libs/finbits/NotaFiscal/Setup/types';
import {
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';

export type NotaFiscalFormRef = {
  isDirty?: boolean;
  setError: UseFormSetError<SetupNotaFiscalFormParams>;
};

type Props = {
  onSubmit: (params: SetupNotaFiscalFormParams) => void;
  children: ReactNode;
  ref: NotaFiscalFormRef;
};

function NotaFiscalSetupForm(
  { children, onSubmit }: Props,
  ref: Ref<NotaFiscalFormRef>
) {
  const { companyId, organizationId } = useCompanyParams();
  const { company } = useCompany({ companyId, organizationId });

  const values = {
    simpleNational: company?.simpleNational ?? false,
    municipalRegistration: company?.municipalRegistration ?? '',
    specialTaxRuleId: company?.specialTaxRuleId ?? '',
    address: {
      city: company?.address?.city ?? '',
    },
    monitoringEnabled:
      company?.notaFiscalConnection?.monitoringEnabled ?? false,
    monitoringProductEnabled:
      company?.notaFiscalConnection?.monitoringProductEnabled ?? false,
  };

  const form = useForm<SetupNotaFiscalFormParams>({
    resolver: zodResolver(NotaFiscalSetupResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: values,
    values: values as SetupNotaFiscalFormParams,
  });

  const {
    handleSubmit,
    setError,
    formState: { isDirty },
  } = form;

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit,
      isDirty,
      setError,
    }),
    [handleSubmit, isDirty, setError]
  );

  return (
    <FormProvider {...form}>
      <form data-testid="nota-fiscal-form" onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}

export default forwardRef(NotaFiscalSetupForm);
