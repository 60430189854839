import type { ReactNode } from 'react';

import cn from 'classnames';
import type { RadioProps } from 'antd';
import { Radio } from 'antd';

import { Card } from 'src/ui';

import styles from './RadioCard.module.less';

type Props = RadioProps & {
  children: ReactNode;
  isActive?: boolean;
  label: string;
};

export default function RadioCard({
  children,
  label,
  isActive,
  ...rest
}: Props) {
  return (
    <Card
      boxShadow={1}
      className={cn([styles.card, isActive && styles.active])}
    >
      <Radio {...rest}>
        <b>{label}</b>
        <div>{children}</div>
      </Radio>
    </Card>
  );
}
