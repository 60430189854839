import type { PropsWithChildren } from 'react';
import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import styles from './PreviewBase.module.scss';

type Props = PropsWithChildren & {
  className?: string;
};

export default function PreviewBase({ className, children }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState(false);

  const [startMouse, setStartMouse] = useState({
    x: 0,
    y: 0,
  });

  const [startScroll, setStartScroll] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    function handleMouseUp() {
      setIsDragging(false);
    }

    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  function handleMouseDown(event: React.MouseEvent<HTMLElement>) {
    if (!ref.current) return;

    setIsDragging(true);

    setStartMouse({ x: event.clientX, y: event.clientY });

    setStartScroll({
      top: ref.current.scrollTop,
      left: ref.current.scrollLeft,
    });
  }

  function handleMove(event: React.MouseEvent<HTMLElement>) {
    if (!ref.current || !isDragging) return;

    const deltaX = event.clientX - startMouse.x;
    const deltaY = event.clientY - startMouse.y;

    ref.current.scrollLeft = startScroll.left - deltaX;
    ref.current.scrollTop = startScroll.top - deltaY;
  }

  return (
    <div
      ref={ref}
      className={cn(styles.previewBase, className, {
        [styles.grabbing]: isDragging,
      })}
      onMouseMove={handleMove}
      onMouseDown={handleMouseDown}
    >
      {children}
    </div>
  );
}
