import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { dateColumn } from 'src/mui/_scss';

import { format } from 'src/libs/finbits/Date';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

export function relevantdateColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return dateColumn({
    columnProps: {
      aggregable: false,
      editable: true,
      headerName: 'Competência',
      field: 'relevantDate',
      valueFormatter: (value) => (!!value ? format(value, 'MMM/yyyy') : null),
      groupingValueGetter: (value) =>
        !!value ? format(value, 'MMM/yyyy') : 'sem data',
      minWidth: 85,
      ...columnProps,
    },
    datePickerProps: {
      format: 'MMM/yyyy',
      views: ['month', 'year'],
    },
  });
}
