import { Box } from '@mui/material';
import { PlusIcon } from 'src/mui/_icons';
import { Link } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import type { ContactSuggestion } from 'src/libs/finbits/Management/Entries/types';

import ShowContactDetails from 'src/features/EntryForm/ShowContactDetails';
import { formatNotFoundContactText } from 'src/features/EntryForm/contactNotFound';

import styles from './ContactActions.module.scss';

type Props = {
  contact?: Contact | null;
  suggestion?: ContactSuggestion | null;
  onCreateContact?: () => void;
  disabled?: boolean;
};

export default function ContactActions({
  contact,
  suggestion,
  onCreateContact,
  disabled = false,
}: Props) {
  const hasSuggestion = Boolean(suggestion);

  if (contact?.id) {
    return (
      <Box className={styles.showContactDetailsContainer}>
        <ShowContactDetails contactId={contact.id} />
      </Box>
    );
  }

  if (hasSuggestion && !contact?.id) {
    return (
      <>
        <Typography className={styles.notFoundContactText}>
          {formatNotFoundContactText(suggestion)}
        </Typography>
        {!disabled && (
          <WithAllowedPermission
            permissions={{ action: ['create'], resource: 'contacts' }}
          >
            <Link startIcon={<PlusIcon />} onClick={onCreateContact}>
              Criar contato
            </Link>
          </WithAllowedPermission>
        )}
      </>
    );
  }

  return null;
}
