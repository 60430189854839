import t from 'zod';

import { isValidPhone } from 'src/libs/finbits/Organization/PhoneNumber';

export const phoneNumber = t
  .string()
  .refine(isValidPhone, {
    message: 'Número de telefone inválido',
  })
  .nullish()
  .or(t.literal(''));
