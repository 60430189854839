import type { QuickFilter } from 'src/mui/_scss/Table/FilterPanel/QuickFilters/types';
import { endOfToday, endOfWeek, endOfYesterday, startOfWeek } from 'date-fns';

import { format } from 'src/libs/finbits/Date';

export const panelQuickFilters: QuickFilter[] = [
  {
    label: 'Atrasados',
    filterItems: [
      {
        field: 'date',
        operator: 'before',
        value: format(endOfYesterday(), 'yyyy-MM-dd'),
        id: 'overdue-1',
      },
      {
        field: 'currentSituation',
        operator: 'not',
        value: 'paid',
        id: 'overdue-2',
      },
    ],
  },
  {
    label: 'A pagar hoje',
    filterItems: [
      {
        field: 'date',
        operator: 'is',
        value: format(endOfToday(), 'yyyy-MM-dd'),
        id: 'today-1',
      },
    ],
  },
  {
    label: 'A pagar nesta semana',
    filterItems: [
      {
        field: 'date',
        operator: 'onOrAfter',
        value: format(startOfWeek(endOfToday()), 'yyyy-MM-dd'),
        id: 'pending-1',
      },
      {
        field: 'date',
        operator: 'onOrBefore',
        value: format(endOfWeek(endOfToday()), 'yyyy-MM-dd'),
        id: 'pending-2',
      },
      {
        field: 'currentSituation',
        operator: 'not',
        value: 'paid',
        id: 'pending-3',
      },
    ],
  },
];
