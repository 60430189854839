import type { ColumnType } from 'antd/lib/table';

import { FilterTree } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

type Params = {
  accounts: Account[];
};

export default function AccountFilter<T extends { accountId: string }>({
  accounts,
}: Params): ColumnType<T> {
  const filters = accounts.map((account) => ({
    text: account.name,
    value: account.id,
  }));

  return {
    filterMode: 'tree',
    filters,
    filterDropdown: (props) => <FilterTree {...props} />,
    onFilter: (value, record) => record?.accountId === value,
  };
}
