import type { FormInstance } from 'antd/lib/form';
import type { FieldValues, UseFormSetError } from 'react-hook-form';

import { removeAccentuation } from 'src/libs/finbits/Accentuation';
import type { ApiErrorForm } from 'src/libs/finbits/client';

export type ErrorsObject = {
  [key: string]: any;
};

export type FieldError = {
  name: string | string[];
  errors: string[];
};

export function isFormCompleted(
  form: FormInstance,
  fields: string[] | boolean = true
) {
  const formTouched =
    fields === true
      ? !!form.isFieldsTouched(true)
      : !!form.isFieldsTouched(fields as string[], true);

  const formHasErrors = form
    .getFieldsError()
    .some(({ errors }: any) => errors.length);

  return formTouched && !formHasErrors;
}

export function isFormPartiallyComplete(form: FormInstance, fields: string[]) {
  const hasErrors = !!form
    .getFieldsError(fields)
    .filter(({ errors }: any) => errors.length).length;

  const wasTouched = !!form.isFieldsTouched(fields, true);

  return !hasErrors && wasTouched;
}

export function isStructuredFormError(
  value: ErrorsObject = {}
): value is ApiErrorForm {
  return value && typeof value.errors === 'object';
}

export function setErrorsInForm<T extends FieldValues>(
  value: ApiErrorForm,
  setError: UseFormSetError<T>
) {
  Object.entries(value).forEach(([field, fieldMessage]) => {
    const message = Array.isArray(fieldMessage)
      ? fieldMessage.join(', ')
      : fieldMessage;

    setError(field as any, { message });
  });
}

export function errorsToFormField(errorObj: ErrorsObject) {
  const fieldsErrors = Object.entries(errorObj).reduce(
    (errors: FieldError[], [path, value]: any[]) =>
      addError(errors, path, value),
    []
  );

  return fieldsErrors;
}

function addError(
  accErrors: FieldError[],
  errorPath: string | string[],
  errorValue: Object
): FieldError[] {
  if (typeof errorValue === 'string') {
    return [...accErrors, { name: errorPath, errors: [errorValue] }];
  }

  if (Array.isArray(errorValue)) {
    return [...accErrors, { name: errorPath, errors: errorValue }];
  }

  if (isLiteralObject(errorValue)) {
    return buildNestedError(accErrors, errorPath, errorValue);
  }

  return accErrors;
}

function buildNestedError(
  accErrors: FieldError[],
  errorPath: string | string[],
  errorValue: Object
): FieldError[] {
  const fieldsErrors = Object.entries(errorValue).reduce(
    (errors: FieldError[], [path, value]: any[]) => {
      const currentPath = Array.isArray(errorPath)
        ? [...errorPath, path]
        : [errorPath, path];

      return addError(errors, currentPath, value);
    },
    accErrors
  );

  return fieldsErrors;
}

function isLiteralObject(value: any) {
  return !!value && value.constructor === Object;
}

export function filterOption(input: string, option: any) {
  const optionFormatted = removeAccentuation(option.children.toLowerCase());
  const inputFormatted = removeAccentuation(input.toLowerCase());
  return optionFormatted.indexOf(inputFormatted) >= 0;
}
