import type { ColumnType } from 'antd/es/table';

import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { PAYMENT_METHOD_DICTIONARY } from 'src/libs/finbits/PaymentMethods/translations';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

const filters = Object.values(PaymentMethod).map((paymentMethod) => ({
  text: PAYMENT_METHOD_DICTIONARY[paymentMethod],
  value: paymentMethod,
}));

function PaymentMethodFilter<T extends BillPayable>(): ColumnType<T> {
  return {
    filterMode: 'tree',
    filters,
    onFilter: (value, record) => {
      const paymentMethod = record.paymentDetails?.paymentMethod;

      return paymentMethod === value;
    },
  };
}

export default PaymentMethodFilter;
