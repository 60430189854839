import has from 'lodash/has';
import { useMutation } from 'react-query';
import type { StaticPixEmvElements } from 'pix-utils';
import { parsePix } from 'pix-utils';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import { formatCNPJ, formatCPF } from 'src/libs/finbits/Documents';
import { phoneFormat } from 'src/libs/finbits/Organization/PhoneNumber';

import type { ParsedQrCode, PixKey, PixKeyPostParams } from './types';
import { PixKeyDecoder } from './types';

async function postPixKey({
  organizationId,
  companyId,
  ...params
}: PixKeyPostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/pix_keys`,
    params
  );

  return decodeResponse<PixKey>(response, PixKeyDecoder);
}

export function useCreatePixKey() {
  const { mutate: createPixKey, ...rest } = useMutation<
    PixKey,
    ApiError,
    PixKeyPostParams
  >(postPixKey);
  return { createPixKey, ...rest };
}

export function formatPixKey(pixKey: string, pixType: string) {
  switch (pixType) {
    case 'cpf':
      return formatCPF(pixKey);

    case 'cnpj':
      return formatCNPJ(pixKey);

    case 'cellphone_number':
      return phoneFormat(pixKey) || pixKey;

    default:
      return pixKey;
  }
}

function parseAmount(parsedQrCode: StaticPixEmvElements) {
  return (parsedQrCode.transactionAmount ?? 0) * 100;
}

export function parseQrCode(qrCode?: string | null): ParsedQrCode {
  if (!qrCode) {
    return { error: true };
  }

  const parsedQrCode = parsePix(qrCode);

  const isError = has(parsedQrCode, 'error');

  if (isError) {
    return {
      error: isError,
    };
  }

  return {
    error: isError,
    parsedQrCode: {
      ...parsedQrCode,
      transactionAmount: parseAmount(parsedQrCode as StaticPixEmvElements),
    } as StaticPixEmvElements,
  };
}
