import type { ChangeEvent } from 'react';
import { useState } from 'react';

import type { SxProps } from '@mui/material';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { CloseIcon, SearchIcon } from 'src/mui/_icons';

type Props = {
  onChange: (value: string) => void;
  loading: boolean;
  className?: string;
  sx?: SxProps;
};

export default function SearchInput({
  onChange,
  loading,
  className,
  sx,
}: Props) {
  const [value, setValue] = useState<string>('');

  function handleOnChange(
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const value = e.target.value;

    setValue(value);
    onChange(value);
  }

  function clear() {
    setValue('');
    onChange('');
  }

  return (
    <OutlinedInput
      value={value}
      placeholder="Buscar"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="start" sx={{ gap: 2 }}>
          {loading && <CircularProgress size={18} color="secondary" />}
          {!loading && !!value && (
            <IconButton aria-label="clear search" onClick={clear} edge="end">
              <CloseIcon />
            </IconButton>
          )}
        </InputAdornment>
      }
      onChange={handleOnChange}
      sx={sx}
      className={className}
    />
  );
}
