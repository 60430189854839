import type { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { API, decodeResponse } from 'src/libs/finbits/client';
import { ONE_HOUR_IN_MS } from 'src/libs/finbits/Time';

import type { Bank } from './types';
import { BanksDecoder } from './types';

type BankExtended = Bank & { fullName: string };

export async function getBanks() {
  const response = await API.get('/institutions');
  return decodeResponse<Bank[]>(response, BanksDecoder);
}

function addFullName(data: Bank[]) {
  return data.map((bank) => {
    return {
      ...bank,
      fullName: `${bank.routingNumber} - ${bank.name}`,
    };
  });
}

type UseBanksProps = {
  select?: (data: Bank[]) => any;
  enabled?: boolean;
};

export function useBanks<TData = BankExtended[]>({
  select,
  enabled,
}: UseBanksProps = {}) {
  return useQuery<
    Bank[],
    AxiosError<{ message: string; errors: string }>,
    TData
  >({
    queryKey: 'banks',
    queryFn: () => getBanks(),
    staleTime: ONE_HOUR_IN_MS,
    select: select || addFullName,
    enabled,
  });
}

export function useBank(toFindRoutingNumber: string | null, enabled?: boolean) {
  return useBanks<BankExtended>({
    select: (data: Bank[]) => {
      const bank = data.find(
        ({ routingNumber }) => toFindRoutingNumber === routingNumber
      );
      return (
        bank && { ...bank, fullName: `${bank.routingNumber} - ${bank.name}` }
      );
    },
    enabled,
  });
}

export function useBankName(toFindRoutingNumber: string) {
  return useBanks<string>({
    select: (data: Bank[]) =>
      data.find(({ routingNumber }) => toFindRoutingNumber === routingNumber)
        ?.name,
  });
}
