import type {
  GridColDef,
  GridValidRowModel,
  GridValueSetter,
} from '@mui/x-data-grid-premium';
import type { DatePickerProps } from '@mui/x-date-pickers-pro';

import { format, isSameDay, isValid, parseISO } from 'src/libs/finbits/Date';

import DateInput from './DateInput';

type Props<T extends GridValidRowModel> = {
  columnProps: GridColDef<T>;
  datePickerProps?: DatePickerProps<Date>;
};

function parseDate(value: Date | string) {
  return typeof value === 'string' ? parseISO(value) : value;
}

export function hasChangeValidator(
  newValue: Date | null,
  oldValue: Date | string | null
) {
  if (newValue === null && oldValue === null) return false;

  if (newValue === null || oldValue === null) return true;

  const oldDate = parseDate(oldValue);

  const hasChange = !isSameDay(newValue, oldDate);

  return hasChange;
}

export function dateColumn<T extends GridValidRowModel>({
  columnProps,
  datePickerProps,
}: Props<T>): GridColDef<T> {
  return {
    type: 'date',
    valueGetter: (value: string | Date) => value && parseDate(value),
    getApplyQuickFilterFn: (filterValue: string) => {
      if (!filterValue) return null;

      return (value: string | Date): boolean => {
        if (!value) return false;

        return filterValue.split(' ').some((splitFilter) => {
          if (!value) return false;

          const formatedDate = format(value, 'dd/MM/yyyy');

          return formatedDate.includes(splitFilter);
        });
      };
    },
    valueFormatter: (value: Date) => {
      if (!value) return null;

      if (isValid(new Date(value))) return format(value, 'dd/MM/yyyy');

      return value;
    },
    groupingValueGetter: (value) => {
      if (isValid(new Date(value))) return format(value, 'dd/MM/yyyy');

      return value;
    },
    renderEditCell: (props) => <DateInput {...datePickerProps} {...props} />,
    valueSetter: (value: GridValueSetter, row) => {
      return {
        ...row,
        [columnProps.field]: value,
        hasChangeValidator,
        updated: { [columnProps.field]: value },
      };
    },
    ...columnProps,
  };
}
