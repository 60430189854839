import { useEffect, useRef, useState } from 'react';

import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Unstable_Grid2';
import { cnpj } from 'cpf-cnpj-validator';
import type { UseFormSetError } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SearchIcon } from 'src/mui/_icons';

import { TextField } from 'src/mui';

import { cnpjMask } from 'src/libs/finbits/Documents';
import { unMask } from 'src/libs/finbits/Masker';
import { useLegalEntity } from 'src/libs/finbits/BrazilianData/LegalEntities';
import type { CompanyCreateParams } from 'src/libs/finbits/Organization/Companies/types';
import type { CompanyInfo } from 'src/libs/finbits/Organization/types';
import { CompanyInfoFormResolver } from 'src/libs/finbits/Organization/types';

type Props = {
  initialValues?: CompanyCreateParams;
  isLoading?: boolean;
  onCancel?: () => void;
  onSubmit: (
    company: CompanyCreateParams,
    setError: UseFormSetError<CompanyInfo>
  ) => void;
  setIsFormEdited?: (value: boolean) => void;
  submitErrors?: { name: string; errors: string[] }[];
  submitLabel?: string;
};

export default function CompanyForm({
  initialValues,
  isLoading,
  onCancel,
  onSubmit,
  setIsFormEdited,
  submitErrors,
  submitLabel = 'Continuar',
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setError,
    setValue,
    clearErrors,
  } = useForm<CompanyInfo>({
    defaultValues: {
      document: initialValues?.document ?? '',
      name: initialValues?.name ?? '',
      tradeName: initialValues?.tradeName ?? '',
    },
    resolver: zodResolver(CompanyInfoFormResolver),
  });

  const searchEl = useRef<HTMLElement>(null);

  const extraInfoInitial = {
    address: initialValues?.address,
    email: initialValues?.email,
    phone: initialValues?.phone,
  };

  const [searchDocument, setSearchDocument] = useState<string | undefined>(
    undefined
  );
  const [extraInfo, setExtraInfo] = useState(
    initialValues ? extraInfoInitial : {}
  );

  const { isFetching: isFetchingDocument, refetch: refetchDocument } =
    useLegalEntity(
      { document: searchDocument },
      {
        retry: false,
        onSuccess: (contactInfo) => {
          const { name, nickname, address, phone, email } = contactInfo;

          setValue('name', name);
          setValue('tradeName', nickname ?? '');

          clearErrors('name');
          clearErrors('tradeName');

          setExtraInfo({
            address: {
              ...address,
              postalCode: address.postalCode
                ? unMask(address.postalCode)
                : null,
            },
            email: email,
            phone: phone,
          });
        },
        onError: () => {
          setValue('name', '');
          setValue('tradeName', '');
          setExtraInfo({});
        },
      }
    );

  function onSearchDocument() {
    const currentDocument = getValues('document');

    const isDocumentValid = currentDocument && cnpj.isValid(currentDocument);

    if (!isDocumentValid) return;

    if (currentDocument && currentDocument === searchDocument) {
      return refetchDocument();
    }

    setSearchDocument(currentDocument);
  }

  function onFinish(values: CompanyCreateParams) {
    onSubmit({ ...values, ...extraInfo }, setError);
  }

  function onSearch() {
    searchEl?.current?.focus();
    searchEl?.current?.blur();
  }

  useEffect(() => {
    setIsFormEdited && setIsFormEdited(isDirty);
  }, [isDirty, setIsFormEdited]);

  useEffect(() => {
    submitErrors?.map(({ name, errors }) => {
      const message = errors.join(', ');
      return setError(name as 'document', { message });
    });
  }, [setError, submitErrors]);

  return (
    <Box component="form" onSubmit={handleSubmit(onFinish)}>
      <Controller
        name="document"
        control={control}
        render={({ field }) => (
          <TextField.Masked
            {...field}
            disabled={isFetchingDocument}
            error={!!errors.document?.message}
            helperText={errors.document?.message}
            label="CNPJ"
            margin="normal"
            mask={[cnpjMask]}
            onBlur={onSearchDocument}
            placeholder="00.000.000/0000-00"
            ref={searchEl}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={onSearch}
                    type="button"
                    aria-label="buscar"
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            disabled={isFetchingDocument}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label="Razão social"
            margin="normal"
            placeholder="Insira a razão social"
          />
        )}
      />
      <Controller
        name="tradeName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            disabled={isFetchingDocument}
            error={!!errors.tradeName?.message}
            helperText={errors.tradeName?.message}
            label="Nome da empresa"
            margin="normal"
            placeholder="Insira o nome da empresa"
          />
        )}
      />

      <Grid container>
        {onCancel && (
          <Grid xs={6} pr={4}>
            <Button
              fullWidth
              variant="outlined"
              onClick={onCancel}
              sx={{ mt: 8 }}
              size="large"
            >
              Cancelar
            </Button>
          </Grid>
        )}

        <Grid xs={onCancel ? 6 : 12}>
          <LoadingButton
            color="primary"
            fullWidth
            loading={isFetchingDocument || isLoading}
            sx={{ mt: 8 }}
            type="submit"
            variant="contained"
            size="large"
          >
            {submitLabel}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
