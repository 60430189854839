import { useState } from 'react';

import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type {
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { TrashIcon } from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import {
  Drawer,
  DrawerHeader,
  Loader,
  Title,
  useOpenConfirmDialog,
} from 'src/ui';

import { pluralize } from 'src/libs/finbits/Pluralize';
import { useNotaFiscal } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Receivable } from 'src/libs/finbits/Receivables/types';
import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import { useUnlinkEntries } from 'src/libs/finbits/NotaFiscal/LinkToEntries';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import type { FilteredInfo } from 'src/features/nota-fiscal/LinkEntriesTable';
import LinkEntriesTable from 'src/features/nota-fiscal/LinkEntriesTable';

type Props = {
  notaFiscalId: string;
} & PortalProps;

export default function LinkedEntriesDrawer({
  notaFiscalId,
  onClose,
  open = true,
  onExit,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const canUnlinkEntries = useAllowedPermission({
    action: 'unlink_entries',
    resource: 'notasFiscais',
  });

  const { notaFiscal, isLoading } = useNotaFiscal({
    id: notaFiscalId,
    companyId,
    organizationId,
  });

  const openConfirmDialog = useOpenConfirmDialog();

  const [selectedEntries, setSelectedEntries] = useState<
    Array<Receivable | FinancialEntry>
  >([]);

  const { unlinkEntries, isLoading: isUnlinking } = useUnlinkEntries();

  function handleUnlinkReceivable() {
    const selectedEntriesLength = selectedEntries.length;
    const selectedEntriesIds = selectedEntries.map((entry) => entry.id);

    const receivablesIds = selectedEntriesIds.filter((entryId) =>
      notaFiscal?.receivables!.find((receivable) => receivable.id === entryId)
    );

    const financialEntriesIds = selectedEntriesIds.filter((entryId) =>
      notaFiscal?.financialEntries!.find(
        (financialEntry) => financialEntry.id === entryId
      )
    );

    unlinkEntries(
      {
        companyId,
        organizationId,
        receivablesIds,
        financialEntriesIds,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: `${pluralize(
              selectedEntriesLength,
              `${selectedEntriesLength} recebimento desvinculado`,
              `${selectedEntriesLength} recebimentos desvinculados`
            )}`,
          });

          onClose();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao desvincular recebimento!',
          });
        },
      }
    );
  }

  function handleUnlinkClick() {
    if (selectedEntries.length === 0) {
      return snackbar({
        variant: 'error',
        message: 'Você precisa selecionar pelo menos um recebimento.',
      });
    }

    openConfirmDialog({
      variant: 'error',
      icon: <TrashIcon />,
      title: 'Tem certeza que deseja desvincular?',
      content:
        'Ao confirmar, os recebimentos selecionados serão desvinculados da nota fiscal.',
      confirmText: 'Sim, quero desvincular',
      onConfirm: handleUnlinkReceivable,
      cancelText: 'Cancelar',
    });
  }

  const [filteredInfo, setFilteredInfo] = useState<FilteredInfo>();

  function handleChangeTable(
    _pagination: TablePaginationConfig,
    filters: FilteredInfo,
    _sorter:
      | SorterResult<Receivable | FinancialEntry>
      | SorterResult<Receivable | FinancialEntry>[]
  ) {
    setFilteredInfo(filters);
  }

  const entries = notaFiscal
    ? [
        ...(notaFiscal.receivables ?? []),
        ...(notaFiscal.financialEntries ?? []),
      ]
    : [];

  const hasLinkedEntries = entries?.length > 0;

  return (
    <Drawer
      title={
        <DrawerHeader
          title={
            <Title icon={<LinkOutlined />}>
              Recebimentos vinculados com a nota fiscal
            </Title>
          }
          extra={
            canUnlinkEntries &&
            (hasLinkedEntries ? (
              <Button onClick={handleUnlinkClick} loading={isUnlinking}>
                Desvincular
              </Button>
            ) : null)
          }
        />
      }
      footer={null}
      onClose={onClose}
      visible={open}
      afterVisibleChange={onExit}
    >
      {isLoading && <Loader />}
      {notaFiscal && (
        <LinkEntriesTable
          receivables={notaFiscal.receivables ?? []}
          financialEntries={notaFiscal.financialEntries ?? []}
          filteredInfo={filteredInfo}
          onChange={handleChangeTable}
          rowSelection={
            canUnlinkEntries
              ? {
                  type: 'checkbox',
                  selectedRowKeys: selectedEntries.map((entry) => entry.id),
                  onChange: (_, selectedRows) =>
                    setSelectedEntries(selectedRows),
                }
              : undefined
          }
        />
      )}
    </Drawer>
  );
}
