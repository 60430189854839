import t from 'zod';

export enum PaymentMethod {
  BOLETO = 'boleto',
  CREDIT_CARD = 'credit_card',
  DIRECT_DEBIT = 'direct_debit',
  PIX = 'pix',
  PIX_QR_CODE = 'pix_qr_code',
  WIRE_TRANSFER = 'wire_transfer',
}

export const PaymentMethodDecoder = t.nativeEnum(PaymentMethod);
