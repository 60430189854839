import t from 'zod';

export const AddressDecoder = t.object({
  state: t.string().nullable(),
  city: t.string().nullable(),
  district: t.string().nullable(),
  street: t.string().nullable(),
  number: t.string().nullable(),
  details: t.string().nullable(),
  postalCode: t.string().nullable(),
  countryCode: t.string().nullable(),
});

export type Address = t.TypeOf<typeof AddressDecoder>;

export type GetParams = {
  postalCode: string;
};
