import { Chip, Typography } from 'src/design-system/components';
import { Stack } from '@mui/material';

import type { ApprovalRuleCondition } from 'src/libs/finbits/ApprovalRules/types';
import { APPROVAL_RULE_CONDITION_TYPE_DICTIONARY } from 'src/libs/finbits/ApprovalRules/translations';

type Props = {
  condition: ApprovalRuleCondition;
};

export default function CategoryConditions({ condition }: Props) {
  return (
    <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
      <Chip
        role="chip"
        aria-label="Categoria"
        label="Categoria"
        variant="outlined"
        size="xsmall"
      />

      <Typography>
        {APPROVAL_RULE_CONDITION_TYPE_DICTIONARY[condition.type]}
      </Typography>

      {condition.categoriesValues?.map((values, index) => (
        <Chip
          role="chip"
          aria-label="condition"
          key={index}
          label={values.category.name}
          variant="outlined"
          size="xsmall"
          color="success"
        />
      ))}
    </Stack>
  );
}
