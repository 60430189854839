import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type { TransitionProps } from '@mui/material/transitions';
import type { DialogProps } from '@mui/material';
import {
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  Stack,
} from '@mui/material';
import { CloseIcon } from 'src/mui/_icons';
import { FormField } from 'src/mui/_scss';
import { LoadingButton } from '@mui/lab';
import { Typography } from 'src/design-system/components';

import { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';

import { Dialog, IconButton, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import Img1 from 'src/assets/default-rules-step-1.svg';
import Img3 from 'src/assets/default-rules-step-3.svg';

import {
  BillsApprovalsType,
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';
import { useUpdateCompanyEnabledBillsApprovals } from 'src/libs/finbits/Organization/Companies/Options';

import AssigneeSelect from 'src/features/assignee/AssigneeSelect';
import ApprovalTypeSelect from 'src/features/approval/ApprovalTypeSelect';

import type { FormProps } from './types';
import { FormResolver } from './types';
import styles from './CreateDefaultRuleModal.module.scss';

type Props = DialogProps & {
  onConfirm: () => void;
} & PortalProps;

function titleText(step: number) {
  if (step === 1) return 'Ativar a automatização por regras de aprovação';
  if (step === 2) return 'Ativar regras de aprovação';
  return 'Regras de aprovação';
}

function buttonText(step: number) {
  if (step === 1) return 'Vamos lá';
  if (step === 2) return 'Salvar';
  return 'Continuar criação de regras';
}

function formToPostValue(formProps?: FormProps) {
  if (!formProps) return undefined;

  return {
    description: formProps.description,
    approvalType: formProps.approvalType,
    assigneesIds: formProps.assignees.map((assignee) => assignee.id),
  };
}

export default function CreateDefaultRuleModal({
  open,
  onClose,
  onConfirm,
  onExit,
}: Props) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  const { organizationId, companyId } = useCompanyParams();
  const { company } = useCompany({ organizationId, companyId });

  const { updateCompanyEnabledBillsApprovals, isLoading } =
    useUpdateCompanyEnabledBillsApprovals();

  const { control, watch, ...form } = useForm<FormProps>({
    resolver: zodResolver(FormResolver),
    mode: 'onChange',
    defaultValues: {
      assignees: [],
      approvalType: BillPayableApprovalType.ANY,
      description: 'Aprovador(es) padrão',
    },
  });

  async function handleCreateDefaultRule() {
    try {
      const isValid = await form.trigger('assignees');

      if (isValid) {
        const defaultRuleProps = form.getValues();

        updateCompanyEnabledBillsApprovals(
          {
            companyId,
            organizationId,
            billsApprovals: BillsApprovalsType.AUTOMATIC,
            defaultRule: formToPostValue(defaultRuleProps),
          },
          {
            onSuccess: () => {
              setCurrentStep(currentStep + 1);

              snackbar({
                variant: 'success',
                message: 'O fluxo de aprovação alterado para automático.',
              });
            },
            onError: () => {
              snackbar({
                variant: 'error',
                message: 'Ocorreu um erro ao salvar as alterações!',
              });
            },
          }
        );
      }
    } catch (e) {}
  }

  async function onNext() {
    if (currentStep === 2) {
      await handleCreateDefaultRule();

      return;
    }

    if (currentStep === 3) {
      onConfirm();
      onClose();
      navigate(
        generateCompanyPath(RouteKey.APPROVAL_RULES, {
          companyId,
          organizationId,
        })
      );
      return;
    }

    setCurrentStep(currentStep + 1);
  }

  const selectedAssignees = watch('assignees');

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      fullWidth
      maxWidth="md"
    >
      <IconButton
        aria-label="Fechar modal de criação de regra de aprovação"
        onClick={onClose}
        className={styles.closeIcon}
      >
        <CloseIcon fontSize="medium" />
      </IconButton>

      <DialogTitle variant="text-lg" fontWeight="600">
        {titleText(currentStep)}
      </DialogTitle>

      <DialogContent dividers className={styles.content}>
        <Slide
          key="1"
          direction="left"
          in={currentStep === 1}
          unmountOnExit
          mountOnEnter
          timeout={{ appear: 100, enter: 100, exit: 0 }}
        >
          <Stack gap={10} direction="row" className={styles.description}>
            <img srcSet={Img1} src={Img1} alt="start" loading="lazy" />
            <div>
              <Typography fontWeight="600" marginBottom={4} color="grey.600">
                Você está ativando as regras de aprovação para a empresa{' '}
                {company?.tradeName}.
              </Typography>
              <Typography marginBottom={6} color="grey.600">
                Ao ativar, os aprovadores passarão a ser atribuídos aos
                lançamentos de forma automática, por meio das regras que você
                criar.
              </Typography>
              <Typography color="grey.600">Vamos começar?</Typography>
            </div>
          </Stack>
        </Slide>
        <Slide
          key="2"
          direction="left"
          in={currentStep === 2}
          unmountOnExit
          mountOnEnter
          timeout={{ appear: 200, enter: 200, exit: 0 }}
        >
          <div className={styles.form}>
            <Typography fontWeight="600" marginBottom={2} color="grey.900">
              Vamos criar a sua primeira regra
            </Typography>
            <Typography color="grey.600">
              Por favor, defina um ou mais usuários para serem os aprovador(es)
              padrão.
            </Typography>

            <Divider className={styles.divider} />

            <Typography fontWeight="500" marginBottom={2} color="grey.700">
              Condições
            </Typography>
            <Typography color="grey.500" fontWeight="400">
              Se nenhuma outra regra for aplicada
            </Typography>

            <Divider className={styles.divider} />

            <FormField
              label="Aprovador(es) padrão"
              name="assignees"
              control={control as any}
            >
              {(field) => {
                return (
                  <AssigneeSelect
                    {...field}
                    multiple
                    placeholder="Selecione um ou mais usuários"
                    onChange={(_e, value) => field.onChange(value)}
                  />
                );
              }}
            </FormField>

            <Collapse
              in={Boolean(selectedAssignees.length > 1)}
              className={styles.collapse}
            >
              <FormField
                label="Aprovações necessárias"
                name="approvalType"
                control={control as any}
              >
                {(field) => {
                  return (
                    <ApprovalTypeSelect
                      {...field}
                      onChange={(_e, value) => field.onChange(value)}
                    />
                  );
                }}
              </FormField>
            </Collapse>
          </div>
        </Slide>
        <Slide
          key="3"
          direction="left"
          in={currentStep === 3}
          unmountOnExit
          mountOnEnter
        >
          <Stack gap={10} direction="row" className={styles.description}>
            <img srcSet={Img3} src={Img3} alt="relax" loading="lazy" />
            <div>
              <Typography fontWeight="600" marginBottom={4} color="grey.900">
                O modo de regras de aprovação foi ativado
              </Typography>
              <Typography marginBottom={6} color="grey.600">
                Continue criando regras para automatizar a atribuição de
                aprovadores dos seus lançamentos.
              </Typography>
              <Typography marginBottom={6} color="grey.600">
                Caso queira fazer isso depois, fique tranquilo.
              </Typography>
              <Typography color="grey.600">
                Até que você crie novas regras, as despesas serão direcionadas
                para o(s) aprovador(es) padrão definidos.
              </Typography>
            </div>
          </Stack>
        </Slide>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant="outlined" onClick={onClose}>
          {currentStep === 3 ? 'Fazer depois' : 'Cancelar'}
        </Button>

        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          onClick={onNext}
        >
          {buttonText(currentStep)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
