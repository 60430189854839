import { useEffect } from 'react';

import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormField } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';

import { AccountType } from 'src/libs/finbits/Bank/Accounts/types';

import BankSelect from 'src/features/bank/BankSelect/BankSelect';
import InitialBalanceFields from 'src/features/accounts/AccountModal/AccountForm/InitialBalanceFields';

export default function AccountTypeSteppe() {
  const { control, watch, resetField } = useFormContext();

  const accountType = watch('type');
  const currentDate = new Date();

  useEffect(() => {
    if (accountType === AccountType.CHECKING_ACCOUNT) {
      resetField('name');
      resetField('initialBalance');

      return;
    }

    resetField('routingNumber');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  return (
    <Stack display="flex" gap={5} flexDirection="column" paddingBottom={8}>
      <Typography variant="text-lg" fontWeight="600" component="h6">
        Tipo de conta
      </Typography>

      <Typography color="grey.600" marginBottom={3}>
        Para começar, por favor, informe o tipo da conta que deseja adicionar.
      </Typography>

      <FormField name="type" control={control}>
        {(field) => (
          <ToggleButtonGroup exclusive fullWidth {...field}>
            <ToggleButton
              value={AccountType.CHECKING_ACCOUNT}
              aria-label="Conta bancária"
            >
              Conta bancária
            </ToggleButton>
            <ToggleButton
              value={AccountType.CASH_ACCOUNT}
              aria-label="Conta não bancarizada"
            >
              Conta não bancarizada
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </FormField>

      {accountType === AccountType.CHECKING_ACCOUNT ? (
        <FormField control={control} label="Banco" name="routingNumber">
          {(field) => <BankSelect {...field} />}
        </FormField>
      ) : (
        <InitialBalanceFields maxDate={currentDate} />
      )}
    </Stack>
  );
}
