import type { FormFieldProps } from 'src/mui/_scss';
import { FormField, Input } from 'src/mui/_scss';

export default function DescriptionField({
  defaultValue = '',
  ...rest
}: FormFieldProps) {
  return (
    <FormField label="Descrição" defaultValue={defaultValue} {...rest}>
      {(field) => <Input placeholder="Digite a descrição" {...field} />}
    </FormField>
  );
}
