import classNames from 'classnames';
import type { GridFilterInputValueProps } from '@mui/x-data-grid';
import { InputLabel } from '@mui/material';

import { InputBaseTable } from 'src/mui';

import styles from './AmountInput.module.scss';

export default function AmountFilter({
  item,
  applyValue,
}: GridFilterInputValueProps) {
  function handleFilterChange(newValue: number) {
    applyValue({ ...item, value: newValue });
  }

  return (
    <>
      <InputLabel>Valor</InputLabel>
      <InputBaseTable.Amount
        value={item.value}
        onChange={handleFilterChange}
        fullWidth
        autoFocus
        inputProps={{
          className: classNames(styles.input, styles.bordered),
        }}
        signed={true}
      />
    </>
  );
}
