import { useEffect } from 'react';

import { Form, Input } from 'antd';

import { DrawerFooter } from 'src/ui';

import type { CompanyService } from 'src/libs/finbits/NotaFiscal/Services/types';

import StepInformation from '../StepInformation';
import type { StepProps } from '../types';

import CompanyServicesList from './CompanyServicesList';
import styles from './ServicesStep.module.less';

export default function ServicesStep({
  form,
  errors,
  buttonText = 'Continuar',
  initialValues,
  isButtonLoading = false,
  showTitle = true,
  onSubmit,
  onPrevious,
  onResolveError,
}: StepProps) {
  const city = initialValues?.address?.city;

  useEffect(() => {
    if (errors) {
      form.setFields(errors);
    }
  }, [form, errors]);

  function clearErrors() {
    onResolveError && onResolveError('services');
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={initialValues}
        className={styles.formWrapper}
      >
        <StepInformation
          title={showTitle ? 'Serviços Municipais' : undefined}
          description="Selecione os serviços prestados pela sua empresa. Este item é obrigatório."
          className={styles.stepInformation}
        />

        <Form.Item hidden name="services" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const companyServices: CompanyService[] =
              getFieldValue('services') ?? [];

            const hasMainActivity = companyServices.some(
              (service) => service.mainActivity
            );

            return (
              <>
                <CompanyServicesList
                  form={form}
                  city={city}
                  hasMainActivity={hasMainActivity}
                  companyServices={companyServices}
                  onConfirm={clearErrors}
                />

                <DrawerFooter>
                  {onPrevious && (
                    <DrawerFooter.BackButton onClick={onPrevious} />
                  )}
                  <DrawerFooter.SubmitButton
                    loading={isButtonLoading}
                    disabled={!hasMainActivity}
                  >
                    {buttonText}
                  </DrawerFooter.SubmitButton>
                </DrawerFooter>
              </>
            );
          }}
        </Form.Item>
      </Form>
    </>
  );
}
