export const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export function emailValidator(value: string) {
  return emailRegex.test(value);
}

export function formEmailValidator(_rule: any, value: string) {
  if (emailRegex.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Formato de e-mail inválido');
}
