import type { Components } from '@mui/material';

export function createMuiLink(): Components['MuiLink'] {
  return {
    defaultProps: {
      underline: 'none',
    },
    styleOverrides: {
      root: {
        fontWeight: '600',
      },
    },
  };
}
