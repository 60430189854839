import type { Components } from '@mui/material';
import { chipClasses, listItemClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';
import { pickersLayoutClasses } from '@mui/x-date-pickers-pro';

export function createMuiList({
  typography,
  palette,
}: ThemeConfig): Components['MuiList'] {
  return {
    styleOverrides: {
      root: {
        [`&.${pickersLayoutClasses.shortcuts}`]: {
          padding: '12px 16px 0px 16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          maxHeight: 'none',
          borderRight: `2px solid ${palette.divider}`,

          [`& .${listItemClasses.root}`]: {
            padding: '0',
          },
        },
        [`&.${pickersLayoutClasses.shortcuts} .${chipClasses.root}`]: {
          borderRadius: '6px',
          minWidth: '160px',
          justifyContent: 'flex-start',
          padding: '10px 16px',
          height: '40px',

          '&:not(:hover)': {
            backgroundColor: 'transparent',
          },

          [`& .${chipClasses.label}`]: {
            padding: '0',
            ...typography['text-sm'],
          },
        },
      },
    },
  };
}
