import type { SxProps, Theme } from '@mui/material';

import type { DialogHeaderSize } from './Header';

export const header: SxProps<Theme> = {
  gap: 4,
};

export const dialogTitle: SxProps<Theme> = {
  fontSize: 18,
  fontWeight: 600,

  p: 0,
};

export const closeButton: SxProps<Theme> = {
  ml: 'auto',
};

export const sizes: Record<DialogHeaderSize, SxProps<Theme>> = {
  small: {
    py: 4,
    px: 6,
  },
  medium: {
    p: 6,
  },
};
