import type { Theme } from '@mui/material';

export const loading = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

export const fullPage = {
  background: (theme: Theme) => theme.palette.background.default,
  height: '100vh',
  width: '100vw',
};

export const centered = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};
