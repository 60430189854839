import { KBarAnimator, KBarPositioner, VisualState, useKBar } from 'kbar';
import { Box, Dialog, Slide } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';

import RenderResults from './RenderResults';
import CommandBarInput from './CommandBarInput';
import { animator } from './CommandBarConsumer.sx';

export default function CommandBarConsumer() {
  const { showing, query } = useKBar((state) => ({
    showing: state.visualState !== VisualState.hidden,
  }));

  return (
    <Dialog
      onClose={() => {
        query.setVisualState(VisualState.hidden);
      }}
      sx={{ zIndex: 1000 }}
      open={showing}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'down' } as TransitionProps}
    >
      <KBarPositioner>
        <Box sx={animator}>
          <KBarAnimator>
            <CommandBarInput />
            <RenderResults />
          </KBarAnimator>
        </Box>
      </KBarPositioner>
    </Dialog>
  );
}
