import { Stack } from '@mui/material';
import { AlertCircleIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { FocusIcon } from 'src/mui';

export default function NotificationsEmptyState() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      gap={4}
      sx={{ mx: 5, mt: 5, mb: 16 }}
    >
      <FocusIcon size="xl">
        <AlertCircleIcon />
      </FocusIcon>
      <Stack direction="column" alignItems="center" gap={1}>
        <Typography fontWeight="bold">
          Você não tem notificações no momento
        </Typography>
        <Typography align="center">
          Não se preocupe, assim que estiver algo de
          <br />
          importante, você será notificado.
        </Typography>
      </Stack>
    </Stack>
  );
}
