import type { Components } from '@mui/material';
import { createMuiAlert } from 'src/design-system/components/Alert/createMuiAlert';
import { createMuiChip } from 'src/design-system/components/Chip/createMuiChip';
import type { ThemeConfig } from 'src/theme';

import { createMuiAppBar } from './MuiAppBar';
import { createMuiAutocomplete } from './MuiAutocomplete';
import { createMuiBadge } from './MuiBadge';
import { createMuiButton } from './MuiButton';
import { createMuiButtonGroup } from './MuiButtonGroup';
import { createMuiCheckbox } from './MuiCheckbox';
import { createMuiDataGrid } from './MuiDataGrid';
import { createMuiDialog } from './MuiDialog';
import { createMuiDivider } from './MuiDivider';
import { createMuiFormControl } from './MuiFormControl';
import { createMuiFormControlLabel } from './MuiFormControlLabel';
import { createMuiFormHelperText } from './MuiFormHelperText';
import { createMuiFormLabel } from './MuiFormLabel';
import { createMuiInput } from './MuiInput';
import { createMuiInputAdornment } from './MuiInputAdornment';
import { createMuiInputBase } from './MuiInputBase';
import { createMuiInputLabel } from './MuiInputLabel';
import { createMuiLink } from './MuiLink';
import { createMuiList } from './MuiList';
import { createMuiMenu } from './MuiMenu';
import { createMuiMenuItem } from './MuiMenuItem';
import { createMuiOutlinedInput } from './MuiOutlinedInput';
import { createMuiPaper } from './MuiPaper';
import { createMuiStepButton } from './MuiStepButton';
import { createMuiSvgIcon } from './MuiSvgIcon';
import { createMuiSwitch } from './MuiSwitch';
import { createMuiTab } from './MuiTab';
import { createMuiTextField } from './MuiTextField';
import { createMuiTooltip } from './MuiTooltip';
import { createMuiTypography } from './MuiTypography';

export function createComponents(config: ThemeConfig): Components {
  return {
    MuiAlert: createMuiAlert(config),
    MuiAppBar: createMuiAppBar(),
    MuiAutocomplete: createMuiAutocomplete(config),
    MuiBadge: createMuiBadge(config),
    MuiButton: createMuiButton(config),
    MuiButtonGroup: createMuiButtonGroup(),
    MuiCheckbox: createMuiCheckbox(config),
    MuiChip: createMuiChip(config),
    MuiDataGrid: createMuiDataGrid(config),
    MuiDivider: createMuiDivider(config),
    MuiFormControl: createMuiFormControl(),
    MuiFormHelperText: createMuiFormHelperText(),
    MuiFormControlLabel: createMuiFormControlLabel(),
    MuiFormLabel: createMuiFormLabel(config),
    MuiInput: createMuiInput(config),
    MuiInputAdornment: createMuiInputAdornment(),
    MuiInputBase: createMuiInputBase(config),
    MuiInputLabel: createMuiInputLabel(config),
    MuiLink: createMuiLink(),
    MuiList: createMuiList(config),
    MuiMenu: createMuiMenu(config),
    MuiMenuItem: createMuiMenuItem(config),
    MuiOutlinedInput: createMuiOutlinedInput(config),
    MuiPaper: createMuiPaper(config),
    MuiStepButton: createMuiStepButton(),
    MuiSwitch: createMuiSwitch(config),
    MuiTextField: createMuiTextField(config),
    MuiTooltip: createMuiTooltip(config),
    MuiTab: createMuiTab(config),
    MuiDialog: createMuiDialog(config),
    MuiSvgIcon: createMuiSvgIcon(config),
    MuiTypography: createMuiTypography(),
  };
}
