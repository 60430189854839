import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { Typography } from 'src/design-system/components';

import { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';

import { snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { useCreateReproved } from 'src/libs/finbits/PaymentsFlow';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { ReprovedBillParams } from 'src/libs/finbits/PaymentsFlow/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import ReviewModal from 'src/features/bills-to-pay/ReviewModal';
import ReproveBillModal from 'src/features/approval/ReproveBillModal';

type Props = {
  selecteds: BillPayable[];
  onSuccess?: () => void;
};

const SNACKBAR_KEY = 'undo';

export default function useReprovedAction({ selecteds, onSuccess }: Props) {
  const openPortal = useOpenPortal();

  const { billsAvailable, billsNotAvailable } = selecteds.reduce<{
    billsAvailable: BillPayable[];
    billsNotAvailable: BillPayable[];
  }>(
    (acc, bill) => {
      if (canPerformAction(bill, AvailableActions.SEND_TO_REPROVED)) {
        acc.billsAvailable.push(bill);
      } else {
        acc.billsNotAvailable.push(bill);
      }

      return acc;
    },
    { billsAvailable: [], billsNotAvailable: [] }
  );

  const navigate = useNavigate();
  const { companyId, organizationId } = useCompanyParams();
  const { createReproved, isLoading: isReproving } = useCreateReproved();

  const isAllowedBillsToPayNavigation = useAllowedPermission({
    resource: 'bills',
    action: 'index',
  });

  function handleReprovedSuccess(
    selectedIds: string[],
    deleteEnabled: boolean = false
  ) {
    snackbar({
      variant: 'success',
      key: SNACKBAR_KEY,
      message: deleteEnabled
        ? pluralize(
            selectedIds.length,
            'Lançamento reprovado e excluído',
            'Lançamentos reprovados e excluídos'
          )
        : pluralize(
            selectedIds.length,
            'Lançamento reprovado',
            'Lançamentos reprovados'
          ),
      customActions: isAllowedBillsToPayNavigation && (
        <Button
          aria-label="Ver reprovados"
          size="medium"
          color="secondary"
          onClick={() =>
            navigate({
              pathname: generateCompanyPath(RouteKey.BILLS_TO_PAY, {
                companyId,
                organizationId,
              }),
              search: `status=${BillStatus.REPROVED}`,
            })
          }
        >
          Ver reprovados
        </Button>
      ),
    });

    onSuccess && onSuccess();
  }

  function handleReproveBill(formValues: ReprovedBillParams) {
    const deleteEnabled = formValues.shouldDelete;
    const selectedIds = billsAvailable.map((bill) => bill.id);

    if (selectedIds.length === 0) {
      handleReprovedSuccess(selectedIds, deleteEnabled);

      return;
    }

    createReproved(
      {
        organizationId,
        companyId,
        billsIds: selectedIds,
        ...formValues,
      },
      {
        onSuccess: () => {
          handleReprovedSuccess(selectedIds, deleteEnabled);
        },
        onError: (e) => {
          snackbar({ variant: 'error', message: e.response?.data.message });
        },
      }
    );
  }

  function openReviewModal() {
    openPortal(ReviewModal, {
      title: 'Reprovar lançamentos',
      modalDescription: (
        <Typography>
          Retiramos da sua seleção itens que não podem ser reprovados por você.
          <br />
          Confira abaixo os lançamentos que você pode reprovar em lote e
          confirme a sua reprovação:
        </Typography>
      ),
      tabs: {
        availables: {
          title: 'Podem ser reprovados por você',
          bills: billsAvailable,
        },
        notAvailables: {
          title: 'Permanecem em aprovação',
          description:
            'Você não consta como aprovador dos itens abaixo e por isso eles não podem ser reprovados.',
          bills: billsNotAvailable,
        },
      },
      onConfirm: {
        action: openConfirmationReproveModal,
        confirmText: 'Reprovar selecionados',
      },
      modalClose: {
        text: 'Confirmar cancelamento da reprovação?',
        subtext: 'Ao cancelar, os itens selecionados não serão reprovados.',
      },
    });
  }

  function openConfirmationReproveModal(newSelectedIds?: string[]) {
    const selectedBills = Boolean(newSelectedIds?.length)
      ? billsAvailable.filter((bill) => newSelectedIds?.includes(bill.id))
      : selecteds;

    openPortal(ReproveBillModal, {
      selecteds: selectedBills,
      onSuccess: onSuccess,
    });
  }

  return {
    sendToReproved: billsNotAvailable.length
      ? openReviewModal
      : openConfirmationReproveModal,
    isReproving,
    handleReproveBill,
  };
}
