import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { CheckIcon, CopyIcon } from 'src/mui/_icons';
import { Box, Button, Tooltip } from '@mui/material';
import type { BoxProps, ButtonProps, TooltipProps } from '@mui/material';

type Props = {
  component?: BoxProps['component'];
  clipboard: string;
  title: string;
  children?: ReactNode;
  placement?: TooltipProps['placement'];
  variant?: ButtonProps['variant'];
} & ButtonProps;

export default function CopyButton({
  component = Button,
  clipboard,
  title,
  children = <CopyIcon />,
  placement = 'top',
  sx,
  variant,
  ...rest
}: Props) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) return;

    const timeout = setTimeout(() => setIsCopied(false), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [setIsCopied, isCopied]);

  function handleCopyClick() {
    navigator.clipboard.writeText(clipboard);

    setIsCopied(true);
  }

  const props =
    component === Button
      ? {
          startIcon: isCopied ? <CheckIcon /> : <CopyIcon />,
          variant,
          ...rest,
        }
      : rest;

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      open={!!isCopied}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={title}
      placement={placement}
      arrow
    >
      <Box
        component={component}
        aria-label="copiar"
        size="small"
        onClick={handleCopyClick}
        sx={sx}
        {...props}
      >
        {children}
      </Box>
    </Tooltip>
  );
}
