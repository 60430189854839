import t from 'zod';

export type Label = {
  id: string;
  name: string;
  classificationId: string;
  children?: Label[];
  active: boolean;
  parentLabelId: string | null;
  parentLabel?: Label | null;
};

export const LabelDecoder: t.Schema<Label> = t.lazy(() =>
  t.object({
    id: t.string(),
    name: t.string(),
    classificationId: t.string(),
    children: LabelDecoder.array().optional(),
    active: t.boolean(),
    parentLabelId: t.string().nullable(),
    parentLabel: LabelDecoder.nullish(),
  })
);

export type PostParams = {
  organizationId?: string;
  companyId?: string;
  classificationId: string;
  name: string;
  parentLabelId?: string | null;
};

export type PatchParams = PostParams & {
  labelId: string;
  active?: boolean;
};

export type ActiveParams = {
  organizationId?: string;
  companyId?: string;
  classificationId: string;
  labelId: string;
};
