import { Button, Col, Row, Space } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  ProfileOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { BankIcon } from 'src/mui/_scss';

import { Tag, Title } from 'src/ui';

import type { OpenBankingConnection } from 'src/libs/finbits/Bank/OpenBanking/types';

import styles from './ConnectionCard.module.less';

type Props = {
  connection: OpenBankingConnection;
};

function ConnectionCard({ connection }: Props) {
  return (
    <>
      <Row>
        <Col sm={2} lg={1}>
          <BankIcon routingNumber={connection.routingNumber} />
        </Col>
        <Col>
          <Title className={styles.title} level={4}>
            {connection.bankName}
          </Title>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col sm={2} lg={1} />
        <Col sm={22} lg={23}>
          <Row justify="space-between" align="middle">
            <Col>
              <Tag icon={<LoadingOutlined />} color="warning">
                Importando dados do banco
              </Tag>
            </Col>
            <Col>
              <Space size={16}>
                <Button
                  aria-label="Importar OFX Loading"
                  size="large"
                  icon={<UploadOutlined />}
                  disabled
                />
                <Button
                  aria-label="Ver extrato Loading"
                  size="large"
                  icon={<ProfileOutlined />}
                  disabled
                />
                <Button
                  aria-label="Editar conta Loading"
                  size="large"
                  icon={<EditOutlined />}
                  disabled
                />
                <Button
                  aria-label="Deletar conta Loading"
                  size="large"
                  icon={<DeleteOutlined />}
                  disabled
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ConnectionCard;
