import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { Button, Col } from 'antd';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';

import styles from './CopyFieldButton.module.less';

type Props = {
  children: ReactNode;
  valueToCopy: string;
};

export default function CopyFieldButton({ children, valueToCopy = '' }: Props) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) return;

    const timeout = setTimeout(() => setIsCopied(false), 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [setIsCopied, isCopied]);

  function copyValue() {
    setIsCopied(true);
    navigator.clipboard.writeText(valueToCopy);
  }

  return (
    <Col span={24} className={styles.container}>
      <Button
        className={styles.copyButton}
        type="text"
        icon={
          isCopied ? (
            <CheckOutlined className={styles.successCheck} />
          ) : (
            <CopyOutlined />
          )
        }
        size="large"
        onClick={copyValue}
      />
      {children}
    </Col>
  );
}
