import { forwardRef } from 'react';

import type { InputBaseComponentProps } from '@mui/material';
import InputBaseMasked from 'src/mui/InputBase/InputBaseMasked';

type Props = Omit<InputBaseComponentProps, 'onChange'> & {
  onChange?: (value: number, e?: InputEvent) => void;
  signed?: boolean;
};

const InputBaseAmount = forwardRef<HTMLInputElement, Props>(
  ({ onChange, value, signed = false, ...props }, ref) => {
    return (
      <InputBaseMasked
        inputRef={ref}
        padFractionalZeros
        onAccept={onChange}
        mask={Number}
        thousandsSeparator="."
        radix=","
        mapToRadix={['']}
        signed={signed}
        scale={2}
        value={value}
        componentsProps={{
          inputBase: {
            ...props,
            inputProps: {
              ...props.inputProps,
              maxLength: 20,
            },
          },
        }}
      />
    );
  }
);

export default InputBaseAmount;
