import type {
  GridCellEditStartParams,
  GridRowModel,
  GridValidRowModel,
  MuiEvent,
} from '@mui/x-data-grid-premium';
import { getSelectedRows } from 'src/mui/_scss/Table/utils';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

import { useOpenPortal } from 'src/ui';

import type { FinancialStatementEntry as StatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { EntrySituation } from 'src/libs/finbits/Management/Entries/types';

import Alerts from './alerts/Alerts';
import { EditAlert } from './alerts/EditAlert';
import { NonEditableField } from './alerts/NonEditableField';
import isCellEditable from './isCellEditable';

export type RowProps = {
  id: StatementEntry['id'];
  amountType: StatementEntry['amountType'];
  currentSituation?: StatementEntry['currentSituation'] | null;
  editableFields?: StatementEntry['editableFields'] | null;
};

function filterNonEditableRows(
  selectedRows: GridRowModel<StatementEntry>[],
  field: string
) {
  return selectedRows.reduce((acc: EntrySituation[], row) => {
    if (acc.includes(row.currentSituation)) {
      return acc;
    }

    if (isCellEditable({ row, field })) {
      return acc;
    }

    return [...acc, row.currentSituation];
  }, []);
}

export default function useEditCellValidation<
  R extends GridValidRowModel & RowProps
>() {
  const openPortal = useOpenPortal();

  function canEdit(
    params: GridCellEditStartParams<R>,
    event: MuiEvent,
    api: GridApiPremium
  ) {
    if (!isCellEditable(params)) {
      const ref = api.getCellElement(params.row.id, params.field);

      if (ref) {
        openPortal(Alerts, {
          params,
          cellRef: ref,
          apiRef: api,
          text: (
            <NonEditableField
              headerName={params.colDef.headerName}
              currentSituation={params.row.currentSituation!}
            />
          ),
          severity: 'warning',
        });
      }

      return (event.defaultMuiPrevented = true);
    }

    const { hasSelected, activeRows } = getSelectedRows<R>(api, params.row);

    if (!hasSelected || !activeRows) {
      return;
    }

    const invalidStatus = filterNonEditableRows(
      activeRows as any,
      params.field
    );
    const hasInvalidStatus = invalidStatus.length > 0;
    const hasDiffType =
      params.field === 'category' &&
      !activeRows.every((r) => r.amountType === params.row.amountType);

    if (hasDiffType || hasInvalidStatus) {
      const ref = api.getCellElement(params.row.id, params.field);

      if (ref) {
        openPortal(Alerts, {
          params,
          cellRef: ref,
          apiRef: api,
          text: (
            <EditAlert
              colDef={params.colDef}
              amountType={params.row.amountType}
              situations={invalidStatus}
              message={hasInvalidStatus ? 'situation' : 'category'}
            />
          ),
          severity: 'warning',
        });
      }
    }
  }

  return canEdit;
}
