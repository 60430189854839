import { useState } from 'react';

import { Box, ButtonGroup } from '@mui/material';
import { ThumbsDownIcon, ThumbsUpIcon } from 'src/mui/_icons';

import { IconButton, Tooltip } from 'src/mui';

import { useUpdateInsight } from 'src/libs/finbits/Ai';

import { FeedbackType } from './enum';

type Props = {
  organizationId: string;
  companyId: string;
  insightId: string;
};

const SHOW_THUMBS_UP = [FeedbackType.GOOD, FeedbackType.PENDING];
const SHOW_THUMBS_DOWN = [FeedbackType.BAD, FeedbackType.PENDING];

export default function Feedback({
  organizationId,
  companyId,
  insightId,
}: Props) {
  const { updateInsight } = useUpdateInsight();
  const [feedback, setFeedback] = useState(FeedbackType.PENDING);

  function handleClick(value: FeedbackType) {
    setFeedback(value);

    updateInsight({
      organizationId,
      companyId,
      insightId,
      status: value,
    });
  }
  return (
    <Box pl={12}>
      <ButtonGroup>
        {SHOW_THUMBS_UP.includes(feedback) && (
          <Tooltip title="Gostei">
            <IconButton
              disabled={feedback !== FeedbackType.PENDING}
              onClick={() => handleClick(FeedbackType.GOOD)}
              aria-label="like"
            >
              <ThumbsUpIcon />
            </IconButton>
          </Tooltip>
        )}
        {SHOW_THUMBS_DOWN.includes(feedback) && (
          <Tooltip title="Não gostei">
            <IconButton
              disabled={feedback !== FeedbackType.PENDING}
              onClick={() => handleClick(FeedbackType.BAD)}
              aria-label="unlike"
            >
              <ThumbsDownIcon />
            </IconButton>
          </Tooltip>
        )}
      </ButtonGroup>
    </Box>
  );
}
