import { Tooltip, Typography } from 'antd';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';

import { isOverDue } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { format } from 'src/libs/finbits/Date';

import UnfilledContext from 'src/features/entries/Columns/UnfilledContext';

import styles from './Date.module.less';

type Props = {
  date: string;
  showCalendarIcon?: boolean;
  showGroupIcon?: boolean;
  showOverDueIcon?: boolean;
  unfilledContextFields?: string[] | null;
};

export default function Date({
  date,
  showCalendarIcon = false,
  showGroupIcon = false,
  showOverDueIcon = false,
  unfilledContextFields,
}: Props) {
  return (
    <>
      <Typography.Text className={styles.date}>
        {format(date, 'dd/MM/yy')}
      </Typography.Text>

      {showOverDueIcon && isOverDue(date) && (
        <Tooltip placement="top" title="Atrasado">
          <ClockCircleOutlined className={styles.danger} />
        </Tooltip>
      )}
      {showCalendarIcon && !isOverDue(date) && (
        <Tooltip placement="top" title="Programado">
          <CalendarOutlined className={styles.secondary} />
        </Tooltip>
      )}
      {unfilledContextFields && (
        <UnfilledContext unfilledContextFields={unfilledContextFields} />
      )}
      {showGroupIcon && (
        <Tooltip placement="top" title="Quebra de lançamentos">
          <SplitCellsOutlined className={styles.secondary} />
        </Tooltip>
      )}
    </>
  );
}
