import { useConciliationSuggestions } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import type { Props } from 'src/features/conciliations/ConciliationDrawer';
import ConciliationDrawer from 'src/features/conciliations/ConciliationDrawer';

export default function ConciliationWithSuggestionDrawer(props: Props) {
  const { organizationId, companyId } = useCompanyParams();

  const { suggestions, isLoading: isLoadingSuggestions } =
    useConciliationSuggestions({
      organizationId,
      companyId,
    });

  return (
    <ConciliationDrawer
      {...props}
      suggestions={suggestions}
      isLoadingSuggestions={isLoadingSuggestions}
    />
  );
}
