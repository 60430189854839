import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { Stepper } from 'src/mui/_scss';

import { snackbar } from 'src/mui';

import { useAddAccount } from 'src/libs/finbits/Bank/Accounts';
import type {
  Account,
  AccountFormParams,
} from 'src/libs/finbits/Bank/Accounts/types';
import {
  AccountType,
  SynchronizeType,
} from 'src/libs/finbits/Bank/Accounts/types';
import { useSupportedBankProviderCodes } from 'src/libs/finbits/Bank/SupportedBank';
import { format } from 'src/libs/finbits/Date';
import {
  convertToSignedNumber,
  getAmountType,
  toIntRepresentation,
} from 'src/libs/finbits/Money';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { useOpenPluggyWidget } from 'src/libs/pluggy/PluggyWidget';

import {
  AccountResolver,
  ManualCheckingAccountSteppeResolver,
} from 'src/features/accounts/AccountModal/AccountForm/validations';

import {
  AccountTypeSteppe,
  ManualCheckingAccountSteppe,
  SynchronizedTypeSteppe,
} from './FormSteppes';

type Props = {
  onClose: () => void;
  onDirty?: (isDirty: boolean) => void;
};

export default function AccountForm({ onClose, onDirty }: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const [currentStep, setCurrentStep] = useState(0);
  const openPluggyWidget = useOpenPluggyWidget();
  const { addAccount } = useAddAccount();

  const resolver =
    currentStep === 1 ? ManualCheckingAccountSteppeResolver : AccountResolver;

  const form = useForm<AccountFormParams>({
    resolver: zodResolver(resolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      branchNumber: null,
      accountNumber: null,
      routingNumber: null,
      initialBalance: {
        balance: null,
        date: null,
        balanceType: undefined,
      },
      type: AccountType.CHECKING_ACCOUNT,
      synchronizeType: SynchronizeType.AUTOMATIC,
    },
  });

  const {
    formState: { isDirty },
  } = form;

  const { providerCodes } = useSupportedBankProviderCodes(
    form.getValues('routingNumber')
  );

  const typeAccount = form.watch('type');

  useEffect(() => {
    onDirty?.(isDirty);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  function parserDataToAddAccount(value: AccountFormParams) {
    const initialBalance = value?.initialBalance;
    const balanceAmount = convertToSignedNumber(
      initialBalance?.balance ?? 0,
      initialBalance?.balanceType ?? BalanceType.CREDIT
    );
    const { amount, type } = getAmountType(
      toIntRepresentation(String(balanceAmount)) ?? 0
    );
    const formattedDate = initialBalance?.date
      ? format(initialBalance.date, 'yyyy-MM-dd')
      : null;

    return {
      ...value,
      initialBalance: {
        balanceType: type,
        balance: amount,
        date: formattedDate,
      },
    };
  }

  function handleAddAccount() {
    const values = form.getValues();

    const params = parserDataToAddAccount(values);

    addAccount(
      {
        companyId,
        organizationId,
        ...params,
      },
      {
        onSuccess: (newAccount: Account) => {
          onClose();
          snackbar({
            variant: 'success',
            message: 'A nova conta foi adicionada com sucesso!',
          });
        },
        onError: ({ response }) => {
          onClose();
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao criar a conta!',
          });
        },
      }
    );
  }

  function getSelectedConnectorIds(): number[] {
    return providerCodes.map((providerCode) => parseInt(providerCode));
  }

  async function handleNextStep(currentStep: number) {
    const { type, synchronizeType } = form.getValues();
    setCurrentStep(currentStep);
    form.clearErrors();

    const isValid = await form.trigger();

    if (type === AccountType.CASH_ACCOUNT && currentStep === 0) {
      if (isValid) handleAddAccount();

      return false;
    }

    if (synchronizeType === SynchronizeType.AUTOMATIC && currentStep === 1) {
      openPluggyWidget({
        companyId,
        organizationId,
        connectorIds: getSelectedConnectorIds(),
        errorModalHeader: 'Adicionar conta',
        onCancel: onClose,
        onSuccess: onClose,
      });

      return false;
    }

    if (currentStep === 2) {
      if (isValid) handleAddAccount();
    }

    return isValid;
  }

  function handlePreviousStep(currentStep: number) {
    if (currentStep === 0) {
      setCurrentStep(currentStep);
      return;
    }
    setCurrentStep(currentStep - 1);
    form.clearErrors();
  }

  return (
    <FormProvider {...form}>
      <Stepper
        onNextStep={handleNextStep}
        showButtonLastStep={true}
        onPreviousStep={handlePreviousStep}
      >
        <AccountTypeSteppe />
        {typeAccount !== AccountType.CASH_ACCOUNT && <SynchronizedTypeSteppe />}
        {typeAccount !== AccountType.CASH_ACCOUNT && (
          <ManualCheckingAccountSteppe />
        )}
      </Stepper>
    </FormProvider>
  );
}
