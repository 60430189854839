import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI } from 'src/libs/finbits/client';
import { invalidateEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { invalidateBillsQueries } from 'src/libs/finbits/Bills/Bills';

import type { DeleteParams } from './types';

async function postDeleteMany({
  organizationId,
  companyId,
  ...params
}: DeleteParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/entries/delete_many`,
    params
  );

  return response;
}

export function useDeleteManyEntries() {
  const queryClient = useQueryClient();

  const { mutate: deleteMany, ...rest } = useMutation<
    unknown,
    ApiError,
    DeleteParams
  >(postDeleteMany, {
    onSuccess: () => {
      invalidateEntriesQueries(queryClient);
      invalidateBillsQueries(queryClient);
    },
  });

  return { deleteMany, ...rest };
}
