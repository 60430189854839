import type { FormInstance } from 'antd';
import {
  CheckCircleOutlined,
  StopOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { CountryCode } from 'libphonenumber-js';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import {
  Drawer,
  DrawerHeader,
  DrawerHeaderButton,
  Loader,
  Title,
} from 'src/ui';

import { errorsToFormField } from 'src/libs/finbits/Form';
import {
  e164Format,
  phoneFormat,
} from 'src/libs/finbits/Organization/PhoneNumber';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  useCompanyContact,
  useUpdateContact,
} from 'src/libs/finbits/Organization/Companies/Contacts';
import type {
  BankDetails,
  Contact,
} from 'src/libs/finbits/Organization/Companies/Contacts/types';
import type { Address } from 'src/libs/finbits/BrazilianData/Addresses/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import { useActivationActions } from 'src/features/contacts/useActivationActions';
import type {
  FormParams,
  RequiredFields,
} from 'src/features/contacts/ContactForm';
import ContactForm from 'src/features/contacts/ContactForm';

type Props = {
  contactId: string;
  requiredFields?: Array<keyof RequiredFields>;
} & PortalProps;

function contactDrawerTitle(contact?: Contact): string {
  switch (contact?.type) {
    case 'legal':
      return 'Contato - Pessoa Jurídica';
    case 'natural':
      return 'Contato - Pessoa Física';
    default:
      return 'Editar Contato';
  }
}

function bankDetailsParams(bankDetails: Partial<BankDetails>) {
  return {
    routingNumber: bankDetails.routingNumber ?? null,
    branchNumber: bankDetails.branchNumber ?? null,
    accountNumber: bankDetails.accountNumber ?? null,
    accountType: bankDetails.accountType ?? null,
    pixType: bankDetails.pixType ?? null,
    pixKey: bankDetails.pixKey ?? null,
  };
}

function addressParams(address: Partial<Address>) {
  return {
    street: address.street ?? null,
    city: address.city ?? null,
    postalCode: address.postalCode ?? null,
    state: address.state ?? null,
    district: address.district ?? null,
    number: address.number ?? null,
    details: address.details ?? null,
    countryCode: address.countryCode ?? null,
  };
}

export default function EditContactDrawer({
  contactId,
  requiredFields,
  onClose,
  open = true,
  onExit,
}: Props) {
  const { updateContact, isLoading } = useUpdateContact();

  const { companyId, organizationId } = useCompanyParams();

  const { contact, isLoading: isLoadingContact } = useCompanyContact({
    contactId,
    companyId,
    organizationId,
  });

  const { openActivationDialog, openDeactivationDialog } = useActivationActions(
    {
      organizationId,
      companyId,
      contactId,
      onChangeActive: onClose,
    }
  );

  const canEditContact = useAllowedPermission({
    action: 'update',
    resource: 'contacts',
  });

  const initialValues = {
    ...contact,
    phone: contact?.phone
      ? phoneFormat(contact.phone, contact.address?.countryCode as CountryCode)
      : null,
  };

  function handleOnSubmit(params: FormParams, form: FormInstance) {
    const patchParams = {
      organizationId,
      companyId,
      id: contactId,
      name: params.name,
      nickname: params.nickname,
      document: params.document,
      phone: params.phone ? e164Format(params.phone) : null,
      email: params.email ? params.email : null,
      address: params.address ? addressParams(params.address) : null,
      comments: params.comments ? params.comments : null,
      bankDetails: params.bankDetails
        ? bankDetailsParams(params.bankDetails)
        : null,
    };

    updateContact(patchParams, {
      onSuccess: () => {
        onClose();
        snackbar({
          variant: 'success',
          message: 'Contato atualizado com sucesso.',
        });
      },
      onError: (error) => {
        if (error.response?.status === 422) {
          return form.setFields(
            errorsToFormField(error.response?.data.errors as any)
          );
        }

        snackbar({
          variant: 'error',
          message: 'Não foi possível salvar o contato.',
        });
      },
    });
  }

  return (
    <Drawer
      title={
        <DrawerHeader
          title={
            <Title icon={<UserOutlined />}>{contactDrawerTitle(contact)}</Title>
          }
          extra={
            contact &&
            canEditContact && (
              <DrawerHeaderButton
                icon={
                  contact.active ? <StopOutlined /> : <CheckCircleOutlined />
                }
                title={contact.active ? 'Desativar Contato' : 'Ativar Contato'}
                onClick={
                  contact.active ? openDeactivationDialog : openActivationDialog
                }
              />
            )
          }
        />
      }
      footer={null}
      onClose={onClose}
      visible={open}
      afterVisibleChange={onExit}
    >
      {isLoadingContact ? (
        <Loader size="small" />
      ) : (
        <ContactForm
          requiredFields={requiredFields}
          onFinish={handleOnSubmit}
          contact={initialValues}
          onGoBack={false}
          isLoading={isLoading}
          disabled={!canEditContact}
          disabledDocument
        />
      )}
    </Drawer>
  );
}
