import { useEffect } from 'react';

import type { TransitionProps } from '@mui/material/transitions';
import { Button, Chip, DialogContent, Slide, Stack } from '@mui/material';
import { ArrowNarrowDownIcon } from 'src/mui/_icons';
import { LoadingButton } from '@mui/lab';
import { FormProvider } from 'react-hook-form';

import { Dialog, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { useUpdateBill } from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useInboxItemMerge } from 'src/libs/finbits/Management/NewInboxItems';

import TabPanelsProvider from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider';
import { useOpenConfirmLeaveFormDialog } from 'src/features/entries/Dialogs';
import BillContent from 'src/features/bills-to-pay/BillContent';
import useBillForm from 'src/features/bills-to-pay/useBillForm';
import EntrySuggestionsProvider from 'src/features/entries/Suggestions/EntrySuggestionsProvider';

import { BillTab } from '../BillContent/TabPanels/TabPanelsProvider/types';
import EditableFieldsProvider from '../BillContent/EditableFieldsProvider/EditableFieldsProvider';

import styles from './EditBillModal.module.scss';

type Props = {
  inboxItemId: string;
  onSuccess: () => void;
  isLinkedEntry?: boolean;
  billId?: string;
} & PortalProps;

export default function EditBillModal({
  inboxItemId,
  onClose,
  open = true,
  onExit,
  onSuccess,
  isLinkedEntry = false,
  billId,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { bill, isLoading } = useInboxItemMerge({
    companyId,
    organizationId,
    inboxItemId,
    billId,
  });

  const openLeaveDialog = useOpenConfirmLeaveFormDialog();
  const { updateBill, isLoading: isUpdateLoading } = useUpdateBill();

  const { ...form } = useBillForm({
    bill,
    inboxItemId,
    isBillLoading: isLoading,
  });

  const {
    formState: { isDirty },
    watch,
    resetField,
  } = form;

  const disabledTabs = { [BillTab.RECURRENCE]: isLinkedEntry };
  const date = watch('date');
  useEffect(() => {
    const FIELDS_TO_RESET = [
      'endDate',
      'quantity',
      'frequencyInterval',
    ] as const;
    FIELDS_TO_RESET.forEach((fieldName: (typeof FIELDS_TO_RESET)[number]) => {
      resetField(fieldName);
    });
  }, [date, resetField]);

  function handleClose() {
    if (isDirty) {
      return openLeaveDialog({
        onConfirm: onClose,
      });
    }

    onClose();
  }

  function handleUpdateBill({
    attachments,
    classifications,
    ...params
  }: BillFormParams) {
    if (!billId) return;

    const routeParams = {
      companyId,
      organizationId,
    };

    const payload = {
      ...params,
      attachmentsIds: attachments?.map((attachment) => attachment.id),
      classifications: classifications ? Object.values(classifications) : [],
      billId,
      inboxItemId,
    };

    updateBill(
      {
        ...routeParams,
        ...payload,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message:
              'O item selecionado foi vinculado ao lançamento existente.',
          });

          onClose();
          onSuccess();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Não foi possível vincular o item selecionado ao lançamento.',
          });
        },
      }
    );
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={handleClose}
      fullWidth
      maxWidth="uxl"
      disableEnforceFocus
    >
      <Dialog.Header
        size="small"
        title={
          <Stack direction="row" gap={2} alignItems="center">
            Pagamento Programado
            <Chip
              label="Saída"
              color="error"
              size="small"
              icon={<ArrowNarrowDownIcon strokeWidth="2.5" />}
            />
          </Stack>
        }
        onClose={handleClose}
      />

      <DialogContent className={styles.dialogContent} dividers>
        <FormProvider {...form}>
          <EditableFieldsProvider
            isEditMode={isLinkedEntry}
            editableFields={bill?.editableFields}
          >
            <TabPanelsProvider disabledTabs={disabledTabs}>
              <EntrySuggestionsProvider
                attachments={watch('attachments')}
                inboxItemId={inboxItemId}
                contactId={watch('contactId')}
                contact={bill?.contact}
              >
                <BillContent />
              </EntrySuggestionsProvider>
            </TabPanelsProvider>
          </EditableFieldsProvider>
        </FormProvider>
      </DialogContent>

      <Dialog.Actions className={styles.dialogActions}>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          onClick={form.handleSubmit(handleUpdateBill)}
          variant="contained"
          color="primary"
          loading={isUpdateLoading}
        >
          Salvar
        </LoadingButton>
      </Dialog.Actions>
    </Dialog>
  );
}
