import { validate as uuidValidate } from 'uuid';

import { formEmailValidator } from 'src/libs/finbits/Email';
import {
  formCnpjValidator,
  formCpfValidator,
} from 'src/libs/finbits/Documents';
import { phoneValidator } from 'src/libs/finbits/Organization/PhoneNumber';

export function formUuidValidator(_rule: any, value: string) {
  if (uuidValidate(value)) {
    return Promise.resolve();
  }

  return Promise.reject('Formato inválido');
}

export function validatePixKey(rule: any, value: string, pixType?: string) {
  if (pixType === 'cpf') {
    return formCpfValidator(rule, value);
  }

  if (pixType === 'cnpj') {
    return formCnpjValidator(rule, value);
  }

  if (pixType === 'cellphone_number') {
    return phoneValidator(rule, value);
  }

  if (pixType === 'email') {
    return formEmailValidator(rule, value);
  }

  if (pixType === 'random') {
    return formUuidValidator(rule, value);
  }
  return Promise.resolve();
}
