import { useEffect, useState } from 'react';

import { Navigate } from 'react-router';

import { Loader } from 'src/ui';

import { logOut } from 'src/libs/finbits/Auth';

export default function Logout() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    logOut();
    setLoading(false);
  }, [setLoading]);

  if (isLoading) {
    return <Loader size="large" full />;
  }

  return <Navigate to="/login" />;
}
