import type { Shadows } from '@mui/material/styles/shadows';
import muiShadows from '@mui/material/styles/shadows';

export const shadows = [
  'none',
  '0 1px 2px rgba(16, 24, 40, 0.05)',
  '0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06)',
  '0 4px 8px -2px rgba(16, 24, 40, 0.1), 0 2px 4px -2px rgba(16, 24, 40, 0.06)',
  '0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)',
  '0 20px 24px -4px rgba(16, 24, 40, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03)',
  '0 24px 48px -12px rgba(16, 24, 40, 0.18)',
  '0 32px 64px -12px rgba(16, 24, 40, 0.14)',
  ...muiShadows.slice(8),
] as Shadows;
