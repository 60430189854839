import { useCallback, useMemo, useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import type { RuleObject } from 'antd/lib/form';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Drawer, DrawerHeader, Title } from 'src/ui';

import { useCreateFinancialEntry } from 'src/libs/finbits/Management/FinancialEntries';
import type { ApiErrorForm } from 'src/libs/finbits/client';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import { format, isBefore, isSameDay, parseISO } from 'src/libs/finbits/Date';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import EntryForm from 'src/features/EntryForm';
import type {
  EntryFormHiddenActions,
  EntryFormHiddenFields,
  FormValues,
  SubmitParams,
} from 'src/features/EntryForm/types';
import CreateNotaFiscalDrawer from 'src/features/nota-fiscal/CreateNotaFiscalDrawer';

const HIDDEN_FIELDS: EntryFormHiddenFields = [
  'paymentDetails',
  'isRecurrent',
  'approvalType',
  'assigneesIds',
];

const HIDDEN_ACTIONS: EntryFormHiddenActions = [
  'createNotaFiscalFromReceivable',
];

type Props = {
  title?: string;
  hiddenFields?: EntryFormHiddenFields;
  hiddenActions?: EntryFormHiddenActions;
  initialValues?: Partial<FormValues>;
} & PortalProps;

export default function CreateFinancialEntryDrawer({
  onClose,
  title = 'Lançamento realizado',
  initialValues,
  hiddenFields = HIDDEN_FIELDS,
  hiddenActions = HIDDEN_ACTIONS,
  open = true,
  onExit,
}: Props) {
  const { organizationId, companyId } = useCompanyParams();
  const [errors, setErrors] = useState<ApiErrorForm>();
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [
    isCreateFinancialEntryDrawerVisible,
    setIsCreateFinancialEntryDrawerVisible,
  ] = useState(true);
  const [isCreateNotaFiscalDrawerVisible, setIsCreateNotaFiscalDrawerVisible] =
    useState(false);

  const {
    createFinancialEntry,
    isLoading: isSaving,
    data: financialEntry,
  } = useCreateFinancialEntry();

  async function onSubmit(
    { attachments, ...params }: SubmitParams,
    shouldCreateNotaFiscal?: boolean
  ) {
    if (!organizationId || !companyId) return;

    createFinancialEntry(
      {
        ...params,
        organizationId,
        companyId,
        attachmentsIds: attachments?.map((a) => a.id),
      },
      {
        onSuccess: () => {
          if (shouldCreateNotaFiscal) {
            setIsCreateFinancialEntryDrawerVisible(false);
            setIsCreateNotaFiscalDrawerVisible(true);

            snackbar({
              variant: 'success',
              message:
                'Lançamento realizado foi adicionado com sucesso! Agora você pode preencher as informações da nota fiscal',
            });

            return;
          }

          onClose();
          snackbar({
            variant: 'success',
            message: 'Lançamento salvo com sucesso!',
          });
        },
        onError: ({ response }) => {
          setErrors(response?.data.errors);

          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar o lançamento!',
          });
        },
      }
    );
  }

  const onFieldsChange = useCallback((values?: FormValues) => {
    setIsFormDirty(true);

    values?.accountId && setSelectedAccountId(values?.accountId);
  }, []);

  const { accounts } = useAccounts(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  function isDateEqualOrBeforeInitalBalance(
    date: Date,
    initialBalanceDate?: string | null
  ) {
    if (!initialBalanceDate) return false;

    const parsedInitialBalanceDate = parseISO(initialBalanceDate);

    return (
      isSameDay(date, parsedInitialBalanceDate) ||
      isBefore(date, parsedInitialBalanceDate)
    );
  }

  function dateValidation(_rule: RuleObject, value?: Date) {
    if (!value) {
      return Promise.resolve();
    }

    const currentAccount = accounts.find(
      (account) => account.id === selectedAccountId
    );

    if (currentAccount?.type !== 'cash_account') {
      return Promise.resolve();
    }

    const currentAccountDate = currentAccount?.initialBalance?.date;

    if (isDateEqualOrBeforeInitalBalance(value, currentAccountDate)) {
      return Promise.reject(
        `Utilize uma data posterior à ${format(
          parseISO(currentAccountDate!),
          'dd/MM/yyyy'
        )}`
      );
    }

    return Promise.resolve();
  }

  function accountIdValidation(_rule: RuleObject, value?: string) {
    if (!value) {
      return Promise.resolve();
    }

    const currentAccountSyncronizeType = accounts.find(
      (account) => account.id === value
    )?.synchronizeType;

    if (currentAccountSyncronizeType === 'manual') {
      return Promise.resolve();
    }

    return Promise.reject(
      'Lançamentos de contas automáticas são sincronizados automaticamente, não é possível criar realizados manualmente.'
    );
  }

  const originValues = useMemo(
    () => ({ id: 'new_financial_entry', ...initialValues }),
    [initialValues]
  );

  return (
    <>
      {isCreateFinancialEntryDrawerVisible && (
        <Drawer
          title={
            <DrawerHeader
              title={<Title icon={<CheckOutlined />}>{title}</Title>}
            />
          }
          footer={null}
          onClose={onClose}
          visible={open}
          afterVisibleChange={onExit}
        >
          <EntryForm
            isFormDirty={isFormDirty}
            onFieldsChange={onFieldsChange}
            organizationId={organizationId}
            companyId={companyId}
            initialValues={originValues}
            onSubmit={onSubmit}
            loading={isSaving}
            errors={errors}
            customValidations={{
              date: dateValidation,
              accountId: accountIdValidation,
            }}
            hiddenFields={hiddenFields}
            hiddenActions={hiddenActions}
            isFinancialEntry
          />
        </Drawer>
      )}

      {isCreateNotaFiscalDrawerVisible && (
        <CreateNotaFiscalDrawer
          initialValues={{
            amount: financialEntry?.amount,
            contactId: financialEntry?.contactId!,
            financialEntryId: financialEntry?.id,
          }}
          onClose={onClose}
        />
      )}
    </>
  );
}
