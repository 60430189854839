import { Alert, AlertTitle, Typography } from 'src/design-system/components';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

type Props = {
  bill?: BillPayable;
};

export default function PaymentFailedAlert({ bill }: Props) {
  const customError = bill?.paymentRequestErrorDetail;

  return (
    <Alert severity="warning">
      <AlertTitle>
        A transação falhou. Antes de re-enviar para pagamento, verifique:
      </AlertTitle>

      {Boolean(customError) ? (
        <Typography>• {customError}</Typography>
      ) : (
        <>
          <Typography>• Se os dados de pagamentos estão válidos;</Typography>
          <Typography>• Se há saldo disponível na conta;</Typography>
          <Typography>
            • Se seu banco bloqueou esse pagamento por motivos de segurança.
          </Typography>
        </>
      )}
    </Alert>
  );
}
