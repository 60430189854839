import { useMemo, useState } from 'react';

import { CalendarOutlined, CopyOutlined } from '@ant-design/icons';
import { Space } from 'antd';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { CopyButton, Drawer, DrawerHeader, Loader, Title } from 'src/ui';

import {
  buildSubmitParams,
  useGetBill,
  useUpdateBill,
  useUpdateBillPendingApproval,
} from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { isStructuredFormError } from 'src/libs/finbits/Form';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import { EntrySituation } from 'src/libs/finbits/Management/Entries/types';
import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import {
  CompanyRole,
  getCompanyPermissionFromUser,
  useAllowedPermission,
} from 'src/libs/finbits/Permissions';
import { useProfile } from 'src/libs/finbits/Organization/Users';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import analytics from 'src/libs/analytics';

import type {
  EntryFormHiddenFields,
  SubmitParams,
} from 'src/features/EntryForm/types';
import { billToFormValues } from 'src/features/EntryForm/formValues';
import EntryForm from 'src/features/EntryForm';
import { useOpenConfirmLeaveFormDialog } from 'src/features/entries/Dialogs';
import CurrentSituationTag from 'src/features/entries/CurrentSituationTag';
import UnfilledContextAlert from 'src/features/entries/UnfilledContextAlert';

import ConciliateHeaderButton from './ConciliateHeaderButton';
import DeleteHeaderButton from './DeleteHeaderButton';
import PaymentFailedAlert from './PaymentFailedAlert';
import ReprovedReasonAlert from './ReprovedReasonAlert';
import ApprovalHeaderButtons from './ApprovalHeaderButtons';
import RestoreHeaderButton from './RestoreHeaderButton';

const HIDDEN_FIELDS: EntryFormHiddenFields = [
  'type',
  'frequencyInterval',
  'quantity',
];

type Props = {
  billId: string;
  getParams?: {
    includingDeleted?: boolean;
  };
  onManualReconciliate?: (financialEntry: FinancialEntry) => void;
} & PortalProps;

export default function EditBillDrawer({
  billId,
  onClose,
  onManualReconciliate,
  open = true,
  onExit,
  getParams,
}: Props) {
  const canEditBill = useAllowedPermission([
    {
      action: 'update',
      resource: 'bills',
    },
    {
      action: 'update_pending_approval',
      resource: 'bills',
    },
  ]);

  const { user } = useProfile();

  const { companyId, organizationId } = useCompanyParams();

  const [errors, setErros] = useState<ApiErrorForm>();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { updateBill, isLoading: isUpdating } = useUpdateBill();
  const { updateBillPendingApproval, isLoading: isUpdatingApproval } =
    useUpdateBillPendingApproval();
  const isSaving = isUpdating || isUpdatingApproval;

  const openLeaveDialog = useOpenConfirmLeaveFormDialog();

  const { bill, isLoading, isFetchedAfterMount } = useGetBill({
    billId,
    companyId,
    organizationId,
    ...getParams,
  });

  const isReproved = bill?.status === BillStatus.REPROVED;
  const isFailed = bill?.currentSituation === EntrySituation.FAILED;
  const isDeleted = bill?.currentSituation === EntrySituation.DELETED;

  function handleSubmit(params: SubmitParams) {
    const isApprover =
      getCompanyPermissionFromUser(user, companyId)?.role ===
      CompanyRole.APPROVER;

    const apiFunction = isApprover ? updateBillPendingApproval : updateBill;

    apiFunction(
      {
        ...buildSubmitParams(params),
        billId,
        companyId,
        organizationId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'O pagamento foi salvo com sucesso!',
          });

          onClose();
        },
        onError: ({ response }: ApiError) => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar o pagamento!',
          });

          if (isStructuredFormError(response?.data)) {
            setErros(response?.data.errors as ApiErrorForm);
          }
        },
      }
    );
  }

  function openCloseConfirmDialog() {
    openLeaveDialog({
      onConfirm: onClose,
    });
  }

  function handleClose() {
    if (isFormDirty) {
      return openCloseConfirmDialog();
    }

    return onClose();
  }

  const initialValues = useMemo(() => bill && billToFormValues(bill), [bill]);

  const submitButtonText = useMemo(() => {
    const isAssignee = bill?.assignees?.some(
      (assignee) => assignee.id === user?.id
    );

    const isWaitingApproval = bill?.status === BillStatus.WAITING_APPROVAL;

    return isAssignee && isWaitingApproval
      ? 'Salvar e pedir aprovação'
      : 'Salvar';
  }, [bill, user]);

  function handleCopyIdMetric() {
    analytics.track('Entry Drawer Header Copy External ID Button Clicked', {
      company_id: companyId,
    });
  }

  return (
    <Drawer
      visible={open}
      afterVisibleChange={onExit}
      destroyOnClose
      title={
        <DrawerHeader
          title={
            <Title icon={<CalendarOutlined />}>Pagamento programado</Title>
          }
          extra={
            <>
              <CurrentSituationTag currentSituation={bill?.currentSituation} />
              <CopyButton
                icon={<CopyOutlined />}
                url={bill?.publicId}
                text={'Copiar ID'}
                onClick={handleCopyIdMetric}
              />
              <RestoreHeaderButton bill={bill} onSuccess={onClose} />
              <ApprovalHeaderButtons bill={bill} onSuccess={onClose} />
              <ConciliateHeaderButton
                bill={bill}
                onConciliate={onManualReconciliate ?? onClose}
              />
              <DeleteHeaderButton bill={bill} onSuccess={onClose} />
            </>
          }
        />
      }
      footer={null}
      onClose={handleClose}
    >
      {isLoading || !isFetchedAfterMount || !initialValues ? (
        <Loader size="small" forceCentered />
      ) : (
        <Space direction="vertical" size="large">
          {isReproved && <ReprovedReasonAlert bill={bill} />}
          {isFailed && <PaymentFailedAlert bill={bill} />}
          <UnfilledContextAlert entry={bill} entryType={EntryType.BILL} />
          <EntryForm
            isFormDirty={isFormDirty}
            onFieldsChange={() => setIsFormDirty(true)}
            organizationId={organizationId}
            companyId={companyId}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={isSaving}
            disabled={!canEditBill || isDeleted}
            hiddenFields={HIDDEN_FIELDS}
            errors={errors}
            bill={bill}
            editableFields={bill?.editableFields}
            submitButtonText={submitButtonText}
          />
        </Space>
      )}
    </Drawer>
  );
}
