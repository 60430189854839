import type { SelectProps } from 'antd';
import { Select } from 'antd';

import { selectFilter } from 'src/features/EntryForm/selectFilter';

export default function ApprovalTypeSelect(props: SelectProps) {
  return (
    <Select
      allowClear={false}
      showSearch
      size="large"
      placeholder="Selecione"
      filterOption={selectFilter}
      {...props}
    >
      <Select.Option value="any">Qualquer aprovador</Select.Option>
      <Select.Option value="all">Todos os aprovadores</Select.Option>
    </Select>
  );
}
