import type { ReactNode } from 'react';

import { Typography } from 'antd';
import type { TitleProps } from 'antd/lib/typography/Title';

import styles from './Title.module.less';

type Props = TitleProps & {
  children: ReactNode;
  icon?: ReactNode;
  level?: number;
};

export default function Title({ children, icon, level = 5, ...props }: Props) {
  return (
    <div className={styles.container}>
      {icon}
      <Typography.Title className={styles.title} {...props} level={level}>
        {children}
      </Typography.Title>
    </div>
  );
}
