import t from 'zod';

export const REQUIRED_MESSAGE_CLIENT_ID = 'Por favor, informe o Client ID.';
export const REQUIRED_MESSAGE_CLIENT_SECRET =
  'Por favor, informe o Client Secret.';

export const SantanderAutoPaymentResolver = t.object({
  clientId: t
    .string({
      required_error: REQUIRED_MESSAGE_CLIENT_ID,
    })
    .min(1, { message: REQUIRED_MESSAGE_CLIENT_ID }),

  clientSecret: t
    .string({
      required_error: REQUIRED_MESSAGE_CLIENT_SECRET,
    })
    .min(1, { message: REQUIRED_MESSAGE_CLIENT_SECRET }),
});
