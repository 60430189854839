import t from 'zod';

import {
  email,
  newPassword,
  password,
} from 'src/libs/finbits/resolverValidations';

export const AuthenticationTokensDecoder = t.object({
  access: t.string(),
  refresh: t.string(),
});

export type AuthenticationTokens = t.TypeOf<typeof AuthenticationTokensDecoder>;

export const SignInResolver = t.object({
  login: email,
  password,
});

export type SignInParams = t.TypeOf<typeof SignInResolver>;

export const RedefinePasswordDecoder = t.object({
  message: t.string(),
});

export type RedefinePassword = t.TypeOf<typeof RedefinePasswordDecoder>;

export const RedefinePasswordResolver = t.object({
  password: newPassword,
});

export type RedefinePasswordParams = t.TypeOf<typeof RedefinePasswordResolver>;
