import type { FormFieldProps } from 'src/mui/_scss';
import { FormField, Input } from 'src/mui/_scss';

type Props = FormFieldProps;

export default function CommentsField({ defaultValue = '', ...rest }: Props) {
  return (
    <FormField label="Observação" defaultValue={defaultValue} {...rest}>
      {(field) => (
        <Input placeholder="Escreva uma observação" {...field} multiline />
      )}
    </FormField>
  );
}
