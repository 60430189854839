import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Link,
  Stack,
} from '@mui/material';
import CopyButton from 'src/mui/CopyButton';
import { Typography } from 'src/design-system/components';

import apiDiagramLightImg from 'src/assets/api-diagram-light.svg';

import styles from './DevDocCard.module.scss';

const DEV_DOC_URL = 'https://docs.finbits.com.br';

export default function DevDocCard() {
  return (
    <Card variant="outlined" className={styles.container}>
      <CardMedia
        component="img"
        alt="Diagrama de uma API"
        src={apiDiagramLightImg}
        className={styles.media}
      />
      <CardContent>
        <Stack justifyContent="center" className={styles.content}>
          <Typography variant="text-lg" fontWeight="600">
            Documentação para desenvolvedores
          </Typography>

          <Typography color="text.secondary">
            Consulte ou compartilhe com a sua equipe técnica a documentação da
            API FinBits:
          </Typography>
        </Stack>
      </CardContent>
      <CardActions className={styles.actions}>
        <Link variant="text-sm" href={DEV_DOC_URL}>
          {DEV_DOC_URL}
        </Link>

        <CopyButton clipboard={DEV_DOC_URL} title="Copiado!" variant="outlined">
          Copiar link
        </CopyButton>
      </CardActions>
    </Card>
  );
}
