import t from 'zod';

import type { Address } from 'src/libs/finbits/BrazilianData/Addresses/types';
import { AddressDecoder } from 'src/libs/finbits/BrazilianData/Addresses/types';
import { NotaFiscalConnectionDecoder } from 'src/libs/finbits/NotaFiscal/Connection/types';

export enum BillsApprovalsType {
  DISABLED = 'disabled',
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export const CompanyDecoder = t.object({
  id: t.string(),
  name: t.string(),
  tradeName: t.string(),
  document: t.string(),
  organizationId: t.string(),
  phone: t.string().nullable(),
  email: t.string().nullable(),
  simpleNational: t.boolean().nullable(),
  municipalRegistration: t.string().nullable(),
  address: AddressDecoder.nullish(),
  specialTaxRuleId: t.string().nullish(),
  inboxEmailAddress: t.string(),
  publicId: t.string(),
  notaFiscalConnection: NotaFiscalConnectionDecoder.nullish(),
  billsApprovals: t.nativeEnum(BillsApprovalsType),
});

export type Company = t.TypeOf<typeof CompanyDecoder>;

export type CompanyUpdateParams = {
  name?: string;
  tradeName?: string;
  document?: string;
  phone?: string | null;
  email?: string;
  address?: Address | null;
};

export type CompanyCreateParams = {
  name: string;
  tradeName: string;
  document: string;
  phone?: string | null;
  email?: string | null;
  address?: {
    postalCode?: string | null;
    district?: string | null;
    street?: string | null;
    number?: string | null;
    details?: string | null;
    state?: string | null;
    city?: string | null;
  };
};

export type GetParams = {
  companyId: string;
  organizationId: string;
};

export type PatchParams = {
  companyId: string;
  organizationId: string;
  params: CompanyUpdateParams;
};

export type CreateParams = {
  organizationId: string;
  params: CompanyCreateParams;
};
