import type { Ref } from 'react';
import { forwardRef, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';

import {
  ApprovalRuleConditionField,
  ApprovalRuleConditionType,
} from 'src/libs/finbits/ApprovalRules/types';
import {
  filterAvailable,
  useClassifications,
} from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

type Option = { id: string; label: string };

type Props = { index: number; onChange: (value: string | null) => void } & Omit<
  SelectProps<any, false, false, false>,
  'options' | 'defaultValue' | 'onChange'
>;

function ConditionFieldSelect(
  { index, onChange, ...props }: Props,
  ref: Ref<HTMLDivElement>
) {
  const { setValue, getValues } = useFormContext();

  const { organizationId, companyId } = useCompanyParams();

  const { classifications, isLoading } = useClassifications(
    {
      organizationId,
      companyId,
    },
    { staleTime: FIVE_MINUTES_IN_MS }
  );

  const options = useMemo(() => {
    const selectedLabels =
      getValues(`conditions.${index}.labelsValues.labels`) ?? [];

    return [
      {
        id: ApprovalRuleConditionField.AMOUNT,
        label: 'Valor',
      },
      { id: ApprovalRuleConditionField.CATEGORY, label: 'Categoria' },
      ...filterAvailable(classifications, selectedLabels).map(
        (classification) => ({
          id: classification.id,
          label: classification.name,
        })
      ),
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classifications.length]);

  function getOptionLabel(option: Option | string) {
    if (typeof option === 'string') {
      return options.find((opt) => opt.id === option)?.label ?? '';
    }

    return option.label;
  }

  function updateConditionTypeField(
    field?: ApprovalRuleConditionField | null | string
  ) {
    if (field === ApprovalRuleConditionField.AMOUNT || !field) {
      setValue(`conditions.${index}.type`, null);

      return;
    }

    if (field !== ApprovalRuleConditionField.CATEGORY) {
      setValue(`conditions.${index}.labelsValues.labels`, []);
    }

    setValue(`conditions.${index}.type`, ApprovalRuleConditionType.ONE_OF);
  }

  function handleChange(
    _e: React.SyntheticEvent<Element, Event>,
    value: Option | null
  ) {
    onChange(value?.id ?? null);

    updateConditionTypeField(value?.id);
  }

  return (
    <Select
      loading={isLoading}
      options={options}
      placeholder="Selecione"
      {...props}
      ref={ref}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => option?.id === value}
      onChange={handleChange}
    />
  );
}

export default forwardRef(ConditionFieldSelect);
