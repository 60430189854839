import useEntriesParams from 'src/features/entries/useEntriesParams';

import { ToolbarDatePicker } from './ToolbarDatePicker';
import { AccountsAutocomplete } from './AccountsAutocomplete';

export default function ToolbarQuickActions() {
  const {
    selectedDateRange,
    setSelectedDateRange,
    selectedAccountIds,
    setSelectedAccountIds,
  } = useEntriesParams();

  return (
    <>
      <ToolbarDatePicker
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
      />
      <AccountsAutocomplete
        selectedAccountIds={selectedAccountIds}
        setSelectedAccountIds={setSelectedAccountIds}
      />
    </>
  );
}
