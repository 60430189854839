import t from 'zod';

import { AccountDecoder } from 'src/libs/finbits/Bank/Accounts/types';
import { PixType } from 'src/libs/finbits/Bank/Pix/types';
import {
  BOLETO_BANKING_SIZE,
  BOLETO_CONSUMPTION_SIZE,
} from 'src/libs/finbits/Boletos';
import { EntryClassificationsDecoder } from 'src/libs/finbits/Classifications/types';
import { LabelDecoder } from 'src/libs/finbits/Labels/types';
import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';
import {
  AvailableActions,
  EntrySituation,
} from 'src/libs/finbits/Management/Entries/types';
import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { ScheduledRecurrenceBaseDecoder } from 'src/libs/finbits/Management/ScheduledRecurrences/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { UserDecoder } from 'src/libs/finbits/Organization/Users/types';
import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { PaymentDetailsDecoder } from 'src/libs/finbits/Payments/types';
import {
  REQUIRED_MESSAGE,
  date,
  required,
} from 'src/libs/finbits/resolverValidations';

import { EditableFields } from 'src/features/EntryForm/types';

import { pixKeyValidation } from '../resolverValidations/pixKey';

import {
  BillApprovalStatus,
  BillFrequencyRecurrence,
  BillPayableApprovalType,
  BillStatus,
} from './types';

export const ClassificationLabelsDecoder = t.object({
  classificationId: t.string(),
  labelsIds: t.string().array(),
});

export const BillFormResolver = t
  .object({
    description: required,
    amount: t.number().min(1, 'Deve ser maior que 0'),
    accountId: required,
    date: date,
    relevantDate: date.nullable(),
    dueDate: date.nullish(),
    notaFiscalNumber: t.string().nullish(),
    notaFiscalIssueDate: date.nullish(),
    contactId: t.string().nullish(),
    categoryId: t.string().nullish(),
    attachments: AttachmentDecoder.array().nullish(),
    assigneesIds: t.array(t.string()).nullish(),
    approvalType: t.nativeEnum(BillPayableApprovalType).nullish(),
    comments: t.string().nullable(),
    classifications: t
      .record(t.string(), ClassificationLabelsDecoder)
      .nullish(),
    isRecurrenceEnabled: t.boolean(),
    frequencyInterval: t.nativeEnum(BillFrequencyRecurrence).nullish(),
    quantity: t.string().nullish(),
    endDate: t.date().nullish(),
    paymentDetails: t
      .object({
        paymentMethod: t.nativeEnum(PaymentMethod).nullish(),
        paymentDetails: t.string().nullish(),
        // TODO: when Epic suggestions 0.5 goes live we can remove all this validation just keeping with pipe section
        pixType: t
          .string()
          .transform((value) => (Boolean(value) ? value : undefined))
          .pipe(t.nativeEnum(PixType).nullish())
          .nullish(),
        pixKey: t.string().nullish(),
        accountDocument: t.string().nullish(),
        routingNumber: t.string().nullish(),
        accountType: t
          .union([t.literal('checking_account'), t.literal('savings_account')])
          .nullish(),
        branchNumber: t.string().nullish(),
        accountNumber: t.string().nullish(),
        boletoId: t.string().nullish(),
        digitableLine: t.string().nullish(),
        payeeName: t.string().nullish(),
      })
      .superRefine(
        (
          {
            paymentMethod,
            digitableLine,
            pixKey,
            pixType,
            accountNumber,
            branchNumber,
            accountType,
            routingNumber,
            payeeName,
            accountDocument,
          },
          ctx
        ) => {
          if (paymentMethod === PaymentMethod.PIX) {
            if (!pixKey) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['pixKey'],
              });
            }

            const pixtypes = Object.values(PixType);
            if (!pixType || !pixtypes.includes(pixType)) {
              ctx.addIssue({
                code: 'custom',
                message: 'Selecione uma Chave Pix válida',
                path: ['pixType'],
              });
            }

            if (pixKey && pixType) {
              if (!pixKeyValidation({ pixKey, pixType })) {
                ctx.addIssue({
                  code: 'custom',
                  message: 'Chave inválida.',
                  path: ['pixKey'],
                });
              }
            }
          }

          if (paymentMethod === PaymentMethod.BOLETO) {
            if (!digitableLine) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['digitableLine'],
              });
            }

            if (digitableLine && digitableLine?.length < 47) {
              ctx.addIssue({
                code: 'too_small',
                message: 'Deve conter pelo menos 47 caracteres',
                path: ['digitableLine'],
                minimum: BOLETO_BANKING_SIZE,
                type: 'string',
                inclusive: true,
              });
            }

            if (digitableLine && digitableLine?.length > 48) {
              ctx.addIssue({
                code: 'too_big',
                message: 'Deve conter no máximo 48 caracteres',
                path: ['digitableLine'],
                maximum: BOLETO_CONSUMPTION_SIZE,
                type: 'string',
                inclusive: true,
              });
            }
          }

          if (paymentMethod === PaymentMethod.PIX_QR_CODE) {
            if (!pixKey) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['pixKey'],
              });
            }
          }

          if (paymentMethod === PaymentMethod.WIRE_TRANSFER) {
            if (!accountNumber) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['accountNumber'],
              });
            }

            if (!accountDocument) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['accountDocument'],
              });
            }

            if (!payeeName) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['payeeName'],
              });
            }

            if (!routingNumber) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['routingNumber'],
              });
            }

            if (!accountType) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['accountType'],
              });
            }

            if (!branchNumber) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['branchNumber'],
              });
            }
          }
        }
      )
      .nullable(),
  })
  .superRefine(({ isRecurrenceEnabled, quantity, frequencyInterval }, ctx) => {
    if (!isRecurrenceEnabled) return;

    if (!frequencyInterval) {
      ctx.addIssue({
        code: 'custom',
        message: 'Esse campo precisa ser preenchido',
        path: ['frequencyInterval'],
      });
    }

    if (!quantity) {
      ctx.addIssue({
        code: 'custom',
        message: REQUIRED_MESSAGE,
        path: ['quantity'],
      });
    }

    if (Number(quantity) < 2) {
      ctx.addIssue({
        code: 'too_small',
        message: 'Deve ser maior que 1',
        path: ['quantity'],
        minimum: 1,
        type: 'number',
        inclusive: true,
      });
    }
    if (Number(quantity) > 53) {
      ctx.addIssue({
        code: 'too_big',
        message: 'Deve ser menor que 54',
        path: ['quantity'],
        maximum: 53,
        type: 'number',
        inclusive: true,
      });
    }
  });

const BillApprovalStatusDecoder = t.nativeEnum(BillApprovalStatus);
const BillPayableStatusDecoder = t.nativeEnum(BillStatus);
const BillPayableApprovalTypeDecoder = t.nativeEnum(BillPayableApprovalType);

export const BillPayableAssignmentDecoder = t.object({
  approvedAt: t.string().nullable(),
  reprovedAt: t.string().nullable(),
  reprovalReason: t.string().nullable(),
  assignee: UserDecoder.nullable(),
});

export const BillPayableLastApprovalDecoder = t.object({
  assignments: BillPayableAssignmentDecoder.array().optional(),
  requester: UserDecoder.nullish(),
  status: BillApprovalStatusDecoder,
  requestedAt: t.string().nullable(),
});

export const BillPayableDecoder = t.object({
  account: AccountDecoder.optional(),
  accountId: t.string(),
  amount: t.number(),
  attachments: AttachmentDecoder.array().optional(),
  category: CategoryDecoder.nullish(),
  categoryId: t.string().nullable(),
  comments: t.string().nullable(),
  companyId: t.string(),
  contact: ContactDecoder.nullish(),
  contactId: t.string().nullable(),
  date: t.string(),
  description: t.string(),
  notaFiscalNumber: t.string().nullish(),
  notaFiscalIssueDate: t.string().nullish(),
  dueDate: t.string().nullish(),
  id: t.string(),
  publicId: t.string(),
  paymentDetails: PaymentDetailsDecoder.nullish(),
  relevantDate: t.string().nullable(),
  status: BillPayableStatusDecoder,
  lastApproval: BillPayableLastApprovalDecoder.nullish(),
  unfilledContextFields: t.string().array().nullable(),
  labels: LabelDecoder.array().optional(),
  assignees: UserDecoder.array().optional(),
  approvalType: BillPayableApprovalTypeDecoder,
  currentSituation: t.nativeEnum(EntrySituation).nullish(),
  editableFields: t.nativeEnum(EditableFields).array().nullish(),
  availableActions: t.nativeEnum(AvailableActions).array().nullish(),
  scheduledRecurrence: ScheduledRecurrenceBaseDecoder.nullish(),
  scheduledRecurrenceId: t.string().nullable(),
  paymentRequestErrorDetail: t.string().nullable(),
  entryType: t.nativeEnum(EntryType),
  amountType: t.nativeEnum(BalanceType),
  classifications: EntryClassificationsDecoder.array().nullish(),
});

export const RecurringBillDecoder = t.object({
  id: t.string(),
  initialDate: t.string().nullable(),
  quantity: t.number(),
  bills: BillPayableDecoder.array(),
});
