import { Chip } from 'src/design-system/components';
import { HourglassOutlined } from '@ant-design/icons';

type Props = {
  label: string;
  suggestion?: boolean;
  isLoading?: boolean;
};

export type SuggestionLabelProp = {
  isLoading: boolean;
  value?: string | null;
};

export default function SuggestionLabel({
  label,
  suggestion,
  isLoading = false,
}: Props) {
  const hasSuggestion = Boolean(suggestion);

  if (isLoading) {
    return (
      <>
        <span>{label}</span>
        <Chip
          icon={<HourglassOutlined />}
          label="Carregando sugestão..."
          size="small"
          color="info"
        />
      </>
    );
  }

  if (hasSuggestion) {
    return (
      <>
        <span>{label}</span>
        <Chip color="success" label="Preenchimento sugerido" size="small" />
      </>
    );
  }

  return <span>{label}</span>;
}
