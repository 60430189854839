import {
  CalendarOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  EditOutlined,
  StopOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import compact from 'lodash/compact';

import { DropdownActions } from 'src/ui';

import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useMenuItem } from 'src/libs/finbits/MenuItem/useMenuItem';

import { useActivationActions } from 'src/features/contacts/useActivationActions';

export type CreateEntryParams = {
  type: 'scheduled' | 'financial';
  contactId: string;
};

type Props = {
  organizationId: string;
  companyId: string;
  contact: Contact;
  onEdit: (contact: Contact) => void;
  onCreateEntry: (createEntryParams: CreateEntryParams) => void;
};

export default function Actions({
  organizationId,
  companyId,
  contact,
  onEdit,
  onCreateEntry,
}: Props) {
  const { openActivationDialog, openDeactivationDialog } = useActivationActions(
    {
      organizationId,
      companyId,
      contactId: contact.id,
    }
  );

  const canEditContact = useAllowedPermission({
    action: 'update',
    resource: 'contacts',
  });

  const items = compact([
    useMenuItem({
      menuItem: {
        key: 'edit',
        label: canEditContact ? 'Editar Contato' : 'Visualizar Contato',
        icon: canEditContact ? <EditOutlined /> : <UserOutlined />,
        onClick: () => onEdit(contact),
      },
    }),

    useMenuItem({
      menuItem: {
        key: 'create-scheduled-entry',
        label: 'Adicionar lançamento programado',
        icon: <CalendarOutlined />,
        onClick: () =>
          onCreateEntry({ type: 'scheduled', contactId: contact.id }),
      },
      permissions: [
        {
          resource: 'bills',
          action: 'create',
        },
        {
          resource: 'receivables',
          action: 'create',
        },
      ],
    }),

    useMenuItem({
      menuItem: {
        key: 'create-financial-entry',
        label: 'Adicionar lançamento realizado',
        icon: <CheckOutlined />,
        onClick: () =>
          onCreateEntry({ type: 'financial', contactId: contact.id }),
      },
      permissions: {
        resource: 'financialEntries',
        action: 'create',
        scope: 'company',
      },
    }),

    useMenuItem({
      menuItem: {
        key: 'activation',
        label: `${contact.active ? 'Desativar' : 'Ativar'} Contato`,
        icon: contact.active ? <StopOutlined /> : <CheckCircleOutlined />,
        onClick: () =>
          contact.active ? openDeactivationDialog() : openActivationDialog(),
      },
      permissions: [
        {
          resource: 'contacts',
          action: 'update',
        },
        {
          resource: 'contacts',
          action: 'show',
        },
      ],
    }),
  ]);

  return <DropdownActions overlay={<Menu items={items} />} />;
}
