import t from 'zod';

const REQUIRED_MESSAGE = 'Por favor, informe o valor do saldo.';

export const RegisterBalanceResolver = t.object({
  balance: t
    .string({
      required_error: REQUIRED_MESSAGE,
    })
    .min(1, { message: REQUIRED_MESSAGE }),
});
