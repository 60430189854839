import { useState } from 'react';

import { Button, Typography } from 'antd';
import type { Location } from 'history';
import { useNavigate } from 'react-router';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type {
  ImportRedirectParams,
  ImportedStatement,
} from 'src/libs/finbits/Bank/Statements/types';

import RevisionDrawer from 'src/features/ofx/RevisionDrawer';

import NewTransactions from './NewTransactions';
import AccountInformation from './AccountInformation';
import styles from './SuccessStep.module.less';

type Props = {
  importedStatement: ImportedStatement;
  closeModal: () => void;
  redirectPathAfterImport: (params: ImportRedirectParams) => Partial<Location>;
};

function SuccessStep({
  importedStatement,
  closeModal,
  redirectPathAfterImport,
}: Props) {
  const navigate = useNavigate();
  const { organizationId, companyId } = useCompanyParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  function uploadSuccessConfirmation() {
    if (importedStatement.transactions.length > 0) {
      navigate(
        redirectPathAfterImport({
          organizationId,
          companyId,
          accountId: importedStatement.accountId,
          date: importedStatement.transactions[0].date,
        })
      );
    }

    closeModal();
  }

  const hasNewTransactions =
    importedStatement?.transactions?.length > 0 ||
    importedStatement?.ignoredRecords?.length > 0;

  return (
    <>
      {hasNewTransactions ? (
        <>
          <NewTransactions importedStatement={importedStatement} />
          <Button type="link" onClick={() => setIsDrawerOpen(true)}>
            <Typography.Text underline>Revisar importação</Typography.Text>
          </Button>
        </>
      ) : (
        <Typography.Text key={importedStatement.accountNumber}>
          Verificamos que não há novos lançamentos no arquivo, todos já constam
          na conta <b>{importedStatement.name}</b> (
          <AccountInformation importedStatement={importedStatement} />
          ).
        </Typography.Text>
      )}

      <div className={styles.buttonWrapper}>
        <Button
          className={styles.button}
          onClick={uploadSuccessConfirmation}
          size="large"
          type="primary"
        >
          OK
        </Button>
      </div>

      <RevisionDrawer
        importedStatement={importedStatement}
        onClose={closeDrawer}
        closeModal={closeModal}
        visible={isDrawerOpen}
      />
    </>
  );
}

export default SuccessStep;
