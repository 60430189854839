import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import styles from './RuleDescriptionCard.module.scss';

type Props = {
  title: string;
  index: number;
  description: string;
};

export default function RuleDescriptionCard({
  title,
  index,
  description,
}: Props) {
  return (
    <Card variant="outlined">
      <CardHeader
        className={styles.cardHeader}
        title={
          <Stack gap={2}>
            <Typography fontWeight={600}>{index}.</Typography>
            <Typography fontWeight={600}>{title}</Typography>
          </Stack>
        }
      />
      <CardContent className={styles.cardContent}>
        <Typography color="grey.600">{description}</Typography>
      </CardContent>
    </Card>
  );
}
