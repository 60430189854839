import type { ButtonProps } from 'antd';
import { Button } from 'antd';

export default function SubmitButton({ children, ...rest }: ButtonProps) {
  return (
    <Button size="large" type="primary" htmlType="submit" {...rest}>
      {children}
    </Button>
  );
}
