import {
  filterServices,
  getServiceId,
} from 'src/libs/finbits/NotaFiscal/Services';
import type {
  CompanyService,
  Service,
} from 'src/libs/finbits/NotaFiscal/Services/types';

import ServiceCard from 'src/features/nota-fiscal/Steps/ServicesStep/ServiceCard';

import styles from './ServicesList.module.less';

type Props = {
  type?: 'checkbox' | 'radio';
  selectedServices?: Array<Service | CompanyService>;
  services: Array<Service | CompanyService>;
  searchValue?: string;
  onChangeService: (service: Service | CompanyService) => void;
};

export default function ServicesList({
  type = 'checkbox',
  selectedServices,
  services,
  searchValue,
  onChangeService,
}: Props) {
  const filteredServices = !!searchValue
    ? services.filter((service) => filterServices(searchValue, service))
    : services;

  if (filteredServices.length <= 0) {
    return null;
  }

  return (
    <ul className={styles.servicesList}>
      {filteredServices.map((service) => {
        const serviceId = getServiceId(service);
        const isSelected = selectedServices?.some(
          (service) => getServiceId(service) === serviceId
        );

        return (
          <li key={serviceId}>
            <ServiceCard
              type="select"
              actionType={type}
              showSuggestedRate
              selected={isSelected}
              highlightText={searchValue}
              service={service}
              onChangeService={() => onChangeService(service)}
            />
          </li>
        );
      })}
    </ul>
  );
}
