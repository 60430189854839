import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { AxiosError, AxiosResponse } from 'axios';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';

import type {
  Category,
  GetParams,
  ImportParams,
  PatchParams,
  PostParams,
} from './types';
import { CategoriesDecoder } from './types';

export async function postCategory({
  companyId,
  organizationId,
  payload,
}: PostParams): Promise<AxiosResponse<Category>> {
  return await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/categories`,
    payload
  );
}

async function postImportCategory({
  organizationId,
  companyId,
  file,
}: ImportParams) {
  const response = await authenticatedAPI.postForm(
    `/organizations/${organizationId}/companies/${companyId}/categories/batch_create`,
    { file }
  );

  return decodeResponse<Category[]>(response, CategoriesDecoder);
}

export async function patchCategory({
  categoryId,
  companyId,
  organizationId,
  payload,
}: PatchParams): Promise<AxiosResponse<Category>> {
  return await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/categories/${categoryId}`,
    payload
  );
}

export async function getCompanyCategories({
  companyId,
  organizationId,
}: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/categories`
  );

  return decodeResponse<Category[]>(response, CategoriesDecoder);
}

export function setQueryKey({ companyId, organizationId }: GetParams) {
  return ['categories', { companyId, organizationId }];
}

export function useCategories(
  { companyId, organizationId }: GetParams,
  customOptions: Partial<UseQueryOptions<Category[], ApiError>> = {}
) {
  const enabled = !!organizationId && !!companyId;
  const { data, ...rest } = useQuery<Category[], ApiError>({
    queryKey: setQueryKey({ companyId, organizationId }),
    queryFn: () => getCompanyCategories({ companyId, organizationId }),
    enabled,
    ...customOptions,
  });
  return { categories: withEmptyArrayDefault(data), ...rest };
}

export function useAddCategory() {
  const queryClient = useQueryClient();

  const { mutate: addCategory, ...rest } = useMutation<
    AxiosResponse<Category>,
    AxiosError<{
      message: string;
      errors: {
        [key: string]: string[];
      };
    }>,
    PostParams
  >(postCategory, {
    onSuccess: () => queryClient.invalidateQueries(['categories']),
  });
  return { addCategory, ...rest };
}

export function useUpdateCategory() {
  const queryClient = useQueryClient();

  const { mutate: updateCategory, ...rest } = useMutation<
    AxiosResponse<Category>,
    AxiosError<{
      message: string;
      errors: {
        [key: string]: string[];
      };
    }>,
    PatchParams
  >(patchCategory, {
    onSuccess: () => queryClient.invalidateQueries(['categories']),
  });
  return { updateCategory, ...rest };
}

export function useImportCategories() {
  const queryClient = useQueryClient();
  const { mutate: importCategories, ...rest } = useMutation<
    Category[],
    ApiError,
    ImportParams
  >(postImportCategory, {
    onSuccess: () => queryClient.invalidateQueries(['categories']),
  });
  return { importCategories, ...rest };
}
