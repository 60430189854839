import { FormField } from 'src/mui/_scss';
import { useWatch } from 'react-hook-form';

import { useClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import LabelSelect from 'src/features/labels/LabelSelect';

type Props = {
  index: number;
};

export default function LabelValues({ index }: Props) {
  const formConditionFieldValue = useWatch({
    name: `conditions.${index}.field`,
  });

  const { organizationId, companyId } = useCompanyParams();

  const { classifications, isLoading } = useClassifications(
    {
      organizationId,
      companyId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const classification = classifications?.find(
    (classification) => classification.id === formConditionFieldValue
  );

  return (
    <FormField
      label="Valor"
      name={`conditions.${index}.labelsValues.labels`}
      shouldUnregister
      defaultValue={[]}
    >
      {(field) => {
        return (
          <LabelSelect
            {...field}
            multiple
            loading={isLoading}
            classification={classification}
            placeholder="valor"
          />
        );
      }}
    </FormField>
  );
}
