import { Divider, Typography } from 'antd';

import type { SpreadsheetTab } from 'src/libs/finbits/Spreadsheet/types';

import type {
  ErrorMessage,
  LineError,
  ScheduledEntryDrawerErrors,
} from 'src/features/entries/Modals/ImportScheduledEntryModal/types';

import styles from './ErrorList.module.less';

type Props = {
  errors: ScheduledEntryDrawerErrors;
  tab: SpreadsheetTab;
};

const DICTIONARY: Record<string, string> = {
  description: 'Descrição',
  amount: 'Valor',
  date: 'Data',
  contactDocument: 'Contato nacional',
  relevantDate: 'Competência',
  categoryName: 'Categoria',
  comments: 'Observação',
  paymentMethod: 'Meio de pagamento',
  paymentDetails: 'Detalhes do pagamento',
  digitableLine: 'Detalhes do pagamento',
  pixKey: 'Chave PIX',
  pixType: 'Tipo da chave PIX',
};

function flatLineErrors(lineErrors: LineError): Record<string, string> {
  return Object.keys(lineErrors).reduce((acc, key) => {
    if (typeof lineErrors[key] === 'string') {
      return { ...acc, [key]: lineErrors[key] };
    }

    if (Array.isArray(lineErrors[key])) {
      return { ...acc, [key]: (lineErrors[key] as string[]).join(', ') };
    }

    return {
      ...acc,
      ...flatLineErrors(lineErrors[key] as Record<string, ErrorMessage>),
    };
  }, {});
}

function ErrorList({ errors, tab }: Props) {
  return (
    <ul className={styles.list}>
      {errors[tab].map(({ number, errors }, index) => {
        const lineErrors = flatLineErrors(errors);

        return Object.keys(lineErrors).map((column) => (
          <li key={`${tab}-${index}-${column}`}>
            <Typography.Paragraph>
              Linha {number} - {DICTIONARY[column]}: {lineErrors[column]}
            </Typography.Paragraph>
            <Divider />
          </li>
        ));
      })}
    </ul>
  );
}

export default ErrorList;
