import { Chip } from 'src/design-system/components';

export default function AutomatichLabel() {
  return (
    <>
      Automático
      <Chip variant="filled" color="success" label="Recomendado" size="small" />
    </>
  );
}
