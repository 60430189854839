import { useState } from 'react';

import { Button, List, Tooltip, Typography } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';

import type { ImportContactResponse } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import ImportErrorDrawer from 'src/features/contacts/ImportErrorDrawer';

import styles from './ResultContent.module.less';

type Props = {
  importResponse: ImportContactResponse;
};

export default function ResultContent({ importResponse }: Props) {
  const [importErrorDrawerVisible, setImportErrorDrawerVisible] =
    useState(false);

  const contactsCount = importResponse?.contacts.length;
  const ignoredCount = importResponse?.ignoredRows.length;
  const failuresCount = importResponse?.failures.length;

  if (contactsCount === 0 && ignoredCount === 0 && failuresCount === 0) {
    return (
      <Typography.Text className={styles.message}>
        Realizamos a importação mas não identificamos nenhum contato. Verifique
        os dados da sua planilha e faça a importação novamente.
      </Typography.Text>
    );
  }

  return (
    <>
      {importErrorDrawerVisible && (
        <ImportErrorDrawer
          importResponse={importResponse}
          onClose={() => setImportErrorDrawerVisible(false)}
        />
      )}

      <div className={styles.list}>
        <List grid={{ column: 1 }}>
          {Boolean(contactsCount) && (
            <List.Item role="listitem">
              <CheckCircleOutlined />
              <Typography.Text>
                Contatos importados: <strong>{contactsCount}</strong>
              </Typography.Text>
            </List.Item>
          )}
          {Boolean(ignoredCount) && (
            <List.Item role="listitem">
              <UserSwitchOutlined />
              <Typography.Text>
                Contatos duplicados: <strong>{ignoredCount}</strong>
              </Typography.Text>
              <Tooltip
                placement="bottom"
                overlayInnerStyle={{ textAlign: 'center' }}
                title="Contatos identificados como duplicados não são importados, pois já estão cadastrados na plataforma."
                className={styles.tooltipIcon}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </List.Item>
          )}
          {Boolean(failuresCount) && (
            <List.Item role="listitem">
              <ExclamationCircleOutlined />
              <Typography.Text>
                Contatos não importados: <strong>{failuresCount}</strong>
              </Typography.Text>
            </List.Item>
          )}
        </List>
      </div>

      {(Boolean(ignoredCount) || Boolean(failuresCount)) && (
        <Button type="link" onClick={() => setImportErrorDrawerVisible(true)}>
          Revisar erros de importação
        </Button>
      )}
    </>
  );
}
