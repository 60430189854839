import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

import { baseFocusIcon, sizes } from '../FocusIcon.sx';
import type { FocusIconSize } from '../FocusIcon';

import { borderRadius, types } from './Square.sx';

export type FocusIconSquareType = 'outlined' | 'filled';

type Props = {
  size: FocusIconSize;
  type?: FocusIconSquareType;
} & Omit<BoxProps, 'color'>;

export default function Square({
  children,
  size,
  sx,
  type = 'outlined',
  ...rest
}: Props) {
  const styles = merge(
    baseFocusIcon,
    sizes[size],
    types[type],
    borderRadius[size],
    sx
  );

  return (
    <Box {...rest} sx={styles} data-testid="focus-icon-square">
      {children}
    </Box>
  );
}
