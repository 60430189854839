import { ptBR as mui_ptBr } from '@mui/material/locale';
import type { GridLocaleText } from '@mui/x-data-grid-premium';
import { ptBR as dataGrid_ptBr } from '@mui/x-data-grid-premium/locales';
import { deepmerge } from '@mui/utils';

const customLocale = {
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: {
          columnMenuSortAsc: 'Ordenar crescente',
          columnMenuSortDesc: 'Ordenar decrescente',
          filterPanelRemoveAll: 'Limpar filtros',
          aggregationMenuItemHeader: 'Agregar valores',
        },
      },
    },
  },
};

const ptBR = deepmerge(mui_ptBr, deepmerge(dataGrid_ptBr, customLocale));

export function dataGridGetLocaleText(key: keyof GridLocaleText) {
  const { components } = ptBR as any;

  return components.MuiDataGrid.defaultProps.localeText[key] || key;
}

export function createLocale() {
  return ptBR;
}
