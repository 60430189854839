import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function RotateRightIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="rotate-right"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M7.50847 9.4805H21.6513C22.1254 9.4805 22.5085 9.86353 22.5085 10.3376V21.4269C22.5085 21.901 22.1254 22.2841 21.6513 22.2841H7.50847C7.03437 22.2841 6.65133 21.901 6.65133 21.4269V10.3376C6.65133 9.86353 7.03437 9.4805 7.50847 9.4805ZM8.68705 20.2484H20.4728V11.5162H8.68705V20.2484Z"
        fill="currentColor"
      />

      <path
        d="M3.56294 7.08421C5.67365 4.38689 8.81294 2.96992 11.9897 2.95385L11.9951 1.23957C11.9951 1.06546 12.1987 0.969031 12.3326 1.07617L15.7612 3.78153C15.8683 3.86457 15.8656 4.02528 15.7612 4.111L12.3299 6.81903C12.1933 6.92617 11.9897 6.82975 11.9924 6.65564V4.94403C11.6469 4.94671 11.2987 4.96814 10.9531 5.011C9.82544 5.15028 8.75401 5.4985 7.76562 6.0476C6.74508 6.61546 5.85847 7.37885 5.1299 8.30564C4.40401 9.2351 3.87901 10.2797 3.57365 11.4074C3.24085 12.6377 3.18142 13.9259 3.39954 15.1815H1.3933C0.996868 12.4065 1.69597 9.47617 3.56294 7.08421Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
