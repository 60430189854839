import t from 'zod';

import {
  email,
  newPassword,
  phoneNumber,
  required,
  terms,
} from 'src/libs/finbits/resolverValidations';

export const InvitationDecoder = t.object({
  email: t.string(),
});

export type Invitation = t.TypeOf<typeof InvitationDecoder>;

export type GetInvitationParams = {
  invitationCode: string | null;
  organizationId?: string;
};

export type PostInvitationParams = {
  email: string;
  organizationId?: string;
};

export type PatchInvitationParams = {
  userId: string;
  organizationId?: string;
};

export const AcceptInvitationFormResolver = t.object({
  authentication: t.object({
    password: newPassword,
  }),
  email,
  name: required,
  phone: t.object({
    number: phoneNumber,
  }),
  terms,
});

export type AcceptInvitationForm = t.TypeOf<
  typeof AcceptInvitationFormResolver
>;

export type PostAcceptInvitationParams = {
  name: AcceptInvitationForm['name'];
  phone: AcceptInvitationForm['phone'];
  password: AcceptInvitationForm['authentication']['password'];
  organizationId?: string;
  invitationCode: string | null;
};
