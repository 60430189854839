import cn from 'classnames';
import { List, Switch, Tooltip, Typography } from 'antd';

import { Popover } from 'src/ui';

import { toDecimal } from 'src/libs/finbits/Money';
import { format } from 'src/libs/finbits/Date';
import type { StatementTransactionRevision } from 'src/libs/finbits/Bank/Statements/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './TransactionItem.module.less';

type Props = {
  transaction: StatementTransactionRevision;
  toggleTransaction: (transactionId: string) => void;
};

function TransactionItem({ transaction, toggleTransaction }: Props) {
  const isCredit = transaction.type === BalanceType.CREDIT;
  const value = toDecimal(transaction.amount, transaction.type);
  const shouldTransact = transaction.shouldTransact;
  const shouldIgnore = !shouldTransact;
  const actionLabel = shouldTransact ? 'Ignorar' : 'Importar';

  return (
    <List.Item>
      <div className={cn(styles.content, [shouldIgnore && styles.opacity])}>
        <span className={styles.date}>{format(transaction.date, 'dd/MM')}</span>
        <Popover
          visible={shouldTransact ? false : undefined}
          placement="bottomLeft"
          title={
            <Typography.Text strong>Registros ignorados 🔦</Typography.Text>
          }
          content={
            'O Finbits usa inteligência para descartar ' +
            'registros sem relevância para a gestão ' +
            'financeira.'
          }
        >
          <Typography.Text
            type={isCredit ? 'success' : undefined}
            ellipsis={{ tooltip: transaction.description }}
          >
            {transaction.description}
          </Typography.Text>
        </Popover>
      </div>
      <div>
        <Typography.Text
          className={cn([shouldIgnore && styles.opacity, styles.value])}
          type={isCredit ? 'success' : undefined}
        >
          {value}
        </Typography.Text>
        <Tooltip title={actionLabel} placement="bottom">
          <Switch
            aria-label={`${actionLabel} ${transaction.description}`}
            checked={shouldTransact}
            onChange={() => toggleTransaction(transaction.id)}
          />
        </Tooltip>
      </div>
    </List.Item>
  );
}

export default TransactionItem;
