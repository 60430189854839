import { useEffect, useMemo, useState } from 'react';

import type { PortalProps } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useInboxItem } from 'src/libs/finbits/Management/NewInboxItems';
import { useEntrySuggestions } from 'src/libs/finbits/Management/Entries/Suggestions';
import type { NewEntrySuggestion } from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

import { useInboxItemsAttachments } from 'src/features/inbox-items/useInboxItemsAttachments';
import type { FormValues } from 'src/features/EntryForm/types';
import { newEntrySuggestionToFormValues } from 'src/features/EntryForm/formValues';
import PreviewAttachment from 'src/features/preview/PreviewAttachment';
import CreateBillDrawer from 'src/features/bills-to-pay/CreateBillDrawer';
import { buildOriginalValuesForConciliation } from 'src/features/bills-to-pay/CreateBillDrawer/buildOriginalValuesForConciliation';

type Props = {
  inboxItemId: string;
  linkedEntry?: BillPayable;
  onSuccess?: () => void;
} & PortalProps;

const emptyOriginalValues = {};

export default function CreateBillFromInboxItem({
  inboxItemId,
  linkedEntry,
  onClose,
  onSuccess,
  open = true,
  onExit,
}: Props) {
  const [suggestions, setSuggestions] = useState<NewEntrySuggestion[]>();

  const { companyId, organizationId } = useCompanyParams();

  const { inboxItem, isLoading: isLoadingInboxItem } = useInboxItem({
    companyId,
    organizationId,
    inboxItemId,
  });

  const { entrySuggestions, isLoading: isLoadingSuggestions } =
    useEntrySuggestions();

  useEffect(() => {
    entrySuggestions(
      { organizationId, companyId, params: { inboxItemsIds: [inboxItemId] } },
      { onSuccess: (response) => setSuggestions(response) }
    );
  }, [organizationId, companyId, inboxItemId, entrySuggestions]);

  const { attachments } = useInboxItemsAttachments(inboxItem);

  const newContact = useMemo(() => {
    if (!suggestions || !suggestions[0] || !suggestions[0].fields.contact) {
      return undefined;
    }

    return suggestions[0].fields.contact;
  }, [suggestions]);

  const fieldsToOmitOnConciliation = useMemo(() => {
    if (inboxItem?.source === 'dda')
      return ['amount', 'date', 'paymentDetails'] as Array<keyof FormValues>;

    if (
      inboxItem?.source === 'purchase_order' &&
      Boolean(inboxItem?.purchaseOrder?.paymentDetails?.paymentMethod)
    )
      return ['paymentDetails'] as Array<keyof FormValues>;

    return [];
  }, [inboxItem]);

  const originValues = useMemo(() => {
    if (!inboxItem || suggestions === undefined || !attachments) {
      return emptyOriginalValues;
    }

    const valuesFromItem = newEntrySuggestionToFormValues(
      suggestions[0],
      attachments
    );

    if (!!linkedEntry) {
      return buildOriginalValuesForConciliation(
        valuesFromItem,
        linkedEntry,
        fieldsToOmitOnConciliation
      );
    }

    return valuesFromItem;
  }, [
    inboxItem,
    suggestions,
    attachments,
    linkedEntry,
    fieldsToOmitOnConciliation,
  ]);

  const isLoading =
    isLoadingInboxItem ||
    isLoadingSuggestions ||
    originValues === emptyOriginalValues;

  const hasAttachments = Array.isArray(attachments) && attachments.length > 0;

  return (
    <>
      {hasAttachments && (
        <PreviewAttachment
          renderWithDrawer
          renderCloseButton={false}
          attachments={attachments}
          onClose={onClose}
          open={open}
        />
      )}

      <CreateBillDrawer
        overlay={!hasAttachments}
        originValues={originValues}
        newContact={newContact}
        inboxItemId={inboxItemId}
        onClose={onClose}
        loading={isLoading}
        onSuccess={onSuccess}
        fieldsToOmitOnConciliation={fieldsToOmitOnConciliation}
        scheduledStatementEntry={linkedEntry}
        open={open}
        onExit={onExit}
        submitButtonText="Enviar para Contas a Pagar"
      />
    </>
  );
}
