import type { SxProps, Theme } from '@mui/material';
import { inputClasses } from '@mui/material';

export const textField: SxProps<Theme> = {
  backgroundColor: 'background.default',
  width: '340px',
  padding: 2,

  [`.${inputClasses.root}`]: {
    height: 'unset',
  },
};

export const popper: SxProps<Theme> = {
  backgroundColor: 'background.default',
  width: '340px !important',
  border: 0,
  boxShadow: 'none !important',
};

export const paper: SxProps<Theme> = {
  border: 0,
  boxShadow: 0,
};
