import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';

import SendToWaitingApprovalButton from '../buttons/SendToWaitingApprovalButton';
import SendToPaymentButton from '../buttons/SendToPaymentButton';
import type { Action } from '../types';

export default function PendingActions(props: Action) {
  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  if (isEnabledBillsApprovals) {
    return <SendToWaitingApprovalButton {...props} />;
  }

  return <SendToPaymentButton {...props} />;
}
