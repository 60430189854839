import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import throttle from 'lodash/throttle';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';

import { NotaFiscalDecoder } from './types';
import type {
  DeleteParams,
  GetAllParams,
  GetParams,
  NotaFiscal,
  PostParams,
} from './types';

function invalidateNotasFiscaisQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries('notasFiscais');
}

export const invalidateNotasFiscaisQueries = throttle(
  invalidateNotasFiscaisQueriesCall,
  FIVE_SECONDS_IN_MS
);

async function postNotaFiscal({
  organizationId,
  companyId,
  ...payload
}: PostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/notas_fiscais`,
    payload
  );

  return decodeResponse<NotaFiscal>(response, NotaFiscalDecoder);
}

export function useCreateNotaFiscal() {
  const queryClient = useQueryClient();

  const { mutate: createNotaFiscal, ...rest } = useMutation<
    NotaFiscal,
    ApiError,
    Omit<PostParams, 'isSendEmailToContact'>
  >(postNotaFiscal, {
    onSuccess: () => invalidateNotasFiscaisQueries(queryClient),
  });

  return { createNotaFiscal, ...rest };
}

async function getNotasFiscais({ organizationId, companyId }: GetAllParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/notas_fiscais`
  );

  return decodeResponse<NotaFiscal[]>(response, NotaFiscalDecoder.array());
}

export function useNotasFiscais(params: GetAllParams) {
  const { data, ...rest } = useQuery<NotaFiscal[], ApiError>({
    queryKey: ['notasFiscais', params],
    enabled: !!params.organizationId && !!params.companyId,
    queryFn: () => getNotasFiscais(params),
  });

  return { notasFiscais: withEmptyArrayDefault(data), ...rest };
}

async function cancelNotaFiscal({
  organizationId,
  companyId,
  notaFiscalId,
}: DeleteParams) {
  return authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/notas_fiscais/${notaFiscalId}`
  );
}

export function useCancelNotaFiscal() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<unknown, ApiError, DeleteParams>(
    cancelNotaFiscal,
    {
      onSuccess: () => invalidateNotasFiscaisQueries(queryClient),
    }
  );

  return { cancelNotaFiscal: mutate, ...rest };
}

async function getNotaFiscal({ organizationId, companyId, id }: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/notas_fiscais/${id}`
  );

  return decodeResponse<NotaFiscal>(response, NotaFiscalDecoder);
}

export function useNotaFiscal(
  params: GetParams,
  options?: UseQueryOptions<NotaFiscal, ApiError>
) {
  const { data, ...rest } = useQuery<NotaFiscal, ApiError>({
    queryKey: ['notasFiscais', params.id],
    enabled: !!params.organizationId && !!params.companyId && !!params.id,
    queryFn: () => getNotaFiscal(params),
    ...options,
  });

  return { notaFiscal: data, ...rest };
}
