import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import deburr from 'lodash/deburr';

import { format } from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useNewInboxItems } from 'src/libs/finbits/Management/NewInboxItems';
import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';
import { inboxItemSourceDictionary } from 'src/libs/finbits/Management/NewInboxItems/translations';

import { useInboxItemsParams } from 'src/features/inbox-items/useInboxItemsParams';

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function filterInboxItems(inboxItems: NewInboxItem[], terms: string[]) {
  const regexTerms = terms.map(
    (string) => new RegExp(escapeRegExp(deburr(string)), 'i')
  );

  return regexTerms.reduce((acc, regex) => {
    return acc.filter(
      ({ title, description, fromName, fromDetail, source, insertedAt }) => {
        const formattedInsertedAt = format(insertedAt, "dd/MM/yyyy 'às' HH:mm");

        return [
          title,
          description,
          fromName,
          fromDetail,
          inboxItemSourceDictionary[source],
          formattedInsertedAt,
        ].some((string) => regex.test(deburr(string ?? '')));
      }
    );
  }, inboxItems);
}

type InboxItemsProviderProps = {
  children: ReactNode;
};

type InboxItemsContextProps = {
  filteredInboxItems: NewInboxItem[];
  isLoading: boolean;
  quickFilter?: string[];
  setQuickFilter: (quickFilter?: string[]) => void;
  isSearching: boolean;
  setIsSearching: (isSearching: boolean) => void;
  activeIndex: number;
  setActiveInboxItemId: (activeInboxItemId?: string) => void;
  selectedInboxItemsIds: string[];
  setSelectedInboxItemsIds: (selectedInboxItemsIds: string[]) => void;
};

const InboxItemsContext = createContext({} as InboxItemsContextProps);

export default function InboxItemsProvider({
  children,
}: InboxItemsProviderProps) {
  const { companyId, organizationId } = useCompanyParams();
  const [activeInboxItemId, setActiveInboxItemId] = useState<string>();
  const [isSearching, setIsSearching] = useState(false);
  const [quickFilter, setQuickFilter] = useState<string[]>();
  const [selectedInboxItemsIds, setSelectedInboxItemsIds] = useState<string[]>(
    []
  );

  const { status } = useInboxItemsParams();

  const { inboxItems, isLoading } = useNewInboxItems(
    {
      companyId,
      organizationId,
      status,
    },
    {
      onSuccess: updateSeletedItems,
    }
  );

  function updateSeletedItems(inboxItems: NewInboxItem[]) {
    if (selectedInboxItemsIds.length === 0) return;

    setSelectedInboxItemsIds((state) =>
      state.filter((inboxItemId) =>
        inboxItems.find((item) => item.id === inboxItemId)
      )
    );
  }

  const filteredInboxItems = useMemo(() => {
    if (!quickFilter) {
      return inboxItems;
    }

    return filterInboxItems(inboxItems, quickFilter);
  }, [inboxItems, quickFilter]);

  const activeIndex = useMemo(() => {
    return filteredInboxItems.findIndex(
      (inboxItem) => inboxItem.id === activeInboxItemId
    );
  }, [filteredInboxItems, activeInboxItemId]);

  return (
    <InboxItemsContext.Provider
      value={{
        filteredInboxItems,
        isLoading,
        isSearching,
        setIsSearching,
        quickFilter,
        setQuickFilter,
        activeIndex,
        setActiveInboxItemId,
        selectedInboxItemsIds,
        setSelectedInboxItemsIds,
      }}
    >
      {children}
    </InboxItemsContext.Provider>
  );
}

export function useInboxItemsContext() {
  const context = useContext(InboxItemsContext);

  if (!context) {
    throw new Error('To use context, you must provide a provider');
  }

  return context;
}
