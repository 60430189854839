import type { RcFile } from 'rc-upload/lib/interface';

import { snackbar } from 'src/mui';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

function isValidType(file: RcFile) {
  return file.type.match(
    /image|pdf|ms-excel|xmlformats|text\/xml|x-ofx|text\/plain/
  );
}

export const MAX_SIZE = 1024 * 1024 * 20; // 20 MB

export function isValidSize(file: RcFile) {
  return file.size <= MAX_SIZE;
}

function isValidSumFilesSize(file: RcFile, files: RcFile[]) {
  const listSizeTotal = files.reduce((sum, current) => sum + current.size, 0);

  return file.size + listSizeTotal <= MAX_SIZE;
}

export function isDuplicatedFile(
  file: RcFile | File,
  stateFiles: Pick<Attachment, 'name' | 'type'>[]
) {
  return stateFiles.some((f) => f.name === file.name && f.type === file.type);
}

export function validateBeforeUpload(
  file: RcFile,
  stateFiles: RcFile[],
  options = { shouldSumAllFiles: false }
) {
  if (!isValidType(file)) {
    snackbar({
      variant: 'error',
      message:
        'Arquivo inválido! 👉 Revise o formato: JPG, PNG, GIF, PDF, XLS, XML, OFX ou TXT.',
    });

    return false;
  }

  if (isDuplicatedFile(file, stateFiles)) {
    snackbar({
      variant: 'error',
      message: 'Este arquivo já existe nos documentos anexos ao lançamento.',
    });

    return false;
  }

  if (options.shouldSumAllFiles && !isValidSumFilesSize(file, stateFiles)) {
    const message =
      stateFiles?.length > 0
        ? 'A soma dos arquivos ultrapassam o limite de 20MB.'
        : 'O tamanho do arquivo ultrapassa o limite de 20MB.';

    snackbar({
      variant: 'error',
      message,
    });

    return false;
  }

  if (!isValidSize(file)) {
    snackbar({
      variant: 'error',
      message: 'O tamanho do arquivo ultrapassa o limite de 20MB.',
    });

    return false;
  }

  return true;
}

export function validateBeforeUploadMulti(
  file: RcFile,
  stateFiles: RcFile[],
  options = { shouldSumAllFiles: false }
) {
  if (!isValidType(file)) {
    snackbar({
      variant: 'error',
      message: (
        <>
          Arquivo <b>{file.name}</b> inválido! 👉 Revise o formato: JPG, PNG,
          GIF, PDF, XLS, XML, OFX ou TXT.
        </>
      ),
    });

    return false;
  }

  if (isDuplicatedFile(file, stateFiles)) {
    snackbar({
      variant: 'error',
      message: (
        <>
          Este arquivo <b>{file.name}</b> já existe nos documentos anexos ao
          lançamento.
        </>
      ),
    });

    return false;
  }

  if (options.shouldSumAllFiles && !isValidSumFilesSize(file, stateFiles)) {
    const message =
      stateFiles?.length > 0 ? (
        'A soma dos arquivos ultrapassam o limite de 20MB.'
      ) : (
        <>
          O tamanho do arquivo <b>${file.name}</b> ultrapassa o limite de 20MB.
        </>
      );

    snackbar({
      variant: 'error',
      message,
    });

    return false;
  }

  if (!isValidSize(file)) {
    snackbar({
      variant: 'error',
      message: (
        <>
          O tamanho do arquivo <b>{file.name}</b> ultrapassa o limite de 20MB.
        </>
      ),
    });

    return false;
  }

  return true;
}
