import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import { invalidateInboxItemsQueries } from 'src/libs/finbits/Management/NewInboxItems';

import type { PostParams, Upload } from './types';
import { UploadDecoder } from './types';

async function postUpload({ organizationId, companyId, file }: PostParams) {
  const formData = new FormData();

  formData.append('file', file);

  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/uploads`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return decodeResponse<Upload>(response, UploadDecoder);
}

export function useCreateUpload() {
  const queryClient = useQueryClient();

  const { mutate: createUpload, ...rest } = useMutation<
    Upload,
    ApiError,
    PostParams
  >({
    mutationFn: postUpload,
    onSuccess: () => {
      invalidateInboxItemsQueries(queryClient);
    },
  });

  return {
    createUpload,
    ...rest,
  };
}
