import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import { formatList } from 'src/libs/finbits/List';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { translateCurrentSituation } from 'src/libs/finbits/Management/Entries/currentSituationTranslations';
import type { FinancialStatementEntry as StatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { EntrySituation } from 'src/libs/finbits/Management/Entries/types';

export function nonDeletableMessage(rows: StatementEntry[]) {
  const nonDeletableSituations = rows.reduce((acc: string[], row) => {
    if (acc.includes(row.currentSituation)) {
      return acc;
    }

    if (row.availableActions.includes(AvailableActions.DELETE)) {
      return acc;
    }

    return [...acc, row.currentSituation];
  }, []);

  if (nonDeletableSituations.length === 0) {
    return null;
  }

  const situations = formatList(
    nonDeletableSituations.map((situation) =>
      translateCurrentSituation(situation as EntrySituation)
    )
  );

  return `Os lançamentos em situação ${situations} não podem ser deletados.`;
}

export function buildEntriesIdsToDelete(rows: StatementEntry[]) {
  const entriesToDelete = rows.filter((row) =>
    row.availableActions.includes(AvailableActions.DELETE)
  );

  const receivablesIds = entriesToDelete
    .filter((entry) => entry.entryType === EntryType.RECEIVABLE)
    .map((entry) => entry.id);

  const financialEntriesIds = entriesToDelete
    .filter((entry) => entry.entryType === EntryType.FINANCIAL_ENTRY)
    .map((entry) => entry.id);

  const billsIds = entriesToDelete
    .filter((entry) => entry.entryType === EntryType.BILL)
    .map((entry) => entry.id);

  return { billsIds, receivablesIds, financialEntriesIds };
}
