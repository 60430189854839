import { useMemo } from 'react';

import { matchPath, useLocation } from 'react-router';

import { routes } from 'src/router/routes';

export function useCurrentRoute() {
  const location = useLocation();

  return useMemo(
    () =>
      routes.find((route) => !!matchPath(route.path ?? '', location.pathname)),
    [location.pathname]
  );
}
