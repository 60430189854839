import { useCallback, useEffect } from 'react';

import isArray from 'lodash/isArray';
import { Box, Link } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertCircleIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { TextField, snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { errorsToFormField } from 'src/libs/finbits/Form';
import { useRedefinePassword } from 'src/libs/finbits/Auth/Auth';
import type { RedefinePasswordParams } from 'src/libs/finbits/Auth/types';
import { RedefinePasswordResolver } from 'src/libs/finbits/Auth/types';

function useInvalidHashDialog() {
  const openConfirmDialog = useOpenConfirmDialog();
  const navigate = useNavigate();

  return useCallback(() => {
    openConfirmDialog({
      variant: 'error',
      icon: <AlertCircleIcon />,
      title: 'Ops! Link inválido',
      content:
        'Uma senha já foi redefinida com esse link. Deseja redefinir uma nova senha?',
      confirmText: 'Redefinir senha',
      onConfirm: () => navigate('/recover_password'),
      closable: false,
    });
  }, [openConfirmDialog, navigate]);
}

function RedefinePassword() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RedefinePasswordParams>({
    defaultValues: {
      password: '',
    },
    resolver: zodResolver(RedefinePasswordResolver),
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetHash = searchParams.get('t');

  const openInvalidHashDialog = useInvalidHashDialog();

  const { redefinePassword, isLoading } = useRedefinePassword();

  async function onSubmit({ password }: RedefinePasswordParams) {
    redefinePassword(
      {
        password,
        resetHash,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Senha alterada com sucesso!',
          });

          navigate('/login');
        },
        onError: ({ response }) => {
          if (!!response?.data?.errors?.password) {
            const errors = errorsToFormField(response?.data?.errors);
            errors.map(({ name, errors }) => {
              const field = isArray(name) ? name.join('.') : name;
              const message = errors.join(', ');
              return setError(field as any, { message });
            });

            return;
          }

          return openInvalidHashDialog();
        },
      }
    );
  }

  useEffect(() => {
    if (!resetHash) {
      openInvalidHashDialog();
    }
  }, [resetHash, openInvalidHashDialog]);

  return (
    <Box sx={{ mt: 6, mb: 16 }}>
      <Typography
        variant="h5"
        component="h2"
        marginBottom={4}
        align="center"
        fontWeight="600"
      >
        Redefinir senha
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField.Password
              {...field}
              disabled={isLoading}
              error={!!errors.password}
              helperText={errors.password?.message}
              label="Senha"
              margin="normal"
              placeholder="••••••••"
              type="password"
            />
          )}
        />

        <LoadingButton
          color="primary"
          fullWidth
          loading={isLoading}
          sx={{ mt: 6, mb: 8 }}
          type="submit"
          variant="contained"
          size="large"
        >
          Redefinir senha
        </LoadingButton>

        <Link component={RouterLink} to="/login">
          Fazer login
        </Link>
      </Box>
    </Box>
  );
}

export default RedefinePassword;
