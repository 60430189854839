import t from 'zod';

export enum payeeDocumentType {
  LEGAL = 'legal',
  NATURAL = 'natural',
}

export enum BoletoType {
  BANKING = 'banking',
  CONSUMPTION = 'consumption',
}

export enum BoletoStatus {
  PENDING = 'pending',
  NOT_FOUND = 'not_found',
  COMPLETED = 'completed',
  PAID = 'paid',
}

export const BoletoDecoder = t.object({
  amount: t.number().nullish(),
  bankName: t.string().nullish(),
  digitableLine: t.string(),
  discount: t.number().nullish(),
  dueDate: t.string().nullish(),
  fine: t.number().nullish(),
  id: t.string(),
  interest: t.number().nullish(),
  payeeDocument: t.string().nullish(),
  payeeName: t.string().nullish(),
  payeeTradeName: t.string().nullish(),
  routingNumber: t.string().nullish(),
  segment: t.string().nullish(),
  status: t.nativeEnum(BoletoStatus),
  type: t.nativeEnum(BoletoType),
  completedAt: t.string().nullish(),
  updatedAt: t.string(),
});

export type Boleto = t.TypeOf<typeof BoletoDecoder>;

export type CreateBoletoParams = {
  organizationId?: string;
  companyId?: string;
  digitableLine?: string;
};
