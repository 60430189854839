import { forwardRef } from 'react';
import type { Ref } from 'react';

import { TextField as MTextField } from '@mui/material';
import type { TextFieldProps } from '@mui/material';

function TextFieldBase(props: TextFieldProps, ref: Ref<HTMLInputElement>) {
  return <MTextField inputRef={ref} {...props} />;
}

export default forwardRef(TextFieldBase);
